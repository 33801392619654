import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'

import { kinships } from '@/App/helpers/kinships'

export const dependentGndiDentalSchema: DynamicFormSchemaProps = {
  formContext: {
    hint: '*Campos de preenchimento obrigatório apenas para a emissão automática e/ou cadastro no sisweb',
  },
  schema: {
    type: 'object',
    properties: {
      name: {
        type: ['string', 'null'],
        title: 'Nome Completo',
      },
      isPartner: {
        type: 'boolean',
        title: 'Sócio/Cônjuge de sócio da empresa?',
        default: false,
      },
      maritalStatus: {
        type: ['string', 'null'],
        title: 'Estado Civil*',
        oneOf: [
          { title: 'Solteiro', const: 'SINGLE' },
          { title: 'Casado', const: 'MARRIED' },
          { title: 'Divorciado', const: 'DIVORCED' },
          { title: 'Viúvo', const: 'WIDOWED' },
          { title: 'Separado', const: 'SEPARATED' },
          { title: 'Outro', const: 'OTHER' },
        ],
      },
      gender: {
        type: ['string', 'null'],
        title: 'Gênero*',
        oneOf: [
          { title: 'Feminino', const: 'FEMALE' },
          { title: 'Masculino', const: 'MALE' },
        ],
      },
      cpf: {
        type: ['string', 'null'],
        title: 'CPF*',
      },
      rg: {
        type: ['string', 'null'],
        title: 'RG*',
      },
      birthDate: {
        type: ['string', 'null'],
        title: 'Data de Nascimento',
        format: 'date',
      },
      motherName: {
        type: ['string', 'null'],
        title: 'Nome da Mãe',
      },
      holderKinship: {
        type: ['string', 'null'],
        title: 'Grau de Parentesco*',
        oneOf: kinships.map((kinship) => ({
          const: kinship.value,
          title: kinship.label,
        })),
      },
      phone: {
        type: ['string', 'null'],
        title: 'Telefone*',
      },
      gracePeriodReduction: {
        type: ['string', 'null'],
        title: 'Redução de Carência*',
        oneOf: [
          { title: 'Carência Promocional', const: 'Carência Promocional' },
          {
            title: '3965 - REDUÇÃO 1',
            const: '3965 - REDUÇÃO 1',
          },
          {
            title: '3904 - REDUÇÃO 2',
            const: '3904 - REDUÇÃO 2',
          },
        ],
      },
      isAdministrativeLife: {
        type: ['boolean', 'null'],
        title: 'É uma vida administrativa?',
        oneOf: [
          { title: 'Sim', const: true },
          { title: 'Não', const: false },
        ],
        default: null,
      },
      dentalPlan: {
        type: 'object',
        properties: {
          subtitle: {
            type: 'string',
            title: 'Dados Complementares (Cadastro Sisweb)',
          },
          lifeAmount: {
            type: ['string', 'null'],
            title: 'Valor Vida Dental*',
          },
        },
      },
    },
  },
  uiSchema: {
    name: {
      'ui:placeholder': 'Ex.: Maria da Silva',
    },
    maritalStatus: {
      'ui:placeholder': 'Selecione o estado civil',
    },
    gender: {
      'ui:placeholder': 'Selecione o gênero',
    },
    cpf: {
      'ui:placeholder': '000.000.000-00',
      'ui:options': {
        mask: 'cpf',
      },
    },
    rg: {
      'ui:placeholder': '000000000',
    },
    birthDate: {
      'ui:placeholder': '00/00/0000',
    },
    motherName: {
      'ui:placeholder': 'Ex.: Ana da Silva',
    },
    holderKinship: {
      'ui:placeholder': 'Selecione o grau de parentesco',
    },
    phone: {
      'ui:placeholder': '(00) 00000 0000',
      'ui:options': {
        mask: 'phone',
      },
    },
    gracePeriodReduction: {
      'ui:placeholder': 'Selecione a carência',
    },
    isAdministrativeLife: {
      'ui:widget': 'radio',
    },
    dentalPlan: {
      'ui:options': {
        colSpan: 2,
      },
      subtitle: {
        'ui:widget': 'SubtitleWidget',
      },
      lifeAmount: {
        'ui:placeholder': 'R$ 00,00',
        'ui:options': {
          mask: 'money',
        },
      },
    },
  },
}
