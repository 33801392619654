import styled from "styled-components";
import { Avatar as AntdAvatar } from 'antd';
import { colors } from "../../styles/theme";

export const AvatarStyled = styled(AntdAvatar)`
&&&{

    background-color: ${colors.secundaryText};
    border:none;
};
`