import styled from 'styled-components'
import { ProfileProps } from './Profile'
import { colors } from '../../styles/theme'

type StyledProfileProps = Pick<ProfileProps, 'color'>
export const StyledProfile = styled.div`
  align-items: end;
`
export const HeaderProfile = styled.div`
  display: flex;
  align-items: center;
  width: 220px;
  background-color: white;
  padding: 10px;
`
export const HeaderProfileMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 10px;
`

export const Logo = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  & img {
    width: 50px;
    height: 50px;
    border-radius: 100px;
  }
`
export const BoxGroup = styled.div`
  margin-left: 10px;
  width: 130px;
  span {
    height: 15px;
    font-size: 15px;
    color: #858aa3;
    padding: 5px;
  }
`

export const LabelName = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #4b4b4b;
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const LabelNameMobile = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #4b4b4b;
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    max-width: 200px;
    width: auto;
    font-size: 12px;
  }
`

export const IconeMenu = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer;
`

export const Menu = styled.div`
  position: absolute;
  right: 2%;
  min-width: 150px;
  padding: 8px 0;
  border-radius: 0px 0px 20px 20px;
  background-color: #fff;
  box-shadow: 0 10px 8px 2px rgba(0, 0, 0, 0.15);
  z-index: 9999;

  ul {
    padding: 10px;
    margin: 0;
  }

  ul li {
    display: flex;
    padding: 3px;
    list-style: none;
    justify-content: center;
    cursor: pointer;

    a {
      text-decoration: none;
      color: #4b4b4b;
      width: 100%;
      text-align: center;
    }

    :active {
      color: #4b4b4b;
    }

    :hover {
      background-color: #f6f6f9;
      border-radius: 10px;
    }
  }
`
export const StyledProfileName = styled.div<StyledProfileProps>`
  align-items: center;
  background-color: ${(props) => props.color ?? colors.purple};
  border-radius: 50px;
  color: white;
  display: flex;
  font-size: 30px;
  height: 50px;
  justify-content: center;
  width: 50px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    height: 30px;
    width: 30px;
  }
`
