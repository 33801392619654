import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Steps } from 'design-system/components'

import { OptionsCustomerAssigment } from '@/App/clients/healthPlansOrder/dto'
import { HealthPlansOrdersRequests } from '@/App/clients/healthPlansOrders'
import { CreateLeadInputDto, CreateLeadOutputDto } from '@/App/clients/healthPlansOrders/dtos'
import useFetch from '@/App/clients/http'
import { clearInputMask } from '@/App/utils/string'

import { UserContext } from '../../contexts/userData/userContext'
import InsurerRegistration from './insurerRegistration'
import CustomerAssignment from './leadAssignment'
import Order from './order'
import { useCreateOrderContext } from './order/store'
import { CreateOrderDiv } from './style'

const CreateOrderPage = () => {
  const navigate = useNavigate()
  const [currentStep, setCurrentStep] = useState(0)
  const [optionsCustomerAssigment, setOptionsCustomerAssigment] =
    useState<OptionsCustomerAssigment>()
  const {
    setRequestConfig: setCreateLeadRequestConfig,
    data: createLeadData,
    isLoading: createLeadIsLoading,
  } = useFetch<CreateLeadOutputDto>()

  const createOrderContext = useCreateOrderContext()

  const createLead = (data: CreateLeadInputDto) => {
    if (createOrderContext.broker?.id) {
      const createLeadRequestConfig = HealthPlansOrdersRequests.createLead(
        createOrderContext.broker?.id,
        {
          ...data,
          origin: 'INTRANET',
          phone: data.phone != null ? clearInputMask(data.phone) : null,
        },
      )

      setCreateLeadRequestConfig(createLeadRequestConfig)
      createOrderContext.setLead({
        name: data.name,
      })
      setCurrentStep(currentStep + 1)
    }
  }

  useEffect(() => {
    if (createLeadData) createOrderContext.setLead({ id: createLeadData.id })
  }, [createLeadData])

  const userContext = useContext(UserContext)

  const steps = [
    {
      label: 'Cadastro na operadora',
      children: <InsurerRegistration />,
    },
    {
      label: 'Para quem é a proposta',
      children: (
        <CustomerAssignment
          createLead={createLead}
          optionsCustomerAssigment={optionsCustomerAssigment}
          setOptionsCustomerAssigment={setOptionsCustomerAssigment}
        />
      ),
      formId: createOrderContext.lead?.id != null ? null : 'createLead',
      onNextStepIsLoading: createLeadIsLoading,
    },
    {
      label: 'Cotação',
      children: <Order createLeadIsLoading={createLeadIsLoading} />,
      formId: 'createOrder',
    },
  ]
  return (
    <CreateOrderDiv>
      <Steps
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        steps={steps}
        title="Nova proposta"
        description={`Responsável atual: ${userContext?.user?.name}`}
        cancel={() => navigate('/propostas')}
      />
    </CreateOrderDiv>
  )
}

export default CreateOrderPage
