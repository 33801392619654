import { useState } from 'react'

import { CloseCircleFilled, EditFilled } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { Select, Table } from 'design-system/components'

import { useChangeBrokerCommissionGridPayMutation } from '@/App/clients/finOps/mutations/changeBrokerCommissionGrid'
import { getBrokerCommissionPayQueryOptions } from '@/App/clients/finOps/queries/brokerCommissionPay'
import { getCommissionGridsPayQueryOptions } from '@/App/clients/finOps/queries/commissionGridPay'

import { TextContainer } from './styles'

const BrokerCommissionPayComponent = () => {
  const [editEnabledId, setEditEnabledId] = useState('')

  dayjs.locale('pt-br')

  const getBrokerCommissionPayQuery = useQuery(getBrokerCommissionPayQueryOptions({ filter: '' }))
  const getCommissionGridsPayQuery = useQuery(getCommissionGridsPayQueryOptions({ filter: '' }))

  const changeBrokerCommissionGridPayMutation = useChangeBrokerCommissionGridPayMutation()

  const gridsList = getCommissionGridsPayQuery.data?.map((grid) => ({
    id: grid.id,
    name: grid.nome,
  }))

  const handleChangeCommisionGrid = (lineData, grid) => {
    changeBrokerCommissionGridPayMutation.mutate({
      id: lineData.id,
      idGrade: grid,
    })
  }

  const tableColumns = [
    {
      title: 'Cod Corretor Sisweb',
      dataIndex: 'idExternoCorretor',
      key: 'idExternoCorretor',
      sorter: (a, b) => a.idExternoCorretor - b.idExternoCorretor,
    },
    {
      title: 'Corretor',
      dataIndex: 'nomeCorretor',
      key: 'nomeCorretor',
    },
    {
      title: 'Supervisor',
      dataIndex: 'supervisor',
      key: 'supervisor',
    },
    {
      title: 'Tipo Corretor',
      dataIndex: 'tipoCorretor',
      key: 'tipoCorretor',
    },
    {
      title: `GMV Produção ${dayjs().subtract(1, 'month').format('MMMM')}`,
      dataIndex: 'gmvProducao',
      key: 'gmvProducao',
      render: (value: number) => `R$ ${value.toFixed(2).replace('.', ',')}`,
    },
    {
      title: `Grade ${dayjs().format('MMMM')}`,
      dataIndex: 'nomeGrade',
      key: 'nomeGrade',
      render: (value, lineData) => {
        if (editEnabledId === lineData.id)
          return (
            <TextContainer>
              <Select
                defaultValue={gridsList?.find((grid) => grid.id === lineData.idGrade)?.id}
                options={gridsList?.map((grid) => ({ label: grid.name, value: grid.id })) ?? []}
                onChange={(gridId) => handleChangeCommisionGrid(lineData, gridId)}
              />
              <CloseCircleFilled style={{ marginLeft: 4 }} onClick={() => setEditEnabledId('')} />
            </TextContainer>
          )

        return (
          <TextContainer>
            {lineData.tipoVinculo === 'M' ? '(M) ' : ''} {value}
            <EditFilled style={{ marginLeft: 4 }} onClick={() => setEditEnabledId(lineData.id)} />
          </TextContainer>
        )
      },
    },
  ]

  return (
    <Table
      loading={
        getBrokerCommissionPayQuery.isFetching || changeBrokerCommissionGridPayMutation.isPending
      }
      columns={tableColumns}
      data={getBrokerCommissionPayQuery.data ?? []}
    />
  )
}

export default BrokerCommissionPayComponent
