import { CheckBox } from 'design-system/components'

import { Action } from '../..'
import { ColumnBody } from './style'

interface Props {
  handleRequest: (action: Action, filterValues: [{ key: string; value: string }]) => void
  selectedInternalSystem: string[]
}

const checkboxOptions = [
  {
    label: 'Cadastradas',
    value: 'true',
    name: 'isRegisteredInInternalSystem',
  },
  {
    label: 'Não cadastradas',
    value: 'false',
    name: 'isRegisteredInInternalSystem',
  },
]

export const InternalSystemFilter = ({ handleRequest, selectedInternalSystem }: Props) => {
  const handleChange = (event) => {
    const action = event.target.checked ? Action.AddFilter : Action.RemoveFilter

    handleRequest(action, [{ key: 'isRegisteredInInternalSystem', value: event.target.value }])
  }

  return (
    <>
      <ColumnBody>
        {checkboxOptions.map((option, index) => {
          return (
            <CheckBox
              key={index}
              onChange={(e) => handleChange(e)}
              label={option.label}
              value={option.value}
              name={option.name}
              checked={selectedInternalSystem?.includes(option.value) ? true : false}
            />
          )
        })}
      </ColumnBody>
    </>
  )
}
