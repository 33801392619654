const SignOutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20" fill="none">
      <path
        d="M14.166 5.83333L12.991 7.00833L15.141 9.16667H6.66602V10.8333H15.141L12.991 12.9833L14.166 14.1667L18.3327 10L14.166 5.83333ZM3.33268 4.16667H9.99935V2.5H3.33268C2.41602 2.5 1.66602 3.25 1.66602 4.16667V15.8333C1.66602 16.75 2.41602 17.5 3.33268 17.5H9.99935V15.8333H3.33268V4.16667Z"
        fill="#8589A2"
      />
    </svg>
  )
}

export default SignOutIcon
