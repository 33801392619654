import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';


export const StyledToastContainer = styled(ToastContainer)`
.Toastify__toast--success {
    background: #369836;
    color: #FFFFFF;
    padding: 0;
}

.Toastify__toast-body{
    padding: 0.8rem;
}

.Toastify__close-button svg {
    color: white;
}

.Toastify__toast {
    border-radius: 1rem;
}

.Toastify__toast--error{
    background: #BF404C;
    color: #FFFFFF;
    padding: 0;
}

.Toastify__close-button{
    padding: 0.8rem;
}

.Toastify__toast-body span{
    font-size: 1rem;
}
`;
