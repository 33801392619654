import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'

import type { GetSchemaProps } from '..'

export function companyPortoSeguroSchema({ isOcrFilled }: GetSchemaProps): DynamicFormSchemaProps {
  return {
    formContext: {
      hint: '*Campos de preenchimento obrigatório apenas para a emissão automática e/ou cadastro no sisweb',
    },
    schema: {
      type: 'object',
      properties: {
        document: {
          type: 'string',
          title: 'CNPJ*',
        },
        tradingName: {
          type: 'string',
          title: 'Razão social',
        },
        address: {
          type: 'object',
          properties: {
            zipCode: {
              type: 'string',
              title: 'CEP*',
            },
            entityAddress: {
              type: 'string',
              title: 'Endereço',
            },
            number: {
              type: 'string',
              title: 'Número*',
            },
            complement: {
              type: 'string',
              title: 'Complemento',
            },
            neighborhood: {
              type: 'string',
              title: 'Bairro',
            },
            city: {
              type: 'string',
              title: 'Cidade',
            },
            state: {
              type: 'string',
              title: 'Estado',
            },
          },
        },
        registrationStatus: {
          type: 'string',
          title: 'Situação Cadastral*',
        },
        openingDate: {
          type: 'string',
          title: 'Data de Abertura*',
          format: 'date',
        },
        emptyColumn1: {
          type: 'string',
        },
        externalLegalRepresentative: {
          type: 'object',
          properties: {
            legalRepresentativeSubtitle: {
              type: 'string',
              title: 'Representante Legal',
            },
            name: {
              type: 'string',
              title: 'Nome Completo*',
            },
            cpf: {
              type: 'string',
              title: 'CPF*',
            },
            mail: {
              type: 'string',
              title: 'E-mail*',
              format: 'email',
            },
            phone: {
              type: 'string',
              title: 'Telefone*',
            },
            role: {
              type: 'string',
              title: 'Cargo*',
            },
            shouldCopyDataToContact: {
              type: 'boolean',
              title: 'Repetir para o contato da empresa?',
            },
          },
        },
        subtitle: {
          type: 'string',
          title: 'Contato da Empresa',
        },
        name: {
          type: 'string',
          title: 'Nome*',
        },
        phone: {
          type: 'string',
          title: 'Telefone*',
        },
        mail: {
          type: 'string',
          title: 'E-mail*',
          format: 'email',
        },
      },
    },
    uiSchema: {
      document: {
        'ui:placeholder': '00.000.000/0000-00',
        'ui:options': {
          mask: 'cnpj',
        },
      },
      tradingName: {
        'ui:placeholder': 'Ex.: Espaço de Beleza LTDA',
        'ui:widget': 'OCRCnpjTextInputWidget',
      },
      address: {
        'ui:options': {
          colSpan: 2,
        },
        zipCode: {
          'ui:placeholder': '00000-000',
          'ui:options': {
            mask: 'cep',
          },
          'ui:widget': 'OCRCnpjTextInputWidget',
        },
        entityAddress: {
          'ui:placeholder': 'Buscar endereço',
          'ui:widget': 'AutoCompleteWidget',
        },
        number: {
          'ui:placeholder': '000',
          'ui:widget': 'OCRCnpjTextInputWidget',
        },
        complement: {
          'ui:placeholder': 'Ex.: Apto 123',
          'ui:widget': 'OCRCnpjTextInputWidget',
        },
        neighborhood: {
          'ui:placeholder': 'Ex.: Pinheiros',
        },
        city: {
          'ui:placeholder': 'Ex.: São Paulo',
        },
        state: {
          'ui:placeholder': 'Ex.: SP',
        },
      },
      registrationStatus: {
        'ui:placeholder': 'Informe a situação',
        'ui:widget': 'OCRCnpjTextInputWidget',
      },
      openingDate: {
        'ui:placeholder': 'DD/MM/YYYY',
        'ui:widget': 'OCRCnpjDateInputWidget',
      },
      emptyColumn1: {
        'ui:widget': 'hidden',
      },
      externalLegalRepresentative: {
        'ui:options': {
          colSpan: 2,
        },
        legalRepresentativeSubtitle: {
          'ui:widget': 'SubtitleWidget',
        },
        name: {
          'ui:placeholder': 'Ex.: Maria Silva',
          'ui:disabled': isOcrFilled,
          'ui:widget': 'OCRDisabledTextInputWidget',
        },
        cpf: {
          'ui:placeholder': '000.000.000-00',
          'ui:options': {
            mask: 'cpf',
          },
          'ui:disabled': isOcrFilled,
          'ui:widget': 'OCRDisabledTextInputWidget',
        },
        mail: {
          'ui:placeholder': 'exemplo@email.com.br',
          'ui:widget': 'TextWidget',
        },
        phone: {
          'ui:placeholder': '(00) 0 0000-0000',
          'ui:options': {
            mask: 'phone',
          },
        },
        role: {
          'ui:placeholder': 'Ex.: Gestor',
        },
      },
      subtitle: {
        'ui:widget': 'SubtitleWidget',
      },
      name: {
        'ui:placeholder': 'Ex.: Maria Pereira Silva',
      },
      phone: {
        'ui:placeholder': '(00) 00000 0000',
        'ui:options': {
          mask: 'phone',
        },
        'ui:widget': 'OCRCnpjTextInputWidget',
      },
      mail: {
        'ui:placeholder': 'exemplo@email.com.br',
        'ui:widget': 'OCRCnpjTextInputWidget',
      },
    },
  }
}
