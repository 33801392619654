import React from 'react'

import { ErrorSchema } from 'design-system/DynamicForm/DynamicForm'
import { StoreApi, createStore, useStore } from 'zustand'

import type { Entity } from '@/App/clients/healthPlansOrders/dtos'
import type { EntityType } from '@/App/pages/orderDetails/entities/entityAccordion'

type ErrorObject = {
  [key: string]: ErrorObject | { __errors: string[] }
}

type PlanOption = {
  label: string
  value: string
}

type EntityDispatchModalProps = {
  isOpen: boolean
  origin?: 'AUTOMATION' | 'INTRANET'
  entity?: Entity | null
  entityType?: EntityType | null
}

type DynamicFormState = {
  extraErrors: Record<string, ErrorSchema | null | undefined>
  planOptions?: Array<PlanOption>
  odontoPlanOptions?: Array<PlanOption>
  entityInclusionDispatchModal: EntityDispatchModalProps
  entityExclusionDispatchModal: EntityDispatchModalProps
}

type DynamicFormAction = {
  setErrorForRequiredField: (entityId?: string | null, fields?: Array<string | null>) => void
  removeFromExtraErrors: (entityId?: string, field?: string) => void
  setPlanOptions: (planOptions?: Array<PlanOption>) => void
  setOdontoPlanOptions: (planOptions?: Array<PlanOption>) => void
  setEntityInclusionDispatchModal: (entityModalProps: EntityDispatchModalProps) => void
  setEntityExclusionDispatchModal: (entityModalProps: EntityDispatchModalProps) => void
}

type DynamicFormContextData = DynamicFormState & DynamicFormAction

const DynamicFormContext = React.createContext<StoreApi<DynamicFormContextData> | null>(null)

type DynamicFormProviderProps = {
  children: React.ReactNode
  initialState?: {
    errors: Record<string, ErrorSchema | null>
    planOptions: Array<PlanOption>
    odontoPlanOptions: Array<PlanOption>
  }
}

export const DynamicFormProvider = ({
  children,
  initialState = {} as DynamicFormProviderProps['initialState'],
}: DynamicFormProviderProps) => {
  const [store] = React.useState(() =>
    createStore<DynamicFormContextData>((set) => ({
      entityInclusionDispatchModal: {
        isOpen: false,
        origin: 'AUTOMATION',
      },
      entityExclusionDispatchModal: {
        isOpen: false,
        origin: 'AUTOMATION',
      },
      planOptions: initialState?.planOptions ?? [],
      odontoPlanOptions: initialState?.odontoPlanOptions ?? [],
      extraErrors: initialState?.errors ?? {},
      setErrorForRequiredField(entityId, fields) {
        set((state) => {
          const updatedErrors = fields?.reduce((errors, field) => {
            const parts = field?.split('.') ?? []
            let current: ErrorObject = errors

            for (let i = 0; i < parts.length - 1; i++) {
              const part = parts.at(i) as string
              if (!current[part]) current[part] = {}
              current = current[part] as ErrorObject
            }

            current[parts.at(-1) as string] = {
              __errors: ['Esse campo é obrigatório'],
            }

            return errors
          }, {})

          if (entityId) {
            return {
              extraErrors: {
                ...state.extraErrors,
                [entityId]: updatedErrors,
              },
            }
          }

          return {
            extraErrors: state.extraErrors,
          }
        })
      },
      removeFromExtraErrors(entityId, field) {
        if (entityId) {
          set((state) => {
            const entityErrors = state.extraErrors[entityId]
            if (entityErrors) {
              const parts = field?.split('.') ?? []
              let current = entityErrors

              for (let i = 0; i < parts.length - 1; i++) {
                const part = parts.at(i) as string
                if (!current[part]) current[part] = {}
                current = current[part] as ErrorSchema
              }

              current[parts.at(-1) as string] = undefined
            }
            return state
          })
        }
      },
      setPlanOptions(planOptions) {
        set(() => {
          return {
            planOptions,
          }
        })
      },
      setOdontoPlanOptions(odontoPlanOptions) {
        set(() => {
          return {
            odontoPlanOptions,
          }
        })
      },
      setEntityInclusionDispatchModal(entityDispatchModal) {
        set(() => {
          return {
            entityInclusionDispatchModal: {
              isOpen: entityDispatchModal.isOpen ?? false,
              origin: entityDispatchModal.origin ?? 'AUTOMATION',
            },
          }
        })
      },
      setEntityExclusionDispatchModal(entityDispatchModal) {
        set(() => {
          return {
            entityExclusionDispatchModal: {
              isOpen: entityDispatchModal.isOpen ?? false,
              origin: entityDispatchModal.origin ?? 'AUTOMATION',
              entity: entityDispatchModal.entity ?? null,
              entityType: entityDispatchModal.entityType ?? null,
            },
          }
        })
      },
    })),
  )

  return <DynamicFormContext.Provider value={store}>{children}</DynamicFormContext.Provider>
}

export function useDynamicFormStore<T>(selector: (state: DynamicFormContextData) => T): T {
  const store = React.useContext(DynamicFormContext)

  if (!store) {
    throw new Error('DynamicFormContext not provided in the provider tree.')
  }

  return useStore(store, selector)
}

export const useDynamicForm = () => useDynamicFormStore((state: DynamicFormContextData) => state)
