import { useVariant } from '@unleash/proxy-client-react'
import { Skeleton } from 'design-system/components'

import { GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'

import { HorizontalLine, OtherInfosDiv, OtherInfosHeader } from './style'
import { BrokerB2CPersonType } from './types/brokerB2BPersonType'
import { BrokerPersonType } from './types/brokerPersonType'
import { ManagerB2CPersonType } from './types/managerB2BPersonType'
import { ManagerPersonType } from './types/managerPersonType'

export interface ExternalPersonsProps {
  orderData?: GetOrderInformationBody
  reloadOrderData: () => void
  isLoadingReloadOrderData: boolean
  validateOrderDispatch?: boolean
}

export const InsurerExternalResponsibles: React.FC<ExternalPersonsProps> = ({
  orderData,
  reloadOrderData,
  validateOrderDispatch,
  isLoadingReloadOrderData,
}) => {
  const blissB2CbBrokers = useVariant('bliss-intranet-b2c-brokers')?.payload?.value
  return (
    <OtherInfosDiv>
      <OtherInfosHeader>
        <span>Dados dos responsáveis na operadora</span>
      </OtherInfosHeader>
      {orderData == null ? (
        <Skeleton
          type="default"
          shape="square"
          active={true}
          loading={true}
          title={{ width: '400px' }}
        />
      ) : (
        <>
          <HorizontalLine />
          <div style={{ display: 'flex', gap: '30px', justifyContent: 'start' }}>
            {orderData?.broker?.mail && blissB2CbBrokers?.includes(orderData?.broker?.mail) ? (
              <>
                <BrokerB2CPersonType />
                <ManagerB2CPersonType />
              </>
            ) : (
              <>
                <BrokerPersonType
                  orderData={orderData}
                  reloadOrderData={reloadOrderData}
                  validateOrderDispatch={validateOrderDispatch}
                  isLoadingReloadOrderData={isLoadingReloadOrderData}
                />

                <ManagerPersonType
                  orderData={orderData}
                  reloadOrderData={reloadOrderData}
                  validateOrderDispatch={validateOrderDispatch}
                  isLoadingReloadOrderData={isLoadingReloadOrderData}
                />
              </>
            )}
          </div>
        </>
      )}
    </OtherInfosDiv>
  )
}
