import { SubmitButtonProps } from '@rjsf/utils'

import { Button } from '../../components'
import { HintMessage } from '../styles'

export function SubmitButtonTemplate({ registry }: SubmitButtonProps) {
  const { formContext } = registry

  return (
    <>
      {formContext?.hint ? <HintMessage>{formContext.hint}</HintMessage> : null}
      <Button htmlType="submit">Salvar</Button>
    </>
  )
}
