export const insurers = [
  { label: 'Affix', value: 'Affix' },
  { label: 'Alice', value: 'Alice' },
  { label: 'Allianz Seguros', value: 'Allianz Seguros' },
  { label: 'Ameplan', value: 'Ameplan' },
  { label: 'Amil', value: 'Amil' },
  { label: 'Ampla Saúde', value: 'Ampla Saúde' },
  { label: 'Bio Saúde', value: 'Bio Saúde' },
  { label: 'Biovida Saúde', value: 'Biovida Saúde' },
  { label: 'Blue', value: 'Blue' },
  { label: 'Blue Med Alvorecer', value: 'Blue Med Alvorecer' },
  { label: 'Bradesco Dental', value: 'Bradesco Dental' },
  { label: 'Bradesco Seguros', value: 'Bradesco Seguros' },
  { label: 'Cassi', value: 'Cassi' },
  { label: 'Clinipam', value: 'Clinipam' },
  { label: 'Clube de Saúde (Qualicorp)', value: 'Clube de Saúde (Qualicorp)' },
  { label: 'Clude', value: 'Clude' },
  { label: 'CNU', value: 'CNU' },
  { label: 'Corpore', value: 'Corpore' },
  { label: 'Cruz Azul Saúde', value: 'Cruz Azul Saúde' },
  { label: 'Cuidar.Me', value: 'Cuidar.Me' },
  { label: 'Dentalpar', value: 'Dentalpar' },
  { label: 'GNDI', value: 'GNDI' },
  { label: 'Golden Cross', value: 'Golden Cross' },
  { label: 'GS Garantia', value: 'GS Garantia' },
  { label: 'Go Care', value: 'Go Care' },
  { label: 'Health Santaris', value: 'Health Santaris' },
  { label: 'HBC Saúde', value: 'HBC Saúde' },
  { label: 'Interodonto', value: 'Interodonto' },
  { label: 'KIPP Saúde', value: 'KIPP Saúde' },
  { label: 'Leve Saúde', value: 'Leve Saúde' },
  { label: 'Med-Tour Saúde', value: 'Med-Tour Saúde' },
  { label: 'Medical Health', value: 'Medical Health' },
  { label: 'Medsênior', value: 'Medsênior' },
  { label: 'Metlife', value: 'Metlife' },
  { label: 'MaisDental', value: 'Mais Dental' },
  { label: 'Notre Dame Intermédica', value: 'Notre Dame Intermédica' },
  { label: 'Notrelife Up', value: 'Notrelife Up' },
  { label: 'Omint', value: 'Omint' },
  { label: 'Plena Saúde', value: 'Plena Saúde' },
  { label: 'Plural', value: 'Plural' },
  { label: 'Porto Seguro', value: 'Porto Seguro' },
  { label: 'Prevent Senior', value: 'Prevent Senior' },
  { label: 'Qsaúde', value: 'Qsaúde' },
  { label: 'Safelife', value: 'Safelife' },
  { label: 'Sami', value: 'Sami' },
  { label: 'Santa Casa Saúde SJC', value: 'Santa Casa Saúde SJC' },
  { label: 'Santa Helena (Grupo Amil)', value: 'Santa Helena (Grupo Amil)' },
  { label: 'São Cristóvão', value: 'São Cristóvão' },
  { label: 'São Miguel Saúde', value: 'São Miguel Saúde' },
  { label: 'Seguros Unimed', value: 'Seguros Unimed' },
  { label: 'Sobam (Grupo Amil)', value: 'Sobam (Grupo Amil)' },
  { label: 'Sompo Seguros', value: 'Sompo Seguros' },
  { label: 'SulAmérica', value: 'SulAmérica' },
  { label: 'SulAmérica Odonto', value: 'SulAmérica Odonto' },
  { label: 'Total Medcare', value: 'Total Medcare' },
  { label: 'Trasmontano', value: 'Trasmontano' },
  { label: 'Unica Saúde', value: 'Unica Saúde' },
  { label: 'Unihosp Saúde', value: 'Unihosp Saúde' },
  { label: 'Unimed Fesp', value: 'Unimed Fesp' },
  { label: 'Unimed Guarulhos', value: 'Unimed Guarulhos' },
  { label: 'Unimed Jundiaí', value: 'Unimed Jundiaí' },
  { label: 'Unimed Nacional (CNU)', value: 'Unimed Nacional (CNU)' },
  { label: 'Unimed São José dos Campos', value: 'Unimed São José dos Campos' },
  { label: 'Unimed Santos', value: 'Unimed Santos' },
  { label: 'Você Clube', value: 'Você Clube' },
]
