import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useMutationState } from '@tanstack/react-query'
import { useVariant } from '@unleash/proxy-client-react'
import Decimal from 'decimal.js'
import {
  DynamicForm as BlissDynamicForm,
  DynamicFormSchemaProps,
} from 'design-system/DynamicForm/DynamicForm'
import { triggerToast } from 'design-system/triggers'

import { useGetCompanyByCnpj } from '@/App/clients/OCR/queries/getCompanyByCnpj'
import { Entity } from '@/App/clients/healthPlansOrders/dtos'
import { useFillOrderInformation } from '@/App/clients/healthPlansOrders/mutations/fillOrderInformation'
import { useOrderInformationQuery } from '@/App/clients/healthPlansOrders/queries/orderInformation'
import { useLatestPersonDispatchEvent } from '@/App/clients/orderGateway/queries/getLatestPersonDispatchEvent'
import { useDynamicForm } from '@/App/contexts/dynamicForm/DynamicFormProvider'
import { genderPredictionFunction } from '@/App/helpers/genderPrediction'
import { ONE_KILOGRAM_IN_GRAM, ONE_METER_IN_CENTIMETER } from '@/App/utils/constants'
import { transformAddressToEntityAddress } from '@/App/utils/parseAddress'
import { clearInputMask } from '@/App/utils/string'

import { EntityType } from '..'
import { useEntities } from '../../store'
import { getRequiredEntityFieldsAmil } from '../helper/amil/requiredFields'
import { getRequiredEntityFieldsBradesco } from '../helper/bradesco/requiredFields'
import { getRequiredEntityFieldsGndi } from '../helper/gndi/requiredFields'
import { parseFillEntityPayloadRequest } from '../helper/helper'
import { getRequiredEntityFieldsPorto } from '../helper/porto/requiredFields'
import { getRequiredEntityFieldsSisweb } from '../helper/sisweb/requiredFields'
import { getRequiredEntityFieldsSulamerica } from '../helper/sulamerica/requiredFields'
import { AutoCompleteWidget } from './AutoCompleteWidget'
import { DispatchEntityFooter } from './DispatchEntityFooter'
import { DispatchEntityIncludeEventConfirmationModal } from './DispatchEntityIncludeEventConfirmationModal'
import { OCRCnpjDateInputWidget } from './OCRCnpjDateInputWidget'
import { OCRCnpjTextInputWidget } from './OCRCnpjTextInputWidget'
import { OCRDisabledTextInputWidget } from './OCRDisabledTextInputField'
import { SubmitButtonTemplate } from './SubmitButtonTemplate'

type DynamicFormProps = {
  entityType: EntityType
  schema?: DynamicFormSchemaProps | null
  entityData?: Entity | null
  financialOfficerId?: string | null
  holders: Entity[] | null | undefined
}

function transformEntityDataContact(entityData?: Entity | null) {
  if (!entityData) {
    return entityData
  }

  const fieldsToValidate = ['mail', 'phone', 'name']

  const hasEqualFields = fieldsToValidate.some((field) => {
    if (entityData.externalLegalRepresentative) {
      return entityData[field] === entityData.externalLegalRepresentative[field]
    }
    return false
  })

  if (!hasEqualFields) {
    return entityData
  }

  return {
    ...entityData,
    externalLegalRepresentative: {
      ...entityData.externalLegalRepresentative,
      shouldCopyDataToContact: true,
    },
    name: entityData.externalLegalRepresentative?.name ?? null,
    mail: entityData.externalLegalRepresentative?.mail ?? null,
    phone: entityData.externalLegalRepresentative?.phone ?? null,
  }
}

const getRequiredFieldInsurers = {
  Amil: getRequiredEntityFieldsAmil,
  'Bradesco Seguros': getRequiredEntityFieldsBradesco,
  GNDI: getRequiredEntityFieldsGndi,
  'Notre Dame Intermédica': getRequiredEntityFieldsGndi,
  'Porto Seguro': getRequiredEntityFieldsPorto,
  Sulamérica: getRequiredEntityFieldsSulamerica,
  default: getRequiredEntityFieldsSisweb,
}

export function DynamicForm({
  entityType,
  schema,
  entityData,
  financialOfficerId,
  holders,
}: DynamicFormProps) {
  const { orderId } = useParams<{ orderId: string }>()
  const { isLegalAge, setIsLegalAge } = useEntities()
  const allowedDispatchPersonInsurers = useVariant('bliss-intranet-dispatch-person-event')

  const dependentHolder = holders?.find((holder) => {
    return holder?.dependents?.some((dependent) => dependent?.id === entityData?.id)
  })

  const dependentQuotationPlans =
    entityType === 'DEPENDENT' ? dependentHolder?.quotationPlans : entityData?.quotationPlans

  const dependentHealthQuotationPlans = dependentQuotationPlans?.find(
    (plan) => plan.type === 'HEALTH',
  )
  const dependentDentalQuotationPlans = dependentQuotationPlans?.find(
    (plan) => plan.type === 'DENTAL',
  )

  const orderInformationQuery = useOrderInformationQuery({ orderId, enabled: false })

  const [formDataState, setFormDataState] = useState({
    ...entityData,
    ...transformAddressToEntityAddress(entityData?.address),
    ...(entityType === 'COMPANY' ? transformEntityDataContact(entityData) : {}),
    ...(entityType === 'HOLDER'
      ? { isLegalAge, gender: entityData?.gender ?? genderPredictionFunction(entityData?.name) }
      : {}),
    height: entityData?.height
      ? new Decimal(entityData.height).dividedBy(ONE_METER_IN_CENTIMETER).toFixed(2)
      : null,
    weight: entityData?.weight
      ? new Decimal(entityData.weight).dividedBy(ONE_KILOGRAM_IN_GRAM).toFixed(2)
      : null,
    hiredHealthPlan: entityData?.hiredPlans?.find((plan) => plan.type === 'HEALTH'),
    hiredDentalPlan: entityData?.hiredPlans?.find((plan) => plan.type === 'DENTAL'),
    dentalPlan: {
      ...dependentDentalQuotationPlans,
      lifeAmount:
        entityData?.quotationPlans?.find((plan) => plan.type === 'DENTAL')?.lifeAmount ?? null,
    },
    healthPlan: {
      ...dependentHealthQuotationPlans,
      lifeAmount:
        entityData?.quotationPlans?.find((plan) => plan.type === 'HEALTH')?.lifeAmount ?? null,
    },
    insurer: orderInformationQuery.data?.quotation?.insurer ?? null,
  })

  const fillOrderInformationMutation = useFillOrderInformation({
    orderId,
    entityType,
  })
  const isFillOrderInformationPending = useMutationState({
    filters: {
      mutationKey: ['orders', orderId, { entityType }],
      status: 'pending',
    },
  })
  const companyByCnpjQuery = useGetCompanyByCnpj({
    cnpj: clearInputMask(formDataState.document),
    enabled: entityType === 'COMPANY' && entityData?.tradingName === null,
  })
  const personDispatchEventsQuery = useLatestPersonDispatchEvent({
    personInternalId: entityData?.id,
    enabled:
      orderInformationQuery.data?.status === 'HIRED' &&
      ['HOLDER', 'DEPENDENT'].includes(entityType),
  })

  const {
    extraErrors,
    removeFromExtraErrors,
    planOptions,
    odontoPlanOptions,
    setEntityInclusionDispatchModal,
  } = useDynamicForm()

  useEffect(() => {
    if (companyByCnpjQuery.isError) {
      triggerToast(false, 'CNPJ não consta em nossa base dados! Por favor preencha manualmente.')
    }
  }, [companyByCnpjQuery.isError])

  useEffect(() => {
    if (fillOrderInformationMutation.isSuccess) {
      triggerToast(true, 'Dados atualizados com sucesso!')
      orderInformationQuery.refetch()
    } else if (fillOrderInformationMutation.isError)
      triggerToast(false, 'Ops, ocorreu um erro ao atualizar os dados')
  }, [fillOrderInformationMutation.isSuccess, fillOrderInformationMutation.isError])

  useEffect(() => {
    if (entityType === 'COMPANY' && companyByCnpjQuery.data?.company != undefined) {
      const companyData = companyByCnpjQuery.data.company
      setFormDataState((prev) => ({
        ...prev,
        ...transformAddressToEntityAddress(companyData.address),
        tradingName: companyData.name,
        document: companyData.document,
        openingDate: companyData.openingDate,
        registrationStatus: companyData.registrationStatus,
        phone: companyData.phone,
        mail: companyData.mail?.toLowerCase(),
      }))
    }
  }, [entityType, companyByCnpjQuery.data])

  useEffect(() => {
    setFormDataState((prev) => ({
      ...prev,
      ...(entityType === 'COMPANY' ? transformEntityDataContact(entityData) : {}),
      ...(entityType === 'HOLDER' ? { isLegalAge } : {}),
      height: entityData?.height
        ? new Decimal(entityData.height).dividedBy(ONE_METER_IN_CENTIMETER).toFixed(2)
        : null,
      weight: entityData?.weight
        ? new Decimal(entityData.weight).dividedBy(ONE_KILOGRAM_IN_GRAM).toFixed(2)
        : null,
      dentalPlan: {
        ...dependentDentalQuotationPlans,
        lifeAmount:
          entityData?.quotationPlans?.find((plan) => plan.type === 'DENTAL')?.lifeAmount ?? null,
      },
      healthPlan: {
        ...dependentHealthQuotationPlans,
        lifeAmount:
          entityData?.quotationPlans?.find((plan) => plan.type === 'HEALTH')?.lifeAmount ?? null,
      },
    }))
  }, [entityData, isLegalAge])

  const handleCopyCompanyLegalRepresentativeToContact = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (formData: any) => {
      const shouldCopyDataToContact = formData?.externalLegalRepresentative?.shouldCopyDataToContact

      if (shouldCopyDataToContact === undefined) {
        return setFormDataState({
          ...formData,
          name: formDataState?.name ?? null,
          phone: formDataState?.phone ?? null,
          mail: formDataState?.mail ?? null,
          contactDocument: formDataState?.cpf ?? null,
        })
      }

      if (shouldCopyDataToContact) {
        return setFormDataState({
          ...formData,
          name: formData?.externalLegalRepresentative?.name ?? null,
          phone: formData?.externalLegalRepresentative?.phone ?? null,
          mail: formData?.externalLegalRepresentative?.mail ?? null,
          contactDocument: formData?.externalLegalRepresentative?.cpf ?? null,
        })
      }

      return setFormDataState({
        ...formData,
        name: null,
        phone: null,
        mail: null,
        contactDocument: null,
        role: null,
      })
    },
    [entityType, formDataState],
  )

  const hasEntityCreatedBeforeOrderWasHired =
    String(entityData?.createdAt) < String(orderInformationQuery.data?.hiredAt) ||
    entityType === 'COMPANY'

  const shouldDisableForm =
    (orderInformationQuery.data?.status === 'HIRED' && hasEntityCreatedBeforeOrderWasHired) ||
    ['SUCCESS', 'PENDING'].includes(personDispatchEventsQuery.data?.processingStatus ?? '')

  const isDispatchPersonInclusionAllowed = useMemo(() => {
    if (!allowedDispatchPersonInsurers?.payload?.value) {
      return false
    }

    const allowedInsurers = JSON.parse(allowedDispatchPersonInsurers.payload.value)['INCLUSION']

    if (!allowedInsurers) {
      return false
    }

    return allowedInsurers.includes(orderInformationQuery.data?.quotation?.insurer ?? '')
  }, [allowedDispatchPersonInsurers, orderInformationQuery.data?.quotation?.insurer])

  const isDispatchPersonExclusionAllowed = useMemo(() => {
    if (!allowedDispatchPersonInsurers?.payload?.value) {
      return false
    }

    const allowedInsurers = JSON.parse(allowedDispatchPersonInsurers.payload.value)['EXCLUSION']

    if (!allowedInsurers) {
      return false
    }

    return (
      allowedInsurers.includes(orderInformationQuery.data?.quotation?.insurer ?? '') &&
      personDispatchEventsQuery.data?.processingStatus === 'FAILURE'
    )
  }, [allowedDispatchPersonInsurers, orderInformationQuery.data?.quotation?.insurer])

  return (
    <>
      <BlissDynamicForm
        id={entityData?.id ?? ''}
        formData={formDataState}
        schema={schema?.schema ?? {}}
        uiSchema={schema?.uiSchema}
        extraErrors={extraErrors[entityData?.id ?? ''] || undefined}
        disabled={shouldDisableForm}
        formContext={{
          ...schema?.formContext,
          isLoading: isFillOrderInformationPending.length > 0,
          isCnpjFilled: formDataState.document !== null,
          entityType,
          shouldDisableForm,
          isRegisteredInInsurer: orderInformationQuery.data?.isRegisteredInInsurer ?? false,
        }}
        widgets={{
          AutoCompleteWidget,
          OCRCnpjTextInputWidget,
          OCRCnpjDateInputWidget,
          OCRDisabledTextInputWidget,
        }}
        templates={{
          ButtonTemplates: {
            SubmitButton: SubmitButtonTemplate,
          },
        }}
        onChange={({ formData }, fieldId) => {
          removeFromExtraErrors(
            formData.id,
            fieldId
              ?.split('_')
              .filter((part) => !part.includes('root'))
              .join('.'),
          )

          if (entityType === 'COMPANY' && fieldId?.includes('externalLegalRepresentative')) {
            return handleCopyCompanyLegalRepresentativeToContact(formData)
          }

          if (entityType === 'HOLDER' && fieldId?.includes('birthDate')) {
            setIsLegalAge(formData?.birthDate)
          }

          if (['HOLDER', 'DEPENDENT'].includes(entityType) && fieldId?.includes('name')) {
            formData = {
              ...formData,
              gender:
                entityData?.name != formData?.name
                  ? genderPredictionFunction(formData?.name)
                  : formData?.gender,
            }
          }

          const data = Object.entries(formData).reduce((oldData, [key, value]) => {
            return {
              ...oldData,
              [key]: value === '' ? null : value,
            }
          }, formData)

          setFormDataState(data)
        }}
        onSubmit={async (data: Entity) => {
          const payload = parseFillEntityPayloadRequest({
            formData: data,
            entityData,
            entityType,
            isDynamicForm: true,
            financialOfficerId,
            planOptions,
            odontoPlanOptions,
          })

          await fillOrderInformationMutation.mutateAsync({ ...payload, orderId })

          if (isDispatchPersonInclusionAllowed && orderInformationQuery?.data?.status === 'HIRED') {
            let getRequiredFieldsFn =
              getRequiredFieldInsurers[
                orderInformationQuery.data.quotation?.insurer ?? 'default'
              ] ?? getRequiredFieldInsurers.default

            if (orderInformationQuery.data.isRegisteredInInsurer) {
              getRequiredFieldsFn = getRequiredFieldInsurers.default
            }

            const { hasEmptyValues } = getRequiredFieldsFn(
              entityType,
              data,
              orderInformationQuery.data,
            )

            if (!hasEmptyValues) {
              setEntityInclusionDispatchModal({
                isOpen: true,
                origin: orderInformationQuery.data.type === 'PERSON' ? 'INTRANET' : 'AUTOMATION',
              })
            }
          }
        }}
        noValidate
      />

      {isDispatchPersonInclusionAllowed || isDispatchPersonExclusionAllowed ? (
        <DispatchEntityFooter dispatchEvent={personDispatchEventsQuery.data} />
      ) : null}

      <DispatchEntityIncludeEventConfirmationModal
        entity={formDataState}
        orderData={orderInformationQuery.data}
        entityType={entityType}
      />
    </>
  )
}
