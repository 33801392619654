import styled from 'styled-components'

export const LoadingDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;
  justify-content: center;
`

export const LoadingDivTitle = styled.p`
  font-weight: 700;
  font-size: 25px;
  text-align: center;
  color: #4b4b4b;
  margin-bottom: 0;
`

export const LoadingDivSubtitle = styled.p`
  font-size: 16px;
  text-align: center;
  color: #878da1;
`

export const QuotationDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const QuotationTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #4b4b4b;
`

export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const QuotationTags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

export const QuotationTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
  background-color: #f5f6f9;
  border-radius: 10px;
  padding: 0px 14px;
  gap: 4px;

  p {
    color: #878da1;
    font-size: 14px;
  }
`

export const TagTitle = styled.p`
  color: #4b4b4b !important;
  font-weight: 700;
  font-size: 14px;
`

export const SkeletonDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

export const CardsDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  margin-top: 40px;
  margin-left: 28px;
`

export const IconsDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

export const QuotationCardTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: #4b4b4b;
`

export const QuotationCardItem = styled.p`
  color: #878da1;
  font-size: 14px;
  margin: 4px;
`

export const QuotationCardAmountDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
`

export const QuotationCardAmount = styled.p`
  font-weight: 700;
  color: #4b4b4b;
  margin: 4px;
`

export const CreateQuotationCardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  margin-left: 28px;
`

export const CreateQuotationCardTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: #4b4b4b;
`
