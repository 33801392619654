const PetIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill="#8589A2"
        d="M9.786 18.403c-.72.048-1.434.15-2.137.306-.65.13-1.3.265-1.954.37a4.02 4.02 0 01-1.58-.003 2.295 2.295 0 01-1.76-2.41c.067-.627.284-1.23.634-1.756a6.158 6.158 0 011.266-1.446c.535-.427 1.03-.902 1.481-1.419.4-.498.769-1.022 1.147-1.538.316-.467.664-.912 1.04-1.332a3.236 3.236 0 011.805-1.03c.966-.159 2.054.093 2.7 1.118.34.541.66 1.095.993 1.64a14.247 14.247 0 002.446 2.996c.491.435.888.966 1.167 1.56.392.882.436 1.767-.014 2.645a1.88 1.88 0 01-1.687 1.072 8.031 8.031 0 01-1.773-.171c-.965-.166-1.93-.328-2.897-.487-.291-.048-.585-.077-.877-.115zM8.974 3.126c.595 1.98-.031 3.917-1.398 4.328-1.367.41-2.956-.862-3.55-2.842-.595-1.98.03-3.918 1.397-4.328 1.367-.41 2.957.862 3.551 2.842zM14.592.078c1.365.413 1.987 2.352 1.388 4.33-.599 1.98-2.191 3.248-3.557 2.835-1.366-.413-1.987-2.352-1.388-4.33.598-1.98 2.191-3.248 3.557-2.835zM18.654 6.262c1.137.345 1.655 1.96 1.156 3.607-.499 1.648-1.825 2.705-2.963 2.36-1.137-.343-1.655-1.958-1.156-3.606.499-1.648 1.825-2.705 2.963-2.36zM4.42 8.595c.507 1.691-.028 3.346-1.195 3.697-1.167.35-2.525-.736-3.033-2.428-.508-1.69.027-3.346 1.194-3.697 1.168-.35 2.525.737 3.033 2.428z"
      ></path>
    </svg>
  )
}

export default PetIcon
