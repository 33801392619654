import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'

import type { GetSchemaProps } from '../..'
import { contractTypes } from '../../../helper/amil/contractType'
import { insurersAmil } from '../../../helper/amil/insurers'
import { issuingAgencies } from '../../../helper/amil/issuingAgencies'

export function holderAmilHealthSchema({
  planOptions,
  isOcrFilled,
  genderPrediction,
  enableHiredFields,
}: GetSchemaProps): DynamicFormSchemaProps {
  return {
    formContext: {
      hint: '*Campos de preenchimento obrigatório apenas para a emissão automática e/ou cadastro no sisweb',
    },
    schema: {
      type: 'object',
      properties: {
        name: {
          type: ['string', 'null'],
          title: 'Nome Completo*',
        },
        isPartner: {
          type: 'boolean',
          title: 'Sócio/Cônjuge de sócio da empresa?',
        },
        maritalStatus: {
          type: ['string', 'null'],
          title: 'Estado Civil*',
          oneOf: [
            { title: 'Solteiro', const: 'SINGLE' },
            { title: 'Casado', const: 'MARRIED' },
            { title: 'Divorciado', const: 'DIVORCED' },
            { title: 'Viúvo', const: 'WIDOWED' },
            { title: 'Separado', const: 'SEPARATED' },
            { title: 'Outro', const: 'OTHER' },
          ],
        },
        gender: {
          type: ['string', 'null'],
          title: 'Gênero*',
          oneOf: [
            { title: 'Feminino', const: 'FEMALE' },
            { title: 'Masculino', const: 'MALE' },
          ],
          default: genderPrediction?.toUpperCase() ?? null,
        },
        contractType: {
          type: ['string', 'null'],
          title: 'Tipo de Contratação*',
          oneOf: contractTypes.map((contractType) => ({
            const: contractType.value,
            title: contractType.label,
          })),
        },
        contractDate: {
          type: ['string', 'null'],
          title: 'Data de Contratação*',
          format: 'date',
        },
        cpf: {
          type: ['string', 'null'],
          title: 'CPF*',
        },
        rg: {
          type: ['string', 'null'],
          title: 'RG*',
        },
        issuingAgency: {
          type: ['string', 'null'],
          title: 'Órgão Emissor*',
          oneOf: issuingAgencies.map((agency) => ({
            title: agency.label,
            const: agency.value,
          })),
        },
        birthDate: {
          type: ['string', 'null'],
          title: 'Data de Nascimento*',
          format: 'date',
        },
        phone: {
          type: ['string', 'null'],
          title: 'Telefone*',
        },
        mail: {
          type: ['string', 'null'],
          title: 'E-mail*',
          format: 'email',
        },
        motherName: {
          type: ['string', 'null'],
          title: 'Nome da Mãe*',
        },
        isAdministrativeLife: {
          type: ['boolean', 'null'],
          title: 'É uma vida administrativa?',
          oneOf: [
            { title: 'Sim', const: true },
            { title: 'Não', const: false },
          ],
          default: null,
        },
        height: {
          type: ['string', 'null'],
          title: 'Altura (m)',
        },
        weight: {
          type: ['string', 'null'],
          title: 'Peso (kg)',
        },
        address: {
          type: 'object',
          properties: {
            zipCode: {
              type: ['string', 'null'],
              title: 'CEP*',
            },
            entityAddress: {
              type: ['string', 'null'],
              title: 'Endereço*',
            },
            number: {
              type: ['string', 'null'],
              title: 'Número*',
            },
            complement: {
              type: ['string', 'null'],
              title: 'Complemento',
            },
            neighborhood: {
              type: ['string', 'null'],
              title: 'Bairro',
            },
            city: {
              type: ['string', 'null'],
              title: 'Cidade',
            },
            state: {
              type: ['string', 'null'],
              title: 'Estado',
            },
          },
        },
        healthPlan: {
          type: 'object',
          properties: {
            catalogPlanId: {
              type: ['string', 'null'],
              title: 'Plano Médico*',
              oneOf:
                planOptions && planOptions?.length > 0
                  ? planOptions.map((plan) => ({
                      title: plan.label,
                      const: plan.value,
                    }))
                  : [{ title: '', const: '' }],
            },
            accommodationType: {
              type: ['string', 'null'],
              title: 'Acomodação*',
              oneOf: [
                { title: 'Apartamento', const: 'Apartamento' },
                { title: 'Enfermaria', const: 'Enfermaria' },
                { title: 'Odonto', const: 'Odonto' },
                { title: 'Não se Aplica', const: 'Não se Aplica' },
              ],
            },
            lifeAmount: {
              type: ['string', 'null'],
              title: 'Valor Vida*',
            },
          },
        },
        hiredHealthPlan: {
          type: 'object',
          properties: {
            isActive: {
              type: 'boolean',
              title: 'Possui plano médico?',
              oneOf: [
                { title: 'Sim', const: true },
                { title: 'Não', const: false },
              ],
              default: false,
            },
          },
          dependencies: {
            isActive: {
              oneOf: [
                {
                  properties: {
                    isActive: {
                      enum: [false],
                    },
                  },
                },
                {
                  properties: {
                    isActive: {
                      enum: [true],
                    },
                    hiredHealthSubtitle: {
                      type: ['string', 'null'],
                      title: 'Dados do Plano Médico Atual',
                    },
                    registrationNumber: {
                      type: ['string', 'null'],
                      title: 'Matrícula',
                    },
                    activeHiredPlanInsurer: {
                      type: ['string', 'null'],
                      title: 'Operadora Atual',
                      oneOf: insurersAmil.map((insurer) => ({
                        title: insurer.label,
                        const: insurer.value,
                      })),
                    },
                    hiredAt: {
                      type: ['string', 'null'],
                      format: 'date',
                      title: 'Data de Início',
                    },
                    lastPaymentAt: {
                      type: ['string', 'null'],
                      format: 'date',
                      title: 'Data do Último Pagamento',
                    },
                  },
                },
              ],
            },
          },
        },
        hiredDentalPlan: {
          type: 'object',
          properties: {
            isActive: {
              type: 'boolean',
              title: 'Possui plano dental?',
              oneOf: [
                { title: 'Sim', const: true },
                { title: 'Não', const: false },
              ],
              default: false,
            },
          },
          dependencies: {
            isActive: {
              oneOf: [
                {
                  properties: {
                    isActive: {
                      enum: [false],
                    },
                  },
                },
                {
                  properties: {
                    isActive: {
                      enum: [true],
                    },
                    hiredDentalSubtitle: {
                      type: ['string', 'null'],
                      title: 'Dados do Plano Dental Atual',
                    },
                    activeHiredPlanInsurer: {
                      type: ['string', 'null'],
                      title: 'Operadora Atual',
                      oneOf: insurersAmil.map((insurer) => ({
                        title: insurer.label,
                        const: insurer.value,
                      })),
                    },
                    hiredAt: {
                      type: ['string', 'null'],
                      format: 'date',
                      title: 'Data de Início',
                    },
                    lastPaymentAt: {
                      type: ['string', 'null'],
                      format: 'date',
                      title: 'Data do Último Pagamento',
                    },
                  },
                },
              ],
            },
          },
        },
        isLegalAge: {
          type: 'boolean',
        },
      },
      dependencies: {
        isLegalAge: {
          oneOf: [
            {
              properties: {
                isLegalAge: {
                  enum: [true],
                },
              },
            },
            {
              properties: {
                isLegalAge: {
                  enum: [false],
                },
                financialOfficer: {
                  type: 'object',
                  properties: {
                    financialOfficerSubtitle: {
                      type: 'string',
                      title: 'Responsável Financeiro',
                    },
                    name: {
                      type: 'string',
                      title: 'Nome Completo',
                    },
                    mail: {
                      type: 'string',
                      title: 'E-mail',
                      format: 'email',
                    },
                    phone: {
                      type: 'string',
                      title: 'Telefone',
                    },
                    cpf: {
                      type: 'string',
                      title: 'CPF*',
                    },
                    birthDate: {
                      type: 'string',
                      title: 'Data Nascimento',
                      format: 'date',
                    },
                  },
                },
              },
            },
          ],
        },
      },
    },
    uiSchema: {
      name: {
        'ui:placeholder': 'Ex.: Maria da Silva',
        'ui:disabled': isOcrFilled,
        'ui:widget': 'OCRDisabledTextInputWidget',
      },
      maritalStatus: {
        'ui:placeholder': 'Selecione o estado civil',
      },
      gender: {
        'ui:placeholder': 'Selecione o gênero',
      },
      contractType: {
        'ui:placeholder': 'Selecione o tipo',
      },
      contractDate: {
        'ui:placeholder': '00/00/0000',
      },
      cpf: {
        'ui:placeholder': '000.000.000-00',
        'ui:options': {
          mask: 'cpf',
        },
        'ui:disabled': isOcrFilled,
        'ui:widget': 'OCRDisabledTextInputWidget',
      },
      rg: {
        'ui:placeholder': '000000000',
        'ui:disabled': isOcrFilled,
        'ui:widget': 'OCRDisabledTextInputWidget',
      },
      issuingAgency: {
        'ui:placeholder': 'Selecione o órgão emissor',
        'ui:disabled': isOcrFilled,
      },
      birthDate: {
        'ui:placeholder': '00/00/0000',
      },
      phone: {
        'ui:placeholder': '(00) 00000 0000',
        'ui:options': {
          mask: 'phone',
        },
      },
      mail: {
        'ui:placeholder': 'exemplo@email.com',
        'ui:widget': 'TextWidget',
      },
      motherName: {
        'ui:placeholder': 'Ex.: Ana da Silva',
        'ui:disabled': isOcrFilled,
        'ui:widget': 'OCRDisabledTextInputWidget',
      },
      isAdministrativeLife: {
        'ui:widget': 'radio',
      },
      address: {
        'ui:options': {
          colSpan: 2,
        },
        zipCode: {
          'ui:placeholder': '00000-000',
          'ui:disabled': isOcrFilled,
          'ui:options': {
            mask: 'cep',
          },
        },
        entityAddress: {
          'ui:placeholder': 'Buscar endereço',
          'ui:disabled': isOcrFilled,
          'ui:widget': 'AutoCompleteWidget',
        },
        number: {
          'ui:placeholder': '000',
          'ui:disabled': isOcrFilled,
        },
        complement: {
          'ui:placeholder': 'Ex.: Apto 123',
        },
        neighborhood: {
          'ui:placeholder': 'Ex.: Pinheiros',
          'ui:disabled': isOcrFilled,
        },
        city: {
          'ui:placeholder': 'Ex.: São Paulo',
          'ui:disabled': isOcrFilled,
        },
        state: {
          'ui:disabled': isOcrFilled,
          'ui:placeholder': 'Ex.: SP',
        },
      },
      height: {
        'ui:placeholder': 'Ex.: 1.76m',
        'ui:options': {
          mask: 'height',
        },
        'ui:widget': enableHiredFields ? 'TextWidget' : 'hidden',
      },
      weight: {
        'ui:placeholder': 'Ex.: 86.60kg',
        'ui:options': {
          mask: 'weight',
        },
        'ui:widget': enableHiredFields ? 'TextWidget' : 'hidden',
      },
      healthPlan: {
        'ui:options': {
          colSpan: 2,
        },
        catalogPlanId: {
          'ui:options': {
            colSpan: 2,
          },
          'ui:placeholder': 'Selecione o plano',
          'ui:disabled': !planOptions || (planOptions && planOptions?.length === 0),
        },
        accommodationType: {
          'ui:placeholder': 'Selecione a acomodação',
        },
        lifeAmount: {
          'ui:placeholder': 'R$ 00,00',
          'ui:options': {
            mask: 'money',
          },
        },
      },
      hiredHealthPlan: {
        'ui:options': {
          colSpan: 2,
        },
        isActive: {
          'ui:widget': 'radio',
          'ui:options': {
            colSpan: 2,
          },
        },
        hiredHealthSubtitle: {
          'ui:widget': 'SubtitleWidget',
        },
        hiredDentalSubtitle: {
          'ui:widget': 'SubtitleWidget',
        },
        registrationNumber: {
          'ui:placeholder': 'Nº de matrícula',
        },
        activeHiredPlanInsurer: {
          'ui:placeholder': 'Selecione a operadora atual',
        },
        hiredAt: {
          'ui:placeholder': '00/00/0000',
        },
        lastPaymentAt: {
          'ui:placeholder': '00/00/0000',
        },
      },
      hiredDentalPlan: {
        'ui:options': {
          colSpan: 2,
        },
        isActive: {
          'ui:widget': 'radio',
          'ui:options': {
            colSpan: 2,
          },
        },
        hiredDentalSubtitle: {
          'ui:widget': 'SubtitleWidget',
        },
        activeHiredPlanInsurer: {
          'ui:placeholder': 'Selecione a operadora atual',
        },
        hiredAt: {
          'ui:placeholder': '00/00/0000',
        },
        lastPaymentAt: {
          'ui:placeholder': '00/00/0000',
        },
      },
      emptyColumn1: {
        'ui:widget': 'hidden',
      },
      financialOfficer: {
        'ui:options': {
          colSpan: 2,
        },
        financialOfficerSubtitle: {
          'ui:widget': 'SubtitleWidget',
        },
        name: {
          'ui:placeholder': 'Ex.: Maria Silva',
          'ui:options': {
            colSpan: 2,
          },
        },
        mail: {
          'ui:placeholder': 'exemplo@email.com.br',
          'ui:widget': 'TextWidget',
        },
        phone: {
          'ui:placeholder': '(00) 00000 0000',
          'ui:options': {
            mask: 'phone',
          },
        },
        cpf: {
          'ui:placeholder': '000.000.000-00',
          'ui:options': {
            mask: 'cpf',
          },
        },
        birthDate: {
          'ui:placeholder': '00/00/0000',
        },
      },
      isLegalAge: {
        'ui:widget': 'hidden',
      },
    },
  }
}
