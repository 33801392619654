import { colors } from '@design-system/styles/theme'
import styled from 'styled-components'

export interface AccordionContentProps {
  backgroundColor?: string | null
}
export interface AccordionChildrenDivProps {
  backgroundColor?: string | null
}

export const WarningMessageStyled = styled.div`
  display: flex;
  font-size: 0.9rem;
  margin-bottom: 15px;
  color: ${colors.secundaryText};
`

export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

export const AccordionHeaderInner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  justify-content: space-between;
`

export const AccordionHeaderTitleDiv = styled.div`
  display: flex;
  align-items: center;
`

export const AccordionHeaderActionsDiv = styled.div`
  display: flex;
  width: 15%;
  margin-right: 10px;
`

export const AccordionHeaderIcon = styled.div`
  width: 30px;
  padding-right: 10px;
  text-align: center;
  color: #9194ab;
  font-weight: 700;
`
export const AccordionTitle = styled.p`
  color: #4b4b4b;
  font-weight: 500;
  font-size: 15px;
`

export const AccordionSubtitle = styled.p`
  width: 280px;
  color: #878da1;
  font-weight: 500;
  font-size: 14px;
  text-align: start;
  margin-left: 25px;
`

export const AccordionContent = styled.div<AccordionContentProps>`
  padding: 20px;
  background-color: ${(props) => props.backgroundColor ?? '#ffffff'};
  justify-content: center;
  height: 100%;
`

export const AccordionChildrenDiv = styled.div<AccordionChildrenDivProps>`
  padding: 20px;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor ?? '#ffffff'};
`

export const TabsLabel = styled.p`
  font-size: 14px;
`
export const SaveButtonDiv = styled.div`
  display: flex;
  width: 100px;
`

export const HolderGroupDiv = styled.div`
  display: flex;
  flex-direction: column;
`

export const CreateOrderHolderDependentButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #b7c8d5;
  border-radius: 10px;
  padding: 5px;
  height: 45px;

  cursor: pointer;

  :hover:not(:disabled) {
    border: 2px solid ${colors.primary};
  }

  :disabled {
    cursor: not-allowed;
    background-color: #f0f1f4;
  }
`

export const CreateOrderHolderDependentButtonText = styled.p`
  font-size: 15px;
  margin-left: 15px;
`
export const DocumentActionButtonDiv = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 15px;
  width: 100%;
`

export const DownloadDocumentsButtonDiv = styled.div`
  width: 150px;

  p {
    margin-left: 5px;
  }
`
export const UploadFilesLoadingDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`

export const WarningMessage = styled.p`
  font-size: 14px;
  color: #bf404c;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 16rem;
`

export const PersonDispatchEventContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 24px;
  gap: 6px;
`

export const PersonDispatchEventText = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  font-family: 'Manrope', sans-serif;
  color: #878da1;
`
