import { useMutation } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '@/App/clients/http'

import { ExternalPerson } from '../dtos'

export type FillExternalBrokerInformationData = {
  data: ExternalPerson
  orderId?: string | null
}

const fillExternalBrokerInformation = async ({
  data,
  orderId,
}: FillExternalBrokerInformationData) => {
  const response = await api.patch(
    `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/orders/${orderId}/external-brokers`,
    { ...data },
    {
      headers: { Authorization: localStorage.getItem('authToken') },
    },
  )
  return response.data
}

export const useFillExternalBrokerInformation = (
  fillExternalBrokerInformationData?: FillExternalBrokerInformationData,
) => {
  const orderId = fillExternalBrokerInformationData?.orderId

  return useMutation({
    mutationKey: ['externalBroker', orderId],
    mutationFn: fillExternalBrokerInformation,
    onSuccess: () => {
      triggerToast(true, 'Vendedor externo atualizado com sucesso!')
    },
    onError: () => {
      triggerToast(false, 'Ops, ocorreu um erro ao atualizar as informações.')
    },
  })
}
