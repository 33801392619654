export const kinshipsBradesco = [
  { label: 'Cônjuge', value: 'SPOUSE' },
  { label: 'Filho', value: 'CHILDREN' },
  { label: 'Neto(a)', value: 'GRANDSON' },
  { label: 'Irmão', value: 'SIBLINGS' },
  { label: 'Sobrinho(a)', value: 'NEPHEWS' },
  { label: 'Genro', value: 'SON-IN-LAW' },
  { label: 'Nora', value: 'DAUGHTER-IN-LAW' },
  { label: 'Enteado(a)', value: 'STEPCHILDREN' },
  { label: 'Tutelado', value: 'WARD' },
]
