import styled, { keyframes } from 'styled-components'
import { Collapse as Accordion } from 'antd'
const { Panel } = Accordion

interface StyledKanbanProps {
  color?: string
  isInactive?: boolean
}

export const ContainerColumn = styled.div`
  background-color: #fafafa;
  border-radius: 10px;
  overflow: inherit;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 21%;
  min-width: 15%;
  height: 90vh;
`
export const StyledInfiniteScroll = styled.div`
  margin-bottom: 50px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0.5em;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`
export const StickyTitleContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`

export const StyledTitleColumn = styled.div<StyledKanbanProps>`
  align-items: center;
  background-color: ${(props) => (props.color ? props.color : '#057bdd')};
  border-radius: 10px;
  color: white;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  text-align: center;
`
export const CardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const ContainerColumnMobile = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

export const StyledCollapse = styled(Accordion)<StyledKanbanProps>`
  &&& {
    border-radius: 10px;
    margin: 0 15px;
  }

  .ant-collapse-item {
    border-radius: 10px;
    :last-child {
      border-radius: 10px;
    }
  }

  .ant-collapse-item-active {
    background-color: #f2f3f7;
    border: none;
    border-radius: 10px;
  }

  &&& .ant-collapse-header {
    border: 1.5px solid #dcdfeb;
    border-radius: 10px;
  }

  .ant-collapse-header-text {
    color: #4b4b4b;
    font-weight: 600;
  }
`
export const StyledPanel = styled(Panel)<StyledKanbanProps>`
  margin-bottom: 10px;

  .ant-collapse-content-box {
    background-color: white;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 160px;
    overflow: auto;
  }
`
export const SkeletonWrapper = styled.div`
  margin-bottom: 10px;
`

export const StyledButtonRefresh = styled.div`
  display: flex;
  justify-content: center;
  height: 25px;
  align-items: center;
  column-gap: 8px;
`

export const StyledIconButton = styled.div`
  color: #9399ab;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-bottom: 2px;
  font-weight: 600;
`

export const StyledTextButtonRefresh = styled.div`
  color: #4b4b4b;
  font-size: 12px;
  font-weight: 700;
  text-decoration: underline;
`

const skeletonLoadingAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
  `

export const SkeletonCard = styled.div`
  width: 100%;
  height: 130px;
  background: linear-gradient(90deg, #eeeeee 25%, #dedede 50%, #eeeeee 75%);
  background-size: 200% 100%;
  animation: ${skeletonLoadingAnimation} 2s infinite ease-in-out;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
`
