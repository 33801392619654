import { useEffect, useState } from 'react'

import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { CheckBox, SearchInput, Spinner } from 'design-system/components'

import { useFiltersBrokersQuery } from '@/App/clients/healthPlansOrders/queries/filtersBrokers'

import { Action } from '../../pages/brokers'
import { LoadingDiv, SearchInputWrapper, SiswebDiv, SiswebFilterContent } from './style'

interface Props {
  handleRequest: (action: Action, filterValues: [{ key: string; value: string }]) => void
  selectedCodes: string[]
  placeHolder: string
}

export const SiswebFilter = ({ handleRequest, selectedCodes, placeHolder }: Props) => {
  const filtersBrokersQuery = useFiltersBrokersQuery()

  const [filteredSiswebCodes, setFilteredSiswebCodes] = useState(
    filtersBrokersQuery.data?.internalSystemIdsOptions || [],
  )

  useEffect(() => {
    if (filtersBrokersQuery.isSuccess) {
      setFilteredSiswebCodes(filtersBrokersQuery.data.internalSystemIdsOptions)
    }
  }, [filtersBrokersQuery.isSuccess, filtersBrokersQuery.data?.internalSystemIdsOptions])

  const handleChange = (event: CheckboxChangeEvent) => {
    const action = event.target.checked ? Action.AddFilter : Action.RemoveFilter

    handleRequest(action, [{ key: 'internalSystemId', value: event.target.value }])
  }

  return (
    <>
      <SearchInputWrapper>
        <SearchInput
          options={filtersBrokersQuery.data?.internalSystemIdsOptions}
          placeholder={placeHolder}
          onSelect={(_value, selected) =>
            setFilteredSiswebCodes([{ label: selected.label, value: selected.reference }])
          }
          onChange={(value) => {
            if (!value) {
              setFilteredSiswebCodes(filtersBrokersQuery.data?.internalSystemIdsOptions ?? [])
            }
          }}
        />
      </SearchInputWrapper>
      <SiswebDiv>
        {filtersBrokersQuery.isLoading ? (
          <LoadingDiv>
            <Spinner />
          </LoadingDiv>
        ) : (
          filteredSiswebCodes?.map((option, index) => {
            return (
              <SiswebFilterContent key={index}>
                <CheckBox
                  onChange={(e) => handleChange(e)}
                  label={option.label}
                  value={option.value}
                  name="internalSystemId"
                  checked={selectedCodes?.includes(option.value ?? '') ? true : false}
                />
              </SiswebFilterContent>
            )
          })
        )}
      </SiswebDiv>
    </>
  )
}
