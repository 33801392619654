import { QueryFunctionContext, queryOptions } from '@tanstack/react-query'

import { api } from '../../http'
import { CommissionGridPay } from '../dtos'

type GetCommissionGridsPayBaseRequest = {
  filter: string
}

type GetCommissionGridsPayRequest = QueryFunctionContext & GetCommissionGridsPayBaseRequest

async function getCommissionGridsPay({ filter, signal }: GetCommissionGridsPayRequest) {
  return api
    .get<CommissionGridPay[]>(
      `${import.meta.env.VITE_FINOPS_SERVICES_URL}/grade-comissao-pagar${
        filter ? '?$anyField=' + filter : ''
      }`,
      {
        signal,
        headers: { Authorization: localStorage.getItem('authToken') },
      },
    )
    .then((res) => res.data)
}

export function getCommissionGridsPayQueryOptions({ filter }: GetCommissionGridsPayBaseRequest) {
  return queryOptions({
    queryKey: ['commissionGridsPay', { filter }] as const,
    queryFn: async (params) => getCommissionGridsPay({ filter, ...params }),
    refetchOnWindowFocus: false,
  })
}
