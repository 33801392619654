import { useForm } from 'react-hook-form'

import CustomInput from 'design-system/TextInput/TextInput'
import { Button, Toast } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

export interface WaitingPeriodFormData {
  unique_id: number
  procedimentos_alta_complexidade: string
  condicoes_preexistentes: string
  internaces: string
  urgencia_emergencia: string
  parto: string
  administradora: string
  administradora_id: string
  consultas: string
  exames_especiais_complexos: string
  operadora_id: string
  terapias: string
  exames_simples: string
}

const WaitingPeriodForm = (defaultValues) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<WaitingPeriodFormData>({
    mode: 'onBlur',
    defaultValues: defaultValues.defaultValues,
  })

  const onSubmit = async (data: WaitingPeriodFormData) => {
    try {
      const response = await fetch(import.meta.env.VITE_UPDATE_WAITING_PERIOD_API_URL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })

      if (!response.ok) {
        triggerToast(false, `Erro ao enviar dados`)
        throw new Error('Erro ao enviar dados')
      }
      triggerToast(true, `Atualizado com sucesso`)
    } catch (error) {
      console.error('Erro ao enviar dados:', error)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomInput
        name="urgencia_emergencia"
        label="Urgência/Emergência:"
        vertical={true}
        register={{
          ...register('urgencia_emergencia'),
        }}
        error={errors.urgencia_emergencia}
      />

      <CustomInput
        name="consultas"
        label="Consultas:"
        vertical={true}
        register={{
          ...register('consultas'),
        }}
        error={errors.consultas}
      />

      <CustomInput
        name="exames_simples"
        label="Exames Simples:"
        vertical={true}
        register={{
          ...register('exames_simples'),
        }}
        error={errors.exames_simples}
      />

      <CustomInput
        name="exames_especiais_complexos"
        label="Exames Especiais Complexos  (Ex.: Ressonância)	:"
        vertical={true}
        register={{
          ...register('exames_especiais_complexos'),
        }}
        error={errors.exames_especiais_complexos}
      />

      <CustomInput
        name="terapias"
        label="Terapias:"
        vertical={true}
        register={{
          ...register('terapias'),
        }}
        error={errors.terapias}
      />

      <CustomInput
        name="internaces"
        label="Internações:"
        vertical={true}
        register={{
          ...register('internaces'),
        }}
        error={errors.procedimentos_alta_complexidade}
      />

      <CustomInput
        name="procedimentos_alta_complexidade"
        label="Procedimentos Alta Complexidade (PAC):"
        vertical={true}
        register={{
          ...register('procedimentos_alta_complexidade'),
        }}
        error={errors.procedimentos_alta_complexidade}
      />

      <CustomInput
        name="parto"
        label="Parto:"
        vertical={true}
        register={{
          ...register('parto'),
        }}
        error={errors.parto}
      />

      <CustomInput
        name="condicoes_preexistentes"
        label="Condições Preexistentes:"
        vertical={true}
        register={{
          ...register('condicoes_preexistentes'),
        }}
        error={errors.procedimentos_alta_complexidade}
      />

      <CustomInput
        name="administradora"
        label="administradora:"
        vertical={true}
        register={{
          ...register('administradora'),
        }}
        error={errors.administradora}
      />

      <Button htmlType="submit" style={{ marginTop: '1rem' }}>
        Salvar Alterações
      </Button>

      <Toast hideProgressBar={true} position="top-right" />
    </form>
  )
}

export default WaitingPeriodForm
