/* eslint-disable react/prop-types */
import { ReactNode, useEffect, useRef, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import dayjs from 'dayjs'
import { DatePicker, Radio, Select, TextInput } from 'design-system/components'
import { insurers } from 'design-system/insurers'
import { triggerToast } from 'design-system/triggers'

import { healthPlansOrderRequest } from '@/App/clients/healthPlansOrder'
import { FillLeadInformationDto } from '@/App/clients/healthPlansOrder/dto'
import useFetch from '@/App/clients/http'
import { clearInputMask } from '@/App/utils/string'

import {
  DatePickerContent,
  FormLeadDataContainer,
  LeadDataColumn,
  MainContainerLeadData,
  RadioPlanLabel,
  WrapperFullWidth,
} from './style'

interface ILead extends FieldValues {
  id: string
  externalId: string
  name: string
  phone: string
  mail: string
  returnDate?: string
  hasActiveHiredPlan: boolean | string
  activeHiredPlanInsurer?: string
  status: string
  origin: string
  createdAt: string
  updatedAt: string
}
interface ILeadData {
  brokerId?: string
  lead?: ILead | null
  planCard?: ReactNode
}

export const LeadDataTab: React.FC<ILeadData> = ({ brokerId, lead, planCard }) => {
  const {
    register,
    formState: { errors },
    watch,
    control,
  } = useForm<ILead>({
    mode: 'onChange',
    delayError: 1000,
    defaultValues: lead ?? undefined,
  })

  const formValues = watch()

  const [healthProvider, setHealthProvider] = useState<Array<{ label: string; value: string }>>([])

  const { setRequestConfig, isLoading, error } = useFetch<FillLeadInformationDto>()

  const previousFormValues = useRef(formValues)

  useEffect(() => {
    if (error) triggerToast(false, 'Ops, ocorreu um erro ao atualizar o campo')
  }, [error])

  useEffect(() => {
    const mapperInsurers = insurers.map((item) => ({
      label: item.value,
      value: item.value,
    }))
    setHealthProvider(mapperInsurers)
  }, [])

  useEffect(() => {
    const sendData = setTimeout(() => {
      if (!isLoading && JSON.stringify(formValues) !== JSON.stringify(previousFormValues.current)) {
        const updatedField = Object.keys(formValues).find((key) => {
          return JSON.stringify(formValues[key]) !== JSON.stringify(previousFormValues.current[key])
        })

        if (updatedField) {
          const updatedValue = formValues[updatedField]
          const updatedFormData = {
            [updatedField]: updatedValue,
          }

          if (updatedField === 'returnDate') {
            updatedFormData.returnDate = dayjs(updatedValue).format('YYYY-MM-DD')
          } else if (updatedField === 'phone') {
            updatedFormData.phone = clearInputMask(updatedValue)
          } else if (updatedField === 'hasActiveHiredPlan') {
            updatedFormData.hasActiveHiredPlan = updatedValue === 'true'
          }

          if (brokerId && lead?.id) {
            const fillLeadInformationRequestConfig = healthPlansOrderRequest.fillLeadInformation(
              brokerId,
              lead.id,
              updatedFormData,
            )
            setRequestConfig(fillLeadInformationRequestConfig)
          }
        }
      }
      previousFormValues.current = formValues
    }, 2000)
    return () => clearTimeout(sendData)
  }, [formValues, isLoading])

  return (
    <MainContainerLeadData>
      <FormLeadDataContainer>
        <TextInput
          labelSize="13px"
          labelWeight="700"
          name="name"
          placeholder="nome"
          label="Nome do lead*"
          vertical
          register={{
            ...register('name', {
              required: 'Este campo é obrigatório.',
            }),
          }}
          error={errors.name}
        />
        <LeadDataColumn>
          <TextInput
            labelSize="13px"
            labelWeight="700"
            name="mail"
            register={{
              ...register('mail'),
            }}
            placeholder="exemplo@saudebliss.com.br"
            label="E-mail"
            vertical
            error={errors.mail}
          />

          <TextInput
            labelSize="13px"
            labelWeight="700"
            name="phone"
            placeholder="(00) 00000 0000"
            label="Telefone*"
            vertical
            mask="phone"
            register={{
              ...register('phone', {
                required: 'Este campo é obrigatório.',
                minLength: { value: 16, message: 'Este campo deve ter no mínimo 10 caracteres.' },
                maxLength: { value: 16, message: 'Este campo deve ter no máximo 10 caracteres.' },
              }),
            }}
            error={errors.phone}
          />
        </LeadDataColumn>
        <LeadDataColumn>
          <WrapperFullWidth>
            <RadioPlanLabel>Possui plano ?</RadioPlanLabel>
            <Radio
              options={[
                { label: 'Sim', value: 'true' },
                { label: 'Não', value: 'false' },
              ]}
              name="hasActiveHiredPlan"
              register={{
                ...register('hasActiveHiredPlan'),
              }}
              defaultValue={lead?.hasActiveHiredPlan?.toString()}
              error={errors?.hasActiveHiredPlan}
            />
          </WrapperFullWidth>
          <WrapperFullWidth>
            <Select
              name="activeHiredPlanInsurer"
              control={control}
              label="Se sim, qual a operadora atual?"
              defaultValue={lead?.activeHiredPlanInsurer}
              options={healthProvider}
              showSearch
            />
          </WrapperFullWidth>
        </LeadDataColumn>
        <LeadDataColumn>
          <WrapperFullWidth>
            <DatePickerContent>
              <DatePicker
                label="Data de retorno"
                name="returnDate"
                rules={{ required: 'Este campo é obrigatório' }}
                control={control}
              />
            </DatePickerContent>
          </WrapperFullWidth>
          <TextInput
            labelSize="13px"
            labelWeight="700"
            name="cep"
            register={{
              ...register('cep'),
            }}
            mask="cep"
            placeholder="00000-000"
            label="Cep para novo plano"
            vertical
          />
        </LeadDataColumn>
      </FormLeadDataContainer>
      {planCard}
    </MainContainerLeadData>
  )
}
