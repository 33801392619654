import styled from 'styled-components'

export const RedMandatoryFlag = styled.span`
  color: red;
  margin: 0 12px 0 4px;
`

export const AdditionalInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
`

export const ButtonContainer = styled.div`
  width: 300px;
  margin: 12px auto;
`

export const DatePickerLabel = styled.span`
  margin: auto 4px;
  color: rgb(108, 120, 149);
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`

export const ErrorLabel = styled.span`
  margin: 0 0 8px auto;
  color: red;
`
