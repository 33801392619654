import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import styled from 'styled-components'

export const AutomationsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  heigh: 100%;
  margin-bottom: 50px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PageTitle = styled.p`
  font-size: 30px;
  margin-bottom: 0;
`

export const PageSubtitle = styled.p`
  font-size: 15px;
`

export const InsurersDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

export const InsurersTitle = styled.p`
  font-size: 20px;
  margin-bottom: 0;
`

export const InsurerCardsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 870px;
  gap: 20px;
  margin-bottom: 100px;
`

export const InsurerCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90px;
  width: 270px;
  box-shadow: 0px 2px 10px rgba(17, 19, 47, 0.25);
  border-radius: 10px;
`

export const InsurerCardTitle = styled.p`
  font-size: 18px;
  margin-bottom: 5px;
`

export const ColoredCheckOutlined = styled(CheckOutlined)`
  color: green;
  font-size: 20px;
`

export const ColoredCloseOutlined = styled(CloseOutlined)`
  color: red;
  font-size: 20px;
`

export const TabsDiv = styled.div`
  width: 80%;
`
