import styled from 'styled-components'

import { colors } from '../../styles/theme'

export interface StyleTextInput {
  iconColor?: string
  textInputColor?: string
  vertical?: boolean
  labelColor?: string
  labelWeight?: string
  labelSize?: string
  iconCursorPointer?: boolean
  disabled?: boolean
  isError?: boolean
}

export const BoxInputValidation = styled.div<StyleTextInput>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

export const DivInput = styled.div<StyleTextInput>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-radius: 0.4rem;
  border: 1px solid;
  border-color: ${(props) => (props.isError ? `${colors.red}` : `#B7C8D5`)};
  */ :focus,
  :hover {
    outline: none;
    border-color: ${colors.secundaryText};
  }
  height: 40px;
  margin-top: 2px;
  background-color: ${colors.white};
`

export const IconInput = styled.div<StyleTextInput>`
  padding: 5px;
  .anticon {
    font-size: 0.875rem;
  }
  color: ${(props) => props.iconColor ?? `${colors.primaryText}`};
  cursor: ${(props) => (props.iconCursorPointer ? 'pointer' : 'default')};
`

export const StyledInput = styled.input<StyleTextInput>`
  width: 100%;
  padding: 5px 0 5px 0;

  border: none;
  color: ${(props) => props.textInputColor ?? ` ${colors.primaryText}`};

  cursor: ${(props) => (props.disabled ? 'not-allowed' : '')};

  ::placeholder {
    color: ${colors.secundaryText};
  }

  :focus,
  :hover {
    outline: none;
    background-color: white;
  }
`

export const Label = styled.label<StyleTextInput>`
  font-family: 'Manrope', sans-serif;
  font-weight: ${(props) => props.labelWeight ?? 500};
  font-size: ${(props) => props.labelSize ?? '1rem'};
  color: ${(props) => props.labelColor ?? `${colors.primaryText}`};
  text-align: ${(props) => (props.vertical ? 'start' : 'end')};
  margin-right: 5px;
`

export const ContainerStyled = styled.div<StyleTextInput>`
  display: flex;
  flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
  align-items: start;
  width: 100%;
  justify-content: center;
`
