import styled from 'styled-components'

export const FilesContainer = styled.div`
  max-height: 350px;
  overflow-y: scroll;
  padding-right: 15px;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dcdfeb;
    border-radius: 10px;
  }
`
