const PlusIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21">
      <path
        fill="#8589A2"
        d="M11.542 5.292H9.458v4.166H5.292v2.084h4.166v4.166h2.084v-4.166h4.166V9.458h-4.166V5.292zM10.5.083C4.75.083.083 4.75.083 10.5S4.75 20.917 10.5 20.917 20.917 16.25 20.917 10.5 16.25.083 10.5.083zm0 18.75c-4.594 0-8.333-3.74-8.333-8.333 0-4.594 3.74-8.333 8.333-8.333 4.594 0 8.333 3.74 8.333 8.333 0 4.594-3.74 8.333-8.333 8.333z"
      ></path>
    </svg>
  )
}

export default PlusIcon
