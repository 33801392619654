import { colors } from '@design-system/styles/theme'
import styled from 'styled-components'

export const OrderDiv = styled.main`
  display: flex;
  flex-direction: column;
  width: 70%;
`

export const OrderHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
`

export const OrderHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  max-height: 80px;
`

export const OrderTagsDiv = styled.div`
  display: flex;
  column-gap: 10px;
`

export const OrderStatusSelectLoadingDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
`
export const OrderStatusSelect = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  width: 270px;

  .ant-picker {
    width: 100% !important;
    height: 41px !important;
  }
`

export const OrderTag = styled.div`
  background-color: #f5f6f9;
  border-radius: 10px;
  color: #878da1;
  align-items: center;
  display: flex;
  padding: 5px;

  span {
    color: ${colors.primaryText};
    display: inline;
    font-weight: 700;
    margin-right: 5px;
  }
`

export const OrderContentDiv = styled.div`
  display: flex;
  margin-bottom: 30px;
  margin-top: 30px;
`

export const OrderContentColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 25px;
`

export const StyledTitle = styled.p`
  color: ${colors.secundaryText};
`

export const StyledIcon = styled.div`
  svg {
    width: 10px;
    height: 10px;
    color: #9399ab;
    cursor: pointer;
  }
`
export const IconStyled = styled.div`
  width: 30px;
  padding-right: 10px;
  text-align: center;
  color: #9194ab;
  font-weight: 700;
`

export const OptionsHeader = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-left: 100px;
`

export const DivInputRadio = styled.div`
  min-width: 200px;
  display: flex;
`
export const InputIcon = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10px 0 0;
  width: 90%;
  a {
    color: ${colors.darkPurple};
    font-size: 14px;
    width: 10%;
  }

  span {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const StyledOptionLabel = styled.span`
  margin-left: 8px;
`
export const DivSkeleton = styled.div`
  padding: 15px;
`

export const LabelTab = styled.span`
  font-size: 14px;
`

export const ResponsibleContent = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`
export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const SelectContainer = styled.div`
  display: flex;
  width: 251px;
`
export const ResponsibleAdminTag = styled.span`
  align-items: center;
  background: #f5f6f9;
  border-radius: 30px;
  color: #4b4b4b;
  column-gap: 10px;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 15px;
  margin: 5px;
`
export const WithoutResponsibleContent = styled.span`
  align-items: center;
  color: ${colors.red};
  column-gap: 5px;
  display: flex;
  font-size: 13px;
  font-weight: 700;
`

export const StyledDivIcon = styled.div`
  cursor: pointer;
`

export const StyledTitleResponsibleAdmin = styled.div`
  align-items: center;
  color: ${colors.primaryText};
  column-gap: 10px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
`

export const StyledDescriptionText = styled.div`
  color: #878da1;
  font-size: 12px;
  margin-bottom: 20px;
`

export const ResponsibleMainContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
