import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'
import { insurers } from 'design-system/insurers'

import { kinships } from '@/App/helpers/kinships'

import type { GetSchemaProps } from '../..'

export function dependentDefaultDentalSchema({
  enableHiredFields,
}: GetSchemaProps): DynamicFormSchemaProps {
  return {
    formContext: {
      hint: '*Campos de preenchimento obrigatório apenas para a emissão automática e/ou cadastro no sisweb',
    },
    schema: {
      type: 'object',
      properties: {
        name: {
          type: 'string',
          title: 'Nome Completo*',
        },
        phone: {
          type: 'string',
          title: 'Telefone*',
        },
        mail: {
          type: 'string',
          title: 'E-mail',
        },
        maritalStatus: {
          type: 'string',
          title: 'Estado civil',
          oneOf: [
            { title: 'Solteiro', const: 'SINGLE' },
            { title: 'Casado', const: 'MARRIED' },
            { title: 'Divorciado', const: 'DIVORCED' },
            { title: 'Viúvo', const: 'WIDOWED' },
            { title: 'Separado', const: 'SEPARATED' },
            { title: 'União Estável', const: 'COMMON_LAW_MARRIAGE' },
          ],
        },
        gender: {
          type: 'string',
          title: 'Gênero*',
          oneOf: [
            { title: 'Feminino', const: 'FEMALE' },
            { title: 'Masculino', const: 'MALE' },
            { title: 'Não binário', const: 'NON_BINARY' },
          ],
        },
        birthDate: {
          type: 'string',
          title: 'Data Nascimento*',
          format: 'date',
        },
        cpf: {
          type: ['string', 'null'],
          title: 'CPF*',
        },
        rg: {
          type: 'string',
          title: 'RG*',
        },
        motherName: {
          type: 'string',
          title: 'Nome da Mãe*',
        },
        holderKinship: {
          type: 'string',
          title: 'Parentesco',
          oneOf: kinships.map((kinship) => ({
            title: kinship.label,
            const: kinship.value,
          })),
        },
        isAdministrativeLife: {
          type: ['boolean', 'null'],
          title: 'É uma vida administrativa?',
          oneOf: [
            { title: 'Sim', const: true },
            { title: 'Não', const: false },
          ],
          default: null,
        },
        dentalPlan: {
          type: 'object',
          properties: {
            lifeAmount: {
              type: 'string',
              title: 'Valor Vida Dental*',
            },
          },
        },
        hasActiveHiredPlan: {
          type: 'boolean',
          title: 'Possui plano?',
          oneOf: [
            { title: 'Sim', const: true },
            { title: 'Não', const: false },
          ],
          default: false,
        },
        insurer: {
          type: 'string',
        },
      },
      dependencies: {
        hasActiveHiredPlan: {
          oneOf: [
            {
              properties: {
                hasActiveHiredPlan: {
                  enum: [false],
                },
              },
            },
            {
              properties: {
                hasActiveHiredPlan: {
                  enum: [true],
                },
                activeHiredPlanInsurer: {
                  type: ['string', 'null'],
                  title: 'Qual a operadora atual?',
                  oneOf: insurers.map((insurer) => ({
                    title: insurer.label,
                    const: insurer.value,
                  })),
                },
              },
            },
          ],
        },
        insurer: {
          oneOf: [
            {
              properties: {
                insurer: {
                  enum: ['Amil'],
                },
                height: {
                  type: ['string', 'null'],
                  title: 'Altura (m)',
                },
                weight: {
                  type: ['string', 'null'],
                  title: 'Peso (kg)',
                },
              },
            },
          ],
        },
      },
    },
    uiSchema: {
      'ui:order': [
        'name',
        'phone',
        'mail',
        'maritalStatus',
        'gender',
        'birthDate',
        'rg',
        'cpf',
        'motherName',
        'holderKinship',
        'dentalPlan',
        'isAdministrativeLife',
        'height',
        'weight',
        '*',
      ],
      name: {
        'ui:placeholder': 'Ex.: Maria Silva',
      },
      phone: {
        'ui:placeholder': '(00) 00000 0000',
        'ui:options': {
          mask: 'phone',
        },
      },
      mail: {
        'ui:placeholder': 'exemplo@email.com.br',
        'ui:widget': 'TextWidget',
      },
      maritalStatus: {
        'ui:placeholder': 'Selecione o estado civil',
      },
      gender: {
        'ui:placeholder': 'Selecione o gênero',
      },
      birthDate: {
        'ui:placeholder': '00/00/0000',
      },
      cpf: {
        'ui:placeholder': '000.000.000-00',
        'ui:options': {
          mask: 'cpf',
        },
      },
      rg: {
        'ui:placeholder': '000000000',
      },
      motherName: {
        'ui:placeholder': 'Ex.: Ana Silva',
      },
      holderKinship: {
        'ui:placeholder': 'Selecione o grau de parentesco',
      },
      isAdministrativeLife: {
        'ui:widget': 'radio',
      },
      height: {
        'ui:placeholder': 'Ex.: 1.76m',
        'ui:options': {
          mask: 'height',
        },
        'ui:widget': enableHiredFields ? 'TextWidget' : 'hidden',
      },
      weight: {
        'ui:placeholder': 'Ex.: 86.60kg',
        'ui:options': {
          mask: 'weight',
        },
        'ui:widget': enableHiredFields ? 'TextWidget' : 'hidden',
      },
      dentalPlan: {
        lifeAmount: {
          'ui:placeholder': 'R$ 00,00',
          'ui:options': {
            colSpan: 2,
            mask: 'money',
          },
        },
      },
      hasActiveHiredPlan: {
        'ui:widget': 'radio',
      },
      activeHiredPlanInsurer: {
        'ui:placeholder': 'Selecione a operadora atual',
      },
      insurer: {
        'ui:widget': 'hidden',
      },
    },
  }
}
