import React from 'react'

import { Skeleton as AntdSkeleton } from 'antd'
import { SkeletonParagraphProps } from 'antd/es/skeleton/Paragraph'
import { SkeletonTitleProps } from 'antd/es/skeleton/Title'

import { SkeletonAvatar, SkeletonImg, SkeletonInput } from './style'

export interface Props {
  active?: boolean
  avatar?: boolean
  avatarHeight?: string
  avatarWidth?: string
  block?: boolean
  loading?: boolean
  round?: boolean
  size?: 'large' | 'small' | 'default'
  shape?: 'circle' | 'square' | 'round' | 'default'
  type?: 'avatar' | 'button' | 'input' | 'image' | 'default'
  title?: boolean | SkeletonTitleProps
  paragraph?: boolean | SkeletonParagraphProps
}

const Skeleton: React.FC<Props> = ({
  avatarHeight,
  avatarWidth,
  active = true,
  avatar,
  block = true,
  loading = true,
  paragraph,
  round,
  shape,
  size,
  title,
  type = 'default',
}) => {
  return (
    <>
      {(() => {
        switch (type) {
          case 'avatar':
            return (
              <SkeletonAvatar
                avatarHeight={avatarHeight}
                avatarWidth={avatarWidth}
                active={active}
                shape="circle"
                size={size}
              />
            )
          case 'button':
            return <AntdSkeleton.Button active={active} block={block} shape={shape} size={size} />
          case 'input':
            return <SkeletonInput active={active} size={size} />
          case 'image':
            return <SkeletonImg active={active} />
          default:
            return (
              <AntdSkeleton
                active={active}
                avatar={avatar}
                loading={loading}
                paragraph={paragraph}
                round={round}
                title={title}
              />
            )
        }
      })()}
    </>
  )
}

export default Skeleton
