import type { GetSchemaProps } from '..'
import { holderDefaultSchema } from './default'
import { holderDentalSchemas } from './dental'
import { holderHealthSchemas } from './health'
import { holderHealthAndDentalSchemas } from './healthAndDental'

export const getHolderSchema = ({
  orderType,
  insurer,
  product,
  planOptions,
  odontoPlanOptions,
  countries,
  isOcrFilled,
  isRegisterInInsurer,
  genderPrediction,
  isCombinedProposal,
  enableHiredFields,
}: GetSchemaProps) => {
  let schema = holderDefaultSchema

  if (!insurer || (orderType === 'PERSON' && !isCombinedProposal)) {
    return holderDefaultSchema({
      isOcrFilled,
      genderPrediction,
      planOptions,
      product,
      enableHiredFields,
    })
  }

  if (product?.toUpperCase() === 'HEALTH') {
    schema = isRegisterInInsurer
      ? holderHealthSchemas.default
      : holderHealthSchemas[insurer] || holderHealthSchemas.default
  }

  if (product?.toUpperCase() === 'DENTAL') {
    schema = isRegisterInInsurer
      ? holderDentalSchemas.default
      : holderDentalSchemas[insurer] || holderDentalSchemas.default
  }

  if (isCombinedProposal) {
    schema = isRegisterInInsurer
      ? holderHealthAndDentalSchemas.default
      : holderHealthAndDentalSchemas[insurer] || holderHealthAndDentalSchemas.default
  }

  return schema({
    planOptions,
    odontoPlanOptions,
    countries,
    isOcrFilled,
    genderPrediction,
    enableHiredFields,
  })
}
