import { useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import { useInfiniteQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import StatusTag from 'design-system/StatusTag/StatusTag'
import { Skeleton, Table } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { statusMappingExecutionInfiniteQueryOptions } from '@/App/clients/orderInformationProcessing/queries/statusMappingExecutions'
import { capitalizeOnlyFirstLetter } from '@/App/utils/string'

import { InsurerLocale } from '..'
import { PendingStatusMappingTable } from './pendingStatusMappingTable'
import { ReviewStatus } from './statusReview'
import { ContainerRow, StatusMappingDiv, TableSkeletonDiv, Title } from './style'

const statusMappingExecutionsColumns = [
  {
    title: 'Operadora/Administradora',
    dataIndex: 'insurer',
    key: 'insurer',
    render: (insurer: string) => InsurerLocale[insurer],
  },
  {
    title: 'Status na operadora',
    dataIndex: 'externalStatus',
    key: 'externalStatus',
    render: (externalStatus: string) => capitalizeOnlyFirstLetter(externalStatus),
  },
  {
    title: 'Substatus na operadora',
    dataIndex: 'externalSubstatus',
    key: 'externalSubstatus',
    render: (externalSubstatus: string) => externalSubstatus ?? '-',
  },
  {
    title: 'Status anterior Bliss',
    dataIndex: 'previousStatus',
    key: 'previousStatus',
    render: (previousStatus: string) =>
      previousStatus ? <StatusTag status={previousStatus} /> : '-',
  },
  {
    title: 'Status Bliss',
    dataIndex: 'internalStatus',
    key: 'internalStatus',
    render: (internalStatus: string) =>
      internalStatus ? <StatusTag status={internalStatus} /> : '-',
  },
  {
    title: 'Mapeado em',
    dataIndex: 'mappedAt',
    key: 'mappedAt',
    render: (mappedAt: string) => (mappedAt ? dayjs(mappedAt).format('DD/MM/YYYY HH:mm:ss') : '-'),
  },
  {
    title: 'Mapeado por',
    dataIndex: 'mappingRequestorMail',
    key: 'mappingRequestorMail',
    render: (mappingRequestorMail: string) => mappingRequestorMail ?? '-',
  },
  {
    title: 'Identificado em',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: string) => dayjs(createdAt).format('DD/MM/YYYY HH:mm:ss'),
  },
]

export function InsurerAutomationsStatusMapping() {
  const statusMappingExecutionsQuery = useInfiniteQuery(
    statusMappingExecutionInfiniteQueryOptions({
      isMapped: true,
    }),
  )

  useEffect(() => {
    if (statusMappingExecutionsQuery.error)
      triggerToast(false, 'Ops, ocorreu um erro ao carregar os últimos mapeamentos realizados')
  }, [statusMappingExecutionsQuery.error])

  const statusMappingExecutions =
    statusMappingExecutionsQuery.data?.pages.map((obj) => obj.statusMappingExecutions).flat() || []

  return (
    <StatusMappingDiv>
      <ContainerRow>
        <StatusMappingDiv>
          <Title>Mapeamentos pendentes</Title>
          <PendingStatusMappingTable />
        </StatusMappingDiv>

        <StatusMappingDiv>
          <Title>Revisão de status</Title>
          <ReviewStatus />
        </StatusMappingDiv>
      </ContainerRow>

      <Title>Últimos mapeamentos</Title>
      {statusMappingExecutionsQuery.isLoading ? (
        <TableSkeletonDiv>
          <Skeleton />
        </TableSkeletonDiv>
      ) : (
        <InfiniteScroll
          dataLength={statusMappingExecutions?.length}
          next={() => statusMappingExecutionsQuery.fetchNextPage()}
          hasMore={statusMappingExecutionsQuery.hasNextPage}
          scrollableTarget="frameContent"
          scrollThreshold={1}
          loader={null}
        >
          <Table
            loading={statusMappingExecutionsQuery.isLoading}
            columns={statusMappingExecutionsColumns}
            data={statusMappingExecutions}
          />
        </InfiniteScroll>
      )}
    </StatusMappingDiv>
  )
}
