import styled, { css } from 'styled-components'

type FormWrapperProps = {
  colSpan?: string | number
}

export const FormWrapper = styled.div<FormWrapperProps>`
  ${({ colSpan = 1 }) => css`
    fieldset {
      all: unset;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      align-items: center;
      column-gap: 3.75rem;
      grid-column: span ${colSpan.toString()};
    }
  `}
`

type FormItemProps = {
  colSpan?: string | number
}

export const FormItem = styled.div<FormItemProps>`
  ${({ colSpan = 1 }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 6.25rem;
    grid-column: span ${colSpan.toString()};
  `}
`

export const FormItemLabel = styled.label`
  font-weight: 500;
  color: #4b4b4b;
  text-align: start;
  margin: 0;
`

export const HintMessage = styled.div`
  display: flex;
  font-size: 0.9rem;
  margin-bottom: 15px;
  color: #858aa3;
`
