import LabelCheckIcon from '@design-system/icons/labelCheck'
import LabelXIcon from '@design-system/icons/labelX'
import { CheckableCards } from 'design-system/components'

import { useCreateOrderContext } from '../order/store'
import {
  CardContent,
  CardSubtitle,
  CardTitle,
  CardsDiv,
  InsurerRegistrationDiv,
  InsurerRegistrationSubtitle,
  InsurerRegistrationTitle,
  InsurerRegistrationTitleDiv,
} from './style'

const InsurerRegistration: React.FC = () => {
  const createOrderContext = useCreateOrderContext()

  return (
    <InsurerRegistrationDiv>
      <InsurerRegistrationTitleDiv>
        <InsurerRegistrationTitle>
          Esta proposta já está cadastrada na operadora?
        </InsurerRegistrationTitle>
        <InsurerRegistrationSubtitle>
          Selecione se a proposta já foi cadastrada, seja pela Bliss ou pelo corretor.
        </InsurerRegistrationSubtitle>
      </InsurerRegistrationTitleDiv>

      {
        <CardsDiv>
          <CheckableCards
            cards={[
              {
                reference: '0',
                children: (
                  <CardContent>
                    <LabelCheckIcon />
                    <CardTitle>Sim</CardTitle>
                    <CardSubtitle>Proposta já cadastrada na operadora</CardSubtitle>
                  </CardContent>
                ),
              },
              {
                reference: '1',
                children: (
                  <CardContent>
                    <LabelXIcon />
                    <CardTitle>Não</CardTitle>
                    <CardSubtitle>Não foi cadastrada na operadora ainda</CardSubtitle>
                  </CardContent>
                ),
              },
            ]}
            defaultCheckedReference={createOrderContext.isRegisteredInInsurer ? '0' : '1'}
            onSelect={(reference) => createOrderContext.setIsRegisteredInInsurer(reference === '0')}
          />
        </CardsDiv>
      }
    </InsurerRegistrationDiv>
  )
}

export default InsurerRegistration
