export const insurersAmil = [
  { label: 'A LA SANTE', value: 'A LA SANTE' },
  {
    label: 'A.I.S. - ASSISTENCIA ODONTOLOGICA REUNIDA S/S LTDA',
    value: 'A.I.S. - ASSISTENCIA ODONTOLOGICA REUNIDA S/S LTDA',
  },
  { label: 'A.P.S. SAUDE.', value: 'A.P.S. SAUDE.' },
  { label: 'ABAS 15', value: 'ABAS 15' },
  {
    label: 'ABBESS - ASSOCIAÇAO BRASILEIRA DE BENEFICIOS E SEGURIDADE SOCIAL',
    value: 'ABBESS - ASSOCIAÇAO BRASILEIRA DE BENEFICIOS E SEGURIDADE SOCIAL',
  },
  { label: 'ABC ASSISTENCIAL', value: 'ABC ASSISTENCIAL' },
  { label: 'ABEB', value: 'ABEB' },
  { label: 'ABET', value: 'ABET' },
  { label: 'ABS ASSISTENCIA BUCAL SERVIÇOS', value: 'ABS ASSISTENCIA BUCAL SERVIÇOS' },
  { label: 'ABSOLUTA BENEFICIOS', value: 'ABSOLUTA BENEFICIOS' },
  { label: 'ABT SAUDE', value: 'ABT SAUDE' },
  { label: 'ADMISA ASSISTENCIA ODONTOLOGICA LTDA', value: 'ADMISA ASSISTENCIA ODONTOLOGICA LTDA' },
  { label: 'ADMIX ADMINISTRADORA', value: 'ADMIX ADMINISTRADORA' },
  {
    label: 'ADMPLAN ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'ADMPLAN ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  {
    label: 'ADPLAN ADMINISTRAÇAO DE PLANOS DE SAUDE',
    value: 'ADPLAN ADMINISTRAÇAO DE PLANOS DE SAUDE',
  },
  { label: 'ADVANCE PLANOS DE SAUDE LTDA I', value: 'ADVANCE PLANOS DE SAUDE LTDA I' },
  { label: 'AESP ODONTO', value: 'AESP ODONTO' },
  { label: 'AETNA INTERNATIONAL HEALTH INSURANCE', value: 'AETNA INTERNATIONAL HEALTH INSURANCE' },
  { label: 'AFFEAM SAUDE', value: 'AFFEAM SAUDE' },
  { label: 'AFFEGO-SAUDE', value: 'AFFEGO-SAUDE' },
  { label: 'AFFEMAT', value: 'AFFEMAT' },
  { label: 'AFFIX BENEFICIOS', value: 'AFFIX BENEFICIOS' },
  { label: 'AFISVEC - SAUDE', value: 'AFISVEC - SAUDE' },
  { label: 'AFPERGS', value: 'AFPERGS' },
  { label: 'AFPES', value: 'AFPES' },
  { label: 'AFRAFEP - SAUDE', value: 'AFRAFEP - SAUDE' },
  { label: 'AGEMED', value: 'AGEMED' },
  { label: 'AGMP', value: 'AGMP' },
  {
    label: 'AGROS - INSTITUTO UFV DE SEGURIDADE SOCIAL',
    value: 'AGROS - INSTITUTO UFV DE SEGURIDADE SOCIAL',
  },
  {
    label: 'ALACORÔ CASF ASSOCIAÇAO CLUBE DE BENEFICIOS PESSOAIS E EMPRESARIAIS',
    value: 'ALACORÔ CASF ASSOCIAÇAO CLUBE DE BENEFICIOS PESSOAIS E EMPRESARIAIS',
  },
  {
    label: 'ALBUQUERQUE ADMINISTRAÇAO DE BENEFICIOS S/C LTDA.',
    value: 'ALBUQUERQUE ADMINISTRAÇAO DE BENEFICIOS S/C LTDA.',
  },
  { label: 'ALCANCE SAUDE', value: 'ALCANCE SAUDE' },
  { label: 'ALFADENTE ODONTOLOGIA INTEGRAL', value: 'ALFADENTE ODONTOLOGIA INTEGRAL' },
  { label: 'ALIANÇA ADMINISTRADORA', value: 'ALIANÇA ADMINISTRADORA' },
  { label: 'ALICE', value: 'ALICE' },
  { label: 'ALL SAUDE I', value: 'ALL SAUDE I' },
  { label: 'ALLIANZ SAUDE S/A', value: 'ALLIANZ SAUDE S/A' },
  { label: 'ALVORECER', value: 'ALVORECER' },
  { label: 'AMAFRE RJ', value: 'AMAFRE RJ' },
  { label: 'AMAFRE SP', value: 'AMAFRE SP' },
  { label: 'AMAGIS SAUDE', value: 'AMAGIS SAUDE' },
  { label: 'AMAZONAS ENERGIA', value: 'AMAZONAS ENERGIA' },
  { label: 'AME-SISTEMA DE SAUDE 24 HORAS', value: 'AME-SISTEMA DE SAUDE 24 HORAS' },
  {
    label: 'AMEDI ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'AMEDI ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'AMENO II', value: 'AMENO II' },
  {
    label: 'AMEP FREGUESIA OPERADORA DE PLANOS DE SAUDE LTDA',
    value: 'AMEP FREGUESIA OPERADORA DE PLANOS DE SAUDE LTDA',
  },
  {
    label: 'AMEPLAN ASSITENCIA MEDICA PLANEJADA LTDA.',
    value: 'AMEPLAN ASSITENCIA MEDICA PLANEJADA LTDA.',
  },
  { label: 'AMERICA PLANOS DE SAUDE', value: 'AMERICA PLANOS DE SAUDE' },
  { label: 'AMERON', value: 'AMERON' },
  { label: 'AMHA', value: 'AMHA' },
  { label: 'AMHA SAUDE SA', value: 'AMHA SAUDE SA' },
  { label: 'AMHE MED', value: 'AMHE MED' },
  { label: 'AMHISA SAUDE', value: 'AMHISA SAUDE' },
  { label: 'AMHPLA', value: 'AMHPLA' },
  {
    label: 'AMI - ASSISTENCIA MEDICA INFANTIL LTDA',
    value: 'AMI - ASSISTENCIA MEDICA INFANTIL LTDA',
  },
  {
    label: 'AMIL ASSISTENCIA MEDICA INTERNACIONAL',
    value: 'AMIL ASSISTENCIA MEDICA INTERNACIONAL',
  },
  { label: 'AMIZI PLANOS E CONVENIOS DE SAUDE', value: 'AMIZI PLANOS E CONVENIOS DE SAUDE' },
  { label: 'AMMP SAUDE', value: 'AMMP SAUDE' },
  { label: 'AMPARA', value: 'AMPARA' },
  { label: 'AMPLA PLANOS DE SAUDE LTDA', value: 'AMPLA PLANOS DE SAUDE LTDA' },
  { label: 'AMR - ASSISTENCIA MEDICA REGIONAL I', value: 'AMR - ASSISTENCIA MEDICA REGIONAL I' },
  {
    label: 'AON AFFINITY ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'AON AFFINITY ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'APAS', value: 'APAS' },
  {
    label: 'APAS - ASSOCIAÇAO POLICIAL DE ASSISTECIA A SAUDE SJBVISTA',
    value: 'APAS - ASSOCIAÇAO POLICIAL DE ASSISTECIA A SAUDE SJBVISTA',
  },
  { label: 'APAS - PRESIDENTE PRUDENTE', value: 'APAS - PRESIDENTE PRUDENTE' },
  { label: 'APAS ANDRADINA', value: 'APAS ANDRADINA' },
  {
    label: 'APAS ASSOCIAÇAO POLICIAL DE ASSISTENCIA A SAUDE RIBEIRAO PRETO',
    value: 'APAS ASSOCIAÇAO POLICIAL DE ASSISTENCIA A SAUDE RIBEIRAO PRETO',
  },
  { label: 'APAS BAIXADA SANTISTA', value: 'APAS BAIXADA SANTISTA' },
  { label: 'APAS BAURU', value: 'APAS BAURU' },
  { label: 'APAS DE DRACENA', value: 'APAS DE DRACENA' },
  { label: 'APAS FERNANDOPOLIS', value: 'APAS FERNANDOPOLIS' },
  { label: 'APAS SOROCABA VOTORANTIM', value: 'APAS SOROCABA VOTORANTIM' },
  { label: 'APAS VALE DO RIBEIRA', value: 'APAS VALE DO RIBEIRA' },
  { label: 'APAS- BARRETOS', value: 'APAS- BARRETOS' },
  { label: 'APAS-BOTUCATU', value: 'APAS-BOTUCATU' },
  { label: 'APAS-JAU', value: 'APAS-JAU' },
  { label: 'APAS/MARILIA', value: 'APAS/MARILIA' },
  { label: 'APAS/PRESIDENTE VENCESLAU', value: 'APAS/PRESIDENTE VENCESLAU' },
  {
    label: 'APICE-ODONTO ASSISTENCIA ODONTOLOGICA LTDA.',
    value: 'APICE-ODONTO ASSISTENCIA ODONTOLOGICA LTDA.',
  },
  { label: 'APMP', value: 'APMP' },
  {
    label: 'APO- ASSISTENCIA PERSONALIZADA ODONTOLOGICA',
    value: 'APO- ASSISTENCIA PERSONALIZADA ODONTOLOGICA',
  },
  { label: 'APOMAS', value: 'APOMAS' },
  { label: 'APPAI', value: 'APPAI' },
  { label: 'APS', value: 'APS' },
  { label: 'APUB SAUDE', value: 'APUB SAUDE' },
  {
    label: 'ARAL ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'ARAL ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'ARAÇA CLINICAS SAUDE', value: 'ARAÇA CLINICAS SAUDE' },
  { label: 'ARCELORMITTAL BRASIL S/A', value: 'ARCELORMITTAL BRASIL S/A' },
  { label: 'ARCELORMITTAL TUBARAO COMERCIAL S.A', value: 'ARCELORMITTAL TUBARAO COMERCIAL S.A' },
  { label: 'ARM ODONTOLOGIA', value: 'ARM ODONTOLOGIA' },
  {
    label: 'ASBEN ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'ASBEN ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'ASCAVI', value: 'ASCAVI' },
  { label: 'ASES', value: 'ASES' },
  { label: 'ASFEB', value: 'ASFEB' },
  { label: 'ASFEPA', value: 'ASFEPA' },
  {
    label: 'ASG ODONTOLOGIA SISTEMA DE PREVENCAO ORAL LTDA',
    value: 'ASG ODONTOLOGIA SISTEMA DE PREVENCAO ORAL LTDA',
  },
  { label: 'ASPBMES', value: 'ASPBMES' },
  { label: 'ASSEC', value: 'ASSEC' },
  { label: 'ASSIM SAUDE', value: 'ASSIM SAUDE' },
  { label: 'ASSIMEDICA I', value: 'ASSIMEDICA I' },
  { label: 'ASSIST', value: 'ASSIST' },
  {
    label: 'ASSISTANCE - ADMINISTRADORA DE BENEFICIOS S/S LTDA',
    value: 'ASSISTANCE - ADMINISTRADORA DE BENEFICIOS S/S LTDA',
  },
  { label: 'ASSISTE', value: 'ASSISTE' },
  {
    label: 'ASSOCIACAO BRAS DE SECURIDADE SOCIAL SERVID PUBLICOS FED,EST,MUN CIVIL E MILITAR',
    value: 'ASSOCIACAO BRAS DE SECURIDADE SOCIAL SERVID PUBLICOS FED,EST,MUN CIVIL E MILITAR',
  },
  {
    label: 'ASSOCIAÇAO BENEFICENTE FNV',
    value: 'ASSOCIAÇAO BENEFICENTE FNV',
  },
  {
    label: 'ASSOCIAÇAO DE AJUDA MUTUA PARA INDUSTRIA COMERCIO E SERVIÇOS DO ESTADO DO RJ',
    value: 'ASSOCIAÇAO DE AJUDA MUTUA PARA INDUSTRIA COMERCIO E SERVIÇOS DO ESTADO DO RJ',
  },
  {
    label: 'ASSOCIAÇAO DE BENEFICENCIA E FILANTROPIA SAO CRISTOVAO',
    value: 'ASSOCIAÇAO DE BENEFICENCIA E FILANTROPIA SAO CRISTOVAO',
  },
  { label: 'ASSOCIAÇAO DE SAUDE HOLAMBRA', value: 'ASSOCIAÇAO DE SAUDE HOLAMBRA' },
  { label: 'ASSOCIAÇAO DO FISCO DE ALAGOAS', value: 'ASSOCIAÇAO DO FISCO DE ALAGOAS' },
  {
    label: 'ASSOCIAÇAO DO MINISTERIO PUBLICO DO RIO GRANDE DO SUL',
    value: 'ASSOCIAÇAO DO MINISTERIO PUBLICO DO RIO GRANDE DO SUL',
  },
  {
    label: 'ASSOCIAÇAO EVANGELICA BENEFICENTE DE LONDRINA',
    value: 'ASSOCIAÇAO EVANGELICA BENEFICENTE DE LONDRINA',
  },
  {
    label: 'ASSOCIAÇAO POLICIAL DE ASSISTENCIA A SAUDE DE ARAÇATUBA',
    value: 'ASSOCIAÇAO POLICIAL DE ASSISTENCIA A SAUDE DE ARAÇATUBA',
  },
  { label: 'ASSOCIAÇAO PRO-SAUDE', value: 'ASSOCIAÇAO PRO-SAUDE' },
  { label: 'ASTRA S/A INDUSTRIA E COMERCIO', value: 'ASTRA S/A INDUSTRIA E COMERCIO' },
  { label: 'ATEMDE ODONTO', value: 'ATEMDE ODONTO' },
  {
    label: 'ATIVIA-COOPERATIVA DE SERVIÇOS MEDICOS E HOSPITALARES',
    value: 'ATIVIA-COOPERATIVA DE SERVIÇOS MEDICOS E HOSPITALARES',
  },
  { label: 'ATUAL BENE SAUDE', value: 'ATUAL BENE SAUDE' },
  { label: 'AUSTACLINICAS', value: 'AUSTACLINICAS' },
  { label: 'AVAMP', value: 'AVAMP' },
  { label: 'B.B. SAUDE', value: 'B.B. SAUDE' },
  { label: 'BACCS', value: 'BACCS' },
  { label: 'BAHIAODONTO', value: 'BAHIAODONTO' },
  { label: 'BANCO CENTRAL', value: 'BANCO CENTRAL' },
  { label: 'BANESCAIXA', value: 'BANESCAIXA' },
  { label: 'BARDELLA S/A INDUSTRIAS MECANICAS.', value: 'BARDELLA S/A INDUSTRIAS MECANICAS.' },
  {
    label: 'BARELA CORP ADMINISTRADORA DE BENEFICIOS LTDA.',
    value: 'BARELA CORP ADMINISTRADORA DE BENEFICIOS LTDA.',
  },
  { label: 'BBC ADMINISTRADORA', value: 'BBC ADMINISTRADORA' },
  { label: 'BCI ADMINISTRADORA DE BENEFICIOS', value: 'BCI ADMINISTRADORA DE BENEFICIOS' },
  {
    label: 'BEDOIS SERVIÇOS DE ADMINISTRAÇAO DE BENEFICIOS LTDA.',
    value: 'BEDOIS SERVIÇOS DE ADMINISTRAÇAO DE BENEFICIOS LTDA.',
  },
  { label: 'BELO DENTE', value: 'BELO DENTE' },
  {
    label: 'BEM BENEFICIOS ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'BEM BENEFICIOS ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'BEM SAUDE', value: 'BEM SAUDE' },
  { label: 'BENECAP SAUDE', value: 'BENECAP SAUDE' },
  { label: 'BENEPLAN', value: 'BENEPLAN' },
  { label: 'BENEVIX ADMINISTRADORA', value: 'BENEVIX ADMINISTRADORA' },
  {
    label: 'BENSAUDE PLANO DE ASSISTENCIA MEDICA HOSPITALAR LTDA',
    value: 'BENSAUDE PLANO DE ASSISTENCIA MEDICA HOSPITALAR LTDA',
  },
  { label: 'BEST LIFE BENEFICIOS OU BEST LIFE', value: 'BEST LIFE BENEFICIOS OU BEST LIFE' },
  { label: 'BHTRANS', value: 'BHTRANS' },
  { label: 'BIO SAUDE', value: 'BIO SAUDE' },
  { label: 'BIODENTE LTDA.', value: 'BIODENTE LTDA.' },
  {
    label: 'BIOLIFE SAUDE OPERADORA DE PLANOS DE SAUDE LTDA',
    value: 'BIOLIFE SAUDE OPERADORA DE PLANOS DE SAUDE LTDA',
  },
  { label: 'BIORAL PLANOS DE SAUDE LTDA', value: 'BIORAL PLANOS DE SAUDE LTDA' },
  { label: 'BIOVIDA SAUDE', value: 'BIOVIDA SAUDE' },
  { label: 'BLESSMED', value: 'BLESSMED' },
  { label: 'BOA VISTA ENERGIA', value: 'BOA VISTA ENERGIA' },
  { label: 'BR', value: 'BR' },
  { label: 'BRADESCO SAUDE S/A', value: 'BRADESCO SAUDE S/A' },
  { label: 'BRASIL CLUB', value: 'BRASIL CLUB' },
  { label: 'BRASIL DENTAL', value: 'BRASIL DENTAL' },
  { label: 'BRASILCENTER COMUNICAÇOES LTDA', value: 'BRASILCENTER COMUNICAÇOES LTDA' },
  {
    label: 'BRASILDENTAL OPERADORA DE PLANOS ODONTOLOGICOS S.A.',
    value: 'BRASILDENTAL OPERADORA DE PLANOS ODONTOLOGICOS S.A.',
  },
  { label: 'BRF - BRASIL FOODS S/A', value: 'BRF - BRASIL FOODS S/A' },
  { label: 'BUCAL HELP', value: 'BUCAL HELP' },
  { label: 'BUCAL LIGHT', value: 'BUCAL LIGHT' },
  { label: 'BUPA INSURANCE LIMITED', value: 'BUPA INSURANCE LIMITED' },
  { label: 'C.S. ASSISTANCE', value: 'C.S. ASSISTANCE' },
  { label: 'CAA/DF', value: 'CAA/DF' },
  { label: 'CAARF', value: 'CAARF' },
  { label: 'CABEFI', value: 'CABEFI' },
  { label: 'CABERGS', value: 'CABERGS' },
  { label: 'CABERJ INTEGRAL SAUDE S.A', value: 'CABERJ INTEGRAL SAUDE S.A' },
  { label: 'CABESP I', value: 'CABESP I' },
  { label: 'CAEME', value: 'CAEME' },
  { label: 'CAESAN', value: 'CAESAN' },
  { label: 'CAFAZ', value: 'CAFAZ' },
  { label: 'CAGIPE', value: 'CAGIPE' },
  {
    label: 'CAIXA BENEFICENTE DOS AUXILIARES DO COM. CAFEEIRO DE SANTOS',
    value: 'CAIXA BENEFICENTE DOS AUXILIARES DO COM. CAFEEIRO DE SANTOS',
  },
  { label: 'CAIXA DE ASSISTENCIA - SIM', value: 'CAIXA DE ASSISTENCIA - SIM' },
  {
    label: 'CAIXA DE ASSISTENCIA A SAUDE - CABERJ',
    value: 'CAIXA DE ASSISTENCIA A SAUDE - CABERJ',
  },
  {
    label: 'CAIXA DE ASSISTENCIA A SAUDE DOS EMPREGADOS DA CELG- CELGMED',
    value: 'CAIXA DE ASSISTENCIA A SAUDE DOS EMPREGADOS DA CELG- CELGMED',
  },
  {
    label: 'CAIXA DE ASSISTENCIA DOS FUNCIONARIOS DO BANCO DA AMAZÔNIA - CASF',
    value: 'CAIXA DE ASSISTENCIA DOS FUNCIONARIOS DO BANCO DA AMAZÔNIA - CASF',
  },
  {
    label: 'CAIXA DE ASSISTENCIA DOS MAGISTRADOS DE PERNAMBUCO',
    value: 'CAIXA DE ASSISTENCIA DOS MAGISTRADOS DE PERNAMBUCO',
  },
  { label: 'CAIXA ECONÔMICA FEDERAL', value: 'CAIXA ECONÔMICA FEDERAL' },
  { label: 'CAIXA SEGURADORA SAUDE', value: 'CAIXA SEGURADORA SAUDE' },
  { label: 'CAMARJ', value: 'CAMARJ' },
  { label: 'CAMED SAUDE - AUTOGESTÃO', value: 'CAMED SAUDE - AUTOGESTÃO' },
  { label: 'CAMED VIDA', value: 'CAMED VIDA' },
  { label: 'CAMIM', value: 'CAMIM' },
  {
    label: 'CAMPEA ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'CAMPEA ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'CAPESESP', value: 'CAPESESP' },
  {
    label: 'CARE CLUB ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'CARE CLUB ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'CARE PLUS MEDICINA ASSISTENCIAL', value: 'CARE PLUS MEDICINA ASSISTENCIAL' },
  { label: 'CARTAO GLOBAL SAUDE', value: 'CARTAO GLOBAL SAUDE' },
  {
    label: 'CAS - CONTRATO DE ASSISTENCIA A SAUDE',
    value: 'CAS - CONTRATO DE ASSISTENCIA A SAUDE',
  },
  {
    label: 'CASA DE SAUDE NOSSA SENHORA DE FATIMA .',
    value: 'CASA DE SAUDE NOSSA SENHORA DE FATIMA .',
  },
  { label: 'CASACARESC', value: 'CASACARESC' },
  { label: 'CASE SAUDE', value: 'CASE SAUDE' },
  { label: 'CASEC', value: 'CASEC' },
  { label: 'CASEMBRAPA', value: 'CASEMBRAPA' },
  { label: 'CASSE', value: 'CASSE' },
  { label: 'CASSEB', value: 'CASSEB' },
  { label: 'CASSEMS', value: 'CASSEMS' },
  { label: 'CASSI', value: 'CASSI' },
  { label: 'CASSIND', value: 'CASSIND' },
  { label: 'CASU/UFMG', value: 'CASU/UFMG' },
  { label: 'CASUFES', value: 'CASUFES' },
  { label: 'CAURJ', value: 'CAURJ' },
  { label: 'CAURN II', value: 'CAURN II' },
  {
    label: 'CDA ASSISTENCIA ODONTOLOGICA LTDA-EPP',
    value: 'CDA ASSISTENCIA ODONTOLOGICA LTDA-EPP',
  },
  { label: 'CEDAE SAUDE', value: 'CEDAE SAUDE' },
  { label: 'CEDOM', value: 'CEDOM' },
  { label: 'CEDPLAN SAUDE LTDA ME', value: 'CEDPLAN SAUDE LTDA ME' },
  {
    label: 'CEFEM OPERADORA DE PLANO DE SAUDE LTDA',
    value: 'CEFEM OPERADORA DE PLANO DE SAUDE LTDA',
  },
  { label: 'CEMERU SAUDE', value: 'CEMERU SAUDE' },
  { label: 'CEMIG SAUDE II', value: 'CEMIG SAUDE II' },
  { label: 'CEMIL CENTRO MEDICO DE ITU LTDA.', value: 'CEMIL CENTRO MEDICO DE ITU LTDA.' },
  { label: 'CENCOSUD BRASIL COMERCIAL LTDA.', value: 'CENCOSUD BRASIL COMERCIAL LTDA.' },
  { label: 'CENIBRA', value: 'CENIBRA' },
  { label: 'CENTERMEDD', value: 'CENTERMEDD' },
  {
    label: 'CENTRAIS ELETRICAS DE RONDÔNIA S/A - CERON',
    value: 'CENTRAIS ELETRICAS DE RONDÔNIA S/A - CERON',
  },
  { label: 'CENTRAL NACIONAL UNIMED', value: 'CENTRAL NACIONAL UNIMED' },
  {
    label: 'CENTRAL OPERADORA DE PLANOS DE SAUDE NORTE-NORDESTE SOCIEDADE COOPERATIVA',
    value: 'CENTRAL OPERADORA DE PLANOS DE SAUDE NORTE-NORDESTE SOCIEDADE COOPERATIVA',
  },
  { label: 'CENTRO CLINICO', value: 'CENTRO CLINICO' },
  { label: 'CENTRO CLINICO GAUCHO', value: 'CENTRO CLINICO GAUCHO' },
  { label: 'CENTRO CLINICO NH', value: 'CENTRO CLINICO NH' },
  { label: 'CENTRO MEDICO ESTANCIA VELHA', value: 'CENTRO MEDICO ESTANCIA VELHA' },
  { label: 'CENTRO MEDICO FATIMA LTDA', value: 'CENTRO MEDICO FATIMA LTDA' },
  { label: 'CENTRO TRASMONTANO DE SAO PAULO', value: 'CENTRO TRASMONTANO DE SAO PAULO' },
  { label: 'CEORP', value: 'CEORP' },
  { label: 'CESAN', value: 'CESAN' },
  { label: 'CETESB', value: 'CETESB' },
  { label: 'CGO SAUDE', value: 'CGO SAUDE' },
  { label: 'CHEQUE SAUDE', value: 'CHEQUE SAUDE' },
  { label: 'CIGNA GLOBAL HEALTH', value: 'CIGNA GLOBAL HEALTH' },
  { label: 'CIME', value: 'CIME' },
  { label: 'CIMMAL', value: 'CIMMAL' },
  { label: 'CIRCULO OPERARIO CAXIENSE', value: 'CIRCULO OPERARIO CAXIENSE' },
  { label: 'CISOPAR', value: 'CISOPAR' },
  { label: 'CLAMP', value: 'CLAMP' },
  {
    label: 'CLASP ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'CLASP ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'CLASSE ADMINISTRADORA', value: 'CLASSE ADMINISTRADORA' },
  { label: 'CLASSES LABORIOSAS', value: 'CLASSES LABORIOSAS' },
  { label: 'CLIMAMP', value: 'CLIMAMP' },
  { label: 'CLIMED', value: 'CLIMED' },
  { label: 'CLIMED SAUDE', value: 'CLIMED SAUDE' },
  { label: 'CLIMEPE TOTAL', value: 'CLIMEPE TOTAL' },
  { label: 'CLIMESQ', value: 'CLIMESQ' },
  { label: 'CLIMOL', value: 'CLIMOL' },
  { label: 'CLIN SERV', value: 'CLIN SERV' },
  { label: 'CLINESP PLANOS DE SAUDE', value: 'CLINESP PLANOS DE SAUDE' },
  { label: 'CLINICA ODONTO LTDA.', value: 'CLINICA ODONTO LTDA.' },
  {
    label: 'CLINICA ODONTOLOGICA LUIZ LIMA LTDA',
    value: 'CLINICA ODONTOLOGICA LUIZ LIMA LTDA',
  },
  {
    label: 'CLINICA ODONTOLOGICA ODONTOBET',
    value: 'CLINICA ODONTOLOGICA ODONTOBET',
  },
  {
    label: 'CLINICA ODONTOLOGICA SASSO SOCIEDADE SIMPLES LTDA',
    value: 'CLINICA ODONTOLOGICA SASSO SOCIEDADE SIMPLES LTDA',
  },
  { label: 'CLINICA SANTA MARCIA', value: 'CLINICA SANTA MARCIA' },
  { label: 'CLINICA SAO GABRIEL S/S LTDA', value: 'CLINICA SAO GABRIEL S/S LTDA' },
  { label: 'CLINICA SAO JOAO LTDA', value: 'CLINICA SAO JOAO LTDA' },
  { label: 'CLINICA SAO JOSE SAUDE LTDA', value: 'CLINICA SAO JOSE SAUDE LTDA' },
  {
    label: 'CLINIPAM CLINICA PARANAENSE DE ASSISTENCIA MEDICA LTDA.',
    value: 'CLINIPAM CLINICA PARANAENSE DE ASSISTENCIA MEDICA LTDA.',
  },
  { label: 'CLINIPREV SAUDE LTDA', value: 'CLINIPREV SAUDE LTDA' },
  {
    label: 'CLUBE CARE ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'CLUBE CARE ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  {
    label: 'CLUBE DE BENEFICIOS OFFICE ASSISTENCIA INTEGRAL',
    value: 'CLUBE DE BENEFICIOS OFFICE ASSISTENCIA INTEGRAL',
  },
  {
    label: 'CLUBE DE BENEFICIOS PARA COOP., ASS., CONS., SIND. E SEGUROS - CBCACSS',
    value: 'CLUBE DE BENEFICIOS PARA COOP., ASS., CONS., SIND. E SEGUROS - CBCACSS',
  },
  {
    label: 'CLUBE DE SAUDE ADMINISTRADORA DE BENEFICIOS LTDA.',
    value: 'CLUBE DE SAUDE ADMINISTRADORA DE BENEFICIOS LTDA.',
  },
  { label: 'CLUBE VIDA', value: 'CLUBE VIDA' },
  {
    label: 'CMI - CLINICA DE MEDICINA INTEGRADA LTDA.',
    value: 'CMI - CLINICA DE MEDICINA INTEGRADA LTDA.',
  },
  { label: 'COBREQ', value: 'COBREQ' },
  { label: 'CODESA', value: 'CODESA' },
  { label: 'COI - CLINICA ODONTOLOGICA INTEGRADA', value: 'COI - CLINICA ODONTOLOGICA INTEGRADA' },
  {
    label: 'COJUN CENTRO ODONTOLOGICO JUNDIAI LTDA',
    value: 'COJUN CENTRO ODONTOLOGICO JUNDIAI LTDA',
  },
  {
    label: 'COMPANHIA DE ENGENHARIA DE TRAFEGO - CET',
    value: 'COMPANHIA DE ENGENHARIA DE TRAFEGO - CET',
  },
  {
    label: 'COMPANHIA NACIONAL DE ABASTECIMENTO - C0NAB',
    value: 'COMPANHIA NACIONAL DE ABASTECIMENTO - C0NAB',
  },
  { label: 'COMPESAPREV', value: 'COMPESAPREV' },
  { label: 'COMSEDER', value: 'COMSEDER' },
  { label: 'CONFIANÇA SAUDE', value: 'CONFIANÇA SAUDE' },
  { label: 'CONMED SAO LUIS', value: 'CONMED SAO LUIS' },
  { label: 'CONMEDH SAUDE I', value: 'CONMEDH SAUDE I' },
  { label: 'CONSAUDE', value: 'CONSAUDE' },
  { label: 'CONSTRUDENTE', value: 'CONSTRUDENTE' },
  { label: 'CONTEM ADMINISTRADORA DE BENEFICIOS', value: 'CONTEM ADMINISTRADORA DE BENEFICIOS' },
  { label: 'CONV MED SAUDE LTDA.', value: 'CONV MED SAUDE LTDA.' },
  { label: 'CONVEM PLANOS ODONTOLOGICOS S/A', value: 'CONVEM PLANOS ODONTOLOGICOS S/A' },
  { label: 'CONVENIO SEMPRE VIDA', value: 'CONVENIO SEMPRE VIDA' },
  { label: 'COODONTO', value: 'COODONTO' },
  {
    label: 'COOPERATIVA AGROINDUSTRIAL ALEGRETE LTDA.',
    value: 'COOPERATIVA AGROINDUSTRIAL ALEGRETE LTDA.',
  },
  {
    label: 'COOPERATIVA DE TRABALHO MEDICO REG.PLAN.SERRANO',
    value: 'COOPERATIVA DE TRABALHO MEDICO REG.PLAN.SERRANO',
  },
  {
    label: 'COOPERATIVA DE TRABALHO ODONTOLOGICO - UNIODONTO ITAJUBA',
    value: 'COOPERATIVA DE TRABALHO ODONTOLOGICO - UNIODONTO ITAJUBA',
  },
  {
    label: 'COOPERATIVA ODONTOLOGICA VALE DO CAI LTDA. DENTSUL',
    value: 'COOPERATIVA ODONTOLOGICA VALE DO CAI LTDA. DENTSUL',
  },
  { label: 'COOPERCISO', value: 'COOPERCISO' },
  { label: 'COOPERDONTO', value: 'COOPERDONTO' },
  { label: 'COOPERMECA', value: 'COOPERMECA' },
  { label: 'COOPERSINOS LTDA', value: 'COOPERSINOS LTDA' },
  {
    label: 'COOPUS - COOPERATIVA DE USUARIOS DO SISTEMA DE SAUDE DE CAMP',
    value: 'COOPUS - COOPERATIVA DE USUARIOS DO SISTEMA DE SAUDE DE CAMP',
  },
  { label: 'COOPUS PLANOS DE SAUDE LTDA', value: 'COOPUS PLANOS DE SAUDE LTDA' },
  { label: 'COOPUSAUDE', value: 'COOPUSAUDE' },
  { label: 'COPASS SAUDE', value: 'COPASS SAUDE' },
  { label: 'COPI', value: 'COPI' },
  {
    label: 'CORPLIFE ADMINISTRADORA DE BENEFICIOS LTDA EPP',
    value: 'CORPLIFE ADMINISTRADORA DE BENEFICIOS LTDA EPP',
  },
  {
    label: 'CORPORE ADMINISTRADORA DE BENEFICIOS DA SAUDE - EIRELI',
    value: 'CORPORE ADMINISTRADORA DE BENEFICIOS DA SAUDE - EIRELI',
  },
  {
    label: 'COTACOM - SERVIÇOS DE GESTAO DE BENEFICIOS LTDA',
    value: 'COTACOM - SERVIÇOS DE GESTAO DE BENEFICIOS LTDA',
  },
  { label: 'COTIA SAUDE', value: 'COTIA SAUDE' },
  {
    label: 'CROE-CENTRO DE REABILITAÇAO ORAL ESPECIALIZADO LTDA',
    value: 'CROE-CENTRO DE REABILITAÇAO ORAL ESPECIALIZADO LTDA',
  },
  { label: 'CROWN ODONTOLOGIA DE GRUPO LTDA', value: 'CROWN ODONTOLOGIA DE GRUPO LTDA' },
  { label: 'CRUSAM', value: 'CRUSAM' },
  { label: 'CRUZ AZUL SAUDE', value: 'CRUZ AZUL SAUDE' },
  { label: 'CUIDAR.ME SAUDE LTDA', value: 'CUIDAR.ME SAUDE LTDA' },
  { label: 'CUNHA ODONTOLOGIA LTDA', value: 'CUNHA ODONTOLOGIA LTDA' },
  { label: 'DANA INDUSTRIAS LTDA.', value: 'DANA INDUSTRIAS LTDA.' },
  { label: 'DAYMED SAUDE', value: 'DAYMED SAUDE' },
  {
    label: 'DEMAIS ADMINISTRADORA DE BENEFICIOS LTDA.',
    value: 'DEMAIS ADMINISTRADORA DE BENEFICIOS LTDA.',
  },
  { label: 'DENT SYSTEM', value: 'DENT SYSTEM' },
  { label: 'DENTAL CENTER LTDA', value: 'DENTAL CENTER LTDA' },
  {
    label: 'DENTAL CENTER SERVIÇOS ODONTOLOGICOS LTDA.',
    value: 'DENTAL CENTER SERVIÇOS ODONTOLOGICOS LTDA.',
  },
  { label: 'DENTAL FACIL', value: 'DENTAL FACIL' },
  { label: 'DENTAL GOLD', value: 'DENTAL GOLD' },
  { label: 'DENTAL MASTER', value: 'DENTAL MASTER' },
  { label: 'DENTAL MED CENTER', value: 'DENTAL MED CENTER' },
  { label: 'DENTAL NETWORK', value: 'DENTAL NETWORK' },
  { label: 'DENTAL NORTE', value: 'DENTAL NORTE' },
  { label: 'DENTAL PLAN', value: 'DENTAL PLAN' },
  { label: 'DENTAL PLUS CONVENIO ODONTOLOGICO', value: 'DENTAL PLUS CONVENIO ODONTOLOGICO' },
  { label: 'DENTAL SEG', value: 'DENTAL SEG' },
  { label: 'DENTAL UNI', value: 'DENTAL UNI' },
  { label: 'DENTAL VIP', value: 'DENTAL VIP' },
  { label: 'DENTALMASTER', value: 'DENTALMASTER' },
  { label: 'DENTALPAR', value: 'DENTALPAR' },
  { label: 'DENTALPLAN VALE DO SAO FRANCISCO', value: 'DENTALPLAN VALE DO SAO FRANCISCO' },
  { label: 'DENTALPREV', value: 'DENTALPREV' },
  { label: 'DENTALPREV I', value: 'DENTALPREV I' },
  { label: 'DENTALSHOW ASSISTENCIA ODONTOLOGICA', value: 'DENTALSHOW ASSISTENCIA ODONTOLOGICA' },
  { label: 'DENTALVIDA', value: 'DENTALVIDA' },
  { label: 'DENTE CROSS LTDA.', value: 'DENTE CROSS LTDA.' },
  { label: 'DENTSY ODONTOLOGIA', value: 'DENTSY ODONTOLOGIA' },
  { label: 'DESBAN', value: 'DESBAN' },
  { label: 'DESENBAHIA', value: 'DESENBAHIA' },
  {
    label: 'DIVICOM ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'DIVICOM ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'DIX SAUDE', value: 'DIX SAUDE' },
  { label: 'DOCTOR CLIN SAUDE INTEGRADA', value: 'DOCTOR CLIN SAUDE INTEGRADA' },
  { label: 'DONA SAUDE.', value: 'DONA SAUDE.' },
  { label: 'DR. SORRISO', value: 'DR. SORRISO' },
  {
    label: 'DRUMMOND ADMINISTRAÇAO EM SAUDE SUPLEMENTAR LTDA',
    value: 'DRUMMOND ADMINISTRAÇAO EM SAUDE SUPLEMENTAR LTDA',
  },
  { label: 'DRVS PARTICIPAÇOES LTDA', value: 'DRVS PARTICIPAÇOES LTDA' },
  { label: 'E-VIDA', value: 'E-VIDA' },
  {
    label: 'EASY SAUDE ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'EASY SAUDE ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'ECONLIFE SAUDE', value: 'ECONLIFE SAUDE' },
  {
    label: 'ECONOMUS INSTITUTO DE SEGURIDADE SOCIAL',
    value: 'ECONOMUS INSTITUTO DE SEGURIDADE SOCIAL',
  },
  {
    label: 'ECS ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'ECS ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'ELETROBRAS', value: 'ELETROBRAS' },
  { label: 'ELETRONUCLEAR', value: 'ELETRONUCLEAR' },
  { label: 'ELETROS-SAUDE', value: 'ELETROS-SAUDE' },
  { label: 'ELETROSUL', value: 'ELETROSUL' },
  {
    label: 'ELITE SAUDE ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'ELITE SAUDE ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'ELO SAUDE', value: 'ELO SAUDE' },
  { label: 'ELOSAUDE', value: 'ELOSAUDE' },
  { label: 'EMGEPRON', value: 'EMGEPRON' },
  { label: 'EMPREMED ASSISTENCIA MEDICA', value: 'EMPREMED ASSISTENCIA MEDICA' },
  {
    label: 'EMPRESA BRASILEIRA DE CORREIOS E TELEGRAFOS',
    value: 'EMPRESA BRASILEIRA DE CORREIOS E TELEGRAFOS',
  },
  {
    label: 'EMPRESA BRASILEIRA DE TELECOMUNICAÇOES',
    value: 'EMPRESA BRASILEIRA DE TELECOMUNICAÇOES',
  },
  { label: 'ESH ODONTO', value: 'ESH ODONTO' },
  {
    label: 'ETHOS ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'ETHOS ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'EV ADMINISTRADORA DE BENEFICIOS LTDA', value: 'EV ADMINISTRADORA DE BENEFICIOS LTDA' },
  { label: 'EVANGELICO SAUDE', value: 'EVANGELICO SAUDE' },
  {
    label: 'EVERCROSS PLANEJAMENTO DE ASSISTENCIA MEDICA LTDA.',
    value: 'EVERCROSS PLANEJAMENTO DE ASSISTENCIA MEDICA LTDA.',
  },
  { label: 'EVIDENTE', value: 'EVIDENTE' },
  { label: 'EVO SAUDE ODONTOLOGICA S/S', value: 'EVO SAUDE ODONTOLOGICA S/S' },
  {
    label: 'EXECUTIVO ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'EXECUTIVO ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'EXTRAMED', value: 'EXTRAMED' },
  { label: 'FACEB', value: 'FACEB' },
  { label: 'FACHESF II', value: 'FACHESF II' },
  { label: 'FAECES', value: 'FAECES' },
  { label: 'FAMA- UNIMED', value: 'FAMA- UNIMED' },
  { label: 'FAMED', value: 'FAMED' },
  { label: 'FAMEH/MP', value: 'FAMEH/MP' },
  { label: 'FAS/HMV', value: 'FAS/HMV' },
  {
    label: 'FEDERAÇAO DAS UNIMEDS DE MATO GROSSO DO SUL',
    value: 'FEDERAÇAO DAS UNIMEDS DE MATO GROSSO DO SUL',
  },
  {
    label: 'FEDERAÇAO DAS UNIMEDS DO ESTADO DE SAO PAULO',
    value: 'FEDERAÇAO DAS UNIMEDS DO ESTADO DE SAO PAULO',
  },
  { label: 'FENIX MEDICAL', value: 'FENIX MEDICAL' },
  { label: 'FIOSAUDE', value: 'FIOSAUDE' },
  { label: 'FIRJAN SAUDE', value: 'FIRJAN SAUDE' },
  { label: 'FISCO SAUDE-PE', value: 'FISCO SAUDE-PE' },
  { label: 'FREE DENT', value: 'FREE DENT' },
  { label: 'FREE LIFE SAUDE', value: 'FREE LIFE SAUDE' },
  { label: 'FREI GALVAO SAUDE', value: 'FREI GALVAO SAUDE' },
  {
    label: 'FUNASA-SAUDE (CAIXA DE ASSISTENCIA DOS EMPREGADOS DA SAELPA)',
    value: 'FUNASA-SAUDE (CAIXA DE ASSISTENCIA DOS EMPREGADOS DA SAELPA)',
  },
  { label: 'FUNDABEM', value: 'FUNDABEM' },
  {
    label: 'FUNDACAO LEONOR DE BARROS CAMARGO',
    value: 'FUNDACAO LEONOR DE BARROS CAMARGO',
  },
  { label: 'FUNDAFFEM', value: 'FUNDAFFEM' },
  {
    label: 'FUNDAÇAO ASSISTENCIAL DOS SERVIDORES DO INCRA - FASSINCRA',
    value: 'FUNDAÇAO ASSISTENCIAL DOS SERVIDORES DO INCRA - FASSINCRA',
  },
  {
    label: 'FUNDAÇAO ASSISTENCIAL DOS SERVIDORES DO MINISTERIO DA FAZENDA',
    value: 'FUNDAÇAO ASSISTENCIAL DOS SERVIDORES DO MINISTERIO DA FAZENDA',
  },
  { label: 'FUNDAÇAO CELESC', value: 'FUNDAÇAO CELESC' },
  { label: 'FUNDAÇAO COPEL', value: 'FUNDAÇAO COPEL' },
  {
    label: 'FUNDAÇAO DE ASSISTENCIA E PREVIDENCIA SOCIAL DO BNDES',
    value: 'FUNDAÇAO DE ASSISTENCIA E PREVIDENCIA SOCIAL DO BNDES',
  },
  {
    label: 'FUNDAÇAO FIAT SAUDE E BEM ESTAR',
    value: 'FUNDAÇAO FIAT SAUDE E BEM ESTAR',
  },
  { label: 'FUNDAÇAO IRMAO DIAMANTINO', value: 'FUNDAÇAO IRMAO DIAMANTINO' },
  { label: 'FUNDAÇAO LIBERTAS', value: 'FUNDAÇAO LIBERTAS' },
  { label: 'FUNDAÇAO PADRE ALBINO', value: 'FUNDAÇAO PADRE ALBINO' },
  {
    label: 'FUNDAÇAO SABESP DE SEGURIDADE SOCIAL - SABESPREV',
    value: 'FUNDAÇAO SABESP DE SEGURIDADE SOCIAL - SABESPREV',
  },
  {
    label: 'FUNDAÇAO SANEPAR DE ASSISTENCIA SOCIAL',
    value: 'FUNDAÇAO SANEPAR DE ASSISTENCIA SOCIAL',
  },
  {
    label: 'FUNDAÇAO SANTA CASA DE MISERICORDIA DE BELO HORIZONTE',
    value: 'FUNDAÇAO SANTA CASA DE MISERICORDIA DE BELO HORIZONTE',
  },
  { label: 'FUNDAÇAO SAUDE ITAU', value: 'FUNDAÇAO SAUDE ITAU' },
  { label: 'FUNDAÇAO USISAUDE', value: 'FUNDAÇAO USISAUDE' },
  { label: 'FUNSSEST', value: 'FUNSSEST' },
  { label: 'FUPRESA', value: 'FUPRESA' },
  { label: 'FUPS', value: 'FUPS' },
  { label: 'FURN', value: 'FURN' },
  { label: 'FURNAS', value: 'FURNAS' },
  {
    label: 'G2C ADMINISTRADORA DE BENEFICIOS LTDA - ME',
    value: 'G2C ADMINISTRADORA DE BENEFICIOS LTDA - ME',
  },
  { label: 'GAMA SAUDE LTDA.', value: 'GAMA SAUDE LTDA.' },
  { label: 'GAMEC', value: 'GAMEC' },
  {
    label: 'GARANTIA DE SAUDE - HOSPITAIS ADVENTISTA DE BELEM E MANAUS',
    value: 'GARANTIA DE SAUDE - HOSPITAIS ADVENTISTA DE BELEM E MANAUS',
  },
  { label: 'GARANTIA DE SAUDE I', value: 'GARANTIA DE SAUDE I' },
  { label: 'GARDEN', value: 'GARDEN' },
  { label: 'GBG GLOBAL BUSINESS GROUP', value: 'GBG GLOBAL BUSINESS GROUP' },
  { label: 'GEAP', value: 'GEAP' },
  { label: 'GEBEMED LTDA', value: 'GEBEMED LTDA' },
  { label: 'GEIA ADMINISTRADORA DE BENEFICIOS', value: 'GEIA ADMINISTRADORA DE BENEFICIOS' },
  { label: 'GESTAF', value: 'GESTAF' },
  { label: 'GESTAO', value: 'GESTAO' },
  { label: 'GESTAO SAUDE', value: 'GESTAO SAUDE' },
  { label: 'GEVISA SA', value: 'GEVISA SA' },
  { label: 'GKN DO BRASIL LTDA', value: 'GKN DO BRASIL LTDA' },
  { label: 'GOCARE PLANOS DE SAUDE', value: 'GOCARE PLANOS DE SAUDE' },
  {
    label: 'GOLDEN CROSS ASSISTENCIA INTERNACIONAL DE SAUDE',
    value: 'GOLDEN CROSS ASSISTENCIA INTERNACIONAL DE SAUDE',
  },
  { label: 'GOODLIFE II', value: 'GOODLIFE II' },
  {
    label: 'GRANJAS QUATRO IRMAOS AGROPECUARIA E COMERCIO S/A',
    value: 'GRANJAS QUATRO IRMAOS AGROPECUARIA E COMERCIO S/A',
  },
  { label: 'GREEN CARD', value: 'GREEN CARD' },
  { label: 'GREEN LIFE SAUDE', value: 'GREEN LIFE SAUDE' },
  { label: 'GREEN LINE SISTEMA DE SAUDE LTDA.', value: 'GREEN LINE SISTEMA DE SAUDE LTDA.' },
  { label: 'GRUPO 1A ODONTO', value: 'GRUPO 1A ODONTO' },
  { label: 'GRUPO CEMERU SAUDE', value: 'GRUPO CEMERU SAUDE' },
  { label: 'GS', value: 'GS' },
  { label: 'HAPVIDA', value: 'HAPVIDA' },
  { label: 'HB SAUDE', value: 'HB SAUDE' },
  { label: 'HBC SAUDE', value: 'HBC SAUDE' },
  { label: 'HC SAUDE', value: 'HC SAUDE' },
  {
    label: 'HEALTH ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'HEALTH ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'HEALTH SANTARIS', value: 'HEALTH SANTARIS' },
  { label: 'HEALTHCARE', value: 'HEALTHCARE' },
  {
    label: 'HEBROM ADMINISTRADORA DE BENEFICIOS LTDA. - ME',
    value: 'HEBROM ADMINISTRADORA DE BENEFICIOS LTDA. - ME',
  },
  { label: 'HELP ODONTO', value: 'HELP ODONTO' },
  { label: 'HFC+SAUDE', value: 'HFC+SAUDE' },
  { label: 'HGU SAUDE', value: 'HGU SAUDE' },
  { label: 'HOC MED', value: 'HOC MED' },
  { label: 'HOSPITAL ARNALDO GAVAZZA FILHO', value: 'HOSPITAL ARNALDO GAVAZZA FILHO' },
  { label: 'HOSPITAL BELINHA SABINO OMETTO', value: 'HOSPITAL BELINHA SABINO OMETTO' },
  { label: 'HOSPITAL BOM SAMARITANO', value: 'HOSPITAL BOM SAMARITANO' },
  { label: 'HOSPITAL CESAR LEITE', value: 'HOSPITAL CESAR LEITE' },
  {
    label: 'HOSPITAL DA SANTA CASA DE MISERICORDIA DE VITORIA',
    value: 'HOSPITAL DA SANTA CASA DE MISERICORDIA DE VITORIA',
  },
  {
    label: 'HOSPITAL DE CARIDADE DE VARGEM GRANDE DO SUL',
    value: 'HOSPITAL DE CARIDADE DE VARGEM GRANDE DO SUL',
  },
  { label: 'HOSPITAL DE PRONTOCLINICA LTDA.', value: 'HOSPITAL DE PRONTOCLINICA LTDA.' },
  { label: 'HOSPITAL DONA BALBINA', value: 'HOSPITAL DONA BALBINA' },
  {
    label: 'HOSPITAL E PRONTO SOCORRO SAO SEBASTIAO',
    value: 'HOSPITAL E PRONTO SOCORRO SAO SEBASTIAO',
  },
  { label: 'HOSPITAL ESPANHOL', value: 'HOSPITAL ESPANHOL' },
  { label: 'HOSPITAL JOSE FONSECA', value: 'HOSPITAL JOSE FONSECA' },
  { label: 'HOSPITAL MARCIO CUNHA', value: 'HOSPITAL MARCIO CUNHA' },
  {
    label: 'HOSPITAL MINISTRO COSTA CAVALCANTI - PLANO DE SAUDE ITAMED',
    value: 'HOSPITAL MINISTRO COSTA CAVALCANTI - PLANO DE SAUDE ITAMED',
  },
  { label: 'HOSPITAL NOSSA SENHORA AUXILIADORA', value: 'HOSPITAL NOSSA SENHORA AUXILIADORA' },
  { label: 'HOSPITAL NOSSA SENHORA DAS GRAÇAS', value: 'HOSPITAL NOSSA SENHORA DAS GRAÇAS' },
  { label: 'HOSPITAL REGIONAL DE FRANCA', value: 'HOSPITAL REGIONAL DE FRANCA' },
  { label: 'HOSPITAL RIO DOCE', value: 'HOSPITAL RIO DOCE' },
  { label: 'HOSPITAL SANTA ISABEL', value: 'HOSPITAL SANTA ISABEL' },
  { label: 'HOSPITAL SANTA IZABEL', value: 'HOSPITAL SANTA IZABEL' },
  { label: 'HOSPITAL SANTA MARIA', value: 'HOSPITAL SANTA MARIA' },
  { label: 'HOSPITAL SANTA ROSALIA', value: 'HOSPITAL SANTA ROSALIA' },
  { label: 'HOSPITAL SAO FRANCISCO DE ASSIS', value: 'HOSPITAL SAO FRANCISCO DE ASSIS' },
  { label: 'HOSPITAL SAO JOAO DE DEUS', value: 'HOSPITAL SAO JOAO DE DEUS' },
  { label: 'HOSPITAL SAO JOSE DO AVAI', value: 'HOSPITAL SAO JOSE DO AVAI' },
  { label: 'HOSPITAL SAO LUCAS', value: 'HOSPITAL SAO LUCAS' },
  { label: 'HOSPITAL SAO MARCOS S/A', value: 'HOSPITAL SAO MARCOS S/A' },
  { label: 'HOSPITAL SAO VICENTE DE PAULO', value: 'HOSPITAL SAO VICENTE DE PAULO' },
  { label: 'HOSPITAL SAUDE FACIL', value: 'HOSPITAL SAUDE FACIL' },
  { label: 'HOSPVIDA', value: 'HOSPVIDA' },
  { label: 'HUMANA SAUDE', value: 'HUMANA SAUDE' },
  { label: 'HUMANITAS SAUDE', value: 'HUMANITAS SAUDE' },
  { label: 'I.P.B.A.', value: 'I.P.B.A.' },
  { label: 'IAMSPE', value: 'IAMSPE' },
  { label: 'IBBCA GESTAO', value: 'IBBCA GESTAO' },
  { label: 'IBCM', value: 'IBCM' },
  { label: 'IDEAL SAUDE', value: 'IDEAL SAUDE' },
  { label: 'IDEAL VIDA E SAUDE', value: 'IDEAL VIDA E SAUDE' },
  { label: 'IDENTAL', value: 'IDENTAL' },
  { label: 'IGARATIBA', value: 'IGARATIBA' },
  { label: 'IGUAMED ASSISTENCIA MEDICA LTDA', value: 'IGUAMED ASSISTENCIA MEDICA LTDA' },
  {
    label: 'INDEPENDENCIA ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'INDEPENDENCIA ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'INDUSTRIA MECANICA SIGRIST LTDA', value: 'INDUSTRIA MECANICA SIGRIST LTDA' },
  {
    label: 'INDUSTRIAS NUCLEARES DO BRASIL S/A - INB',
    value: 'INDUSTRIAS NUCLEARES DO BRASIL S/A - INB',
  },
  { label: 'INFRAERO', value: 'INFRAERO' },
  { label: 'INPAO', value: 'INPAO' },
  { label: 'INSISO', value: 'INSISO' },
  { label: 'INSTITUTO CURITIBA DE SAUDE - ICS', value: 'INSTITUTO CURITIBA DE SAUDE - ICS' },
  {
    label: 'INSTITUTO DE ASSISTENCIA PARA COOPERATIVAS E ASSOCIAÇOES E SINDICATOS- INFINITY',
    value: 'INSTITUTO DE ASSISTENCIA PARA COOPERATIVAS E ASSOCIAÇOES E SINDICATOS- INFINITY',
  },
  { label: 'INSTITUTO DE SAUDE ASCADE - ISA', value: 'INSTITUTO DE SAUDE ASCADE - ISA' },
  {
    label: 'INSTITUTO UNIVERSAL DE ADMINISTRAÇAO DE BENEFICIOS LTDA.',
    value: 'INSTITUTO UNIVERSAL DE ADMINISTRAÇAO DE BENEFICIOS LTDA.',
  },
  {
    label: 'INTEGRA - ADMINISTRAÇAO DE PLANOS DE SAUDE LTDA',
    value: 'INTEGRA - ADMINISTRAÇAO DE PLANOS DE SAUDE LTDA',
  },
  { label: 'INTEGRAL CONVENIO ODONTOLOGICO', value: 'INTEGRAL CONVENIO ODONTOLOGICO' },
  {
    label: 'INTEGRAL CONVENIO ODONTOLOGICO LTDA.',
    value: 'INTEGRAL CONVENIO ODONTOLOGICO LTDA.',
  },
  {
    label: 'INTERBRASIL ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'INTERBRASIL ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'INTERCLINICA', value: 'INTERCLINICA' },
  { label: 'INTERDENTAL', value: 'INTERDENTAL' },
  {
    label: 'INTERDENTAL ODONTOLOGIA INTEGRADA LTDA.',
    value: 'INTERDENTAL ODONTOLOGIA INTEGRADA LTDA.',
  },
  { label: 'INTERMED', value: 'INTERMED' },
  { label: 'INTERMEDICA SISTEMA DE SAUDE', value: 'INTERMEDICA SISTEMA DE SAUDE' },
  { label: 'INTERMEDICI PIRACICABA', value: 'INTERMEDICI PIRACICABA' },
  {
    label: 'INTERODONTO - SISTEMA DE SAUDE ODONTOLOGICA LTDA.',
    value: 'INTERODONTO - SISTEMA DE SAUDE ODONTOLOGICA LTDA.',
  },
  { label: 'INTRA SUL DE MINAS', value: 'INTRA SUL DE MINAS' },
  {
    label: 'INVEST HEALTH ADMINISTRADORA DE BENEFICIOS',
    value: 'INVEST HEALTH ADMINISTRADORA DE BENEFICIOS',
  },
  { label: 'INVEST ORAL PLANO ODONTOLOGICO LTDA.', value: 'INVEST ORAL PLANO ODONTOLOGICO LTDA.' },
  { label: 'IOMA', value: 'IOMA' },
  { label: 'A LA SANTE', value: 'A LA SANTE' },
  { label: 'IPE SAUDE', value: 'IPE SAUDE' },
  { label: 'IPIRANGA PRODUTOS DE PETROLEO S.A.', value: 'IPIRANGA PRODUTOS DE PETROLEO S.A.' },
  { label: 'IPREF', value: 'IPREF' },
  {
    label: 'IPSEMG - INSTITUTO DE PREVIDENCIA DOS SERVIDORES DO ESTADO MG',
    value: 'IPSEMG - INSTITUTO DE PREVIDENCIA DOS SERVIDORES DO ESTADO MG',
  },
  { label: 'IRB-BRASIL RE', value: 'IRB-BRASIL RE' },
  {
    label: 'IRM.DA STA.CASA DE MIS.E MAT. DONA ZILDA SALVAGNI',
    value: 'IRM.DA STA.CASA DE MIS.E MAT. DONA ZILDA SALVAGNI',
  },
  {
    label: 'IRMANDADE DA SANTA CASA DE MISERICORDIA DE CATAGUASES',
    value: 'IRMANDADE DA SANTA CASA DE MISERICORDIA DE CATAGUASES',
  },
  {
    label: 'IRMANDADE DA SANTA CASA DE MISERICORDIA DE MUZAMBINHO',
    value: 'IRMANDADE DA SANTA CASA DE MISERICORDIA DE MUZAMBINHO',
  },
  {
    label: 'IRMANDADE DA SANTA CASA DE MISERICORDIA DE RIO CLARO',
    value: 'IRMANDADE DA SANTA CASA DE MISERICORDIA DE RIO CLARO',
  },
  {
    label: 'IRMANDADE DA SANTA CASA DE MISERICORDIA DE VALINHOS',
    value: 'IRMANDADE DA SANTA CASA DE MISERICORDIA DE VALINHOS',
  },
  {
    label: 'IRMANDADE DE MISERICORDIA DO HOSPITAL DA SANTA CASA DE MONTE ALTO',
    value: 'IRMANDADE DE MISERICORDIA DO HOSPITAL DA SANTA CASA DE MONTE ALTO',
  },
  {
    label: 'IRMANDADE DO SENHOR BOM JESUS DOS PASSOS DA STA CASA DE MISERICORDIA DE BRAGANÇA',
    value: 'IRMANDADE DO SENHOR BOM JESUS DOS PASSOS DA STA CASA DE MISERICORDIA DE BRAGANÇA',
  },
  {
    label: 'IRMANDADE NOSSA SENHORA DAS MERCES DE MONTES CLAROS',
    value: 'IRMANDADE NOSSA SENHORA DAS MERCES DE MONTES CLAROS',
  },
  {
    label: 'IRMANDADE SANTA CASA DE MISERICORDIA DE LIMEIRA',
    value: 'IRMANDADE SANTA CASA DE MISERICORDIA DE LIMEIRA',
  },
  { label: 'ISAM', value: 'ISAM' },
  { label: 'ISODONT', value: 'ISODONT' },
  { label: 'ITAGUAI SAUDE', value: 'ITAGUAI SAUDE' },
  { label: 'ITAIPU BINACIONAL', value: 'ITAIPU BINACIONAL' },
  { label: 'ITAUSEG SAÚDE', value: 'ITAUSEG SAÚDE' },
  { label: 'JOAQUIM OLIVEIRA S/A PARTICIPAÇOES', value: 'JOAQUIM OLIVEIRA S/A PARTICIPAÇOES' },
  { label: 'JTC ODONTOLOGIA LTDA.', value: 'JTC ODONTOLOGIA LTDA.' },
  { label: 'JUDICEMED', value: 'JUDICEMED' },
  { label: 'JURAL ADMINISTRADORA', value: 'JURAL ADMINISTRADORA' },
  { label: 'JUST LIFE BENEFICIOS', value: 'JUST LIFE BENEFICIOS' },
  { label: 'KIPP SAUDE', value: 'KIPP SAUDE' },
  { label: 'KLINI', value: 'KLINI' },
  {
    label: 'LAM OPERADORA DE PLANOS DE SAUDE S/C LTDA.',
    value: 'LAM OPERADORA DE PLANOS DE SAUDE S/C LTDA.',
  },
  {
    label: 'LANCERS ADMINISTRADORA DE BENEFICIOS DE SAUDE LTDA.',
    value: 'LANCERS ADMINISTRADORA DE BENEFICIOS DE SAUDE LTDA.',
  },
  { label: 'LEADER', value: 'LEADER' },
  { label: 'LEVE SAUDE', value: 'LEVE SAUDE' },
  { label: 'LEVMED SAÚDE', value: 'LEVMED SAÚDE' },
  {
    label: 'LEXUS ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'LEXUS ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  {
    label: 'LIAZE CORPORATION ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'LIAZE CORPORATION ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'LIFE CLASS', value: 'LIFE CLASS' },
  { label: 'LIFE EMPRESARIAL SAUDE', value: 'LIFE EMPRESARIAL SAUDE' },
  { label: 'LIFE LAGOS SAUDE', value: 'LIFE LAGOS SAUDE' },
  {
    label: 'LIFE SAO PAULO ASSISTENCIA MEDICA E ODONTOLOGICA',
    value: 'LIFE SAO PAULO ASSISTENCIA MEDICA E ODONTOLOGICA',
  },
  { label: 'LIFE SYSTEM', value: 'LIFE SYSTEM' },
  { label: 'LIFEDAY PLANOS DE SAÚDE LTDA', value: 'LIFEDAY PLANOS DE SAÚDE LTDA' },
  { label: 'LIS DENTAL LTDA', value: 'LIS DENTAL LTDA' },
  {
    label: 'LITORAL CONVENIOS E CREDENCIAMENTO ODONTOLOGICO LTDA ME',
    value: 'LITORAL CONVENIOS E CREDENCIAMENTO ODONTOLOGICO LTDA ME',
  },
  { label: 'LIV SAUDE', value: 'LIV SAUDE' },
  { label: 'LOG & PRINT GRAFICA E LOGISTICA S/A', value: 'LOG & PRINT GRAFICA E LOGISTICA S/A' },
  { label: 'MAC DENTAL', value: 'MAC DENTAL' },
  {
    label: 'MADIA & DUARTE ADMINISTRADORA DE BENEFICIOS DE SAUDE LTDA',
    value: 'MADIA & DUARTE ADMINISTRADORA DE BENEFICIOS DE SAUDE LTDA',
  },
  { label: 'MAIS SAUDE S/A', value: 'MAIS SAUDE S/A' },
  { label: 'MAIS SAUDE SANTA CASA', value: 'MAIS SAUDE SANTA CASA' },
  { label: 'MAISODONTO', value: 'MAISODONTO' },
  { label: 'MAPFRE SAUDE LTDA', value: 'MAPFRE SAUDE LTDA' },
  { label: 'MAPMA', value: 'MAPMA' },
  { label: 'MARKI ODONTO', value: 'MARKI ODONTO' },
  { label: 'MASTER ODONTO', value: 'MASTER ODONTO' },
  {
    label: 'MASTER ODONTO ASSISTENCIA ODONTOLOGICA',
    value: 'MASTER ODONTO ASSISTENCIA ODONTOLOGICA',
  },
  { label: 'MASTER PAX SAUDE', value: 'MASTER PAX SAUDE' },
  { label: 'MATAO CLINICAS & AMHMA SAUDE', value: 'MATAO CLINICAS & AMHMA SAUDE' },
  { label: 'MATERMED ASSISTENCIA MEDICA', value: 'MATERMED ASSISTENCIA MEDICA' },
  { label: 'MATTOS ODONTOLOGIA LTDA', value: 'MATTOS ODONTOLOGIA LTDA' },
  { label: 'MAXIDENTE', value: 'MAXIDENTE' },
  { label: 'MAXMED', value: 'MAXMED' },
  { label: 'MD ODONTO', value: 'MD ODONTO' },
  { label: 'MED CORP SAUDE CORPORATIVA', value: 'MED CORP SAUDE CORPORATIVA' },
  { label: 'MED VIDA SAUDE', value: 'MED VIDA SAUDE' },
  { label: 'MED-TOUR SAUDE', value: 'MED-TOUR SAUDE' },
  { label: 'MEDHEALTH', value: 'MEDHEALTH' },
  { label: 'MEDIATORE', value: 'MEDIATORE' },
  { label: 'MEDICAL HEALTH', value: 'MEDICAL HEALTH' },
  {
    label: 'MEDICAL MEDICINA COOPERATIVA ASSISTENCIAL DE LIMEIRA',
    value: 'MEDICAL MEDICINA COOPERATIVA ASSISTENCIAL DE LIMEIRA',
  },
  { label: 'MEDICAL RIO', value: 'MEDICAL RIO' },
  { label: 'MEDIPLAN', value: 'MEDIPLAN' },
  {
    label: 'MEDISANITAS BRASIL ASSISTENCIA INTEGRAL A SAUDE S/A.',
    value: 'MEDISANITAS BRASIL ASSISTENCIA INTEGRAL A SAUDE S/A.',
  },
  {
    label: 'MEDISERVICE OPERADORA DE PLANOS DE SAUDE',
    value: 'MEDISERVICE OPERADORA DE PLANOS DE SAUDE',
  },
  { label: 'MEDISUL', value: 'MEDISUL' },
  { label: 'MEDLINE', value: 'MEDLINE' },
  { label: 'MEDPLAN', value: 'MEDPLAN' },
  { label: 'MEDPLANN', value: 'MEDPLANN' },
  { label: 'MEDPORTO ASSISTENCIA MEDICA LTDA.', value: 'MEDPORTO ASSISTENCIA MEDICA LTDA.' },
  { label: 'MEDSENIOR', value: 'MEDSENIOR' },
  { label: 'MEGAMED SAUDE II', value: 'MEGAMED SAUDE II' },
  { label: 'MEMORIAL SAUDE LTDA', value: 'MEMORIAL SAUDE LTDA' },
  { label: 'MERCOPLAN PLANOS DE SAUDE', value: 'MERCOPLAN PLANOS DE SAUDE' },
  { label: 'METALURGICA WOLF LTDA', value: 'METALURGICA WOLF LTDA' },
  { label: 'METLIFE PLANOS ODONTOLOGICOS LTDA.', value: 'METLIFE PLANOS ODONTOLOGICOS LTDA.' },
  { label: 'METODONT', value: 'METODONT' },
  {
    label: 'METROPOLITANACIONAL ADMINISTRADORA DE BENEFICIOS',
    value: 'METROPOLITANACIONAL ADMINISTRADORA DE BENEFICIOS',
  },
  {
    label: 'METRUS INSTITUTO DE SEGURIDADE SOCIAL II',
    value: 'METRUS INSTITUTO DE SEGURIDADE SOCIAL II',
  },
  {
    label: 'MH VIDA - OPERADORA DE PLANOS DE SAUDE LTDA.',
    value: 'MH VIDA - OPERADORA DE PLANOS DE SAUDE LTDA.',
  },
  { label: 'MINERAÇAO CARAIBA', value: 'MINERAÇAO CARAIBA' },
  { label: 'MOGIDONTO', value: 'MOGIDONTO' },
  { label: 'MR SAUDE', value: 'MR SAUDE' },
  { label: 'MS ASSISTENCIA MEDICA S/C LTDA', value: 'MS ASSISTENCIA MEDICA S/C LTDA' },
  { label: 'MULTI-BUCAL CONVENIO ODONTOLOGICO', value: 'MULTI-BUCAL CONVENIO ODONTOLOGICO' },
  { label: 'MULTI-SAUDE', value: 'MULTI-SAUDE' },
  { label: 'MULTICARE SAUDE LTDA', value: 'MULTICARE SAUDE LTDA' },
  { label: 'MULTICLINICA SERVIÇOS DE SAUDE LTDA', value: 'MULTICLINICA SERVIÇOS DE SAUDE LTDA' },
  { label: 'MULTIPLA ADMINISTRADORA', value: 'MULTIPLA ADMINISTRADORA' },
  { label: 'MUND ODONTO', value: 'MUND ODONTO' },
  { label: 'MUTUA DOS MAGISTRADOS', value: 'MUTUA DOS MAGISTRADOS' },
  {
    label: 'NEOPLAN - PLANO DE EXCELENCIA EM ODONTOLOGIA LTDA',
    value: 'NEOPLAN - PLANO DE EXCELENCIA EM ODONTOLOGIA LTDA',
  },
  {
    label: 'NEW ODONTO ADMINISTRAÇAO DE CONVENIOS ODONTOLOGICOS LTDA',
    value: 'NEW ODONTO ADMINISTRAÇAO DE CONVENIOS ODONTOLOGICOS LTDA',
  },
  { label: 'NEWKLIN ODONTOCLINICA', value: 'NEWKLIN ODONTOCLINICA' },
  { label: 'NEXT FILIAL', value: 'NEXT FILIAL' },
  {
    label: 'NIPON DENTAL CONVENIO ODONTOLOGICO LTDA',
    value: 'NIPON DENTAL CONVENIO ODONTOLOGICO LTDA',
  },
  { label: 'NORDESTE SAUDE EMPRESARIAL', value: 'NORDESTE SAUDE EMPRESARIAL' },
  { label: 'NOSSA SAUDE', value: 'NOSSA SAUDE' },
  { label: 'NOTRE DAME SEGURADORA', value: 'NOTRE DAME SEGURADORA' },
  { label: 'NOVODENTE', value: 'NOVODENTE' },
  { label: 'NUCLEO ASSISTENCIA ODONTOLOGICA', value: 'NUCLEO ASSISTENCIA ODONTOLOGICA' },
  {
    label: 'NUCLEO ODONTOLOGICO E PLANO DE ASSIST ODONTOLOGICA S/S LTDA',
    value: 'NUCLEO ODONTOLOGICO E PLANO DE ASSIST ODONTOLOGICA S/S LTDA',
  },
  { label: 'NUCLEP II', value: 'NUCLEP II' },
  {
    label: 'NUNES & GROSSI ADMINISTRADORA DE BENEFICIOS E SERVIÇOS LTDA',
    value: 'NUNES & GROSSI ADMINISTRADORA DE BENEFICIOS E SERVIÇOS LTDA',
  },
  { label: 'ODMED', value: 'ODMED' },
  { label: 'ODONTO CARD', value: 'ODONTO CARD' },
  { label: 'ODONTO CORPUS S/S LTDA.', value: 'ODONTO CORPUS S/S LTDA.' },
  { label: 'ODONTO HEALTH', value: 'ODONTO HEALTH' },
  { label: 'ODONTO JARAGUA', value: 'ODONTO JARAGUA' },
  { label: 'ODONTO LIFE', value: 'ODONTO LIFE' },
  { label: 'ODONTO LIVE', value: 'ODONTO LIVE' },
  { label: 'ODONTO MAGIC', value: 'ODONTO MAGIC' },
  { label: 'ODONTO MINAS', value: 'ODONTO MINAS' },
  { label: 'ODONTO PLUS', value: 'ODONTO PLUS' },
  { label: 'ODONTO PRIME', value: 'ODONTO PRIME' },
  {
    label: 'ODONTO QUALIT SERVIÇOS ODONTOLOGICOS LTDA',
    value: 'ODONTO QUALIT SERVIÇOS ODONTOLOGICOS LTDA',
  },
  { label: 'ODONTO S.A', value: 'ODONTO S.A' },
  { label: 'ODONTO SAO LUCAS', value: 'ODONTO SAO LUCAS' },
  { label: 'ODONTO SAUDE', value: 'ODONTO SAUDE' },
  {
    label: 'ODONTO SAUDE SERVIÇOS DE ODONTOLOGIA LTDA.',
    value: 'ODONTO SAUDE SERVIÇOS DE ODONTOLOGIA LTDA.',
  },
  { label: 'ODONTO SEG', value: 'ODONTO SEG' },
  { label: 'ODONTO SYSTEM', value: 'ODONTO SYSTEM' },
  { label: 'ODONTO TOTAL STORE', value: 'ODONTO TOTAL STORE' },
  { label: 'ODONTO WORLD', value: 'ODONTO WORLD' },
  { label: 'A LA SANTE', value: 'A LA SANTE' },
  { label: 'ODONTOART', value: 'ODONTOART' },
  { label: 'ODONTOASSIST', value: 'ODONTOASSIST' },
  { label: 'ODONTOBASE', value: 'ODONTOBASE' },
  { label: 'ODONTOCAP', value: 'ODONTOCAP' },
  { label: 'ODONTOCARD', value: 'ODONTOCARD' },
  { label: 'ODONTOCLASSIC', value: 'ODONTOCLASSIC' },
  {
    label: 'ODONTOCLIN SERVIÇOS ODONTOLOGICOS LTDA',
    value: 'ODONTOCLIN SERVIÇOS ODONTOLOGICOS LTDA',
  },
  {
    label: 'ODONTOCLINICA RCC LTDA. S/S',
    value: 'ODONTOCLINICA RCC LTDA. S/S',
  },
  { label: 'ODONTOCROSS', value: 'ODONTOCROSS' },
  {
    label: 'ODONTODHAN, NUCLEO ODONTOLOGICO OU SORRISO DHAN',
    value: 'ODONTODHAN, NUCLEO ODONTOLOGICO OU SORRISO DHAN',
  },
  { label: 'ODONTOFACE', value: 'ODONTOFACE' },
  { label: 'ODONTOFEM', value: 'ODONTOFEM' },
  { label: 'ODONTOGROUP', value: 'ODONTOGROUP' },
  { label: 'ODONTOLOGICA', value: 'ODONTOLOGICA' },
  { label: 'ODONTOLOGYC SYSTEM', value: 'ODONTOLOGYC SYSTEM' },
  { label: 'ODONTOMAIS', value: 'ODONTOMAIS' },
  { label: 'ODONTOMAR LTDA.', value: 'ODONTOMAR LTDA.' },
  { label: 'ODONTOMAX', value: 'ODONTOMAX' },
  { label: 'ODONTOMAXI', value: 'ODONTOMAXI' },
  { label: 'ODONTOMED SAUDE LTDA - ME', value: 'ODONTOMED SAUDE LTDA - ME' },
  { label: 'ODONTOMOINHOS', value: 'ODONTOMOINHOS' },
  { label: 'ODONTONOG', value: 'ODONTONOG' },
  { label: 'ODONTOPAM', value: 'ODONTOPAM' },
  { label: 'ODONTOPARA LTDA', value: 'ODONTOPARA LTDA' },
  { label: 'ODONTOPERSONALITE', value: 'ODONTOPERSONALITE' },
  { label: 'ODONTOPLAN', value: 'ODONTOPLAN' },
  {
    label: 'ODONTOPLAN ASSISTENCIA ODONTOLOGICA LTDA',
    value: 'ODONTOPLAN ASSISTENCIA ODONTOLOGICA LTDA',
  },
  { label: 'ODONTOPLAN BH', value: 'ODONTOPLAN BH' },
  { label: 'ODONTOPLAN I', value: 'ODONTOPLAN I' },
  { label: 'ODONTOPPLASA', value: 'ODONTOPPLASA' },
  {
    label: 'ODONTOPONTA CLINICA ODONTOLOGICA LTDA',
    value: 'ODONTOPONTA CLINICA ODONTOLOGICA LTDA',
  },
  { label: 'ODONTOPREV', value: 'ODONTOPREV' },
  { label: 'ODONTOREAL', value: 'ODONTOREAL' },
  { label: 'ODONTOREALE', value: 'ODONTOREALE' },
  { label: 'ODONTOVIDA', value: 'ODONTOVIDA' },
  { label: 'ODONTOVIDE PLANOS ODONTOLOGICOS', value: 'ODONTOVIDE PLANOS ODONTOLOGICOS' },
  { label: 'ODONTOVIP', value: 'ODONTOVIP' },
  { label: 'ODONTRAT', value: 'ODONTRAT' },
  { label: 'OESTE SAUDE', value: 'OESTE SAUDE' },
  { label: 'OMINT SERVIÇOS DE SAUDE', value: 'OMINT SERVIÇOS DE SAUDE' },
  { label: 'ONEPLAN', value: 'ONEPLAN' },
  {
    label: 'OPERADORA DE PLANOS DE SAUDE SERRA IMPERIAL LTDA.',
    value: 'OPERADORA DE PLANOS DE SAUDE SERRA IMPERIAL LTDA.',
  },
  { label: 'OPTPLAN', value: 'OPTPLAN' },
  { label: 'ORAL BRASIL PLANOS ODONTOLOGICOS', value: 'ORAL BRASIL PLANOS ODONTOLOGICOS' },
  { label: 'ORAL CLEAN', value: 'ORAL CLEAN' },
  { label: 'ORAL COMPANY', value: 'ORAL COMPANY' },
  { label: 'ORAL FLEX', value: 'ORAL FLEX' },
  {
    label: 'ORAL GAM ASSISTENCIA ODONTOLOGICA LTDA',
    value: 'ORAL GAM ASSISTENCIA ODONTOLOGICA LTDA',
  },
  { label: 'ORAL MED PARANA', value: 'ORAL MED PARANA' },
  { label: 'ORAL ODONTO', value: 'ORAL ODONTO' },
  { label: 'ORAL SANTA HELENA', value: 'ORAL SANTA HELENA' },
  { label: 'ORAL SAUDE', value: 'ORAL SAUDE' },
  {
    label: 'ORAL SAUDE CLINICA ODONTOLOGICA LTDA.',
    value: 'ORAL SAUDE CLINICA ODONTOLOGICA LTDA.',
  },
  {
    label: 'ORAL SAUDE DE IBITINGA SERV. ODONTOL. S/S LTDA',
    value: 'ORAL SAUDE DE IBITINGA SERV. ODONTOL. S/S LTDA',
  },
  { label: 'ORAL SAUDE I', value: 'ORAL SAUDE I' },
  { label: 'ORAL SISTER', value: 'ORAL SISTER' },
  {
    label: 'ORALE PLANOS DE ASSISTENCIA ODONTOLOGICA',
    value: 'ORALE PLANOS DE ASSISTENCIA ODONTOLOGICA',
  },
  { label: 'ORALMED ASSISTENCIA ODONTOLOGICA', value: 'ORALMED ASSISTENCIA ODONTOLOGICA' },
  { label: 'ORALSYSTEM', value: 'ORALSYSTEM' },
  {
    label: 'ORION PLANOS E SERVIÇOS ODONTOLOGICOS',
    value: 'ORION PLANOS E SERVIÇOS ODONTOLOGICOS',
  },
  { label: 'ORLAMAX PLANOS ODONTOLOGICOS', value: 'ORLAMAX PLANOS ODONTOLOGICOS' },
  { label: 'OURO BRASIL', value: 'OURO BRASIL' },
  { label: 'PAM', value: 'PAM' },
  { label: 'PAME - PLANOS DE ASSISTENCIA MEDICA', value: 'PAME - PLANOS DE ASSISTENCIA MEDICA' },
  { label: 'PAN AMERICAN LIFE', value: 'PAN AMERICAN LIFE' },
  {
    label: 'PAN DENTAL ODONTOLOGIA DE GRUPO LTDA.',
    value: 'PAN DENTAL ODONTOLOGIA DE GRUPO LTDA.',
  },
  {
    label: 'PAR SAUDE ADMINISTRADORA DE BENEFICIOS',
    value: 'PAR SAUDE ADMINISTRADORA DE BENEFICIOS',
  },
  { label: 'PARANA CLINICAS', value: 'PARANA CLINICAS' },
  { label: 'PARANÁ CLÍNICAS', value: 'PARANÁ CLÍNICAS' },
  { label: 'PARRA ODONTOLOGIA - ATUAL CONVENIO', value: 'PARRA ODONTOLOGIA - ATUAL CONVENIO' },
  { label: 'PASA', value: 'PASA' },
  { label: 'PCM SAUDE', value: 'PCM SAUDE' },
  {
    label: 'PERSONAL DOCTOR ASSISTENCIA ODONTOLOGICA LTDA.',
    value: 'PERSONAL DOCTOR ASSISTENCIA ODONTOLOGICA LTDA.',
  },
  { label: 'PETROBRAS', value: 'PETROBRAS' },
  { label: 'PETROLEO IPIRANGA', value: 'PETROLEO IPIRANGA' },
  {
    label: 'PINTI SAUDE PLANOS ODONTOLOGICOS LTDA',
    value: 'PINTI SAUDE PLANOS ODONTOLOGICOS LTDA',
  },
  { label: 'PLADISA', value: 'PLADISA' },
  { label: 'PLADISA PLANOS DE SAUDE', value: 'PLADISA PLANOS DE SAUDE' },
  { label: 'PLAEMO SAUDE BUCAL', value: 'PLAEMO SAUDE BUCAL' },
  { label: 'PLAM', value: 'PLAM' },
  { label: 'PLAME SAUDE', value: 'PLAME SAUDE' },
  { label: 'PLAMEB', value: 'PLAMEB' },
  {
    label: 'PLAMED PLANO DE ASSISTENCIA MEDICA LTDA.',
    value: 'PLAMED PLANO DE ASSISTENCIA MEDICA LTDA.',
  },
  { label: 'PLAMEDH', value: 'PLAMEDH' },
  { label: 'PLAMER - PLANO MEDICO RESENDE I', value: 'PLAMER - PLANO MEDICO RESENDE I' },
  { label: 'PLAMHEG', value: 'PLAMHEG' },
  { label: 'PLAMHUV', value: 'PLAMHUV' },
  { label: 'PLAN MED', value: 'PLAN MED' },
  { label: 'PLANDENTAL', value: 'PLANDENTAL' },
  { label: 'PLANET LIFE', value: 'PLANET LIFE' },
  { label: 'PLANIDENT', value: 'PLANIDENT' },
  { label: 'PLANMED', value: 'PLANMED' },
  { label: 'PLANO AMAZÔNIA SAUD', value: 'PLANO AMAZÔNIA SAUD' },
  { label: 'PLANO AMAZÔNIA SAUDE', value: 'PLANO AMAZÔNIA SAUDE' },
  { label: 'PLANO AMIGO ASSISTENCIA MEDICAA', value: 'PLANO AMIGO ASSISTENCIA MEDICAA' },
  { label: 'PLANO DE SAUDE - SMH II', value: 'PLANO DE SAUDE - SMH II' },
  { label: 'PLANO DE SAUDE ANA COSTA LTDA', value: 'PLANO DE SAUDE ANA COSTA LTDA' },
  { label: 'PLANO DE SAUDE FATIMA', value: 'PLANO DE SAUDE FATIMA' },
  { label: 'PLANO DE SAUDE IDEAL', value: 'PLANO DE SAUDE IDEAL' },
  { label: 'PLANO DE SAUDE LUIZ FRANÇA', value: 'PLANO DE SAUDE LUIZ FRANÇA' },
  { label: 'PLANO DE SAUDE SANTA CASA DE SANTOS', value: 'PLANO DE SAUDE SANTA CASA DE SANTOS' },
  { label: 'PLANO DE SAUDE SAO JANUARIO', value: 'PLANO DE SAUDE SAO JANUARIO' },
  { label: 'PLANO DE SAUDE SAO JOSE', value: 'PLANO DE SAUDE SAO JOSE' },
  { label: 'PLANO DE SAUDE TACCHIMED', value: 'PLANO DE SAUDE TACCHIMED' },
  { label: 'PLANO DE SAUDE UNIVIDA', value: 'PLANO DE SAUDE UNIVIDA' },
  { label: 'PLANO DE SUDE LIMA', value: 'PLANO DE SUDE LIMA' },
  { label: 'PLANO FAMILIA', value: 'PLANO FAMILIA' },
  { label: 'PLANO FAMILIA & SAUDE', value: 'PLANO FAMILIA & SAUDE' },
  { label: 'PLANO HOSPITAL SAMARITANO LTDA II', value: 'PLANO HOSPITAL SAMARITANO LTDA II' },
  {
    label: 'PLANO MEDICO-HOSPITALAR NOSSA SENHORA DAS GRAÇAS-PLANSEG',
    value: 'PLANO MEDICO-HOSPITALAR NOSSA SENHORA DAS GRAÇAS-PLANSEG',
  },
  { label: 'PLANO ODONTOLOGICO ORTOCLIN', value: 'PLANO ODONTOLOGICO ORTOCLIN' },
  { label: 'PLANO PAS', value: 'PLANO PAS' },
  { label: 'PLANO SALVAR', value: 'PLANO SALVAR' },
  { label: 'PLANO SANTA CASA SAUDE DE TUPA', value: 'PLANO SANTA CASA SAUDE DE TUPA' },
  {
    label: 'PLANO SEGURO ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'PLANO SEGURO ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'PLANODENTE LTDA.', value: 'PLANODENTE LTDA.' },
  {
    label: 'PLANODONT SERVIÇOS ODONTOLOGICOS LTDA',
    value: 'PLANODONT SERVIÇOS ODONTOLOGICOS LTDA',
  },
  { label: 'PLANOS DE SAUDE PSMC', value: 'PLANOS DE SAUDE PSMC' },
  { label: 'PLANSFER', value: 'PLANSFER' },
  { label: 'PLANSUL', value: 'PLANSUL' },
  { label: 'PLASAC', value: 'PLASAC' },
  { label: 'PLASC', value: 'PLASC' },
  {
    label: 'PLATINUM ADMINISTRADORA DE BENEFICIOS',
    value: 'PLATINUM ADMINISTRADORA DE BENEFICIOS',
  },
  { label: 'PLENA SAUDE', value: 'PLENA SAUDE' },
  { label: 'PLENODONTO', value: 'PLENODONTO' },
  { label: 'PLURAL ADMINISTRADORA DE BENEFICIOS', value: 'PLURAL ADMINISTRADORA DE BENEFICIOS' },
  { label: 'PLURAL ASSISTENCIA ODONTOLOGICA.', value: 'PLURAL ASSISTENCIA ODONTOLOGICA.' },
  {
    label: 'PLURICLUB ADMINISTRADORA DE BENEFICIOS LTDA.',
    value: 'PLURICLUB ADMINISTRADORA DE BENEFICIOS LTDA.',
  },
  {
    label: 'POLI SAUDE OPERADORA DE PLANO DE SAUDE LTDA',
    value: 'POLI SAUDE OPERADORA DE PLANO DE SAUDE LTDA',
  },
  { label: 'POLICLIN SAUDE S/A.', value: 'POLICLIN SAUDE S/A.' },
  { label: 'POLICLINICA GRAMACHO LTDA - EPP', value: 'POLICLINICA GRAMACHO LTDA - EPP' },
  { label: 'POLIMEDICA SAUDE', value: 'POLIMEDICA SAUDE' },
  { label: 'POLYMED', value: 'POLYMED' },
  {
    label: 'PONTUAL SAUDE ASSISTENCIA MEDICA LTDA',
    value: 'PONTUAL SAUDE ASSISTENCIA MEDICA LTDA',
  },
  { label: 'PORTO ALEGRE CLINICAS S/S LTDA.', value: 'PORTO ALEGRE CLINICAS S/S LTDA.' },
  { label: 'PORTO DENTAL', value: 'PORTO DENTAL' },
  { label: 'PORTO DIAS SAUDE', value: 'PORTO DIAS SAUDE' },
  { label: 'PORTO SEGURO SAUDE', value: 'PORTO SEGURO SAUDE' },
  {
    label: 'PORTOMED - PORTO SEGURO SERVIÇOS DE SAUDE S/A',
    value: 'PORTOMED - PORTO SEGURO SERVIÇOS DE SAUDE S/A',
  },
  { label: 'POSTAL SAUDE', value: 'POSTAL SAUDE' },
  { label: 'PREMIUM SAUDE', value: 'PREMIUM SAUDE' },
  { label: 'PREST ODONTO LTDA', value: 'PREST ODONTO LTDA' },
  {
    label: 'PREV ODONTO ASSISTENCIA ODONTOLOGICA S/C LTDA.',
    value: 'PREV ODONTO ASSISTENCIA ODONTOLOGICA S/C LTDA.',
  },
  {
    label: 'PREV SAUDE - ASSOCIAÇAO DE ASSISTENCIA MEDICA PRIVADA',
    value: 'PREV SAUDE - ASSOCIAÇAO DE ASSISTENCIA MEDICA PRIVADA',
  },
  { label: 'PREV SYSTEM', value: 'PREV SYSTEM' },
  { label: 'PREV-DONT', value: 'PREV-DONT' },
  {
    label: 'PREVDONTO ODONTO EMPRESA ASSISTENCIA ODONTOLOGICA LTDA.',
    value: 'PREVDONTO ODONTO EMPRESA ASSISTENCIA ODONTOLOGICA LTDA.',
  },
  {
    label: 'PREVENT SENIOR CORPORATE OPERADORA DE SAUDE LTDA.',
    value: 'PREVENT SENIOR CORPORATE OPERADORA DE SAUDE LTDA.',
  },
  { label: 'PREVENT SENIOR I', value: 'PREVENT SENIOR I' },
  { label: 'PREVIDENT', value: 'PREVIDENT' },
  { label: 'PREVINA', value: 'PREVINA' },
  { label: 'A LA SANTE', value: 'A LA SANTE' },
  { label: 'PREVODOCTOR', value: 'PREVODOCTOR' },
  {
    label: 'PREVQUALI ADMINISTRADORA DE BENEFICIOS',
    value: 'PREVQUALI ADMINISTRADORA DE BENEFICIOS',
  },
  { label: 'PREVTOP', value: 'PREVTOP' },
  { label: 'PRIMA VIDA', value: 'PRIMA VIDA' },
  { label: 'PRO ODONTO', value: 'PRO ODONTO' },
  { label: 'PRO-SAUDE', value: 'PRO-SAUDE' },
  { label: 'PROASA PROGRAMA ADVENTISTA DE SAUDE', value: 'PROASA PROGRAMA ADVENTISTA DE SAUDE' },
  { label: 'PRODENT - ASSISTENCIA ODONTOLOGICA', value: 'PRODENT - ASSISTENCIA ODONTOLOGICA' },
  {
    label: 'PRODONTO, PRODONTINHO, ODONTOTAL E ODONTINHO',
    value: 'PRODONTO, PRODONTINHO, ODONTOTAL E ODONTINHO',
  },
  { label: 'PROGRAMA DE ASSISTENCIA MEDICA LIDER', value: 'PROGRAMA DE ASSISTENCIA MEDICA LIDER' },
  { label: 'PROI ODONTOLOGIA', value: 'PROI ODONTOLOGIA' },
  { label: 'PROJETO SORRIR OU PROSORRIR', value: 'PROJETO SORRIR OU PROSORRIR' },
  { label: 'PROMED ASSISTENCIA MEDICA', value: 'PROMED ASSISTENCIA MEDICA' },
  { label: 'PROMED ASSISTENCIA MEDICA LTDA', value: 'PROMED ASSISTENCIA MEDICA LTDA' },
  { label: 'PROMED I', value: 'PROMED I' },
  { label: 'PROMED ODONTOLOGICO', value: 'PROMED ODONTOLOGICO' },
  { label: 'PROMEDICA', value: 'PROMEDICA' },
  { label: 'PRONTO SERVICE', value: 'PRONTO SERVICE' },
  {
    label: 'PRONTO SOCORRO INFANTIL RODRIGUES DE AGUIAR',
    value: 'PRONTO SOCORRO INFANTIL RODRIGUES DE AGUIAR',
  },
  { label: 'PRONTOCLINICA', value: 'PRONTOCLINICA' },
  { label: 'PRONTOMED', value: 'PRONTOMED' },
  { label: 'PRONTOMED LTDA.', value: 'PRONTOMED LTDA.' },
  { label: 'PRONTOMED NOVO HAMBURGO LTDA', value: 'PRONTOMED NOVO HAMBURGO LTDA' },
  {
    label: 'PRONTOMEDICO PLANO DE ASSISTENCIA MEDICA LTDA',
    value: 'PRONTOMEDICO PLANO DE ASSISTENCIA MEDICA LTDA',
  },
  { label: 'PROVECTO SERVIÇOS ODONTOLOGICOS', value: 'PROVECTO SERVIÇOS ODONTOLOGICOS' },
  {
    label: 'PRUDENT PLANOS ODONTOLOGICOS S/S LTDA',
    value: 'PRUDENT PLANOS ODONTOLOGICOS S/S LTDA',
  },
  { label: 'PW HIDROPNEUMATICA LTDA', value: 'PW HIDROPNEUMATICA LTDA' },
  { label: 'QSAUDE', value: 'QSAUDE' },
  {
    label: 'QUALICORP ADMINISTRADORA DE BENEFICIOS S.A.',
    value: 'QUALICORP ADMINISTRADORA DE BENEFICIOS S.A.',
  },
  { label: 'QUALITEK', value: 'QUALITEK' },
  {
    label: 'QUALIVIDA ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'QUALIVIDA ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'A LA SANTE', value: 'A LA SANTE' },
  { label: 'QUALLITY PRO SAUDE', value: 'QUALLITY PRO SAUDE' },
  {
    label: 'QUANTICA ADMINISTRADORA DE BENEFICIOS DA SAUDE LTDA',
    value: 'QUANTICA ADMINISTRADORA DE BENEFICIOS DA SAUDE LTDA',
  },
  {
    label: 'RAMED ADMINISTRADORA DE BENEFICIOS LTDA.',
    value: 'RAMED ADMINISTRADORA DE BENEFICIOS LTDA.',
  },
  { label: 'RBS PARTICIPAÇOES S/A', value: 'RBS PARTICIPAÇOES S/A' },
  {
    label: 'REAL GRANDEZA - FUNDAÇAO DE PREVIDENCIA E ASSISTENCIA SOCIAL',
    value: 'REAL GRANDEZA - FUNDAÇAO DE PREVIDENCIA E ASSISTENCIA SOCIAL',
  },
  { label: 'REDE BRASIL VIDA', value: 'REDE BRASIL VIDA' },
  { label: 'REDE DENTAL', value: 'REDE DENTAL' },
  { label: 'REDEODONTO', value: 'REDEODONTO' },
  {
    label: 'RESOLVE ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'RESOLVE ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  {
    label: 'REUNIDAS OPERADORA DE PLANOS DE SAUDE LTDA.',
    value: 'REUNIDAS OPERADORA DE PLANOS DE SAUDE LTDA.',
  },
  {
    label: 'RIBERDENTE SERVIÇOS ODONTOLOGICOS S/S LTDA',
    value: 'RIBERDENTE SERVIÇOS ODONTOLOGICOS S/S LTDA',
  },
  { label: 'RINALDI CLINICA DE ODONTOLOGIA', value: 'RINALDI CLINICA DE ODONTOLOGIA' },
  { label: 'RISO PLANO ODONTOLOGICO', value: 'RISO PLANO ODONTOLOGICO' },
  { label: 'RIZZO ODONTOLOGIA LTDA.', value: 'RIZZO ODONTOLOGIA LTDA.' },
  { label: 'RN SAUDE', value: 'RN SAUDE' },
  { label: 'ROPE', value: 'ROPE' },
  { label: 'RS ODONTO', value: 'RS ODONTO' },
  {
    label: 'RS SAUDE GERENCIAMENTO E AUDITORIA EM SAUDE LTDA',
    value: 'RS SAUDE GERENCIAMENTO E AUDITORIA EM SAUDE LTDA',
  },
  { label: 'S & M SAUDE DENTAL', value: 'S & M SAUDE DENTAL' },
  { label: 'S1', value: 'S1' },
  { label: 'SABET SAUDE ABET', value: 'SABET SAUDE ABET' },
  { label: 'SADINCREMENTAL ODONTOLOGIA', value: 'SADINCREMENTAL ODONTOLOGIA' },
  { label: 'SADINCREMETAL', value: 'SADINCREMETAL' },
  {
    label: 'SALUPLAN ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'SALUPLAN ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'SALUTAR', value: 'SALUTAR' },
  { label: 'SAM-BEMAT', value: 'SAM-BEMAT' },
  { label: 'SAME', value: 'SAME' },
  { label: 'SAME I', value: 'SAME I' },
  { label: 'SAMED', value: 'SAMED' },
  { label: 'SAMEISA', value: 'SAMEISA' },
  { label: 'SAMEL', value: 'SAMEL' },
  { label: 'SAMI ASSISTENCIA MEDICA', value: 'SAMI ASSISTENCIA MEDICA' },
  {
    label: 'SAMIG - SERV.DE ASSISTENCIA MEDICA DA ILHA DO GOVERNADOR LTDA',
    value: 'SAMIG - SERV.DE ASSISTENCIA MEDICA DA ILHA DO GOVERNADOR LTDA',
  },
  { label: 'SAMOCc', value: 'SAMOCc' },
  { label: 'SAMP', value: 'SAMP' },
  { label: 'SAMP II', value: 'SAMP II' },
  { label: 'SANAMED SAUDE SANTO ANTONIO LTDA II', value: 'SANAMED SAUDE SANTO ANTONIO LTDA II' },
  { label: 'SANATORINHOS - SAUDE', value: 'SANATORINHOS - SAUDE' },
  { label: 'SANSAUDE', value: 'SANSAUDE' },
  { label: 'SANTA CASA CLINICAS', value: 'SANTA CASA CLINICAS' },
  { label: 'SANTA CASA DE MAUA', value: 'SANTA CASA DE MAUA' },
  {
    label: 'SANTA CASA DE MISERICORDIA DE BARRETOS',
    value: 'SANTA CASA DE MISERICORDIA DE BARRETOS',
  },
  {
    label: 'SANTA CASA DE MISERICORDIA DE ITAPEVA',
    value: 'SANTA CASA DE MISERICORDIA DE ITAPEVA',
  },
  {
    label: 'SANTA CASA DE MISERICORDIA DE JUIZ DE FORA',
    value: 'SANTA CASA DE MISERICORDIA DE JUIZ DE FORA',
  },
  { label: 'SANTA CASA DE MISERICORDIA DE PASSOS', value: 'SANTA CASA DE MISERICORDIA DE PASSOS' },
  {
    label: 'SANTA CASA DE MISERICORDIA DE PONTA GROSSA',
    value: 'SANTA CASA DE MISERICORDIA DE PONTA GROSSA',
  },
  {
    label: 'SANTA CASA DE MISERICORDIA DE SAO JOAQUIM DA BARRA',
    value: 'SANTA CASA DE MISERICORDIA DE SAO JOAQUIM DA BARRA',
  },
  {
    label: 'SANTA CASA DE MISERICORDIA DE SAO JOSE DO RIO PARDO - HOSPITAL S',
    value: 'SANTA CASA DE MISERICORDIA DE SAO JOSE DO RIO PARDO - HOSPITAL S',
  },
  {
    label: 'SANTA CASA DE MISERICORDIA DONA CAROLINA MALHEIROS',
    value: 'SANTA CASA DE MISERICORDIA DONA CAROLINA MALHEIROS',
  },
  {
    label: 'SANTA CASA DE MISERICORDIA E ASILO DOS POBRES DE BATATAIS',
    value: 'SANTA CASA DE MISERICORDIA E ASILO DOS POBRES DE BATATAIS',
  },
  { label: 'SANTA CASA DE MOCOCA', value: 'SANTA CASA DE MOCOCA' },
  { label: 'SANTA CASA S', value: 'SANTA CASA S' },
  { label: 'SANTA CASA SAUD', value: 'SANTA CASA SAUD' },
  { label: 'SANTA CASA SAUD I', value: 'SANTA CASA SAUD I' },
  { label: 'SANTA CASA SAUDE', value: 'SANTA CASA SAUDE' },
  { label: 'SANTA CASA SAUDE DE ARAÇATUBA', value: 'SANTA CASA SAUDE DE ARAÇATUBA' },
  { label: 'SANTA CASA SAUDE DE ARAÇATUBA I', value: 'SANTA CASA SAUDE DE ARAÇATUBA I' },
  { label: 'SANTA CASA SAUDE DE LEME', value: 'SANTA CASA SAUDE DE LEME' },
  { label: 'SANTA CASA SAUDE DE RIBEIRAO PRETO', value: 'SANTA CASA SAUDE DE RIBEIRAO PRETO' },
  {
    label: 'SANTA CASA SAUDE DE SAO JOSE DO RIO PRETO',
    value: 'SANTA CASA SAUDE DE SAO JOSE DO RIO PRETO',
  },
  { label: 'SANTA CASA SAUDE DE SOROCABA', value: 'SANTA CASA SAUDE DE SOROCABA' },
  { label: 'SANTA CASA SAUDE I', value: 'SANTA CASA SAUDE I' },
  { label: 'SANTA CASA SAUDE II', value: 'SANTA CASA SAUDE II' },
  { label: 'SANTA CASA SAUDE III', value: 'SANTA CASA SAUDE III' },
  { label: 'SANTA CASA SAUDE IIII', value: 'SANTA CASA SAUDE IIII' },
  { label: 'SANTA CLARA PLANOS DE SAUDE', value: 'SANTA CLARA PLANOS DE SAUDE' },
  { label: 'SANTA FILOMENA SAUDE', value: 'SANTA FILOMENA SAUDE' },
  { label: 'SANTA HELENA SAUDE', value: 'SANTA HELENA SAUDE' },
  { label: 'SANTA RITA SAUDE', value: 'SANTA RITA SAUDE' },
  { label: 'SANTAMALIA SAUDE S/A', value: 'SANTAMALIA SAUDE S/A' },
  { label: 'SANTAMED', value: 'SANTAMED' },
  { label: 'SANTELE', value: 'SANTELE' },
  { label: 'SAO BERNARDO SAUDE', value: 'SAO BERNARDO SAUDE' },
  { label: 'SAO DOMINGOS SAUDE', value: 'SAO DOMINGOS SAUDE' },
  { label: 'SAO FRANCISCO CLINICAS', value: 'SAO FRANCISCO CLINICAS' },
  { label: 'SAO FRANCISCO ODONTOLOGIA', value: 'SAO FRANCISCO ODONTOLOGIA' },
  { label: 'SAO FRANCISCO SAUDE II', value: 'SAO FRANCISCO SAUDE II' },
  { label: 'SAO FRANCISCO VIDA', value: 'SAO FRANCISCO VIDA' },
  { label: 'SAO LUCAS SAUDE', value: 'SAO LUCAS SAUDE' },
  { label: 'SAO LUCAS SAUDE I', value: 'SAO LUCAS SAUDE I' },
  { label: 'SAO LUIZ SAUDE', value: 'SAO LUIZ SAUDE' },
  { label: 'SAO MIGUEL SAUDE', value: 'SAO MIGUEL SAUDE' },
  { label: 'SAO PAULO TRANSPORTE S.A.', value: 'SAO PAULO TRANSPORTE S.A.' },
  { label: 'SAO VICENTE SAUDE', value: 'SAO VICENTE SAUDE' },
  { label: 'SAOS', value: 'SAOS' },
  { label: 'SAS', value: 'SAS' },
  {
    label: 'SASC SANTA CASA DE SAUDE COMPLEMENTAR',
    value: 'SASC SANTA CASA DE SAUDE COMPLEMENTAR',
  },
  { label: 'SAUDE - SISTEMA ASSISTENCIAL', value: 'SAUDE - SISTEMA ASSISTENCIAL' },
  { label: 'SAUDE BENEFICENCIA', value: 'SAUDE BENEFICENCIA' },
  { label: 'SAUDE BRB', value: 'SAUDE BRB' },
  { label: 'SAUDE CAM', value: 'SAUDE CAM' },
  { label: 'SAUDE CASSEB', value: 'SAUDE CASSEB' },
  { label: 'SAUDE CEAM', value: 'SAUDE CEAM' },
  { label: 'SAUDE CONCEIÇAO', value: 'SAUDE CONCEIÇAO' },
  { label: 'SAUDE DENTAL', value: 'SAUDE DENTAL' },
  {
    label: 'SAUDE E TUDO ASSISTENCIA ODONTOLOGICA LTDA.',
    value: 'SAUDE E TUDO ASSISTENCIA ODONTOLOGICA LTDA.',
  },
  { label: 'SAUDE FACIL', value: 'SAUDE FACIL' },
  { label: 'SAUDE GOIANIA LTDA', value: 'SAUDE GOIANIA LTDA' },
  { label: 'SAUDE GRANDE RIO', value: 'SAUDE GRANDE RIO' },
  { label: 'SAUDE INTERNACIONAL', value: 'SAUDE INTERNACIONAL' },
  { label: 'SAUDE MAIOR', value: 'SAUDE MAIOR' },
  { label: 'SAUDE MEDICOL S/A', value: 'SAUDE MEDICOL S/A' },
  { label: 'SAUDE PAS', value: 'SAUDE PAS' },
  { label: 'SAUDE REAL', value: 'SAUDE REAL' },
  { label: 'SAUDE RURAL', value: 'SAUDE RURAL' },
  { label: 'SAUDE RURALl', value: 'SAUDE RURALl' },
  { label: 'SAUDE SANTA GENOVEVA', value: 'SAUDE SANTA GENOVEVA' },
  { label: 'SAUDE SANTA TEREZA LTDA.', value: 'SAUDE SANTA TEREZA LTDA.' },
  { label: 'SAUDE SAO JOSE', value: 'SAUDE SAO JOSE' },
  { label: 'SAUDE SAO LUCAS', value: 'SAUDE SAO LUCAS' },
  { label: 'SAUDE SIM', value: 'SAUDE SIM' },
  { label: 'SAUDE UNIFENAS', value: 'SAUDE UNIFENAS' },
  {
    label: 'SAUDE VIDA - SMV SERVIÇOS MEDICOS LTDA',
    value: 'SAUDE VIDA - SMV SERVIÇOS MEDICOS LTDA',
  },
  { label: 'SBC SAUDE', value: 'SBC SAUDE' },
  { label: 'SEDEG', value: 'SEDEG' },
  { label: 'SEGUROS UNIMED', value: 'SEGUROS UNIMED' },
  { label: 'SEISA SERVIÇOS INTEGRADOS DE SAUDE', value: 'SEISA SERVIÇOS INTEGRADOS DE SAUDE' },
  { label: 'SEMPRE ODONTO', value: 'SEMPRE ODONTO' },
  {
    label: 'SEMTRA ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'SEMTRA ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'SEPACO AG', value: 'SEPACO AG' },
  { label: 'SEPACO SAUDEe', value: 'SEPACO SAUDEe' },
  { label: 'SER UNICO - PLANOS ODONTOLOGICOS', value: 'SER UNICO - PLANOS ODONTOLOGICOS' },
  {
    label: 'SERDIL- SERVIÇO ESPECIALIZADO EM RADIODIAGNOSTICO LTDA.',
    value: 'SERDIL- SERVIÇO ESPECIALIZADO EM RADIODIAGNOSTICO LTDA.',
  },
  { label: 'SERMED ODONTO', value: 'SERMED ODONTO' },
  { label: 'SERMED-SAUDE LTDA.', value: 'SERMED-SAUDE LTDA.' },
  { label: 'SERPRAM', value: 'SERPRAM' },
  { label: 'SERPRO', value: 'SERPRO' },
  { label: 'SERVBEM CONSULTORIA', value: 'SERVBEM CONSULTORIA' },
  { label: 'SERVMED SAUDE LTDA', value: 'SERVMED SAUDE LTDA' },
  { label: 'SESI - MG ODONTOVIDA', value: 'SESI - MG ODONTOVIDA' },
  { label: 'SHARING PLANOS ODONTOLOGICOS', value: 'SHARING PLANOS ODONTOLOGICOS' },
  { label: 'SILVESTRE SAUDE', value: 'SILVESTRE SAUDE' },
  {
    label: 'SIMPLES ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'SIMPLES ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'SIND-SAUDE', value: 'SIND-SAUDE' },
  { label: 'SINDESTIVASAUDE', value: 'SINDESTIVASAUDE' },
  { label: 'SINDO ODONTO', value: 'SINDO ODONTO' },
  { label: 'SISDENTE', value: 'SISDENTE' },
  { label: 'SISSAUDE ODONTO', value: 'SISSAUDE ODONTO' },
  {
    label: 'SISTEMA PAULISTA DE ASSISTENCIA SAUDE',
    value: 'SISTEMA PAULISTA DE ASSISTENCIA SAUDE',
  },
  {
    label: 'SISTEMAS ASSISTENCIA ODONTOLOGICA LTDA.',
    value: 'SISTEMAS ASSISTENCIA ODONTOLOGICA LTDA.',
  },
  { label: 'SISTEMAS E PLANOS DE SAUDE LTDA.', value: 'SISTEMAS E PLANOS DE SAUDE LTDA.' },
  { label: 'SITTO', value: 'SITTO' },
  { label: 'SLAM', value: 'SLAM' },
  { label: 'SM SAUDE', value: 'SM SAUDE' },
  { label: 'SM SERVIÇOS ODONTOLOGICOS S/C LTDA.', value: 'SM SERVIÇOS ODONTOLOGICOS S/C LTDA.' },
  { label: 'SMILE', value: 'SMILE' },
  { label: 'SO ODONTOLOGIA', value: 'SO ODONTOLOGIA' },
  {
    label: 'SO SAUDE ASSISTENCIA MEDICO HOSPITALAR LTDA',
    value: 'SO SAUDE ASSISTENCIA MEDICO HOSPITALAR LTDA',
  },
  { label: 'SOBAM CENTRO MEDICO HOSPITALAR LTDAa', value: 'SOBAM CENTRO MEDICO HOSPITALAR LTDAa' },
  {
    label: 'SOCIEDADE BENEFICENTE DEZOITO DE JULHO',
    value: 'SOCIEDADE BENEFICENTE DEZOITO DE JULHO',
  },
  {
    label: 'SOCIEDADE PORTUGUESA DE BENEFICENCIA.',
    value: 'SOCIEDADE PORTUGUESA DE BENEFICENCIA.',
  },
  { label: 'SOESP', value: 'SOESP' },
  { label: 'SOGELI PLANOS ODONTOLOGICOS LTDA.', value: 'SOGELI PLANOS ODONTOLOGICOS LTDA.' },
  { label: 'SOLIDENTE', value: 'SOLIDENTE' },
  { label: 'SOLLUS CORP', value: 'SOLLUS CORP' },
  { label: 'SOMPO SAUDE SEGUROS S/A', value: 'SOMPO SAUDE SEGUROS S/A' },
  { label: 'SOPREVI SAUDE BUCAL', value: 'SOPREVI SAUDE BUCAL' },
  { label: 'SORRIDEN', value: 'SORRIDEN' },
  { label: 'SORRIDONTO ODONTOLOGIA LTDA', value: 'SORRIDONTO ODONTOLOGIA LTDA' },
  { label: 'SORRISO ASSISTENCIA ODONTOLOGICA', value: 'SORRISO ASSISTENCIA ODONTOLOGICA' },
  { label: 'SORRISO BRASIL PLANO ODONTOLOGICO', value: 'SORRISO BRASIL PLANO ODONTOLOGICO' },
  { label: 'SORRISO OPERADORA ODONTOLOGICA LTDA', value: 'SORRISO OPERADORA ODONTOLOGICA LTDA' },
  { label: 'A LA SANTE', value: 'A LA SANTE' },
  { label: 'SORRISO TOTAL PLANO DE SADE', value: 'SORRISO TOTAL PLANO DE SADE' },
  { label: 'SORRISO UNIDENT', value: 'SORRISO UNIDENT' },
  { label: 'SPECIAL', value: 'SPECIAL' },
  { label: 'SSI SAUDE', value: 'SSI SAUDE' },
  { label: 'STSAUDE - SISTEMA TOTAL DE SAUDE', value: 'STSAUDE - SISTEMA TOTAL DE SAUDE' },
  { label: 'STYLLUS', value: 'STYLLUS' },
  {
    label: 'SUL AMERICA COMPANHIA DE SEGURO SAUDE',
    value: 'SUL AMERICA COMPANHIA DE SEGURO SAUDE',
  },
  { label: 'SUL AMERICA ODONTOLOGICO S/A', value: 'SUL AMERICA ODONTOLOGICO S/A' },
  {
    label: 'SUL AMERICA SAUDE COMPANHIA DE SEGUROS',
    value: 'SUL AMERICA SAUDE COMPANHIA DE SEGUROS',
  },
  { label: 'SUL AMERICA SERVIÇOS DE SAUDE S.A.', value: 'SUL AMERICA SERVIÇOS DE SAUDE S.A.' },
  { label: 'SULCLINICA', value: 'SULCLINICA' },
  { label: 'SULMED - ASSISTENCIA MEDICA LTDA', value: 'SULMED - ASSISTENCIA MEDICA LTDA' },
  { label: 'TAMPAS CLICK', value: 'TAMPAS CLICK' },
  {
    label: 'TELOS - FUNDAÇAO EMBRATEL DE SEGURIDADE SOCIAL',
    value: 'TELOS - FUNDAÇAO EMBRATEL DE SEGURIDADE SOCIAL',
  },
  { label: 'TEMPO DENTAL', value: 'TEMPO DENTAL' },
  { label: 'TEMPO SAUDE PARTICIPAÇOES', value: 'TEMPO SAUDE PARTICIPAÇOES' },
  {
    label: 'TEN ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'TEN ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: `TOP DOCTOR'S ODONTOLOGIA LTDA`, value: `TOP DOCTOR'S ODONTOLOGIA LTDA` },
  { label: 'TOPDONTO', value: 'TOPDONTO' },
  {
    label: 'TOTAL CLUBE DE BENEFICIOS ASSISTENCIAIS LTDA',
    value: 'TOTAL CLUBE DE BENEFICIOS ASSISTENCIAIS LTDA',
  },
  { label: 'TOTAL MEDCARE', value: 'TOTAL MEDCARE' },
  { label: 'TOTAL ODONTO', value: 'TOTAL ODONTO' },
  { label: 'TUIUIU ADM. DE PLANO DE SAUDE LTDA', value: 'TUIUIU ADM. DE PLANO DE SAUDE LTDA' },
  { label: 'UH SAUDE', value: 'UH SAUDE' },
  { label: 'UHG - UNITED HEALTH CARE', value: 'UHG - UNITED HEALTH CARE' },
  { label: 'UNAFISCO SAUD', value: 'UNAFISCO SAUD' },
  { label: 'UNIAO ADMINISTRADORA DE BENEFICIOS', value: 'UNIAO ADMINISTRADORA DE BENEFICIOS' },
  { label: 'UNIAO MEDICA', value: 'UNIAO MEDICA' },
  { label: 'UNIAO OPERARIA', value: 'UNIAO OPERARIA' },
  { label: 'UNIAO SAUDE', value: 'UNIAO SAUDE' },
  {
    label: 'UNIBEN ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'UNIBEN ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  {
    label: 'UNICA COOPERATIVA DE ATENDIMENTO ODOTOLOGICO LTDA',
    value: 'UNICA COOPERATIVA DE ATENDIMENTO ODOTOLOGICO LTDA',
  },
  { label: 'UNIDASODONTO LTDA.', value: 'UNIDASODONTO LTDA.' },
  { label: 'UNIDENTAL', value: 'UNIDENTAL' },
  { label: 'UNIDENTAL II', value: 'UNIDENTAL II' },
  {
    label: 'UNIDENTIS ASSISTENCIA ODONTOLOGICA LTDA',
    value: 'UNIDENTIS ASSISTENCIA ODONTOLOGICA LTDA',
  },
  { label: 'UNIDOCTOR', value: 'UNIDOCTOR' },
  { label: 'UNIDONTO', value: 'UNIDONTO' },
  { label: 'UNIDONTO SUL FLUMINENSE', value: 'UNIDONTO SUL FLUMINENSE' },
  {
    label: 'UNIFOCUS ADMINISTRADORA DE BENEFICIOS S.A.',
    value: 'UNIFOCUS ADMINISTRADORA DE BENEFICIOS S.A.',
  },
  { label: 'UNIHOSP', value: 'UNIHOSP' },
  { label: 'UNIHOSP II', value: 'UNIHOSP II' },
  { label: 'UNILIFE SAUDE', value: 'UNILIFE SAUDE' },
  { label: 'UNIMED (RS) ERECHIM', value: 'UNIMED (RS) ERECHIM' },
  { label: 'UNIMED (RS) VALE DO SINOS', value: 'UNIMED (RS) VALE DO SINOS' },
  {
    label: 'UNIMED - COOP. DE SERV. DE SAUDE VALES DO TAQUARI E RIO PARDO LTDA.',
    value: 'UNIMED - COOP. DE SERV. DE SAUDE VALES DO TAQUARI E RIO PARDO LTDA.',
  },
  {
    label: 'UNIMED - INTRAFEDERATIVA DO VALE DO PARAIBA',
    value: 'UNIMED - INTRAFEDERATIVA DO VALE DO PARAIBA',
  },
  {
    label: 'UNIMED AGRESTE MERIDIONAL - COOPERATIVA DE TRABALHO MEDICO LTDA',
    value: 'UNIMED AGRESTE MERIDIONAL - COOPERATIVA DE TRABALHO MEDICO LTDA',
  },
  {
    label: 'UNIMED ALEGRETE/RS - SOCIEDADE COOPERATIVA DE ASSISTENCIA A SAUD',
    value: 'UNIMED ALEGRETE/RS - SOCIEDADE COOPERATIVA DE ASSISTENCIA A SAUD',
  },
  { label: 'UNIMED ALEM PARAIBA', value: 'UNIMED ALEM PARAIBA' },
  { label: 'UNIMED ALFENAS', value: 'UNIMED ALFENAS' },
  { label: 'UNIMED ALTO DA SERRA', value: 'UNIMED ALTO DA SERRA' },
  { label: 'UNIMED ALTO JACUI', value: 'UNIMED ALTO JACUI' },
  { label: 'UNIMED ALTO PARANAIBA LTDA', value: 'UNIMED ALTO PARANAIBA LTDA' },
  { label: 'UNIMED ALTO SAO FRANCISCO', value: 'UNIMED ALTO SAO FRANCISCO' },
  { label: 'UNIMED ALTO URUGUAI/RS', value: 'UNIMED ALTO URUGUAI/RS' },
  { label: 'UNIMED ALTO VALE', value: 'UNIMED ALTO VALE' },
  { label: 'UNIMED AMPARO', value: 'UNIMED AMPARO' },
  { label: 'UNIMED ANAPOLIS', value: 'UNIMED ANAPOLIS' },
  { label: 'UNIMED ANDRADAS', value: 'UNIMED ANDRADAS' },
  { label: 'UNIMED ANGRA DOS REIS', value: 'UNIMED ANGRA DOS REIS' },
  { label: 'UNIMED APUCARANA', value: 'UNIMED APUCARANA' },
  { label: 'UNIMED ARAGUAIA', value: 'UNIMED ARAGUAIA' },
  { label: 'UNIMED ARAGUARI', value: 'UNIMED ARAGUARI' },
  {
    label: 'UNIMED ARARUAMA COOPERATIVA DE TRABALHO MEDICO LTDA.',
    value: 'UNIMED ARARUAMA COOPERATIVA DE TRABALHO MEDICO LTDA.',
  },
  { label: 'UNIMED ARAXA', value: 'UNIMED ARAXA' },
  { label: 'UNIMED ARIQUEMES', value: 'UNIMED ARIQUEMES' },
  { label: 'UNIMED BAIXA MOGIANA', value: 'UNIMED BAIXA MOGIANA' },
  { label: 'UNIMED BARBACENA', value: 'UNIMED BARBACENA' },
  { label: 'UNIMED BARRA DO PIRAI', value: 'UNIMED BARRA DO PIRAI' },
  { label: 'UNIMED BAURU', value: 'UNIMED BAURU' },
  { label: 'UNIMED BH', value: 'UNIMED BH' },
  { label: 'UNIMED BOTUCATU', value: 'UNIMED BOTUCATU' },
  { label: 'UNIMED CABO FRIO', value: 'UNIMED CABO FRIO' },
  { label: 'UNIMED CACERES', value: 'UNIMED CACERES' },
  {
    label: 'UNIMED CAJAZEIRAS - COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED CAJAZEIRAS - COOPERATIVA DE TRABALHO MEDICO',
  },
  {
    label: 'UNIMED CAMPINA GRANDE - COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED CAMPINA GRANDE - COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED CAMPINAS', value: 'UNIMED CAMPINAS' },
  {
    label: 'UNIMED CAMPO BELO- COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED CAMPO BELO- COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED CAMPO GRANDE', value: 'UNIMED CAMPO GRANDE' },
  {
    label: 'UNIMED CARATINGA - COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED CARATINGA - COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED CARUARU', value: 'UNIMED CARUARU' },
  {
    label: 'UNIMED CATAGUASES COOPERATIVA DE TRABALHO MEDICO LTDA',
    value: 'UNIMED CATAGUASES COOPERATIVA DE TRABALHO MEDICO LTDA',
  },
  { label: 'UNIMED CATANDUVA', value: 'UNIMED CATANDUVA' },
  { label: 'UNIMED CAÇADOR', value: 'UNIMED CAÇADOR' },
  {
    label: 'UNIMED CENTRO OESTE PAULISTA - FEDERACAO INTRAFEDERATIVA DAS COO',
    value: 'UNIMED CENTRO OESTE PAULISTA - FEDERACAO INTRAFEDERATIVA DAS COO',
  },
  { label: 'UNIMED CENTRO PAULISTA', value: 'UNIMED CENTRO PAULISTA' },
  {
    label: 'UNIMED CENTRO-OESTE E TOCANTINS',
    value: 'UNIMED CENTRO-OESTE E TOCANTINS',
  },
  { label: 'UNIMED CENTRO-OESTE II', value: 'UNIMED CENTRO-OESTE II' },
  { label: 'UNIMED CERRADO', value: 'UNIMED CERRADO' },
  { label: 'UNIMED CHAPECO', value: 'UNIMED CHAPECO' },
  { label: 'UNIMED CIRCUITO DAS AGUAS', value: 'UNIMED CIRCUITO DAS AGUAS' },
  { label: 'UNIMED CONSELHEIRO LAFAIETE', value: 'UNIMED CONSELHEIRO LAFAIETE' },
  { label: 'UNIMED COSTA DO DESCOBRIMENTO', value: 'UNIMED COSTA DO DESCOBRIMENTO' },
  { label: 'UNIMED COSTA DO SOL', value: 'UNIMED COSTA DO SOL' },
  { label: 'UNIMED COSTA OESTE', value: 'UNIMED COSTA OESTE' },
  { label: 'UNIMED COSTA VERDE', value: 'UNIMED COSTA VERDE' },
  { label: 'UNIMED CRICIUMA', value: 'UNIMED CRICIUMA' },
  { label: 'UNIMED CRUZEIRO II', value: 'UNIMED CRUZEIRO II' },
  { label: 'UNIMED CUIABA', value: 'UNIMED CUIABA' },
  {
    label: 'UNIMED CURITIBA - SOCIEDADE COOPERATIVA DE MEDICOS.',
    value: 'UNIMED CURITIBA - SOCIEDADE COOPERATIVA DE MEDICOS.',
  },
  {
    label: 'UNIMED DAS ESTANCIAS PAULISTAS OPERADORA DE PLANOS DE SAUDE, SOC COOPERATIVA',
    value: 'UNIMED DAS ESTANCIAS PAULISTAS OPERADORA DE PLANOS DE SAUDE, SOC COOPERATIVA',
  },
  { label: 'UNIMED DE ADAMANTINA', value: 'UNIMED DE ADAMANTINA' },
  {
    label: 'UNIMED DE ANDRADINA COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED DE ANDRADINA COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED DE ARACATUBA', value: 'UNIMED DE ARACATUBA' },
  { label: 'UNIMED DE ARAGUAINA', value: 'UNIMED DE ARAGUAINA' },
  { label: 'UNIMED DE ARARAQUARA', value: 'UNIMED DE ARARAQUARA' },
  {
    label: 'UNIMED DE ARARAS COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED DE ARARAS COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED DE ASSIS', value: 'UNIMED DE ASSIS' },
  { label: 'UNIMED DE AVARE', value: 'UNIMED DE AVARE' },
  { label: 'UNIMED DE BARRA MANSA', value: 'UNIMED DE BARRA MANSA' },
  { label: 'UNIMED DE BARRETOS', value: 'UNIMED DE BARRETOS' },
  { label: 'UNIMED DE BATATAIS', value: 'UNIMED DE BATATAIS' },
  { label: 'UNIMED DE BEBEDOURO', value: 'UNIMED DE BEBEDOURO' },
  { label: 'UNIMED DE BELEM', value: 'UNIMED DE BELEM' },
  { label: 'UNIMED DE BIRIGUI', value: 'UNIMED DE BIRIGUI' },
  { label: 'UNIMED DE BLUMENAU', value: 'UNIMED DE BLUMENAU' },
  {
    label: 'UNIMED DE BOA VISTA COOP TRABALHO MEDICO',
    value: 'UNIMED DE BOA VISTA COOP TRABALHO MEDICO',
  },
  {
    label: 'UNIMED DE BRUSQUE COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED DE BRUSQUE COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED DE CALDAS NOVAS', value: 'UNIMED DE CALDAS NOVAS' },
  { label: 'UNIMED DE CAMPOS', value: 'UNIMED DE CAMPOS' },
  {
    label: 'UNIMED DE CAPIVARI -COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED DE CAPIVARI -COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED DE CASCAVEL', value: 'UNIMED DE CASCAVEL' },
  { label: 'UNIMED DE CATALÃO', value: 'UNIMED DE CATALÃO' },
  { label: 'UNIMED DE CAÇAPAVA - COOPERATIVA DE TRABALHO MÉDICO', value: 'UNIMED DE CAÇAPAVA' },
  { label: 'UNIMED DE CIANORTE COOPERATIVA DE TRABALHO MÉDICO LTDA', value: 'UNIMED DE CIANORTE' },
  { label: 'UNIMED DE CORUMBÁ', value: 'UNIMED DE CORUMBÁ' },
  { label: 'UNIMED DE DOURADOS', value: 'UNIMED DE DOURADOS' },
  { label: 'UNIMED DE DRACENA', value: 'UNIMED DE DRACENA' },
  { label: 'UNIMED DE FEIRA DE SANTANA', value: 'UNIMED DE FEIRA DE SANTANA' },
  { label: 'UNIMED DE FERNANDÓPOLIS', value: 'UNIMED DE FERNANDÓPOLIS' },
  { label: 'UNIMED DE FORTALEZA', value: 'UNIMED DE FORTALEZA' },
  { label: 'UNIMED DE FRANCA', value: 'UNIMED DE FRANCA' },
  {
    label: 'UNIMED DE GUARATINGUETÁ - COOPERATIVA DE TRABALHO MÉDICO',
    value: 'UNIMED DE GUARATINGUETÁ',
  },
  { label: 'UNIMED DE GUARULHOS', value: 'UNIMED DE GUARULHOS' },
  { label: 'UNIMED DE IBITINGA', value: 'UNIMED DE IBITINGA' },
  { label: 'UNIMED DE ITAPETININGA', value: 'UNIMED DE ITAPETININGA' },
  { label: 'UNIMED DE ITAPEVA', value: 'UNIMED DE ITAPEVA' },
  { label: 'UNIMED DE ITUVERAVA', value: 'UNIMED DE ITUVERAVA' },
  { label: 'UNIMED DE JABOTICABAL', value: 'UNIMED DE JABOTICABAL' },
  { label: 'UNIMED DE JATAÍ', value: 'UNIMED DE JATAÍ' },
  { label: 'UNIMED DE JEQUIÉ COOPERATIVA DE TRABALHO MÉDICO', value: 'UNIMED DE JEQUIÉ' },
  { label: 'UNIMED DE JOINVILLE', value: 'UNIMED DE JOINVILLE' },
  { label: 'UNIMED DE LENÇÓIS PAULISTA', value: 'UNIMED DE LENÇÓIS PAULISTA' },
  { label: 'UNIMED DE LINS', value: 'UNIMED DE LINS' },
  { label: 'UNIMED DE LONDRINA', value: 'UNIMED DE LONDRINA' },
  { label: 'UNIMED DE LORENA COOPERATIVA DE TRABALHO MÉDICO', value: 'UNIMED DE LORENA' },
  { label: 'UNIMED DE MANAUS', value: 'UNIMED DE MANAUS' },
  { label: 'UNIMED DE MARÍLIA', value: 'UNIMED DE MARÍLIA' },
  { label: 'UNIMED DE MINEIROS', value: 'UNIMED DE MINEIROS' },
  { label: 'UNIMED DE MOCOCA', value: 'UNIMED DE MOCOCA' },
  { label: 'UNIMED DE MONTE ALTO', value: 'UNIMED DE MONTE ALTO' },
  { label: 'UNIMED DE ORLÂNDIA', value: 'UNIMED DE ORLÂNDIA' },
  { label: 'UNIMED DE OURINHOS', value: 'UNIMED DE OURINHOS' },
  { label: 'UNIMED DE PARANAGUÁ', value: 'UNIMED DE PARANAGUÁ' },
  { label: 'UNIMED DE PARANAVAI', value: 'UNIMED DE PARANAVAI' },
  { label: 'UNIMED DE PASSO FUNDO', value: 'UNIMED DE PASSO FUNDO' },
  { label: 'UNIMED DE CASCAVEL', value: 'UNIMED DE CASCAVEL' },
  { label: 'UNIMED DE CATALÃO', value: 'UNIMED DE CATALÃO' },
  { label: 'UNIMED DE CAÇAPAVA - COOPERATIVA DE TRABALHO MÉDICO', value: 'UNIMED DE CAÇAPAVA' },
  { label: 'UNIMED DE CIANORTE COOPERATIVA DE TRABALHO MÉDICO LTDA', value: 'UNIMED DE CIANORTE' },
  { label: 'UNIMED DE CORUMBÁ', value: 'UNIMED DE CORUMBÁ' },
  { label: 'UNIMED DE DOURADOS', value: 'UNIMED DE DOURADOS' },
  { label: 'UNIMED DE DRACENA', value: 'UNIMED DE DRACENA' },
  { label: 'UNIMED DE FEIRA DE SANTANA', value: 'UNIMED DE FEIRA DE SANTANA' },
  { label: 'UNIMED DE FERNANDÓPOLIS', value: 'UNIMED DE FERNANDÓPOLIS' },
  { label: 'UNIMED DE FORTALEZA', value: 'UNIMED DE FORTALEZA' },
  { label: 'UNIMED DE FRANCA', value: 'UNIMED DE FRANCA' },
  {
    label: 'UNIMED DE GUARATINGUETÁ - COOPERATIVA DE TRABALHO MÉDICO',
    value: 'UNIMED DE GUARATINGUETÁ',
  },
  { label: 'UNIMED DE GUARULHOS', value: 'UNIMED DE GUARULHOS' },
  { label: 'UNIMED DE IBITINGA', value: 'UNIMED DE IBITINGA' },
  { label: 'UNIMED DE ITAPETININGA', value: 'UNIMED DE ITAPETININGA' },
  { label: 'UNIMED DE ITAPEVA', value: 'UNIMED DE ITAPEVA' },
  { label: 'UNIMED DE ITUVERAVA', value: 'UNIMED DE ITUVERAVA' },
  { label: 'UNIMED DE JABOTICABAL', value: 'UNIMED DE JABOTICABAL' },
  { label: 'UNIMED DE JATAÍ', value: 'UNIMED DE JATAÍ' },
  { label: 'UNIMED DE JEQUIÉ COOPERATIVA DE TRABALHO MÉDICO', value: 'UNIMED DE JEQUIÉ' },
  { label: 'UNIMED DE JOINVILLE', value: 'UNIMED DE JOINVILLE' },
  { label: 'UNIMED DE LENÇÓIS PAULISTA', value: 'UNIMED DE LENÇÓIS PAULISTA' },
  { label: 'UNIMED DE LINS', value: 'UNIMED DE LINS' },
  { label: 'UNIMED DE LONDRINA', value: 'UNIMED DE LONDRINA' },
  { label: 'UNIMED DE LORENA COOPERATIVA DE TRABALHO MÉDICO', value: 'UNIMED DE LORENA' },
  { label: 'UNIMED DE MANAUS', value: 'UNIMED DE MANAUS' },
  { label: 'UNIMED DE MARÍLIA', value: 'UNIMED DE MARÍLIA' },
  { label: 'UNIMED DE MINEIROS', value: 'UNIMED DE MINEIROS' },
  { label: 'UNIMED DE MOCOCA', value: 'UNIMED DE MOCOCA' },
  { label: 'UNIMED DE MONTE ALTO', value: 'UNIMED DE MONTE ALTO' },
  { label: 'UNIMED DE ORLÂNDIA', value: 'UNIMED DE ORLÂNDIA' },
  { label: 'UNIMED DE OURINHOS', value: 'UNIMED DE OURINHOS' },
  { label: 'UNIMED DE PARANAGUÁ', value: 'UNIMED DE PARANAGUÁ' },
  { label: 'UNIMED DE PARANAVAI', value: 'UNIMED DE PARANAVAI' },
  {
    label: 'UNIMED DE PENÁPOLIS COOP. TRAB. MÉDICO',
    value: 'UNIMED DE PENÁPOLIS COOP. TRAB. MÉDICO',
  },
  { label: 'UNIMED DE PIRACICABA', value: 'UNIMED DE PIRACICABA' },
  {
    label: 'UNIMED DE PIRASSUNUNGA-COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED DE PIRASSUNUNGA-COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED DE PRESIDENTE PRUDENTE', value: 'UNIMED DE PRESIDENTE PRUDENTE' },
  { label: 'UNIMED DE REGISTRO', value: 'UNIMED DE REGISTRO' },
  { label: 'UNIMED DE RIBEIRÃO PRETO', value: 'UNIMED DE RIBEIRÃO PRETO' },
  { label: 'UNIMED DE RIO CLARO SP', value: 'UNIMED DE RIO CLARO SP' },
  {
    label: "UNIMED DE SANTA BÁRBARA D'OESTE E AMERICANA - COOP DE TRABALHO M",
    value: "UNIMED DE SANTA BÁRBARA D'OESTE E AMERICANA - COOP DE TRABALHO M",
  },
  {
    label: 'UNIMED DE SANTOS - COOP DE TRAB MEDICO',
    value: 'UNIMED DE SANTOS - COOP DE TRAB MEDICO',
  },
  { label: 'UNIMED DE SÃO JOSÉ DO RIO PARDO', value: 'UNIMED DE SÃO JOSÉ DO RIO PARDO' },
  { label: 'UNIMED DE SERTAOZINHO', value: 'UNIMED DE SERTAOZINHO' },
  { label: 'UNIMED DE SOBRAL', value: 'UNIMED DE SOBRAL' },
  { label: 'UNIMED DE TRÊS LAGOAS', value: 'UNIMED DE TRÊS LAGOAS' },
  { label: 'UNIMED DE TUBARÃO', value: 'UNIMED DE TUBARÃO' },
  { label: 'UNIMED DE TUPA', value: 'UNIMED DE TUPA' },
  { label: 'UNIMED DE UMUARAMA', value: 'UNIMED DE UMUARAMA' },
  {
    label: 'UNIMED DE VOLTA REDONDA COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED DE VOLTA REDONDA COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED DE VOTUPORANGA', value: 'UNIMED DE VOTUPORANGA' },
  { label: 'UNIMED DETAUBATÉ', value: 'UNIMED DETAUBATÉ' },
  { label: 'UNIMED DIVINÓPOLIS II', value: 'UNIMED DIVINÓPOLIS II' },
  {
    label: 'UNIMED DO ABC - COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED DO ABC - COOPERATIVA DE TRABALHO MEDICO',
  },
  {
    label: 'UNIMED DO BRASIL - CONF. NACIONAL DAS COOPERATIVAS MÉDICAS',
    value: 'UNIMED DO BRASIL - CONF. NACIONAL DAS COOPERATIVAS MÉDICAS',
  },
  { label: 'UNIMED DO CARIRI', value: 'UNIMED DO CARIRI' },
  { label: 'UNIMED DO CEARÁ', value: 'UNIMED DO CEARÁ' },
  { label: 'UNIMED DO ESTADO DE SANTA CATARINA', value: 'UNIMED DO ESTADO DE SANTA CATARINA' },
  {
    label: 'UNIMED DO GUARUJA COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED DO GUARUJA COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED DO OESTE DA BAHIA', value: 'UNIMED DO OESTE DA BAHIA' },
  {
    label: 'UNIMED DO OESTE DO PARANÁ - COOP. DE TRABALHO MEDICO',
    value: 'UNIMED DO OESTE DO PARANÁ - COOP. DE TRABALHO MEDICO',
  },
  { label: 'UNIMED DO SUDOESTE', value: 'UNIMED DO SUDOESTE' },
  {
    label: 'UNIMED DO VALE DO SEPOTUBA - COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED DO VALE DO SEPOTUBA - COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED ENCOSTA DA SERRA', value: 'UNIMED ENCOSTA DA SERRA' },
  { label: 'UNIMED EXTREMO OESTE CATARINENSE', value: 'UNIMED EXTREMO OESTE CATARINENSE' },
  {
    label: 'UNIMED EXTREMO SUL COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED EXTREMO SUL COOPERATIVA DE TRABALHO MEDICO',
  },
  {
    label: 'UNIMED FEDERAÇAO DO ESTADO DO MATO GROSSO',
    value: 'UNIMED FEDERAÇAO DO ESTADO DO MATO GROSSO',
  },
  { label: 'UNIMED FEDERAÇAO RIO', value: 'UNIMED FEDERAÇAO RIO' },
  {
    label: 'UNIMED FRANCISCO BELTRAO COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED FRANCISCO BELTRAO COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED FRONTEIRA NOROESTE/RS', value: 'UNIMED FRONTEIRA NOROESTE/RS' },
  {
    label: 'UNIMED FRUTAL COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED FRUTAL COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED GERAIS DE MINAS', value: 'UNIMED GERAIS DE MINAS' },
  { label: 'UNIMED GOIANIA II', value: 'UNIMED GOIANIA II' },
  { label: 'UNIMED GRANDE FLORIANOPOLIS', value: 'UNIMED GRANDE FLORIANOPOLIS' },
  { label: 'UNIMED GUARAPUAVA', value: 'UNIMED GUARAPUAVA' },
  { label: 'UNIMED GUAXUPE', value: 'UNIMED GUAXUPE' },
  { label: 'UNIMED GURUPI', value: 'UNIMED GURUPI' },
  { label: 'UNIMED GV COOP TRABALHO MEDICO LTDA', value: 'UNIMED GV COOP TRABALHO MEDICO LTDA' },
  {
    label: 'UNIMED ILHEUS COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED ILHEUS COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED IMPERATRIZ', value: 'UNIMED IMPERATRIZ' },
  { label: 'UNIMED INCONFIDENTES', value: 'UNIMED INCONFIDENTES' },
  {
    label: 'UNIMED ITABIRA COOPERATIVA DE TRABALHO MEDICO II',
    value: 'UNIMED ITABIRA COOPERATIVA DE TRABALHO MEDICO II',
  },
  { label: 'UNIMED ITABUNA', value: 'UNIMED ITABUNA' },
  {
    label: 'UNIMED ITAJUBA COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED ITAJUBA COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED ITAQUI/RS', value: 'UNIMED ITAQUI/RS' },
  { label: 'UNIMED ITATIBA', value: 'UNIMED ITATIBA' },
  { label: 'UNIMED ITAUNA', value: 'UNIMED ITAUNA' },
  { label: 'UNIMED ITUIUTABA', value: 'UNIMED ITUIUTABA' },
  { label: 'UNIMED JI PARANA', value: 'UNIMED JI PARANA' },
  { label: 'UNIMED JOAO MONLEVADE', value: 'UNIMED JOAO MONLEVADE' },
  {
    label: 'UNIMED JOAO PESSOA - COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED JOAO PESSOA - COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED JOAÇABA', value: 'UNIMED JOAÇABA' },
  {
    label: 'UNIMED JUIZ DE FORA COOPERATIVA DE TRABALHO MEDICO LTDA',
    value: 'UNIMED JUIZ DE FORA COOPERATIVA DE TRABALHO MEDICO LTDA',
  },
  { label: 'UNIMED JUNDIAI', value: 'UNIMED JUNDIAI' },
  { label: 'UNIMED LAVRAS', value: 'UNIMED LAVRAS' },
  { label: 'UNIMED LEOPLDINA', value: 'UNIMED LEOPLDINA' },
  { label: 'UNIMED LESTE FLUMINENSE II', value: 'UNIMED LESTE FLUMINENSE II' },
  {
    label: 'UNIMED LESTE PAULISTA COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED LESTE PAULISTA COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED LIMEIRA', value: 'UNIMED LIMEIRA' },
  { label: 'UNIMED LITORAL', value: 'UNIMED LITORAL' },
  { label: 'UNIMED LITORAL SUL/RS', value: 'UNIMED LITORAL SUL/RS' },
  { label: 'UNIMED MACAPÁ', value: 'UNIMED MACAPÁ' },
  {
    label: 'UNIMED MACEIÓ COOPERATIVA DE TRABALHO MÉDICO',
    value: 'UNIMED MACEIÓ COOPERATIVA DE TRABALHO MÉDICO',
  },
  { label: 'UNIMED MACHADO', value: 'UNIMED MACHADO' },
  { label: 'UNIMED MARQUES DE VALENÇA', value: 'UNIMED MARQUES DE VALENÇA' },
  { label: 'UNIMED METROPOLITANA DO AGRESTE', value: 'UNIMED METROPOLITANA DO AGRESTE' },
  { label: 'UNIMED MG', value: 'UNIMED MG' },
  { label: 'UNIMED MISSOES/RS', value: 'UNIMED MISSOES/RS' },
  { label: 'UNIMED MONTE CARMELO', value: 'UNIMED MONTE CARMELO' },
  { label: 'UNIMED MORRINHOS', value: 'UNIMED MORRINHOS' },
  { label: 'UNIMED MOSSORÓ', value: 'UNIMED MOSSORÓ' },
  {
    label: 'UNIMED MURIAE COOPERATIVA DE TRABALHO MEDICO LTDA',
    value: 'UNIMED MURIAE COOPERATIVA DE TRABALHO MEDICO LTDA',
  },
  {
    label: 'UNIMED NATAL SOCIEDADE COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED NATAL SOCIEDADE COOPERATIVA DE TRABALHO MEDICO',
  },
  {
    label: 'UNIMED NORDESTE PAULISTA FEDERAÇAO INTRAFED.COOP.MEDICAS',
    value: 'UNIMED NORDESTE PAULISTA FEDERAÇAO INTRAFED.COOP.MEDICAS',
  },
  {
    label: 'UNIMED NORDESTE RS SOCIEDADE COOPERATIVA DE SERVIÇOS MEDICOS LTD',
    value: 'UNIMED NORDESTE RS SOCIEDADE COOPERATIVA DE SERVIÇOS MEDICOS LTD',
  },
  {
    label: 'UNIMED NOROESTE CAPIXABA COOPERATIVA DE TRABALHO MEDICO.',
    value: 'UNIMED NOROESTE CAPIXABA COOPERATIVA DE TRABALHO MEDICO.',
  },
  { label: 'UNIMED NOROESTE DE MINAS', value: 'UNIMED NOROESTE DE MINAS' },
  { label: 'UNIMED NOROESTE FLUMINENSE', value: 'UNIMED NOROESTE FLUMINENSE' },
  { label: 'UNIMED NOROESTE/RS', value: 'UNIMED NOROESTE/RS' },
  {
    label: 'UNIMED NORTE CAPIXABA- COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED NORTE CAPIXABA- COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED NORTE DE MINAS', value: 'UNIMED NORTE DE MINAS' },
  { label: 'UNIMED NORTE DO MATO GROSSO', value: 'UNIMED NORTE DO MATO GROSSO' },
  { label: 'UNIMED NORTE DO PARANÁ', value: 'UNIMED NORTE DO PARANÁ' },
  { label: 'UNIMED NORTE FLUMINENSE', value: 'UNIMED NORTE FLUMINENSE' },
  {
    label: 'UNIMED NORTE PIONEIRO - COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED NORTE PIONEIRO - COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED NORTE/NORDESTE', value: 'UNIMED NORTE/NORDESTE' },
  { label: 'UNIMED NOVA IGUAÇU', value: 'UNIMED NOVA IGUAÇU' },
  { label: 'UNIMED ODONTO', value: 'UNIMED ODONTO' },
  {
    label: 'UNIMED OESTE DO PARA COOP TRABALHO MEDICO',
    value: 'UNIMED OESTE DO PARA COOP TRABALHO MEDICO',
  },
  { label: 'UNIMED PALMAS', value: 'UNIMED PALMAS' },
  { label: 'UNIMED PALMEIRA DOS INDIOS', value: 'UNIMED PALMEIRA DOS INDIOS' },
  { label: 'UNIMED PARAIBA', value: 'UNIMED PARAIBA' },
  { label: 'UNIMED PARANA', value: 'UNIMED PARANA' },
  { label: 'UNIMED PATO BRANCO', value: 'UNIMED PATO BRANCO' },
  { label: 'UNIMED PATOS', value: 'UNIMED PATOS' },
  {
    label: 'UNIMED PATOS DE MINAS COOPERATIVA TRABALHO MEDICO',
    value: 'UNIMED PATOS DE MINAS COOPERATIVA TRABALHO MEDICO',
  },
  { label: 'UNIMED PATROCINIO', value: 'UNIMED PATROCINIO' },
  {
    label: 'UNIMED PAULISTANA SOCIEDADE COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED PAULISTANA SOCIEDADE COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED PAULO AFONSO', value: 'UNIMED PAULO AFONSO' },
  { label: 'UNIMED PERNAMBUCANA', value: 'UNIMED PERNAMBUCANA' },
  { label: 'UNIMED PERNAMBUCO CENTRAL', value: 'UNIMED PERNAMBUCO CENTRAL' },
  { label: 'UNIMED PETROPOLIS', value: 'UNIMED PETROPOLIS' },
  { label: 'UNIMED PIAUI', value: 'UNIMED PIAUI' },
  { label: 'UNIMED PINDAMONHANGABA', value: 'UNIMED PINDAMONHANGABA' },
  {
    label: 'UNIMED PIRAQUEAÇU COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED PIRAQUEAÇU COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED PLANALTO', value: 'UNIMED PLANALTO' },
  { label: 'UNIMED PLANALTO CENTRAL RS', value: 'UNIMED PLANALTO CENTRAL RS' },
  { label: 'UNIMED PLANALTO MEDIO', value: 'UNIMED PLANALTO MEDIO' },
  { label: 'UNIMED PLANALTO NORTE', value: 'UNIMED PLANALTO NORTE' },
  { label: 'UNIMED POCOS DE CALDAS', value: 'UNIMED POCOS DE CALDAS' },
  { label: 'UNIMED PONTA GROSSA', value: 'UNIMED PONTA GROSSA' },
  { label: 'UNIMED PONTAL DO TRIANGULO', value: 'UNIMED PONTAL DO TRIANGULO' },
  { label: 'UNIMED PONTE NOVA E REGIAO', value: 'UNIMED PONTE NOVA E REGIAO' },
  {
    label: 'UNIMED PORTO ALEGRE - COOPERATIVA MEDICA',
    value: 'UNIMED PORTO ALEGRE - COOPERATIVA MEDICA',
  },
  {
    label: 'UNIMED RECIFE COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED RECIFE COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED REGIAO DA CAMPANHA - RS', value: 'UNIMED REGIAO DA CAMPANHA - RS' },
  { label: 'UNIMED REGIAO DA FRONTEIRA - RS', value: 'UNIMED REGIAO DA FRONTEIRA - RS' },
  { label: 'UNIMED REGIAO DA PRODUÇAO / RS', value: 'UNIMED REGIAO DA PRODUÇAO / RS' },
  { label: 'UNIMED REGIONAL DE CAMPO MOURAO', value: 'UNIMED REGIONAL DE CAMPO MOURAO' },
  { label: 'UNIMED REGIONAL DE CRATEUS', value: 'UNIMED REGIONAL DE CRATEUS' },
  { label: 'UNIMED REGIONAL DE PICOS', value: 'UNIMED REGIONAL DE PICOS' },
  { label: 'UNIMED REGIONAL FLORIANO', value: 'UNIMED REGIONAL FLORIANO' },
  { label: 'UNIMED REGIONAL JAU', value: 'UNIMED REGIONAL JAU' },
  {
    label: 'UNIMED REGIONAL MARINGA COOP.DE TRABALHO MEDICO',
    value: 'UNIMED REGIONAL MARINGA COOP.DE TRABALHO MEDICO',
  },
  { label: 'UNIMED REGIONAL SUL/GO', value: 'UNIMED REGIONAL SUL/GO' },
  { label: 'UNIMED RESENDE', value: 'UNIMED RESENDE' },
  {
    label: 'UNIMED RIO BRANCO COOPERATIVA DE TRABALHO MEDICO LTDA',
    value: 'UNIMED RIO BRANCO COOPERATIVA DE TRABALHO MEDICO LTDA',
  },
  {
    label: 'UNIMED RIO COOPERATIVA DE TRABALHO MEDICO DO RJ LTDA',
    value: 'UNIMED RIO COOPERATIVA DE TRABALHO MEDICO DO RJ LTDA',
  },
  { label: 'UNIMED RIO VERDE', value: 'UNIMED RIO VERDE' },
  { label: 'UNIMED RONDONIA', value: 'UNIMED RONDONIA' },
  { label: 'UNIMED RONDONOPOLIS', value: 'UNIMED RONDONOPOLIS' },
  { label: 'UNIMED RS PELOTAS', value: 'UNIMED RS PELOTAS' },
  {
    label: 'UNIMED SALTO/ITU - COOPERATIVA MEDICA',
    value: 'UNIMED SALTO/ITU - COOPERATIVA MEDICA',
  },
  {
    label: 'UNIMED SANTA MARIA SOC.COOPERATIVA DE SERVIÇOS MEDICOS LTDA.',
    value: 'UNIMED SANTA MARIA SOC.COOPERATIVA DE SERVIÇOS MEDICOS LTDA.',
  },
  { label: 'UNIMED SANTO ANTONIO DE JESUS', value: 'UNIMED SANTO ANTONIO DE JESUS' },
  {
    label: 'UNIMED SANTOS DUMONT SOCIEDADE COOPERATIVA DE SERVIÇOS MEDICOS L',
    value: 'UNIMED SANTOS DUMONT SOCIEDADE COOPERATIVA DE SERVIÇOS MEDICOS L',
  },
  { label: 'UNIMED SAO CARLOS', value: 'UNIMED SAO CARLOS' },
  {
    label: 'UNIMED SAO JOAO DEL REI COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED SAO JOAO DEL REI COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED SAO JOAO NEPOMUCENO', value: 'UNIMED SAO JOAO NEPOMUCENO' },
  {
    label: 'UNIMED SAO JOSE DO RIO PRETO - COOP. DE TRABALHO MEDICO',
    value: 'UNIMED SAO JOSE DO RIO PRETO - COOP. DE TRABALHO MEDICO',
  },
  { label: 'UNIMED SAO JOSE DOS CAMPOS', value: 'UNIMED SAO JOSE DOS CAMPOS' },
  { label: 'UNIMED SAO LUIS', value: 'UNIMED SAO LUIS' },
  { label: 'UNIMED SAO ROQUE', value: 'UNIMED SAO ROQUE' },
  { label: 'UNIMED SÃO SEBASTIÃO DO PARAÍSO', value: 'UNIMED SÃO SEBASTIÃO DO PARAÍSO' },
  { label: 'UNIMED SERRA DOS ÓRGÃOS', value: 'UNIMED SERRA DOS ÓRGÃOS' },
  { label: 'UNIMED SERRANA RJ', value: 'UNIMED SERRANA RJ' },
  { label: 'UNIMED SERRAS DE MINAS', value: 'UNIMED SERRAS DE MINAS' },
  {
    label: 'UNIMED SETE LAGOAS COOPERATIVA DE TRABALHO MÉDICO',
    value: 'UNIMED SETE LAGOAS COOPERATIVA DE TRABALHO MÉDICO',
  },
  { label: 'UNIMED SOROCABA', value: 'UNIMED SOROCABA' },
  { label: 'UNIMED SOUSA', value: 'UNIMED SOUSA' },
  {
    label: 'UNIMED STA RITA, STA ROSA E SÃO SIMÃO',
    value: 'UNIMED STA RITA, STA ROSA E SÃO SIMÃO',
  },
  { label: 'UNIMED SUDOESTE DE MINAS', value: 'UNIMED SUDOESTE DE MINAS' },
  { label: 'UNIMED SUL CAPIXABA', value: 'UNIMED SUL CAPIXABA' },
  {
    label: 'UNIMED SUL DO PARÁ COOPERATIVA DE TRABALHO MÉDICO',
    value: 'UNIMED SUL DO PARÁ COOPERATIVA DE TRABALHO MÉDICO',
  },
  { label: 'UNIMED SUL MINEIRA', value: 'UNIMED SUL MINEIRA' },
  { label: 'UNIMED TATUI', value: 'UNIMED TATUI' },
  { label: 'UNIMED TERESINA', value: 'UNIMED TERESINA' },
  {
    label: 'UNIMED TRÊS CORAÇÕES COOPERATIVA DE TRABALHO MÉDICO LTDA.',
    value: 'UNIMED TRÊS CORAÇÕES COOPERATIVA DE TRABALHO MÉDICO LTDA.',
  },
  {
    label: 'UNIMED TRÊS PONTAS - COOPERATIVA DE TRABALHO MÉDICO',
    value: 'UNIMED TRÊS PONTAS - COOPERATIVA DE TRABALHO MÉDICO',
  },
  { label: 'UNIMED TRÊS RIOS', value: 'UNIMED TRÊS RIOS' },
  { label: 'UNIMED TRÊS VALES', value: 'UNIMED TRÊS VALES' },
  { label: 'UNIMED UBA', value: 'UNIMED UBA' },
  { label: 'UNIMED UBERABA LTDA.', value: 'UNIMED UBERABA LTDA.' },
  { label: 'UNIMED UBERLÂNDIA', value: 'UNIMED UBERLÂNDIA' },
  { label: 'UNIMED URUGUAIANA/RS', value: 'UNIMED URUGUAIANA/RS' },
  {
    label: 'UNIMED VALE DAS ANTAS, RS - SOC COOP DE SERVIÇOS MÉDICOS LTD',
    value: 'UNIMED VALE DAS ANTAS, RS - SOC COOP DE SERVIÇOS MÉDICOS LTD',
  },
  {
    label: 'UNIMED VALE DO AÇO COOPERATIVA DE TRABALHO MÉDICO',
    value: 'UNIMED VALE DO AÇO COOPERATIVA DE TRABALHO MÉDICO',
  },
  { label: 'UNIMED VALE DO AÇU', value: 'UNIMED VALE DO AÇU' },
  { label: 'UNIMED VALE DO CAI', value: 'UNIMED VALE DO CAI' },
  { label: 'UNIMED VALE DO CARANGOLA', value: 'UNIMED VALE DO CARANGOLA' },
  {
    label: 'UNIMED VALE DO CORUMBÁ COOPERATIVA DE TRABALHO MÉDICO',
    value: 'UNIMED VALE DO CORUMBÁ COOPERATIVA DE TRABALHO MÉDICO',
  },
  {
    label: 'UNIMED VALE DO JAURU COOPERATIVA DE TRABALHO MÉDICO',
    value: 'UNIMED VALE DO JAURU COOPERATIVA DE TRABALHO MÉDICO',
  },
  { label: 'UNIMED VALE DO PIQUIRI', value: 'UNIMED VALE DO PIQUIRI' },
  { label: 'UNIMED VALE DO SAO FRANCISCO', value: 'UNIMED VALE DO SAO FRANCISCO' },
  { label: 'UNIMED VALE DO URUCUIA', value: 'UNIMED VALE DO URUCUIA' },
  {
    label: 'UNIMED VARGINHA COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNIMED VARGINHA COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNIMED VERTENTE DO CAPARAO', value: 'UNIMED VERTENTE DO CAPARAO' },
  { label: 'UNIMED VILHENA', value: 'UNIMED VILHENA' },
  { label: 'UNIMED VITORIA', value: 'UNIMED VITORIA' },
  { label: 'UNIMED/RS', value: 'UNIMED/RS' },
  { label: 'UNIMEDFOZ', value: 'UNIMEDFOZ' },
  { label: 'UNIODONT', value: 'UNIODONT' },
  { label: 'UNIODONTO', value: 'UNIODONTO' },
  { label: 'UNIODONTO - AC', value: 'UNIODONTO - AC' },
  { label: 'UNIODONTO ADMINISTRADORA', value: 'UNIODONTO ADMINISTRADORA' },
  { label: 'UNIODONTO AMAPA', value: 'UNIODONTO AMAPA' },
  { label: 'UNIODONTO ARACATUBA', value: 'UNIODONTO ARACATUBA' },
  { label: 'UNIODONTO ARAPIRACA', value: 'UNIODONTO ARAPIRACA' },
  {
    label: 'UNIODONTO BELEM - COOPERATIVA DE ASSISTENCIA A SAUDE ODONTOLOGICA',
    value: 'UNIODONTO BELEM - COOPERATIVA DE ASSISTENCIA A SAUDE ODONTOLOGICA',
  },
  { label: 'UNIODONTO BOTUCATU', value: 'UNIODONTO BOTUCATU' },
  { label: 'UNIODONTO BRAGANÇA PAULISTA', value: 'UNIODONTO BRAGANÇA PAULISTA' },
  { label: 'UNIODONTO CARUARU I', value: 'UNIODONTO CARUARU I' },
  { label: 'UNIODONTO CATARINENSE', value: 'UNIODONTO CATARINENSE' },
  {
    label: 'UNIODONTO COOP ODONT VALE TAQUARI E RIO PARDO LTDA',
    value: 'UNIODONTO COOP ODONT VALE TAQUARI E RIO PARDO LTDA',
  },
  {
    label: 'UNIODONTO DE AMERICANA COOPERATIVA ODONTOLOGICA',
    value: 'UNIODONTO DE AMERICANA COOPERATIVA ODONTOLOGICA',
  },
  {
    label: 'UNIODONTO DE ARACAJU COOPERATIVA DE TRABALHO ODONTOLOGICO',
    value: 'UNIODONTO DE ARACAJU COOPERATIVA DE TRABALHO ODONTOLOGICO',
  },
  { label: 'UNIODONTO DE ARARAQUARA', value: 'UNIODONTO DE ARARAQUARA' },
  { label: 'UNIODONTO DE AVARE', value: 'UNIODONTO DE AVARE' },
  { label: 'UNIODONTO DE CAMPINAS', value: 'UNIODONTO DE CAMPINAS' },
  {
    label: 'UNIODONTO DE CAMPOS COOPERATIVA ODONTOLOGICA',
    value: 'UNIODONTO DE CAMPOS COOPERATIVA ODONTOLOGICA',
  },
  { label: 'UNIODONTO DE CATANDUVA', value: 'UNIODONTO DE CATANDUVA' },
  { label: 'UNIODONTO DE CRUZ ALTA', value: 'UNIODONTO DE CRUZ ALTA' },
  {
    label: 'UNIODONTO DE FERNANDOPOLIS COOPERATIVA ODONTOLOGICA',
    value: 'UNIODONTO DE FERNANDOPOLIS COOPERATIVA ODONTOLOGICA',
  },
  { label: 'UNIODONTO DE FORTALEZA', value: 'UNIODONTO DE FORTALEZA' },
  { label: 'UNIODONTO DE JABOTICABAL', value: 'UNIODONTO DE JABOTICABAL' },
  { label: 'UNIODONTO DE JACAREI', value: 'UNIODONTO DE JACAREI' },
  {
    label: 'UNIODONTO DE JALES - COOPERATIVA ODONTOLOGICA',
    value: 'UNIODONTO DE JALES - COOPERATIVA ODONTOLOGICA',
  },
  { label: 'UNIODONTO DE JUNDIAI', value: 'UNIODONTO DE JUNDIAI' },
  { label: 'UNIODONTO DE LENÇOIS PAULISTA', value: 'UNIODONTO DE LENÇOIS PAULISTA' },
  { label: 'UNIODONTO DE LIMEIRA', value: 'UNIODONTO DE LIMEIRA' },
  {
    label: 'UNIODONTO DE LINS COOPERATIVA ODONTOLOGICA',
    value: 'UNIODONTO DE LINS COOPERATIVA ODONTOLOGICA',
  },
  {
    label: 'UNIODONTO DE LONDRINA COOP. ODONTOLOGICA',
    value: 'UNIODONTO DE LONDRINA COOP. ODONTOLOGICA',
  },
  { label: 'UNIODONTO DE MARILIA', value: 'UNIODONTO DE MARILIA' },
  { label: 'UNIODONTO DE MARINGA', value: 'UNIODONTO DE MARINGA' },
  { label: 'UNIODONTO DE MONTE ALTO', value: 'UNIODONTO DE MONTE ALTO' },
  {
    label: 'UNIODONTO DE NOVA IGUAÇU COOPERATIVA ODONTOLOGICA',
    value: 'UNIODONTO DE NOVA IGUAÇU COOPERATIVA ODONTOLOGICA',
  },
  { label: 'UNIODONTO DE PAULO AFONSO', value: 'UNIODONTO DE PAULO AFONSO' },
  { label: 'UNIODONTO DE PIRACICABA', value: 'UNIODONTO DE PIRACICABA' },
  { label: 'UNIODONTO DE PIRASSUNUNGA', value: 'UNIODONTO DE PIRASSUNUNGA' },
  {
    label: 'UNIODONTO DE PRESIDENTE PRUDENTE COOPERATIVA ODONTOLOGICA',
    value: 'UNIODONTO DE PRESIDENTE PRUDENTE COOPERATIVA ODONTOLOGICA',
  },
  {
    label: 'UNIODONTO DE RECIFE - COOPERATIVA ODONTOLOGICA',
    value: 'UNIODONTO DE RECIFE - COOPERATIVA ODONTOLOGICA',
  },
  { label: 'UNIODONTO DE RIO CLARO', value: 'UNIODONTO DE RIO CLARO' },
  { label: 'UNIODONTO DE RONDÔNIA', value: 'UNIODONTO DE RONDÔNIA' },
  { label: 'UNIODONTO DE RORAIMA', value: 'UNIODONTO DE RORAIMA' },
  { label: 'UNIODONTO DE SAO LUIS', value: 'UNIODONTO DE SAO LUIS' },
  { label: 'UNIODONTO DE SAO PAULO', value: 'UNIODONTO DE SAO PAULO' },
  { label: 'UNIODONTO DE SERTAOZINHO', value: 'UNIODONTO DE SERTAOZINHO' },
  {
    label: 'UNIODONTO DE TAQUARITINGA COOPERATIVA ODONTOLOGICA',
    value: 'UNIODONTO DE TAQUARITINGA COOPERATIVA ODONTOLOGICA',
  },
  { label: 'UNIODONTO DE UBERABA', value: 'UNIODONTO DE UBERABA' },
  {
    label: 'UNIODONTO DE VOTUPORANGA COOPERATIVA ODONTOLOGICA',
    value: 'UNIODONTO DE VOTUPORANGA COOPERATIVA ODONTOLOGICA',
  },
  { label: 'UNIODONTO DO ABC', value: 'UNIODONTO DO ABC' },
  { label: 'UNIODONTO DO BRASIL', value: 'UNIODONTO DO BRASIL' },
  { label: 'UNIODONTO DO SERIDO', value: 'UNIODONTO DO SERIDO' },
  { label: 'UNIODONTO DO SUL GOIANO', value: 'UNIODONTO DO SUL GOIANO' },
  {
    label: 'UNIODONTO DOURADOS - COOPERATIVA ODONTOLOGICA',
    value: 'UNIODONTO DOURADOS - COOPERATIVA ODONTOLOGICA',
  },
  {
    label: 'UNIODONTO DUQUE DE CAXIAS COOPERATIVA DE TRABALHO ODONTOLOGICO LTDA.',
    value: 'UNIODONTO DUQUE DE CAXIAS COOPERATIVA DE TRABALHO ODONTOLOGICO LTDA.',
  },
  { label: 'UNIODONTO ERECHIM', value: 'UNIODONTO ERECHIM' },
  { label: 'UNIODONTO FEIRA DE SANTANA', value: 'UNIODONTO FEIRA DE SANTANA' },
  { label: 'UNIODONTO FRONTEIRA OESTE/RS', value: 'UNIODONTO FRONTEIRA OESTE/RS' },
  { label: 'UNIODONTO GARANHUNS', value: 'UNIODONTO GARANHUNS' },
  { label: 'UNIODONTO GOIANIA', value: 'UNIODONTO GOIANIA' },
  { label: 'UNIODONTO I', value: 'UNIODONTO I' },
  { label: 'UNIODONTO ILHEUS', value: 'UNIODONTO ILHEUS' },
  { label: 'UNIODONTO ITABUNA', value: 'UNIODONTO ITABUNA' },
  {
    label: 'UNIODONTO ITAPERUNA COOP TRAB ODONTOLOGICO',
    value: 'UNIODONTO ITAPERUNA COOP TRAB ODONTOLOGICO',
  },
  { label: 'UNIODONTO JF', value: 'UNIODONTO JF' },
  { label: 'UNIODONTO JOAO PESSOA', value: 'UNIODONTO JOAO PESSOA' },
  {
    label: 'UNIODONTO LEOPOLDINA - COOPERATIVA ODONTOLOGICA',
    value: 'UNIODONTO LEOPOLDINA - COOPERATIVA ODONTOLOGICA',
  },
  { label: 'UNIODONTO LESTE FLUMINENSE', value: 'UNIODONTO LESTE FLUMINENSE' },
  { label: 'UNIODONTO MACAE', value: 'UNIODONTO MACAE' },
  { label: 'UNIODONTO MACEIO', value: 'UNIODONTO MACEIO' },
  { label: 'UNIODONTO MANAUS', value: 'UNIODONTO MANAUS' },
  { label: 'UNIODONTO MATO GROSSO', value: 'UNIODONTO MATO GROSSO' },
  { label: 'UNIODONTO MG', value: 'UNIODONTO MG' },
  { label: 'UNIODONTO MOSSORO', value: 'UNIODONTO MOSSORO' },
  { label: 'UNIODONTO PARA DE MINAS', value: 'UNIODONTO PARA DE MINAS' },
  { label: 'UNIODONTO PAULISTA', value: 'UNIODONTO PAULISTA' },
  {
    label: 'UNIODONTO PETROPOLIS-COOP.TRAB.ODONTOLOGICOS LTDA',
    value: 'UNIODONTO PETROPOLIS-COOP.TRAB.ODONTOLOGICOS LTDA',
  },
  { label: 'UNIODONTO PINDAMONHANGABA', value: 'UNIODONTO PINDAMONHANGABA' },
  { label: 'UNIODONTO PIRAQUEAÇU', value: 'UNIODONTO PIRAQUEAÇU' },
  { label: 'UNIODONTO PONTA GROSSA', value: 'UNIODONTO PONTA GROSSA' },
  {
    label: 'UNIODONTO PORTO ALEGRE COOPERATIVA ODONTOLOGICA LTDA',
    value: 'UNIODONTO PORTO ALEGRE COOPERATIVA ODONTOLOGICA LTDA',
  },
  { label: 'UNIODONTO POÇOS DE CALDAS', value: 'UNIODONTO POÇOS DE CALDAS' },
  {
    label: 'UNIODONTO RESENDE COOPERATIVA ODONTOLOGICA',
    value: 'UNIODONTO RESENDE COOPERATIVA ODONTOLOGICA',
  },
  {
    label: 'UNIODONTO RIO GRANDE-LITORAL COOPERATIVA ODONTOLOGICA LTDA',
    value: 'UNIODONTO RIO GRANDE-LITORAL COOPERATIVA ODONTOLOGICA LTDA',
  },
  { label: 'UNIODONTO SALVADOR', value: 'UNIODONTO SALVADOR' },
  { label: 'UNIODONTO SÃO CARLOS', value: 'UNIODONTO SÃO CARLOS' },
  { label: 'UNIODONTO SÃO JOSÉ DO RIO PARDO', value: 'UNIODONTO SÃO JOSÉ DO RIO PARDO' },
  { label: 'UNIODONTO SÃO JOSÉ DOS CAMPOS', value: 'UNIODONTO SÃO JOSÉ DOS CAMPOS' },
  {
    label: 'UNIODONTO SISTEMA NACIONAL DE COOP. ODONTOLOGICAS',
    value: 'UNIODONTO SISTEMA NACIONAL DE COOP. ODONTOLOGICAS',
  },
  { label: 'UNIODONTO SOROCABA', value: 'UNIODONTO SOROCABA' },
  {
    label: 'UNIODONTO SUDOESTE GOIANO - COOPERATIVA DE TRABALHO ODONTOLOGICO',
    value: 'UNIODONTO SUDOESTE GOIANO - COOPERATIVA DE TRABALHO ODONTOLOGICO',
  },
  {
    label: 'UNIODONTO SUL CAPIXABA COOPERATIVA ODONTOLOGICA',
    value: 'UNIODONTO SUL CAPIXABA COOPERATIVA ODONTOLOGICA',
  },
  { label: 'UNIODONTO TAUBATÉ', value: 'UNIODONTO TAUBATÉ' },
  { label: 'UNIODONTO TERESÓPOLIS', value: 'UNIODONTO TERESÓPOLIS' },
  { label: 'UNIODONTO TRÊS RIOS', value: 'UNIODONTO TRÊS RIOS' },
  { label: 'UNIODONTO UBERLÂNDIA', value: 'UNIODONTO UBERLÂNDIA' },
  { label: 'UNIODONTO VALE DO SINOS', value: 'UNIODONTO VALE DO SINOS' },
  {
    label: 'UNIODONTO VALE HISTÓRICO COOPERATIVA ODONTOLOGICA',
    value: 'UNIODONTO VALE HISTÓRICO COOPERATIVA ODONTOLOGICA',
  },
  { label: 'UNIODONTO VITÓRIA', value: 'UNIODONTO VITÓRIA' },
  { label: 'UNIODONTO-NORDESTE', value: 'UNIODONTO-NORDESTE' },
  { label: 'UNIODONTO-RJ', value: 'UNIODONTO-RJ' },
  { label: 'UNIODONTO/RN', value: 'UNIODONTO/RN' },
  { label: 'UNIODONTO/RS', value: 'UNIODONTO/RS' },
  { label: 'UNIOPREV', value: 'UNIOPREV' },
  { label: 'UNIPLAM', value: 'UNIPLAM' },
  { label: 'UNISAUDE', value: 'UNISAUDE' },
  { label: 'UNISAUDE II', value: 'UNISAUDE II' },
  { label: 'UNIVERSAL PLANO ODONTOLOGICO LTDA', value: 'UNIVERSAL PLANO ODONTOLOGICO LTDA' },
  { label: 'UNIVERSO ADMINISTRADORA', value: 'UNIVERSO ADMINISTRADORA' },
  { label: 'UNIX', value: 'UNIX' },
  {
    label: 'UNMED SERGIPE - COOPERATIVA DE TRABALHO MEDICO',
    value: 'UNMED SERGIPE - COOPERATIVA DE TRABALHO MEDICO',
  },
  { label: 'UNNIMAX ADMINISTRADORA DE BENEFICIOS', value: 'UNNIMAX ADMINISTRADORA DE BENEFICIOS' },
  { label: 'UP ASSISTÊNCIA ODONTOLOGICA S.A', value: 'UP ASSISTÊNCIA ODONTOLOGICA S.A' },
  { label: 'USODONTO', value: 'USODONTO' },
  { label: 'VALE', value: 'VALE' },
  { label: 'VALEC', value: 'VALEC' },
  { label: 'VALEM', value: 'VALEM' },
  { label: 'VALLOR BENEFICIOS', value: 'VALLOR BENEFICIOS' },
  { label: 'VASCO DA GAMA', value: 'VASCO DA GAMA' },
  { label: 'VEPLAM - VIVA PLAN', value: 'VEPLAM - VIVA PLAN' },
  { label: 'VERA CRUZ ASSOCIAÇAO DE SAUDE', value: 'VERA CRUZ ASSOCIAÇAO DE SAUDE' },
  { label: 'VERA CRUZ PLANO DE SAUDE (2CARE)', value: 'VERA CRUZ PLANO DE SAUDE (2CARE)' },
  {
    label: 'VIACORP ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'VIACORP ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'VIDA & VIDA OPS', value: 'VIDA & VIDA OPS' },
  {
    label: 'VIDAMAX ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'VIDAMAX ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  { label: 'VIDAPLAN SAUDE', value: 'VIDAPLAN SAUDE' },
  { label: 'VILA VALQUEIRE', value: 'VILA VALQUEIRE' },
  { label: 'VIP ODONTO', value: 'VIP ODONTO' },
  { label: 'VIP SAUDE ASSISTENCIA MEDICA LTDA.', value: 'VIP SAUDE ASSISTENCIA MEDICA LTDA.' },
  { label: 'VITA ASSISTENCIA A SAUDE LTDA.', value: 'VITA ASSISTENCIA A SAUDE LTDA.' },
  { label: 'VITAEe', value: 'VITAEe' },
  { label: 'VITALLIS', value: 'VITALLIS' },
  { label: 'VITASERV PLANOS DE SAUDE', value: 'VITASERV PLANOS DE SAUDE' },
  { label: 'VIVA PLANOS DE SAUDE LTDA II', value: 'VIVA PLANOS DE SAUDE LTDA II' },
  { label: 'VIVAMED SAUDE', value: 'VIVAMED SAUDE' },
  { label: 'VIVAODONTO', value: 'VIVAODONTO' },
  { label: 'VIVEST', value: 'VIVEST' },
  { label: 'VOCE - CLUBE DE BENEFICIOS', value: 'VOCE - CLUBE DE BENEFICIOS' },
  { label: 'VOCE ODONTO', value: 'VOCE ODONTO' },
  { label: 'VOLKSWAGEN DO BRASIL', value: 'VOLKSWAGEN DO BRASIL' },
  { label: 'VOLVO', value: 'VOLVO' },
  { label: 'VONPAR REFRESCOS S/A', value: 'VONPAR REFRESCOS S/A' },
  {
    label: 'VORTICE - ADMINISTRADORA DE BENEFICIOS LTDA',
    value: 'VORTICE - ADMINISTRADORA DE BENEFICIOS LTDA',
  },
  {
    label: 'VUMI - VIP UNIVERSAL MEDICAL INSURANCE GROUP',
    value: 'VUMI - VIP UNIVERSAL MEDICAL INSURANCE GROUP',
  },
  { label: 'WIN SAUDE', value: 'WIN SAUDE' },
  { label: 'WMS SUPERMERCADOS DO BRASIL LTDA', value: 'WMS SUPERMERCADOS DO BRASIL LTDA' },
  { label: 'YES ODONTOLOGIA LTDA', value: 'YES ODONTOLOGIA LTDA' },
  { label: 'YOU SAUDE', value: 'YOU SAUDE' },
  { label: 'ÔMEGA SAUDE', value: 'ÔMEGA SAUDE' },
  {
    label: 'ÔNIX OPERADORA DE PLANOS DE SAUDE LTDA',
    value: 'ÔNIX OPERADORA DE PLANOS DE SAUDE LTDA',
  },
]
