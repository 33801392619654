import { useMutation, useQueryClient } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '../../http'
import { DuplicateCommissionGridPayPayload } from '../dtos'

async function duplicateCommissionGridPay(data: DuplicateCommissionGridPayPayload) {
  const url = `${import.meta.env.VITE_FINOPS_SERVICES_URL}/grade-comissao-pagar/${data.id}/duplicar`

  return api.post(
    url,
    {
      criadoPor: data.createdBy,
    },
    {
      headers: {
        Authorization: localStorage.getItem('authToken'),
      },
    },
  )
}

export function useDuplicateCommissionGridPayMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['commissionGridPay', 'duplicate'] as const,
    mutationFn: async (data: DuplicateCommissionGridPayPayload) => duplicateCommissionGridPay(data),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['commissionGridsPay'] })
    },
    onError: () => {
      triggerToast(false, 'Ops, ocorreu um erro ao duplicar a grade')
    },
    onSuccess: () => {
      triggerToast(true, 'Grade duplicada com sucesso!')

      queryClient.invalidateQueries({
        queryKey: ['commissionGridsPay'],
      })
    },
  })
}
