import { SwitchSize } from 'antd/es/switch'
import { SwitchStyled } from './style'
import React from 'react'

export interface Props {
  size?: SwitchSize
  disabled?: boolean
  onChange?: (checked: boolean) => void
  onClick?: (checked: boolean) => void
  checked?: boolean
  defaultChecked?: boolean
  color?: string
  isLoading?: boolean
}

const SwitchButton: React.FC<Props> = ({
  onClick,
  onChange,
  size,
  disabled,
  checked,
  color,
  defaultChecked,
  isLoading,
}) => (
  <SwitchStyled
    onClick={onClick}
    onChange={onChange}
    size={size}
    loading={isLoading}
    disabled={disabled}
    checked={checked}
    defaultChecked={defaultChecked}
    color={color}
  />
)

export default SwitchButton
