import { useMutation } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '../../http'

type GenerateSignedUrlRequest = {
  orderId?: string | null
  documentId: string
  fileName: string
}

export type GenerateSignedUrlResponse = {
  uploadURL: string
}

async function generateSignedUrl({ orderId, documentId, fileName }: GenerateSignedUrlRequest) {
  const response = await api.put<GenerateSignedUrlResponse>(
    `${
      import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
    }/api/v1/orders/${orderId}/documents/${documentId}`,
    { fileName: fileName },
    {
      headers: { Authorization: localStorage.getItem('authToken') },
    },
  )
  return response.data
}

export const useGenerateSignedUrl = (GenerateSignedUrlRequest?: GenerateSignedUrlRequest) => {
  const orderId = GenerateSignedUrlRequest?.orderId
  const documentId = GenerateSignedUrlRequest?.documentId

  return useMutation({
    mutationKey: ['signedUrl', orderId, documentId],
    mutationFn: generateSignedUrl,
    onError: () => {
      triggerToast(false, 'Ops, ocorreu um erro durante o upload.')
    },
  })
}
