import { useMutation } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '../../http'

type OrderDispatchProps = {
  orderId?: string | null
  insurer?: string | null
}

const sendOrderDispatch = async ({ orderId, insurer }: OrderDispatchProps) => {
  const response = await api.post(
    `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/orders/${orderId}/dispatch`,
    {
      insurer,
    },
    {
      headers: {
        Authorization: localStorage.getItem('authToken'),
      },
    },
  )
  return response.data
}

export const useOrderDispatchMutation = (OrderDispatchProps?: OrderDispatchProps) => {
  const orderId = OrderDispatchProps?.orderId
  const insurer = OrderDispatchProps?.insurer

  return useMutation({
    mutationKey: ['dispatch', orderId, insurer],
    mutationFn: sendOrderDispatch,
    onSuccess: () => {
      triggerToast(
        true,
        'Solicitação de emissão feita com sucesso! Agora basta acompanhar o status da emissão.',
      )
    },
    onError: () => {
      triggerToast(false, 'Opss, ocorreu um erro ao enviar sua solicitação.')
    },
  })
}
