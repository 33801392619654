import { QueryFunctionContext, queryOptions } from '@tanstack/react-query'

import { api } from '../../http'
import { GetCommissionGridPayItemsRequestBase, GridItem } from '../dtos'

type GetCommissionGridPayItemsRequest = QueryFunctionContext & GetCommissionGridPayItemsRequestBase

async function getCommissionGridPayItems({ gridId, signal }: GetCommissionGridPayItemsRequest) {
  return api
    .get<GridItem[]>(
      `${import.meta.env.VITE_FINOPS_SERVICES_URL}/itens-grade-comissao-pagar?idGrade=${gridId}`,
      {
        signal,
        headers: { Authorization: localStorage.getItem('authToken') },
      },
    )
    .then((res) => res.data)
}

export function getCommissionGridPayItemsQueryOptions({
  gridId,
}: GetCommissionGridPayItemsRequestBase) {
  return queryOptions({
    queryKey: ['commissionGridPayItems', { gridId }] as const,
    queryFn: async (params) => getCommissionGridPayItems({ gridId, ...params }),
  })
}
