import { useMutation } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '@/App/clients/http'

export type DeleteExternalManagerData = {
  orderId?: string | null
  externalManagerId?: string | null
}

const deleteExternalManager = async ({ orderId, externalManagerId }: DeleteExternalManagerData) => {
  await api.delete(
    `${
      import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
    }/api/v1/orders/${orderId}/external-managers/${externalManagerId}`,
    {
      headers: { Authorization: localStorage.getItem('authToken') },
    },
  )
}

export const useDeleteExternalManager = (deleteExternalManagerData?: DeleteExternalManagerData) => {
  const orderId = deleteExternalManagerData?.orderId
  const externalManagerId = deleteExternalManagerData?.externalManagerId

  return useMutation({
    mutationKey: ['deleteExternalManager', orderId, externalManagerId],
    mutationFn: deleteExternalManager,
    onSuccess: () => {
      triggerToast(true, 'Corretor externo desativado com sucesso!')
    },
    onError: () => {
      triggerToast(false, 'Ops, ocorreu um erro ao desativar corretor.')
    },
  })
}
