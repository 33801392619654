import styled from 'styled-components'

export const FilterDiv = styled.div`
  z-index: 3;
  position: absolute;
  margin: 10px;

  & .ant-tabs-content-holder {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
    color: #4b4b4b !important;
  }

  .ant-tabs-tab-btn {
    font-weight: 600;
    color: #878ea6 !important;
  }

  .ant-tabs-content-holder {
    display: flex;
    align-items: flex-start;
  }
`
export const FilterButton = styled.button<{ isActive: boolean }>`
  padding: 8px 15px 8px 15px;
  border-radius: 30px;
  border: 1px solid #dcdfeb;
  margin-top: 16px;
  background-color: ${(props) => (props.isActive ? '#514984' : '#FFFFFF')};
  color: ${(props) => (props.isActive ? '#FFF' : '#4B4B4B')};
  & svg {
    margin-right: 5px;
  }
  &:hover {
    cursor: pointer;
    background: ${(props) => (props.isActive ? '#514984' : '#F6F6F9')};
    color: ${(props) => (props.isActive ? '#FFFFFF' : '#4b4b4b')};
  }
`
export const SelectedFilters = styled.span`
  background-color: rgb(245, 246, 249);
  padding: 5px 20px 5px 20px;
  border-radius: 20px;
  font-weight: 700;
  font-size: 13px;
  color: rgb(75, 75, 75);
  margin-left: 10px;
  & svg {
    font-size: 11px;
  }

  &:hover {
    cursor: pointer;
  }
`
