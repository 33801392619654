import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { FlagProvider, IFlagProvider } from '@unleash/proxy-client-react'

import { Router } from '@/App/Router'

import './App.css'
import { reactQueryClient } from './clients/http/react-query'

const unleashConfig: IFlagProvider['config'] = {
  url: import.meta.env.VITE_UNLEASH_BASE_URL,
  clientKey: import.meta.env.VITE_UNLEASH_API_TOKEN,
  refreshInterval: 15,
  appName: 'default',
  environment: import.meta.env.VITE_NODE_ENV,
}

export const App = () => {
  return (
    <QueryClientProvider client={reactQueryClient}>
      <FlagProvider config={unleashConfig}>
        <Router />
      </FlagProvider>
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
    </QueryClientProvider>
  )
}
export default App
