import { normalizeString } from './string'

type GetObjectKeyByValueProps = {
  objectToExtract: Record<string, string>
  valueToCompare?: string
  exact?: boolean
}

export function getObjectKeyByValue({
  objectToExtract,
  exact = false,
  valueToCompare,
}: GetObjectKeyByValueProps) {
  if (!valueToCompare) {
    return
  }

  return Object.entries(objectToExtract)
    .find(([_key, value]) => {
      if (exact) {
        return normalizeString(value) === normalizeString(valueToCompare)
      }

      return normalizeString(valueToCompare).includes(normalizeString(value))
    })
    ?.at(0)
}
