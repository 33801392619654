import { useMutation, useQueryClient } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '../../http'
import { DeleteCommissionGridPayPayload } from '../dtos'

async function deteteCommissionGridPay({ id }: DeleteCommissionGridPayPayload) {
  const url = `${import.meta.env.VITE_FINOPS_SERVICES_URL}/grade-comissao-pagar/${id}`

  return api.delete(url, {
    headers: {
      Authorization: localStorage.getItem('authToken'),
    },
  })
}

export function useDeleteCommissionGridPayMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['commissionGridPay', 'delete'] as const,
    mutationFn: async (data: DeleteCommissionGridPayPayload) => deteteCommissionGridPay(data),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['commissionGridsPay'] })
    },
    onError: () => {
      triggerToast(false, 'Ops, ocorreu um erro ao apagar a grade')
    },
    onSuccess: () => {
      triggerToast(true, 'Grade apagada com sucesso!')

      queryClient.invalidateQueries({
        queryKey: ['commissionGridsPay'],
      })
    },
  })
}
