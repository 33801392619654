import { QueryFunctionContext, queryOptions } from '@tanstack/react-query'

import { api } from '../../http'
import { CommissionGridPayDetailed, GetCommissionGridPayDetailsRequestBase } from '../dtos'

type GetCommissionGridPayDetailsRequest = QueryFunctionContext &
  GetCommissionGridPayDetailsRequestBase

async function getCommissionGridPayDetails({ gridId, signal }: GetCommissionGridPayDetailsRequest) {
  return api
    .get<CommissionGridPayDetailed>(
      `${import.meta.env.VITE_FINOPS_SERVICES_URL}/grade-comissao-pagar/${gridId}`,
      {
        signal,
        headers: { Authorization: localStorage.getItem('authToken') },
      },
    )
    .then((res) => res.data)
}

export function getCommissionGridPayDetailsQueryOptions({
  gridId,
}: GetCommissionGridPayDetailsRequestBase) {
  return queryOptions({
    queryKey: ['commissionGridPayDetails', { gridId }] as const,
    queryFn: async (params) => getCommissionGridPayDetails({ gridId, ...params }),
  })
}
