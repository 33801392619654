import React, { ReactNode, useState } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { ModalProps } from 'antd'

import Button from '../Button/Button'
import { ResponseModal } from '../components'
import { StyledClickText, StyledDescription, StyledIcon, StyledModal, StyledTitle } from './style'

export interface Props extends ModalProps {
  children: ReactNode
  errorMessage: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  successMessage: string
  buttonText?: string
  onClick?: () => Promise<boolean>
  title?: string
  width?: number
}

const ConfirmationModal: React.FC<Props> = ({
  children,
  errorMessage,
  isOpen,
  setIsOpen,
  successMessage,
  buttonText,
  onClick,
  title,
  width,
}) => {
  const [showSuccessModal, setShowSuccesModal] = useState(false)
  const [showFailureModal, setShowFailureModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const cancelClick = () => {
    setIsOpen(false)
  }

  async function handleConfirmation() {
    setIsLoading(true)
    if (onClick) {
      const response = await onClick()

      response ? setShowSuccesModal(true) : setShowFailureModal(true)
    }
    setIsLoading(false)
    setIsOpen(false)
  }

  return (
    <>
      <StyledModal
        open={isOpen}
        onCancel={cancelClick}
        footer={[
          <Button
            key="submit"
            loading={isLoading}
            onClick={handleConfirmation}
            style={{ width: '188px' }}
          >
            {buttonText ? `${buttonText}` : 'Confirmar'}
          </Button>,
          <StyledClickText key={'back'} onClick={cancelClick}>
            Cancelar
          </StyledClickText>,
        ]}
        width={width}
        centered
      >
        <StyledIcon>
          <ExclamationCircleOutlined />
        </StyledIcon>

        <StyledTitle>
          {title ? `${title}` : 'Tem certeza que deseja concluir esta ação?'}
        </StyledTitle>

        <StyledDescription>{children}</StyledDescription>
      </StyledModal>

      <ResponseModal
        icon="success"
        buttonText="Ok"
        isOpen={showSuccessModal}
        setIsOpen={setShowSuccesModal}
        title={'Ação concluída com sucesso!'}
      >
        {successMessage}
      </ResponseModal>
      <ResponseModal
        icon="error"
        buttonText="Entendi"
        isOpen={showFailureModal}
        setIsOpen={setShowFailureModal}
        title={'Ops, algo deu errado!'}
      >
        {errorMessage}
      </ResponseModal>
    </>
  )
}

export default ConfirmationModal
