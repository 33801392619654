import { useEffect, useMemo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Skeleton } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { Button, TextInput } from 'design-system/components'

import { GridItem } from '@/App/clients/finOps/dtos'
import { useChangeNameCommissionGridPayMutation } from '@/App/clients/finOps/mutations/changeNameCommissionGridPay'
import { getCommissionGridPayDetailsQueryOptions } from '@/App/clients/finOps/queries/commissionGridPayDetails'
import { getCommissionGridPayItemsQueryOptions } from '@/App/clients/finOps/queries/commissionGridPayItems'

import {
  CompactTable,
  Divider,
  FormContainer,
  TextInputContainer,
  Title,
  TitleContainer,
} from './styles'

export interface QuotasObject {
  quota1: number
  quota2: number
  quota3: number
  quota4: number
  quota5: number
  quota6: number
  quota7: number
  quota8: number
}

export interface DistributionChannelQuotas {
  [key: string]: QuotasObject
}

export interface ITableData extends QuotasObject {
  id: string
  broker: string
  modality: string
  administrator: string
  platform: string
}

export const CommissionGridDetails = ({ gridId = '1' }) => {
  const { data, isLoading } = useQuery(getCommissionGridPayDetailsQueryOptions({ gridId }))
  const { data: commissionGridItemsData, isLoading: isCommissionGridItemsLoading } = useQuery(
    getCommissionGridPayItemsQueryOptions({ gridId }),
  )

  const changeNameCommissionGridsPayMutation = useChangeNameCommissionGridPayMutation()

  const columns: ColumnsType<ITableData> = [
    {
      key: '0',
      dataIndex: 'broker',
      title: 'Operadora/Administradora',
      sorter: (a, b) => a.broker.localeCompare(b.broker),
    },
    {
      key: '1',
      dataIndex: 'administrator',
      title: 'Administradora',
      sorter: (a, b) => a.administrator.localeCompare(b.administrator),
    },
    {
      key: '2',
      dataIndex: 'modality',
      title: 'Modalidade Agregada',
      sorter: (a, b) => a.modality.localeCompare(b.modality),
    },
    {
      key: '3',
      dataIndex: 'platform',
      title: 'Plataforma',
      sorter: (a, b) => a.platform.localeCompare(b.platform),
    },
    {
      key: '4',
      dataIndex: 'quota1',
      title: '1',
      render: (value) => value + ' %',
    },
    {
      key: '5',
      dataIndex: 'quota2',
      title: '2',
      render: (value) => value + ' %',
    },
    {
      key: '6',
      dataIndex: 'quota3',
      title: '3',
      render: (value) => value + ' %',
    },
    {
      key: '7',
      dataIndex: 'quota4',
      title: '4',
      render: (value) => value + ' %',
    },
    {
      key: '8',
      dataIndex: 'quota5',
      title: '5',
      render: (value) => value + ' %',
    },
    {
      key: '9',
      dataIndex: 'quota6',
      title: '6',
      render: (value) => value + ' %',
    },
    {
      key: '10',
      dataIndex: 'quota7',
      title: '7',
      render: (value) => value + ' %',
    },
    {
      key: '11',
      dataIndex: 'quota8',
      title: '8',
      render: (value) => value + ' %',
    },
    {
      key: '12',
      dataIndex: '',
      title: 'Total',
      render: (_value, lineData) => {
        const quota1 = lineData.quota1 ?? 0
        const quota2 = lineData.quota2 ?? 0
        const quota3 = lineData.quota3 ?? 0
        const quota4 = lineData.quota4 ?? 0
        const quota5 = lineData.quota5 ?? 0
        const quota6 = lineData.quota6 ?? 0
        const quota7 = lineData.quota7 ?? 0
        const quota8 = lineData.quota8 ?? 0

        const total = quota1 + quota2 + quota3 + quota4 + quota5 + quota6 + quota7 + quota8

        return `${Intl.NumberFormat('pt-BR', { minimumFractionDigits: 0 }).format(total)}%`
      },
    },
    {
      key: '13',
      dataIndex: 'startAt',
      title: 'Início vigência',
      render: (a) => `${a ? dayjs(a).format('DD/MM/YYYY') : ''}`,
    },
    {
      key: '14',
      dataIndex: 'endAt',
      title: 'Fim vigência',
      render: (a) => `${a ? dayjs(a).format('DD/MM/YYYY') : ''}`,
    },
  ]

  const parseDataToTable = (filteredGridItem: GridItem[]): ITableData[] => {
    const dados = filteredGridItem?.map((gridItem) => {
      const quotas: QuotasObject = {
        quota1: 0,
        quota2: 0,
        quota3: 0,
        quota4: 0,
        quota5: 0,
        quota6: 0,
        quota7: 0,
        quota8: 0,
      }

      if (gridItem)
        gridItem?.parcelas?.forEach((parcela) => {
          const quotaLookup: Record<number, string> = {
            1: 'quota1',
            2: 'quota2',
            3: 'quota3',
            4: 'quota4',
            5: 'quota5',
            6: 'quota6',
            7: 'quota7',
            8: 'quota8',
          }
          const quotaNumber = quotaLookup[parcela.numero] as keyof QuotasObject

          if (quotaNumber) quotas[quotaNumber] = Math.round(parcela.porcentagem * 100)
        })

      return {
        id: gridItem.id,
        dristibutionChannelId: gridItem.idCanalDistribuicao,
        grid: gridItem.nomeGrade,
        gridId: gridItem.idGrade,
        broker: gridItem.nomeOperadora,
        brokerId: gridItem.idOperadora,
        modality: gridItem.nomeModalidade,
        modalityId: gridItem.idModalidade,
        platform: gridItem.nomePlataforma ? gridItem.nomePlataforma : 'Direta',
        platformId: gridItem.idPlataforma,
        administrator: gridItem.nomeAdministradora ? gridItem.nomeAdministradora : 'Direta',
        administratorId: gridItem.idAdministradora,
        startAt: gridItem.inicioVigencia,
        endAt: gridItem.fimVigencia,
        createdAt: gridItem.createdAt,
        ...quotas,
      }
    })

    return dados
  }

  const dataTable = useMemo(
    () => parseDataToTable(commissionGridItemsData ?? []),
    [commissionGridItemsData],
  )

  const handleChangedName = () => {
    const userName = localStorage.getItem('userName')
    if (data?.id && gridName && userName && data?.nome !== gridName) {
      changeNameCommissionGridsPayMutation.mutate({
        id: data.id,
        nome: gridName,
        criadoPor: userName,
      })
    }
  }

  const [gridName, setGridName] = useState(data?.nome)

  useEffect(() => {
    setGridName(data?.nome)
  }, [data])

  return (
    <>
      <TitleContainer>
        <Title>Editar | Ver detalhes da Grade</Title>
        <Divider />
      </TitleContainer>

      {isLoading ? (
        <Skeleton />
      ) : (
        <FormContainer>
          <TextInputContainer>
            <TextInput
              name="nomeGrade"
              value={gridName}
              onChange={(e) => setGridName(e.target.value)}
              placeholder="Digite o nome da grade"
              label="Nome da Grade"
              vertical
              labelWeight="700"
            />
          </TextInputContainer>
          <div>
            <Button
              style={{ marginTop: 10 }}
              onClick={handleChangedName}
              loading={changeNameCommissionGridsPayMutation.isPending}
            >
              Salvar alterações
            </Button>
          </div>
        </FormContainer>
      )}

      {isCommissionGridItemsLoading ? (
        <Skeleton />
      ) : (
        <CompactTable
          columns={columns as ColumnsType<object>}
          dataSource={dataTable}
          loading={false}
          pagination={false}
          rowClassName="editable-row"
        />
      )}
    </>
  )
}
