import styled from 'styled-components'

export const CenterText = styled.div`
  text-align: center;
`
export const SearchContainer = styled.div`
  display: flex;
  width: 100%;

  & > div {
    width: 100%;
  }
`
export const ButtonContainer = styled.div`
  position: fixed;
  width: 300px;
  left: 50%;
  transform: translate(-50%, -85%);
  bottom: 0;
`
