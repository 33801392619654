import { colors } from '@design-system/styles/theme'
import { SubmitButtonProps } from 'design-system/DynamicForm/DynamicForm'
import { Button } from 'design-system/components'
import styled from 'styled-components'

const SubmitButtonWrapper = styled.div`
  max-width: 100px;
`

const HintMessage = styled.div`
  display: flex;
  font-size: 0.9rem;
  margin-bottom: 15px;
  color: #858aa3;
`

type SubmitButtonTemplateProps = SubmitButtonProps

export function SubmitButtonTemplate({ registry }: SubmitButtonTemplateProps) {
  const { formContext } = registry

  return (
    <>
      {formContext?.hint ? <HintMessage>{formContext.hint}</HintMessage> : null}
      <SubmitButtonWrapper>
        <Button
          htmlType="submit"
          height="30px"
          backgroundColor={colors.darkPurple}
          loading={formContext?.isLoading || false}
          disabled={formContext?.shouldDisableForm}
        >
          Salvar
        </Button>
      </SubmitButtonWrapper>
    </>
  )
}
