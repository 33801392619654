import { useEffect, useState } from 'react'

import { OnFileUploadInput } from 'design-system/UploadArea/UploadArea'
import { Button, Toast, UploadArea } from 'design-system/components'

import { HealthPlansOrdersRequests } from '@/App/clients/healthPlansOrders'
import { useFetchParallel } from '@/App/clients/http'
import { UseFetchConfig } from '@/App/clients/http/interface'

import { triggerToast } from '../../../../../design-system/src/utils/triggers'
import {
  FileLabel,
  MarketingAssetsDiv,
  UploadAreaButtonDiv,
  UploadAreaDiv,
  UploadFilesAreaSubtitle,
  UploadFilesAreaTitle,
} from './style'

const AdminMarketingAssetsPage = () => {
  const [requestConfigs, setRequestConfigs] = useState<UseFetchConfig[]>([])
  const { setRequestConfig, isLoading, error, statusCode } = useFetchParallel()

  const handleFileUpload = async (files: OnFileUploadInput[]) => {
    const requestConfigs = files.map((file) => {
      const formData = new FormData()
      formData.append('file', file.fileContent, file.fileName)

      return HealthPlansOrdersRequests.createStaticMarketingAsset(formData)
    })

    setRequestConfigs(requestConfigs)
  }

  useEffect(() => {
    if (!statusCode) return
    const [responseStatusCode] = statusCode

    if (responseStatusCode === 201)
      triggerToast(true, 'Materiais de divulgação criados com sucesso!')
  }, [statusCode])

  useEffect(() => {
    if (error)
      triggerToast(false, 'Ops, ocorreu um erro ao fazer o upload dos arquivos selecionados')
  }, [error])

  return (
    <MarketingAssetsDiv>
      <UploadFilesAreaTitle>Materiais de divulgação</UploadFilesAreaTitle>
      <UploadFilesAreaSubtitle>Criação de peças estáticas</UploadFilesAreaSubtitle>
      <UploadAreaDiv>
        <FileLabel>Arquivo</FileLabel>
        <UploadArea onFileUpload={handleFileUpload} />
        <UploadAreaButtonDiv>
          <Button
            loading={isLoading}
            disabled={requestConfigs.length === 0}
            onClick={() => setRequestConfig(requestConfigs)}
          >
            Criar
          </Button>
        </UploadAreaButtonDiv>
      </UploadAreaDiv>
      <Toast position="top-right" />
    </MarketingAssetsDiv>
  )
}

export default AdminMarketingAssetsPage
