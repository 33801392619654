import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
`
export const ActionButton = styled.div`
  font-weight: bold;
  color: #4b4b4b;
  margin: 0 4px;

  &:hover {
    color: #bbb;
  }
`

export const ActionButtonText = styled.span`
  font-weight: bold;
  text-decoration: underline;
  margin-left: 3px;

  &:hover {
    text-decoration: underline;
  }
`
