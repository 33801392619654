import { UseFormRegister } from 'react-hook-form'

import { TextInput } from 'design-system/components'

import { FillQuotationInformationBody } from '@/App/clients/healthPlansOrders/dtos'

import { FormItem } from '../style'

interface BradescoFormQuotationProps {
  register: UseFormRegister<FillQuotationInformationBody>
}

export const BradescoFormQuotation: React.FC<BradescoFormQuotationProps> = ({ register }) => {
  return (
    <>
      <FormItem>
        <TextInput
          name="externalId"
          label="Número da cotação*"
          placeholder="0000000"
          vertical
          register={{
            ...register('externalId'),
          }}
        />
      </FormItem>
    </>
  )
}
