import { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { Skeleton, Table } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { StatusMappingExecutionDto } from '@/App/clients/orderInformationProcessing/dtos'
import { statusMappingExecutionQueryOptions } from '@/App/clients/orderInformationProcessing/queries/statusMappingExecutions'

import { StatusMappingExpandableRowContent } from '../expandableRow'
import { TableSkeletonDiv } from '../style'

const InsurerLocale = {
  AMIL: 'Amil',
  QUALICORP: 'Qualicorp',
  PORTO_SEGURO: 'Porto Seguro',
  BRADESCO: 'Bradesco Saúde',
  SULAMERICA: 'SulAmérica',
  PLANIUM: 'Planium',
  GNDI: 'GNDI',
} as const

const pendingStatusMappingExecutionsColumns = [
  {
    title: 'Operadora/Administradora',
    dataIndex: 'insurer',
    key: 'insurer',
    render: (insurer: keyof typeof InsurerLocale) => InsurerLocale[insurer],
  },
  {
    title: 'Status na operadora',
    dataIndex: 'externalStatus',
    key: 'externalStatus',
    render: (externalStatus: string) =>
      `${externalStatus.charAt(0).toUpperCase()}${externalStatus.slice(1).toLowerCase()}`,
  },
  {
    title: 'Identificado em',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: string) => dayjs(createdAt).format('DD/MM/YYYY HH:mm:ss'),
  },
]

export function PendingStatusMappingTable() {
  const pendingStatusMappingExecutionsQuery = useQuery(
    statusMappingExecutionQueryOptions({
      isMapped: false,
    }),
  )

  useEffect(() => {
    if (pendingStatusMappingExecutionsQuery.error) {
      triggerToast(false, 'Ops, ocorreu um erro ao carregar mapeamentos de status pendentes')
    }
  }, [pendingStatusMappingExecutionsQuery.error])

  if (pendingStatusMappingExecutionsQuery.isLoading) {
    return (
      <TableSkeletonDiv>
        <Skeleton />
      </TableSkeletonDiv>
    )
  }

  if (pendingStatusMappingExecutionsQuery.data?.statusMappingExecutions.length === 0) {
    return <p>Não há mapeamentos disponíveis.</p>
  }

  return (
    <Table
      loading={pendingStatusMappingExecutionsQuery.isLoading}
      columns={pendingStatusMappingExecutionsColumns}
      data={pendingStatusMappingExecutionsQuery.data?.statusMappingExecutions ?? []}
      expandableRowComponent={(record) => (
        <StatusMappingExpandableRowContent record={record as StatusMappingExecutionDto} />
      )}
    />
  )
}
