import React, { useEffect, useState } from 'react'
import { Control, Controller, ControllerRenderProps, RegisterOptions } from 'react-hook-form'

import Error from '../Error/Error'
import { StyledCaracteresLabel, StyledDivTextArea, StyledInputTextArea } from './style'

export interface Props {
  value?: string
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
  rows?: number
  key: string
  disabled?: boolean
  control?: Control<any>
  rules?: RegisterOptions
  name?: string
  maxCharactersQuantity?: number
  placeholder?: string
}

const TextAreaInput: React.FC<Props> = ({
  value,
  onChange,
  rows,
  key,
  disabled,
  control,
  rules,
  name,
  maxCharactersQuantity,
  placeholder,
}) => {
  const [charCount, setCharCount] = useState(0)
  const [isCharacterMaxQuantityExceeded, setIsCharacterMaxQuantityExceeded] = useState(false)

  useEffect(() => {
    if (value) {
      setCharCount(value.length)
    }
  }, [value])

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    field?: ControllerRenderProps,
  ) => {
    if (field) field.onChange(event)

    const desiredText = event.target.value

    if (maxCharactersQuantity && desiredText.length <= maxCharactersQuantity) {
      setCharCount(desiredText.length)
      onChange?.(event)
      setIsCharacterMaxQuantityExceeded(false)
    }
    if (desiredText.length === maxCharactersQuantity) {
      setIsCharacterMaxQuantityExceeded(true)
      setTimeout(() => {
        setIsCharacterMaxQuantityExceeded(false)
      }, 2000)
    }
  }

  return !control ? (
    <StyledDivTextArea>
      <StyledInputTextArea
        key={key}
        onChange={(event) => handleChange(event)}
        value={value}
        rows={rows}
        disabled={disabled}
        maxLength={maxCharactersQuantity}
        placeholder={placeholder}
      />
      {maxCharactersQuantity && (
        <StyledCaracteresLabel isCharacterMaxQuantityExceeded={isCharacterMaxQuantityExceeded}>
          {charCount} de {maxCharactersQuantity}
        </StyledCaracteresLabel>
      )}
    </StyledDivTextArea>
  ) : (
    <Controller
      control={control}
      name={name ?? ''}
      rules={rules}
      render={({ field, fieldState }) => (
        <StyledDivTextArea>
          <StyledInputTextArea
            {...field}
            value={field.value}
            onChange={(event) => handleChange(event, field)}
            key={key}
            rows={rows}
            disabled={disabled}
            maxLength={maxCharactersQuantity}
            placeholder={placeholder}
          />
          {maxCharactersQuantity && (
            <StyledCaracteresLabel isCharacterMaxQuantityExceeded={isCharacterMaxQuantityExceeded}>
              {charCount} de {maxCharactersQuantity} caracteres
            </StyledCaracteresLabel>
          )}
          {fieldState.error && <Error error={fieldState.error} />}
        </StyledDivTextArea>
      )}
    />
  )
}

export default TextAreaInput
