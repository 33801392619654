import { Tabs as AntdTabs } from 'antd'
import styled from 'styled-components'
import { colors } from '../../styles/theme'

interface StyledTabsProps {
  itemsColor?: string
}

export const StyledTabs = styled(AntdTabs)<StyledTabsProps>`
  &&& {
    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #56575b;
    }

    .ant-tabs .ant-tabs-ink-bar {
      background: ${(props) => props.itemsColor ?? `${colors.primary}`} !important;
    }
    .ant-tabs .ant-tabs-tab:hover {
      background: rgba(205, 215, 223, 0.3);
      border-radius: 10px;
      color: #56575b;
    }

    .ant-tabs {
      color: #56575b;
      padding-left: 10px;
      padding-right: 10px;
    }
    .ant-tabs .ant-tabs-tab-btn:active {
      color: #56575b !important;
    }

    .ant-tabs-tab-btn {
      color: ${(props) => props.itemsColor ?? `${colors.primary}`};
      text-shadow: none;
    }

    .ant-tabs-ink-bar {
      background: ${(props) => props.itemsColor ?? `${colors.primary}`} !important;
    }

    .ant-tabs-tab:hover {
      color: ${(props) => props.itemsColor ?? `${colors.primary}`} !important;
    }

    .ant-tabs-tab-remove:active {
      color: ${(props) => props.itemsColor ?? `${colors.primary}`} !important;
    }
  }
`
