import { colors } from '@design-system/styles/theme'
import styled from 'styled-components'

interface StyledProps {
  activeHover?: boolean
  isValidate?: boolean
}

export const EditInfoButton = styled.div<StyledProps>`
  width: 21rem;
  display: flex;
  column-gap: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: start;

  p {
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 0;
    font-weight: 600;
    color: ${colors.primaryText};
  }

  :hover {
    cursor: pointer;
    border-radius: 10px;
    background-color: ${(props) => props.activeHover && colors.mouseHover};
  }

  svg {
    color: ${(props) => (props.isValidate ? colors.red : '#878da1')};
  }

  span {
    color: ${(props) => (props.isValidate ? colors.red : '#878da1')};
  }
`
export const ExternalMain = styled.div`
  gap: 3%;
  display: flex;
`

export const ExternalWrapper = styled.div`
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  background-color: #f5f6f9;
  padding: 10px 10px 20px 10px;
`
export const FillInsurerIdInput = styled.input`
  width: 100px;
  height: 20px;
  border: none;
  background-color: #f5f6f9;

  :focus {
    outline: none;
  }
`
export const FillInsurerIdInputDiv = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const FillInsurerIdInputLabel = styled.p`
  font-size: 13px;
  font-weight: 600;
`

export const HorizontalLine = styled.hr`
  height: 1px;
  width: 100%;
  border-width: 0;
  background-color: #dcdfeb;
`

export const OtherInfosDiv = styled.div`
  width: 100%;
  row-gap: 10px;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`

export const OtherInfosHeader = styled.div`
  height: 30px;
  display: flex;
  font-size: 17px;
  margin-top: 15px;
  align-items: center;
  color: ${colors.primaryText};
  justify-content: space-between;

  span {
    font-weight: 600;
    size: 17px;
  }
`

export const SaveInsurerIdButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const WrapperExternalDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    font-size: 13px;
    font-weight: 600;
  }

  span {
    color: #878da1;
    font-weight: 400;
  }
`

export const SelectWrapper = styled.div`
  width: 42%;
`
export const ErrorStyled = styled.div`
  display: flex;
  column-gap: 5px;
  font-weight: 600;
  margin-top: 10px;
  font-size: 0.9rem;
  align-items: center;
  color: ${colors.red};
  font-family: 'Manrope', sans-serif;
`
