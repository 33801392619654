import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 60%;
  justify-content: space-between;
  gap: 10px;
`
export const StyledOCRView = styled.div`
  height: 30%;
  width: 49%;
  margin-top: 20px;
`
export const StyledForm = styled.form`
  width: 49%;
  margin-top: 20px;
`

export const ImageInspector = styled.div`
  max-height: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;

  border: 1px solid #dcdfeb;
  border-radius: 10px;

  font-size: 25px;
  margin-bottom: 25px;
`

export const StyledFigure = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
`
export const SearchIconDiv = styled.div`
  display: flex;
  align-self: flex-end;
  margin-right: 15px;
  margin-bottom: 15px;
`
export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5px;
`
export const FormItemLabel = styled.p`
  font-weight: 500;
  color: #4b4b4b;
  text-align: start;
  margin: 0;
`
