import { ReactNode } from 'react'

import { Divider, Form, Select, Space } from 'antd'
import styled from 'styled-components'

import { colors } from '../../styles/theme'

interface IStyledSelect {
  icon?: ReactNode
  backgroundColor?: string
}

export const StyledSelect = styled(Select)<IStyledSelect>`
  width: 100%;

  .ant-select-selection-item {
    font-size: 14px;
  }

  .ant-select-selection-item > .anticon {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }

  .ant-select-selector {
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : colors.white} !important;
    color: ${(props) => (props.backgroundColor ? colors.white : colors.primaryText)} !important;
    border-color: #b7c8d5 !important;
  }

  .ant-select-arrow {
    color: ${(props) => (props.backgroundColor ? colors.white : colors.primaryText)} !important;
  }

  .ant-select-selection-placeholder {
    font-size: 14px;
  }
`

export const SelectOptionsContent = styled.div`
  display: flex;
  align-items: center;
`

export const StyledLabel = styled(Form.Item)<{ label?: string }>`
  font-weight: 700;
  line-height: 18px;
  margin: 0;
  display: ${(props) => (props.label ? 'block' : 'none')};
  margin-top: 5px;

  .ant-form-item-label > label {
    color: #4b4b4b;
    font-size: 13px;
  }
`

export const StyledSpace = styled(Space)`
  padding: 0 8px 4px;
`

export const StyledDivider = styled(Divider)`
  padding: 0 8px 4px;
`
