import React, { ReactNode } from 'react'
import { StyledTimeline } from './style'
interface Items {
  color?: string
  dot?: ReactNode
  children?: ReactNode
}

export interface Props {
  items: Items[]
}

const Timeline: React.FC<Props> = ({ items }) => <StyledTimeline items={items} />

export default Timeline
