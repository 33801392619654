export const roles = [
  { label: 'Administrador', value: 'ADMINISTRATOR' },
  { label: 'Aposentado', value: 'RETIREE' },
  { label: 'Aprendiz', value: 'LEARNER' },
  { label: 'Demitido', value: 'FIRED' },
  { label: 'Diretor', value: 'MANAGER' },
  { label: 'Empregado CLT', value: 'CLT' },
  { label: 'Estagiário', value: 'INTERN' },
  { label: 'Sócio', value: 'PARTNER' },
]
