import { colors } from '@design-system/styles/theme'
import styled from 'styled-components'

export const OrderEntitiesDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;

  .ant-tabs .ant-tabs-tab {
    padding: 0 20px !important;
    height: 40px;
    margin-right: 90px;
  }
`

export const OrderEntitiesHeader = styled.div`
  font-size: 17px;
  color: ${colors.primaryText};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin-top: 15px;

  span {
    font-weight: 600;
    size: 17px;
  }
`

export const CompanyOrderHeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`

export const DownloadDocumentsButtonDiv = styled.div`
  width: 200px;

  p {
    font-size: 14px;
  }
`

export const ModalActionButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`
export const DownloadDocumentsModalCancelButtonDiv = styled.div`
  width: 200px;
`

export const DownloadDocumentsModalSaveButtonDiv = styled.div`
  width: 200px;
`

export const HorizontalLine = styled.hr`
  height: 1px;
  border-width: 0;
  background-color: #dcdfeb;
  width: 100%;
`

export const DownloadDocumentsModalDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  font-family: manrope;

  h3 {
    color: ${colors.primaryText};
    font-size: 17px;
  }

  p {
    color: #4b4b4b;
    font-size: 15px;
    font-weight: 600;
    margin: 0;
    text-align: center;
  }
`

export const CheckBoxesHeaderDiv = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 30%;
`

export const EntitiesCheckBoxesDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 8rem;
`
