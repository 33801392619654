import { Entity, GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'

import type { EntityType } from '../..'
import { getRequiredEntityFields } from '../helper'

export const getRequiredFieldsAmil = (orderData?: GetOrderInformationBody) => {
  const dynamicFormValidator: Array<{
    dynamicFormId?: string | null | undefined
    requiredFields: Array<string | null>
  } | null> = [
    getRequiredEntityFields(orderData?.company, [
      // company
      'document',
      'tradingName',
      'type',
      'registrationStatus',
      'openingDate',
      'address.zipCode',
      'address.number',

      // externalLegalRepresentative
      'externalLegalRepresentative.name',
      'externalLegalRepresentative.cpf',
      'externalLegalRepresentative.phone',
      'externalLegalRepresentative.mail',

      // Contact
      'name',
      'role',
      'phone',
      'mail',

      // financialOfficer
    ]),
    getRequiredEntityFields(orderData?.quotation, ['totalAmount']),
  ]

  if (orderData?.holders && orderData.holders.length > 0) {
    for (const holder of orderData.holders) {
      const { requiredFields } = getRequiredEntityFieldsAmil('HOLDER', holder, orderData)
      dynamicFormValidator.push(...requiredFields)

      const dependents = holder.dependents ?? []
      for (const dependent of dependents) {
        const { requiredFields } = getRequiredEntityFieldsAmil('DEPENDENT', dependent, orderData)
        dynamicFormValidator.push(...requiredFields)
      }
    }
  }

  return dynamicFormValidator.filter((value) => value)
}

export const getRequiredCompanyFieldsAmil = (orderData?: GetOrderInformationBody) => {
  return [
    orderData?.company?.document,
    orderData?.company?.tradingName,
    orderData?.company?.type,
    orderData?.company?.registrationStatus,
    orderData?.company?.openingDate,
    orderData?.company?.address?.zipCode,
    orderData?.company?.address?.number,

    orderData?.company?.name,
    orderData?.company?.role,
    orderData?.company?.phone,
    orderData?.company?.mail,

    orderData?.company?.externalLegalRepresentative?.name,
    orderData?.company?.externalLegalRepresentative?.cpf,
    orderData?.company?.externalLegalRepresentative?.phone,
    orderData?.company?.externalLegalRepresentative?.mail,
  ]
}

export const getRequiredHolderGroupFieldsAmil = (orderData?: GetOrderInformationBody) => {
  const holderList = orderData?.holders?.map((_, index) => {
    return [
      orderData?.holders?.at(index)?.rg,
      orderData?.holders?.at(index)?.cpf,
      orderData?.holders?.at(index)?.birthDate,
      orderData?.holders?.at(index)?.name,
      orderData?.holders?.at(index)?.issuingAgency,
      orderData?.holders?.at(index)?.maritalStatus,
      orderData?.holders?.at(index)?.contractType,
      orderData?.holders?.at(index)?.contractDate,
      orderData?.holders?.at(index)?.gender,
      orderData?.holders?.at(index)?.phone,
      orderData?.holders?.at(index)?.isAdministrativeLife,
      orderData?.holders?.at(index)?.address?.zipCode,
      orderData?.holders?.at(index)?.address?.number,
      orderData?.holders?.at(index)?.healthPlan?.catalogPlanId,
      ...(orderData?.holders?.at(index)?.hiredHealthPlan?.isActive
        ? [
            orderData?.holders?.at(index)?.hiredHealthPlan?.registrationNumber,
            orderData?.holders?.at(index)?.hiredHealthPlan?.activeHiredPlanInsurer,
            orderData?.holders?.at(index)?.hiredHealthPlan?.hiredAt,
            orderData?.holders?.at(index)?.hiredHealthPlan?.lastPaymentAt,
          ]
        : []),
      ...(orderData?.holders?.at(index)?.hiredDentalPlan?.isActive
        ? [
            orderData?.holders?.at(index)?.hiredDentalPlan?.activeHiredPlanInsurer,
            orderData?.holders?.at(index)?.hiredDentalPlan?.hiredAt,
            orderData?.holders?.at(index)?.hiredDentalPlan?.lastPaymentAt,
          ]
        : []),
    ]
  })

  const allDependents = getRequiredDependentFieldsAmil(orderData)

  return [...(holderList?.at(0) ?? []), ...allDependents]
}

export const getRequiredDependentFieldsAmil = (orderData?: GetOrderInformationBody) => {
  const dependentList =
    orderData?.holders?.map((holder) =>
      holder?.dependents?.map((dependent: Entity) => [
        dependent.rg,
        dependent.birthDate,
        dependent.name,
        dependent.issuingAgency,
        dependent.maritalStatus,
        dependent.gender,
        dependent.holderKinship,
        dependent.cpf,
        dependent.isAdministrativeLife,
        ...(dependent.hiredHealthPlan?.isActive
          ? [
              dependent.hiredHealthPlan.registrationNumber,
              dependent.hiredHealthPlan.activeHiredPlanInsurer,
              dependent.hiredHealthPlan.hiredAt,
              dependent.hiredHealthPlan.lastPaymentAt,
            ]
          : []),
        ...(dependent.hiredDentalPlan?.isActive
          ? [
              dependent.hiredDentalPlan.activeHiredPlanInsurer,
              dependent.hiredDentalPlan.hiredAt,
              dependent.hiredDentalPlan.lastPaymentAt,
            ]
          : []),
      ]),
    ) ?? []

  const allDependents = dependentList.flat(2)

  return allDependents
}

export const getRequiredEntityFieldsAmil = (
  entityType: EntityType,
  entity?: Entity | null,
  orderData?: GetOrderInformationBody,
) => {
  const requiredFields: Array<{
    dynamicFormId?: string | null | undefined
    requiredFields: Array<string | null>
  } | null> = []

  if (entityType === 'HOLDER') {
    requiredFields.push(
      getRequiredEntityFields(entity, [
        'rg',
        'cpf',
        'birthDate',
        'name',
        'mail',
        'issuingAgency',
        'maritalStatus',
        'contractType',
        'contractDate',
        'gender',
        'phone',
        'isAdministrativeLife',
        'address.zipCode',
        'address.number',
        ...(orderData?.quotation?.product === 'HEALTH' || orderData?.quotation?.isCombinedProposal
          ? ['healthPlan.catalogPlanId']
          : []),
        ...(orderData?.quotation?.product === 'DENTAL' || orderData?.quotation?.isCombinedProposal
          ? ['dentalPlan.catalogPlanId']
          : []),
        ...(entity?.hiredHealthPlan?.isActive
          ? [
              'hiredHealthPlan.registrationNumber',
              'hiredHealthPlan.activeHiredPlanInsurer',
              'hiredHealthPlan.hiredAt',
              'hiredHealthPlan.lastPaymentAt',
            ]
          : []),
        ...(entity?.hiredDentalPlan?.isActive
          ? [
              'hiredDentalPlan.activeHiredPlanInsurer',
              'hiredDentalPlan.hiredAt',
              'hiredDentalPlan.lastPaymentAt',
            ]
          : []),
        ...(orderData?.status === 'HIRED' ? ['height', 'weight'] : []),
      ]),
    )
  }

  if (entityType === 'DEPENDENT') {
    requiredFields.push(
      getRequiredEntityFields(entity, [
        'rg',
        'birthDate',
        'name',
        'issuingAgency',
        'maritalStatus',
        'gender',
        'holderKinship',
        'cpf',
        'isAdministrativeLife',
        ...(entity?.hiredHealthPlan?.isActive
          ? [
              'hiredHealthPlan.registrationNumber',
              'hiredHealthPlan.activeHiredPlanInsurer',
              'hiredHealthPlan.hiredAt',
              'hiredHealthPlan.lastPaymentAt',
            ]
          : []),
        ...(entity?.hiredDentalPlan?.isActive
          ? [
              'hiredDentalPlan.activeHiredPlanInsurer',
              'hiredDentalPlan.hiredAt',
              'hiredDentalPlan.lastPaymentAt',
            ]
          : []),
        ...(orderData?.status === 'HIRED' ? ['height', 'weight'] : []),
      ]),
    )
  }

  return {
    requiredFields,
    hasEmptyValues:
      requiredFields?.some(
        (validator) => validator?.requiredFields && validator.requiredFields.length > 0,
      ) ?? false,
  }
}
