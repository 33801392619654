import { QueryFunctionContext, queryOptions, useQuery } from '@tanstack/react-query'

import { api } from '../../http'

type ListPossibleStatesBaseRequest = {
  currentStatus?: string | null
}

type ListPossibleStatesRequest = QueryFunctionContext & ListPossibleStatesBaseRequest

type ListPossibleStatesResponse = {
  statusTransitions: Array<{
    direction: 'BACKWARD' | 'FORWARD'
    transitionStatus: string
  }>
}

async function listPossibleStates({ signal, currentStatus }: ListPossibleStatesRequest) {
  const response = await api.get<ListPossibleStatesResponse>(
    `${import.meta.env.VITE_ORDER_STATE_URL}/status-transitions`,
    {
      signal,
      params: { currentStatus },
    },
  )
  return response.data
}

export function listPossibleStatesQueryOptions(currentStatus?: string | null) {
  return queryOptions({
    queryKey: ['order-state', { currentStatus }] as const,
    queryFn: async (params) => listPossibleStates({ ...params, currentStatus }),
    refetchOnWindowFocus: false,
    enabled: !!currentStatus,
  })
}

export const usePossibleStatusesQuery = (currentStatus?: string | null) => {
  return useQuery(listPossibleStatesQueryOptions(currentStatus))
}
