import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import StatusTag from 'design-system/StatusTag/StatusTag'
import { Accordion, Button, Select, Skeleton } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { useRemapStatusMutation } from '@/App/clients/orderInformationProcessing/mutations/remapStatus'
import { statusMappingsQueryOptions } from '@/App/clients/orderInformationProcessing/queries/statusMappings'
import { statusMapperFn } from '@/App/helpers/statusMapper'

import { InsurerLocale } from '../..'
import {
  AriaLabel,
  FormContainer,
  FormDataContainer,
  FormLabel,
  FormRow,
  FormSelectorContainer,
  FullWidthContainer,
  SaveButtonContainer,
  SelectOptionLabel,
  StatusTagContainer,
  SubtitleText,
} from './style'

const getOrderStatusOptions = () => {
  const statusMapper = statusMapperFn()

  return Object.entries(statusMapper).map(([key, { label, color, icon }]) => ({
    label: (
      <>
        {icon}
        <SelectOptionLabel>{label}</SelectOptionLabel>
      </>
    ),
    value: key,
    color,
    icon,
  }))
}

interface FormState {
  insurer: string
  externalStatus: string
  externalSubstatus: string
  internalStatus: string
}

const defaultData: FormState = {
  insurer: 'Selecione a operadora',
  externalStatus: 'Selecione o status',
  externalSubstatus: 'Selecione o status',
  internalStatus: 'Selecione o novo status',
}

export const ReviewStatus = () => {
  const [formState, setFormState] = useState<FormState>(defaultData)
  const getStatusMappingsQuery = useQuery(
    statusMappingsQueryOptions(
      formState.insurer === defaultData.insurer ? null : formState.insurer,
    ),
  )
  const remapStatusMutation = useRemapStatusMutation({
    onSuccess: () => setFormState(defaultData),
  })

  const insurerOptions = Object.entries(InsurerLocale).map(([key, value]) => {
    return {
      label: value,
      value: key,
    }
  })

  useEffect(() => {
    if (getStatusMappingsQuery.error) {
      triggerToast(false, 'Ops, ocorreu um erro ao carregar dados')
    }
  }, [getStatusMappingsQuery.error])

  const handleChangeInsurer = (insurer: string) => {
    getStatusMappingsQuery.refetch()
    setFormState({
      ...defaultData,
      insurer,
    })
  }

  const handleChangeExternalStatus = (externalStatus: string) => {
    setFormState((prev) => ({
      insurer: prev.insurer,
      externalStatus,
      externalSubstatus: defaultData.externalSubstatus,
      internalStatus: defaultData.internalStatus,
    }))
  }

  const handleChangeExternalSubStatus = (substatus: string) => {
    setFormState((prev) => ({
      insurer: prev.insurer,
      externalStatus: prev.externalStatus,
      internalStatus: defaultData.internalStatus,
      externalSubstatus: substatus,
    }))
  }

  const handleChangeInternalStatus = (internalStatus: string) => {
    setFormState((prev) => ({
      ...prev,
      internalStatus,
    }))
  }

  const statusOptionsByInsurer = useMemo(() => {
    return (
      getStatusMappingsQuery.data?.statusMappings
        .filter(
          (item, index, arr) =>
            arr.findIndex(({ externalStatus }) => externalStatus === item.externalStatus) === index,
        )
        .map((item) => ({
          label: item.externalStatus,
          value: item.externalStatus,
        })) ?? []
    )
  }, [getStatusMappingsQuery.data])

  const substatusOptionsByStatus = useMemo(() => {
    return (
      getStatusMappingsQuery.data?.statusMappings
        .filter((item) => item.externalStatus === formState.externalStatus)
        .map((item) => ({
          label: item.externalSubstatus,
          value: item.externalSubstatus,
        })) ?? []
    )
  }, [getStatusMappingsQuery.data, formState.externalStatus])

  const actualStatus = useMemo(() => {
    let internalStatus = getStatusMappingsQuery.data?.statusMappings.find(
      (item) =>
        item.externalStatus === formState.externalStatus &&
        item.externalSubstatus === formState.externalSubstatus,
    )?.internalStatus

    if (!internalStatus) {
      internalStatus = getStatusMappingsQuery.data?.statusMappings.find(
        (item) =>
          item.externalStatus === formState.externalStatus && item.externalSubstatus === null,
      )?.internalStatus
    }

    return internalStatus
  }, [
    getStatusMappingsQuery.data?.statusMappings,
    formState.externalStatus,
    formState.externalSubstatus,
  ])

  const updateStatusMapping = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      const { externalStatus, externalSubstatus, insurer, internalStatus } = formState

      remapStatusMutation.mutate({
        internalStatus,
        insurer,
        externalStatus,
        externalSubstatus,
        previousStatus: actualStatus,
      })
    },
    [formState, actualStatus],
  )

  return (
    <FullWidthContainer>
      <SubtitleText>Modifique status que já estão mapeados</SubtitleText>

      <Accordion header="Remapear status">
        <FormContainer onSubmit={updateStatusMapping}>
          <FormDataContainer>
            <FormRow>
              <FormLabel>Operadora:</FormLabel>
              <FormSelectorContainer>
                <Select
                  value={formState.insurer}
                  options={insurerOptions}
                  defaultValue="Selecione a operadora"
                  onChange={(value) => {
                    if (typeof value === 'string') {
                      handleChangeInsurer(value)
                    }
                  }}
                />
              </FormSelectorContainer>
            </FormRow>

            <FormRow>
              <FormLabel>Nome status operadora:</FormLabel>
              <FormSelectorContainer>
                {getStatusMappingsQuery.isLoading ? (
                  <Skeleton type="input" />
                ) : (
                  <Select
                    defaultValue="Selecione o status"
                    value={formState.externalStatus}
                    options={statusOptionsByInsurer}
                    onChange={(value) => {
                      if (typeof value === 'string') {
                        handleChangeExternalStatus(value)
                      }
                    }}
                  />
                )}
              </FormSelectorContainer>
            </FormRow>

            <FormRow>
              <FormLabel>Substatus operadora:</FormLabel>
              <FormSelectorContainer>
                {getStatusMappingsQuery.isLoading ? (
                  <Skeleton type="input" />
                ) : (
                  <Select
                    defaultValue="Selecione o status"
                    value={formState.externalSubstatus}
                    options={substatusOptionsByStatus}
                    onChange={(value) => {
                      if (typeof value === 'string') {
                        handleChangeExternalSubStatus(value)
                      }
                    }}
                  />
                )}
              </FormSelectorContainer>
            </FormRow>

            <FormRow>
              <FormLabel>Status anterior Bliss:</FormLabel>
              {actualStatus ? (
                <StatusTagContainer>
                  <StatusTag status={actualStatus} />
                </StatusTagContainer>
              ) : (
                <AriaLabel>Aparecerá assim que for identificado</AriaLabel>
              )}
            </FormRow>

            <FormRow>
              <FormLabel>Status novo Bliss:</FormLabel>
              <FormSelectorContainer>
                <Select
                  defaultValue="Selecione o novo status"
                  value={formState.internalStatus}
                  options={getOrderStatusOptions()}
                  onChange={(value) => {
                    if (typeof value === 'string') {
                      handleChangeInternalStatus(value)
                    }
                  }}
                />
              </FormSelectorContainer>
            </FormRow>
          </FormDataContainer>

          <SaveButtonContainer>
            <Button
              loading={remapStatusMutation.isPending}
              disabled={!formState.internalStatus || !actualStatus}
              height={'40px'}
              htmlType="submit"
            >
              Salvar
            </Button>
          </SaveButtonContainer>
        </FormContainer>
      </Accordion>
    </FullWidthContainer>
  )
}
