import { Button as ConfirmButton } from 'design-system/components'
import styled from 'styled-components'

export const TabPaneContainer = styled.div`
  max-height: 650px;
  overflow-y: scroll;
  padding-right: 15px;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dcdfeb;
    border-radius: 10px;
  }
`

export const SelectLabel = styled.span`
  color: #4b4b4b;
  margin: 10px;
  font-weight: 600;
`

export const SelectContainer = styled.div`
  display: flex;

  .ant-select {
    width: auto;
  }
`

export const HistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  ul {
    list-style: none;
    padding: 0;
  }
`

export const NotesContainer = styled.div`
  margin-top: 10px;
`

export const NotesButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
`

export const ListNotesContainer = styled.div`
  margin: 30px 0;
  padding: 10px 0;
  border-bottom: 1px solid #dcdfeb;
`

export const ListNotesHeader = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;

  & span:last-child {
    margin-left: auto;
  }
`
export const ListNotesTitle = styled.div`
  font-weight: 700;
  color: #4b4b4b;
`

export const ListContentNote = styled.div``

export const StyledMention = styled.span`
  color: #8b6dff;
  font-weight: 700;
`

export const ListNotesDate = styled.span`
  color: #878ea6;
  font-size: 12px;
`

export const LoadMorButtonContainer = styled.div`
  width: 150px;
`

export const NoteTypeTag = styled.span`
  padding: 5px 15px;
  background: #f5f6f9;
  border-radius: 30px;
  color: #4b4b4b;
  font-weight: 600;
  font-size: 12px;

  & svg {
    margin-right: 5px;
  }
`

export const StyledConfirmButton = styled(ConfirmButton)`
  border-radius: 50px;
  background-color: rgb(81, 73, 132);
  color: rgb(255, 255, 255);
  padding: 10px 30px;
  border: none;
  font-weight: 600;
  width: auto;
  margin: 0;
`
export const CancelButton = styled.button`
  border: none;
  padding: 0 10px 0 10px;
  font-weight: 600;
  border-radius: 30px;
  background-color: #efefef;
  color: #4b4b4b;
  margin-right: 10px;
  height: 32px;

  &:hover {
    cursor: pointer;
  }
`

export const NotationType = styled.div`
  div {
    margin-top: 10px;
    margin-left: 10px;
  }
`
export const NotationAction = styled.div`
  display: flex;
`
export const SkeletonContainer = styled.div`
  width: 50%;
`

export const StyledNotesMainContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyledFormContatainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`
