import { normalizeString } from './string'

export function assembleInsurer(insurerName?: string | null) {
  if (!insurerName) {
    return null
  }

  const insurers: Record<string, string> = {
    NOTRE_DAME_INTERMEDICA: 'GNDI',
    NOTREDAME_INTERMEDICA: 'GNDI',
    BRADESCO_SEGUROS: 'BRADESCO',
  }

  const normalizedInsurerName = normalizeString(insurerName)?.replace(/\s+/g, '_').toUpperCase()

  return insurers[normalizedInsurerName] || normalizedInsurerName
}
