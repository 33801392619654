import { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Skeleton, Tabs, Toast } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { getInsurersQueryOptions } from '@/App/clients/orderInformationProcessing/queries/insurers'

import InsurerAutomationsLastExecutions from './lastExecutions'
import { InsurerAutomationsStatusMapping } from './statusMapping'
import {
  AutomationsDiv,
  ColoredCheckOutlined,
  ColoredCloseOutlined,
  Header,
  InsurerCard,
  InsurerCardTitle,
  InsurerCardsDiv,
  InsurersDiv,
  InsurersTitle,
  PageSubtitle,
  PageTitle,
  TabsDiv,
} from './style'

export enum InsurerLocale {
  'AMIL' = 'Amil',
  'QUALICORP' = 'Qualicorp',
  'PORTO_SEGURO' = 'Porto Seguro',
  'BRADESCO' = 'Bradesco Saúde',
  'SULAMERICA' = 'SulAmérica',
  'PLANIUM' = 'Planium',
  'GNDI' = 'GNDI',
}

const InsurerAutomationsPage = () => {
  const getInsurersQuery = useQuery(getInsurersQueryOptions())

  useEffect(() => {
    if (getInsurersQuery.error)
      triggerToast(false, 'Ops, ocorreu um erro ao carregar operadoras com automações ativas')
  }, [getInsurersQuery.error])

  const tabsItems = [
    {
      key: '0',
      label: 'Últimas execuções',
      children: <InsurerAutomationsLastExecutions />,
    },
    {
      key: '1',
      label: 'Mapeamento de status',
      children: <InsurerAutomationsStatusMapping />,
    },
  ]
  return (
    <AutomationsDiv>
      <Header>
        <PageTitle>Automações</PageTitle>
        <PageSubtitle>
          Acompanhe as informações das excecuções de automações com operadoras ativas na plataforma
        </PageSubtitle>
      </Header>

      <InsurersDiv>
        <InsurersTitle>Operadoras/Administradoras ativas</InsurersTitle>
        <InsurerCardsDiv>
          {getInsurersQuery.isLoading ? (
            <Skeleton
              paragraph={{
                rows: 2,
                width: '400px',
              }}
            />
          ) : (
            getInsurersQuery.data?.insurers.map((insurer, index) => {
              return (
                <InsurerCard key={index}>
                  <InsurerCardTitle>{InsurerLocale[insurer.name]}</InsurerCardTitle>
                  {insurer.lastOrdersFillingExecutionStatus === 'SUCCESS' ? (
                    <ColoredCheckOutlined />
                  ) : (
                    <ColoredCloseOutlined />
                  )}
                </InsurerCard>
              )
            })
          )}
        </InsurerCardsDiv>
      </InsurersDiv>

      <TabsDiv>
        <Tabs items={tabsItems} itemsColor="black" />
      </TabsDiv>

      <Toast position="top-right" />
    </AutomationsDiv>
  )
}

export default InsurerAutomationsPage
