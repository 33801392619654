import { useMutation } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '../../http'

type QuotationDispatchProps = {
  orderId?: string | null
  insurer?: string | null
}

const sendQuotationDispatch = async ({ orderId, insurer }: QuotationDispatchProps) => {
  const response = await api.post(
    `${import.meta.env.VITE_BLISS_ORDER_GATEWAY_API_URL}/order/quotation/dispatch`,
    {
      insurer,
      orderId,
    },
    {
      headers: {
        'x-api-key': import.meta.env.VITE_BLISS_ORDER_GATEWAY_API_KEY,
      },
    },
  )
  return response.data
}

export const useQuotationDispatchMutation = (QuotationDispatchProps?: QuotationDispatchProps) => {
  const orderId = QuotationDispatchProps?.orderId
  const insurer = QuotationDispatchProps?.insurer

  return useMutation({
    mutationKey: ['quotation', orderId, insurer],
    mutationFn: sendQuotationDispatch,
    onSuccess: () => {
      triggerToast(
        true,
        'Solicitação de cotação feita com sucesso! Agora basta acompanhar o status da cotação.',
      )
    },
    onError: () => {
      triggerToast(false, 'Opss, ocorreu um erro ao enviar sua solicitação.')
    },
  })
}
