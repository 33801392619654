import { HttpVerbs } from '../http/enum'
import { UseFetchConfig } from '../http/interface'

export const AuthenticationServerRequests = {
  signIn: (mail: string, password: string): UseFetchConfig => {
    return {
      url: `${import.meta.env.VITE_AUTHENTICATION_SERVER_URL}/api/v1/sessions`,
      method: HttpVerbs.Post,
      data: {
        mail,
        password,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }
  },
}
