import { colors } from '@design-system/styles/theme'
import { Table } from 'antd'
import styled from 'styled-components'

export const CompactTable = styled(Table)`
  margin-top: 20px;

  .ant-table-thead .ant-table-cell {
    background-color: transparent;
    border-bottom: none;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    display: none !important;
  }

  .ant-table-thead > tr > th {
    color: #6c7895;
    padding-bottom: 4px;
    font-weight: normal;
  }

  .ant-table-tbody > tr > td {
    background-color: #ffffff;
    margin-top: 1px;
    color: #485964;
  }

  .anticon-down .anticon-up svg {
    color: ${colors.gray} !important;
  }

  .ant-table-tbody > tr:first-child > td:last-child {
    border-top-right-radius: 7px;
  }

  .ant-table-tbody > tr:first-child > td:first-child {
    border-top-left-radius: 7px;
  }

  .ant-table-tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 7px;
  }

  .ant-table-tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 7px;
  }

  .ant-table-tbody > tr:first-child > td {
    border-top: 1px solid #dfdfdf;
  }

  .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid #dfdfdf;

    padding: 0 15px;
  }

  .ant-table-tbody > tr > td {
    border-right: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;

    padding: 4px;
    min-width: 50px;
  }

  & .ant-table-tbody:hover {
    cursor: pointer !important;
  }
`

export const ButtonContainer = styled.div`
  position: fixed;
  width: 300px;
  left: 50%;
  transform: translate(-50%, -85%);
  bottom: 0;
`
