import { Tabs } from 'design-system/components'

import { ITableData } from '..'
import { GridsItemsEditorComponent } from './gridsItemsEditor'

export const GridsItemsEditComponent = ({
  items,
  setModalOpen,
}: {
  items: ITableData[]
  setModalOpen: (a: boolean) => void
}) => {
  const tabItems = [
    {
      key: '0',
      label: 'Atualizar Comissões',
      children: <GridsItemsEditorComponent items={items} setModalOpen={setModalOpen} />,
    },
  ]

  return <Tabs items={tabItems} itemsColor="#514984" />
}
