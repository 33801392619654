import styled from 'styled-components'

export const StyledButton = styled.button<{ isActive: boolean }>`
  padding: 8px 15px 8px 15px;
  border-radius: 30px;
  border: 1px solid #dcdfeb;
  background-color: ${(props) => (props.isActive ? '#514984' : '#FFFFFF')};
  color: ${(props) => (props.isActive ? '#FFF' : '#4B4B4B')};
  & svg {
    margin-right: 5px;
  }
  &:hover {
    cursor: pointer;
    background: ${(props) => (props.isActive ? '#514984' : '#F6F6F9')};
    color: ${(props) => (props.isActive ? '#FFFFFF' : '#4b4b4b')};
  }
`

export const FiltersContent = styled.div`
  display: flex;
  gap: 0.8rem;
  flex-wrap: wrap;
  align-items: center;
`

export const ColumnBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-left: 10px;
`

export const ColumnHeader = styled.div`
  padding: 10px;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #dcdfeb;
    margin-top: 0.7rem;
  }
`

export const SelectedFiltersContent = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 1rem;
`

export const SelectedFilterSpan = styled.span`
  background-color: rgb(245, 246, 249);
  padding: 5px 20px 5px 20px;
  border-radius: 20px;
  font-weight: 700;
  font-size: 13px;
  color: rgb(75, 75, 75);

  & svg {
    font-size: 11px;
  }

  &:hover {
    cursor: pointer;
  }
`
export const FilterDiv = styled.div`
  z-index: 10;
`
export const NewProposalButtonContainer = styled.div`
  width: 200px;
  margin-left: auto;
`
