/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { useOrderInformationQuery } from '@/App/clients/healthPlansOrders/queries/orderInformation'
import { useUpdateStatus } from '@/App/clients/orderState/mutation/updateStatus'

import {
  ButtonDiv,
  CancelButton,
  Icon,
  ModalContent,
  ModalSubTitle,
  ModalTextArea,
  ModalTitle,
} from './style'

interface IUpdateStatusDescriptionModalProps {
  setIsModalOpen?: (value: boolean) => void
  orderId?: string
  setShouldReload?: React.Dispatch<React.SetStateAction<boolean>>
  setOrderStatus?: React.Dispatch<React.SetStateAction<string | null>>
}

export const UpdateStatusDescriptionModal: React.FC<IUpdateStatusDescriptionModalProps> = ({
  setIsModalOpen,
  orderId,
  setShouldReload,
  setOrderStatus,
}) => {
  const [statusUpdateDescription, setStatusUpdateDescription] = useState('')

  const orderInformationQuery = useOrderInformationQuery({ orderId })
  const updateStatusMutation = useUpdateStatus(orderId)

  const handleSaveAndSend = async () => {
    if (orderId) {
      await updateStatusMutation.mutateAsync({
        nextStatus: 'FILLING_NOT_MEET_REQUIREMENTS',
        order: orderInformationQuery?.data,
        statusUpdateMetadata: {
          statusUpdateDescription,
        },
      })
    }
  }

  useEffect(() => {
    if (updateStatusMutation.isSuccess) {
      setOrderStatus && setOrderStatus('FILLING_NOT_MEET_REQUIREMENTS')
      if (setShouldReload) setShouldReload(true)
      triggerToast(true, 'Status atualizado com sucesso')
      setIsModalOpen && setIsModalOpen(false)
    }
  }, [updateStatusMutation.isSuccess])

  useEffect(() => {
    if (updateStatusMutation.isError) triggerToast(false, 'Erro ao atualizar')
  }, [updateStatusMutation.isError])

  return (
    <ModalContent>
      <Icon>
        <ExclamationCircleOutlined />
      </Icon>
      <ModalTitle>Qual é a pendência</ModalTitle>
      <ModalSubTitle>Assim que você enviar, notificaremos o corretor.</ModalSubTitle>
      <ModalTextArea
        cols={30}
        rows={10}
        value={statusUpdateDescription}
        onChange={(event) => setStatusUpdateDescription(event.target.value)}
      />
      <ButtonDiv>
        <Button
          disabled={!statusUpdateDescription.length}
          loading={updateStatusMutation.isPending}
          onClick={handleSaveAndSend}
        >
          Salvar e enviar
        </Button>
        <CancelButton onClick={() => setIsModalOpen && setIsModalOpen(false)}>
          Cancelar
        </CancelButton>
      </ButtonDiv>
    </ModalContent>
  )
}
