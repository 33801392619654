/* eslint-disable @typescript-eslint/no-explicit-any */
import { InputContainer, NumericInput } from './styles'

export const InvisibleInput = ({
  value,
  onChange,
  onBlur,
}: {
  value: any
  onChange: any
  onBlur?: any
}) => {
  return (
    <InputContainer>
      <NumericInput
        placeholder="0"
        type="number"
        onChange={onChange}
        value={value}
        onBlur={onBlur}
      />
      %
    </InputContainer>
  )
}
