import { QueryFunctionContext, queryOptions } from '@tanstack/react-query'

import { api } from '../../http'
import { GetCatalogProductsDto } from '../dtos'

async function getCatalogProducts({ signal }: QueryFunctionContext) {
  const response = await api.get<GetCatalogProductsDto>(
    `${import.meta.env.VITE_CATALOGO_API_URL}/produtos`,
    {
      signal,
      headers: { Authorization: localStorage.getItem('authToken') },
    },
  )
  return response.data
}

export function getCatalogProductsOptions(params?: { enabled?: boolean }) {
  const enabled = params?.enabled ?? true

  return queryOptions({
    queryKey: ['catalog', 'products'] as const,
    queryFn: getCatalogProducts,
    refetchOnWindowFocus: false,
    enabled,
    select(data) {
      return data.data.map((product) => ({
        label: product.nome,
        value: product.id,
      }))
    },
  })
}
