import { ChangeEvent, useCallback, useState } from 'react'

import { WidgetProps } from '@rjsf/utils'
import { TextInputMask } from 'design-system/TextInput/TextInput'
import {
  cepApplyMask,
  cnpjApplyMask,
  cpfApplyMask,
  currencyApplyMask,
  heightApplyMask,
  phoneApplyMask,
  rgApplyMask,
  weightApplyMask,
} from 'design-system/TextInput/masks'
import { TextInput } from 'design-system/components'
import styled, { css } from 'styled-components'

type FormItemProps = {
  colSpan?: string | number
}

const FormItem = styled.div<FormItemProps>`
  ${({ colSpan = 1 }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 6.25rem;
    grid-column: span ${colSpan.toString()};
  `}
`

type MaskFunction = (value?: string) => string | undefined

const masks: Record<TextInputMask | 'rg', MaskFunction> = {
  cep: cepApplyMask,
  cnpj: cnpjApplyMask,
  cpf: cpfApplyMask,
  money: currencyApplyMask,
  phone: phoneApplyMask,
  rg: rgApplyMask,
  height: heightApplyMask,
  weight: weightApplyMask,
}

export function OCRDisabledTextInputWidget({
  name,
  value,
  label,
  required,
  onChange,
  options,
  rawErrors,
  disabled: isOCRFilled,
  formContext,
  ...rest
}: WidgetProps) {
  const [isEmptyWhenTyping, setIsEmptyWhenTyping] = useState(false)
  const applyMask: MaskFunction = masks[options?.mask as string]
  const isRegisteredInInsurer = formContext?.isRegisteredInInsurer ?? false
  const onTextChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsEmptyWhenTyping(e.target.value === '')

      onChange(
        e.target.value === ''
          ? options?.emptyValue || ''
          : applyMask
          ? applyMask(e.target.value)
          : e.target.value,
      )
    },
    [onChange],
  )

  const error = rawErrors
    ? {
        type: 'unknown',
        message: rawErrors.at(0),
      }
    : null

  const maskedValue = applyMask ? applyMask(value) : value

  return (
    <FormItem colSpan={options.colSpan as string | number}>
      <TextInput
        label={required ? `${label}*` : label}
        name={name}
        onChange={onTextChange}
        value={maskedValue || ''}
        vertical
        error={error}
        {...rest}
        disabled={!isRegisteredInInsurer && (isOCRFilled || (!isEmptyWhenTyping && !maskedValue))}
      />
    </FormItem>
  )
}
