import React from 'react'

import { Collapse } from 'antd'

import ArrowDownIcon from '../../icons/arrowDown'
import ArrowUpIcon from '../../icons/arrowUp'
import { ExpandableRowDiv, ExpandableRowDivider, StyledCollapse, StyledSpace } from './style'

export interface Props {
  dataSource?: Array<object>
  mobileColumns?: Array<{
    title: string
    dataIndex: string
    key: string
  }>
  expandableRowComponent?: (record: object) => React.ReactNode
}

const MobileTable = ({ dataSource, mobileColumns, expandableRowComponent }) => {
  return (
    <StyledSpace direction="vertical">
      {dataSource?.map((record: object, index: number) => (
        <StyledCollapse
          key={index + 1}
          accordion
          expandIconPosition="end"
          expandIcon={({ isActive }) => (isActive ? <ArrowUpIcon /> : <ArrowDownIcon />)}
        >
          <Collapse.Panel
            header={
              <div>
                {mobileColumns?.map((column, columnIndex) => (
                  <div key={columnIndex}>
                    {column.customHeader ? (
                      <column.customHeader record={record} />
                    ) : (
                      <span>{record[column.dataIndex]}</span>
                    )}
                  </div>
                ))}
              </div>
            }
            key={index}
          >
            {expandableRowComponent && (
              <ExpandableRowDiv>
                <ExpandableRowDivider />
                {expandableRowComponent(record)}
              </ExpandableRowDiv>
            )}
          </Collapse.Panel>
        </StyledCollapse>
      ))}
    </StyledSpace>
  )
}

export default MobileTable
