import React, { useEffect, useState } from 'react'
import { FieldError, UseFormRegisterReturn } from 'react-hook-form'

import Error from '../Error/Error'
import { ButtonStyled, CounterStyled, InputStyled } from './style'

export interface CounterProps {
  count: number | null
  hideButton?: 'increment' | 'decrement' | 'both'
  onCountChange: (count: number) => void
  min?: number
  max?: number
  name?: string
  error?: FieldError
  register?: UseFormRegisterReturn
}

const Counter: React.FC<CounterProps> = ({
  count,
  hideButton,
  onCountChange,
  min,
  max,
  name,
  error,
  register,
}) => {
  const [hideDecrement, setHideDecrement] = useState(false)
  const [hideIncrement, setHideIncrement] = useState(false)

  useEffect(() => {
    switch (hideButton) {
      case 'decrement':
        setHideDecrement(true)
        break
      case 'increment':
        setHideIncrement(true)
        break

      case 'both':
        setHideDecrement(true)
        setHideIncrement(true)
    }
  })

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (hideButton === 'both') return

    const newCount = parseInt(event.target.value)
    if (min && max && newCount >= min && newCount <= max) {
      onCountChange(newCount)
    }
  }

  const incrementCounter = () => {
    if (max != null && count && count >= max) return
    onCountChange(count ? count + 1 : 0 + 1)
  }

  const decrementCounter = () => {
    if (!count) return
    if (min != null && count <= min) return

    onCountChange(count - 1)
  }

  return (
    <>
      <CounterStyled>
        <ButtonStyled hideButton={hideDecrement} onClick={decrementCounter}>
          -
        </ButtonStyled>
        <InputStyled
          placeholder="0"
          type="text"
          name={name}
          {...register}
          value={count ?? undefined}
          onChange={handleInputChange}
        />
        <ButtonStyled hideButton={hideIncrement} onClick={incrementCounter}>
          +
        </ButtonStyled>
      </CounterStyled>
      {error && <Error error={error}></Error>}
    </>
  )
}

export default Counter
