import React, { ReactNode } from 'react'

import { StyledButton } from './styles'

export interface Props {
  children?: ReactNode
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  style?: React.CSSProperties
  href?: string
  backgroundColor?: string
  loading?: boolean
  disabled?: boolean
  htmlType?: 'button' | 'submit' | 'reset'
  height?: string
  formId?: string | null
  palette?: 'primary' | 'cancel'
}

const Button: React.FC<Props> = ({
  children,
  onClick,
  style,
  href,
  loading,
  disabled,
  htmlType = 'button',
  backgroundColor,
  height,
  formId,
  palette = 'primary',
}) => (
  <StyledButton
    htmlType={htmlType}
    onClick={onClick}
    href={href}
    loading={loading}
    style={style}
    disabled={disabled}
    backgroundColor={backgroundColor}
    height={height}
    form={formId ?? undefined}
    palette={palette}
  >
    {children}
  </StyledButton>
)

export default Button
