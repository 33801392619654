import { useState } from 'react'

import dayjs from 'dayjs'
import { DatePicker, Toast } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { Action } from '../..'
import {
  Button,
  EffectiveDateFilterContent,
  FilterButton,
  FinalDateFilter,
  InitialDateFilter,
  LastDaysFilter,
  LastDaysTitle,
  PeriodFilter,
  PeriodFilterLabel,
  PeriodFilterTitle,
} from './style'

interface Props {
  handleRequest: (action: Action, filterValues: { key: string; value: string }[]) => void
}

const filterButtonOptions = [
  {
    label: '7',
    value: '7',
    name: 'effectiveDatePeriod',
    isActive: false,
  },
  {
    label: '15',
    value: '15',
    name: 'effectiveDatePeriod',
    isActive: false,
  },
  {
    label: '30',
    value: '30',
    name: 'effectiveDatePeriod',
    isActive: false,
  },
]

export const EffectiveDateFilter = ({ handleRequest }: Props) => {
  const [filterButtonState, setFilterButtonState] = useState(filterButtonOptions)
  const [initialEffectiveDate, setInitialEffectiveDate] = useState<string>()
  const [endEffectiveDate, setEndEffectiveDate] = useState<string>()

  const handleOnClick = (e) => {
    const updatedButtons = filterButtonState.map((button) => {
      const clickedButton = e.target as HTMLButtonElement
      const buttonValue = clickedButton.value

      if (button.value === buttonValue) {
        return { ...button, isActive: true }
      }
      return { ...button, isActive: false }
    })

    handleRequest(Action.ReplaceFilter, [{ key: 'effectiveDatePeriod', value: e.target.value }])

    setFilterButtonState(updatedButtons)
  }

  const handleOnClickFilter = () => {
    setFilterButtonState(
      filterButtonState.map((state) => {
        return { ...state, isActive: false }
      }),
    )

    if (!initialEffectiveDate || !endEffectiveDate) {
      triggerToast(false, 'Preencha a data inicial e a data final')
      return
    }

    handleRequest(Action.ReplaceFilter, [
      { key: 'initialEffectiveDate', value: initialEffectiveDate },
      { key: 'endEffectiveDate', value: endEffectiveDate },
    ])
  }

  return (
    <>
      <EffectiveDateFilterContent>
        <LastDaysFilter>
          <LastDaysTitle>Últimos dias:</LastDaysTitle>
          {filterButtonOptions.map((option) => (
            <FilterButton
              key={option.value}
              name={option.name}
              onClick={handleOnClick}
              value={option.value}
              isActive={
                filterButtonState.find((button) => button.value === option.value)?.isActive ?? false
              }
            >
              {option.label}
            </FilterButton>
          ))}
        </LastDaysFilter>
        <PeriodFilterTitle>Selecione o período:</PeriodFilterTitle>
        <PeriodFilter>
          <InitialDateFilter>
            <PeriodFilterLabel>Início</PeriodFilterLabel>
            <DatePicker
              name="initialDate"
              placeholder="Data Início"
              onChange={(date: dayjs.Dayjs) =>
                setInitialEffectiveDate(dayjs(date).format('YYYY-MM-DD'))
              }
            />
          </InitialDateFilter>
          <FinalDateFilter>
            <PeriodFilterLabel>Fim</PeriodFilterLabel>
            <DatePicker
              name="endDate"
              placeholder="Data fim"
              onChange={(date: dayjs.Dayjs) =>
                setEndEffectiveDate(dayjs(date).format('YYYY-MM-DD'))
              }
            />
          </FinalDateFilter>
        </PeriodFilter>
        <Button onClick={handleOnClickFilter}>Filtrar</Button>
      </EffectiveDateFilterContent>
      <Toast />
    </>
  )
}
