import styled from 'styled-components'

interface SliderProps {
  imageStart: boolean
  imageFinished: boolean
  transformValue: string
}

interface ContainerProps {
  containerWidth?: string
}

export const Container = styled.div<ContainerProps>`
  background: white;
  height: 100px;
  width: ${(props) => props.containerWidth ?? '100%'};
  margin: auto;
  overflow: hidden;
`

export const Image = styled.img`
  height: 80px;
`

export const Slider = styled.div<SliderProps>`
  display: flex;
  flex-direction: row;
  column-gap: 60px;
  padding-top: 10px;
  transition: ${(props) => (props.imageStart ? '3.5s' : '1s')};
  transition: ${(props) => (props.imageFinished ? '3.5s' : '1s')};
  transform: ${({ transformValue }) => transformValue};
`
