import { QueryFunctionContext, queryOptions, useQuery } from '@tanstack/react-query'
import CheckCircleIcon from 'design-system/checkCircle'
import FailCircleIcon from 'design-system/failCircle'
import InProgressIcon from 'design-system/inProgressCircle'

import { api } from '../../http'
import { DispatchEvent, GetDispatchEventResponse } from '../dtos'

type GetDispatchEventRequest = QueryFunctionContext & {
  orderId?: string | null
}

const getOrderDispatchEvents = async ({ signal, orderId }: GetDispatchEventRequest) => {
  return api
    .get<GetDispatchEventResponse>(
      `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/orders/${orderId}/dispatch-events`,
      {
        signal,
        headers: { Authorization: localStorage.getItem('authToken') },
      },
    )
    .then((res) => res.data)
}

export function getOrderDispatchEventOptions({
  orderId,
  isDispatchedToInternalSystem,
  status,
  dispatchStatus,
}: {
  orderId?: string | null
  isDispatchedToInternalSystem?: boolean
  status?: string | null
  dispatchStatus?: boolean
}) {
  return queryOptions({
    queryKey: ['orders', orderId, 'dispatchEvents'] as const,
    queryFn: async (params) => getOrderDispatchEvents({ ...params, orderId }),
    enabled:
      !!orderId &&
      (status === 'DISPATCH_IN_PROGRESS' ||
        status === 'QUOTATION_INSURER' ||
        isDispatchedToInternalSystem === true) &&
      dispatchStatus,
    refetchOnWindowFocus: false,
    retry: false,
    select(data) {
      const eventsList: DispatchEvent[] = data?.dispatchEvents?.map((event) => {
        const statuses = {
          SUCCESS: {
            Icon: CheckCircleIcon,
            amount: event.amount,
            label: 'Concluído',
            createdAt: event.createdAt,
            insurerOpportunityId: event.insurerOpportunityId,
            insurerOrderId: event.insurerOrderId,
            processingStatus: event.processingStatus,
            type: event.type,
            product: event.product,
            updatedAt: event.updatedAt,
          },
          PENDING: {
            Icon: InProgressIcon,
            label: 'Em andamento',
            createdAt: event.createdAt,
            processingStatus: event.processingStatus,
            type: event.type,
            product: event.product,
            updatedAt: event.updatedAt,
          },
          FAILURE: {
            Icon: FailCircleIcon,
            label: 'Ocorreu falha',
            createdAt: event.createdAt,
            errorReason: event.errorReason,
            insurerOpportunityId: event.insurerOpportunityId,
            insurerOrderId: event.insurerOrderId,
            processingStatus: event.processingStatus,
            type: event.type,
            product: event.product,
            updatedAt: event.updatedAt,
          },
        }

        return statuses[event?.processingStatus.toUpperCase()] || statuses.FAILURE
      })
      return eventsList
    },
  })
}
export const useOrderDispatchEvents = ({
  orderId,
  isDispatchedToInternalSystem,
  status,
  dispatchStatus,
}: {
  orderId?: string | null
  isDispatchedToInternalSystem?: boolean
  status?: string | null
  dispatchStatus?: boolean
}) => {
  return useQuery(
    getOrderDispatchEventOptions({ orderId, isDispatchedToInternalSystem, status, dispatchStatus }),
  )
}
