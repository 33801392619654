import { ReactNode, useEffect, useRef, useState } from 'react'
import React from 'react'
import { Control, Controller, RegisterOptions } from 'react-hook-form'

import { PlusOutlined } from '@ant-design/icons'
import { Select as AntdSelect, Button, Input, InputRef } from 'antd'

import Error from '../Error/Error'
import {
  SelectOptionsContent,
  StyledDivider,
  StyledLabel,
  StyledSelect,
  StyledSpace,
} from './styles'

export interface Option<T = string> {
  label: ReactNode
  value: T
  disabled?: boolean
  color?: string
}

export interface Props<T> {
  defaultValue?: string | string[] | null
  options: Array<Option<T>>
  disabled?: boolean
  size?: 'large' | 'middle' | 'small'
  onChange?: (value: string | string[]) => void
  icon?: ReactNode
  backgroundColor?: string
  showSearch?: boolean
  value?: string | string[] | null
  label?: string
  style?: React.CSSProperties
  control?: Control<any>
  rules?: RegisterOptions
  name?: string
  customInputOption?: boolean
  mode?: 'multiple' | 'tags'
  placeholder?: string
}

const Select: React.FC<Props<string>> = ({
  onChange,
  size = 'large',
  disabled,
  options,
  defaultValue,
  icon,
  showSearch,
  value,
  label,
  style,
  control,
  rules,
  name,
  customInputOption = false,
  mode,
  placeholder,
}) => {
  const [selectColor, setSelectedColor] = useState('')
  const [customOption, setCustomOption] = useState('')
  const inputRef = useRef<InputRef>(null)
  const [availableOptions, setAvailableOptions] = useState<Array<Option>>([])

  useEffect(() => {
    setSelectedColor(options.find((option) => option.value === value)?.color ?? '')
    setAvailableOptions(options)
  }, [options, value])

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault()

    const formatOption = { value: customOption, label: customOption }

    setAvailableOptions([...availableOptions, formatOption])
    setCustomOption('')

    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  return (
    <>
      <StyledLabel label={label}></StyledLabel>

      {!control ? (
        <StyledSelect
          mode={mode}
          onChange={(value) => onChange && onChange(value as string | string[])}
          size={size}
          style={style}
          disabled={disabled}
          defaultValue={defaultValue}
          icon={icon}
          backgroundColor={selectColor}
          showSearch={showSearch}
          optionFilterProp="label"
          value={value}
          onSelect={(selectedValue, option) => {
            if (selectedValue === value) {
              setSelectedColor(option.style.color)
            }
          }}
          placeholder={placeholder}
          dropdownRender={(menu) => {
            if (!customInputOption) {
              return menu
            }

            return (
              <>
                {menu}
                <StyledDivider />
                <StyledSpace>
                  <Input
                    placeholder="Nome do documento"
                    ref={inputRef}
                    value={customOption}
                    onChange={(e) => setCustomOption(e.target.value)}
                  />
                  <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                    Adicionar
                  </Button>
                </StyledSpace>
              </>
            )
          }}
        >
          {availableOptions.map((option) => (
            <AntdSelect.Option
              key={option.value}
              value={option.value}
              label={option.label}
              style={{ color: option.color }}
              disabled={option.disabled ?? false}
            >
              <SelectOptionsContent>
                {icon} {option.label}
              </SelectOptionsContent>
            </AntdSelect.Option>
          ))}
        </StyledSelect>
      ) : (
        <Controller
          control={control}
          name={name ?? ''}
          rules={rules}
          render={({ field, fieldState }) => (
            <>
              <StyledSelect
                mode={mode}
                onChange={field.onChange}
                size={size}
                style={style}
                disabled={disabled}
                defaultValue={defaultValue}
                icon={icon}
                backgroundColor={selectColor}
                showSearch={showSearch}
                optionFilterProp="label"
                value={field.value}
                placeholder={placeholder}
                onSelect={(_value, option) => setSelectedColor(option.style.color)}
                dropdownRender={(menu) => {
                  if (!customInputOption) {
                    return menu
                  }
                  return (
                    <>
                      {menu}
                      <StyledDivider />
                      <StyledSpace>
                        <Input
                          placeholder="Nome do documento"
                          ref={inputRef}
                          value={customOption}
                          onChange={(e) => setCustomOption(e.target.value)}
                        />
                        <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                          Adicionar
                        </Button>
                      </StyledSpace>
                    </>
                  )
                }}
              >
                {availableOptions.map((option) => (
                  <AntdSelect.Option
                    key={option.value}
                    value={option.value}
                    label={option.label}
                    style={{ color: option.color }}
                    disabled={option.disabled}
                  >
                    <SelectOptionsContent>
                      {icon} {option.label}
                    </SelectOptionsContent>
                  </AntdSelect.Option>
                ))}
              </StyledSelect>

              {fieldState.error && <Error error={fieldState.error}></Error>}
            </>
          )}
        />
      )}
    </>
  )
}

export default Select
