import { Radio as RadioColorPicker } from 'antd'
import styled from 'styled-components'
import { colors } from '../../styles/theme'

export const StyledRadioColor = styled(RadioColorPicker)`
  &&& {
    .ant-radio-checked .ant-radio-inner {
      border-color: #d6d6d6 !important;
    }

    .ant-radio:hover .ant-radio-inner {
      border-color: ${colors.secundaryText};
    }

    .ant-radio-checked .ant-radio-inner:after {
      background-color: ${(Props) => Props.value};
      border-color: #d6d6d6;
    }

    .ant-radio-checked .ant-radio-inner {
      background-color: #d6d6d6;
      border-radius: 200px 200px 200px 200px;
    }

    .ant-radio-inner {
      background-color: ${(Props) => Props.value};
      border-color: ${(Props) => Props.value};
    }

    .ant-radio-inner::after {
      transform: scale(0.575);
    }
  }
`
export const RadioColorPickerContentDiv = styled.div``
