import { QueryFunctionContext, queryOptions } from '@tanstack/react-query'

import { api } from '../../http'
import { GetInsurersDto } from '../dtos'

async function getInsurers({ signal }: QueryFunctionContext) {
  return api
    .get<GetInsurersDto>(
      `${import.meta.env.VITE_ORDER_INFORMATION_PROCESSING_URL}/api/v1/insurers`,
      {
        signal,
        headers: { Authorization: localStorage.getItem('authToken') },
      },
    )
    .then((res) => res.data)
}

export function getInsurersQueryOptions() {
  return queryOptions({
    queryKey: ['insurers'] as const,
    queryFn: async (params) => getInsurers(params),
  })
}
