/* eslint-disable @typescript-eslint/no-explicit-any */
import { Entity, GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import { calculateAge } from '@/App/utils/calculateProcessingTime'

import type { EntityType } from '../..'
import { getRequiredEntityFields } from '../helper'

export const getRequiredFieldsSisweb = (orderData?: GetOrderInformationBody) => {
  const dynamicFormValidator: Array<{
    dynamicFormId?: string | null | undefined
    requiredFields: Array<string | null>
  } | null> = []

  if (orderData?.type === 'COMPANY') {
    dynamicFormValidator.push(
      getRequiredEntityFields(orderData?.company, [
        // company
        'document',
        'tradingName',
        'address.zipCode',
        'address.number',
        'address.entityAddress',

        // contact
        'phone',

        // externalLegalRepresentative
        'externalLegalRepresentative.name',
        'externalLegalRepresentative.mail',
        'externalLegalRepresentative.phone',
      ]),
    )

    if (orderData?.holders && orderData.holders.length > 0) {
      for (const holder of orderData.holders) {
        const { requiredFields } = getRequiredEntityFieldsSisweb('HOLDER', holder, orderData)
        dynamicFormValidator.push(...requiredFields)

        const dependents = holder.dependents ?? []
        for (const dependent of dependents) {
          const { requiredFields } = getRequiredEntityFieldsSisweb(
            'DEPENDENT',
            dependent,
            orderData,
          )
          dynamicFormValidator.push(...requiredFields)
        }
      }
    }

    return dynamicFormValidator.filter((value) => value)
  }

  if (orderData?.holders && orderData.holders.length > 0) {
    for (const holder of orderData.holders) {
      const { requiredFields } = getRequiredEntityFieldsSisweb('HOLDER', holder, orderData)
      dynamicFormValidator.push(...requiredFields)

      const dependents = holder.dependents ?? []
      for (const dependent of dependents) {
        const { requiredFields } = getRequiredEntityFieldsSisweb('DEPENDENT', dependent, orderData)
        dynamicFormValidator.push(...requiredFields)
      }
    }
  }

  return dynamicFormValidator.filter((value) => value)
}

export const getRequiredCompanyFieldsSisweb = (orderData?: GetOrderInformationBody) => {
  return [
    orderData?.company?.document,
    orderData?.company?.tradingName,
    orderData?.company?.address?.zipCode,
    orderData?.company?.address?.number,
    orderData?.company?.address?.entityAddress,

    orderData?.company?.phone,

    orderData?.company?.externalLegalRepresentative?.name,
    orderData?.company?.externalLegalRepresentative?.mail,
    orderData?.company?.externalLegalRepresentative?.phone,
  ]
}

export const getRequiredHolderFieldsSisweb = (orderData?: GetOrderInformationBody) => {
  const shouldValidateFinancialOfficer = calculateAge(orderData?.holders?.at(0)?.birthDate) < 18

  return [
    orderData?.holders?.at(0)?.name,
    orderData?.holders?.at(0)?.phone,
    orderData?.holders?.at(0)?.gender,
    orderData?.holders?.at(0)?.birthDate,
    orderData?.holders?.at(0)?.cpf,
    orderData?.holders?.at(0)?.rg,
    orderData?.holders?.at(0)?.address?.zipCode,
    orderData?.holders?.at(0)?.address?.number,
    orderData?.holders?.at(0)?.address?.entityAddress,
    ...(orderData?.quotation?.product === 'HEALTH'
      ? [
          orderData?.holders?.at(0)?.healthPlan?.catalogPlanId,
          orderData?.holders?.at(0)?.healthPlan?.accommodationType,
          orderData?.holders?.at(0)?.healthPlan?.lifeAmount,
        ]
      : []),
    ...(orderData?.quotation?.product === 'DENTAL'
      ? [
          orderData?.holders?.at(0)?.dentalPlan?.catalogPlanId,
          orderData?.holders?.at(0)?.dentalPlan?.accommodationType,
          orderData?.holders?.at(0)?.dentalPlan?.lifeAmount,
        ]
      : []),
    ...(orderData?.holders?.at(0)?.hiredHealthPlan?.isActive
      ? [orderData?.holders?.at(0)?.hiredHealthPlan?.activeHiredPlanInsurer]
      : []),
    ...(shouldValidateFinancialOfficer
      ? [
          orderData?.holders?.at(0)?.financialOfficer?.name,
          orderData?.holders?.at(0)?.financialOfficer?.mail,
          orderData?.holders?.at(0)?.financialOfficer?.phone,
          orderData?.holders?.at(0)?.financialOfficer?.cpf,
          orderData?.holders?.at(0)?.financialOfficer?.birthDate,
        ]
      : []),
  ]
}

export const getRequiredDependentsFieldsSisweb = (orderData?: GetOrderInformationBody) => {
  const dependentList =
    orderData?.holders?.map((holder) =>
      holder?.dependents?.map((dependent: Entity) => [
        dependent.name,
        dependent.rg,
        dependent.cpf,
        dependent.holderKinship,
        dependent.birthDate,
        dependent.gender,
      ]),
    ) ?? []

  const allDependents = dependentList.flatMap((dep) => dep?.at(0) ?? [])
  return allDependents
}

export const getRequiredHolderGroupFieldsSisweb = (orderData?: GetOrderInformationBody) => {
  const holderList = orderData?.holders?.map((_, index) => {
    const shouldValidateFinancialOfficer =
      calculateAge(orderData?.holders?.at(index)?.birthDate) < 18

    return [
      orderData?.holders?.at(index)?.name,
      orderData?.holders?.at(index)?.phone,
      orderData?.holders?.at(index)?.gender,
      orderData?.holders?.at(index)?.birthDate,
      orderData?.holders?.at(index)?.cpf,
      orderData?.holders?.at(index)?.rg,
      orderData?.holders?.at(index)?.isAdministrativeLife,
      orderData?.holders?.at(index)?.address?.zipCode,
      orderData?.holders?.at(index)?.address?.number,
      orderData?.holders?.at(index)?.address?.entityAddress,
      ...(orderData?.quotation?.product === 'HEALTH'
        ? [
            orderData?.holders?.at(index)?.healthPlan?.catalogPlanId,
            orderData?.holders?.at(index)?.healthPlan?.accommodationType,
            orderData?.holders?.at(index)?.healthPlan?.lifeAmount,
          ]
        : []),
      ...(orderData?.quotation?.product === 'DENTAL'
        ? [
            orderData?.holders?.at(index)?.dentalPlan?.catalogPlanId,
            orderData?.holders?.at(index)?.dentalPlan?.accommodationType,
            orderData?.holders?.at(index)?.dentalPlan?.lifeAmount,
          ]
        : []),
      ...(orderData?.holders?.at(index)?.hiredHealthPlan?.isActive
        ? [orderData?.holders?.at(index)?.hiredHealthPlan?.activeHiredPlanInsurer]
        : []),
      ...(shouldValidateFinancialOfficer
        ? [
            orderData?.holders?.at(index)?.financialOfficer?.name,
            orderData?.holders?.at(index)?.financialOfficer?.mail,
            orderData?.holders?.at(index)?.financialOfficer?.phone,
            orderData?.holders?.at(index)?.financialOfficer?.cpf,
            orderData?.holders?.at(index)?.financialOfficer?.birthDate,
          ]
        : []),
    ]
  })

  const dependentList =
    orderData?.holders?.map((holder) =>
      holder?.dependents?.map((dependent: Entity) => [
        dependent.name,
        dependent.rg,
        dependent.cpf,
        dependent.holderKinship,
        dependent.birthDate,
        dependent.gender,
        dependent.isAdministrativeLife,
      ]),
    ) ?? []

  const allDependents = dependentList.flatMap((dep) => dep?.at(0) ?? [])

  return [...(holderList?.at(0) ?? []), ...allDependents]
}

export const getRequiredOtherInfos = (orderData?: GetOrderInformationBody) => {
  return [orderData?.dispatchedAt, orderData?.insurerId]
}

export const getRequiredEntityFieldsSisweb = (
  entityType: EntityType,
  entity?: Entity | null,
  orderData?: GetOrderInformationBody,
) => {
  const requiredFields: Array<{
    dynamicFormId?: string | null | undefined
    requiredFields: Array<string | null>
  } | null> = []

  if (entityType === 'HOLDER') {
    if (orderData?.type === 'COMPANY') {
      requiredFields.push(
        getRequiredEntityFields(entity, [
          'name',
          'phone',
          'gender',
          'birthDate',
          'address.zipCode',
          'address.number',
          'address.entityAddress',
          'cpf',
          'rg',
          'isAdministrativeLife',
          ...(orderData?.quotation?.product === 'HEALTH' || orderData?.quotation?.isCombinedProposal
            ? ['healthPlan.catalogPlanId', 'healthPlan.accommodationType', 'healthPlan.lifeAmount']
            : []),
          ...(orderData?.quotation?.product === 'DENTAL' || orderData?.quotation?.isCombinedProposal
            ? ['dentalPlan.catalogPlanId', 'dentalPlan.accommodationType', 'dentalPlan.lifeAmount']
            : []),

          ...(entity?.hiredHealthPlan?.isActive ? ['hiredHealthPlan.activeHiredPlanInsurer'] : []),
        ]),
      )
    } else {
      const shouldValidateFinancialOfficer = calculateAge(entity?.birthDate) < 18

      requiredFields.push(
        getRequiredEntityFields(entity, [
          'name',
          'phone',
          'gender',
          'birthDate',
          'address.zipCode',
          'address.number',
          'address.entityAddress',
          'cpf',
          'rg',
          ...(orderData?.quotation?.product === 'HEALTH' || orderData?.quotation?.isCombinedProposal
            ? ['healthPlan.catalogPlanId', 'healthPlan.accommodationType', 'healthPlan.lifeAmount']
            : []),
          ...(orderData?.quotation?.product === 'DENTAL' || orderData?.quotation?.isCombinedProposal
            ? ['dentalPlan.catalogPlanId', 'dentalPlan.accommodationType', 'dentalPlan.lifeAmount']
            : []),

          ...(entity?.hiredHealthPlan?.isActive ? ['hiredHealthPlan.activeHiredPlanInsurer'] : []),
          ...(shouldValidateFinancialOfficer
            ? [
                'financialOfficer.name',
                'financialOfficer.mail',
                'financialOfficer.phone',
                'financialOfficer.cpf',
                'financialOfficer.birthDate',
              ]
            : []),
        ]),
      )
    }
  }

  if (entityType === 'DEPENDENT') {
    const isLegalAge = calculateAge(entity?.birthDate) >= 18
    if (orderData?.type === 'COMPANY') {
      requiredFields.push(
        getRequiredEntityFields(entity, [
          'name',
          'rg',
          ...(isLegalAge ? ['cpf'] : []),
          'holderKinship',
          'birthDate',
          'gender',
          'isAdministrativeLife',
        ]),
      )
    } else {
      requiredFields.push(
        getRequiredEntityFields(entity, [
          'name',
          'rg',
          ...(isLegalAge ? ['cpf'] : []),
          'holderKinship',
          'birthDate',
          'gender',
        ]),
      )
    }
  }

  return {
    requiredFields,
    hasEmptyValues:
      requiredFields?.some(
        (validator) => validator?.requiredFields && validator.requiredFields.length > 0,
      ) ?? false,
  }
}
