import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px 70px;

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
    color: #4b4b4b !important;
  }

  .ant-tabs-tab-btn {
    font-weight: 600;
    color: #878ea6 !important;
  }
`
