import { useEffect, useState } from 'react'

import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { CheckBox, SearchInput } from 'design-system/components'

import { Action } from '../..'
import { ManagerFilterContent, SearchInputWrapper } from './style'

interface Props {
  data?: Array<{ value: string; label: string }>
  handleRequest: (action: Action, filterValues: [{ key: string; value: string }]) => void
  selectedManagers?: string[]
}

export const ManagerFilter = ({ data, handleRequest, selectedManagers }: Props) => {
  const [filteredManagers, setFilteredManagers] = useState(data)

  const brokersLimit = (data?: Array<{ label: string; value: string }>) => {
    const orderedBrokers = data?.sort((a, b) => a.label.localeCompare(b.label))
    const brokers = orderedBrokers?.slice(0, 10)

    return brokers
  }

  const handleChange = (event: CheckboxChangeEvent) => {
    const action = event.target.checked ? Action.AddFilter : Action.RemoveFilter

    handleRequest(action, [{ key: 'managerId', value: event.target.value }])
  }

  useEffect(() => {
    setFilteredManagers(brokersLimit(data))
  }, [])

  return (
    <>
      <SearchInputWrapper>
        <SearchInput
          options={data}
          placeholder="Buscar supervisor"
          onSelect={(_value, selected) =>
            setFilteredManagers([{ label: selected.label, value: selected.reference }])
          }
          onChange={(value) => {
            if (!value) {
              setFilteredManagers(brokersLimit(data))
            }
          }}
        />
      </SearchInputWrapper>
      {filteredManagers?.map((option, index) => {
        return (
          <ManagerFilterContent key={index}>
            <CheckBox
              onChange={(e) => handleChange(e)}
              label={option.label}
              value={option.value}
              name="managerId"
              checked={selectedManagers?.includes(option.value) ? true : false}
            />
          </ManagerFilterContent>
        )
      })}
    </>
  )
}
