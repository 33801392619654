import { Upload } from 'antd'
import styled from 'styled-components'

import { colors } from '../../styles/theme'

const { Dragger } = Upload

export interface StyledTextProps {
  isErrorEnabled: boolean
}

export const StyledUploadArea = styled(Dragger)`
  .ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: ${colors.purple};
  }

  .ant-upload-wrapper .ant-upload-drag .ant-upload {
    padding: 20px 0;
  }

  & svg {
    margin-right: 10px;
  }
`

export const StyledText = styled.p<StyledTextProps>`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.isErrorEnabled ? '#BF404C' : `${colors.primaryText}`)};
`
