import { holderAmilDentalSchema } from './amil'
import { holderDefaultDentalSchema } from './default'
import { holderGndiDentalSchema } from './gndi'
import { holderPortoSeguroDentalSchema } from './portoSeguro'

export const holderDentalSchemas = {
  amil: holderAmilDentalSchema,
  gndi: holderGndiDentalSchema,
  portoSeguro: holderPortoSeguroDentalSchema,
  default: holderDefaultDentalSchema,
}
