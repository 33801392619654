import styled from 'styled-components'

import { colors } from '../../../../../design-system/src/styles/theme'

export const FilesDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  row-gap: 10px;
  width: 100%;
`

export const FilesHeader = styled.div`
  font-size: 17px;
  color: ${colors.primaryText};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin-top: 15px;

  span {
    font-weight: 600;
    size: 17px;
  }
`
export const HorizontalLine = styled.hr`
  height: 1px;
  border-width: 0;
  background-color: #dcdfeb;
  width: 100%;
`

export const UploadFilesLoadingDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`
