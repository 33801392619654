import { QueryFunctionContext, infiniteQueryOptions, queryOptions } from '@tanstack/react-query'

import { api } from '../../http'
import { PaginationParams } from '../../http/interface'
import { GetStatusMappingExecutionsDto } from '../dtos'

const PER_PAGE_STATUS_MAPPING_EXECUTION = 50

type GetStatusMappingExecutionsBaseRequest = PaginationParams & {
  isMapped?: boolean
}

type GetStatusMappingExecutionsRequest = QueryFunctionContext &
  GetStatusMappingExecutionsBaseRequest

async function getStatusMappingExecutions({
  isMapped,
  perPage = PER_PAGE_STATUS_MAPPING_EXECUTION,
  page = 0,
  signal,
}: GetStatusMappingExecutionsRequest) {
  const url = `${
    import.meta.env.VITE_ORDER_INFORMATION_PROCESSING_URL
  }/api/v1/status-mapping-executions?perPage=${perPage}&page=${page}&isMapped=${isMapped?.toString()}`

  return api
    .get<GetStatusMappingExecutionsDto>(url, {
      signal,
      headers: {
        Authorization: localStorage.getItem('authToken'),
      },
    })
    .then((res) => {
      return {
        ...res.data,
        statusMappingExecutions: res.data.statusMappingExecutions.map((statusMapping) => {
          return {
            ...statusMapping,
            key: statusMapping.id,
          }
        }),
      }
    })
}

export function statusMappingExecutionQueryOptions({
  isMapped,
  page,
  perPage,
}: GetStatusMappingExecutionsBaseRequest) {
  return queryOptions({
    queryKey: ['statusMappingExecutions', { isMapped, page, perPage }] as const,
    queryFn: async (params) => {
      return getStatusMappingExecutions({ ...params, isMapped, page, perPage })
    },
  })
}

export function statusMappingExecutionInfiniteQueryOptions({
  isMapped,
  perPage,
}: GetStatusMappingExecutionsBaseRequest) {
  return infiniteQueryOptions({
    queryKey: ['statusMappingExecutions', { isMapped, perPage }] as const,
    initialPageParam: 0,
    queryFn: async (params) => {
      return getStatusMappingExecutions({
        ...params,
        isMapped,
        page: params.pageParam,
        perPage,
      })
    },
    getNextPageParam: (lastPage) => (lastPage.hasNext ? lastPage.page + 1 : undefined),
  })
}
