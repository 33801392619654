const HealthIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill="#8589A2"
        d="M18.234 1.967C17.074.7 15.519 0 13.872 0c-1.428 0-2.781.52-3.869 1.487A5.779 5.779 0 006.135 0C4.488 0 2.933.7 1.773 1.967-.02 3.933-.48 6.873.52 9.333h4.456l2.474-3.7c.147-.22.4-.333.66-.293.26.047.467.233.533.487l1.621 5.92 1.854-2.78c.12-.187.333-.3.553-.3h3.335c.367 0 .667.3.667.666 0 .367-.3.667-.667.667H13.03l-2.474 3.7a.656.656 0 01-.554.3c-.033 0-.073 0-.106-.007a.667.667 0 01-.534-.486l-1.62-5.92-1.854 2.78a.663.663 0 01-.553.3H1.306L9.51 19.78c.127.14.3.22.493.22.187 0 .367-.08.494-.22l7.736-8.453c2.356-2.58 2.356-6.78.001-9.36z"
      ></path>
    </svg>
  )
}

export default HealthIcon
