import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  PaperClipOutlined,
  SearchOutlined,
  VerticalAlignBottomOutlined,
  WarningFilled,
} from '@ant-design/icons'
import { RcFile } from 'antd/es/upload'

import { triggerToast } from '../../utils/triggers'
import type { OnFileUploadInput } from '../Upload/Upload'
import { Button, Image, Spinner, Upload } from '../components'
import {
  ButtonWrapper,
  ImageInspector,
  InfoWrapper,
  LoadingDiv,
  SearchIconDiv,
  StyledButton,
  StyledDeleteOutlined,
  StyledDownloadOutlined,
  StyledDrawer,
  StyledFigure,
  StyledInfo,
  StyledIsExternalType,
  StyledLine,
  StyledLineActions,
  StyledLineButton,
  StyledLineContainer,
  StyledLinePending,
  StyledLineType,
  StyledName,
  StyledPaperClipOutlined,
  StyledSpan,
  StyledTable,
  StyledTitle,
  TableBody,
  TableHeader,
  TdActions,
  TdName,
  TdType,
} from './style'
import { MAX_FILE_SIZE_BYTES } from '../../utils/validationValues'

export interface Document {
  id: string
  type?: string
  fileName?: string | null
  status: string
  previewURL?: string
  isLoadingDownloadDocument?: boolean
  isLoadingDeleteDocument?: boolean
  fileNameChildren?: React.ReactNode
  typeChildren?: React.ReactNode
  required?: boolean
}

export interface File {
  fileContent: Blob
  fileContentType: string
  fileName: string
}

export interface Props {
  onDeleteClick?: (reference: string) => void
  onDownloadClick?: (document: Document) => void
  onAttachmentClick?: (document: Document, file?: OnFileUploadInput) => void
  onOCRClick?: (document: Document) => void
  firstColumn?: 'name' | 'type'
  onRowClick?: () => void
  data: Document[]
  loading?: boolean
  entityType?: string
  entityName?: string
  documentType?: string
  isInspectorOpen?: boolean
  setInspectorOpen?: React.Dispatch<React.SetStateAction<boolean>>
  onChangeInspector?: () => void
  onDownloadImageFromInspectorClick?: (document: Document) => void
  isLoadingDownloadImageFromInspector?: boolean
  isExternal?: boolean
  disabled?: boolean
}

const DocumentTable: React.FC<Props> = ({
  onDeleteClick,
  onDownloadClick,
  onAttachmentClick,
  onOCRClick,
  firstColumn = 'name',
  data,
  onRowClick,
  loading,
  entityName,
  entityType,
  documentType,
  isInspectorOpen,
  setInspectorOpen,
  onDownloadImageFromInspectorClick,
  isLoadingDownloadImageFromInspector,
  isExternal = false,
  disabled = false,
}) => {
  const [imagePreviewVisibility, setImagePreviewVisibility] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)

  const isMobile = useMediaQuery({ maxWidth: 768 })

  const validateFileSize = (file: RcFile) => {
    const isValidSize = file.size <= MAX_FILE_SIZE_BYTES
    if (!isValidSize) {
      triggerToast(false, `O arquivo deve ser menor que 5MB!`)
    }
    return isValidSize
  }

  const showInspector = (index: number) => {
    setImageIndex(index)
    setInspectorOpen && setInspectorOpen(true)
  }

  const onClose = () => {
    setInspectorOpen && setInspectorOpen(false)
  }

  const DocumentTableDesktop = () => {
    return (
      <>
        <TableHeader>
          {firstColumn === 'type' ? (
            <>
              <p>Tipo do arquivo</p>
              <p>Nome do arquivo</p>
            </>
          ) : (
            <>
              <p>Nome do arquivo</p>
              <p>Tipo do arquivo</p>
            </>
          )}
          <p>Ações</p>
        </TableHeader>
        <TableBody onRowClick={onRowClick}>
          {loading ? (
            <LoadingDiv>
              <Spinner />
            </LoadingDiv>
          ) : (
            data?.map((document, index) => {
              return (
                <div key={index}>
                  {index !== 0 && <p />}
                  {document.status === 'PENDING' && !isExternal ? (
                    <tr key={index}>
                      <TdName firstColumn={'name'}>
                        <StyledButton>Arquivo solicitado ao lead</StyledButton>
                      </TdName>
                      <TdType firstColumn={'name'}>{document.type}</TdType>
                      <TdActions>
                        {onAttachmentClick && !disabled && (
                          <Upload
                            type="icon"
                            showFileUploadList={false}
                            icon={<PaperClipOutlined />}
                            onFileUpload={(file) => onAttachmentClick(document, file[0])}
                            accept="image/png, image/jpg, .pdf"
                          />
                        )}
                        <StyledSpan>-</StyledSpan>
                        {document.isLoadingDeleteDocument ? (
                          <Spinner height="15px" width="15px" />
                        ) : (
                          onDeleteClick &&
                          !disabled && <DeleteOutlined onClick={() => onDeleteClick(document.id)} />
                        )}
                      </TdActions>
                    </tr>
                  ) : (
                    <tr key={index}>
                      {firstColumn === 'type' ? (
                        <>
                          <TdType
                            firstColumn={'type'}
                            onClick={() => {
                              onRowClick && !document.typeChildren
                                ? onRowClick()
                                : showInspector(index)
                            }}
                          >
                            {document.type ?? (!document.status ? document.typeChildren : '-')}
                          </TdType>
                          <TdName
                            firstColumn={'type'}
                            onClick={() => {
                              onRowClick && !document.fileNameChildren
                                ? onRowClick()
                                : showInspector(index)
                            }}
                            documentName={document.fileName}
                          >
                            {document.fileName ??
                              (!document.status ? document.fileNameChildren : '-')}
                            {document?.required && (
                              <span>
                                <WarningFilled style={{ color: '#bf404c', fontSize: '10px' }} />{' '}
                                Campo Obrigatório
                              </span>
                            )}
                          </TdName>
                        </>
                      ) : (
                        <>
                          <TdName
                            firstColumn={'name'}
                            onClick={() => {
                              onRowClick && !document.fileNameChildren
                                ? onRowClick()
                                : showInspector(index)
                            }}
                          >
                            {document.fileName ??
                              (!document.status ? document.fileNameChildren : '-')}
                          </TdName>
                          <TdType
                            firstColumn={'name'}
                            onClick={() => {
                              onRowClick && !document.typeChildren
                                ? onRowClick()
                                : showInspector(index)
                            }}
                          >
                            {document.type ?? (!document.status ? document.typeChildren : '-')}
                          </TdType>
                        </>
                      )}
                      <TdActions>
                        {[
                          'RG',
                          'CNH',
                          'Documento de Identificação',
                          'Documento de Identificação*',
                          'Documento de identificação com Foto*',
                          'Carta de permanência',
                          'Carta de Permanência',
                          'Carta do Plano Saúde Anterior',
                          'Carta do Plano Dental Anterior',
                          'Comprovante de residência',
                        ].includes(document?.type ?? '') &&
                          document.status === 'UPLOADED' &&
                          onOCRClick && <EyeOutlined onClick={() => onOCRClick(document)} />}

                        {onAttachmentClick && document.status && !disabled ? (
                          <Upload
                            type="icon"
                            showFileUploadList={false}
                            icon={<PaperClipOutlined />}
                            onFileUpload={(file) => onAttachmentClick(document, file[0])}
                            accept="image/png, image/jpg, .pdf"
                            beforeUpload={(file) => validateFileSize(file)}
                          />
                        ) : (
                          <StyledSpan>-</StyledSpan>
                        )}

                        {document.isLoadingDownloadDocument ? (
                          <Spinner height="15px" width="15px" />
                        ) : onDownloadClick && document.status === 'UPLOADED' ? (
                          <DownloadOutlined onClick={() => onDownloadClick(document)} />
                        ) : (
                          <StyledSpan>-</StyledSpan>
                        )}

                        {document.isLoadingDeleteDocument ? (
                          <Spinner height="15px" width="15px" />
                        ) : document?.status === 'UPLOADED' && onDeleteClick && !disabled ? (
                          <DeleteOutlined onClick={() => onDeleteClick(document.id)} />
                        ) : (
                          <StyledSpan>-</StyledSpan>
                        )}
                      </TdActions>
                    </tr>
                  )}
                </div>
              )
            })
          )}
          <StyledDrawer
            title="Fechar"
            placement="right"
            onClose={onClose}
            open={isInspectorOpen && data[imageIndex]?.fileName != null}
            mask={false}
          >
            <div>
              <ImageInspector>
                <StyledFigure>
                  {data[imageIndex]?.fileName?.split('.').pop() === 'pdf' ? (
                    <embed src={data[imageIndex]?.previewURL} />
                  ) : (
                    <Image
                      src={data[imageIndex]?.previewURL ?? ''}
                      visible={imagePreviewVisibility && !!data[imageIndex]?.previewURL}
                      setVisible={setImagePreviewVisibility}
                    />
                  )}
                </StyledFigure>
                <SearchIconDiv>
                  <SearchOutlined onClick={() => setImagePreviewVisibility(true)} />
                </SearchIconDiv>
              </ImageInspector>

              <InfoWrapper>
                <ButtonWrapper>
                  {imageIndex >= 1 && (
                    <Button
                      onClick={() => setImageIndex(imageIndex - 1)}
                      style={{
                        background: 'none',
                        boxShadow: 'none',
                        width: '80%',
                        color: '#4b4b4b',
                        border: '1px solid #DCDFEB',
                      }}
                    >
                      <ArrowLeftOutlined />
                    </Button>
                  )}
                </ButtonWrapper>

                <StyledInfo>
                  <StyledTitle>{entityType}</StyledTitle>
                  <StyledName>{entityName}</StyledName>
                  {documentType && <StyledTitle>{documentType}</StyledTitle>}
                </StyledInfo>

                <ButtonWrapper>
                  {imageIndex < data.length - 1 && (
                    <Button
                      onClick={() => setImageIndex(imageIndex + 1)}
                      style={{
                        background: 'none',
                        boxShadow: 'none',
                        width: '80%',
                        color: '#4b4b4b',
                        border: '1px solid #DCDFEB',
                        marginLeft: '20%',
                      }}
                    >
                      <ArrowRightOutlined />
                    </Button>
                  )}
                </ButtonWrapper>
              </InfoWrapper>

              <Button
                onClick={() =>
                  onDownloadImageFromInspectorClick &&
                  onDownloadImageFromInspectorClick(data[imageIndex])
                }
                style={{
                  height: 30,
                  width: '100%',
                  fontSize: '15px',
                  background: 'none',
                  color: '#4b4b4b',
                  boxShadow: 'none',
                  border: 'none',
                }}
                loading={isLoadingDownloadImageFromInspector}
              >
                <VerticalAlignBottomOutlined />
                Baixar arquivo
              </Button>
            </div>
          </StyledDrawer>
        </TableBody>
      </>
    )
  }

  const DocumentTableMobile = () => {
    return (
      <>
        {data?.map((document, index) => (
          <StyledLineContainer key={index}>
            {document.status === 'PENDING' && isExternal ? (
              <StyledLine isExternal={isExternal}>
                <StyledIsExternalType>
                  <span>{document.type ?? document.fileName}</span>
                </StyledIsExternalType>
                <StyledLineActions>
                  {onAttachmentClick && !disabled && (
                    <Upload
                      type="icon"
                      showFileUploadList={false}
                      icon={<StyledPaperClipOutlined />}
                      onFileUpload={(file) => onAttachmentClick(document, file[0])}
                      accept="image/png, image/jpg, .pdf"
                      beforeUpload={(file) => validateFileSize(file)}
                    />
                  )}
                </StyledLineActions>
              </StyledLine>
            ) : (
              <StyledLine>
                <StyledLinePending>
                  <StyledLineType>
                    <span>{document.type ?? document.fileName}</span>
                    {document.status === 'PENDING' && (
                      <StyledLineButton>Arquivo solicitado</StyledLineButton>
                    )}
                  </StyledLineType>
                </StyledLinePending>
                <StyledLineActions>
                  {document.status === 'PENDING' ? (
                    <>
                      {onAttachmentClick && document.status && !disabled && (
                        <Upload
                          type="icon"
                          showFileUploadList={false}
                          icon={<PaperClipOutlined />}
                          onFileUpload={(file) => onAttachmentClick(document, file[0])}
                          accept="image/png, image/jpg, .pdf"
                          beforeUpload={(file) => validateFileSize(file)}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {document.isLoadingDownloadDocument ? (
                        <Spinner height="25px" width="25px" />
                      ) : (
                        onDownloadClick &&
                        document.status && (
                          <StyledDownloadOutlined onClick={() => onDownloadClick(document)} />
                        )
                      )}

                      {document.isLoadingDeleteDocument ? (
                        <Spinner height="25px" width="25px" />
                      ) : (
                        onDeleteClick &&
                        document?.status === 'UPLOADED' &&
                        !disabled && (
                          <StyledDeleteOutlined onClick={() => onDeleteClick(document.id)} />
                        )
                      )}
                    </>
                  )}
                </StyledLineActions>
              </StyledLine>
            )}
          </StyledLineContainer>
        ))}
      </>
    )
  }

  return <StyledTable>{isMobile ? <DocumentTableMobile /> : <DocumentTableDesktop />}</StyledTable>
}

export default DocumentTable
