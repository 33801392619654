import styled from 'styled-components'

export const EffectiveDateFilterContent = styled.div`
  text-align: center;
`

export const LastDaysFilter = styled.div``

export const LastDaysTitle = styled.h1`
  font-size: 14px;
  font-weight: 400;
`

export const PeriodFilter = styled.div`
  justify-content: center;

  display: flex;
  gap: 1rem;
`
export const PeriodFilterTitle = styled.h3`
  font-weight: 400;
`

export const InitialDateFilter = styled.div``

export const PeriodFilterLabel = styled.label``

export const FinalDateFilter = styled.div``

export const FilterButton = styled.button<{ isActive: boolean }>`
  background: ${(props) => (props.isActive ? '#514984' : 'transparent')};
  border: 2px solid #b8b8b8;
  padding: 5px 20px 5px 20px;
  border-radius: 30px;
  margin-right: 5px;
  color: ${(props) => (props.isActive ? '#ffffff' : '#b8b8b8')};
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
`

export const Button = styled.button`
  display: block;
  margin: 0 auto;
  padding: 10px;
  border: none;
  border-radius: 30px;
  width: 50%;
  margin-top: 20px;
  background: #514984;
  color: #ffffff;
  font-weight: bold;
  font-family: 'Manrope';

  &:hover {
    cursor: pointer;
  }
`
