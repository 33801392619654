import { Radio } from 'antd'
import styled from 'styled-components'

export interface StyledRadioProps {
  radioColor?: string
  fontColor?: string
  disabled?: boolean
}

export const StyledRadioColor = styled(Radio)<StyledRadioProps>`
  &&& {
    .ant-radio-checked .ant-radio-inner {
      background-color: white !important;
      border-color: #b7c8d5 !important;
    }
    .ant-radio-checked .ant-radio-inner::after {
      background-color: ${(props) => props.radioColor ?? '#514984'};
      width: 22px;
      height: 22px;
      margin: -11px;
    }

    .ant-radio:hover .ant-radio-inner {
      border-color: ${(props) => props.radioColor ?? '#514984'};
    }

    .ant-radio-inner {
      border-color: #b7c8d5;
    }
    color: ${(props) => props.fontColor ?? '#878DA1'};
    font-size: 14px;
    font-weight: 600;
  }
`

export const StyledLabel = styled.label<{ labelColor?: string }>`
  color: ${(props) => props.labelColor ?? '#878DA1'};
  font-size: 14px;
  font-weight: 400;
  margin-right: 7px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
`

export const StyledSpan = styled.span<StyledRadioProps>`
  top: 6px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid #b7c8d5;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  left: -8px;

  &::after {
    content: '';
    width: 12px;
    height: 12px;
    background: ${(props) => (props.disabled ? '#878DA1' : 'rgb(103 96 148)')};
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s;
  }
`

export const StyledInput = styled.input`
  visibility: hidden;
  margin: 0;

  &:checked + span {
    border: 1px solid #b7c8d5;
  }

  &:checked + span:after {
    opacity: 1;
  }
`
