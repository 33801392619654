import { holderAmilHealthAndDentalSchema } from './amil'
import { holderBradescoHealthAndDentalSchema } from './bradesco'
import { holderDefaultHealthAndDentalSchema } from './default'
import { holderGndiHealthAndDentalSchema } from './gndi'
import { holderPortoSeguroHealthAndDentalSchema } from './portoSeguro'
import { holderSulamericaHealthAndDentalSchema } from './sulamerica'

export const holderHealthAndDentalSchemas = {
  amil: holderAmilHealthAndDentalSchema,
  gndi: holderGndiHealthAndDentalSchema,
  portoSeguro: holderPortoSeguroHealthAndDentalSchema,
  sulamerica: holderSulamericaHealthAndDentalSchema,
  bradesco: holderBradescoHealthAndDentalSchema,
  default: holderDefaultHealthAndDentalSchema,
}
