import React, { CSSProperties, ReactNode } from 'react'
import { ModalProps } from 'antd'
import { StyledModal } from './style'

export interface Props extends ModalProps {
  children: ReactNode
  title?: string
  width?: number
  bodyStyle?: CSSProperties
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  footer?: React.ReactNode
  footerAlign?: 'start' | 'center' | 'end'
  padding?: string
  shouldHideCloseIcon?: boolean
  customHeight?: string
  disableMarginTop?: true
}

const Modal: React.FC<Props> = ({
  title,
  children,
  width,
  bodyStyle,
  isOpen,
  setIsOpen,
  footer,
  footerAlign,
  padding,
  shouldHideCloseIcon,
  customHeight,
  disableMarginTop,
}) => {
  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <>
      <StyledModal
        shouldHideCloseIcon={shouldHideCloseIcon}
        title={title}
        bodyStyle={bodyStyle}
        open={isOpen}
        onCancel={closeModal}
        footer={footer}
        width={width}
        centered
        footerAlign={footerAlign}
        padding={padding}
        customHeight={customHeight}
        disableMarginTop={disableMarginTop}
      >
        {children}
      </StyledModal>
    </>
  )
}

export default Modal
