import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { CheckOutlined, EditOutlined, WarningFilled } from '@ant-design/icons'
import { colors } from '@design-system/styles/theme'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {
  Button,
  Counter,
  DatePicker,
  Modal,
  Select,
  Skeleton,
  TextInput,
} from 'design-system/components'
import Exclamation from 'design-system/exclamation'
import ExternalLink from 'design-system/externalLink'

import { getCatalogAdministratorOptions } from '@/App/clients/catalogApi/queries/catalogAdministrator'
import { getCatalogInsurerOptions } from '@/App/clients/catalogApi/queries/catalogInsurers'
import {
  FillQuotationInformationBody,
  GetOrderInformationBody,
} from '@/App/clients/healthPlansOrders/dtos'
import { useFillQuotationInformation } from '@/App/clients/healthPlansOrders/mutations/fillQuotaionInformation'
import { useOrderInformationQuery } from '@/App/clients/healthPlansOrders/queries/orderInformation'
import { MIN_LIFE_QUANTITY_ALLOWED } from '@/App/utils/constants'
import { clearInputMask } from '@/App/utils/string'

import { BradescoFormQuotation } from './bradesco/bradescoEditForm'
import { BradescoViewForm } from './bradesco/bradescoViewForm'
import { GndiFormQuotation } from './gndi/gndiEditForm'
import { GndiViewForm } from './gndi/gndiViewForm'
import { PortoFormQuotation } from './porto/portoEditForm'
import { PortoViewForm } from './porto/portoViewForm'
import {
  AttachmentDocumentDiv,
  EditQuotationButton,
  ErrorStyled,
  FormItem,
  FormItemGroup,
  FormItemLabel,
  HorizontalLine,
  ModalButtonText,
  ModalConfirmButtonDiv,
  ModalContent,
  ModalTextDiv,
  ModalTitle,
  QuotationDiv,
  QuotationHeader,
  QuotationHeaderSubtitle,
  QuotationHeaderTitle,
  QuotationInformationDiv,
  SaveQuotationButtonDiv,
} from './style'
import { SulamericaFormQuotation } from './sulamerica/sulamericaEditForm'
import { SulamericaViewForm } from './sulamerica/sulamericaViewForm'

dayjs.extend(customParseFormat)

interface QuotationProps {
  validateQuotationDispatch?: boolean
  validateOrderDispatch?: boolean
}

export const OrderProductLocale = {
  HEALTH: 'Saúde',
  DENTAL: 'Odonto',
  HEALTH_AND_DENTAL: 'Saúde e Odonto',
  PET: 'Pet',
} as const

export const OrderTypeLocale = {
  PERSON: 'PF',
  COMPANY: 'PME',
  GROUP: 'Adesão',
} as const

export const OrderSubtypeLocale = {
  OPTIONAL: 'Livre adesão',
  COMPULSORY: 'Compulsório',
} as const

export const Quotation: React.FC<QuotationProps> = ({
  validateQuotationDispatch,
  validateOrderDispatch,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [lifesQuantity, setLifesQuantity] = useState<number | null>(null)
  const [isFillInformationModalVisible, setIsFillInformationModalVisible] = useState(false)
  const [updatedOrderData, setUpdatedOrderData] = useState<GetOrderInformationBody>()

  const { orderId } = useParams<{ orderId: string }>()

  const orderDataQuery = useOrderInformationQuery({ orderId })

  let orderData = orderDataQuery?.data

  const {
    handleSubmit,
    control,
    register,
    reset,
    watch,
    setValue: formSetValue,
  } = useForm<FillQuotationInformationBody>()

  const formValues = watch()

  const getInsurerQuery = useQuery(
    getCatalogInsurerOptions({
      modalityId: orderData?.quotation?.typeId,
      enabled: !!isEditing && orderData?.quotation?.administrator == null,
    }),
  )

  const getAdministratorQuery = useQuery(
    getCatalogAdministratorOptions({
      modalityId: orderData?.quotation?.typeId,
      enabled: !!isEditing && !!orderData?.quotation?.administrator,
    }),
  )

  const getInsurerWithAdministratorQuery = useQuery(
    getCatalogInsurerOptions({
      administratorId: formValues.administratorId,
      modalityId: orderData?.quotation?.typeId,
      enabled: !!isEditing && !!formValues.administratorId,
    }),
  )
  const modalityIsGroup = orderData?.quotation?.type === 'GROUP'

  const insurerQueries = modalityIsGroup ? getInsurerWithAdministratorQuery : getInsurerQuery
  const fillQuotationInformationRequest = useFillQuotationInformation()

  const insurerPorto = insurerQueries.data?.find((insurer) =>
    insurer.label.includes('Porto Seguro'),
  )?.value
  const insurerNotreDame = insurerQueries.data?.find((insurer) =>
    insurer.label.includes('Notre Dame Intermédica'),
  )?.value
  const insurerGndi = insurerQueries.data?.find((insurer) => insurer.label.includes('GNDI'))?.value
  const insurerSulamerica = insurerQueries.data?.find((insurer) =>
    insurer.label.includes('Sulamérica'),
  )?.value
  const insurerBradesco = insurerQueries.data?.find((insurer) =>
    insurer.label.includes('Bradesco Seguros'),
  )?.value

  const handleChangeAdministratorId = (administratorId?: string | null) => {
    formSetValue(
      'administrator',
      getAdministratorQuery.data?.find((administrator) => administrator.value === administratorId)
        ?.label,
    )
    if (
      formValues.administratorId !== orderData?.quotation?.administratorId &&
      formValues !== undefined
    ) {
      formSetValue('insurerId', undefined)
    }
  }

  const handleChangeInsurer = (insurerId?: string | null) => {
    formSetValue(
      'insurer',
      modalityIsGroup
        ? getInsurerWithAdministratorQuery?.data?.find((insurer) => insurer.value === insurerId)
            ?.label
        : getInsurerQuery?.data?.find((insurer) => insurer.value === insurerId)?.label,
    )
  }

  useEffect(() => {
    if (orderData != null) {
      setUpdatedOrderData(orderData)
      reset({
        ...orderData?.quotation,
        totalAmount: orderData?.quotation?.totalAmount?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
      })
      setLifesQuantity(orderData?.lifesQuantity ?? null)
    }
  }, [orderData])

  useEffect(() => {
    if (fillQuotationInformationRequest.isSuccess) {
      setIsFillInformationModalVisible(false)
      setIsEditing(false)
      orderData = updatedOrderData
      orderDataQuery.refetch()
    }
  }, [fillQuotationInformationRequest.isSuccess])

  useEffect(() => {
    handleChangeAdministratorId(formValues.administratorId)
  }, [formValues.administratorId])

  useEffect(() => {
    handleChangeInsurer(formValues.insurerId)
  }, [formValues.insurerId])

  const fillQuotationInformation = (quotationData: FillQuotationInformationBody) => {
    const parsedData = {
      ...quotationData,
      lifesQuantity,
      totalAmount:
        quotationData.totalAmount != null
          ? +clearInputMask(quotationData.totalAmount.toString(), 'money')
          : null,
      isCoparticipation: formValues?.isCoparticipation?.toString() === 'true',
    }

    setUpdatedOrderData({ ...orderData, ...parsedData })

    fillQuotationInformationRequest.mutateAsync({
      brokerId: orderData?.broker?.id,
      quotationId: orderData?.quotation?.id,
      leadId: orderData?.quotation?.lead?.id,
      data: { ...parsedData },
    })
  }

  const handleLifesQuantityChange = (value: number) => {
    setLifesQuantity(value)
    formSetValue('lifesQuantity', value)
  }

  const editQuoatation = () => {
    setIsEditing(true)
    formSetValue('insurerId', orderData?.quotation?.insurerId)
    formSetValue(
      'isCoparticipation',
      orderData?.quotation?.isCoparticipation?.toString() as unknown as boolean,
    )
  }

  const renderFormComponents = () => {
    if (formValues?.insurerId === insurerSulamerica) {
      return <SulamericaFormQuotation control={control} register={register} orderId={orderId} />
    }
    if (formValues?.insurerId === insurerPorto) {
      return <PortoFormQuotation register={register} />
    }
    if (formValues?.insurerId === insurerBradesco) {
      return <BradescoFormQuotation register={register} />
    }
    if (formValues?.insurerId === insurerGndi || formValues?.insurerId === insurerNotreDame) {
      return <GndiFormQuotation control={control} />
    }
    return null
  }

  return (
    <>
      <QuotationDiv>
        {fillQuotationInformationRequest.isPending ? (
          <>
            <Skeleton
              type="default"
              shape="square"
              active={true}
              loading={true}
              title={{ width: '100%' }}
            />
            <Skeleton
              type="default"
              shape="square"
              active={true}
              loading={true}
              title={{ width: '100%' }}
            />
            <Skeleton
              type="default"
              shape="square"
              active={true}
              loading={true}
              title={{ width: '100%' }}
            />
          </>
        ) : (
          <>
            <QuotationHeader>
              <QuotationHeaderTitle> Cotação </QuotationHeaderTitle>
              {isEditing ? (
                <SaveQuotationButtonDiv>
                  <Button
                    onClick={() => setIsFillInformationModalVisible(true)}
                    height="26px"
                    backgroundColor={colors.darkPurple}
                  >
                    <CheckOutlined />
                    <p>Salvar edição</p>
                  </Button>
                </SaveQuotationButtonDiv>
              ) : (
                <EditQuotationButton onClick={editQuoatation}>
                  <EditOutlined />
                  <p>Editar</p>
                </EditQuotationButton>
              )}
            </QuotationHeader>

            <QuotationHeaderSubtitle>
              Proposta Criada pela Bliss em {dayjs(orderData?.createdAt).format('DD/MM/YYYY')}
              <br />
              {orderData?.quotation?.updatedAt
                ? `Última edição em ${dayjs(orderData?.quotation?.updatedAt).format('DD/MM/YYYY')}`
                : ''}
            </QuotationHeaderSubtitle>

            <HorizontalLine />

            {isEditing ? (
              <QuotationInformationDiv>
                <form id="editQuotation" onSubmit={handleSubmit(fillQuotationInformation)}>
                  <FormItem>
                    <QuotationInformationDiv>
                      <li>Produto*</li>
                      <p>{OrderProductLocale[orderData?.quotation?.product ?? ''] ?? '-'}</p>

                      <li>Modalidade*</li>
                      <p>
                        {orderData?.quotation?.type
                          ? `${OrderTypeLocale[orderData?.quotation?.type]} ${
                              orderData?.quotation?.subtype
                                ? `- ${OrderSubtypeLocale[orderData?.quotation?.subtype]}`
                                : ''
                            }`
                          : '-'}
                      </p>
                    </QuotationInformationDiv>
                  </FormItem>
                  <FormItemGroup isVisible={modalityIsGroup}>
                    <FormItemLabel> Administradora*</FormItemLabel>
                    {getAdministratorQuery.isPending ? (
                      <Skeleton type="input" />
                    ) : (
                      <Select
                        control={control}
                        name="administratorId"
                        placeholder="Selecione a administradora"
                        options={getAdministratorQuery.data ?? []}
                      />
                    )}
                  </FormItemGroup>

                  <FormItem>
                    <FormItemLabel>Operadora*</FormItemLabel>
                    {getInsurerWithAdministratorQuery.isPending && getInsurerQuery.isPending ? (
                      <Skeleton type="input" />
                    ) : (
                      <Select
                        placeholder="Selecione a operadora"
                        control={control}
                        showSearch={true}
                        name="insurerId"
                        options={
                          (modalityIsGroup
                            ? getInsurerWithAdministratorQuery.data
                            : getInsurerQuery.data) ?? []
                        }
                      />
                    )}
                  </FormItem>

                  <FormItem>
                    <FormItemLabel>
                      Data de vigência
                      {(formValues.insurerId === insurerPorto ||
                        formValues.insurerId === insurerGndi) &&
                        '*'}
                    </FormItemLabel>
                    <DatePicker name="effectiveDate" control={control} placeholder="00/00/0000" />
                  </FormItem>

                  <FormItem>
                    <TextInput
                      name="externalURL"
                      label="Link da cotação:"
                      placeholder="http://"
                      vertical
                      register={{
                        ...register('externalURL'),
                      }}
                    />
                  </FormItem>

                  {orderData && renderFormComponents()}

                  <FormItem>
                    <TextInput
                      name="totalAmount"
                      label="Valor total*"
                      vertical
                      mask="money"
                      register={{
                        ...register('totalAmount', {}),
                      }}
                    />
                  </FormItem>

                  <FormItem>
                    <FormItemLabel>Quantidade de vidas*</FormItemLabel>

                    <Counter
                      hideButton={orderData?.type !== 'COMPANY' ? 'decrement' : 'both'}
                      min={0}
                      max={999}
                      count={lifesQuantity ?? 0}
                      onCountChange={handleLifesQuantityChange}
                    />
                  </FormItem>
                </form>
              </QuotationInformationDiv>
            ) : (
              orderData && (
                <QuotationInformationDiv>
                  <li>Produto*</li>{' '}
                  <p>{OrderProductLocale[orderData?.quotation?.product ?? ''] ?? '-'}</p>
                  <li>Modalidade*</li>
                  <p>
                    {orderData?.quotation?.type
                      ? `${OrderTypeLocale[orderData?.quotation?.type]} ${
                          orderData?.quotation?.subtype
                            ? `- ${OrderSubtypeLocale[orderData?.quotation?.subtype]}`
                            : ''
                        }`
                      : '-'}
                  </p>
                  {modalityIsGroup && (
                    <>
                      <li>Administradora:</li> <p>{orderData?.quotation?.administrator ?? '-'}</p>
                    </>
                  )}
                  <li>Operadora*</li> <p>{orderData?.quotation?.insurer ?? '-'}</p>
                  <li>
                    Data de vigência
                    {(orderData?.quotation?.insurer === 'Porto Seguro' ||
                      orderData?.quotation?.insurer === 'Notre Dame Intermédica' ||
                      orderData?.quotation?.insurer === 'GNDI') &&
                      '*'}
                  </li>
                  <p>
                    {orderData?.quotation?.effectiveDate ? (
                      dayjs(orderData?.quotation?.effectiveDate).format('DD/MM/YYYY')
                    ) : validateQuotationDispatch &&
                      orderData?.quotation?.insurer === 'Porto Seguro' ? (
                      <ErrorStyled>
                        <WarningFilled style={{ color: colors.red }} />
                        Dado obrigatório
                      </ErrorStyled>
                    ) : (
                      '-'
                    )}
                  </p>
                  <li>Link da cotação</li>
                  <p>
                    {orderData?.quotation?.externalURL ? (
                      <AttachmentDocumentDiv>
                        <span>{orderData?.quotation?.externalURL} </span>
                        <a
                          href={orderData?.quotation?.externalURL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ExternalLink />
                        </a>
                      </AttachmentDocumentDiv>
                    ) : (
                      '-'
                    )}
                  </p>
                  <li>Valor Total*</li>
                  <p>
                    {orderData?.quotation?.totalAmount ? (
                      orderData?.quotation?.totalAmount.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                    ) : validateOrderDispatch && orderData?.status === 'FILLED' ? (
                      <ErrorStyled>
                        <WarningFilled style={{ color: colors.red }} />
                        Dado obrigatório
                      </ErrorStyled>
                    ) : (
                      '-'
                    )}
                  </p>
                  <li>Quantidade de vidas*</li> <p>{orderData?.lifesQuantity ?? '-'}</p>
                  {validateQuotationDispatch && (
                    <ErrorStyled>
                      <WarningFilled style={{ color: colors.red }} />
                      `Número de vidas deve ser no mínimo ${MIN_LIFE_QUANTITY_ALLOWED}`
                    </ErrorStyled>
                  )}
                  {orderData?.quotation?.insurer === 'Sulamérica' && (
                    <SulamericaViewForm
                      orderData={orderData}
                      validateQuotationDispatch={validateQuotationDispatch}
                      validateOrderDispatch={validateOrderDispatch}
                    />
                  )}
                  {orderData?.quotation?.insurer === 'Porto Seguro' && (
                    <PortoViewForm
                      orderData={orderData}
                      validateOrderDispatch={validateOrderDispatch}
                    />
                  )}
                  {orderData?.quotation?.insurer === 'Bradesco Seguros' && (
                    <BradescoViewForm
                      orderData={orderData}
                      validateOrderDispatch={validateOrderDispatch}
                    />
                  )}
                  {(orderData?.quotation?.insurer === 'Notre Dame Intermédica' ||
                    orderData?.quotation?.insurer === 'GNDI') && (
                    <GndiViewForm
                      orderData={orderData}
                      validateOrderDispatch={validateOrderDispatch}
                    />
                  )}
                  <li>Proposta emitida pelo corretor</li>
                  <p>{orderData?.isRegisteredInInsurer ? 'Sim' : 'Não'}</p>
                  <li>Proposta conjugada</li>
                  <p>{orderData?.quotation?.isCombinedProposal ? 'Sim' : 'Não'}</p>
                </QuotationInformationDiv>
              )
            )}
          </>
        )}
      </QuotationDiv>

      <Modal
        width={677}
        bodyStyle={{ height: '250px' }}
        isOpen={isFillInformationModalVisible}
        setIsOpen={setIsFillInformationModalVisible}
        footer={
          <ModalButtonText
            onClick={() => setIsFillInformationModalVisible(!isFillInformationModalVisible)}
          >
            <p>Cancelar</p>
          </ModalButtonText>
        }
      >
        <ModalContent>
          <Exclamation />
          <ModalTitle>Tem certeza que deseja editar as informações da cotação?</ModalTitle>
          <ModalTextDiv>
            <p>
              Editando as informações da cotação, as mudanças serão refletidas também para o
              corretor.
            </p>
            <p>Certifique-se de que as informações estão corretas.</p>
          </ModalTextDiv>
          <ModalConfirmButtonDiv>
            <Button
              formId="editQuotation"
              htmlType="submit"
              backgroundColor="#53154E"
              height="45px"
              loading={fillQuotationInformationRequest.isPending}
            >
              Editar cotação
            </Button>
          </ModalConfirmButtonDiv>
        </ModalContent>
      </Modal>
    </>
  )
}
