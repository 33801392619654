import dayjs from 'dayjs'
import { Tabs } from 'design-system/components'

import { OrderFillingExecutionDto } from '@/App/clients/orderInformationProcessing/dtos'

import {
  ExecutionDetails,
  ExecutionDetailsItemTitle,
  ExecutionDetailsItemValue,
  ExecutionDetailsRow,
  ExpandableRowDiv,
} from './style'

interface IContentProps {
  record: OrderFillingExecutionDto
}

enum ErrorReasonLocale {
  'EMPTY_FILE' = 'Arquivo vazio',
  'EMPTY_ORDER_STATUS' = 'Proposta com status em branco encontrada',
  'FILE_NOT_AVAILABLE' = 'Arquivo não disponível no Drive',
}

export const LastExecutionsExpandableRowContent: React.FC<IContentProps> = ({ record }) => {
  const tabsItems = [
    {
      key: '0',
      label: 'Informações',
      children: (
        <ExecutionDetails>
          <ExecutionDetailsRow>
            <ExecutionDetailsItemTitle>Nome do arquivo:</ExecutionDetailsItemTitle>
            <ExecutionDetailsItemValue>{record.fileName}</ExecutionDetailsItemValue>
          </ExecutionDetailsRow>

          <ExecutionDetailsRow>
            <ExecutionDetailsItemTitle>Início da execução:</ExecutionDetailsItemTitle>
            <ExecutionDetailsItemValue>
              {dayjs(record.startedAt).format('DD/MM/YYYY HH:mm:ss')}
            </ExecutionDetailsItemValue>
          </ExecutionDetailsRow>

          {record.finishedAt && (
            <ExecutionDetailsRow>
              <ExecutionDetailsItemTitle>Final da execução:</ExecutionDetailsItemTitle>
              <ExecutionDetailsItemValue>
                {dayjs(record.finishedAt).format('DD/MM/YYYY HH:mm:ss')}
              </ExecutionDetailsItemValue>
            </ExecutionDetailsRow>
          )}

          {record.errorReason && (
            <ExecutionDetailsRow>
              <ExecutionDetailsItemTitle>Motivo do erro:</ExecutionDetailsItemTitle>
              <ExecutionDetailsItemValue>
                {ErrorReasonLocale[record.errorReason]}
              </ExecutionDetailsItemValue>
            </ExecutionDetailsRow>
          )}
        </ExecutionDetails>
      ),
    },
    {
      key: '1',
      label: 'Propostas atualizadas',
      children: record.updatedOrdersIds.length ? (
        <ul>
          {record.updatedOrdersIds.map((id) => {
            return (
              <li key={id}>
                <p>
                  <a
                    target="_blank"
                    href={`${import.meta.env.VITE_INTRANET_URL}/propostas/${id}`}
                    rel="noreferrer"
                  >
                    {id}
                  </a>
                </p>
              </li>
            )
          })}
        </ul>
      ) : (
        <p>Nenhuma proposta foi atualizada.</p>
      ),
    },
  ]
  return (
    <ExpandableRowDiv>
      <Tabs items={tabsItems} itemsColor="black" />
    </ExpandableRowDiv>
  )
}
