import { ReactNode, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  ClockCircleOutlined,
  CloseOutlined,
  FilterOutlined,
  PlusOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { Button, Filter, SearchInput } from 'design-system/components'

import { Action, IFilters } from '../index'
import {
  FilterDiv,
  FiltersContent,
  NewProposalButtonContainer,
  SelectedFilterSpan,
  SelectedFiltersContent,
  StyledButton,
} from './style'

interface IFiltersProps {
  selectedFilters: IFilters
  items: Array<{
    key: string
    label: string
    children: ReactNode
  }>
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>
  handleRequest: (action: Action, filterValues: [{ key: string; value: string | string[] }]) => void
  statusFilters: boolean
  baseStatus: string[]
}

const buttons = [
  {
    id: 'em-cadastro',
    icon: <ClockCircleOutlined />,
    text: 'Em cadastro',
    value: ['FILLING_IN_PROGRESS', 'OPPORTUNITY_FILLED', 'FILLED', 'INSURER_PROCESSING'],
  },
  {
    id: 'com-pendencia',
    icon: <WarningOutlined />,
    text: 'Com pendência',
    value: [
      'CUSTOMER_ACCEPTANCE',
      'READY_TO_DISPATCH',
      'FILLING_NOT_MEET_REQUIREMENTS',
      'EFFECTIVE_DATE_PENDING',
      'WAITING_SIGNATURE',
      'BILL_PAYMENT_PENDING',
      'WAITING_BILL',
      'PENDING_DISPATCH',
    ],
  },
  {
    id: 'em-analise',
    icon: <SearchOutlined />,
    text: 'Em análise',
    value: ['ANALYSIS', 'QUALIFIED_INTERVIEW'],
  },
]

const filterTranslations = {
  status: 'Status',
  effectiveDatePeriod: 'Período',
  initialEffectiveDate: 'Data Inicial',
  endEffectiveDate: 'Data Final',
  brokerId: 'Corretor',
  managerId: 'Supervisor',
  isRegisteredInInternalSystem: 'Registrado no SisWeb',
  responsibleAdminId: 'Responsável pela proposta',
  leadNameOrInsurerId: 'Lead',
  insurer: 'Operadora',
  internalSystemId: 'Código Sisweb',
}

export const Filters = ({
  items,
  selectedFilters,
  setFilters,
  handleRequest,
  statusFilters,
  baseStatus,
}: IFiltersProps) => {
  const [filterPopOverButton, setFilterPopOverButton] = useState(false)
  const [selectedButton, setSelectedButton] = useState('')
  const [filtersInUse, setFiltersInUse] = useState<Array<string>>([])

  const filterRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  useEffect(() => {
    const emptyFilters = {
      status: [],
      effectiveDatePeriod: '',
      initialEffectiveDate: '',
      endEffectiveDate: '',
      brokerId: [],
      managerId: [],
      responsibleAdminId: [],
      isRegisteredInInternalSystem: [],
      leadNameOrInsurerId: '',
      insurer: [],
    }

    if (JSON.stringify(selectedFilters) === JSON.stringify(emptyFilters)) setFiltersInUse([])
    else handleFiltersChange(selectedFilters)
  }, [selectedFilters])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        !event.target.classList.contains('ant-select-item-option-content') &&
        !event.target.classList.contains('ant-picker-cell-inner') &&
        !event.target.classList.contains('ant-picker-cell') &&
        !event.target.classList.contains('ant-picker-cell-in-view')
      ) {
        setFilterPopOverButton(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  const handleFiltersChange = (filters) => {
    for (const filter in filters) {
      if (baseStatus.length && filter === 'status') continue

      if (filters[filter]?.length > 0) {
        if (filter && !filtersInUse.includes(filter)) {
          setFiltersInUse((prevState) => {
            if (!prevState.includes(filter)) {
              return [...prevState, filter]
            } else {
              return prevState
            }
          })
        }
      }
    }
    return filtersInUse
  }

  const getTranslatedFilterName = (filterName: string) => {
    return filterTranslations[filterName] || filterName
  }

  const handleStatusButtonClick = (buttonId: string, buttonValue: string[]) => {
    if (selectedButton === buttonId) {
      handleResetFilter('status')
    } else {
      const action = selectedButton !== '' ? Action.ReplaceFilter : Action.AddFilter

      setSelectedButton(buttonId)
      handleRequest(action, [{ key: 'status', value: buttonValue }])
    }
  }

  const handlePopOverFilter = () => {
    setTimeout(() => {
      setFilterPopOverButton(!filterPopOverButton)
    }, 0)
  }

  const handleResetFilter = (filterName: string) => {
    setFilters((prevFilters) => {
      const updatedFilters: IFilters = {
        ...prevFilters,
        [filterName]: [],
      }
      return updatedFilters
    })
    setSelectedButton('')
    setFiltersInUse((prevState) => prevState.filter((filter) => filter !== filterName))
  }

  return (
    <>
      <FiltersContent>
        <StyledButton onClick={handlePopOverFilter} isActive={filterPopOverButton}>
          <FilterOutlined style={{ color: `${filterPopOverButton ? '#FFF' : '#4B4B4B'}` }} />
          Filtrar
        </StyledButton>
        {filterPopOverButton ? (
          <FilterDiv ref={filterRef}>
            <Filter items={items} />
          </FilterDiv>
        ) : null}

        {statusFilters &&
          buttons.map((button) => (
            <StyledButton
              name="status"
              value={button.value}
              key={button.id}
              onClick={() => {
                handleStatusButtonClick(button.id, button.value as string[])
              }}
              isActive={selectedButton === button.id}
            >
              {button.icon} {button.text}
            </StyledButton>
          ))}
        <SearchInput
          placeholder="Busque por cliente ou nº da proposta"
          name="holderName"
          onSearch={(value) =>
            handleRequest(Action.ReplaceFilter, [{ key: 'leadNameOrInsurerId', value }])
          }
        />
        <NewProposalButtonContainer>
          <Button style={{ height: '36px' }} onClick={() => navigate('/cadastrar-proposta')}>
            <PlusOutlined /> Cadastrar proposta
          </Button>
        </NewProposalButtonContainer>
      </FiltersContent>
      {filtersInUse && (
        <SelectedFiltersContent>
          {filtersInUse.map((filter) => (
            <SelectedFilterSpan key={filter} onClick={() => handleResetFilter(filter)}>
              {getTranslatedFilterName(filter)}
              <CloseOutlined style={{ marginLeft: '5px', color: '#878EA6' }} />
            </SelectedFilterSpan>
          ))}
        </SelectedFiltersContent>
      )}
    </>
  )
}
