import React from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import defaultStyle from './style'

export interface Props {
  data: { id: string; display: string }[]
  onChange: (e) => void
  value: string
  mentionBgColor?: string
}

const Mentions: React.FC<Props> = ({ data, onChange, value, mentionBgColor = '#cee4e5' }) => {
  return (
    <MentionsInput value={value} onChange={onChange} style={defaultStyle}>
      <Mention
        trigger="@"
        data={data}
        style={{ backgroundColor: mentionBgColor }}
        displayTransform={(_id, display) => `@${display}`}
        renderSuggestion={(_suggestion, _search, highlightedDisplay) => (
          <span>{highlightedDisplay}</span>
        )}
      />
    </MentionsInput>
  )
}

export default Mentions
