import { Button as AntdButton } from 'antd'
import styled from 'styled-components'

import { colors } from '../../styles/theme'

interface StyledButtonProps {
  backgroundColor?: string
  height?: string
  palette: 'primary' | 'cancel'
}

export const StyledButton = styled(AntdButton)<StyledButtonProps>`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: ${(props) => props.height ?? '50px'};

    background-color: ${(props) => getBackgroundColor(props.palette, props.backgroundColor)};
    color: ${(props) => getFontColor(props.palette)};
    text-decoration: none;

    font-weight: 600;
    font-size: 14px;

    border: ${(props) => (props.palette === 'primary' ? 'none' : `1px solid #B7C8D5`)};
    border-radius: 50px;

    cursor: pointer;

    &:hover {
      transition: background-color 0.2s ease-in-out;
      border: 1px solid ${colors.purple};
      background: ${colors.purple};
      color: ${colors.white};
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    }

    &:disabled {
      background: ${({ disabled }) => (disabled ? '#B8B8B8' : colors.primary)};
    }
  }
`

const getFontColor = (colorPalette: string): string => {
  switch (colorPalette) {
    case 'primary':
      return colors.white
    case 'cancel':
      return '#4B4B4B'
    default:
      return colors.white
  }
}

const getBackgroundColor = (colorPalette: string, backgroundColor?: string) => {
  switch (colorPalette) {
    case 'primary':
      return colors.purple
    case 'cancel':
      return colors.white
    default:
      return backgroundColor
  }
}
