import { memo } from 'react'

import StatusTag from 'design-system/StatusTag/StatusTag'
import Tooltip from 'design-system/Tooltip/Tooltip'
import { Skeleton } from 'design-system/components'

import { useOrderDispatchEvents } from '@/App/clients/healthPlansOrders/queries/orderDispatchEvents'
import { calculateProcessingTime } from '@/App/utils/calculateProcessingTime'

import { StatusStyledWithSubstatus } from './style'

export interface StatusCellProps {
  status: string
  orderId: string
}

function OrderProcessingStatusComponent({ status, orderId }: StatusCellProps) {
  const orderDispatchEventQuery = useOrderDispatchEvents({ orderId, status })

  const hasImplantantion = orderDispatchEventQuery?.data?.filter(
    (item) => item?.type === 'IMPLANTATION',
  )

  const processingTime = hasImplantantion?.map((item) => {
    return calculateProcessingTime(item?.createdAt, status, item?.updatedAt)
  })
  if (status !== 'DISPATCH_IN_PROGRESS') {
    return <StatusTag status={status} />
  }

  return (
    <StatusStyledWithSubstatus>
      <StatusTag status={status} />
      {orderDispatchEventQuery.isLoading ? (
        <Skeleton type="avatar" />
      ) : orderDispatchEventQuery.isSuccess ? (
        hasImplantantion?.map((item) => {
          return (
            <Tooltip
              key="id"
              placement="bottom"
              title={`Status do processamento: ${item?.label} ${processingTime}`}
            >
              {' '}
              {item?.Icon()}
            </Tooltip>
          )
        })
      ) : null}
    </StatusStyledWithSubstatus>
  )
}

export const OrderProcessingStatus = memo(
  OrderProcessingStatusComponent,
  (prevProps, nextProps) => {
    return Object.is(prevProps, nextProps)
  },
)
