/* eslint-disable react/prop-types */
import { useEffect } from 'react'

import { Button, Toast } from 'design-system/components'
import Exclamation from 'design-system/exclamation'

import { GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import { useDispatchSiswebEvent } from '@/App/clients/sisweb/mutation/dispatchSiswebEvent'

import { ButtonDiv, CancelButton, ModalContent, ModalSubTitle, ModalTitle } from './style'

interface SiswebDispatchingProps {
  setIsModalOpen?: (value: boolean) => void
  order: GetOrderInformationBody
  product?: 'HEALTH' | 'DENTAL'
  reloadOrderData: () => void
}

export const SiswebDispatchingModal: React.FC<SiswebDispatchingProps> = ({
  setIsModalOpen,
  order,
  product,
  reloadOrderData,
}) => {
  const sendDispatchOrder = useDispatchSiswebEvent({
    product,
    orderId: order.id,
  })

  useEffect(() => {
    if (sendDispatchOrder.isSuccess) {
      reloadOrderData()
      setIsModalOpen && setIsModalOpen(false)
    }
  }, [sendDispatchOrder.isSuccess])

  return (
    <ModalContent>
      <Exclamation />
      <ModalTitle>Tem certeza que deseja cadastrar no sisweb?</ModalTitle>
      <ModalSubTitle>
        Ao confirmar, a proposta será automaticamente cadastrada no sisweb. Certifique-se de que
        todos os campos obrigatórios estão preenchidos corretamente.
      </ModalSubTitle>

      <ButtonDiv>
        <Button
          loading={sendDispatchOrder.isPending}
          onClick={() => {
            sendDispatchOrder.mutate(order)
          }}
        >
          Solicitar Cadastro Automático
        </Button>
        <CancelButton onClick={() => setIsModalOpen && setIsModalOpen(false)}>
          Cancelar
        </CancelButton>
      </ButtonDiv>
      <Toast />
    </ModalContent>
  )
}
