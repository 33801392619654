import styled from 'styled-components'

interface OrderScoreTagContainerProps {
  backgroundColor: string
}

export const OrderScoreTagContainer = styled.div<OrderScoreTagContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 8px;
  border-radius: 10px;
  background-color: ${(props) => props.backgroundColor};
  max-width: 75px;

  font-size: 12px;
  color: #1e1d1c;
  line-height: 16px;
  font-weight: 600;
  font-family: 'Manrope', sans-serif;
`
