import { useState } from 'react'

import { Button, Select } from 'design-system/components'

import { StatusMappingExecutionDto } from '@/App/clients/orderInformationProcessing/dtos'
import { useMapStatusMutation } from '@/App/clients/orderInformationProcessing/mutations/mapStatus'
import { statusMapperFn } from '@/App/helpers/statusMapper'
import { capitalizeOnlyFirstLetter } from '@/App/utils/string'

import {
  ExecutionDetails,
  ExecutionDetailsItemTitle,
  ExecutionDetailsItemValue,
  ExecutionDetailsRow,
  ExecutionDetailsSelectItemValue,
  ExpandableRowDiv,
  SaveButtonDiv,
  SelectOptionLabel,
} from './style'

interface IContentProps {
  record: StatusMappingExecutionDto
}

export const StatusMappingExpandableRowContent: React.FC<IContentProps> = ({ record }) => {
  const [selectedStatus, setSelectedStatus] = useState<string>()
  const mapStatusMutation = useMapStatusMutation()

  const getOrderStatusOptions = () => {
    const statusMapper = statusMapperFn()

    return Object.entries(statusMapper).map(([key, { label, color, icon }]) => ({
      label: (
        <>
          {icon}
          <SelectOptionLabel>{label}</SelectOptionLabel>
        </>
      ),
      value: key,
      color,
      icon,
    }))
  }

  const handleSelectChange = (value: string) => {
    setSelectedStatus(value)
  }

  return (
    <ExpandableRowDiv>
      <ExecutionDetails>
        <ExecutionDetailsRow>
          <ExecutionDetailsItemTitle>Status na operadora:</ExecutionDetailsItemTitle>
          <ExecutionDetailsItemValue>
            {capitalizeOnlyFirstLetter(record.externalStatus)}
          </ExecutionDetailsItemValue>
        </ExecutionDetailsRow>

        {record.externalSubstatus && (
          <ExecutionDetailsRow>
            <ExecutionDetailsItemTitle>
              {record.externalSubstatusPropertyName} na operadora:
            </ExecutionDetailsItemTitle>
            <ExecutionDetailsItemValue>
              {capitalizeOnlyFirstLetter(record.externalSubstatus)}
            </ExecutionDetailsItemValue>
          </ExecutionDetailsRow>
        )}

        <ExecutionDetailsRow>
          <ExecutionDetailsItemTitle>Status na plataforma:</ExecutionDetailsItemTitle>
          <ExecutionDetailsSelectItemValue>
            <Select
              value={selectedStatus}
              options={getOrderStatusOptions()}
              defaultValue={{ label: 'Selecione um status' } as unknown as string}
              onChange={(value) => {
                if (typeof value === 'string') {
                  handleSelectChange(value)
                }
              }}
            />
          </ExecutionDetailsSelectItemValue>
        </ExecutionDetailsRow>
      </ExecutionDetails>
      <SaveButtonDiv>
        <Button
          loading={mapStatusMutation.isPending}
          disabled={!selectedStatus}
          height={'40px'}
          onClick={() => {
            mapStatusMutation.mutate({
              internalStatus: selectedStatus,
              statusMappingExecution: record,
            })
          }}
        >
          Salvar
        </Button>
      </SaveButtonDiv>
    </ExpandableRowDiv>
  )
}
