import { ReactNode, useState } from 'react'

import { Select } from 'design-system/components'

import { ChangeLogs } from './ChangeLogs'
import { Notes } from './Notes'
import { SelectContainer, SelectLabel, TabPaneContainer } from './style'

interface IActivityTapProps {
  planCard?: ReactNode
  orderId?: string | null
}

export const ActivityTab: React.FC<IActivityTapProps> = ({ planCard, orderId }) => {
  const [showActivity, setShowActivity] = useState('notes')

  return (
    <TabPaneContainer>
      <SelectContainer>
        <SelectLabel>Mostrar: </SelectLabel>
        <Select
          defaultValue="notes"
          options={[
            { label: 'Histórico', value: 'history' },
            { label: 'Anotações', value: 'notes' },
          ]}
          onChange={(value) => {
            if (!Array.isArray(value)) {
              setShowActivity(value)
            }
          }}
        />
      </SelectContainer>
      {showActivity === 'history' ? (
        <ChangeLogs planCard={planCard} orderId={orderId} />
      ) : (
        <Notes planCard={planCard} orderId={orderId} />
      )}
    </TabPaneContainer>
  )
}
