import styled from 'styled-components'

export const ContainerBoard = styled.div`
  column-gap: 12px;
  display: flex;
  height: 100vh;
  padding-bottom: 10px;
  justify-content: center;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`
