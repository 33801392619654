import styled from 'styled-components'
import { colors } from '../../styles/theme'

export interface StyledMenuProps {
  color?: string
}

export const BackgroundMobile = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #414141;
`

export const StyledMenuMobile = styled.div<StyledMenuProps>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 90vw;
  background-color: #fff;
  transition: 0.5s;
  position: absolute;
  z-index: 10;
  ul li {
    list-style: none;
  }
  ul li a {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    text-decoration: none;

    span {
      color: #8589a2;
      font-size: 20px;
    }
  }
  ul {
    padding-left: 15px;
  }
`
export const LabelMobile = styled.p`
  padding-left: 10px;
  font-size: 16px;
  color: #4b4b4b;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const CloseMenuMobile = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  width: 100px;
  height: 40px;
  padding-top: 30px;
  padding-left: 18px;
  p {
    text-decoration: underline;
    padding-left: 15px;
  }
  span {
    color: #9399ab;
  }
`
export const ByBliss = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  color: #8b8b88;
  position: absolute;
  bottom: 0;
  width: 82vw;
`
export const BodyByBliss = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  span {
    padding: 5px;
    font-size: 12px;
  }

  img {
    width: 60px;
    height: 28px;
  }
`

export const StyledFooterMobile = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const LogoutMenuMobile = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  width: 100px;
  height: 40px;
  padding-top: 10px;
  padding-left: 15px;
  span {
    padding-left: 15px;
    color: #9399ab;
  }
`

export const Line = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 99%;
  border: 1px solid #dcdfeb;
`

export const StyledMenu = styled.div<StyledMenuProps>`
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  overflow-x: hidden;
  white-space: nowrap;
  padding-top: 20px;
  z-index: 10;

  width: 100px;
  height: calc(100vh - 20px);

  transition: 0.5s;

  background-color: #ffffff;
  box-shadow: 0px 4px 14px 0px #373b6226;

  .active {
    background-color: #f6f6f9;
    border-radius: 20px;
    .anticon {
      color: ${(props) => props.color ?? colors.purple};
    }
  }

  ul > li > a {
    justify-content: center;

    span:nth-child(2) {
      display: none;
    }
  }

  &:hover {
    ul > li > a {
      justify-content: flex-start;

      span:nth-child(2) {
        display: block;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0px;
  }

  ul {
    padding: 0px;
    margin: 0px;
  }

  ul li {
    list-style: none;
    padding: 10px;
    margin-bottom: 10px;
    white-space: normal;
  }

  ul li:hover {
    background-color: #f6f6f9;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.4s;
    justify-content: auto;
    align-items: auto;
    a {
      color: ${(props) => props.color ?? colors.purple};
      span {
        white-space: normal;
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #8b8b88;
    padding: 10px;
  }

  .anticon {
    row-gap: 10px;
    font-size: 18px;
  }

  :hover {
    width: 250px;
    transition: 0.4s;
    span {
      margin-left: 12px;
      transition: 0.4s;
      opacity: 1;
    }
    a {
      justify-content: flex-start !important;
    }
  }
`

export const LogoImageDiv = styled.div`
  width: 62px;
`

export const HeaderMenu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`
export const Footer = styled.div`
  display: flex;
  padding: 20px;
  background-color: #ffff;
  bottom: 0;
  position: absolute;
  transition: 0.4s;
  font-size: 14px;
  color: #8b8b88;
  span {
    margin-left: -106px;
    padding: 5px;
    opacity: 0;
    font-size: 12px;
  }

  img {
    width: 60px;
    height: 28px;
  }
`

export const Label = styled.span`
  font-size: 16px;
  color: #8b8b88;
  margin-left: 50px;
`

export const StyledImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
`
export const StyledLogo = styled.div<StyledMenuProps>`
  align-items: center;
  color: ${(props) => props.color ?? colors.darkPurple};
  display: flex;
  font-size: 32px;
  font-weight: 700;
  height: 40px;
  justify-content: center;
  width: 50px;
`
export const StyledDivLogo = styled.div<StyledMenuProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-left: 10px;
  span {
    color: ${(props) => props.color ?? colors.darkPurple};
    font-size: 10px;
    font-weight: 600;
  }
`
