import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { Address, Entity } from '../../healthPlansOrders/dtos'
import { api } from '../../http'
import { getLatestPersonDispatchEventOptions } from '../queries/getLatestPersonDispatchEvent'

type DispatchPersonEventDependentRelatedHolder = {
  cpf?: string | null
  address?: Address | null
}
type DispatchPersonEventEntity = Entity & {
  relatedHolder?: DispatchPersonEventDependentRelatedHolder | null
}

type SendDispatchPersonEventProps = {
  entities?: DispatchPersonEventEntity[]
  quotation?: {
    type?: string | null
    isCombinedProposal?: boolean | null
  }
  company?: {
    document?: string | null
  }
  insurer?: string | null
  type: 'INCLUSION' | 'EXCLUSION'
  entityType: string
  metadata?: Record<string, unknown> | null
  isDispatchedManually?: boolean
}

const sendDispatchPersonEvent = async ({
  entities,
  insurer,
  type,
  entityType,
  quotation,
  isDispatchedManually = false,
  metadata,
  company,
}: SendDispatchPersonEventProps) => {
  const formattedEntities =
    entities?.map((entity) => ({
      ...entity,
      type: entity.type ?? entityType,
    })) || []

  await api.post(`${import.meta.env.VITE_BLISS_ORDER_GATEWAY_API_URL}/persons/dispatch-events`, {
    entities: formattedEntities,
    quotation,
    insurer,
    type,
    metadata,
    company,
    origin: isDispatchedManually ? 'INTRANET' : 'AUTOMATION',
  })
}

type DispatchPersonEventHookProps = {
  entityId?: string | null
}

export const useDispatchPersonEvent = ({ entityId }: DispatchPersonEventHookProps) => {
  const queryClient = useQueryClient()
  const { queryKey } = getLatestPersonDispatchEventOptions({ personInternalId: entityId })

  return useMutation({
    mutationKey: ['dispatchPersonEvent', entityId],
    mutationFn: sendDispatchPersonEvent,
    async onMutate({ isDispatchedManually }) {
      await queryClient.cancelQueries({ queryKey })

      const previousEntityDispatchEvent = queryClient.getQueryData(queryKey)

      if (isDispatchedManually) {
        return {
          previousEntityDispatchEvent,
        }
      }

      const now = new Date().toISOString()

      if (previousEntityDispatchEvent) {
        queryClient.setQueryData(queryKey, () => {
          return {
            ...previousEntityDispatchEvent,
            processingStatus: 'PENDING',
            type: 'INCLUSION',
            origin: 'AUTOMATION',
            updatedAt: now,
          }
        })
      } else {
        queryClient.setQueryData(queryKey, () => {
          return {
            origin: 'AUTOMATION',
            personInternalId: entityId,
            processingStatus: 'PENDING',
            type: 'INCLUSION',
            createdAt: now,
          }
        })
      }

      return {
        previousEntityDispatchEvent,
      }
    },
    onError(_error, _variables, context) {
      queryClient.setQueryData(queryKey, context?.previousEntityDispatchEvent)
    },
    onSettled() {
      queryClient.invalidateQueries({ queryKey })
      queryClient.invalidateQueries({ queryKey: ['dispatchPersonEvent'] })
    },
  })
}
