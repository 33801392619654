export const formatPhone = (value: string): string => {
  const phoneNumber = value.replace(/\D/g, '')
  return phoneNumber.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3')
}

export const formatCpf = (value: string): string => {
  const cpf = value.replace(/\D/g, '')
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export const formatCnpj = (value: string): string => {
  const cnpj = value.replace(/\D/g, '')
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}
