import { ReactNode, useEffect, useState } from 'react'

import { Skeleton } from 'antd'
import { triggerToast } from 'design-system/triggers'

import { healthPlansOrderRequest } from '@/App/clients/healthPlansOrder'
import { GetOrderChangeLogsByIdDto } from '@/App/clients/healthPlansOrder/dto'
import useFetch from '@/App/clients/http'

import { HistoryContainer, SkeletonContainer } from './style'

interface IChangeLogsProps {
  planCard: ReactNode
  orderId?: string | null
}
export const ChangeLogs = ({ planCard, orderId }: IChangeLogsProps) => {
  const [changelogs, setChangelogs] = useState<GetOrderChangeLogsByIdDto['changelogs']>([])

  const {
    setRequestConfig: getChangeLogs,
    data: changeLogsData,
    isLoading: isLoadingChangeLogs,
    error: errorChangeLogs,
  } = useFetch<GetOrderChangeLogsByIdDto>()

  const orderChangeLogsRequestConfig = healthPlansOrderRequest.getOrdersChangelogById(orderId)

  useEffect(() => {
    setChangelogs(changeLogsData?.changelogs ?? [])
  }, [changeLogsData])

  useEffect(() => {
    getChangeLogs(orderChangeLogsRequestConfig)
  }, [])

  useEffect(() => {
    if (errorChangeLogs) {
      triggerToast(true, 'Erro ao carregar histórico')
    }
  }, [errorChangeLogs])

  return (
    <div>
      {isLoadingChangeLogs ? (
        <SkeletonContainer>
          <Skeleton active={true} />
        </SkeletonContainer>
      ) : (
        <HistoryContainer>
          {changelogs?.map((item, index) => (
            <ul key={index}>
              <li>{item.event}</li>
              <li>{item.date}</li>
              <li>{item.changeset}</li>
            </ul>
          ))}
        </HistoryContainer>
      )}
      {planCard}
    </div>
  )
}
