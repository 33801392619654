import { ObjectFieldTemplateProps } from '@rjsf/utils'

export function ObjectFieldTemplate({ properties, uiSchema }: ObjectFieldTemplateProps) {
  const options = uiSchema?.['ui:options']

  const colspanStyles = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '3.75rem',
    gridColumn: `span ${options?.colSpan ? options?.colSpan.toString() : "1"}`
  };

  return (
    <fieldset id="FIELDSET" style={colspanStyles}>
      {properties.map((element) => {
        return (
          <div key={element.name} style={{ display: 'contents' }}>
            {element.content}
          </div>
        )
      })}
    </fieldset>
  )
}
