import { Modal } from 'antd'
import styled from 'styled-components'

export interface StyleProps {
  footerAlign?: 'start' | 'center' | 'end'
  padding?: string
  shouldHideCloseIcon?: boolean
  customHeight?: string
  disableMarginTop?: boolean
}

export const StyledModal = styled(Modal)<StyleProps>`
  &&& {
    .ant-modal-content {
      padding: ${(props) => props.padding ?? '20px 24px'};
      height: ${(props) => props.customHeight};
      overflow: auto;
    }

    .ant-modal-body {
      margin-top: ${(props) => (props.title || props.disableMarginTop ? '0' : '30px')};
    }

    .ant-modal-footer {
      display: flex !important;
      justify-content: ${(props) => (props.footerAlign ? props.footerAlign : 'center')} !important;
    }

    .ant-modal-close {
      display: ${(props) => (props.shouldHideCloseIcon ? 'none' : '')} !important;
    }
  }
`
