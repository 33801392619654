export const formatDate = (dateString: string) => {
  const parts = dateString?.split('/')
  if (parts != undefined) {
    const day = parts.at(0)
    const month = parts.at(1)
    const year = parts.at(2)

    return `${year}-${month?.padStart(2, '0')}-${day?.padStart(2, '0')}`
  }
}
