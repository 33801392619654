import { AuthContext } from './AuthContext'

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const signIn = async (token: string) => {
    return localStorage.setItem('authToken', token)
  }

  const signOut = () => {
    localStorage.removeItem('authToken')
    localStorage.removeItem('desiredUrlPath')
  }

  return <AuthContext.Provider value={{ signIn, signOut }}>{children}</AuthContext.Provider>
}
