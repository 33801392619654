import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Select, Skeleton, Spinner, TextInput } from 'design-system/components'

import { OptionsCustomerAssigment } from '@/App/clients/healthPlansOrder/dto'
import { HealthPlansOrdersRequests } from '@/App/clients/healthPlansOrders'
import {
  CreateLeadInputDto,
  GetBrokersByFiltersDto,
  GetLeadsByBrokerIdDto,
  GetManagersDto,
} from '@/App/clients/healthPlansOrders/dtos'
import useFetch from '@/App/clients/http'

import { useCreateOrderContext } from '../order/store'
import {
  CreateLeadButton,
  FormDiv,
  LeadAssignmentDiv,
  LeadAssignmentTitle,
  LeadAssignmentTitleDiv,
  LeadDiv,
  LeadInputDiv,
  SelectDiv,
  SelectLabel,
  SkeletonDiv,
} from './style'

export interface LeadAssignmentProps {
  optionsCustomerAssigment?: OptionsCustomerAssigment
  createLead: (data: CreateLeadInputDto) => void
  setOptionsCustomerAssigment?: React.Dispatch<
    React.SetStateAction<OptionsCustomerAssigment | undefined>
  >
}

const LeadAssignment: React.FC<LeadAssignmentProps> = ({
  optionsCustomerAssigment,
  createLead,
  setOptionsCustomerAssigment,
}) => {
  const [isCreateLeadSelected, setIsCreateLeadSelected] = useState(false)
  const [selectedBroker, setSelectedBroker] = useState(false)
  const [errorRequestBroker, setErrorRequestBroker] = useState(false)

  const createOrderStep = useCreateOrderContext()

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<CreateLeadInputDto>({
    mode: 'onChange',
    delayError: 1000,
  })

  const {
    setRequestConfig: setManagersRequestConfig,
    data: managersOptionsData,
    isLoading: managersRetrieveIsLoading,
  } = useFetch<GetManagersDto>()
  const {
    setRequestConfig: setBrokersRequestConfig,
    data: brokersOptionsData,
    isLoading: brokersRetrieveIsLoadind,
    error: brokersRequestError,
  } = useFetch<GetBrokersByFiltersDto>()
  const {
    setRequestConfig: setLeadsRequestConfig,
    data: leadsOptionsData,
    isLoading: leadsRetrieveIsLoading,
  } = useFetch<GetLeadsByBrokerIdDto>()

  useEffect(() => {
    if (optionsCustomerAssigment?.managerName === undefined) {
      const managersRequestConfig = HealthPlansOrdersRequests.getManagers()
      setManagersRequestConfig(managersRequestConfig)
    }
  }, [optionsCustomerAssigment?.managerName])

  useEffect(() => {
    if (optionsCustomerAssigment?.managerId && createOrderStep.broker?.id === undefined) {
      const brokersRequestConfig = HealthPlansOrdersRequests.getBrokersByFilters({
        managerId: optionsCustomerAssigment?.managerId,
      })
      setBrokersRequestConfig(brokersRequestConfig)
    }
  }, [optionsCustomerAssigment?.managerId, createOrderStep.broker?.id])

  useEffect(() => {
    if (selectedBroker && createOrderStep.broker?.id) {
      const leadsRequestConfig = HealthPlansOrdersRequests.getLeadsByBrokerId(
        createOrderStep.broker.id,
        100,
        0,
      )
      setLeadsRequestConfig(leadsRequestConfig)
    }
  }, [createOrderStep.broker?.id])

  useEffect(() => {
    if (managersOptionsData != null && managersOptionsData?.managers != null) {
      const options = managersOptionsData?.managers
        .map((manager) => ({
          label: manager.name,
          value: manager.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
      if (setOptionsCustomerAssigment) {
        setOptionsCustomerAssigment((oldState) => ({ ...oldState, managerOptions: options }))
      }
    }
  }, [managersOptionsData])

  useEffect(() => {
    if (brokersOptionsData != null && brokersOptionsData?.brokers != null) {
      const options = brokersOptionsData?.brokers
        .map((broker) => ({
          label: broker.name,
          value: broker.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))

      if (setOptionsCustomerAssigment) {
        setOptionsCustomerAssigment((oldState) => ({ ...oldState, brokerOptions: options }))
      }
    }
  }, [brokersOptionsData])

  useEffect(() => {
    if (leadsOptionsData != null && leadsOptionsData?.leads != null) {
      const options = leadsOptionsData?.leads
        .map((lead) => ({
          label: lead.name,
          value: lead.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))

      if (setOptionsCustomerAssigment) {
        setOptionsCustomerAssigment((oldState) => ({ ...oldState, leadOptions: options }))
      }
    }
  }, [leadsOptionsData])

  useEffect(() => {
    if (brokersRequestError) setErrorRequestBroker(true)
  }, [brokersRequestError])

  const setBroker = (value: string) => {
    const broker = optionsCustomerAssigment?.brokerOptions?.find((broker) => broker.value === value)
    createOrderStep.setBroker({
      id: broker?.value,
      name: broker?.label,
    })
    setSelectedBroker(true)
  }

  const setManager = (value: string) => {
    const manager = optionsCustomerAssigment?.managerOptions?.find(
      (manager) => manager.value === value,
    )
    if (setOptionsCustomerAssigment) {
      setOptionsCustomerAssigment({
        managerId: manager?.value,
        managerName: manager?.label,
        managerOptions: optionsCustomerAssigment?.managerOptions,
      })
    }
    createOrderStep.setBroker(null)
    setErrorRequestBroker(false)
  }

  const setLead = (value: string) => {
    const lead = optionsCustomerAssigment?.leadOptions?.find((lead) => lead.value === value)
    createOrderStep.setLead({
      id: lead?.value,
      name: lead?.label,
    })
  }

  return (
    <LeadAssignmentDiv>
      <LeadAssignmentTitleDiv>
        <LeadAssignmentTitle>Quem é o corretor responsável por esta proposta?</LeadAssignmentTitle>
      </LeadAssignmentTitleDiv>
      {optionsCustomerAssigment ? (
        <>
          <FormDiv>
            <SelectDiv>
              {managersRetrieveIsLoading ? (
                <SkeletonDiv>
                  <Skeleton type="input" />
                </SkeletonDiv>
              ) : (
                <>
                  <SelectLabel>Gestor</SelectLabel>
                  <Select
                    defaultValue={optionsCustomerAssigment?.managerName ?? 'Selecionar o gestor'}
                    options={optionsCustomerAssigment?.managerOptions ?? []}
                    onChange={(value) => {
                      if (typeof value === 'string') {
                        setManager(value)
                      }
                    }}
                  />
                </>
              )}
            </SelectDiv>
            <SelectDiv isVisible={!!optionsCustomerAssigment?.managerId}>
              {brokersRetrieveIsLoadind ? (
                <SkeletonDiv>
                  <Skeleton type="input" />
                </SkeletonDiv>
              ) : (
                <>
                  {optionsCustomerAssigment?.brokerOptions && !errorRequestBroker && (
                    <>
                      <SelectLabel>Corretor</SelectLabel>
                      <Select
                        showSearch
                        defaultValue={createOrderStep.broker?.name ?? 'Selecionar o corretor'}
                        options={optionsCustomerAssigment?.brokerOptions}
                        onChange={(value) => {
                          if (typeof value === 'string') {
                            setBroker(value)
                          }
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </SelectDiv>
          </FormDiv>

          <LeadDiv isVisible={!!createOrderStep.broker?.id}>
            <LeadAssignmentTitleDiv>
              <LeadAssignmentTitle>Selecione o Cliente</LeadAssignmentTitle>
            </LeadAssignmentTitleDiv>

            <FormDiv>
              {leadsRetrieveIsLoading ? (
                <SkeletonDiv>
                  <Skeleton type="input" />
                </SkeletonDiv>
              ) : (
                <>
                  {optionsCustomerAssigment?.leadOptions && (
                    <SelectDiv>
                      <SelectLabel>Cliente</SelectLabel>
                      <Select
                        showSearch
                        defaultValue={createOrderStep.lead?.name ?? 'Selecionar o cliente'}
                        options={optionsCustomerAssigment?.leadOptions}
                        onChange={(value) => {
                          if (typeof value === 'string') {
                            setLead(value)
                          }
                        }}
                      />
                    </SelectDiv>
                  )}

                  <CreateLeadButton
                    selected={isCreateLeadSelected}
                    onClick={() => setIsCreateLeadSelected(!isCreateLeadSelected)}
                  >
                    <p>Cadastrar novo cliente</p>
                  </CreateLeadButton>
                  <form id="createLead" onSubmit={handleSubmit(createLead)}>
                    <LeadInputDiv isVisible={isCreateLeadSelected}>
                      <TextInput
                        name="name"
                        vertical
                        label="Nome do cliente*"
                        register={{
                          ...register('name', {
                            required: 'Este campo é obrigatório.',
                          }),
                        }}
                        error={errors.name}
                      />
                      <TextInput
                        name="phone"
                        vertical
                        label="Telefone*"
                        mask="phone"
                        register={{
                          ...register('phone', {
                            required: 'Este campo é obrigatório.',
                            pattern: {
                              value: /^\(\d{2}\)(?: \d)? \d{4} \d{4}$/,
                              message: 'Telefone inválido',
                            },
                          }),
                        }}
                        error={errors.phone}
                      />
                      <TextInput
                        name="mail"
                        vertical
                        label="E-mail"
                        register={{
                          ...register('mail', {
                            pattern: {
                              value: /^[a-za-z0-9._%+-]+@[a-za-z0-9.-]+\.[a-za-z]{2,}$/,
                              message: 'E-mail inválido',
                            },
                          }),
                        }}
                        error={errors.mail}
                      />
                    </LeadInputDiv>
                  </form>
                </>
              )}
            </FormDiv>
          </LeadDiv>
        </>
      ) : (
        <Spinner />
      )}
    </LeadAssignmentDiv>
  )
}

export default LeadAssignment
