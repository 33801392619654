import { useEffect, useState } from 'react'

import { SettingOutlined } from '@ant-design/icons'
import { Modal, Skeleton, Spinner, SwitchButton } from 'design-system/components'

import { HealthPlansOrdersRequests } from '@/App/clients/healthPlansOrders'
import { DispatchEvent } from '@/App/clients/healthPlansOrders/dtos'
import { useFillOrderInformation } from '@/App/clients/healthPlansOrders/mutations/fillOrderInformation'
import { useOrderDispatchEvents } from '@/App/clients/healthPlansOrders/queries/orderDispatchEvents'
import { useOrderInformationQuery } from '@/App/clients/healthPlansOrders/queries/orderInformation'
import type { UseFetchConfig } from '@/App/clients/http/interface'
import { useDynamicForm } from '@/App/contexts/dynamicForm/DynamicFormProvider'
import { calculateProcessingTime } from '@/App/utils/calculateProcessingTime'

import { getRequiredFieldsSisweb } from '../entities/entityAccordion/helper/sisweb/requiredFields'
import { SiswebDispatchingModal } from './siswebDispatching'
import {
  ActionsDiv,
  ButtonsWrapper,
  HorizontalLine,
  IconWrapper,
  OtherInfosDiv,
  OtherInfosHeader,
  RequestTag,
  StyledDispatchInProgress,
  SubttitleStatus,
  TitleStatus,
  WrapperDiv,
} from './style'

export interface SiswebDispatchProps {
  setFillIsRegisteredInInternalSystemRequestConfig?: React.Dispatch<
    React.SetStateAction<UseFetchConfig | undefined>
  >
  isRegisteredInInternalSystem: boolean
  setIsRegisteredInInternalSystem: React.Dispatch<React.SetStateAction<boolean | undefined>>
  isLoadingFillIsRegisteredInInternalSystem: boolean
  setValidateSiswebDispatch: React.Dispatch<React.SetStateAction<boolean | undefined>>
  orderId?: string | null
}

export const SiswebDispatch: React.FC<SiswebDispatchProps> = ({
  setFillIsRegisteredInInternalSystemRequestConfig,
  isRegisteredInInternalSystem,
  setIsRegisteredInInternalSystem,
  isLoadingFillIsRegisteredInInternalSystem,
  orderId,
  setValidateSiswebDispatch,
}) => {
  const orderInformationQuery = useOrderInformationQuery({ orderId })
  const fillOrderInformation = useFillOrderInformation()

  const orderDispatchEventQuery = useOrderDispatchEvents({
    orderId,
    isDispatchedToInternalSystem:
      orderInformationQuery?.data?.isDispatchedToInternalSystem ?? false,
    status: orderInformationQuery?.data?.status,
    dispatchStatus: orderInformationQuery.isSuccess === true,
  })

  const orderData = orderInformationQuery?.data

  const [isModalOpen, setIsModalOpen] = useState<boolean>()
  const [siswebDispatchProduct, setSiswebDispatchProduct] = useState<'HEALTH' | 'DENTAL'>()
  const [healthEvent, setHealthEvent] = useState<DispatchEvent>()
  const [dentalEvent, setDentalEvent] = useState<DispatchEvent>()
  const [isValidatingForms, setIsValidatingForms] = useState<boolean>()

  const dynamicFormState = useDynamicForm()

  const eventSucess =
    orderData?.quotation?.product === 'HEALTH_DENTAL'
      ? healthEvent?.processingStatus === 'SUCCESS' && dentalEvent?.processingStatus === 'SUCCESS'
      : healthEvent?.processingStatus === 'SUCCESS' || dentalEvent?.processingStatus === 'SUCCESS'

  const showErrorReasonHealth =
    healthEvent?.processingStatus === 'insurer_processing_error' ||
    healthEvent?.processingStatus === 'FAILED' ||
    healthEvent?.processingStatus === 'ERROR'

  const showErrorReasonDental =
    healthEvent?.processingStatus === 'insurer_processing_error' ||
    healthEvent?.processingStatus === 'FAILED' ||
    healthEvent?.processingStatus === 'ERROR'

  const toggleIsRegisteredInInternalSystem = () => {
    const fillOrderInformationRequestConfig = HealthPlansOrdersRequests.fillOrderInformation(
      { isRegisteredInInternalSystem: !isRegisteredInInternalSystem },
      orderData?.id,
    )
    if (setFillIsRegisteredInInternalSystemRequestConfig) {
      setFillIsRegisteredInInternalSystemRequestConfig(fillOrderInformationRequestConfig)
    }

    setIsRegisteredInInternalSystem(!isRegisteredInInternalSystem)
  }

  useEffect(() => {
    if (!orderData?.isRegisteredInInternalSystem && eventSucess) {
      toggleIsRegisteredInInternalSystem()
    }
  }, [eventSucess])

  useEffect(() => {
    if (fillOrderInformation.status == 'success') {
      orderInformationQuery.refetch()
    }
  }, [fillOrderInformation.status])

  useEffect(() => {
    if (orderDispatchEventQuery?.data && orderDispatchEventQuery?.data?.length > 0) {
      orderDispatchEventQuery.data.map((event) => {
        if (event.type === 'INCLUSION' && event.product === 'HEALTH') {
          setHealthEvent(event)
        }
        if (event.type === 'INCLUSION' && event.product === 'DENTAL') {
          setDentalEvent(event)
        }
      })
    }
  }, [orderDispatchEventQuery?.data])

  useEffect(() => {
    if (orderInformationQuery?.isRefetching === false && isValidatingForms) {
      const requiredFields = getRequiredFieldsSisweb(orderData)

      requiredFields?.forEach((validator) => {
        dynamicFormState.setErrorForRequiredField(
          validator?.dynamicFormId,
          validator?.requiredFields ?? [],
        )
      })

      const hasEmptyValues =
        requiredFields?.some(
          (validator) => validator?.requiredFields && validator.requiredFields.length > 0,
        ) ?? false

      if (hasEmptyValues === true) {
        setValidateSiswebDispatch(true)
      } else {
        setValidateSiswebDispatch(false)
        setIsModalOpen(true)
      }
      setIsValidatingForms(false)
    }
  }, [orderInformationQuery?.isRefetching])

  const verifyForm = (product: 'HEALTH' | 'DENTAL') => {
    orderInformationQuery.refetch()
    setSiswebDispatchProduct(product)
    setIsValidatingForms(true)
  }

  const healthProcessingTime = calculateProcessingTime(
    healthEvent?.createdAt,
    healthEvent?.processingStatus,
    healthEvent?.updatedAt,
  )

  const dentalProcessingTime = calculateProcessingTime(
    dentalEvent?.createdAt,
    dentalEvent?.processingStatus,
    dentalEvent?.updatedAt,
  )

  return (
    <OtherInfosDiv>
      {orderData == null ? (
        <Skeleton
          type="default"
          shape="square"
          active={true}
          loading={true}
          title={{ width: '400px' }}
        />
      ) : (
        <>
          <OtherInfosHeader>
            <span> Cadastro no Sisweb </span>
          </OtherInfosHeader>
          <HorizontalLine />
          <ButtonsWrapper>
            <ActionsDiv>
              {orderData?.quotation?.product === 'HEALTH' && (
                <>
                  <RequestTag onClick={() => verifyForm('HEALTH')}>
                    {isValidatingForms ? (
                      <Spinner width="12px" height="12px" />
                    ) : (
                      <SettingOutlined />
                    )}
                    <span>Cadastrar Proposta Saúde</span>
                  </RequestTag>

                  {healthEvent != undefined && (
                    <StyledDispatchInProgress>
                      <IconWrapper>{healthEvent?.Icon()}</IconWrapper>
                      {healthEvent?.processingStatus === 'PENDING' ? (
                        <div>
                          <TitleStatus>Status de Cadastro:</TitleStatus>
                          <div>
                            <TitleStatus> {healthEvent?.label} </TitleStatus>
                            <SubttitleStatus>{healthProcessingTime}</SubttitleStatus>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div>
                            <TitleStatus> {healthEvent?.label} </TitleStatus>
                            <SubttitleStatus>{healthProcessingTime}</SubttitleStatus>
                          </div>
                          {showErrorReasonHealth && healthEvent?.errorReason !== null && (
                            <div>
                              <TitleStatus>Motivo</TitleStatus>
                              <SubttitleStatus>{`->${healthEvent?.errorReason}`}</SubttitleStatus>
                            </div>
                          )}
                        </div>
                      )}
                    </StyledDispatchInProgress>
                  )}
                </>
              )}
            </ActionsDiv>

            <ActionsDiv>
              {(orderData?.quotation?.product === 'DENTAL' ||
                orderData?.quotation?.isCombinedProposal) && (
                <>
                  <RequestTag onClick={() => verifyForm('DENTAL')}>
                    {isValidatingForms ? (
                      <Spinner width="12px" height="12px" />
                    ) : (
                      <SettingOutlined />
                    )}
                    <span>Cadastrar Proposta Odonto</span>
                  </RequestTag>

                  {dentalEvent != undefined && (
                    <StyledDispatchInProgress>
                      <IconWrapper>{dentalEvent?.Icon()}</IconWrapper>
                      {dentalEvent?.processingStatus === 'PENDING' ? (
                        <div>
                          <TitleStatus>Status de Cadastro:</TitleStatus>
                          <div>
                            <TitleStatus> {dentalEvent?.label} </TitleStatus>
                            <SubttitleStatus>{dentalProcessingTime}</SubttitleStatus>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div>
                            <TitleStatus> {dentalEvent?.label} </TitleStatus>
                            <SubttitleStatus>{dentalProcessingTime}</SubttitleStatus>
                          </div>
                          {showErrorReasonDental && dentalEvent?.errorReason !== null && (
                            <div>
                              <TitleStatus>Motivo</TitleStatus>
                              <SubttitleStatus>{`->${dentalEvent?.errorReason}`}</SubttitleStatus>
                            </div>
                          )}
                        </div>
                      )}
                    </StyledDispatchInProgress>
                  )}
                </>
              )}
            </ActionsDiv>
          </ButtonsWrapper>
          <WrapperDiv>
            <p>Cadastrada no sisweb</p>

            <SwitchButton
              isLoading={isLoadingFillIsRegisteredInInternalSystem}
              checked={isRegisteredInInternalSystem || eventSucess}
              onClick={toggleIsRegisteredInInternalSystem}
              color="#514984"
            />
          </WrapperDiv>
          <Modal
            isOpen={isModalOpen ?? false}
            width={800}
            setIsOpen={() => setIsModalOpen(false)}
            footer={false}
          >
            <SiswebDispatchingModal
              order={orderData}
              product={siswebDispatchProduct}
              setIsModalOpen={setIsModalOpen}
              reloadOrderData={() => orderInformationQuery.refetch()}
            />
          </Modal>
        </>
      )}
    </OtherInfosDiv>
  )
}
