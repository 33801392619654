import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { SearchOutlined } from '@ant-design/icons'
import { Skeleton } from 'antd'
import { Image, TextInput } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { OcrDocumentInformationResponse } from '@/App/clients/OCR/mutation/fillProofResidency'
import { DocumentDto, Entity } from '@/App/clients/healthPlansOrders/dtos'
import { useFillOrderInformation } from '@/App/clients/healthPlansOrders/mutations/fillOrderInformation'

import {
  FormItem,
  ImageInspector,
  SearchIconDiv,
  StyledFigure,
  StyledForm,
  StyledOCRView,
  StyledWrapper,
} from '../style'

export interface OnFileUploadInput {
  fileContent: Blob | string
  fileContentType: string
  fileName: string
  reference?: string
}

export interface OCRDocumentsProps {
  isInspectorOpen: boolean
  setInspectorOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>
  documentUploaded?: DocumentDto
  entityData?: Entity | null
  OCRData?: OcrDocumentInformationResponse
  OCRLoading: boolean
  orderId?: string | null
  isViewOcrActivated: boolean
  insurer?: string | null
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>
  setDocumentUploaded: React.Dispatch<React.SetStateAction<DocumentDto | undefined>>
  reloadOrderData?: () => void
  isOcrProcessing: boolean
  setIsOcrProcessing: React.Dispatch<React.SetStateAction<boolean>>
}
export const OCRDeclarationProofResidency: React.FC<OCRDocumentsProps> = ({
  isInspectorOpen,
  setInspectorOpen,
  documentUploaded,
  entityData,
  OCRData,
  OCRLoading,
  orderId,
  isViewOcrActivated,
  setVisibility,
  setDocumentUploaded,
  reloadOrderData,
  setIsOcrProcessing,
}) => {
  const [imagePreviewVisibility, setImagePreviewVisibility] = useState(false)

  const fillOrderInformationSaveMutation = useFillOrderInformation()

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<Entity>({
    defaultValues: entityData ?? {},
    mode: 'onSubmit',
  })

  useEffect(() => {
    if (OCRData != undefined) {
      setValue('address.neighborhood', OCRData.neighborhood)
      setValue('address.city', OCRData.city)
      setValue('address.state', OCRData.state)
      setValue('address.zipCode', OCRData.zipcode)
      setValue('address.number', OCRData.number)
      setValue('address.street', OCRData.street)
      setValue('address.complement', OCRData.complement)
    }
  }, [OCRData])

  const fillEntityInformation = async (data: Entity) => {
    delete data.quotationPlans
    const payload = {
      entities: [
        {
          id: entityData?.id,
          address: {
            neighborhood: data?.address?.neighborhood,
            city: data?.address?.city,
            state: data?.address?.state,
            zipCode: data?.address?.zipCode,
            number: data?.address?.number,
            street: data?.address?.street,
            complement: data?.address?.complement,
          },
        },
      ],
    }

    fillOrderInformationSaveMutation.mutate({ ...payload, orderId: orderId })
    setIsOcrProcessing(true)
  }

  useEffect(() => {
    if (fillOrderInformationSaveMutation.isSuccess) {
      setVisibility(false)
      setDocumentUploaded(undefined)
      setInspectorOpen(false)
      reloadOrderData && reloadOrderData()
    }
  }, [fillOrderInformationSaveMutation.isSuccess])

  useEffect(() => {
    if (fillOrderInformationSaveMutation.isError) {
      triggerToast(false, 'Ops algo deu errado, verifique as informações enviadas!')
      setIsOcrProcessing(false)
    }
  }, [fillOrderInformationSaveMutation.isError])

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      triggerToast(false, 'Preencha os campos obrigatórios!')
    }
  }, [errors])

  const requiredMessage = 'Campo obrigatório'

  return (
    <>
      {OCRLoading && <Skeleton />}
      {(isInspectorOpen || isViewOcrActivated) && (
        <StyledWrapper>
          <StyledOCRView>
            <ImageInspector>
              <StyledFigure>
                {documentUploaded?.fileName?.split('.').pop() === 'pdf' ? (
                  <embed src={documentUploaded?.previewURL} width={450} height={380} />
                ) : (
                  <Image
                    height={'360px'}
                    src={documentUploaded?.previewURL ?? ''}
                    visible={imagePreviewVisibility && !!documentUploaded?.previewURL}
                    setVisible={setImagePreviewVisibility}
                  />
                )}
              </StyledFigure>
              <SearchIconDiv>
                {!(documentUploaded?.fileName?.split('.').pop() === 'pdf') && (
                  <SearchOutlined onClick={() => setImagePreviewVisibility(true)} />
                )}
              </SearchIconDiv>
            </ImageInspector>
          </StyledOCRView>
          <StyledForm id="OCR" onSubmit={handleSubmit(fillEntityInformation)}>
            <FormItem>
              <TextInput
                name="street"
                placeholder="Ex.: Rua João Pessoa"
                register={{
                  ...register('address.street', {
                    setValueAs: (value) => (value ? value : null),
                    required: requiredMessage,
                  }),
                }}
                label="Logradouro*"
                vertical
                error={errors?.address?.street}
              />
            </FormItem>

            <FormItem>
              <TextInput
                name="neighborhood"
                placeholder="Ex.: Pinheiros"
                register={{
                  ...register('address.neighborhood', {
                    setValueAs: (value) => (value ? value : null),
                    required: requiredMessage,
                  }),
                }}
                label="Bairro"
                vertical
                error={errors?.address?.neighborhood}
              />
            </FormItem>

            <FormItem>
              <TextInput
                name="city"
                placeholder="Ex.: Guarujá"
                register={{
                  ...register('address.city', {
                    setValueAs: (value) => (value ? value : null),
                    required: requiredMessage,
                  }),
                }}
                label="Cidade"
                vertical
                error={errors?.address?.city}
              />
            </FormItem>

            <FormItem>
              <TextInput
                name="state"
                placeholder="Ex.: SP"
                register={{
                  ...register('address.state', {
                    setValueAs: (value) => (value ? value : null),
                    required: requiredMessage,
                  }),
                }}
                label="Estado"
                vertical
                error={errors?.address?.state}
              />
            </FormItem>

            <FormItem>
              <TextInput
                name="number"
                placeholder="000"
                label="Número*"
                vertical
                register={{
                  ...register('address.number', {
                    required: requiredMessage,
                    setValueAs: (value) => (value ? value : null),
                  }),
                }}
                error={errors?.address?.number}
              />
            </FormItem>

            <FormItem>
              <TextInput
                name="complement"
                placeholder="Ex.: Apto 123"
                register={{
                  ...register('address.complement', {
                    setValueAs: (value) => (value ? value : null),
                  }),
                }}
                label="Complemento"
                vertical
              />
            </FormItem>

            <FormItem>
              <TextInput
                name="zipcode"
                mask="cep"
                placeholder="00000-000"
                label="CEP*"
                vertical
                register={{
                  ...register('address.zipCode', {
                    required: requiredMessage,
                    setValueAs: (value) => (value ? value : null),
                  }),
                }}
                error={errors?.address?.zipCode}
              />
            </FormItem>
          </StyledForm>
        </StyledWrapper>
      )}
    </>
  )
}
