/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, TextInput } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { OrdersData } from '@/App/clients/healthPlansOrder/dto'
import { useOrderInformationQuery } from '@/App/clients/healthPlansOrders/queries/orderInformation'
import { useUpdateStatus } from '@/App/clients/orderState/mutation/updateStatus'

import { ButtonDiv, CancelButton, Icon, ModalContent, ModalSubTitle, ModalTitle } from './style'

interface IAnalysisModalProps {
  setIsModalOpen?: (value: boolean) => void
  orderId?: string | null
  insurerId?: string | null
  setShouldReload?: React.Dispatch<React.SetStateAction<boolean>>
  setOrders?: React.Dispatch<React.SetStateAction<OrdersData[]>>
  setOrderStatus?: React.Dispatch<React.SetStateAction<string | null>>
}

export const AnalysisModal: React.FC<IAnalysisModalProps> = ({
  setIsModalOpen,
  orderId,
  insurerId,
  setShouldReload,
  setOrders,
  setOrderStatus,
}) => {
  const [orderInsurerId, setOrderInsurerId] = useState(insurerId === '-' ? '' : insurerId)

  const orderInformationQuery = useOrderInformationQuery({ orderId })
  const updateStatusMutation = useUpdateStatus(orderId)

  useEffect(() => {
    if (updateStatusMutation.isSuccess) {
      setOrderStatus && setOrderStatus('ANALYSIS')
      setIsModalOpen && setIsModalOpen(false)
      updateInsurerId(orderId, orderInsurerId)
      if (setShouldReload) setShouldReload(true)
      triggerToast(true, 'Status atualizado com sucesso')
    } else if (updateStatusMutation.isError) {
      triggerToast(false, 'Erro ao atualizar')
    }
  }, [updateStatusMutation.isError, updateStatusMutation.isSuccess])

  const fillOrderStatusAndInsurerId = async () => {
    if (orderId) {
      await updateStatusMutation.mutateAsync({
        nextStatus: 'ANALYSIS',
        order: orderInformationQuery?.data,
        insurerId: orderInsurerId,
      })
    }
  }

  const updateInsurerId = (orderId?: string | null, orderInsurerId?: string | null) => {
    setOrders &&
      setOrders((prevOrders) => {
        const updatedOrders = prevOrders.map((order) => {
          if (order.key === orderId) {
            return { ...order, insurerId: orderInsurerId }
          }
          return order
        })

        return updatedOrders
      })
  }

  return (
    <ModalContent>
      <Icon>
        <ExclamationCircleOutlined />
      </Icon>
      <ModalTitle>Qual o número da proposta na operadora?</ModalTitle>
      <ModalSubTitle>
        Essa informação é essencial para a consulta automática de status no portal da operadora.
      </ModalSubTitle>
      <TextInput
        name="orderInsurerId"
        value={orderInsurerId ?? undefined}
        placeholder="Digite o número da proposta"
        onChange={(event) => setOrderInsurerId(event.target.value)}
      />
      <ButtonDiv>
        <Button
          disabled={!orderInsurerId?.length}
          loading={updateStatusMutation.isPending}
          onClick={fillOrderStatusAndInsurerId}
        >
          Salvar
        </Button>
        <CancelButton onClick={() => setIsModalOpen && setIsModalOpen(false)}>
          Cancelar
        </CancelButton>
      </ButtonDiv>
    </ModalContent>
  )
}
