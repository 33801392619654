import styled from 'styled-components'

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
  width: 450px;
  margin: 0 auto;
`
export const ButtonDiv = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
`
export const CancelButton = styled.button`
  margin-top: 20px;
  border: none;
  background: white;
  padding: 0;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
`
export const ModalTitle = styled.p`
  color: #4b4b4b;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 17px;
`
