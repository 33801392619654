import type { Maybe } from './maybe'

export const genderPredictionFunction = (name?: string | null): Maybe<'FEMALE' | 'MALE'> => {
  if (!name) return null

  const maleEndings: string[] = ['o', 'r', 'l', 'm', 'n', 's', 'é', 'us']
  const femaleEndings: string[] = ['a', 'e', 'i', 'y', 'la', 'na', 'da', 'ra']

  const normalizedFirstName = name.split(' ', 1)[0].toLowerCase()

  for (const ending of femaleEndings) {
    if (normalizedFirstName.endsWith(ending)) {
      return 'FEMALE'
    }
  }

  for (const ending of maleEndings) {
    if (normalizedFirstName.endsWith(ending)) {
      return 'MALE'
    }
  }
}
