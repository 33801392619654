import styled from 'styled-components'

export interface StyledHeaderProps {
  headerHeight?: string
}

export const StyledHeader = styled.header<StyledHeaderProps>`
  width: 100%;
  height: ${(props) => props.headerHeight ?? '66px'};
  border-bottom: 1px solid #dcdfeb;
  box-shadow: 0px 4px 4px -4px #373b6226;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`
