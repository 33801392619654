import { GetBrokersByIdDto } from '@/App/clients/healthPlansOrder/dto'

import { BankTransferContent } from './BankTransferContent'
import { PixContent } from './PixContent'

interface DefaultContentProps {
  data?: GetBrokersByIdDto | null
}

export const DefaultContent = ({ data }: DefaultContentProps) => {
  const RenderDefaultContent = () => {
    if (data?.paymentInformation?.pixKey != undefined) {
      return <PixContent data={data} />
    }

    if (data?.paymentInformation?.accountNumber != undefined) {
      return <BankTransferContent data={data} />
    }

    return <div>Sem dados de pagamento cadastrados</div>
  }

  return <RenderDefaultContent />
}
