import styled from 'styled-components'

export const FormLeadDataContainer = styled.form`
  width: 70%;
`

export const LeadDataColumn = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 10px;
`

export const WrapperFullWidth = styled.div`
  width: 100%;
`

export const RadioPlanLabel = styled.p`
  display: 'block';
  font-size: 13px;
  margin-top: 10px;
  margin: 0;
  font-weight: 700;
`

export const DatePickerContent = styled.div`
  height: 66px;

  p {
    font-size: 13px;
    color: #4b4b4b;
    font-weight: 700;
  }
  .ant-picker {
    width: 100%;
  }
`
export const MainContainerLeadData = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
`
