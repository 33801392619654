import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'

import { ActionButton, ActionButtonText, Container } from './styles'

export interface OptionsColumnProps {
  onEditView: () => void
  onDuplicate: () => void
  onDelete: () => void
}

const OptionsColumn = ({ onEditView, onDuplicate, onDelete }: OptionsColumnProps) => {
  return (
    <Container>
      <ActionButton onClick={onEditView}>
        <EditOutlined /> <ActionButtonText>Editar | Ver detalhes</ActionButtonText>
      </ActionButton>
      <ActionButton onClick={onDuplicate}>
        <CopyOutlined /> <ActionButtonText>Duplicar</ActionButtonText>
      </ActionButton>
      <ActionButton onClick={onDelete}>
        <DeleteOutlined /> <ActionButtonText>Excluir</ActionButtonText>
      </ActionButton>
    </Container>
  )
}

export default OptionsColumn
