const DentalIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none" viewBox="0 0 20 21">
      <path
        fill="#8589A2"
        d="M13.906.006A5.835 5.835 0 0011.15.8c-.701.411-1.533.411-2.235 0A5.897 5.897 0 005.692.006C2.522.134 0 2.726 0 5.858c0 1.874 1.17 3.286 1.716 5.031.883 2.85 1.793 5.699 2.677 8.522a1.59 1.59 0 001.533 1.104c.702 0 1.326-.462 1.534-1.104l1.845-5.852c.234-.719 1.248-.719 1.456 0l1.845 5.878a1.59 1.59 0 001.534 1.104c.701 0 1.325-.462 1.533-1.104 1.014-3.209 1.767-6.648 3.145-9.729 1.04-2.31 1.82-4.415.442-6.776-1.092-1.874-3.171-3.004-5.354-2.927z"
      ></path>
    </svg>
  )
}

export default DentalIcon
