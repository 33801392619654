import type { IFilters } from '@/App/pages/orders'

import { HttpVerbs } from '../http/enum'
import { UseFetchConfig } from '../http/interface'
import { CreateOrderNoteDto, FiltersDto, FiltersHashMapDto } from './dto'

export const healthPlansOrderRequest = {
  getOrdersById: (orderId: string): UseFetchConfig => {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v2/orders/${orderId}`,
      method: HttpVerbs.Get,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  getOrdersByFilters: (filters: FiltersDto = {}, page: number): UseFetchConfig => {
    const filtersHashMap: FiltersHashMapDto = {}

    for (const key in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, key)) {
        const value = filters[key]
        if (Array.isArray(value)) {
          filtersHashMap[key] = value.join(',')
        } else if (value != null && typeof value !== 'undefined' && value !== '') {
          filtersHashMap[key] = value.toString()
        }
      }
    }

    const filterString = Object.entries(filtersHashMap)
      .filter(([_key, value]) => !!value)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')

    const queryString = filterString ? `${filterString}` : ''

    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v2/admin/orders?perPage=20&page=${page}&${queryString}`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  getOrdersChangelogById: (orderId?: string | null): UseFetchConfig => {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/orders/${orderId}/changelogs`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  getLeadNotesByLeadId: (brokerId: string, leadId: string): UseFetchConfig => {
    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/brokers/${brokerId}/leads/${leadId}/notes`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  getBrokersByFilters: (filters: IFilters, page: number): UseFetchConfig => {
    const filtersHashMap: FiltersHashMapDto = {}

    for (const key in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, key)) {
        const value = filters[key]
        if (Array.isArray(value)) {
          filtersHashMap[key] = value.join(',')
        } else if (value != null && typeof value !== 'undefined' && value !== '') {
          filtersHashMap[key] = value.toString()
        }
      }
    }

    const filterString = Object.entries(filtersHashMap)
      .filter(([_key, value]) => !!value)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')

    const queryString = filterString ? `${filterString}` : ''

    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v2/brokers?perPage=20&page=${page}&${queryString}`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  getBrokersByFiltersV1: (filters: Record<string, unknown>): UseFetchConfig => {
    const filtersHashMap: FiltersHashMapDto = {}

    for (const key in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, key)) {
        const value = filters[key]
        if (Array.isArray(value)) {
          filtersHashMap[key] = value.join(',')
        } else if (value != null && typeof value !== 'undefined' && value !== '') {
          filtersHashMap[key] = value.toString()
        }
      }
    }

    const filterString = Object.entries(filtersHashMap)
      .filter(([_key, value]) => !!value)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')

    const queryString = filterString ? `${filterString}` : ''

    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/brokers?${queryString}`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  getBrokersById: (brokerId: string): UseFetchConfig => {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/brokers/${brokerId}`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  getManagers: (): UseFetchConfig => {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/managers/`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  getAdminsByFilters: (): UseFetchConfig => {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/admins/`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  getOrderTimelineById: (orderId?: string | null): UseFetchConfig => {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/orders/${orderId}/timelines`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  getOrderNotesById: (orderId?: string | null, page = 0): UseFetchConfig => {
    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/orders/${orderId}/notes?perPage=10&page=${page}`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  createOrderNote: (
    orderId?: string | null,
    orderNoteData?: CreateOrderNoteDto | null,
  ): UseFetchConfig => {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/orders/${orderId}/notes`,
      data: {
        type: orderNoteData?.type,
        content: orderNoteData?.content,
      },
      method: HttpVerbs.Post,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  uploadDocumentToOrder: (formData: unknown, orderId?: string | null): UseFetchConfig => {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/orders/${orderId}/documents`,
      data: formData,
      method: HttpVerbs.Put,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
        'Content-Type': 'multipart/form-data',
      },
    }
  },

  fillLeadInformation: (brokerId: string, leadId: string, data: unknown): UseFetchConfig => {
    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/brokers/${brokerId}/leads/${leadId}`,
      data: data,
      method: HttpVerbs.Patch,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  fillOrderInformation: (orderId: string, data: unknown): UseFetchConfig => {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/orders/${orderId}`,
      data: data,
      method: HttpVerbs.Patch,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  fillBrokerInformation: (brokerId: string, data: unknown): UseFetchConfig => {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/brokers/${brokerId}`,
      data: data,
      method: HttpVerbs.Patch,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  downloadDocumentFileFromOrder: (orderId: string, documentId: string): UseFetchConfig => {
    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/orders/${orderId}/documents/${documentId}/files`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
      responseType: 'blob',
    }
  },

  deleteDocumentFileFromOrder: (orderId: string, documentId: string): UseFetchConfig => {
    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/orders/${orderId}/documents/${documentId}`,
      method: HttpVerbs.Delete,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },
}
