import { EditInfoButton, ExternalMain, ExternalWrapper, WrapperExternalDiv } from '../style'

export const BrokerB2CPersonType: React.FC = () => {
  return (
    <ExternalMain>
      <ExternalWrapper>
        <WrapperExternalDiv>
          <p>Vendedor B2C</p>
        </WrapperExternalDiv>

        <EditInfoButton>
          <p>
            Vendedor na Operadora:* <span>Lina Horiguchi</span>
          </p>
        </EditInfoButton>

        <EditInfoButton>
          <p>
            CPF do vendedor:* {''}
            <span>34295154830</span>
          </p>
        </EditInfoButton>

        <EditInfoButton>
          <p>
            E-mail do vendedor:* <span>lina.horiguchi@saudebliss.com.br</span>
          </p>
        </EditInfoButton>

        <EditInfoButton>
          <p>
            Telefone do vendedor:* <span>(11) 9 9679-8301</span>
          </p>
        </EditInfoButton>
      </ExternalWrapper>
    </ExternalMain>
  )
}
