import { useEffect, useMemo, useState } from 'react'

import { CloseOutlined, FilterOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { ItemProps } from 'design-system/Filter/Filter'
import { CheckBox, Filter, SearchInput } from 'design-system/components'

import { GridItem } from '@/App/clients/finOps/dtos'

import { FilterButton, FilterDiv, SelectedFilters } from './styles'

type Filters = {
  brokers: string[]
  modalities: string[]
  grids: string[]
  platforms: string[]
  administrators: string[]
  others: string[]
}

type FiltersSearch = {
  brokers: string
  modalities: string
  grids: string
  platforms: string
  administrators: string
  others: string
}

export const CustomFilter = ({
  data,
  setFilteredData,
}: {
  data: GridItem[]
  setFilteredData: (data: GridItem[]) => void
}) => {
  const [openFilter, setOpenFilter] = useState(false)
  const [filters, setFilters] = useState<Filters>({
    brokers: [],
    grids: [],
    modalities: [],
    platforms: [],
    administrators: [],
    others: [],
  })

  const [search, setSearch] = useState<FiltersSearch>({
    brokers: '',
    modalities: '',
    grids: '',
    platforms: '',
    administrators: '',
    others: '',
  })

  useEffect(() => {
    setFilteredData(
      data?.filter((item) => {
        if (filters.others.includes('Ativo') && !isActive(item)) return false

        // if (filters.others.includes('Inativo') && isActive(item)) return false

        if (filters.others.includes('Última atualização') && !isInLastUpdatedGroup(item))
          return false

        if (filters.others.includes('Itens não cadastrados nas grades') && isIncludedInGrid(item))
          return false

        return (
          // !(
          //   filters.brokers.length === 0 &&
          //   filters.modalities.length === 0 &&
          //   filters.grids.length === 0
          // ) && // TODO LUIZ obrigar a usar pelo menos 1 filtro, se não não retorna nenhum valor
          (filters.brokers.includes(item.nomeOperadora) || filters.brokers.length === 0) &&
          (filters.modalities.includes(item.nomeModalidade) || filters.modalities.length === 0) &&
          (filters.platforms.includes(item.nomePlataforma ? item.nomePlataforma : 'Direta') ||
            filters.platforms.length === 0) &&
          (filters.administrators.includes(
            item.nomeAdministradora ? item.nomeAdministradora : 'Direta',
          ) ||
            filters.administrators.length === 0) &&
          (filters.grids.includes(item.nomeGrade) || filters.grids.length === 0)
        )
      }),
    )
  }, [filters, data])

  const handleChangeFilters = (type: keyof typeof filters, filter: string) => {
    setFilters((bkp) => ({
      ...bkp,
      [type]: toggleFilter(type, filter),
    }))
  }

  const toggleFilter = (type: keyof typeof filters, filter: string) => {
    if (filters[type].includes(filter)) {
      return filters[type].filter((key) => key !== filter)
    } else {
      return [...filters[type], filter]
    }
  }

  const handleResetFilter = (type: keyof typeof filters) => {
    setFilters((bkp) => ({
      ...bkp,
      [type]: [],
    }))
  }

  const filterNames = {
    brokers: 'Operadora',
    modalities: 'Modalidade',
    grids: 'Grade',
    platforms: 'Plataforma',
    administrators: 'Administradora',
    others: 'Outro',
  }

  const getTranslatedFilterName = (type: string) => {
    return filterNames[type]
  }

  const loadOptionsBrokers = (data: GridItem[]): string[] => {
    const brokersList = data.map((data) => data.nomeOperadora)
    return brokersList
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .sort((a, b) => a.localeCompare(b))
  }

  const loadModalities = (data: GridItem[]): string[] => {
    const modalityList = data.map((item) => item.nomeModalidade)
    return modalityList
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .sort((a, b) => a.localeCompare(b))
  }

  const loadGrids = (data: GridItem[]): string[] => {
    const gridsList = data.map((item) => item.nomeGrade)
    return gridsList
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .sort((a, b) => a.localeCompare(b))
  }

  const loadPlatforms = (data: GridItem[]): string[] => {
    const platformsList = data.map((item) => (item.nomePlataforma ? item.nomePlataforma : 'Direta'))
    return platformsList
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .sort((a, b) => a.localeCompare(b))
  }

  const loadAdministrators = (data: GridItem[]): string[] => {
    const administratorsList = data.map((item) =>
      item.nomeAdministradora ? item.nomeAdministradora : 'Direta',
    )
    return administratorsList
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .sort((a, b) => a.localeCompare(b))
  }

  const loadOthers = (): string[] => {
    return ['Ativo', 'Itens não cadastrados nas grades', 'Última atualização'].sort((a, b) =>
      a.localeCompare(b),
    )
  }

  const lastUpdateDate = useMemo(() => {
    if (data.length === 0) return new Date()
    const maxDate = data
      .map((item) => item.createdAt)
      .reduce((a, b) => (dayjs(b).isBefore(dayjs(a)) ? a : b))

    return maxDate
  }, [data])

  const isInLastUpdatedGroup = (item: GridItem) => {
    return dayjs(item.createdAt).isSame(lastUpdateDate, 'day')
  }

  const isIncludedInGrid = (item: GridItem) => {
    return item.inicioVigencia
  }

  const isActive = (item: GridItem) => {
    // Nunca foi incluido na grade
    if (!item.inicioVigencia) return false

    // Foi incluido mas com uma data futura então será ativo SOMENTE no FUTURO
    if (dayjs(item.inicioVigencia).isAfter(dayjs(), 'day')) return false

    // Verificar se o fim da vigencia não chegou, se chegou já era também
    if (item.fimVigencia && dayjs(item.fimVigencia).isBefore(dayjs(), 'day')) return false

    return true
  }

  const handleChangeSearch = (key: keyof FiltersSearch, value: string) => {
    setSearch({
      brokers: '',
      modalities: '',
      grids: '',
      platforms: '',
      administrators: '',
      others: '',
      [key]: value,
    })
  }

  const mountFilterItems = (data: GridItem[]): ItemProps[] => {
    const brokers = loadOptionsBrokers(data)
    const modalities = loadModalities(data)
    const grids = loadGrids(data)
    const platforms = loadPlatforms(data)
    const administrators = loadAdministrators(data)
    const others = loadOthers()

    return [
      {
        key: 'brokers',
        label: 'Operadoras',
        children: (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <SearchInput
              placeholder="Busque por operadoras"
              onChange={(value) => handleChangeSearch('brokers', value)}
              style={{ marginBottom: 12 }}
            ></SearchInput>
            {brokers
              .filter((item) => item.toLowerCase().includes(search.brokers.toLowerCase()))
              .map((broker) => (
                <CheckBox
                  key={broker}
                  label={broker}
                  value={broker}
                  checked={filters.brokers.includes(broker)}
                  onChange={() => handleChangeFilters('brokers', broker)}
                />
              ))}
          </div>
        ),
      },
      {
        key: 'administrators',
        label: 'Administradoras',
        children: (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <SearchInput
              placeholder="Busque por administradoras"
              onChange={(value) => handleChangeSearch('administrators', value)}
              style={{ marginBottom: 12 }}
            ></SearchInput>
            {administrators
              .filter((item) => item.toLowerCase().includes(search.administrators.toLowerCase()))
              .map((broker) => (
                <CheckBox
                  key={broker}
                  label={broker}
                  value={broker}
                  checked={filters.administrators.includes(broker)}
                  onChange={() => handleChangeFilters('administrators', broker)}
                />
              ))}
          </div>
        ),
      },
      {
        key: 'modalities',
        label: 'Modalidades',
        children: (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <SearchInput
              placeholder="Busque por modalidades"
              onChange={(value) => handleChangeSearch('modalities', value)}
              style={{ marginBottom: 12 }}
            ></SearchInput>
            {modalities
              .filter((item) => item.toLowerCase().includes(search.modalities.toLowerCase()))
              .map((modality) => (
                <CheckBox
                  key={modality}
                  label={modality}
                  value={modality}
                  checked={filters.modalities.includes(modality)}
                  onChange={() => handleChangeFilters('modalities', modality)}
                />
              ))}
          </div>
        ),
      },
      {
        key: 'grids',
        label: 'Grades',
        children: (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <SearchInput
              placeholder="Busque por grades"
              onChange={(value) => handleChangeSearch('grids', value)}
              style={{ marginBottom: 12 }}
            ></SearchInput>
            {grids
              .filter((item) => item.toLowerCase().includes(search.grids.toLowerCase()))
              .map((grid) => (
                <CheckBox
                  key={grid}
                  label={grid}
                  value={grid}
                  checked={filters.grids.includes(grid)}
                  onChange={() => handleChangeFilters('grids', grid)}
                />
              ))}
          </div>
        ),
      },
      {
        key: 'platforms',
        label: 'Plataformas',
        children: (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <SearchInput
              placeholder="Busque por plataformas"
              onChange={(value) => handleChangeSearch('platforms', value)}
              style={{ marginBottom: 12 }}
            ></SearchInput>
            {platforms
              .filter((item) => item?.toLowerCase().includes(search.platforms.toLowerCase()))
              .map((grid) => (
                <CheckBox
                  key={grid}
                  label={grid}
                  value={grid}
                  checked={filters.platforms.includes(grid)}
                  onChange={() => handleChangeFilters('platforms', grid)}
                />
              ))}
          </div>
        ),
      },
      {
        key: 'others',
        label: 'Outros',
        children: (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <SearchInput
              placeholder="Busque por outros"
              onChange={(value) => handleChangeSearch('others', value)}
              style={{ marginBottom: 12 }}
            ></SearchInput>
            {others
              .filter((item) => item.toLowerCase().includes(search.others.toLowerCase()))
              .map((other) => (
                <CheckBox
                  key={other}
                  label={other}
                  value={other}
                  checked={filters.others.includes(other)}
                  onChange={() => handleChangeFilters('others', other)}
                />
              ))}
          </div>
        ),
      },
    ]
  }

  return (
    <>
      <FilterButton
        onClick={(e) => {
          e.preventDefault()
          setOpenFilter(!openFilter)
        }}
        isActive={openFilter}
      >
        <FilterOutlined style={{ color: `${openFilter ? '#FFF' : '#4B4B4B'}` }} />
        Filtrar
      </FilterButton>

      {openFilter && (
        <FilterDiv>
          <Filter items={mountFilterItems(data)}></Filter>
        </FilterDiv>
      )}

      {Object.keys(filters).map((key) => {
        const value = filters[key]
        if (Array.isArray(value) && value.length > 0) {
          return (
            <SelectedFilters
              key={key}
              onClick={() => handleResetFilter(key as keyof typeof filters)}
            >
              {getTranslatedFilterName(key)}
              <CloseOutlined style={{ marginLeft: '5px', color: '#878EA6' }} />
            </SelectedFilters>
          )
        }
        return null
      })}
    </>
  )
}
