/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'

import { FileDoneOutlined } from '@ant-design/icons'
import type { Document } from 'design-system/DocumentTable/DocumentTable'
import { OnFileUploadInput } from 'design-system/Upload/Upload'
import { Button, DocumentTable, Toast } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { HealthPlansOrdersRequests } from '@/App/clients/healthPlansOrders'
import { DocumentDto } from '@/App/clients/healthPlansOrders/dtos'
import { useOrderInformationQuery } from '@/App/clients/healthPlansOrders/queries/orderInformation'
import { useFetchParallel } from '@/App/clients/http'
import type { UseFetchConfig } from '@/App/clients/http/interface'
import { useUpdateStatus } from '@/App/clients/orderState/mutation/updateStatus'

import {
  ButtonDiv,
  CancelButton,
  ModalContent,
  ModalSubTitle,
  ModalTitle,
  StyledTag,
  TagContainer,
} from './style'

interface BillPendingModalProps {
  setIsModalOpen?: (value: boolean) => void
  orderId?: string
  setShouldReload?: React.Dispatch<React.SetStateAction<boolean>>
  setOrderStatus?: React.Dispatch<React.SetStateAction<string | null>>
}

interface UploadedFiles {
  fileContent: Blob
  fileContentType: string
  fileName: string
  fileId: string
}

export const BillPendingModal: React.FC<BillPendingModalProps> = ({
  setIsModalOpen,
  orderId,
  setShouldReload,
  setOrderStatus,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFiles[]>([])
  const [billName, setBillName] = useState<string | null | undefined>()
  const [signedOrderName, setSignedOrderName] = useState<string | null | undefined>()

  const orderInformationQuery = useOrderInformationQuery({ orderId })
  const updateStatusMutation = useUpdateStatus(orderId)

  const documents: Array<Document> = [
    {
      id: '1',
      status: 'PENDING',
      type: 'Boleto',
      fileName: billName,
    },
    {
      id: '2',
      status: 'PENDING',
      type: 'Proposta assinada',
      fileName: signedOrderName,
    },
  ]

  const {
    setRequestConfig: setUploadFileRequest,
    isLoading: isUploading,
    error: uploadError,
    statusCode: uploadStatus,
    data: uploadFileData,
  } = useFetchParallel<{ uploadURL?: string }>()

  const {
    setRequestConfig: setUploadFileByUploadURLRequestConfig,
    statusCode: uploadFileByUploadURLStatusCodes,
    isLoading: isLoadingUploadFileByUploadURL,
  } = useFetchParallel()

  useEffect(() => {
    if (uploadError) triggerToast(false, 'Erro ao enviar o arquivo')
    if (updateStatusMutation.isError) triggerToast(false, 'Erro ao atualizar o status')
  }, [uploadError, updateStatusMutation.isError])

  useEffect(() => {
    if (updateStatusMutation.isSuccess) {
      setOrderStatus && setOrderStatus('BILL_PAYMENT_PENDING')
      !billName && !signedOrderName && setIsModalOpen && setIsModalOpen(false)
    }
  }, [updateStatusMutation.isSuccess])

  useEffect(() => {
    if (!uploadStatus) return
    const [responseStatusCode] = uploadStatus

    if (responseStatusCode === 200) {
      const uploadRequestConfigs = uploadedFiles
        .map((_file, index) => {
          if (uploadFileData[index].uploadURL) {
            return HealthPlansOrdersRequests.uploadOrderDocumentByUploadURL(
              uploadFileData[index].uploadURL as string,
              uploadedFiles[index].fileContent,
              uploadedFiles[index].fileContentType,
            )
          }
        })
        .filter((file) => file != null) as Array<UseFetchConfig>
      setUploadFileByUploadURLRequestConfig(uploadRequestConfigs)
    }
  }, [uploadStatus])

  useEffect(() => {
    if (!uploadFileByUploadURLStatusCodes) return
    const index = uploadFileByUploadURLStatusCodes.length - 1

    if (uploadFileByUploadURLStatusCodes[index] === 200) {
      setIsModalOpen && setIsModalOpen(false)
      setBillName(null)
      setSignedOrderName(null)
      triggerToast(true, 'Arquivo(s) enviado(s) com sucesso!')
      if (setShouldReload) setShouldReload(true)
    }

    if (uploadFileByUploadURLStatusCodes[index] > 400) {
      triggerToast(false, 'Ops, ocorreu um erro ao enviar o arquivo')
    }
  }, [uploadFileByUploadURLStatusCodes])

  const customUploadRequest = () => {
    const uploadFileRequestConfigs = uploadedFiles.map((file) => {
      const type = file.fileId === '1' ? 'BILL' : 'SIGNED_ORDER'
      const formData = new FormData()
      formData.append('fileName', file.fileName)
      formData.append('fileType', type)

      return HealthPlansOrdersRequests.uploadOrderDocument(formData, orderId)
    })

    setUploadFileRequest(uploadFileRequestConfigs)
  }

  const onFileUploadFiles = (document: DocumentDto, file: OnFileUploadInput) => {
    const fileData = {
      fileContent: file.fileContent,
      fileContentType: file.fileContentType,
      fileName: file.fileName,
      fileId: document.id,
    }

    document.id === '1' ? setBillName(file.fileName) : setSignedOrderName(file.fileName)

    const uploadedList = [...uploadedFiles, fileData].filter(
      (file) => file != null,
    ) as Array<UploadedFiles>
    setUploadedFiles(uploadedList)
  }

  const deleteDocument = (documentId: string) => {
    const filteredList = uploadedFiles.filter((file) => file.fileId !== documentId)
    setUploadedFiles(filteredList)

    documentId === '1' ? setBillName(undefined) : setSignedOrderName(undefined)
  }

  const updateStatusRequest = async () => {
    if (orderId) {
      await updateStatusMutation.mutateAsync({
        nextStatus: 'BILL_PAYMENT_PENDING',
        order: orderInformationQuery?.data,
      })
    }
  }

  const handleClick = () => {
    if (billName || signedOrderName) customUploadRequest()
    updateStatusRequest()
  }

  return (
    <ModalContent>
      <FileDoneOutlined style={{ fontSize: '40px' }} />
      <ModalTitle>Anexe os arquivos:</ModalTitle>
      <TagContainer>
        <StyledTag>Boleto</StyledTag>
        <StyledTag>Proposta assinada</StyledTag>
      </TagContainer>

      <DocumentTable
        data={documents ?? []}
        firstColumn="type"
        onAttachmentClick={(document, file) =>
          onFileUploadFiles(document as DocumentDto, file as OnFileUploadInput)
        }
        onDeleteClick={deleteDocument}
        isExternal={true}
      />

      <ModalSubTitle>
        Assim que você clicar em enviar, notificaremos o corretor sobre o boleto pendente de
        pagamento.
      </ModalSubTitle>
      <ButtonDiv>
        <Button
          loading={isLoadingUploadFileByUploadURL || isUploading || updateStatusMutation.isPending}
          onClick={handleClick}
        >
          Salvar e enviar
        </Button>
      </ButtonDiv>
      <CancelButton onClick={() => setIsModalOpen && setIsModalOpen(false)}>Cancelar</CancelButton>
      <Toast />
    </ModalContent>
  )
}
