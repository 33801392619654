import React from 'react'

import { AutoComplete } from 'antd'

import { StyledSearch } from './style'

export interface Props {
  placeholder: string
  options?: { label?: string; value?: string }[]
  onSearch?: (
    value: string,
    event?:
      | React.ChangeEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void
  onSelect?: (_value, option) => void
  onChange?: (value: string) => void
  style?: React.CSSProperties
  name?: string
}

const SearchInput: React.FC<Props> = ({
  placeholder,
  onSearch,
  style,
  options,
  onSelect,
  onChange,
  name,
}) => {
  const renderItem = (value?: string, label?: string) => {
    return { value: label, label, reference: value, key: value }
  }

  const renderOptions = options?.map((item) => renderItem(item.value, item.label))

  return (
    <AutoComplete
      onChange={onChange}
      options={renderOptions}
      filterOption={(inputValue, option) =>
        option?.value?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }
      onSelect={onSelect}
    >
      <StyledSearch
        placeholder={placeholder}
        allowClear
        onSearch={onSearch}
        name={name}
        style={style}
      />
    </AutoComplete>
  )
}

export default SearchInput
