import styled from 'styled-components'
import { colors } from '../../styles/theme'
import { Switch as AntdSwitch } from 'antd'

export interface StyledProps {
  color?: string
}

export const SwitchStyled = styled(AntdSwitch)<StyledProps>`
  &.ant-switch-checked {
    background-color: ${(props) => (props.color ? props.color : colors.purple)};
  }

  &.ant-switch-checked:hover:enabled {
    background-color: ${(props) => (props.color ? props.color : colors.purple)};
  }
`
