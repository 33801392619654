/* eslint-disable react/prop-types */
import { useEffect, useMemo, useState } from 'react'

import { Button, Select, TextAreaInput, Toast } from 'design-system/components'
import Exclamation from 'design-system/exclamation'
import { triggerToast } from 'design-system/triggers'

import { useFillOrderInformation } from '@/App/clients/healthPlansOrders/mutations/fillOrderInformation'
import { useOrderInformationQuery } from '@/App/clients/healthPlansOrders/queries/orderInformation'
import { useUpdateStatus } from '@/App/clients/orderState/mutation/updateStatus'

import { ButtonDiv, CancelButton, ModalContent, ModalTitle } from './style'

const riskDimensionOptions = [
  {
    label: 'Corretor - Referências Externas',
    value: 'Corretor - Referências Externas',
  },
  {
    label: 'Corretor - Dados internos',
    value: 'Corretor - Dados internos',
  },
  {
    label: 'Entidade Legal (CNPJ)',
    value: 'Entidade Legal (CNPJ)',
  },
  {
    label: 'Beneficiários',
    value: 'Beneficiários',
  },
  {
    label: 'Potencial Inadimplência',
    value: 'Potencial Inadimplência',
  },
  {
    label: 'Histórico de alto custo',
    value: 'Histórico de alto custo',
  },
]

const riskItemOptions = [
  {
    label: 'Corretor com propostas negadas',
    value: 'Corretor com propostas negadas',
  },
  {
    label: 'Redlist Bliss',
    value: 'Redlist Bliss',
  },
  {
    label: 'Background Check Corretor',
    value: 'Background Check Corretor',
  },
  {
    label: 'Redlist Operadoras',
    value: 'Redlist Operadoras',
  },
  {
    label: 'CNPJ Inativo',
    value: 'CNPJ Inativo',
  },
  {
    label: 'Data de criação do CNPJ',
    value: 'Data de criação do CNPJ',
  },
  {
    label: 'Patrimônio Líquido',
    value: 'Patrimônio Líquido',
  },

  {
    label: 'Vinculo trabalhista',
    value: 'Vinculo trabalhista',
  },

  {
    label: 'Vinculo familiar',
    value: 'Vinculo familiar',
  },

  {
    label: 'Email dos beneficiários',
    value: 'Email dos beneficiários',
  },

  {
    label: 'Comprovação societária',
    value: 'Comprovação societária',
  },

  {
    label: 'Comprovação plano anterior',
    value: 'Comprovação plano anterior',
  },

  {
    label: 'Beneficiários ativos na carteira',
    value: 'Beneficiários ativos na carteira',
  },

  {
    label: 'Presença em redes sociais',
    value: 'Presença em redes sociais',
  },

  {
    label: 'Modalidade do cnpj e tipo de plano',
    value: 'Modalidade do cnpj e tipo de plano',
  },

  {
    label: 'Localização empresa e funcionários',
    value: 'Localização empresa e funcionários',
  },
  {
    label: 'Saldo devedor com operadora',
    value: 'Saldo devedor com operadora',
  },

  {
    label: 'Score de crédito da empresa',
    value: 'Score de crédito da empresa',
  },

  {
    label: 'Score de crédito Sócio',
    value: 'Score de crédito Sócio',
  },
  {
    label: 'Quantidade de Reembolsos',
    value: 'Quantidade de Reembolsos',
  },
  {
    label: 'Desinteresse comercial operadoras',
    value: 'Desinteresse comercial operadoras',
  },
  {
    label: 'Outros',
    value: 'Outros',
  },
]

interface RiskAnalysisModalProps {
  setIsModalOpen?: (value: boolean) => void
  orderId?: string
  setShouldReload?: React.Dispatch<React.SetStateAction<boolean>>
  setOrderStatus?: React.Dispatch<React.SetStateAction<string | null>>
  isManualDetection?: boolean
}

export const RiskAnalysisModal: React.FC<RiskAnalysisModalProps> = ({
  setIsModalOpen,
  orderId,
  setShouldReload,
  setOrderStatus,
  isManualDetection = false,
}) => {
  const updateStatusMutation = useUpdateStatus(orderId)
  const fillOrderInformationRequest = useFillOrderInformation()
  const orderInformationQuery = useOrderInformationQuery({ orderId })
  const [statusUpdateMetadata, setStatusUpdateMetadata] = useState({
    riskItemIndicator: '',
    riskDimensionIndicator: '',
    riskDescription: '',
  })

  const updateStatus = useMemo(() => {
    if (isManualDetection) {
      return fillOrderInformationRequest
    }

    return updateStatusMutation
  }, [isManualDetection, updateStatusMutation, fillOrderInformationRequest])

  const isFormValid =
    statusUpdateMetadata.riskItemIndicator &&
    statusUpdateMetadata.riskDimensionIndicator &&
    statusUpdateMetadata.riskDescription.length > 5

  useEffect(() => {
    if (updateStatus.isSuccess) {
      setOrderStatus && setOrderStatus('RISK_ANALYSIS')
      setIsModalOpen && setIsModalOpen(false)
      if (setShouldReload) setShouldReload(true)
      triggerToast(true, 'Status atualizado com sucesso')
    } else if (updateStatus.isError) {
      triggerToast(false, 'Erro ao atualizar o status')
    }
  }, [updateStatus.isError, updateStatus.isSuccess])

  const updateStatusRequest = async () => {
    if (orderId) {
      const payload = isManualDetection
        ? {
            orderId,
            statusUpdateMetadata,
            isRiskDetected: true,
          }
        : {
            orderId,
            nextStatus: 'RISK_ANALYSIS',
            order: orderInformationQuery?.data,
            status: 'RISK_ANALYSIS',
            statusUpdateMetadata,
          }

      await updateStatus.mutateAsync(payload)
    }
  }

  const handleChange = (key: string, value: string) => {
    setStatusUpdateMetadata((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  return (
    <ModalContent>
      <Exclamation />
      <ModalTitle>Risco identificado na proposta?</ModalTitle>

      <Select
        options={riskDimensionOptions}
        placeholder="Selecione a dimensão*"
        onChange={(value) => handleChange('riskDimensionIndicator', value as string)}
      />
      <Select
        options={riskItemOptions}
        placeholder="Selecione o item de verificação*"
        onChange={(value) => handleChange('riskItemIndicator', value as string)}
      />
      <TextAreaInput
        placeholder="Descreva o risco identificado*"
        rows={4}
        key="1"
        maxCharactersQuantity={1000}
        onChange={(event) => handleChange('riskDescription', event.target.value)}
      />

      <ButtonDiv>
        <Button
          htmlType="submit"
          loading={updateStatus.isPending}
          onClick={updateStatusRequest}
          disabled={!isFormValid}
        >
          Salvar e enviar
        </Button>
        <CancelButton onClick={() => setIsModalOpen && setIsModalOpen(false)}>
          Cancelar
        </CancelButton>
      </ButtonDiv>
      <Toast />
    </ModalContent>
  )
}
