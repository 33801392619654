import styled from 'styled-components'

export const DispatchEntityFooterContainer = styled.div`
  border-top: 1px solid #dcdfeb;
  margin-top: 16px;
  padding-top: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DispatchEntityEventContainer = styled.div<{ processingStatus?: string | null }>`
  flex: 1;
  display: flex;
  gap: 8px;
  align-items: ${(props) => (props.processingStatus === 'FAILURE' ? 'start' : 'center')};
`

export const DispatchEventTitle = styled.span`
  color: #485964;
  font-size: 10px;
  font-weight: 600;
`

export const DispatchEventSubtitleStatus = styled(DispatchEventTitle)`
  color: #879dac;
`

export const DispatchEntitySwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 12px;
`

export const DispatchEntitySwitchLabel = styled.label`
  font-family: 'Manrope';
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  color: #4b4b4b;
`
