import styled from 'styled-components'

import { Button } from '../components'

export interface StyledButtonProps {
  mobileActionButtonsPosition: 'bottomOfScreen' | 'afterContent'
}

export const StepsContainer = styled.div`
  display: grid;
  grid-template-columns: 0fr auto;
  height: 100%;
  width: 100vw;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: auto;
  }
`

export const ButtonPrevious = styled(Button)`
  border: 5px solid #b7c8d5 !important;
  color: #4b4b4b;
  padding: 1em;
  width: 150px;
`

export const ButtonNext = styled(Button)`
  padding: 1em;
  background-color: #53154e;
  width: 150px;
`

export const StyledStepContent = styled.div<StyledButtonProps>`
  transition: 1.4s;
  width: 100%;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: ${(props) => props.mobileActionButtonsPosition === 'bottomOfScreen' && '100%'};
  }
`

export const ActionButtonsDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 40px;
`

export const StepsActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  width: 380px;
  .anticon {
    color: 'white';
  }
`

export const ActionButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  gap: 15px;
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
`

export const StepsEntries = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f6f9;
  box-shadow: 0px 4px 10px 0px #373b6226;
  border-radius: 10px;
  width: 22vw;
  height: 100%;

  @media screen and (max-width: 768px) {
    width: 100%;
    border-radius: 0;
    justify-content: center;
    background-color: white;
    box-shadow: none;
    height: auto;
  }
`

export const UlStyledDesktop = styled.ul`
  padding-left: 0px;
  li {
    list-style: none;
    padding-left: 10%;
    padding-right: 10%;
  }
  li div {
    list-style: none;
    display: flex;
    justify-content: start;
    color: red;
  }
`

export const StyledLi = styled.li<{ isActive: boolean }>`
  background: ${(props) => (props.isActive ? '#DCDFEB' : '')};
  transition: 0.5s;

  div p:nth-child(1) {
    font-size: ${(props) => (props.isActive ? '17px' : '16px')};
    font-weight: ${(props) => (props.isActive ? '700' : '700')};
    color: ${(props) => (props.isActive ? '#4b4b4b' : '#4b4b4b')};
    transition: 0.5s;
  }

  div p:nth-child(2) {
    font-size: ${(props) => (props.isActive ? '17px' : '16px')};
    color: ${(props) => (props.isActive ? '#4b4b4b' : '#8589a2')};
    font-weight: ${(props) => (props.isActive ? '700' : '600')};
    padding-left: ${(props) => (props.isActive ? '10px' : '10px')};
    transition: 0.5s;
  }

  div p:nth-child(3) {
    color: ${(props) => (props.isActive ? '#53154E' : '')};
    font-size: ${(props) => (props.isActive ? '17px' : '0px')};
    transition: 0.5s;
    padding-left: ${(props) => (props.isActive ? '10%' : '10px')};
  }
`

export const StepsEntriesHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  row-gap: 10px;
  padding-top: 6%;

  @media screen and (max-width: 768px) {
    padding-left: 5%;
    padding-top: 3%;
    row-gap: 4px;
  }
`
export const Line = styled.span`
  width: 95%;
  border: 1px solid #dcdfeb;
`

export const Title = styled.div`
  color: #4b4b4b;
  font-size: 20px;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

export const Description = styled.div`
  color: #878da1;
  font-size: 14px;
  font-weight: 600;
  padding-right: 10%;
`

export const StyledNumber = styled.div`
  border-radius: 50px;
  font-size: 10px;
  font-weight: 700;
`

export const UlStiledMobile = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 50%;
  padding: 20px;
`
export const StyledLiMobile = styled.li<{ isActive: boolean }>`
  background: ${(props) => (props.isActive ? '#DCDFEB' : '')};
  border: ${(props) => (props.isActive ? 'none' : '1px solid #B7C8D5')};
  border-radius: 20px;
  list-style: none;
  padding: 5px 12px;
  transition: 0.5s;
  div {
    color: ${(props) => (props.isActive ? '#4b4b4b' : '#878DA1')};
  }
`

export const LabelStepMobile = styled.div`
  background-color: #e8eaf2;
  border-radius: 50px;
  color: #4b4b4b;
  font-size: 12px;
  font-weight: 700;
  margin: 0 auto;
  padding: 10px 20px;
`

export const DivIconMobile = styled.div`
  align-items: center;
  display: flex;
  height: 17px;
  justify-content: center;
  width: 17px;
`
export const ActionButtonsDivMobile = styled.div<StyledButtonProps>`
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 20px 0 20px 0;
  margin: ${(props) => props.mobileActionButtonsPosition === 'bottomOfScreen' && 'auto 0 0 20px'};
`
