import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { Modal, Table } from 'design-system/components'

import WaitingPeriodForm, { WaitingPeriodFormData } from './Form'

const fetchWaitingPeriods = async () => {
  const { data } = await axios.get(`${import.meta.env.VITE_WAITING_PERIOD_API_URL}`)
  return data
}

const WaitingPeriod = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<WaitingPeriodFormData | null>(null)

  const { data, error, isLoading } = useQuery({
    queryKey: ['waitingPeriods'],
    queryFn: fetchWaitingPeriods,
  })

  const columns = [
    { title: 'ID', dataIndex: 'unique_id', key: 'unique_id' },
    {
      title: 'Procedimentos de Alta Complexidade',
      dataIndex: 'procedimentos_alta_complexidade',
      key: 'procedimentos_alta_complexidade',
    },
    {
      title: 'Condições Preexistentes',
      dataIndex: 'condicoes_preexistentes',
      key: 'condicoes_preexistentes',
    },
    { title: 'Internações', dataIndex: 'internaces', key: 'internaces' },
    { title: 'Urgência/Emergência', dataIndex: 'urgencia_emergencia', key: 'urgencia_emergencia' },
    { title: 'Parto', dataIndex: 'parto', key: 'parto' },
    { title: 'Consultas', dataIndex: 'consultas', key: 'consultas' },
    {
      title: 'Exames Especiais Complexos',
      dataIndex: 'exames_especiais_complexos',
      key: 'exames_especiais_complexos',
    },
    { title: 'Exames Simples', dataIndex: 'exames_simples', key: 'exames_simples' },
    { title: 'Terapias', dataIndex: 'terapias', key: 'terapias' },
    { title: 'Administradora', dataIndex: 'administradora', key: 'administradora' },
  ]

  if (error) return <div>Erro ao carregar dados</div>

  const handleRowClick = (record) => {
    setSelectedItem(record)
    setIsOpen(true)
  }

  const handleModal = () => {
    setIsOpen(!isOpen)
    setSelectedItem(null)
  }
  return (
    <>
      <Table
        columns={columns}
        data={data}
        loading={isLoading}
        onRowClick={(record) => handleRowClick(record)}
      />

      <Modal isOpen={isOpen} setIsOpen={handleModal} footer={false}>
        <WaitingPeriodForm defaultValues={selectedItem} key={selectedItem?.unique_id} />
      </Modal>
    </>
  )
}

export default WaitingPeriod
