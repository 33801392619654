import { useEffect, useState } from 'react'

import { CheckOutlined, EditFilled } from '@ant-design/icons'
import { colors } from '@design-system/styles/theme'
import { UseQueryResult } from '@tanstack/react-query'
import { Button, SwitchButton } from 'design-system/components'
import DentalIcon from 'design-system/dental'
import HealthIcon from 'design-system/health'
import PetIcon from 'design-system/pet'
import { triggerToast } from 'design-system/triggers'

import { HealthPlansOrdersRequests } from '@/App/clients/healthPlansOrders'
import { GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import { useFillOrderInformation } from '@/App/clients/healthPlansOrders/mutations/fillOrderInformation'
import type { UseFetchConfig } from '@/App/clients/http/interface'

import {
  EditInsurerIdButton,
  FillInsurerIdInput,
  FillInsurerIdInputDiv,
  Footer,
  IconsDiv,
  PlanCard,
  PlanCardTitle,
  SaveInsurerIdButtonDiv,
  StyledContainerDetailsProposal,
  StyledLi,
  StyledSpan,
  StyledSpanLi,
  StyledTextInput,
  StyledUl,
} from './style'

interface PlanCardProps {
  setShouldReload?: React.Dispatch<React.SetStateAction<boolean>>
  setFillIsRegisteredInInternalSystemRequestConfig?: React.Dispatch<
    React.SetStateAction<UseFetchConfig | undefined>
  >
  isRegisteredInInternalSystem?: boolean
  setIsRegisteredInInternalSystem?: React.Dispatch<React.SetStateAction<boolean | undefined>>
  isLoadingFillIsRegisteredInInternalSystem?: boolean
  orderInformationQuery: UseQueryResult<GetOrderInformationBody, Error>
}
export const PlanCardComponent: React.FC<PlanCardProps> = ({
  setFillIsRegisteredInInternalSystemRequestConfig,
  isRegisteredInInternalSystem,
  setIsRegisteredInInternalSystem,
  isLoadingFillIsRegisteredInInternalSystem,
  orderInformationQuery,
}) => {
  const [isEditingInsurerId, setIsEditingInsurerId] = useState(false)
  const [insurerId, setInsurerId] = useState<string | null>(null)

  const fillInformationRequest = useFillOrderInformation()
  const orderData = orderInformationQuery?.data

  const fillInsurerId = () => {
    fillInformationRequest.mutateAsync({
      orderId: orderData?.id,
      insurerId: insurerId,
    })
  }

  const toggleIsRegisteredInInternalSystem = () => {
    if (orderData?.id) {
      const fillOrderInformationRequestConfig = HealthPlansOrdersRequests.fillOrderInformation(
        { isRegisteredInInternalSystem: !isRegisteredInInternalSystem },
        orderData?.id,
      )
      setFillIsRegisteredInInternalSystemRequestConfig &&
        setFillIsRegisteredInInternalSystemRequestConfig(fillOrderInformationRequestConfig)

      setIsRegisteredInInternalSystem &&
        setIsRegisteredInInternalSystem(!isRegisteredInInternalSystem)
    }
  }

  const getQuotationIcon = (quotationProduct?: string) => {
    switch (quotationProduct) {
      case 'HEALTH':
        return <HealthIcon />
      case 'DENTAL':
        return <DentalIcon />
      case 'PET':
        return <PetIcon />
      case 'HEALTH_AND_DENTAL':
        return (
          <IconsDiv>
            <HealthIcon />
            <DentalIcon />
          </IconsDiv>
        )
    }
  }

  const allowedInsurers = import.meta.env.VITE_ORDER_DISPATCH_INSURER_ALLOWED

  const insurerCheck = allowedInsurers.includes(orderData?.quotation?.insurer)

  useEffect(() => {
    if (orderData != null) {
      setInsurerId(orderData.insurerId ?? null)
    }
  }, [orderData])

  useEffect(() => {
    if (fillInformationRequest.isSuccess) {
      orderInformationQuery.refetch()
      triggerToast(true, 'Atualização realizada com sucesso!')
      setIsEditingInsurerId(false)
    }
    if (fillInformationRequest.isError) {
      triggerToast(false, 'Ops, ocorreu um erro ao realizar a atualização!')
    }
  }, [fillInformationRequest.isSuccess, fillInformationRequest.isError])
  return (
    <StyledContainerDetailsProposal>
      <PlanCard>
        {getQuotationIcon(orderData?.quotation?.product)}
        {(!insurerCheck || orderData?.quotation?.type !== 'COMPANY') && (
          <PlanCardTitle>{orderData?.quotation?.plan ?? 'Sem plano preenchido'}</PlanCardTitle>
        )}
        <StyledUl>
          <StyledLi>{orderData?.quotation?.insurer ?? 'Sem operadora preenchida'}</StyledLi>
          <StyledLi>
            {orderData?.lifesQuantity ?? 0} vida
            {(orderData?.lifesQuantity && orderData?.lifesQuantity > 1) ||
            orderData?.lifesQuantity == null
              ? 's'
              : ''}
          </StyledLi>
          <StyledLi>
            Valor total:
            <StyledSpanLi>
              {orderData?.quotation?.totalAmount != null
                ? orderData?.quotation?.totalAmount.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : orderData?.totalAmount != null
                ? orderData?.totalAmount.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : ' - '}
            </StyledSpanLi>
          </StyledLi>
        </StyledUl>
      </PlanCard>
      <Footer>
        <StyledSpan>
          Cadastrada no Sisweb:
          <SwitchButton
            isLoading={isLoadingFillIsRegisteredInInternalSystem}
            checked={isRegisteredInInternalSystem}
            onChange={toggleIsRegisteredInInternalSystem}
            color="#514984"
          />
        </StyledSpan>
        {isEditingInsurerId ? (
          <>
            <FillInsurerIdInputDiv>
              <StyledSpan>Nº da propostass na operadora</StyledSpan>
              <FillInsurerIdInput
                type="text"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                onChange={(event) =>
                  setInsurerId(event.target.value.replaceAll(/[^A-Za-z0-9_-]/g, ''))
                }
                value={insurerId ?? undefined}
              />
              <SaveInsurerIdButtonDiv>
                <Button
                  height="30px"
                  backgroundColor={colors.darkPurple}
                  loading={fillInformationRequest.isPending}
                  onClick={fillInsurerId}
                >
                  <CheckOutlined style={{ color: 'white' }} />
                </Button>
              </SaveInsurerIdButtonDiv>
            </FillInsurerIdInputDiv>
          </>
        ) : (
          <EditInsurerIdButton onClick={() => setIsEditingInsurerId(true)}>
            <StyledSpan>Nº da proposta na operadora:</StyledSpan>
            <StyledTextInput>{insurerId ?? '-'}</StyledTextInput>
            <EditFilled />
          </EditInsurerIdButton>
        )}
      </Footer>
    </StyledContainerDetailsProposal>
  )
}
