import styled from 'styled-components'

export interface CardProps {
  checked?: boolean
  height?: string
  width?: string
  border?: 'solid' | 'dashed'
  addCardHeight?: string
  addCardWidth?: string
}

export interface CheckedIconProps {
  checked?: boolean
}

export const CardsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`

export const Card = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  height: ${(props) => props.height ?? '220px'};
  width: ${(props) => props.width ?? '270px'};
  box-shadow: 0px 2px 10px rgba(17, 19, 47, 0.25);
  border-radius: 10px;
  cursor: pointer;
  border: ${(props) =>
    props.checked ? `2px solid #514984` : `2px ${props.border ?? 'transparent'} #B7C8D5`};
`
export const StyledAddableCard = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  height: ${(props) => props.addCardHeight ?? '220px'};
  width: ${(props) => props.addCardWidth ?? '270px'};
  box-shadow: 0px 2px 10px rgba(17, 19, 47, 0.25);
  border-radius: 10px;
  cursor: pointer;
  border: ${(props) =>
    props.checked ? `2px solid #514984` : `2px ${props.border ?? 'transparent'} #B7C8D5`};
`

export const CardContent = styled.div`
  width: 100%;
`

export const CheckedIcon = styled.div<CheckedIconProps>`
  display: flex;
  visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`
