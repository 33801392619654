import { ReactNode } from 'react'

import Tabs from '../Tabs/Tabs'
import { motion } from 'framer-motion'

import { StyledFilterMenu } from './style'

export interface ItemProps {
  key: string
  label: ReactNode
  children: ReactNode
}

export interface TabsProps {
  position?: 'top' | 'right' | 'bottom' | 'left'
  items: ItemProps[]
  itemsColor?: string
  zIndex?: string
}

const Filter = ({ items, position, itemsColor, zIndex = '1' }: TabsProps) => {
  return (
    <motion.div
      initial={{ scale: 0 }}
      animate={{ rotate: 0, scale: 1 }}
      transition={{
        type: 'spring',
        stiffness: 260,
        damping: 25,
      }}
      style={{ zIndex }}
    >
      <StyledFilterMenu>
        <Tabs position={position ?? 'left'} itemsColor={itemsColor ?? 'purple'} items={items} />
      </StyledFilterMenu>
    </motion.div>
  )
}

export default Filter
