import { useCallback } from 'react'

import { WidgetProps } from '@rjsf/utils'
import dayjs from 'dayjs'
import { DatePicker, Error } from 'design-system/components'
import styled, { css } from 'styled-components'

type FormItemProps = {
  colSpan?: string | number
}

export const FormItem = styled.div<FormItemProps>`
  ${({ colSpan = 1 }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 6.25rem;
    grid-column: span ${colSpan.toString()};
  `}
`

export const FormItemLabel = styled.label`
  font-weight: 500;
  color: #4b4b4b;
  text-align: start;
  margin: 0;
`

export function OCRCnpjDateInputWidget({
  label,
  defaultValue,
  value,
  onChange,
  required,
  rawErrors,
  formContext,
  disabled,
  ...rest
}: WidgetProps) {
  const transformedDefaultValue = defaultValue ? dayjs(defaultValue as string).toDate() : null
  const transformedValue = value ? dayjs(value as string).toDate() : null

  const error = rawErrors
    ? {
        type: 'unknown',
        message: rawErrors.at(0),
      }
    : null

  const handleChange = useCallback(
    (date: dayjs.Dayjs) => {
      return onChange(date?.format('YYYY-MM-DD'))
    },
    [onChange],
  )

  return (
    <FormItem>
      <FormItemLabel>
        {label}
        {required ? '*' : ''}
      </FormItemLabel>
      <DatePicker
        value={transformedValue || transformedDefaultValue}
        onChange={handleChange}
        disabled={!formContext?.isCnpjFilled || disabled || false}
        {...rest}
      />
      {error && <Error error={error} />}
    </FormItem>
  )
}
