import { useEffect, useState } from 'react'

import { Skeleton } from 'antd'
import { OnFileUploadInput } from 'design-system/UploadArea/UploadArea'
import { DocumentTable, UploadArea } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { healthPlansOrderRequest } from '@/App/clients/healthPlansOrder'
import { DeleteDocumentFileFromOrderDto, GetOrdersById } from '@/App/clients/healthPlansOrder/dto'
import { HealthPlansOrdersRequests } from '@/App/clients/healthPlansOrders'
import { DocumentDto } from '@/App/clients/healthPlansOrders/dtos'
import useFetch, { useFetchParallel } from '@/App/clients/http'
import type { UseFetchConfig } from '@/App/clients/http/interface'

import { FilesContainer } from './style'

interface IFilesTabProps {
  orderId: string
}

// eslint-disable-next-line react/prop-types
export const FilesTab: React.FC<IFilesTabProps> = ({ orderId }) => {
  const [isDocumentInspectorVisible, setIsDocumentInspectorVisible] = useState(false)
  const [uploadedDocumentFiles, setUploadedDocumentFiles] = useState<OnFileUploadInput[]>([])
  const [downloadedFileName, setDownloadedFileName] = useState<string>()
  const [selectedDocument, setSelectedDocument] = useState('')

  const { setRequestConfig, data, isLoading } = useFetch<GetOrdersById>()

  const {
    setRequestConfig: setDownloadFileByPreviewURLRequestConfig,
    data: downloadFileByPreviewURLData,
    error: downloadFileByPreviewURLError,
    isLoading: isLoadindDownloadFileByPreviewURL,
  } = useFetch<ArrayBuffer>()

  const {
    setRequestConfig: deleteFileFromOrderRequestConfig,
    isLoading: isDeleting,
    statusCode: deleteStatusCode,
    error: deleteError,
  } = useFetch<DeleteDocumentFileFromOrderDto>()

  const {
    setRequestConfig: setUploadFilesRequestConfigs,
    statusCode: uploadFilesStatusCodes,
    data: uploadFilesData,
    error: uploadFilesError,
    isLoading: isLoadingUploadFiles,
  } = useFetchParallel<{ id: string; uploadURL?: string }>()

  const {
    setRequestConfig: setUploadFilesByUploadURLRequestConfig,
    statusCode: uploadFilesByUploadURLStatusCodes,
    isLoading: isLoadindUploadFilesByUploadURL,
  } = useFetchParallel()

  useEffect(() => {
    const getOrderByIdRequestConfig = healthPlansOrderRequest.getOrdersById(orderId)
    setRequestConfig(getOrderByIdRequestConfig)
  }, [])

  useEffect(() => {
    const getOrderByIdRequestConfig = healthPlansOrderRequest.getOrdersById(orderId)
    setRequestConfig(getOrderByIdRequestConfig)
  }, [deleteStatusCode])

  useEffect(() => {
    if (!uploadFilesStatusCodes) return
    const [uploadFilesStatusCode] = uploadFilesStatusCodes

    if (uploadFilesStatusCode === 200) {
      const requestConfigs = uploadFilesData
        // .filter((data) => data.uploadURL != null)
        .map((data, index) => {
          const contentType = uploadedDocumentFiles?.at(index)?.fileContentType
          if (data.uploadURL && contentType) {
            return HealthPlansOrdersRequests.uploadOrderDocumentByUploadURL(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              data.uploadURL,
              uploadedDocumentFiles[index].fileContent,
              contentType,
            )
          }
        })
        .filter((req) => req != null) as Array<UseFetchConfig>

      setUploadFilesByUploadURLRequestConfig(requestConfigs)
    }
  }, [uploadFilesStatusCodes])

  useEffect(() => {
    if (!uploadFilesByUploadURLStatusCodes) return
    const [uploadFilesStatusCode] = uploadFilesByUploadURLStatusCodes

    if (uploadFilesStatusCode === 200) {
      triggerToast(true, 'Arquivos enviados  com sucesso!')

      const getOrderByIdRequestConfig = healthPlansOrderRequest.getOrdersById(orderId)
      setRequestConfig(getOrderByIdRequestConfig)
    } else if (uploadFilesStatusCode > 400)
      triggerToast(false, 'Ops, ocorreu um erro ao enviar os arquivos')
  }, [uploadFilesByUploadURLStatusCodes])

  useEffect(() => {
    const errorReference = uploadFilesError
      ? 'ao fazer o upload dos arquivos selecionados'
      : downloadFileByPreviewURLError
      ? 'ao fazer o download do arquivo selecionado'
      : 'ao apagar o arquivo selecionado'

    if (uploadFilesError || downloadFileByPreviewURLError || deleteError) {
      triggerToast(false, `Ops, ocorreu um erro ${errorReference}`)
    }
  }, [uploadFilesError, downloadFileByPreviewURLError, deleteError])

  useEffect(() => {
    if (downloadFileByPreviewURLData && downloadedFileName) {
      const blobs = new Blob([downloadFileByPreviewURLData])
      const url = window.URL.createObjectURL(blobs)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', downloadedFileName)

      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }
  }, [downloadFileByPreviewURLData])

  const customUploadRequest = async (files: OnFileUploadInput[]) => {
    const uploadFileRequestConfigs = files.map((file) => {
      const formData = new FormData()
      formData.append('fileName', file.fileName)

      return healthPlansOrderRequest.uploadDocumentToOrder(formData, orderId)
    })

    setUploadedDocumentFiles(files)
    setUploadFilesRequestConfigs(uploadFileRequestConfigs)
  }

  const downloadDocument = (document: DocumentDto) => {
    setDownloadedFileName(document.fileName)
    setSelectedDocument(document.id)

    if (document.previewURL) {
      const downloadFileByPreviewURLRequestConfig =
        HealthPlansOrdersRequests.downloadOrderDocumentByPreviewURL(document.previewURL)
      setDownloadFileByPreviewURLRequestConfig(downloadFileByPreviewURLRequestConfig)
    }
  }

  const deleteDocument = (documentId: string) => {
    setSelectedDocument(documentId)
    const deleteDocumentsOrdersRequestConfig = healthPlansOrderRequest.deleteDocumentFileFromOrder(
      orderId,
      documentId,
    )
    deleteFileFromOrderRequestConfig(deleteDocumentsOrdersRequestConfig)
  }

  return (
    <FilesContainer>
      {(isLoadingUploadFiles || isLoading || isLoadindUploadFilesByUploadURL) &&
      isDeleting == false ? (
        <Skeleton />
      ) : (
        <>
          <UploadArea beforeUpload={() => false} onFileUpload={customUploadRequest} />
          {data?.documents && data.documents.length > 0 && (
            <DocumentTable
              data={data.documents.map((document) => {
                return {
                  id: document.id,
                  previewURL: document.previewURL,
                  fileName: document.fileName,
                  type: document.type,
                  status: document.status,
                  isLoadingDownloadDocument:
                    document.id == selectedDocument && isLoadindDownloadFileByPreviewURL,
                  isLoadingDeleteDocument: document.id == selectedDocument && isDeleting,
                }
              })}
              onDownloadClick={(doc) => downloadDocument(doc as DocumentDto)}
              onDeleteClick={deleteDocument}
              isInspectorOpen={isDocumentInspectorVisible}
              setInspectorOpen={setIsDocumentInspectorVisible}
              onDownloadImageFromInspectorClick={(doc) => downloadDocument(doc as DocumentDto)}
            />
          )}
        </>
      )}
    </FilesContainer>
  )
}
