/* eslint-disable react/prop-types */
import { ReactNode, useEffect, useState } from 'react'

import { DownloadOutlined, PaperClipOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { OnFileUploadInput } from 'design-system/Upload/Upload'
import CloseIcon from 'design-system/close'
import { Modal, Select, Skeleton, Timeline, Upload } from 'design-system/components'
import { Button } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'
import WarningIcon from 'design-system/warning'

import { healthPlansOrderRequest } from '@/App/clients/healthPlansOrder'
import {
  GetOrderTimelineDto,
  OrderTimelineDocuments,
  OrderTimelineEvent,
} from '@/App/clients/healthPlansOrder/dto'
import { HealthPlansOrdersRequests } from '@/App/clients/healthPlansOrders'
import {
  FillOrderInformationBody,
  GetOrderInformationBody,
  GetOrderResponsibleAdmins,
} from '@/App/clients/healthPlansOrders/dtos'
import useFetch from '@/App/clients/http'
import { statusMapperFn } from '@/App/helpers/statusMapper'

import {
  CustomDot,
  DownloadDocumentButtonDiv,
  EventContainer,
  ListItem,
  MainContainer,
  ModalContent,
  ModalTag,
  ModalTagContainer,
  ModalTime,
  ModalTitle,
  OrderStatusSelectLoadingDiv,
  ResponsibleAdminTag,
  ResponsibleContent,
  SelectContainer,
  StyledAutomationDetails,
  StyledButton,
  StyledDetails,
  StyledDivIcon,
  StyledSubTitle,
  StyledTitle,
  TagsContainer,
  TimelineContent,
  WithoutResponsibleContent,
} from './style'

interface ITimeLineTabProps {
  orderId?: string | null
  shouldReloadTimelineRequest?: boolean
  setShouldReloadTimelineRequest?: React.Dispatch<React.SetStateAction<boolean>>
  setShouldFillResponsibleAdminsIds?: React.Dispatch<React.SetStateAction<boolean>>
  shouldFillResponsibleAdminsIds?: boolean
  viewContentResponsibleAdmin: boolean
  planCard?: ReactNode
}

export const TimeLineTab: React.FC<ITimeLineTabProps> = ({
  orderId,
  shouldReloadTimelineRequest,
  setShouldReloadTimelineRequest,
  setShouldFillResponsibleAdminsIds,
  shouldFillResponsibleAdminsIds,
  viewContentResponsibleAdmin,
  planCard,
}) => {
  const [events, setEvents] = useState<
    Array<{
      color: string
      children: React.ReactNode
      dot: React.ReactNode
    }>
  >([])
  const [uploadedFiles, setUploadedFiles] = useState<OnFileUploadInput[]>([])
  const [selectedDocument, setSelectedDocument] = useState<string>()
  const [fileName, setFileName] = useState<string>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedEvent, setSelectedEvent] = useState<OrderTimelineEvent>()

  const {
    setRequestConfig: setGetOrderTimelineRequestConfig,
    data: getOrderTimelineData,
    isLoading: isGetOrderTimelineLoading,
  } = useFetch<GetOrderTimelineDto>()

  const {
    setRequestConfig: setFillOrderResponsibleAdminsRequestConfig,
    isLoading: isLoadingFillOrderResponsibleAdmins,
    statusCode: fillOrderResponsibleAdminsStatusCode,
  } = useFetch<FillOrderInformationBody>()

  const {
    setRequestConfig: setDownloadFileByPreviewURLRequestConfig,
    data: downloadFileByPreviewURLData,
    isLoading: isLoadindDownloadFileByPreviewURL,
    error: downloadFileByPreviewURLError,
  } = useFetch<ArrayBuffer>()

  const {
    setRequestConfig: setGetOrderByIdRequestConfig,
    data: orderData,
    statusCode: getOrderByIdStatusCode,
    isLoading: isLoadingGetOrderById,
  } = useFetch<GetOrderInformationBody>()

  const {
    setRequestConfig: setGetResponsibleAdminsRequestConfig,
    data: responsibleAdminData,
    isLoading: isLoadingGetResponsibleAdmins,
  } = useFetch<GetOrderResponsibleAdmins>()

  const {
    setRequestConfig: setUploadFileRequest,
    statusCode: uploadStatus,
    isLoading: isUploading,
    data: uploadData,
  } = useFetch<{ uploadURL: string }>()

  const {
    setRequestConfig: setUploadFileByUploadURLRequestConfig,
    statusCode: uploadFileByUploadURLStatusCodes,
    isLoading: isLoadingUploadFileByUploadURL,
  } = useFetch()

  const [selectResponsibleAdmins, setSelectResponsibleAdmins] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [currentResponsibleAdmins, setCurrentResponsibleAdmins] = useState<
    Array<{ label: string; id: string }>
  >([])

  useEffect(() => {
    if (shouldReloadTimelineRequest) {
      const getOrderTimelineByIdRequestConfig =
        healthPlansOrderRequest.getOrderTimelineById(orderId)
      setGetOrderTimelineRequestConfig(getOrderTimelineByIdRequestConfig)
    }
  }, [shouldReloadTimelineRequest])

  useEffect(() => {
    const getOrderTimelineByIdRequestConfig = healthPlansOrderRequest.getOrderTimelineById(orderId)
    setGetOrderTimelineRequestConfig(getOrderTimelineByIdRequestConfig)
  }, [])

  useEffect(() => {
    if (shouldFillResponsibleAdminsIds) {
      const getOrderByIdRequestConfig = HealthPlansOrdersRequests.getOrderById(orderId)
      setGetOrderByIdRequestConfig(getOrderByIdRequestConfig)
    }
    setShouldFillResponsibleAdminsIds && setShouldFillResponsibleAdminsIds(false)
  }, [shouldFillResponsibleAdminsIds])

  useEffect(() => {
    const getOrderByIdRequestConfig = HealthPlansOrdersRequests.getOrderById(orderId)
    setGetOrderByIdRequestConfig(getOrderByIdRequestConfig)
  }, [])

  useEffect(() => {
    const responsibleAdminsRequestConfig = healthPlansOrderRequest.getAdminsByFilters()
    setGetResponsibleAdminsRequestConfig(responsibleAdminsRequestConfig)
  }, [])

  useEffect(() => {
    if (orderData != null) {
      assignCurrentResponsibleAdmins()
    }

    if (responsibleAdminData != null) {
      const rawAdminsIds = responsibleAdminData.admins.map((admin) => admin.id)
      const responsibleAdminsIds = orderData?.responsibleAdmins?.map((admin) => admin.id)

      const targetIds = rawAdminsIds.filter((id) => !responsibleAdminsIds?.includes(id))
      const availableAdmins = targetIds.map((option) => {
        const targetLabel = responsibleAdminData.admins.find(
          (rawAdmin) => rawAdmin.id === option,
        )?.name

        return { label: targetLabel ?? option, value: option }
      })
      setSelectResponsibleAdmins(availableAdmins ?? [])
    }
  }, [orderData, getOrderByIdStatusCode])

  useEffect(() => {
    if (fillOrderResponsibleAdminsStatusCode === 200) {
      const rawAdminsIds = responsibleAdminData?.admins.map((admin) => admin.id)
      const responsibleAdminsIds = currentResponsibleAdmins.map((admin) => admin.id)

      const targetIds = rawAdminsIds?.filter((id) => !responsibleAdminsIds.includes(id))
      const availableAdmins = targetIds?.map((option) => {
        const targetLabel = responsibleAdminData?.admins.find(
          (rawAdmin) => rawAdmin.id === option,
        )?.name

        return { label: targetLabel ?? option, value: option }
      })
      setSelectResponsibleAdmins(availableAdmins ?? [])
      triggerToast(true, 'Responsável alterado com sucesso!')
    }
  }, [fillOrderResponsibleAdminsStatusCode])

  useEffect(() => {
    if (uploadFileByUploadURLStatusCodes) {
      if (uploadFileByUploadURLStatusCodes === 200) {
        triggerToast(true, 'Arquivo anexado com sucesso!')
        setShouldReloadTimelineRequest && setShouldReloadTimelineRequest(true)
      }

      if (uploadFileByUploadURLStatusCodes >= 400) {
        triggerToast(false, 'Ops, ocorreu um erro ao anexar o arquivo')
      }
    }
  }, [uploadFileByUploadURLStatusCodes])

  useEffect(() => {
    const uploadContentType = uploadedFiles?.at(0)?.fileContentType
    if (uploadStatus === 200 && uploadData?.uploadURL && uploadContentType) {
      const requestConfigs = HealthPlansOrdersRequests.uploadOrderDocumentByUploadURL(
        uploadData.uploadURL,
        uploadedFiles[0].fileContent,
        uploadContentType,
      )
      setUploadFileByUploadURLRequestConfig(requestConfigs)
    }
  }, [uploadStatus])

  const assignCurrentResponsibleAdmins = (): void => {
    const admins = orderData?.responsibleAdmins?.map((option) => {
      return { label: option.name, id: option.id }
    })
    setCurrentResponsibleAdmins(admins ?? [])
  }

  const addResponsibleAdmin = (selectedOption: string) => {
    const responsibleAdminsIds = currentResponsibleAdmins.map((admin) => admin.id)
    responsibleAdminsIds.push(selectedOption)

    const targetAdmins = responsibleAdminsIds.map((admin) => {
      const targetLabel = responsibleAdminData?.admins.find(
        (rawAdmin) => rawAdmin.id === admin,
      )?.name

      return { label: targetLabel ?? admin, id: admin }
    })
    setCurrentResponsibleAdmins(targetAdmins ?? [])

    const fillOrderInformationRequestConfig = HealthPlansOrdersRequests.fillOrderInformation(
      { responsibleAdminsIds: responsibleAdminsIds },
      orderId,
    )

    setFillOrderResponsibleAdminsRequestConfig(fillOrderInformationRequestConfig)
  }

  const deleteResponsibleAdmin = (value: string) => {
    const responsibleAdminsIds = currentResponsibleAdmins.map((item) => item.id)
    const targetResponsibleIds = responsibleAdminsIds.filter((admin) => admin !== value)

    const targetAdmins = targetResponsibleIds.map((option) => {
      const targetLabel = responsibleAdminData?.admins.find(
        (rawAdmin) => rawAdmin.id === option,
      )?.name

      return { label: targetLabel ?? option, id: option }
    })

    setCurrentResponsibleAdmins(targetAdmins)

    const fillOrderInformationRequestConfig = HealthPlansOrdersRequests.fillOrderInformation(
      { responsibleAdminsIds: targetResponsibleIds },
      orderId,
    )
    setFillOrderResponsibleAdminsRequestConfig(fillOrderInformationRequestConfig)
  }

  const onFileUpload = (files: OnFileUploadInput[], type: string) => {
    setSelectedDocument(type)
    const formData = new FormData()
    formData.append('fileName', files[0].fileName)
    formData.append('fileType', type)
    const uploadFileRequestConfigs = healthPlansOrderRequest.uploadDocumentToOrder(
      formData,
      orderId,
    )

    setUploadFileRequest(uploadFileRequestConfigs)

    setUploadedFiles(files)
  }

  useEffect(() => {
    if (downloadFileByPreviewURLError) triggerToast(false, 'Erro ao baixar o arquivo')
  }, [downloadFileByPreviewURLError])

  useEffect(() => {
    if (downloadFileByPreviewURLData && fileName) {
      const blobs = new Blob([downloadFileByPreviewURLData])
      const url = window.URL.createObjectURL(blobs)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)

      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(url)
      setSelectedDocument('')
    }
  }, [downloadFileByPreviewURLData, fileName])

  useEffect(() => {
    const transformedEvents = getOrderTimelineData?.events.map((event) => {
      const status = statusMapperFn(true)
      let hasBill = false
      let hasSignedOrder = false

      const statusMap = status[event.status] ?? {
        label: event.status,
        color: status[event.status].color,
        backgroundColor: status[event.status].backgroundColor,
        icon: status[event.status].icon,
      }

      const billPaymentPendingEvent = getOrderTimelineData.events.find(
        (event) => event.status === 'BILL_PAYMENT_PENDING',
      )

      if (billPaymentPendingEvent) {
        hasSignedOrder = !!billPaymentPendingEvent.documents?.find(
          (document) => document.type === 'SIGNED_ORDER',
        )
        hasBill = !!billPaymentPendingEvent.documents?.find((document) => document.type === 'BILL')
      }

      const handleOpenEvent = (event: OrderTimelineEvent) => {
        setSelectedEvent(event)
        setIsOpen(true)
      }

      const eventCharactersCount = event?.details && event.details.length > 320

      return {
        color: status[event.status].color,
        children: (
          <ListItem>
            <StyledTitle>{statusMap.label}</StyledTitle>
            <StyledSubTitle>
              {dayjs(event.timestamp).locale('pt-br').format('DD [de] MMMM [de] YYYY [às] HH:mm')}
            </StyledSubTitle>
            {event.origin === 'AUTOMATION' ? (
              <>
                <StyledDetails>
                  Status alterado automaticamente pela operadora.
                  <EventContainer>
                    {event?.externalStatus && (
                      <div>
                        <span>Nome original: </span>
                        {event?.externalStatus}
                      </div>
                    )}
                    {event?.externalSubstatus && (
                      <div>
                        <span>Substatus na Operadora: </span>
                        {event?.externalSubstatus}
                      </div>
                    )}
                  </EventContainer>
                </StyledDetails>
                {event?.details && event?.status === 'FILLING_NOT_MEET_REQUIREMENTS' && (
                  <StyledAutomationDetails>
                    <span>Pendência: </span>
                    {eventCharactersCount
                      ? event?.details.slice(0, 320).concat('...')
                      : event?.details}
                  </StyledAutomationDetails>
                )}
                {eventCharactersCount && (
                  <StyledButton onClick={() => handleOpenEvent(event)}>+Ver mais</StyledButton>
                )}
              </>
            ) : (
              <StyledDetails>{event?.details}</StyledDetails>
            )}
            {statusMap.label === 'Boleto pendente pgto' && (
              <DownloadDocumentButtonDiv>
                {hasBill ? (
                  <Button
                    palette="cancel"
                    onClick={() => {
                      const document = event?.documents?.at(0)
                      if (document) {
                        downloadDocument(document)
                      }
                    }}
                    height="44px"
                    loading={selectedDocument === 'BILL' && isLoadindDownloadFileByPreviewURL}
                    style={{ width: '155px' }}
                  >
                    <DownloadOutlined />
                    Baixar boleto
                  </Button>
                ) : (
                  <Upload
                    text="Anexar boleto"
                    icon={<PaperClipOutlined style={{ color: '#d9d9d9' }} />}
                    onFileUpload={(files) => onFileUpload(files, 'BILL')}
                    showFileUploadList={false}
                    border="dashed"
                    loading={
                      (selectedDocument === 'BILL' && isUploading) || isLoadingUploadFileByUploadURL
                    }
                  />
                )}
                {hasSignedOrder ? (
                  <Button
                    palette="cancel"
                    onClick={() => {
                      const document = event?.documents?.at(1)
                      if (document) {
                        downloadDocument(document)
                      }
                    }}
                    height="44px"
                    loading={
                      selectedDocument === 'SIGNED_ORDER' && isLoadindDownloadFileByPreviewURL
                    }
                    style={{ width: '225px' }}
                  >
                    <DownloadOutlined /> Baixar proposta assinada
                  </Button>
                ) : (
                  <Upload
                    text="Anexar proposta assinada"
                    icon={<PaperClipOutlined style={{ color: '#d9d9d9' }} />}
                    onFileUpload={(files) => onFileUpload(files, 'SIGNED_ORDER')}
                    showFileUploadList={false}
                    border="dashed"
                    loading={
                      (selectedDocument === 'SIGNED_ORDER' && isUploading) ||
                      isLoadingUploadFileByUploadURL
                    }
                  />
                )}
              </DownloadDocumentButtonDiv>
            )}
          </ListItem>
        ),
        dot: <CustomDot bgColor={statusMap.backgroundColor}>{statusMap.icon}</CustomDot>,
      }
    })
    setShouldReloadTimelineRequest && setShouldReloadTimelineRequest(false)
    setEvents(transformedEvents ?? [])
  }, [getOrderTimelineData, isLoadindDownloadFileByPreviewURL, isUploading])

  const downloadDocument = (document: OrderTimelineDocuments) => {
    setSelectedDocument(document.type)
    setFileName(document.fileName)

    const downloadDocumentRequestConfig =
      HealthPlansOrdersRequests.downloadOrderDocumentByPreviewURL(document.previewURL)

    setDownloadFileByPreviewURLRequestConfig(downloadDocumentRequestConfig)
  }

  return (
    <TimelineContent>
      {isGetOrderTimelineLoading ? (
        <OrderStatusSelectLoadingDiv>
          <Skeleton type="input" />
        </OrderStatusSelectLoadingDiv>
      ) : (
        viewContentResponsibleAdmin && (
          <div>
            {isLoadingFillOrderResponsibleAdmins ||
            isLoadingGetResponsibleAdmins ||
            isLoadingGetOrderById ? (
              <OrderStatusSelectLoadingDiv>
                <Skeleton type="input" />
              </OrderStatusSelectLoadingDiv>
            ) : (
              <ResponsibleContent>
                <SelectContainer>
                  <Select
                    defaultValue=" + Alocar novo responsável"
                    options={selectResponsibleAdmins}
                    onChange={(value) => addResponsibleAdmin(value as string)}
                  />
                </SelectContainer>
                {currentResponsibleAdmins.length != 0 ? (
                  <TagsContainer>
                    {currentResponsibleAdmins.map((value, index) => (
                      <ResponsibleAdminTag key={index}>
                        {value.label}
                        <StyledDivIcon onClick={() => deleteResponsibleAdmin(value.id)}>
                          <CloseIcon />
                        </StyledDivIcon>
                      </ResponsibleAdminTag>
                    ))}
                  </TagsContainer>
                ) : (
                  <WithoutResponsibleContent>
                    <WarningIcon />
                    Proposta sem responsável
                  </WithoutResponsibleContent>
                )}
              </ResponsibleContent>
            )}
          </div>
        )
      )}

      {isGetOrderTimelineLoading ? (
        <Skeleton active />
      ) : (
        <MainContainer>
          <Timeline items={events} />
          {planCard}
        </MainContainer>
      )}
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        footerAlign="center"
        footer={[
          <Button onClick={() => setIsOpen(false)} style={{ width: '250px' }} key={'01'}>
            Entendi
          </Button>,
        ]}
        customHeight="550px"
        width={800}
        padding="20px 80px 0 80px"
      >
        <ModalContent>
          <ModalTitle>Pendência</ModalTitle>
          <ModalTime>
            {dayjs(selectedEvent?.timestamp)
              .locale('pt-br')
              .format('DD [de] MMMM [de] YYYY [às] HH:mm')}
          </ModalTime>
          <ModalTagContainer>
            <ModalTag>
              <span>Corretor: </span>
              {orderData?.broker?.name}
            </ModalTag>
            <ModalTag>
              <span>Lead:</span>
              {orderData?.quotation?.lead?.name ?? orderData?.lead?.name}
            </ModalTag>
          </ModalTagContainer>
          <StyledDetails>{selectedEvent?.details}</StyledDetails>
        </ModalContent>
      </Modal>
    </TimelineContent>
  )
}
