import { Control } from 'react-hook-form'

import { Select } from 'design-system/components'

import { FillQuotationInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import { useGetCitiesQuery } from '@/App/clients/healthPlansOrders/queries/getCities'
import { states } from '@/App/helpers/states'

import { FormItem, FormItemLabel } from '../style'

interface GndiFormQuotationProps {
  control: Control<FillQuotationInformationBody>
}

export const GndiFormQuotation: React.FC<GndiFormQuotationProps> = ({ control }) => {
  const getCities = useGetCitiesQuery()

  return (
    <>
      <FormItem>
        <FormItemLabel>Coparticipação*</FormItemLabel>
        <Select
          placeholder="Selecione a opção"
          control={control}
          showSearch={true}
          name="isCoparticipation"
          options={[
            { label: 'Completa', value: 'true' },
            { label: 'Parcial', value: 'false' },
          ]}
        />
      </FormItem>
      <FormItem>
        <FormItemLabel>Estado Beneficiários (Maioria)*</FormItemLabel>
        <Select
          placeholder="Selecione a opção"
          control={control}
          showSearch={true}
          name="state"
          options={states}
        />
      </FormItem>

      <FormItem>
        <FormItemLabel>Cidade Beneficiários (Maioria)*</FormItemLabel>
        <Select
          placeholder="Selecione a opção"
          control={control}
          showSearch={true}
          name="city"
          options={getCities?.data ?? []}
        />
      </FormItem>
    </>
  )
}
