import { Button, Upload } from 'antd'
import styled from 'styled-components'

import { colors } from '../../styles/theme'

interface ButtonUploadProps {
  bgcolor?: string
  textcolor?: string
  text?: string
  border?: 'dashed' | 'solid'
}

type StyledUploadProps = Omit<ButtonUploadProps, 'bgColor'>

export const UploadButton = styled(Button)<ButtonUploadProps>`
  &&& {
    background-color: ${(props) => props.bgcolor};
    width: 100%;
    height: 44px;
    padding: 0.7rem;
    border-radius: 50px;
    border: ${(props) => (props.border === 'dashed' ? '2px dashed #dfdfdf' : '1px solid #dfdfdf')};
  }

  & span {
    color: ${(props) => props.textcolor};
  }
`

export const StyledUpload = styled(Upload)<StyledUploadProps>`
  &&& {
    .ant-upload-list-item-name {
      color: ${(props) => props.textcolor ?? colors.primaryText};
    }

    .ant-upload {
      width: 100%;
    }
  }
`
