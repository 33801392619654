import { Tag } from 'antd'
import styled from 'styled-components'

export const StyledTag = styled(Tag)`
  padding: 10px;
  font-size: 1rem;
  border-radius: 10px;
  svg {
    font-size: 1rem;
  }
  @media screen and (max-width: 768px) {
    padding: 2px 10px 2px 10px;
  }
`
