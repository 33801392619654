import { useMutation, useQueryClient } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'
import { v4 as uuidv4 } from 'uuid'

import { api } from '../../http'
import { UpdateMappingStatusDto } from '../dtos'
import { statusMappingExecutionInfiniteQueryOptions } from '../queries/statusMappingExecutions'
import { statusMappingsQueryOptions } from '../queries/statusMappings'

async function remapStatus({
  externalStatus,
  insurer,
  internalStatus,
  externalSubstatus,
}: UpdateMappingStatusDto) {
  const url = `${import.meta.env.VITE_ORDER_INFORMATION_PROCESSING_URL}/api/v1/status-mappings`

  return api.put(
    url,
    {
      insurer,
      internalStatus,
      externalStatus: externalSubstatus ? `${externalStatus}#${externalSubstatus}` : externalStatus,
    },
    {
      headers: {
        Authorization: localStorage.getItem('authToken'),
      },
    },
  )
}

type RemapStatusMutationParams = {
  onSuccess: () => void
}

export function useRemapStatusMutation(params?: RemapStatusMutationParams) {
  const queryClient = useQueryClient()

  const statusMappingExecutionInfiniteQueryKey = statusMappingExecutionInfiniteQueryOptions({
    isMapped: true,
  }).queryKey

  return useMutation({
    mutationKey: ['statusMappingExecution'] as const,
    mutationFn: (data: UpdateMappingStatusDto) => remapStatus(data),
    onMutate: async ({
      externalStatus,
      insurer,
      internalStatus,
      externalSubstatus,
      previousStatus,
    }) => {
      await queryClient.cancelQueries({ queryKey: ['statusMappingExecutions'] })

      const previousStatusMappingExecutions = queryClient.getQueryData(
        statusMappingExecutionInfiniteQueryKey,
      )

      queryClient.setQueryData(statusMappingExecutionInfiniteQueryKey, (old) => {
        if (!old) {
          return old
        }

        const [fistPage, ...pages] = old.pages
        const id = uuidv4()

        const newStatusMappingExecutions = Object.assign(fistPage, {
          statusMappingExecutions: [
            {
              id,
              key: id,
              insurer,
              externalStatus,
              externalSubstatus,
              internalStatus,
              previousStatus,
            },
            ...fistPage.statusMappingExecutions,
          ],
        })

        return {
          ...old,
          pages: [newStatusMappingExecutions, ...pages],
        }
      })

      return {
        previousStatusMappingExecutions,
      }
    },
    onError: (_error, _variables, context) => {
      triggerToast(false, 'Ops, ocorreu um erro ao remapear o status')

      queryClient.setQueryData(
        statusMappingExecutionInfiniteQueryKey,
        context?.previousStatusMappingExecutions,
      )
    },
    onSuccess: () => {
      triggerToast(true, 'Remapeamento de status realizado com sucesso!')
      params?.onSuccess && params.onSuccess()
    },
    onSettled: (_data, _error, variables, _context) => {
      queryClient.invalidateQueries({
        queryKey: statusMappingExecutionInfiniteQueryKey,
      })
      queryClient.invalidateQueries({
        queryKey: statusMappingsQueryOptions(variables.insurer).queryKey,
      })
    },
  })
}
