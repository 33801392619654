import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import DentalIcon from '@design-system/icons/dental'
import HealthIcon from '@design-system/icons/health'
import PetIcon from '@design-system/icons/pet'
import PlusIcon from '@design-system/icons/plus'
import type { CheckableCard } from 'design-system/CheckableCards/CheckableCards'
import { CheckableCards, Skeleton, Spinner } from 'design-system/components'

import { HealthPlansOrdersRequests } from '@/App/clients/healthPlansOrders'
import {
  CreateOrderInputDto,
  CreateQuotationInputDto,
  CreateQuotationOutputDto,
  GetQuotationsByLeadIdDtoOutput,
  QuotationDto,
} from '@/App/clients/healthPlansOrders/dtos'
import useFetch from '@/App/clients/http'
import { clearInputMask } from '@/App/utils/string'

import { useCreateOrderContext } from '../store'
import CreateQuotation from './createQuotation'
import {
  CardContent,
  CardsDiv,
  CreateQuotationCardContent,
  CreateQuotationCardTitle,
  IconsDiv,
  LoadingDiv,
  LoadingDivSubtitle,
  LoadingDivTitle,
  QuotationCardAmount,
  QuotationCardAmountDiv,
  QuotationCardItem,
  QuotationCardTitle,
  QuotationDiv,
  QuotationTag,
  QuotationTags,
  QuotationTitle,
  SkeletonDiv,
  TagTitle,
} from './style'

export interface QuotationProps {
  setCreatedQuotation: React.Dispatch<React.SetStateAction<QuotationDto | undefined>>
  createOrder: (order: CreateOrderInputDto) => void
  createLeadIsLoading: boolean
  setSelectedQuotation: React.Dispatch<React.SetStateAction<QuotationDto | undefined>>
}

const Quotation: React.FC<QuotationProps> = ({
  setCreatedQuotation,
  createOrder,
  setSelectedQuotation,
  createLeadIsLoading,
}) => {
  const [isCreateQuotationSelected, setIsCreateQuotationSelected] = useState(false)
  const [quotationsCards, setQuotationsCards] = useState<Array<CheckableCard>>([])

  const createOrderContext = useCreateOrderContext()

  const { handleSubmit } = useForm()

  const {
    setRequestConfig: setGetQuotationsRequestConfig,
    data: getQuotationsData,
    isLoading: getQuotationsIsLoading,
  } = useFetch<GetQuotationsByLeadIdDtoOutput>()

  const {
    setRequestConfig: setCreateQuotationRequestConfig,
    payload: createQuotationPayload,
    data: createQuotationData,
    isLoading: createQuotationIsLoading,
    error: createQuotationError,
  } = useFetch<CreateQuotationOutputDto>()

  const {
    setRequestConfig: setUploadFileByUploadURLRequestConfig,
    isLoading: uploadFileByUploadURLIsLoading,
    statusCode: uploadFileByUploadURLStatusCodes,
  } = useFetch()

  useEffect(() => {
    if (createOrderContext.lead?.id != null && createOrderContext.broker?.id != null) {
      const quotationsRequestConfig = HealthPlansOrdersRequests.getQuotationsByBrokerAndLeadId(
        createOrderContext.broker?.id,
        createOrderContext.lead.id,
      )
      setGetQuotationsRequestConfig(quotationsRequestConfig)
    }
  }, [createOrderContext.lead?.id])

  useEffect(() => {
    if (createQuotationData != null) {
      if (createQuotationData.attachmentFileUploadUrl != null) {
        const uploadFileByUploadURLRequestConfig =
          HealthPlansOrdersRequests.uploadOrderDocumentByUploadURL(
            createQuotationData.attachmentFileUploadUrl,
            createQuotationPayload['quotationAttachmentFile'] as Blob,
            createQuotationPayload['quotationAttachmentFileContentType'],
          )
        setUploadFileByUploadURLRequestConfig(uploadFileByUploadURLRequestConfig)
      } else setCreatedQuotation({ ...createQuotationPayload, id: createQuotationData.id })
    }
  }, [createQuotationData])

  useEffect(() => {
    if (uploadFileByUploadURLStatusCodes === 200 && createQuotationData?.id) {
      setCreatedQuotation({ ...createQuotationPayload, id: createQuotationData.id })
    }
  }, [uploadFileByUploadURLStatusCodes])

  useEffect(() => {
    if (getQuotationsData) {
      const quotationsCards: Array<CheckableCard> = getQuotationsData.quotations.map(
        (quotation) => {
          return {
            reference: quotation.id,
            children: (
              <CardContent>
                {getQuotationIcon(quotation.product)}
                <QuotationCardTitle>{quotation.plan ?? 'Sem plano preenchido'}</QuotationCardTitle>
                <QuotationCardItem>
                  {quotation.insurer ?? 'Sem operadora preenchida'}
                </QuotationCardItem>
                <QuotationCardItem>
                  {quotation.lifesQuantity ?? 0} vida
                  {(quotation.lifesQuantity && quotation.lifesQuantity > 1) ||
                  quotation.lifesQuantity == null
                    ? 's'
                    : ''}
                </QuotationCardItem>
                <QuotationCardAmountDiv>
                  <QuotationCardItem>Valor total:</QuotationCardItem>{' '}
                  <QuotationCardAmount>
                    {quotation.totalAmount
                      ? quotation.totalAmount.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })
                      : 'R$ -'}
                  </QuotationCardAmount>
                </QuotationCardAmountDiv>
              </CardContent>
            ),
            border: undefined,
          }
        },
      )

      quotationsCards.push({
        reference: 'NEW_QUOTATION',
        children: (
          <CreateQuotationCardContent>
            <PlusIcon />
            <CreateQuotationCardTitle>Cadastrar nova cotação</CreateQuotationCardTitle>
          </CreateQuotationCardContent>
        ),
        border: 'dashed',
      })

      setQuotationsCards(quotationsCards)
    }
  }, [getQuotationsData])

  useEffect(() => {
    if (createQuotationError?.message) {
      createOrderContext.setQuotation({
        error: createQuotationError.message,
      })
    }
  }, [createQuotationError])

  useEffect(() => {
    if (createQuotationError) {
      createOrderContext.setQuotation({
        isLoading: createQuotationIsLoading,
      })
    }
  }, [createQuotationIsLoading])

  const handleCardSelection = (reference: string) => {
    if (reference === 'NEW_QUOTATION') setIsCreateQuotationSelected(true)
    else
      setSelectedQuotation(
        getQuotationsData?.quotations.find((quotation) => quotation.id === reference),
      )
  }

  const createQuotation = (quotation: CreateQuotationInputDto) => {
    const targetQuotation = {
      ...quotation,
      totalAmount: +clearInputMask(quotation.totalAmount as string, 'money'),
      isCoparticipation: quotation.isCoparticipation?.toString() === 'true',
      isCombinedProposal: quotation.isCombinedProposal?.toString() === 'true',
    }

    if (createOrderContext.broker?.id && createOrderContext.lead?.id) {
      const createQuotationRequestConfig = HealthPlansOrdersRequests.createQuotation(
        createOrderContext.broker?.id,
        createOrderContext.lead?.id,
        targetQuotation,
      )

      setCreateQuotationRequestConfig(createQuotationRequestConfig)
    }
  }

  const getQuotationIcon = (quotationProduct?: string) => {
    switch (quotationProduct) {
      case 'HEALTH':
        return <HealthIcon />

      case 'DENTAL':
        return <DentalIcon />
      case 'PET':
        return <PetIcon />

      case 'HEALTH_AND_DENTAL':
        return (
          <IconsDiv>
            <HealthIcon />
            <DentalIcon />
          </IconsDiv>
        )
      default:
        return null
    }
  }

  if (
    createQuotationIsLoading ||
    createOrderContext.order.isLoading ||
    uploadFileByUploadURLIsLoading
  ) {
    return (
      <LoadingDiv>
        <Spinner height="100px" width="100px" />
        <LoadingDivTitle>
          Quase lá! <br /> Estamos criando a proposta...
        </LoadingDivTitle>
        <LoadingDivSubtitle>
          Assim que ela for criada, você poderá subir documentos.
        </LoadingDivSubtitle>
      </LoadingDiv>
    )
  }

  if (!isCreateQuotationSelected && !createOrderContext.isRegisteredInInsurer) {
    return (
      <QuotationDiv>
        <QuotationTitle>Selecione a cotação referente à esta proposta</QuotationTitle>
        {getQuotationsIsLoading || createLeadIsLoading ? (
          <SkeletonDiv>
            <Skeleton
              type="default"
              shape="square"
              active={true}
              loading={true}
              title={{ width: '400px' }}
            />
            <Skeleton
              type="default"
              shape="square"
              active={true}
              loading={true}
              title={{ width: '400px' }}
            />
            <Skeleton
              type="default"
              shape="square"
              active={true}
              loading={true}
              title={{ width: '400px' }}
            />
          </SkeletonDiv>
        ) : (
          <>
            <QuotationTags>
              <QuotationTag>
                <TagTitle>Corretor(a): </TagTitle>
                <p>{createOrderContext.broker?.name}</p>
              </QuotationTag>

              <QuotationTag>
                <TagTitle>Lead: </TagTitle>
                <p>{createOrderContext.lead?.name}</p>
              </QuotationTag>
            </QuotationTags>

            <form id="createOrder" onSubmit={handleSubmit(createOrder)}>
              <CardsDiv>
                <CheckableCards
                  cards={quotationsCards}
                  cardHeight="250px"
                  onSelect={(reference) => handleCardSelection(reference)}
                />
              </CardsDiv>
            </form>
          </>
        )}
      </QuotationDiv>
    )
  }

  return <CreateQuotation createQuotation={createQuotation} />
}

export default Quotation
