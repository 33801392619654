import styled from 'styled-components'

export interface StyledButtonProps {
  hideButton?: boolean
}

export const ButtonStyled = styled.div<StyledButtonProps>`
  border-radius: 50%;
  background-color: #514984;
  border: none;
  color: white;
  font-size: 25px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: ${(props) => props.hideButton && 'none'};
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`

export const InputStyled = styled.input`
  width: 44px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid #b7c8d5;
  :focus,
  :hover {
    outline: none;
    background-color: white;
  }
  color: #485964;
  text-align: center;
`

export const CounterStyled = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`
