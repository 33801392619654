import styled from 'styled-components'

export const StyledFilterMenu = styled.div`
  position: absolute;
  background: white;
  border-radius: 15px;
  box-shadow: 3px 4px 30px #bbbbbb;
  width: 40vw;

  .ant-tabs > .ant-tabs-nav {
    background: none !important;
  }

  .ant-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .ant-tabs > .ant-tabs-nav {
    background: #f5f6f9 !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
    padding: 15px;
  }

  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black;
    width: 100%;
    text-align: left;
  }

  .ant-tabs .ant-tabs-tab:hover {
    color: purple;
  }
  .ant-tabs-tabpane {
    padding: 10px;
  }

  @media screen and (max-width: 768px) {
    width: 90vw;
    position: relative;

    .ant-tabs {
      grid-template-columns: 1fr;
    }
  }
`
