export const insurersSulamerica = [
  { value: '3', label: 'ALLIANZ' },
  { value: '1', label: 'AMIL' },
  { value: '30', label: 'ASSIM SAÚDE' },
  { value: '2', label: 'BRADESCO' },
  { value: '11', label: 'CABERJ' },
  { value: '59', label: 'CAIXA SEGUROS' },
  { value: '12', label: 'CARE PLUS' },
  { value: '13', label: 'CNU' },
  { value: '14', label: 'DIX' },
  { value: '33', label: 'FUNCESP' },
  { value: '31', label: 'GNDI' },
  { value: '15', label: 'GOLDEN CROSS' },
  { value: '29', label: 'HAPVIDA' },
  { value: '17', label: 'INTERMÉDICA' },
  { value: '18', label: 'LINCX' },
  { value: '20', label: 'MEDIAL' },
  { value: '69', label: 'MEDISERVICE' },
  { value: '22', label: 'NOTREDAME' },
  { value: '23', label: 'OMINT' },
  { value: '24', label: 'ONE HEALTH' },
  { value: '4', label: 'OUTROS' },
  { value: '25', label: 'PORTO SEGURO' },
  { value: '28', label: 'PROMED' },
  { value: '32', label: 'SANTA AMALIA' },
  { value: '68', label: 'SÃO FRANCISCO SAÚDE' },
  { value: '62', label: 'SAUDE SISTEMA' },
  { value: '9', label: 'SEGURO NOVO' },
  { value: '26', label: 'SEGUROS UNIMED' },
  { value: '19', label: 'SOMPO SEGUROS' },
  { value: '71', label: 'SULAMÉRICA' },
  { value: '27', label: 'TEMPO SAÚDE' },
  { value: '64', label: 'UNIMED BH' },
  { value: '67', label: 'UNIMED CAMPINAS' },
  { value: '70', label: 'UNIMED FEDERAÇÃO' },
  { value: '65', label: 'UNIMED RECIFE' },
  { value: '63', label: 'UNIMED RIO' },
]
