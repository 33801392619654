import styled from 'styled-components'
import { colors } from '../../styles/theme'

export interface StyledImageCardProps {
  imageHeight?: string
}

export interface StyledCardContainerProps {
  hoverBackgroundColor: string
  hoverTextColor: string
  width: string
  height?: string
  boxShadow?: 'light' | 'heavy'
}

export const StyledContainer = styled.div<StyledCardContainerProps>`
  width: ${(props) => props.width};
  span {
    font-weight: 500;
    font-size: 14px;
    color: #858aa3;
  }
  :hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    transition: 0.5s;
    background-color: ${(props) => props.hoverBackgroundColor};
    h2 {
      color: ${(props) => props.hoverTextColor};
    }
    span {
      color: ${(props) => props.hoverTextColor};
    }
  }
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: ${(props) =>
    props.boxShadow === 'heavy'
      ? '0px 2px 10px 0px rgba(55, 70, 126, 0.25)'
      : '0 0 3px rgba(0, 0, 0, 0.4)'};
  align-items: center;
  cursor: pointer;
  height: ${(props) => props.height};
  justify-content: center;

  @media screen and (max-width: 768px) {
    column-gap: 7px;
    flex-direction: row;
    height: 110px;
  }
`

export const StyledTitle = styled.h2`
  color: ${colors.primaryText};
  font-size: 1.063rem;
  text-align: center;
  width: 80%;
  margin: 5px 0;
  @media screen and (max-width: 768px) {
    font-size: 13px;
    width: 100%;
    text-align: start;
  }
`

export const StyledDescription = styled.span`
  color: ${colors.secundaryText};
  font-size: 0.875rem;
  width: 80%;
  text-align: center;
  margin-bottom: 10px;
  @media screen and (max-width: 768px) {
    font-size: 12px;
    font-weight: 600;
    text-align: start;
    width: 100%;
  }
`
export const StyledImage = styled.img<StyledImageCardProps>`
  margin-bottom: 10px;
  border-radius: 10px;
  width: 90%;
  height: ${(props) => (props.imageHeight ? props.imageHeight : '')};

  @media screen and (max-width: 768px) {
    height: 80%;
    margin: 5px;
    width: 40%;
  }
`

export const StyledMobileTextContent = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`
