import React, { CSSProperties, ReactNode } from 'react'

import { CheckCircleFilled, FileExclamationFilled } from '@ant-design/icons'
import { ModalProps } from 'antd'

import { colors } from '../../styles/theme'
import Button from '../Button/Button'
import { StyledModal } from './style'

export interface Props extends ModalProps {
  children: ReactNode
  title: string
  buttonText: string
  width?: number
  bodyStyle?: CSSProperties
  icon?: 'success' | 'error'
  customButtonOnClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  confirmLoading?: boolean
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ResponseModal: React.FC<Props> = ({
  title,
  children,
  buttonText,
  width,
  bodyStyle,
  customButtonOnClick,
  confirmLoading,
  icon,
  isOpen,
  setIsOpen,
}) => {
  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <StyledModal
      bodyStyle={bodyStyle}
      open={isOpen}
      onCancel={closeModal}
      footer={[
        <Button
          key="back"
          onClick={customButtonOnClick ? customButtonOnClick : closeModal}
          style={{ width: '200px' }}
        >
          {buttonText}
        </Button>,
      ]}
      width={width}
      centered
      confirmLoading={confirmLoading}
    >
      <div className="icon">
        {icon == 'success' ? (
          <CheckCircleFilled style={{ color: `${colors.green}` }} />
        ) : (
          <FileExclamationFilled style={{ color: `${colors.red}` }} />
        )}
      </div>
      <p>{title}</p>
      {children}
    </StyledModal>
  )
}

export default ResponseModal
