import { Outlet, useNavigate } from 'react-router-dom'

export const ProtectedRoute = ({ component }: { component: JSX.Element }) => {
  const navigate = useNavigate()
  const token = localStorage.getItem('authToken')

  if (token == null) {
    navigate('/')
  }
  return component ?? <Outlet />
}
