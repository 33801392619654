import { Tabs as AntdTabs } from 'antd'
import styled from 'styled-components'
import { colors } from '../../styles/theme'

interface StyledTabsProps {
  colorBackground?: string
}

export const StyledTabs = styled(AntdTabs)<StyledTabsProps>`
  &&& {
    .ant-tabs-tab {
      background-color: white;
      border-radius: 10px;
      border: 1px solid #b7c8d5;
      padding-left: 30px;
      padding-right: 30px;
      color: #4b4b4b;
      font-size: 17px;
      font-family: 'Manrope';
    }

    &&& {
      .ant-tabs-ink-bar {
        background-color: transparent !important;
      }
    }
    .ant-tabs-nav::before {
      border-bottom: 1px solid transparent;
    }
    .ant-tabs-tab {
      margin: 0 0 0 10px;
    }

    &&& .ant-tabs-tab-active {
      background: ${(props) => props.colorBackground ?? colors.purple} !important;
    }

    .anticon {
      color: #9b9eb3;
      font-size: 18px;
    }

    &&& {
      .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: white !important;
        font-size: 17px;
        .anticon {
          color: white;
          padding-right: 8px;
        }
      }
    }

    &&& .ant-tabs-tab-btn {
      color: #4b4b4b;
    }
  }
`
