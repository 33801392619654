import { TextLabel } from 'design-system/components'

import { GetBrokersByIdDto } from '@/App/clients/healthPlansOrder/dto'

import { InputDiv, Label } from './style'

type PixContentProps = {
  data?: GetBrokersByIdDto | null
}

export const PixContent = ({ data }: PixContentProps) => {
  return (
    <>
      <InputDiv>
        <Label>Possui CNPJ:</Label>
        <TextLabel
          text={data?.paymentInformation?.accountHolderCompanyDocument ? 'Sim' : 'Não'}
          backgroundColor="#f2f3f7"
          rightIconType="copy"
        />
      </InputDiv>

      <InputDiv>
        <Label>Forma de pagamento:</Label>
        <TextLabel text={'PIX'} backgroundColor="#f2f3f7" rightIconType="copy" />
      </InputDiv>

      <InputDiv>
        <Label>Chave Pix:</Label>
        <TextLabel
          text={data?.paymentInformation?.pixKey}
          backgroundColor="#f2f3f7"
          rightIconType="copy"
        />
      </InputDiv>
    </>
  )
}
