import { useEffect, useState } from 'react'

import { WidgetProps } from 'design-system/DynamicForm/DynamicForm'
import { AutoComplete, Error } from 'design-system/components'
import styled, { css } from 'styled-components'

import { type GooglePlacesPredictions, useGooglePlaces } from '@/App/utils/hooks/useGooglePlaces'

type FormItemProps = {
  colSpan?: string | number
}

export const FormItem = styled.div<FormItemProps>`
  ${({ colSpan = 1 }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 6.25rem;
    grid-column: span ${colSpan.toString()};
  `}
`

export const FormItemLabel = styled.label`
  font-weight: 500;
  color: #4b4b4b;
  text-align: start;
  margin: 0;
`

export function AutoCompleteWidget({
  rawErrors,
  placeholder,
  value,
  onChange,
  label,
  required,
  disabled,
  formContext,
}: WidgetProps) {
  const [companyAddress, setCompanyAddress] = useState(value)
  const [predictions, setPredictions] = useState<Array<GooglePlacesPredictions>>([])
  const placesService = useGooglePlaces(import.meta.env.VITE_GOOGLE_PLACES_API_KEY)

  const shouldDisableForCompanyOCR =
    formContext?.entityType === 'COMPANY' && !formContext?.isCnpjFilled

  useEffect(() => {
    setCompanyAddress(value)
  }, [value])

  const handleInputChange = (searchText: string) => {
    setCompanyAddress(searchText)
    if (placesService) {
      const request = {
        input: searchText,
      }

      placesService.getPlacePredictions(request, (results, status) => {
        if (status === 'OK') {
          setPredictions(results)
        }
      })
    }
  }

  const error = rawErrors
    ? {
        type: 'unknown',
        message: rawErrors.at(0),
      }
    : null

  return (
    <FormItem>
      <FormItemLabel>
        {label}
        {required ? '*' : ''}
      </FormItemLabel>
      <AutoComplete
        value={companyAddress}
        status={error ? 'error' : undefined}
        options={predictions.map((prediction) => ({
          value: prediction.description,
          label: prediction.description,
        }))}
        onChange={(value) => {
          onChange(value)
          setCompanyAddress(value)
        }}
        onSelect={(selectedValue) => {
          onChange(selectedValue)
          setCompanyAddress(selectedValue)
        }}
        onSearch={handleInputChange}
        placeholder={placeholder}
        disabled={shouldDisableForCompanyOCR || disabled || false}
      />
      {error && <Error error={error} />}
    </FormItem>
  )
}
