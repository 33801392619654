import React, { ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'

import LogoutFille from '../../icons/SignOutIcon'
import CloseIcon from '../../icons/close'
import {
  BackgroundMobile,
  BodyByBliss,
  ByBliss,
  CloseMenuMobile,
  Footer,
  HeaderMenu,
  Label,
  LabelMobile,
  Line,
  LogoImageDiv,
  LogoutMenuMobile,
  StyledDivLogo,
  StyledFooterMobile,
  StyledImg,
  StyledLogo,
  StyledMenu,
  StyledMenuMobile,
} from './style'

interface MenuItem {
  id: number
  url: string
  label: string
  icon?: ReactNode
}

export interface MenuProps {
  logoImageSrc?: string
  color?: string
  footerImage?: boolean
  items: MenuItem[]
  activeIndex: number
  mobileVisibility?: boolean
  setMobileVisibility?: React.Dispatch<React.SetStateAction<boolean>>
  signOut?: () => void
  fallbackLogo?: string
}

const Menu: React.FC<MenuProps> = ({
  items,
  footerImage = true,
  logoImageSrc,
  color,
  activeIndex = 0,
  mobileVisibility,
  setMobileVisibility,
  signOut,
  fallbackLogo,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const MobileMenu = () => {
    return (
      mobileVisibility && (
        <BackgroundMobile>
          <StyledMenuMobile>
            <CloseMenuMobile onClick={() => setMobileVisibility && setMobileVisibility(false)}>
              <CloseIcon />
              <LabelMobile>Fechar</LabelMobile>
            </CloseMenuMobile>
            <Line />
            <ul>
              {items.map((item, index) => (
                <li className={activeIndex === index ? 'active' : ''} key={item.id}>
                  <a href={item.url}>
                    {item.icon}
                    <LabelMobile>{item.label}</LabelMobile>
                  </a>
                </li>
              ))}
            </ul>
            <Line />
            <StyledFooterMobile>
              <LogoutMenuMobile onClick={signOut}>
                <LogoutFille />
                <LabelMobile>Sair</LabelMobile>
              </LogoutMenuMobile>

              <ByBliss>
                <BodyByBliss>
                  <span>Desenvolvido por</span>
                  <img
                    src="https://parcerias-bliss.data2.media/file/1rw85I0Ai8UMqBSbmJiDW"
                    alt="blissImage"
                  />
                </BodyByBliss>
              </ByBliss>
            </StyledFooterMobile>
          </StyledMenuMobile>
        </BackgroundMobile>
      )
    )
  }

  const DesktopMenu = () => {
    return (
      <StyledMenu color={color}>
        <HeaderMenu>
          {logoImageSrc ? (
            <LogoImageDiv>
              <StyledImg src={logoImageSrc} title="logoImage" />
            </LogoImageDiv>
          ) : (
            <StyledDivLogo color={color}>
              <StyledLogo color={color}>{fallbackLogo}</StyledLogo>
              <span>Seguros Saúde</span>
            </StyledDivLogo>
          )}
        </HeaderMenu>

        <ul>
          {items.map((item, index) => (
            <li className={activeIndex === index ? 'active' : ''} key={item.id}>
              <a href={item.url}>
                {item.icon}
                <Label>{item.label}</Label>
              </a>
            </li>
          ))}
        </ul>

        {footerImage ? (
          <Footer>
            <span>Desenvolvido por</span>
            <img
              src="https://parcerias-bliss.data2.media/file/1rw85I0Ai8UMqBSbmJiDW"
              alt="blissImage"
            />
          </Footer>
        ) : (
          ''
        )}
      </StyledMenu>
    )
  }

  return <div>{isMobile ? <MobileMenu /> : <DesktopMenu />}</div>
}

export default Menu
