import { QueryFunctionContext, queryOptions } from '@tanstack/react-query'

import { api } from '../../http'
import { BrokerCommissionPay } from '../dtos'

type GetBrokerCommissionPayBaseRequest = {
  filter: string
}

type GetBrokerCommissionPayRequest = QueryFunctionContext & GetBrokerCommissionPayBaseRequest

async function getBrokerCommissionPay({ filter, signal }: GetBrokerCommissionPayRequest) {
  return api
    .get<BrokerCommissionPay[]>(
      `${import.meta.env.VITE_FINOPS_SERVICES_URL}/comissao-corretores${
        filter ? '?$anyField=' + filter : ''
      }`,
      {
        signal,
        headers: { Authorization: localStorage.getItem('authToken') },
      },
    )
    .then((res) => res.data)
}

export function getBrokerCommissionPayQueryOptions({ filter }: GetBrokerCommissionPayBaseRequest) {
  return queryOptions({
    queryKey: ['brokerCommissionPay', { filter }] as const,
    queryFn: async (params) => getBrokerCommissionPay({ filter, ...params }),
    refetchOnWindowFocus: false,
  })
}
