import styled from 'styled-components'

export const OrderContentDiv = styled.div`
  display: flex;
  margin-bottom: 30px;
  margin-top: 30px;
`
export const OrderContentColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 25px;
`
