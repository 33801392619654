import styled from 'styled-components'

export const ManagerFilterContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`

export const SearchInputWrapper = styled.div`
  .ant-select-show-search {
    width: 100% !important;
  }
`
