import React, { ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'

import ArrowDownIcon from '../../icons/arrowDown'
import ArrowUpIcon from '../../icons/arrowUp'
import { Spinner } from '../components'
import { CollapseContent, CollapsePanel, FooterIconDiv, StyledCollapse } from './styles'

export interface AccordionProps {
  children: ReactNode
  iconPosition?: 'start' | 'end'
  ghost?: boolean
  collapsible?: 'header' | 'icon' | 'disabled'
  header: ReactNode
  footer?: {
    icon?: ReactNode
    onClick?: () => void
    isLoading?: boolean
  } | null
  fixedFooterSize?: boolean
  backgroundColor?: string | null
  innerPadding?: boolean
  borderless?: boolean
}

const Accordion: React.FC<AccordionProps> = ({
  header,
  children,
  iconPosition = 'end',
  ghost,
  collapsible,
  footer,
  fixedFooterSize = false,
  backgroundColor,
  innerPadding = true,
  borderless = false,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const getFooterIcon = () => {
    return (
      <FooterIconDiv
        fixedFooterSize={fixedFooterSize}
        onClick={(event) => {
          footer?.onClick && footer?.onClick()
          event.stopPropagation()
        }}
      >
        {footer?.isLoading ? <Spinner width="15px" height="15px" /> : footer?.icon}
      </FooterIconDiv>
    )
  }

  return (
    <>
      <StyledCollapse
        backgroundColor={backgroundColor}
        expandIconPosition={iconPosition}
        ghost={ghost}
        expandIcon={({ isActive }) => (isActive ? <ArrowUpIcon /> : <ArrowDownIcon />)}
      >
        <CollapsePanel
          backgroundColor={backgroundColor}
          innerPadding={innerPadding}
          collapsible={collapsible}
          key="1"
          extra={getFooterIcon()}
          header={<CollapseContent>{header}</CollapseContent>}
          isMobile={isMobile}
          borderless={borderless}
        >
          {children}
        </CollapsePanel>
      </StyledCollapse>
    </>
  )
}

export default Accordion
