import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'

import { insurers } from '../../../helper/porto/insurers'
import { issuingAgencies } from '../../../helper/porto/issuingAgencies'
import { kinships } from '../../../helper/porto/kinships'

export const dependentPortoSeguroHealthSchema: DynamicFormSchemaProps = {
  formContext: {
    hint: '*Campos de preenchimento obrigatório apenas para a emissão automática e/ou cadastro no sisweb',
  },
  schema: {
    type: 'object',
    properties: {
      name: {
        type: ['string', 'null'],
        title: 'Nome Completo*',
      },
      holderKinship: {
        type: ['string', 'null'],
        title: 'Grau de Parentesco*',
        oneOf: kinships.map((kinship) => ({
          title: kinship.label,
          const: kinship.value,
        })),
      },
      maritalStatus: {
        type: ['string', 'null'],
        title: 'Estado Civil*',
        oneOf: [
          { title: 'Solteiro', const: 'SINGLE' },
          { title: 'Casado', const: 'MARRIED' },
          { title: 'Viúvo', const: 'WIDOWED' },
          { title: 'Desquitado', const: 'SEPARATED' },
          { title: 'Divorciado', const: 'DIVORCED' },
          { title: 'Marital', const: 'MARITAL' },
        ],
      },
      gender: {
        type: ['string', 'null'],
        title: 'Gênero*',
        oneOf: [
          { title: 'Feminino', const: 'FEMALE' },
          { title: 'Masculino', const: 'MALE' },
        ],
      },
      cpf: {
        type: ['string', 'null'],
        title: 'CPF*',
      },
      rg: {
        type: ['string', 'null'],
        title: 'RG*',
      },
      issuingAgency: {
        type: ['string', 'null'],
        title: 'Órgão Emissor*',
        oneOf: issuingAgencies.map((agency) => ({
          const: agency.value,
          title: agency.label,
        })),
      },
      issuingDate: {
        type: ['string', 'null'],
        title: 'Data de Expedição*',
        format: 'date',
      },
      birthDate: {
        type: ['string', 'null'],
        title: 'Data de Nascimento*',
        format: 'date',
      },
      motherName: {
        type: ['string', 'null'],
        title: 'Nome da Mãe*',
      },
      isAdministrativeLife: {
        type: ['boolean', 'null'],
        title: 'É uma vida administrativa?',
        oneOf: [
          { title: 'Sim', const: true },
          { title: 'Não', const: false },
        ],
        default: null,
      },
      hiredHealthPlan: {
        type: 'object',
        properties: {
          isActive: {
            type: 'boolean',
            title: 'Possui plano médico?',
            oneOf: [
              { title: 'Sim', const: true },
              { title: 'Não', const: false },
            ],
            default: false,
          },
        },
        dependencies: {
          isActive: {
            oneOf: [
              {
                properties: {
                  isActive: {
                    enum: [false],
                  },
                },
              },
              {
                properties: {
                  isActive: {
                    enum: [true],
                  },
                  hiredHealthSubtitle: {
                    type: ['string', 'null'],
                    title: 'Dados do Plano Médico Atual',
                  },
                  activeHiredPlanInsurer: {
                    type: ['string', 'null'],
                    title: 'Operadora Atual',
                    oneOf: insurers.map((insurer) => ({
                      title: insurer.label,
                      const: insurer.value,
                    })),
                  },
                  hiredAt: {
                    type: ['string', 'null'],
                    format: 'date',
                    title: 'Data de Início',
                  },
                  lastPaymentAt: {
                    type: ['string', 'null'],
                    format: 'date',
                    title: 'Data do Último Pagamento',
                  },
                },
              },
            ],
          },
        },
      },
      healthPlan: {
        type: 'object',
        properties: {
          subtitle: {
            type: 'string',
            title: 'Dados Complementares (Cadastro Sisweb)',
          },
          lifeAmount: {
            type: ['string', 'null'],
            title: 'Valor Vida Saúde*',
          },
        },
      },
    },
    if: {
      properties: {
        maritalStatus: { const: 'MARRIED' },
      },
    },
    then: {
      properties: {
        marriageDate: {
          type: ['string', 'null'],
          title: 'Data do casamento*',
          format: 'date',
        },
      },
    },
  },
  uiSchema: {
    'ui:order': ['name', 'holderKinship', 'maritalStatus', 'marriageDate', '*'],
    name: {
      'ui:placeholder': 'Ex.: Maria da Silva',
    },
    maritalStatus: {
      'ui:placeholder': 'Selecione o estado civil',
    },
    marriageDate: {
      'ui:placeholder': '00/00/0000',
    },
    holderKinship: {
      'ui:placeholder': 'Selecione o grau de parentesco',
    },
    gender: {
      'ui:placeholder': 'Selecione o gênero',
    },
    cpf: {
      'ui:placeholder': '000.000.000-00',
      'ui:options': {
        mask: 'cpf',
      },
    },
    rg: {
      'ui:placeholder': '000000000',
    },
    issuingAgency: {
      'ui:placeholder': 'Selecione o órgão emissor',
    },
    issuingDate: {
      'ui:placeholder': '00/00/0000',
    },
    birthDate: {
      'ui:placeholder': '00/00/0000',
    },
    motherName: {
      'ui:placeholder': 'Ex.: Ana da Silva',
    },
    isAdministrativeLife: {
      'ui:widget': 'radio',
    },
    hiredHealthPlan: {
      'ui:options': {
        colSpan: 2,
      },
      isActive: {
        'ui:widget': 'radio',
        'ui:options': {
          colSpan: 2,
        },
      },
      hiredHealthSubtitle: {
        'ui:widget': 'SubtitleWidget',
      },
      activeHiredPlanInsurer: {
        'ui:placeholder': 'Selecione a operadora atual',
      },
      hiredAt: {
        'ui:placeholder': '00/00/0000',
      },
      lastPaymentAt: {
        'ui:placeholder': '00/00/0000',
      },
    },
    healthPlan: {
      'ui:options': {
        colSpan: 2,
      },
      subtitle: {
        'ui:widget': 'SubtitleWidget',
      },
      lifeAmount: {
        'ui:placeholder': 'R$ 00,00',
        'ui:options': {
          mask: 'money',
        },
      },
    },
  },
}
