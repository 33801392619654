import { type QueryFunctionContext, queryOptions, useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'

import { api } from '../../http'
import type { PersonDispatchEvent } from '../dtos'

type PersonDispatchEventHookProps = {
  enabled?: boolean
  personInternalId?: string | null
}

type GetLatestPersonDispatchEventProps = QueryFunctionContext & {
  personInternalId?: string | null
}

async function getLatestPersonDispatchEvent({
  personInternalId,
  signal,
}: GetLatestPersonDispatchEventProps) {
  const response = await api.get<PersonDispatchEvent>(
    `${
      import.meta.env.VITE_BLISS_ORDER_GATEWAY_API_URL
    }/persons/${personInternalId}/dispatch-events`,
    { signal },
  )

  return response.data.latestDispatchEvent ?? null
}

export const getLatestPersonDispatchEventOptions = ({
  enabled,
  personInternalId,
}: PersonDispatchEventHookProps) => {
  return queryOptions({
    queryKey: ['entity', personInternalId, 'dispatchEvent'] as const,
    queryFn: (params) => getLatestPersonDispatchEvent({ ...params, personInternalId }),
    enabled: !!personInternalId && enabled,
    refetchInterval(query) {
      if (query.state.data?.processingStatus !== 'PENDING') {
        return false
      }

      return 1000 * 30 // 30 seconds
    },
    select(data) {
      if (!data) {
        return null
      }

      return {
        ...data,
        formattedCreatedAt: data?.createdAt ? dayjs(data?.createdAt).format('DD/MM HH:mm') : null,
      }
    },
  })
}

export const useLatestPersonDispatchEvent = (params: PersonDispatchEventHookProps) => {
  return useQuery(getLatestPersonDispatchEventOptions(params))
}
