import React, { useState } from 'react'

import { CloudUploadOutlined } from '@ant-design/icons'
import Upload, { RcFile, UploadChangeParam, UploadFile } from 'antd/es/upload'

import { StyledText, StyledUploadArea } from './style'

export interface OnFileUploadInput {
  fileContent: Blob
  fileContentType?: string
  fileName: string
  reference?: string
}

export interface Props {
  onDrop?: (event: React.DragEvent) => void
  onFileUpload?: (files: OnFileUploadInput[]) => void
  name?: string
  beforeUpload?: () => boolean
  reference?: string
  onSuccess?: () => void
  disabled?: boolean
}

const UploadArea: React.FC<Props> = ({
  name,
  onFileUpload,
  onDrop,
  reference,
  onSuccess,
  disabled = false,
}) => {
  const [filesQuantityError, setFilesQuantityError] = useState(false)

  const onChange = async (file: UploadChangeParam<UploadFile<RcFile>>, reference?: string) => {
    const fileContentRetrievePromises = file.fileList.map(
      (file) =>
        new Promise((resolve) => {
          if (!file.originFileObj) {
            return resolve(file)
          }
          const reader = new FileReader()

          reader.readAsArrayBuffer(file.originFileObj)
          reader.onload = () => resolve(reader.result as ArrayBuffer)
        }),
    )

    const fileContents = await Promise.all(fileContentRetrievePromises)

    const result = fileContents.map((content, index) => {
      const fileContentOutput = new Blob([content as ArrayBuffer], {
        type: file.fileList[index].type,
      })

      return {
        fileContent: fileContentOutput,
        fileName: file.fileList[index].name,
        fileContentType: file.fileList[index].type,
        reference,
      }
    })

    onFileUpload && onFileUpload(result)
  }

  const beforeUpload = (files: RcFile[]): boolean | string => {
    if (files.length > 8) {
      setFilesQuantityError(true)
      return Upload.LIST_IGNORE
    }

    setFilesQuantityError(false)
    onSuccess && onSuccess()
    return false
  }

  return (
    <StyledUploadArea
      name={name}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      multiple={true}
      onChange={(file) => onChange(file, reference)}
      onDrop={onDrop}
      beforeUpload={(_file, fileList) => beforeUpload(fileList)}
      maxCount={8}
      disabled={disabled}
    >
      <StyledText isErrorEnabled={filesQuantityError}>
        <CloudUploadOutlined />{' '}
        {filesQuantityError
          ? 'Por favor, selecione no máximo 8 arquivos'
          : 'Clique ou arraste até 8 arquivos por vez aqui'}
      </StyledText>
    </StyledUploadArea>
  )
}

export default UploadArea
