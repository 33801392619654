const WarningIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 15 15">
      <path
        fill="#BF404C"
        d="M.625 13.125h13.75L7.5 1.25.625 13.125zm7.5-1.875h-1.25V10h1.25v1.25zm0-2.5h-1.25v-2.5h1.25v2.5z"
      ></path>
    </svg>
  )
}

export default WarningIcon
