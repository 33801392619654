import styled from 'styled-components'

export interface StyledSpinnerProps {
    color?: string;
    width?: string;
    height?: string;
}

export const StyledSpinner = styled.span<StyledSpinnerProps>`
    width: ${props => (props.width ?? '32px')};
    height: ${props => (props.height ?? '32px')};
    border: 3px solid ${props => (props.color ?? '#601A5A')};
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
`

