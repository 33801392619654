import styled from 'styled-components'

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 35px;

  & svg {
    font-size: 50px;
  }
`
export const ButtonDiv = styled.div`
  margin-top: 20px;
  width: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const CancelButton = styled.button`
  margin-top: 20px;
  border: none;
  background: white;
  border-bottom: 1px solid;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`

export const ModalTitle = styled.p`
  color: #4b4b4b;
  font-size: 17px;
  font-weight: 800;
  margin-bottom: 3px;
`
export const ModalSubTitle = styled.p`
  color: #4b4b4b;
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
`
export const Icon = styled.div`
  & svg {
    font-size: 50px;
  }
`
