import { useFieldArray, useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import { Button, DatePicker, Modal, Select, Upload } from 'design-system/components'
import Exclamation from 'design-system/exclamation'
import { triggerToast } from 'design-system/triggers'
import { v4 as uuidv4 } from 'uuid'
import { z } from 'zod'

import type { GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import { useUploadOrderEntityDocument } from '@/App/clients/healthPlansOrders/mutations/uploadOrderEntityDocument'
import { useDispatchPersonEvent } from '@/App/clients/orderGateway/mutations/dispatchPersonEvent'
import { useDynamicForm } from '@/App/contexts/dynamicForm/DynamicFormProvider'

import { cancelReasonsAmil } from '../../helper/amil/cancelReasons'
import { cancelReasonsGNDI } from '../../helper/gndi/cancelReasons'
import * as S from './style'

const dispatchSubmitBodySchema = z.object({
  canceledAt: z.string(),
  cancelReason: z.string(),
  documents: z
    .array(
      z.object({
        uid: z.string(),
        fileContent: z.instanceof(Blob),
        fileContentType: z.string().nullish(),
        fileName: z.string(),
      }),
    )
    .default([]),
})

type DispatchSubmitBodySchema = z.infer<typeof dispatchSubmitBodySchema>

interface DispatchEntityExcludeEventConfirmationModalProps {
  orderData?: GetOrderInformationBody | null
}

interface HandleDispatchExcludeProps extends Partial<DispatchSubmitBodySchema> {
  isDispatchedManually: boolean
}

const cancelReasons = {
  Amil: cancelReasonsAmil,
  GNDI: cancelReasonsGNDI,
  'Notre Dame Intermédica': cancelReasonsGNDI,
}

export const DispatchEntityExcludeEventConfirmationModal = ({
  orderData,
}: DispatchEntityExcludeEventConfirmationModalProps) => {
  const { entityExclusionDispatchModal, setEntityExclusionDispatchModal } = useDynamicForm()
  const dispatchEntityExcludeEventMutation = useDispatchPersonEvent({
    entityId: entityExclusionDispatchModal?.entity?.id,
  })

  const { control, handleSubmit, watch, reset } = useForm<DispatchSubmitBodySchema>({
    resolver: zodResolver(dispatchSubmitBodySchema),
  })
  const { insert, remove, fields } = useFieldArray({
    control,
    name: 'documents',
  })
  const uploadOrderEntityDocumentMutation = useUploadOrderEntityDocument()

  const cancelReason = watch('cancelReason')
  const canceledAt = watch('canceledAt')
  const documents = watch('documents')

  async function closeModal() {
    setEntityExclusionDispatchModal({ isOpen: false })
    reset()
  }

  async function handleDispatchEntityExcludeSubmit({
    cancelReason,
    canceledAt,
    documents,
  }: DispatchSubmitBodySchema) {
    await handleDispatchExclude({
      isDispatchedManually: false,
      canceledAt,
      cancelReason,
      documents,
    })
  }

  async function handleDispatchExclude(params: HandleDispatchExcludeProps) {
    const document = params.documents?.at(0) || documents.at(0) || null

    const entities = entityExclusionDispatchModal.entity
      ? [entityExclusionDispatchModal.entity]
      : []

    if (entityExclusionDispatchModal.entityType === 'HOLDER_GROUP') {
      const dependents = entityExclusionDispatchModal.entity?.dependents || []

      for (const dependent of dependents) {
        entities.push({ ...dependent, type: 'DEPENDENT' })
      }
    }

    try {
      if (document) {
        for (const entity of entities) {
          const formData = new FormData()
          formData.append('fileName', document.fileName)
          formData.append('fileType', 'Formulário de movimentação')

          const { documentId, uploadURL } = await uploadOrderEntityDocumentMutation.mutateAsync({
            data: formData,
            orderId: orderData?.id,
            entityId: entity.id,
          })

          entity.documents = [
            {
              id: documentId,
              type: 'Formulário de movimentação',
              fileName: document.fileName,
              status: 'UPLOADED',
              previewURL: uploadURL ?? undefined,
            },
          ]
        }
      }

      await dispatchEntityExcludeEventMutation.mutateAsync({
        type: 'EXCLUSION',
        isDispatchedManually: params.isDispatchedManually,
        entities,
        insurer: orderData?.quotation?.insurer,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        entityType: entityExclusionDispatchModal.entityType!,
        company: {
          document: orderData?.company?.document,
        },
        quotation: {
          isCombinedProposal: orderData?.quotation?.isCombinedProposal,
          type: orderData?.quotation?.product,
        },
        metadata: {
          canceledAt: params.canceledAt || canceledAt || null,
          cancelReason: params.cancelReason || cancelReason || null,
        },
      })

      closeModal()
      triggerToast(true, 'Exclusão de vida solicitada com sucesso!')
    } catch (error) {
      triggerToast(
        false,
        'Não foi possível solicitar a exclusão de vida. Tente novamente mais tarde.',
      )
    }
  }

  return (
    <Modal
      isOpen={entityExclusionDispatchModal.isOpen}
      width={677}
      setIsOpen={() => closeModal()}
      footer={false}
    >
      <S.ModalContainer>
        <S.Icon>
          <Exclamation />
        </S.Icon>
        <S.ModalHeader>
          <S.ModalTitle>Tem certeza que deseja solicitar a exclusão de beneficiários?</S.ModalTitle>
          <S.ModalSubTitle>
            Ao confirmar, será realizado a modificação de beneficiários na proposta e não será
            possível editá-la.
          </S.ModalSubTitle>
        </S.ModalHeader>

        <S.ModalForm onSubmit={handleSubmit(handleDispatchEntityExcludeSubmit)}>
          <S.ModalFormContent>
            <S.FormItem>
              <S.FormItemLabel>Data de Cancelamento</S.FormItemLabel>
              <DatePicker name="canceledAt" control={control} />
            </S.FormItem>
            <S.FormItem>
              <S.FormItemLabel>Motivo de cancelamento*</S.FormItemLabel>
              <Select
                control={control}
                options={cancelReasons[orderData?.quotation?.insurer ?? '']}
                defaultValue="Selecione o motivo"
                name="cancelReason"
                value={cancelReason ?? null}
              />
            </S.FormItem>
            {['Amil'].includes(orderData?.quotation?.insurer ?? '') ? (
              <S.FormItem>
                <S.FormItemLabel>Formulário de movimentação</S.FormItemLabel>
                <Upload
                  text="Insira o PDF de movimentação"
                  control={control}
                  onFileUpload={(files) => {
                    if (fields.length === 1) {
                      remove(0)
                    }

                    insert(0, {
                      ...files[0],
                      uid: uuidv4(),
                    })
                  }}
                  onRemove={() => remove()}
                  maxCount={1}
                  fileList={documents
                    ?.filter((doc) => doc.fileName)
                    ?.map((doc) => ({ name: doc.fileName, uid: doc.uid }))}
                />
              </S.FormItem>
            ) : null}
          </S.ModalFormContent>

          <S.ActionContainer>
            <Button
              htmlType="submit"
              loading={
                uploadOrderEntityDocumentMutation.isPending ||
                dispatchEntityExcludeEventMutation.isPending
              }
            >
              Solicitar exclusão automática
            </Button>
            <S.CancelButton
              type="button"
              onClick={() => handleDispatchExclude({ isDispatchedManually: true })}
              disabled={
                uploadOrderEntityDocumentMutation.isPending ||
                dispatchEntityExcludeEventMutation.isPending
              }
            >
              Exclusão já realizada (manual)
            </S.CancelButton>
          </S.ActionContainer>
        </S.ModalForm>
      </S.ModalContainer>
    </Modal>
  )
}
