import styled from 'styled-components'

export const AccordionBodyDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Label = styled.label`
  font-size: 14px;
  justify-self: end;
  margin-right: 10px;
`
export const InputDiv = styled.div`
  display: grid;
  width: 700px;
  grid-template-columns: 0.5fr 1fr;
  margin: 10px;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: block !important;
    width: 100%;
  }
`
export const RegisteredOperatorsDiv = styled.div``

export const AccordionHeader = styled.div`
  justify-content: flex-start;
  & svg {
    margin-right: 10px;
  }
`
