import styled from 'styled-components'

export const ExpandableRowDiv = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  padding: 20px 30px 30px 30px;
`

export const ExecutionDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: 10px;
`

export const ExecutionDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ExecutionDetailsItemTitle = styled.p`
  font-weight: 600;
  margin: 0;
  align-self: end;
  width: 60%;
`

export const ExecutionDetailsItemValue = styled.p`
  margin: 0 0 0 5px;
  width: 40%;
  text-align: right;
`

export const ExecutionDetailsSelectItemValue = styled.div`
  display: flex;
  align-items: end;
  margin: 0 0 0 5px;

  width: 100%;
`

export const SelectOptionLabel = styled.span`
  margin-left: 8px;
`

export const SaveButtonDiv = styled.div`
  margin-top: 30px;
`
