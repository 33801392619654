import { useMutation, useQueryClient } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '../../http'
import { ChangeBrokerCommissionGridPayPayload } from '../dtos'

async function changeBrokerCommissionGridPay(data: ChangeBrokerCommissionGridPayPayload) {
  const url = `${import.meta.env.VITE_FINOPS_SERVICES_URL}/comissao-corretores`

  return api.patch(url, data, {
    headers: {
      Authorization: localStorage.getItem('authToken'),
    },
  })
}

export function useChangeBrokerCommissionGridPayMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['brokerCommissionGridPay', 'change'] as const,
    mutationFn: async (data: ChangeBrokerCommissionGridPayPayload) =>
      changeBrokerCommissionGridPay(data),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['brokerCommissionPay'] })
    },
    onError: () => {
      triggerToast(false, 'Erro ao realizar alteração')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['brokerCommissionPay'],
      })

      triggerToast(true, 'Alteração realizada com sucesso')
    },
  })
}
