import styled, { keyframes } from 'styled-components'

export const AccordionBodyDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Label = styled.label`
  font-size: 14px;
  justify-self: end;
`
export const InputDiv = styled.div`
  display: grid;
  width: 700px;
  grid-template-columns: 0.4fr 1fr;
  margin: 10px;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: block !important;
    width: 100%;
  }
`

export const AccordionHeader = styled.div`
  justify-content: flex-start;
  & svg {
    margin-right: 10px;
  }
`

const skeletonLoadingAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
  `

export const SkeletonCard = styled.div`
  width: 100%;
  height: 130px;
  background: linear-gradient(90deg, #eeeeee 25%, #dedede 50%, #eeeeee 75%);
  background-size: 200% 100%;
  animation: ${skeletonLoadingAnimation} 2s infinite ease-in-out;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
`
