import { useMutation, useQueryClient } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '@/App/clients/http'

import { FillQuotationInformationBody } from '../dtos'

export type FillQuotationInformationData = {
  data: FillQuotationInformationBody
  brokerId?: string
  leadId?: string
  quotationId?: string
}

const fillQuotationInformation = async ({
  data,
  brokerId,
  leadId,
  quotationId,
}: FillQuotationInformationData) => {
  const response = await api.patch(
    `${
      import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
    }/api/v1/brokers/${brokerId}/leads/${leadId}/quotations/${quotationId}`,
    data,
    {
      headers: { Authorization: localStorage.getItem('authToken') },
    },
  )
  return response.data
}

export const useFillQuotationInformation = (
  fillQuotationInformationData?: FillQuotationInformationData,
) => {
  const queryClient = useQueryClient()

  const brokerId = fillQuotationInformationData?.brokerId
  const leadId = fillQuotationInformationData?.leadId
  const quotationId = fillQuotationInformationData?.quotationId

  return useMutation({
    mutationKey: ['brokers', brokerId, leadId, quotationId],
    mutationFn: fillQuotationInformation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['order'] })
      triggerToast(true, 'Cotação atualizada com sucesso!')
    },
    onError: () => {
      triggerToast(false, 'Ops, ocorreu um erro ao atualizar a cotação.')
    },
  })
}
