const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 10 10">
      <path
        fill="#878EA6"
        d="M10 1.007L8.993 0 5 3.993 1.007 0 0 1.007 3.993 5 0 8.993 1.007 10 5 6.007 8.993 10 10 8.993 6.007 5 10 1.007z"
      ></path>
    </svg>
  )
}

export default CloseIcon
