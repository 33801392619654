import { useEffect, useState } from 'react'

import CheckCircleIcon from 'design-system/checkCircle'
import { SwitchButton } from 'design-system/components'
import FailCircleIcon from 'design-system/failCircle'
import InProgressIcon from 'design-system/inProgressCircle'

import type { PersonDispatchEvent } from '@/App/clients/orderGateway/dtos'
import { useDynamicForm } from '@/App/contexts/dynamicForm/DynamicFormProvider'
import { calculateProcessingTime } from '@/App/utils/calculateProcessingTime'

import * as S from './styles'

interface DisptachEntityFooterProps {
  dispatchEvent?: Partial<PersonDispatchEvent['latestDispatchEvent']> | null
}

export const DispatchEntityFooter = ({ dispatchEvent }: DisptachEntityFooterProps) => {
  const { setEntityInclusionDispatchModal } = useDynamicForm()
  const [isDispatchedSuccessfully, setIsDispatchedSuccessfully] = useState(
    dispatchEvent?.processingStatus === 'SUCCESS',
  )

  useEffect(() => {
    setIsDispatchedSuccessfully(dispatchEvent?.processingStatus === 'SUCCESS')
  }, [dispatchEvent?.processingStatus])

  function toggleIsRegisteredInInsurer() {
    setEntityInclusionDispatchModal({
      isOpen: true,
      origin: 'INTRANET',
    })
  }

  const processingTime = calculateProcessingTime(
    dispatchEvent?.createdAt,
    dispatchEvent?.processingStatus,
    dispatchEvent?.updatedAt,
  )

  const processingStatus =
    {
      SUCCESS: {
        icon: <CheckCircleIcon />,
        label: 'Concluído',
      },
      PENDING: {
        icon: <InProgressIcon />,
        label: 'Em andamento',
      },
      FAILURE: {
        icon: <FailCircleIcon />,
        label: 'Ocorreu falha',
      },
    }[dispatchEvent?.processingStatus ?? ''] ?? null

  return (
    <>
      <S.DispatchEntityFooterContainer>
        {dispatchEvent && dispatchEvent.origin === 'AUTOMATION' && processingStatus ? (
          <S.DispatchEntityEventContainer processingStatus={dispatchEvent?.processingStatus}>
            {processingStatus.icon}
            <div>
              <div>
                <S.DispatchEventTitle>{processingStatus.label}</S.DispatchEventTitle>
                <S.DispatchEventSubtitleStatus>{processingTime}</S.DispatchEventSubtitleStatus>
              </div>
              {dispatchEvent?.errorReason && dispatchEvent.processingStatus === 'FAILURE' ? (
                <div>
                  <S.DispatchEventTitle>Motivo</S.DispatchEventTitle>
                  <S.DispatchEventSubtitleStatus>{`->${dispatchEvent.errorReason}`}</S.DispatchEventSubtitleStatus>
                </div>
              ) : null}
            </div>
          </S.DispatchEntityEventContainer>
        ) : null}

        {dispatchEvent?.type === 'INCLUSION' ? (
          <S.DispatchEntitySwitchContainer>
            <S.DispatchEntitySwitchLabel>Incluído na operadora:</S.DispatchEntitySwitchLabel>
            <SwitchButton
              isLoading={false}
              disabled={isDispatchedSuccessfully}
              checked={isDispatchedSuccessfully}
              onChange={toggleIsRegisteredInInsurer}
              color="#514984"
            />
          </S.DispatchEntitySwitchContainer>
        ) : null}
      </S.DispatchEntityFooterContainer>
    </>
  )
}
