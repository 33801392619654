import { useMutation } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '@/App/clients/http'

import { ExternalPerson } from '../dtos'

export type FillExternalManagerInformationData = {
  data: ExternalPerson
  orderId?: string | null
}

const fillExternalManagerInformation = async ({
  data,
  orderId,
}: FillExternalManagerInformationData) => {
  const response = await api.patch(
    `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/orders/${orderId}/external-managers`,
    { ...data },
    {
      headers: { Authorization: localStorage.getItem('authToken') },
    },
  )
  return response.data
}

export const useFillExternalManagerInformation = (
  fillExternalManagerInformationData?: FillExternalManagerInformationData,
) => {
  const orderId = fillExternalManagerInformationData?.orderId

  return useMutation({
    mutationKey: ['externalManager', orderId],
    mutationFn: fillExternalManagerInformation,
    onSuccess: () => {
      triggerToast(true, 'Supervisor externo atualizado com sucesso!')
    },
    onError: () => {
      triggerToast(false, 'Ops, ocorreu um erro ao atualizar as informações.')
    },
  })
}
