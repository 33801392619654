import type { GetSchemaProps } from '..'
import { dependentDefaultSchema } from './default'
import { dependentDentalSchemas } from './dental'
import { dependentHealthSchemas } from './health'
import { dependentHealthAndDentalSchemas } from './healthAndDental'

export const getDependentSchema = ({
  insurer,
  product,
  countries,
  orderType,
  isRegisterInInsurer,
  planOptions,
  odontoPlanOptions,
  isCombinedProposal,
  enableHiredFields,
}: GetSchemaProps) => {
  let schema = dependentDefaultSchema as unknown

  if (!insurer || orderType === 'PERSON') {
    return dependentDefaultSchema
  }

  if (product?.toUpperCase() === 'HEALTH') {
    schema = isRegisterInInsurer
      ? dependentHealthSchemas.default
      : dependentHealthSchemas[insurer] || dependentHealthSchemas.default
  }

  if (product?.toUpperCase() === 'DENTAL') {
    schema = isRegisterInInsurer
      ? dependentDentalSchemas.default
      : dependentDentalSchemas[insurer] || dependentDentalSchemas.default
  }

  if (isCombinedProposal) {
    schema = isRegisterInInsurer
      ? dependentHealthAndDentalSchemas.default
      : dependentHealthAndDentalSchemas[insurer] || dependentHealthAndDentalSchemas.default
  }

  return typeof schema === 'function'
    ? schema({ insurer, product, countries, planOptions, odontoPlanOptions, enableHiredFields })
    : schema
}
