import React from 'react'
import { FieldError, UseFormRegisterReturn } from 'react-hook-form'

import Error from '../Error/Error'
import { StyledInput, StyledLabel, StyledSpan } from './style'

export interface Option {
  label: string
  value: string
}

export interface Props {
  labelColor?: string
  error?: FieldError | null
  register?: UseFormRegisterReturn
  options: Option[]
  onChange?: (value: string) => void
  checked?: string
  name?: string
  defaultValue?: string
  disabled?: boolean
  id?: string
}

const RadioGroup: React.FC<Props> = ({
  disabled,
  options,
  register,
  error,
  onChange,
  labelColor,
  name,
  defaultValue,
  id,
}) => {
  return (
    <>
      {options.map((option) => (
        <StyledLabel key={option.value} labelColor={labelColor}>
          <StyledInput
            disabled={disabled}
            onChange={(event) => onChange && onChange(event.target.value)}
            type="radio"
            value={option.value}
            name={id || name}
            defaultChecked={option.value === defaultValue}
            {...register}
          />
          <StyledSpan disabled={disabled} />
          {option.label}
        </StyledLabel>
      ))}
      {error && <Error error={error}></Error>}
    </>
  )
}

export default RadioGroup
