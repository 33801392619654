import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'
import { insurers } from 'design-system/insurers'

import type { GetSchemaProps } from '../..'
import { contractTypes } from '../../../helper/amil/contractType'

export function holderDefaultHealthAndDentalSchema({
  planOptions,
  isOcrFilled,
  genderPrediction,
  odontoPlanOptions,
  enableHiredFields,
}: GetSchemaProps): DynamicFormSchemaProps {
  return {
    formContext: {
      hint: '*Campos de preenchimento obrigatório apenas para a emissão automática e/ou cadastro no sisweb',
    },
    schema: {
      type: 'object',
      properties: {
        name: {
          type: ['string', 'null'],
          title: 'Nome Completo*',
        },
        phone: {
          type: ['string', 'null'],
          title: 'Telefone*',
        },
        mail: {
          type: ['string', 'null'],
          title: 'E-mail',
          format: 'email',
        },
        maritalStatus: {
          type: ['string', 'null'],
          title: 'Estado Civil',
          oneOf: [
            { title: 'Solteiro', const: 'SINGLE' },
            { title: 'Casado', const: 'MARRIED' },
            { title: 'Divorciado', const: 'DIVORCED' },
            { title: 'Viúvo', const: 'WIDOWED' },
            { title: 'Separado', const: 'SEPARATED' },
            { title: 'União Estável', const: 'COMMON_LAW_MARRIAGE' },
          ],
          default: null,
        },
        gender: {
          type: ['string', 'null'],
          title: 'Gênero*',
          oneOf: [
            { title: 'Feminino', const: 'FEMALE' },
            { title: 'Masculino', const: 'MALE' },
            { title: 'Não binário', const: 'NON_BINARY' },
          ],
          default: genderPrediction?.toUpperCase() ?? null,
        },
        birthDate: {
          type: ['string', 'null'],
          title: 'Data de Nascimento*',
          format: 'date',
        },
        cpf: {
          type: ['string', 'null'],
          title: 'CPF*',
        },
        rg: {
          type: ['string', 'null'],
          title: 'RG*',
        },
        motherName: {
          type: ['string', 'null'],
          title: 'Nome da mãe*',
        },
        isAdministrativeLife: {
          type: ['boolean', 'null'],
          title: 'É uma vida administrativa?',
          oneOf: [
            { title: 'Sim', const: true },
            { title: 'Não', const: false },
          ],
          default: null,
        },
        address: {
          type: 'object',
          properties: {
            zipCode: {
              type: ['string', 'null'],
              title: 'CEP*',
            },
            entityAddress: {
              type: ['string', 'null'],
              title: 'Endereço*',
            },
            number: {
              type: ['string', 'null'],
              title: 'Número*',
            },
            complement: {
              type: ['string', 'null'],
              title: 'Complemento',
            },
            neighborhood: {
              type: ['string', 'null'],
              title: 'Bairro',
            },
            city: {
              type: ['string', 'null'],
              title: 'Cidade',
            },
            state: {
              type: ['string', 'null'],
              title: 'Estado',
            },
          },
        },
        healthPlan: {
          type: 'object',
          properties: {
            catalogPlanId: {
              type: ['string', 'null'],
              title: 'Plano Médico*',
              oneOf:
                planOptions && planOptions?.length > 0
                  ? planOptions.map((plan) => ({
                      title: plan.label,
                      const: plan.value,
                    }))
                  : [{ title: 'Selecione o plano', const: '' }],
            },
            accommodationType: {
              type: ['string', 'null'],
              title: 'Acomodação*',
              oneOf: [
                { title: 'Apartamento', const: 'Apartamento' },
                { title: 'Enfermaria', const: 'Enfermaria' },
                { title: 'Odonto', const: 'Odonto' },
              ],
            },
            lifeAmount: {
              type: ['string', 'null'],
              title: 'Valor Vida Saúde*',
            },
          },
        },
        dentalPlan: {
          type: 'object',
          properties: {
            catalogPlanId: {
              type: ['string', 'null'],
              title: 'Plano Dental*',
              oneOf:
                odontoPlanOptions && odontoPlanOptions?.length > 0
                  ? odontoPlanOptions.map((plan) => ({
                      title: plan.label,
                      const: plan.value,
                    }))
                  : [{ title: 'Selecione o plano', const: '' }],
            },
            accommodationType: {
              type: ['string', 'null'],
              title: 'Acomodação odonto*',
              oneOf: [
                { title: 'Apartamento', const: 'Apartamento' },
                { title: 'Enfermaria', const: 'Enfermaria' },
                { title: 'Odonto', const: 'Odonto' },
              ],
            },
            lifeAmount: {
              type: ['string', 'null'],
              title: 'Valor Vida Odonto*',
            },
          },
        },
        hiredHealthPlan: {
          type: 'object',
          properties: {
            isActive: {
              type: 'boolean',
              title: 'Possui plano?',
              oneOf: [
                { title: 'Sim', const: true },
                { title: 'Não', const: false },
              ],
              default: false,
            },
          },
          dependencies: {
            isActive: {
              oneOf: [
                {
                  properties: {
                    isActive: {
                      enum: [false],
                    },
                  },
                },
                {
                  properties: {
                    isActive: {
                      enum: [true],
                    },
                    activeHiredPlanInsurer: {
                      type: ['string', 'null'],
                      title: 'Operadora',
                      oneOf: insurers.map((insurer) => ({
                        title: insurer.label,
                        const: insurer.value,
                      })),
                    },
                  },
                },
              ],
            },
          },
        },
        isLegalAge: {
          type: 'boolean',
        },
        insurer: {
          type: 'string',
        },
      },
      dependencies: {
        isLegalAge: {
          oneOf: [
            {
              properties: {
                isLegalAge: {
                  enum: [true],
                },
              },
            },
            {
              properties: {
                isLegalAge: {
                  enum: [false],
                },
                financialOfficer: {
                  type: 'object',
                  properties: {
                    financialOfficerSubtitle: {
                      type: 'string',
                      title: 'Responsável Financeiro',
                    },
                    name: {
                      type: 'string',
                      title: 'Nome Completo',
                    },
                    mail: {
                      type: 'string',
                      title: 'E-mail',
                      format: 'email',
                    },
                    phone: {
                      type: 'string',
                      title: 'Telefone',
                    },
                    cpf: {
                      type: 'string',
                      title: 'CPF*',
                    },
                    birthDate: {
                      type: 'string',
                      title: 'Data Nascimento',
                      format: 'date',
                    },
                  },
                },
              },
            },
          ],
        },
        insurer: {
          oneOf: [
            {
              properties: {
                insurer: {
                  enum: ['Amil'],
                },
                contractType: {
                  type: ['string', 'null'],
                  title: 'Tipo de Contratação*',
                  oneOf: contractTypes.map((contractType) => ({
                    const: contractType.value,
                    title: contractType.label,
                  })),
                },
                contractDate: {
                  type: ['string', 'null'],
                  title: 'Data de Contratação*',
                  format: 'date',
                },
                height: {
                  type: ['string', 'null'],
                  title: 'Altura (m)',
                },
                weight: {
                  type: ['string', 'null'],
                  title: 'Peso (kg)',
                },
              },
            },
          ],
        },
      },
    },
    uiSchema: {
      'ui:order': [
        'name',
        'phone',
        'mail',
        'maritalStatus',
        'gender',
        'birthDate',
        'cpf',
        'rg',
        'motherName',
        'isAdministrativeLife',
        'contractType',
        'contractDate',
        'height',
        'weight',
        '*',
      ],
      name: {
        'ui:placeholder': 'Ex.: Maria da Silva',
        'ui:disabled': isOcrFilled,
        'ui:widget': 'OCRDisabledTextInputWidget',
      },
      phone: {
        'ui:placeholder': '(00) 00000-0000',
        'ui:options': {
          mask: 'phone',
        },
      },
      mail: {
        'ui:placeholder': 'exemplo@email.com',
        'ui:widget': 'TextWidget',
      },
      maritalStatus: {
        'ui:placeholder': 'Selecione o estado civil',
      },
      gender: {
        'ui:placeholder': 'Selecione o gênero',
      },
      birthDate: {
        'ui:placeholder': '00/00/0000',
      },
      address: {
        'ui:options': {
          colSpan: 2,
        },
        zipCode: {
          'ui:placeholder': '00000-000',
          'ui:disabled': isOcrFilled,
          'ui:options': {
            mask: 'cep',
          },
        },
        entityAddress: {
          'ui:placeholder': 'Buscar endereço',
          'ui:disabled': isOcrFilled,
          'ui:widget': 'AutoCompleteWidget',
        },
        number: {
          'ui:placeholder': '000',
          'ui:disabled': isOcrFilled,
        },
        complement: {
          'ui:placeholder': 'Ex.: Apto 123',
        },
        neighborhood: {
          'ui:placeholder': 'Ex.: Pinheiros',
          'ui:disabled': isOcrFilled,
        },
        city: {
          'ui:placeholder': 'Ex.: São Paulo',
          'ui:disabled': isOcrFilled,
        },
        state: {
          'ui:placeholder': 'Ex.: SP',
          'ui:disabled': isOcrFilled,
        },
      },
      cpf: {
        'ui:placeholder': '000.000.000-00',
        'ui:options': {
          mask: 'cpf',
        },
        'ui:disabled': isOcrFilled,
        'ui:widget': 'OCRDisabledTextInputWidget',
      },
      rg: {
        'ui:placeholder': '000000000',
        'ui:disabled': isOcrFilled,
        'ui:widget': 'OCRDisabledTextInputWidget',
      },
      motherName: {
        'ui:placeholder': 'Ex.: Ana da Silva',
        'ui:disabled': isOcrFilled,
        'ui:widget': 'OCRDisabledTextInputWidget',
      },
      isAdministrativeLife: {
        'ui:widget': 'radio',
      },
      contractType: {
        'ui:placeholder': 'Selecione o tipo',
        'ui:widget': enableHiredFields ? 'SelectWidget' : 'hidden',
      },
      contractDate: {
        'ui:placeholder': '00/00/0000',
        'ui:widget': enableHiredFields ? 'DateWidget' : 'hidden',
      },
      height: {
        'ui:placeholder': 'Ex.: 1.76m',
        'ui:options': {
          mask: 'height',
        },
        'ui:widget': enableHiredFields ? 'TextWidget' : 'hidden',
      },
      weight: {
        'ui:placeholder': 'Ex.: 86.60kg',
        'ui:options': {
          mask: 'weight',
        },
        'ui:widget': enableHiredFields ? 'TextWidget' : 'hidden',
      },
      healthPlan: {
        'ui:options': {
          colSpan: 2,
        },
        catalogPlanId: {
          'ui:placeholder': 'Selecione o plano',
        },
        accommodationType: {
          'ui:placeholder': 'Selecione a acomodação',
        },
        lifeAmount: {
          'ui:placeholder': 'R$ 00,00',
          'ui:options': {
            mask: 'money',
          },
        },
      },
      dentalPlan: {
        'ui:options': {
          colSpan: 2,
        },
        catalogPlanId: {
          'ui:placeholder': 'Selecione o plano odontológico',
          'ui:disabled':
            !odontoPlanOptions || (odontoPlanOptions && odontoPlanOptions?.length === 0),
        },
        accommodationType: {
          'ui:placeholder': 'Selecione a acomodação odonto',
        },
        lifeAmount: {
          'ui:placeholder': 'R$ 00,00',
          'ui:options': {
            mask: 'money',
          },
        },
      },
      hiredHealthPlan: {
        'ui:options': {
          colSpan: 2,
        },
        isActive: {
          'ui:widget': 'radio',
        },
        activeHiredPlanInsurer: {
          'ui:placeholder': 'Selecione a operadora atual',
        },
      },
      isLegalAge: {
        'ui:widget': 'hidden',
      },
      insurer: {
        'ui:widget': 'hidden',
      },
    },
  }
}
