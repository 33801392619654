/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

import axios, { AxiosResponse } from 'axios'

import { ErrorResponse, UseFetchConfig, UseFetchOutput, UseFetchParallelOutput } from './interface'

export const api = axios.create()

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('session')
    if (token) {
      config.headers.Authorization = `${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject({
      status: error.response.status,
      body: error.response.data,
      headers: error.response.headers,
    })
  },
)
api.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear()
      localStorage.setItem('desiredUrlPath', window.location.pathname)
      window.location.href = '/'
    }
    return Promise.reject({
      status: error.response.status,
      message: error.response.message,
      body: error.response.data,
      headers: error.response.headers,
    })
  },
)

const useFetch = <T>(): UseFetchOutput<T> => {
  const [requestConfig, setRequestConfig] = useState<UseFetchConfig>()
  const [data, setData] = useState<T>()
  const [payload, setPayload] = useState({})
  const [statusCode, setStatusCode] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<ErrorResponse>()
  const [fileName, setFileName] = useState<string>()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setStatusCode(null)
        setIsLoading(true)

        const response = await api.request<T>({
          url: requestConfig?.url,
          method: requestConfig?.method,
          data: requestConfig?.data,
          headers: { ...requestConfig?.headers },
          responseType: requestConfig?.responseType,
        })

        const filename = response.headers?.['content-disposition']?.split('filename=')[1]?.trim()
        setFileName(filename)

        setPayload(requestConfig?.data ?? {})
        setData(response.data)
        setStatusCode(response.status)
        setIsLoading(false)
      } catch (err) {
        const error = err as ErrorResponse
        setError(error)
        setStatusCode(error.status)
        setIsLoading(false)
      }
    }

    if (requestConfig != null) fetchData()
  }, [requestConfig])

  return { payload, data, fileName, statusCode, isLoading, error, setRequestConfig }
}

export const useFetchParallel = <T>(): UseFetchParallelOutput<T> => {
  const [requestConfig, setRequestConfig] = useState<UseFetchConfig[]>([])
  const [data, setData] = useState<T[]>([])
  const [statusCode, setStatusCode] = useState<number[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<ErrorResponse>()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setStatusCode([])
        setIsLoading(true)

        const fetchPromises = requestConfig?.reduce(async (previousPromise, config) => {
          await previousPromise

          const response = await api.request<T>({
            url: config.url,
            method: config.method,
            data: config.data,
            headers: { ...config.headers },
          })

          return [...(await previousPromise), response]
        }, Promise.resolve([]) as Promise<AxiosResponse<T>[]>)

        const responses = await fetchPromises

        setData(responses.map((response) => response.data))
        setStatusCode(responses.map((response) => response.status))
        setIsLoading(false)
      } catch (err) {
        const error = err as ErrorResponse
        setError(error)
        setStatusCode([error.status])
        setIsLoading(false)
      }
    }

    if (requestConfig != null) fetchData()
  }, [requestConfig])

  return { data, statusCode, isLoading, error, setRequestConfig }
}

export default useFetch
