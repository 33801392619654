import { useEffect } from 'react'

export const ChatBot = ({ userMail }: { userMail?: string }) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js'

    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
        <df-messenger
          project-id="prd-bliss-assistente"
          agent-id="cc6f654b-a6b8-4084-af96-66b74f25fcd4"
          allow-feedback="all"
          language-code="pt-br">
          <df-messenger-chat-bubble
          chat-title="Assistente Virtual Bliss"
          endUserMetadata="{'email': '${userMail}'}">
          </df-messenger-chat-bubble>
        </df-messenger>
`,
      }}
      style={{
        zIndex: 999,
        position: 'fixed',
        bottom: '16px',
        right: '16px',
      }}
    />
  )
}
