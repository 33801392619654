const FailCircleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <circle cx="10" cy="10" r="10" fill="#DD6B76"></circle>
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.3"
        d="M14.106 6.912l.106-.106-.106-.106-.806-.806-.106-.106-.106.106L10 8.982 6.912 5.894l-.106-.106-.106.106-.806.806-.106.106.106.106L8.982 10l-3.088 3.088-.106.106.106.106.806.806.106.106.106-.106L10 11.018l3.088 3.088.106.106.106-.106.806-.806.106-.106-.106-.106L11.018 10l3.088-3.088z"
      ></path>
    </svg>
  )
}

export default FailCircleIcon
