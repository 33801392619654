import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { useQuery } from '@tanstack/react-query'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { Button, DatePicker } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { ItemGridPay, Quota } from '@/App/clients/finOps/dtos'
import { useCreateCommissionGridPayItemInBatchMutation } from '@/App/clients/finOps/mutations/createCommissionGridPayItemInBatch'
import { getDistributionChannelsQueryOptions } from '@/App/clients/productInformationManagment/queries/distributionChannels'

import { ITableData } from '../..'
import { InvisibleInput } from '../../invisibleInput'
import { CompactTable } from '../../styles'
import {
  AdditionalInfoContainer,
  ButtonContainer,
  Container,
  DatePickerLabel,
  ErrorLabel,
  RedMandatoryFlag,
} from './styles'

export const GridsItemsEditorComponent = ({
  items,
  setModalOpen,
}: {
  items: ITableData[]
  setModalOpen: (a: boolean) => void
}) => {
  const { data: distributionChannels } = useQuery(getDistributionChannelsQueryOptions())

  useEffect(() => {
    setTableData(items)
  }, [items])

  const [tableData, setTableData] = useState<ITableData[]>(items)

  const handleChangeTableQuota = useCallback(
    async (valueRaw: string, id: string, quotaId: string) => {
      const value = valueRaw.replace(/[^0-9]/g, '')

      const valueFormatted =
        Number(value) > 100 ? 100 : Number(value) < 0 || isNaN(Number(value)) ? 0 : Number(value)
      const lineIndex = tableData.findIndex((item) => item.id === id)

      if (!tableData[lineIndex]) return

      const lineUpdated = {
        ...tableData[lineIndex],
        [quotaId]: Number(valueFormatted.toFixed(0)),
      }

      const tableDataCopy = [...tableData]

      tableDataCopy.splice(lineIndex, 1, lineUpdated)

      setTableData(tableDataCopy)
    },
    [tableData],
  )

  const columns: ColumnsType<ITableData> = [
    {
      key: '0',
      dataIndex: 'grid',
      title: 'Grade',
    },
    {
      key: '1',
      dataIndex: 'broker',
      title: 'Operadora/Administradora',
      sorter: (a, b) => a.broker.localeCompare(b.broker),
    },
    {
      key: '2',
      dataIndex: 'administrator',
      title: 'Administradora',
      sorter: (a, b) => a.administrator.localeCompare(b.administrator),
    },
    {
      key: '3',
      dataIndex: 'modality',
      title: 'Modalidade Agregada',
      sorter: (a, b) => a.modality.localeCompare(b.modality),
    },
    {
      key: '4',
      dataIndex: 'quota1',
      title: '1',
      render: (fieldValue, lineData: ITableData) => {
        const id: string = lineData.id

        return (
          <InvisibleInput
            value={fieldValue}
            onChange={(e) => handleChangeTableQuota(e.target.value, id, 'quota1')}
          />
        )
      },
    },
    {
      key: '5',
      dataIndex: 'quota2',
      title: '2',
      render: (fieldValue, lineData: ITableData) => {
        const id: string = lineData.id

        return (
          <InvisibleInput
            value={fieldValue}
            onChange={(e) => handleChangeTableQuota(e.target.value, id, 'quota2')}
          />
        )
      },
    },
    {
      key: '6',
      dataIndex: 'quota3',
      title: '3',
      render: (fieldValue, lineData: ITableData) => {
        const id: string = lineData.id
        return (
          <InvisibleInput
            value={fieldValue}
            onChange={(e) => handleChangeTableQuota(e.target.value, id, 'quota3')}
          />
        )
      },
    },
    {
      key: '7',
      dataIndex: 'quota4',
      title: '4',
      render: (fieldValue, lineData: ITableData) => {
        const id: string = lineData.id
        return (
          <InvisibleInput
            value={fieldValue}
            onChange={(e) => handleChangeTableQuota(e.target.value, id, 'quota4')}
          />
        )
      },
    },
    {
      key: '8',
      dataIndex: 'quota5',
      title: '5',
      render: (fieldValue, lineData: ITableData) => {
        const id: string = lineData.id
        return (
          <InvisibleInput
            value={fieldValue}
            onChange={(e) => handleChangeTableQuota(e.target.value, id, 'quota5')}
          />
        )
      },
    },
    {
      key: '9',
      dataIndex: 'quota6',
      title: '6',
      render: (fieldValue, lineData: ITableData) => {
        const id: string = lineData.id
        return (
          <InvisibleInput
            value={fieldValue}
            onChange={(e) => handleChangeTableQuota(e.target.value, id, 'quota6')}
          />
        )
      },
    },
    {
      key: '10',
      dataIndex: 'quota7',
      title: '7',
      render: (fieldValue, lineData: ITableData) => {
        const id: string = lineData.id
        return (
          <InvisibleInput
            value={fieldValue}
            onChange={(e) => handleChangeTableQuota(e.target.value, id, 'quota7')}
          />
        )
      },
    },
    {
      key: '11',
      dataIndex: 'quota8',
      title: '8',
      render: (fieldValue, lineData: ITableData) => {
        const id: string = lineData.id
        return (
          <InvisibleInput
            value={fieldValue}
            onChange={(e) => handleChangeTableQuota(e.target.value, id, 'quota8')}
          />
        )
      },
    },
    {
      key: '12',
      dataIndex: '',
      title: 'Total',
      render: (_, lineData) => {
        const id: string = lineData.id
        const lineQuotas = tableData.find((item) => item.id === id)

        if (!lineQuotas) return 0

        const quota1 = lineQuotas.quota1 ?? 0
        const quota2 = lineQuotas.quota2 ?? 0
        const quota3 = lineQuotas.quota3 ?? 0
        const quota4 = lineQuotas.quota4 ?? 0
        const quota5 = lineQuotas.quota5 ?? 0
        const quota6 = lineQuotas.quota6 ?? 0
        const quota7 = lineQuotas.quota7 ?? 0
        const quota8 = lineQuotas.quota8 ?? 0

        const total = quota1 + quota2 + quota3 + quota4 + quota5 + quota6 + quota7 + quota8

        return `${Intl.NumberFormat('pt-BR', { minimumFractionDigits: 0 }).format(total)}%`
      },
    },
    {
      key: '13',
      dataIndex: 'startAt',
      title: 'Início vigência',
      render: (a) => `${a ? dayjs(a).format('DD/MM/YYYY') : ''}`,
    },
  ]

  const { control, getValues } = useForm()
  const [dateError, setDateError] = useState('')
  const createComissionGridPayItemInBatch = useCreateCommissionGridPayItemInBatchMutation()

  const [maxDate, setMaxDate] = useState<Date | null>(new Date())
  const [loadingCreateComissionGridPayItemInBatch, setLoadingCreateComissionGridPayItemInBatch] =
    useState(false)
  useEffect(() => {
    const arrDates = tableData.map((i) => i.startAt)

    const dates = arrDates.filter((date) => date)

    const maxDate = dates.length
      ? dates.reduce((a, b) => (dayjs(b).isBefore(dayjs(a)) ? a : b))
      : null

    setMaxDate(maxDate)
  }, [tableData])

  const handleSubmit = async (e) => {
    setDateError('')
    e.preventDefault()
    const values = getValues()

    const validDate = values.dataInicio ? dayjs(values.dataInicio).isValid() : false

    if (!validDate) {
      setDateError('Selecione uma data válida')

      return
    }

    const isDateAfterMaxDate = dayjs(values.dataInicio).isAfter(maxDate)

    if (dayjs(maxDate).isValid() && !isDateAfterMaxDate) {
      setDateError('Selecione uma data válida posterior a ' + dayjs(maxDate).format('DD/MM/YYYY'))

      return
    }

    const parsedDataToRequest = tableData.map((item) => {
      const parsedQuotaToPercetage: Quota[] = Object.keys(item)
        .filter((key) => key.includes('quota'))
        .map((key) => {
          const quotaLookup = {
            quota1: 1,
            quota2: 2,
            quota3: 3,
            quota4: 4,
            quota5: 5,
            quota6: 6,
            quota7: 7,
            quota8: 8,
          }
          const quotaNumber = quotaLookup[key] || 0
          const quotaValue = item[key]

          return {
            numero: quotaNumber,
            porcentagem: quotaValue ? Number(quotaValue) / 100 : 0,
          }
        })

      const distributionChannel = distributionChannels?.find(
        (dc) =>
          dc.modalidade.id === item.modalityId &&
          dc.operadora.id === item.brokerId &&
          (item.administratorId ?? '') === (dc.administradora?.id ?? '') &&
          (item.platformId ?? '') === (dc.plataforma?.id ?? ''),
      )

      if (!distributionChannel) throw new Error('No distribution channel found')

      return {
        idGrade: item.gridId,
        idCanalDistribuicao: distributionChannel.id,
        inicioVigencia: dayjs(values.dataInicio).toDate(),
        parcelas: parsedQuotaToPercetage,
      } as ItemGridPay
    })

    try {
      setLoadingCreateComissionGridPayItemInBatch(true)
      await createComissionGridPayItemInBatch.mutateAsync(parsedDataToRequest)
      setLoadingCreateComissionGridPayItemInBatch(false)

      setModalOpen(false)
      triggerToast(true, 'Sucesso ao salvar itens')
    } catch (e) {
      triggerToast(false, 'Erro ao salvar itens')
      setLoadingCreateComissionGridPayItemInBatch(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <AdditionalInfoContainer>
        <Container>
          <DatePickerLabel>Início de vigência</DatePickerLabel>
          <RedMandatoryFlag>*</RedMandatoryFlag>
          <DatePicker
            name="dataInicio"
            control={control}
            disabledDate={(i) => i < dayjs(maxDate).endOf('day')}
          />
        </Container>
        <ErrorLabel>{dateError}</ErrorLabel>
      </AdditionalInfoContainer>
      <CompactTable
        columns={columns as ColumnsType<object>}
        dataSource={tableData}
        pagination={{ hideOnSinglePage: false, defaultPageSize: 50, showSizeChanger: true }}
        loading={false}
        rowClassName="editable-row"
      />

      <ButtonContainer>
        <Button htmlType="submit" loading={loadingCreateComissionGridPayItemInBatch}>
          Aplicar em todos e salvar
        </Button>
      </ButtonContainer>
    </form>
  )
}
