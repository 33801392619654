import { QueryFunctionContext, queryOptions, useQuery } from '@tanstack/react-query'

import type { IFilters } from '@/App/pages/orders'

import type { GetOrdersByFiltersDto } from '../../healthPlansOrder/dto'
import { api } from '../../http'

type GetOrdersByFiltersBaseRequest = {
  page?: number | null
  filters: Record<string, string>
}

type GetOrdersByFiltersRequest = QueryFunctionContext & GetOrdersByFiltersBaseRequest

async function getOrdersByFilters({ signal, filters, page = 0 }: GetOrdersByFiltersRequest) {
  const filterString = Object.entries(filters)
    .filter(([_key, value]) => !!value)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')

  const queryString = filterString ? `${filterString}` : ''

  const response = await api.get<GetOrdersByFiltersDto>(
    `${
      import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
    }/api/v2/admin/orders?perPage=20&page=${page}&${queryString}`,
    {
      signal,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    },
  )
  return response.data
}

export function ordersByFiltersQueryOptions({ filters, page }: GetOrdersByFiltersBaseRequest) {
  return queryOptions({
    queryKey: ['orders', { page, ...filters }] as const,
    queryFn: async (params) => getOrdersByFilters({ ...params, page, filters }),
    refetchOnWindowFocus: false,
  })
}

export const useOrdersByFilters = (filters?: IFilters, page = 0) => {
  const filtersHashMap: Record<string, string> = {}

  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      const value = filters[key]
      if (Array.isArray(value)) {
        if (value.length > 0) {
          filtersHashMap[key] = value.join(',')
        }
      } else if (value != null && typeof value !== 'undefined' && value !== '') {
        filtersHashMap[key] = value.toString()
      }
    }
  }

  return useQuery(ordersByFiltersQueryOptions({ page, filters: filtersHashMap }))
}
