import { Control, UseFormRegister } from 'react-hook-form'

import { Select, TextInput } from 'design-system/components'

import { FillQuotationInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import { useOrderInformationQuery } from '@/App/clients/healthPlansOrders/queries/orderInformation'

import { FormItem, FormItemLabel } from '../style'

interface SulamericaFormQuotationProps {
  orderId: string | undefined
  control: Control<FillQuotationInformationBody>
  register: UseFormRegister<FillQuotationInformationBody>
}

export const SulamericaFormQuotation: React.FC<SulamericaFormQuotationProps> = ({
  orderId,
  control,
  register,
}) => {
  const orderDataQuery = useOrderInformationQuery({ orderId })

  return (
    <>
      <FormItem>
        <TextInput
          name="externalId"
          label="Número da cotação*"
          placeholder="0000000"
          vertical
          register={{
            ...register('externalId'),
          }}
        />
      </FormItem>
      <FormItem>
        <FormItemLabel>Coparticipação*</FormItemLabel>
        <Select
          placeholder="Selecione a opção"
          control={control}
          showSearch={true}
          name="isCoparticipation"
          options={[
            { label: 'Completa', value: 'true' },
            { label: 'Parcial', value: 'false' },
          ]}
        />
      </FormItem>

      <FormItem>
        <FormItemLabel>Tipo de contratação*</FormItemLabel>
        <Select
          showSearch
          defaultValue={orderDataQuery?.data?.quotation?.hiringType}
          name="hiringType"
          control={control}
          options={[
            { label: 'CBO', value: 'CBO' },
            { label: 'Compulsório', value: 'Compulsório' },
            { label: 'Flex', value: 'Flex' },
          ]}
          placeholder="Selecione o tipo de contratação"
        />
      </FormItem>

      <FormItem>
        <FormItemLabel>Tipo de plano*</FormItemLabel>
        <Select
          showSearch
          name="coverageType"
          control={control}
          options={[
            {
              label: 'Ambulatorial e Hospitalar com Obstetrícia',
              value: 'Ambulatorial e Hospitalar com Obstetrícia',
            },
            { label: 'Hospitalar com Obstetrícia', value: 'Hospitalar com Obstetrícia' },
          ]}
          placeholder="Selecione o tipo de plano"
        />
      </FormItem>

      <FormItem>
        <FormItemLabel>Vigência contratual*</FormItemLabel>
        <Select
          showSearch
          name="contractualTerm"
          control={control}
          options={[
            { label: '12 meses', value: '12 meses' },
            { label: '24 meses', value: '24 meses' },
          ]}
          placeholder="Selecione o período"
        />
      </FormItem>
    </>
  )
}
