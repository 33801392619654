import { useMutation } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '@/App/clients/http'

import { FillBrokerInformationDto } from '../dtos'

export type FillBrokerInformationData = {
  data: FillBrokerInformationDto
  brokerId?: string
}

const fillBrokerInformation = async ({ data, brokerId }: FillBrokerInformationData) => {
  const response = await api.patch(
    `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/brokers/${brokerId}`,
    data,
    {
      headers: { Authorization: localStorage.getItem('authToken') },
    },
  )
  return response.data
}

export const useFillBrokerInformation = (fillBrokerInformationData?: FillBrokerInformationData) => {
  const brokerId = fillBrokerInformationData?.brokerId

  return useMutation({
    mutationKey: ['brokers', brokerId],
    mutationFn: fillBrokerInformation,
    onSuccess: () => {
      triggerToast(true, 'Alteração feita com sucesso!')
    },
    onError: () => {
      triggerToast(false, 'Ops, ocorreu um erro durante a alteração.')
    },
  })
}
