import styled from 'styled-components'

export const ExpandableRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 30px 30px 30px;
`

export const ExecutionDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: 10px;
`

export const ExecutionDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const ExecutionDetailsItemTitle = styled.p`
  font-weight: 600;
  margin: 0;
`

export const ExecutionDetailsItemValue = styled.p`
  margin: 0 0 0 5px;
`
