import { ReactNode, useEffect, useState } from 'react'

import CloseIcon from 'design-system/close'
import { Select, Skeleton } from 'design-system/components'
import GroupIcon from 'design-system/group'
import { triggerToast } from 'design-system/triggers'
import WarningIcon from 'design-system/warning'

import { healthPlansOrderRequest } from '@/App/clients/healthPlansOrder'
import { HealthPlansOrdersRequests } from '@/App/clients/healthPlansOrders'
import {
  FillOrderInformationBody,
  GetOrderInformationBody,
  GetOrderResponsibleAdmins,
} from '@/App/clients/healthPlansOrders/dtos'
import useFetch from '@/App/clients/http'

import {
  OrderDiv,
  OrderHeader,
  OrderHeaderContent,
  OrderStatusSelectLoadingDiv,
  OrderTagsDiv,
  ResponsibleAdminTag,
  ResponsibleContent,
  ResponsibleMainContainer,
  SelectContainer,
  StyledDescriptionText,
  StyledIcon,
  StyledTitleResponsibleAdmin,
  TagsContainer,
  WithoutResponsibleContent,
} from './style'

interface Props {
  orderData?: GetOrderInformationBody
  isLoadingGetOrderById: boolean
  setResponsibleAdminsQuantity: React.Dispatch<React.SetStateAction<number | undefined>>
  planCard: ReactNode
}

export const ResponsibleAdminTab: React.FC<Props> = ({
  orderData,
  isLoadingGetOrderById,
  setResponsibleAdminsQuantity,
  planCard,
}) => {
  const [selectResponsibleAdmins, setSelectResponsibleAdmins] = useState<
    Array<{ label: string; value: string }>
  >([])
  const [currentResponsibleAdmins, setCurrentResponsibleAdmins] = useState<
    Array<{ label: string; id: string }>
  >([])
  const [isModalOpen, _] = useState(false)

  const orderId = orderData?.id
  const {
    setRequestConfig: setGetResponsibleAdminsRequestConfig,
    data: responsibleAdminData,
    isLoading: isLoadingGetResponsibleAdmins,
  } = useFetch<GetOrderResponsibleAdmins>()

  const {
    setRequestConfig: setFillOrderResponsibleAdminsRequestConfig,
    isLoading: isLoadingFillOrderResponsibleAdmins,
    statusCode: fillOrderResponsibleAdminsStatusCode,
  } = useFetch<FillOrderInformationBody>()

  useEffect(() => {
    const responsibleAdminsRequestConfig = healthPlansOrderRequest.getAdminsByFilters()
    setGetResponsibleAdminsRequestConfig(responsibleAdminsRequestConfig)
  }, [])

  useEffect(() => {
    if (orderData != null) {
      assignCurrentResponsibleAdmins()
    }

    if (responsibleAdminData != null) {
      const rawAdminsIds = responsibleAdminData.admins.map((item) => item.id)
      const responsibleAdminsIds = orderData?.responsibleAdmins?.map((item) => item.id)

      const targetIds = rawAdminsIds.filter((id) => !responsibleAdminsIds?.includes(id))
      const availableAdmins = targetIds.map((option) => {
        const targetLabel = responsibleAdminData.admins.find(
          (rawAdmin) => rawAdmin.id === option,
        )?.name

        return { label: targetLabel ?? option, value: option }
      })

      setSelectResponsibleAdmins(availableAdmins)
    }
  }, [orderData, responsibleAdminData])

  useEffect(() => {
    if (fillOrderResponsibleAdminsStatusCode === 200) {
      const rawAdminsIds = responsibleAdminData?.admins.map((item) => item.id)
      const responsibleAdminsIds = currentResponsibleAdmins.map((item) => item.id)

      const targetIds = rawAdminsIds?.filter((id) => !responsibleAdminsIds.includes(id))
      const availableAdmins = targetIds?.map((option) => {
        const targetLabel = responsibleAdminData?.admins.find(
          (rawAdmin) => rawAdmin.id === option,
        )?.name

        return { label: targetLabel ?? option, value: option }
      })
      setSelectResponsibleAdmins(availableAdmins ?? [])
      setResponsibleAdminsQuantity(currentResponsibleAdmins.length)
      if (!isModalOpen) {
        triggerToast(true, 'Responsável alterado com sucesso!')
      }
    }
  }, [fillOrderResponsibleAdminsStatusCode])

  const assignCurrentResponsibleAdmins = (): void => {
    const admins = orderData?.responsibleAdmins?.map((option) => {
      return { label: option.name, id: option.id }
    })
    setCurrentResponsibleAdmins(admins ?? [])
  }

  const addResponsibleAdmin = (selectedOption: string) => {
    const responsibleAdminsIds = currentResponsibleAdmins.map((item) => item.id)
    responsibleAdminsIds.push(selectedOption)

    const targetAdmins = responsibleAdminsIds.map((option) => {
      const targetLabel = responsibleAdminData?.admins.find(
        (rawAdmin) => rawAdmin.id === option,
      )?.name

      return { label: targetLabel ?? option, id: option }
    })

    setCurrentResponsibleAdmins(targetAdmins ?? [])

    const fillOrderInformationRequestConfig = HealthPlansOrdersRequests.fillOrderInformation(
      { responsibleAdminsIds: responsibleAdminsIds },
      orderId,
    )

    setFillOrderResponsibleAdminsRequestConfig(fillOrderInformationRequestConfig)
  }

  const deleteResponsibleAdmin = (value: string) => {
    const responsibleAdminsIds = currentResponsibleAdmins.map((item) => item.id)
    const targetResponsibleIds = responsibleAdminsIds.filter((option) => option !== value)

    const targetAdmins = targetResponsibleIds.map((option) => {
      const targetLabel = responsibleAdminData?.admins.find(
        (rawAdmin) => rawAdmin.id === option,
      )?.name

      return { label: targetLabel ?? option, id: option }
    })

    setCurrentResponsibleAdmins(targetAdmins)
    const fillOrderInformationRequestConfig = HealthPlansOrdersRequests.fillOrderInformation(
      { responsibleAdminsIds: targetResponsibleIds },
      orderId,
    )

    setFillOrderResponsibleAdminsRequestConfig(fillOrderInformationRequestConfig)
  }
  const EditResponsibleAdmin = () => {
    return (
      <>
        <ResponsibleContent>
          <SelectContainer>
            <Select
              defaultValue=" + Alocar novo responsável"
              options={selectResponsibleAdmins}
              onChange={(value) => {
                if (!Array.isArray(value)) {
                  addResponsibleAdmin(value)
                }
              }}
            />
          </SelectContainer>

          {currentResponsibleAdmins.length != 0 ? (
            <TagsContainer>
              {currentResponsibleAdmins.map((value, index) => (
                <ResponsibleAdminTag key={index}>
                  {value.label}
                  <StyledIcon onClick={() => deleteResponsibleAdmin(value.id)}>
                    <CloseIcon />
                  </StyledIcon>
                </ResponsibleAdminTag>
              ))}
            </TagsContainer>
          ) : (
            <WithoutResponsibleContent>
              <WarningIcon />
              Proposta sem responsável
            </WithoutResponsibleContent>
          )}
        </ResponsibleContent>
      </>
    )
  }

  return (
    <ResponsibleMainContainer>
      <OrderDiv>
        <OrderHeader>
          {orderData == null ? (
            <OrderHeaderContent>
              <OrderTagsDiv>
                <Skeleton type="input" />
                <Skeleton type="input" />
              </OrderTagsDiv>
              <OrderStatusSelectLoadingDiv>
                <Skeleton type="input" />
              </OrderStatusSelectLoadingDiv>
            </OrderHeaderContent>
          ) : (
            <OrderHeaderContent>
              <StyledTitleResponsibleAdmin>
                <GroupIcon />
                <p>Responsáveis pela proposta</p>
              </StyledTitleResponsibleAdmin>
              <StyledDescriptionText>
                Aqui você define quais pessoas estão responsáveis, no momento, pela proposta. Por
                exemplo, quando alguém do time de Implantação entra no processo, este é o momento de
                alocar esta pessoa. Este preenchimento é importante para manter a comunicação sempre
                atualizada.
              </StyledDescriptionText>
              {isLoadingFillOrderResponsibleAdmins ||
              isLoadingGetResponsibleAdmins ||
              isLoadingGetOrderById ? (
                <OrderStatusSelectLoadingDiv>
                  <Skeleton type="input" />
                </OrderStatusSelectLoadingDiv>
              ) : (
                <EditResponsibleAdmin />
              )}
            </OrderHeaderContent>
          )}
        </OrderHeader>
      </OrderDiv>
      {planCard}
    </ResponsibleMainContainer>
  )
}
