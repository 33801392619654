import styled from 'styled-components'

export const ColumnHeader = styled.div`
  padding: 10px;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #dcdfeb;
    margin-top: 0.7rem;
  }
`

export const ColumnBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-left: 10px;
`
