import { useCallback } from 'react'

import RJSFForm, { FormProps as RJSFFormProps } from '@rjsf/core'
import { RJSFValidationError } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { clsx } from 'clsx'

import { FormWrapper } from './styles'
import { FieldTemplate, ObjectFieldTemplate, SubmitButtonTemplate } from './templates'
import {
  CheckboxWidget,
  DateWidget,
  RadioWidget,
  SelectWidget,
  SubtitleWidget,
  TextWidget,
} from './widgets'

export type DynamicFormSchemaProps = Pick<RJSFFormProps, 'schema' | 'uiSchema' | 'formContext'>

export type DynamicFormProps = Omit<RJSFFormProps, 'onSubmit' | 'validator'> & {
  onSubmit: (data: Record<string, unknown>) => void
}

export function DynamicForm({
  schema,
  uiSchema,
  onSubmit,
  formData,
  className,
  widgets,
  templates,
  ...rest
}: DynamicFormProps) {
  const customWidgets = {
    TextWidget,
    SelectWidget,
    DateWidget,
    RadioWidget,
    CheckboxWidget,
    SubtitleWidget,
    ...widgets,
  }

  const customTemplates = {
    ErrorListTemplate: () => null,
    FieldTemplate,
    ObjectFieldTemplate,
    ButtonTemplates: {
      SubmitButton: SubmitButtonTemplate,
    },
    ...templates,
  }

  const transformErrors = useCallback(
    (errors: Array<RJSFValidationError>) => {
      return errors.map((error) => {
        const requiredErrorMessages = ['must be equal to constant', 'must be string']
        if (
          error.name === 'required' ||
          (schema.required?.includes(error.property?.split('.').slice(1, 2).join('.') ?? '') &&
            requiredErrorMessages.includes(error.message ?? ''))
        ) {
          return { ...error, message: 'Este campo é obrigatório.' }
        }

        if (error.name === 'format' && error.params.format === 'email') {
          return { ...error, message: 'E-mail inválido' }
        }

        return error
      })
    },
    [schema],
  )

  return (
    <FormWrapper>
      <RJSFForm
        className={clsx('', className)}
        schema={schema}
        transformErrors={transformErrors}
        uiSchema={uiSchema}
        validator={validator}
        onSubmit={(data) => onSubmit(data.formData)}
        formData={formData}
        widgets={customWidgets}
        templates={customTemplates}
        onError={() => ({})}
        {...rest}
      />
    </FormWrapper>
  )
}

export * from '@rjsf/utils'
