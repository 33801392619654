import { useState } from 'react'

import { UserData } from '../../clients/healthPlansOrders/dtos'
import { ChatBot } from './ChatBot'
import { UserContext } from './userContext'

export const UserProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useState<UserData | null>(() => {
    const id = localStorage.getItem('userId')
    const name = localStorage.getItem('userName')
    const mail = localStorage.getItem('userMail')

    if (id && name && mail) {
      return {
        id,
        name,
        mail,
      }
    } else {
      return null
    }
  })

  const setUserData = async (userData: UserData) => {
    localStorage.setItem('userName', userData.name)
    localStorage.setItem('userId', userData.id)
    localStorage.setItem('userMail', userData.mail)

    setUser(userData)
  }

  const signOut = () => {
    localStorage.removeItem('userName')
    localStorage.removeItem('userId')
    localStorage.removeItem('userMail')
    localStorage.removeItem('desiredUrlPath')
  }

  return (
    <UserContext.Provider value={{ setUserData, user, signOut }}>
      {children}
      <ChatBot userMail={user?.mail} />
    </UserContext.Provider>
  )
}
