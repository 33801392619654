import { QueryFunctionContext, queryOptions } from '@tanstack/react-query'

import { api } from '../../http'
import { StatusMappingsByInsurer } from '../dtos'

type GetStatusMappingsBaseRequest = {
  insurer?: string | null
}

type GetStatusMappingsRequest = QueryFunctionContext & GetStatusMappingsBaseRequest

async function getStatusMappingsByInsurer({ insurer, signal }: GetStatusMappingsRequest) {
  return api
    .get<StatusMappingsByInsurer>(
      `${import.meta.env.VITE_ORDER_INFORMATION_PROCESSING_URL}/api/v1/status-mappings/${insurer}`,
      {
        signal,
        headers: { Authorization: localStorage.getItem('authToken') },
      },
    )
    .then((res) => res.data)
}

export function statusMappingsQueryOptions(insurer?: string | null) {
  return queryOptions({
    queryKey: ['statusMappings', insurer] as const,
    queryFn: async (params) => {
      return getStatusMappingsByInsurer({ ...params, insurer })
    },
    enabled: !!insurer,
  })
}
