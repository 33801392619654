import { StyledHeader } from './style'
import React, { ReactNode } from 'react'

export interface HeaderProps {
  headerHeight?: string
  children?: ReactNode
}

const Header: React.FC<HeaderProps> = ({ headerHeight, children }) => (
  <StyledHeader headerHeight={headerHeight}>{children}</StyledHeader>
)

export default Header
