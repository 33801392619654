import React from 'react'
import { ReactNode } from 'react'
import { Control, Controller, RegisterOptions } from 'react-hook-form'

import { FileImageOutlined } from '@ant-design/icons'
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload'

import Error from '../Error/Error'
import { StyledUpload, UploadButton } from './style'

export interface OnFileUploadInput {
  uid: string
  fileContent: Blob
  fileContentType?: string
  fileName: string
}

export type Props = Omit<UploadProps, 'type'> & {
  bgcolor?: string
  beforeUpload?: (file: RcFile) => boolean | Promise<void>
  control?: Control<any>
  disabled?: boolean
  icon?: ReactNode
  name?: string
  onFileUpload?: (files: OnFileUploadInput[]) => void
  onRemove?: (fileName?: string) => void
  rules?: RegisterOptions
  text?: string
  textcolor?: string
  showFileUploadList?: boolean
  type?: 'button' | 'icon'
  border?: 'dashed' | 'solid'
  loading?: boolean
  accept?: string
}

const Upload: React.FC<Props> = ({
  beforeUpload,
  bgcolor,
  control,
  disabled = false,
  icon = <FileImageOutlined />,
  name,
  onFileUpload,
  onRemove,
  rules,
  text,
  textcolor,
  showFileUploadList,
  type = 'button',
  border = 'solid',
  loading,
  accept,
  ...props
}) => {
  const onChange = async (file: UploadChangeParam<UploadFile<RcFile>>) => {
    const fileContentRetrievePromises = file.fileList.map(
      (file) =>
        new Promise((resolve) => {
          if (!file.originFileObj) {
            return resolve(file)
          }
          const reader = new FileReader()

          reader.readAsArrayBuffer(file.originFileObj)
          reader.onload = () => resolve(reader.result as ArrayBuffer)
        }),
    )

    const fileContents = await Promise.all(fileContentRetrievePromises)

    const result = fileContents.map((content, index) => {
      const fileContentOutput = new Blob([content as ArrayBuffer], {
        type: file.fileList[index].type,
      })

      return {
        uid: file.fileList[index].uid,
        fileContent: fileContentOutput,
        fileName: file.fileList[index].name,
        fileContentType: file.fileList[index].type,
      }
    })
    onFileUpload && onFileUpload(result)
  }

  return !control ? (
    <StyledUpload
      {...props}
      beforeUpload={beforeUpload ?? (() => false)}
      textcolor={textcolor}
      multiple={false}
      disabled={disabled}
      onChange={onChange}
      showUploadList={showFileUploadList}
      accept={accept}
      onRemove={(file) => onRemove && onRemove(file.fileName ?? file.name)}
    >
      {type === 'button' ? (
        <UploadButton
          loading={loading}
          border={border}
          textcolor={textcolor}
          bgcolor={bgcolor}
          icon={icon}
        >
          {text}
        </UploadButton>
      ) : (
        <>{icon}</>
      )}
    </StyledUpload>
  ) : (
    <Controller
      control={control}
      name={name ?? ''}
      rules={rules}
      render={({ field, fieldState }) => (
        <>
          <StyledUpload
            {...props}
            beforeUpload={beforeUpload ?? (() => false)}
            disabled={disabled}
            multiple={false}
            onRemove={(file) => onRemove && onRemove(file.fileName)}
            onChange={(event) => {
              if (event.file?.status !== 'removed') field.onChange(event)

              onChange(event)
            }}
            textcolor={textcolor}
            accept={accept}
          >
            <UploadButton {...field} textcolor={textcolor} bgcolor={bgcolor} icon={icon}>
              {text}
            </UploadButton>
          </StyledUpload>
          {fieldState.error ? <Error error={fieldState.error} /> : ''}
        </>
      )}
    />
  )
}

export default Upload
