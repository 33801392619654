type InputMaskType = 'text' | 'money'

export const clearInputMask = (value?: string | null, type: InputMaskType = 'text') => {
  if (!value) return ''

  switch (type) {
    case 'money':
      return value
        .replace(/[R$\s]+/g, '') // Matches and removes the 'R$' symbol and any spaces in the string
        .replace(/,/g, '.') // Converts commas (,) to periods (.) for standard decimal formatting
        .replace(/\.(?=\d{3}(\.|$))/g, '') // Matches a dot (.) only if it's followed by exactly three digits, and those three digits are followed either by another dot or the end of the string ($)
    case 'text':
    default:
      return value.replaceAll(/\D/g, '')
  }
}

export const regexMailPattern = {
  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  message: 'E-mail inválido',
}

export const capitalizeOnlyFirstLetter = (value: string): string => {
  return `${value.charAt(0).toUpperCase()}${value.slice(1).toLowerCase()}`
}

export const normalizeString = (str: string) => {
  return str
    .normalize('NFD')
    .toLowerCase()
    .trim()
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w\s]/gi, '')
}

export const toCamelCase = (str?: string | null) => {
  if (!str) {
    return null
  }

  return str
    .toLowerCase()
    .split('_')
    .map((word, index) => (index ? word[0].toUpperCase() + word.slice(1) : word))
    .join('')
}
