import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'
import { insurers } from 'design-system/insurers'

export const dependentDefaultSchema: DynamicFormSchemaProps = {
  formContext: {
    hint: '*Campos de preenchimento obrigatório apenas para a emissão automática e/ou cadastro no sisweb',
  },
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        title: 'Nome Completo',
      },
      phone: {
        type: 'string',
        title: 'Telefone',
      },
      mail: {
        type: 'string',
        title: 'E-mail',
      },
      maritalStatus: {
        type: 'string',
        title: 'Estado civil',
        oneOf: [
          { title: 'Solteiro', const: 'SINGLE' },
          { title: 'Casado', const: 'MARRIED' },
          { title: 'Divorciado', const: 'DIVORCED' },
          { title: 'Viúvo', const: 'WIDOWED' },
          { title: 'Separado', const: 'SEPARATED' },
          { title: 'União Estável', const: 'COMMON_LAW_MARRIAGE' },
        ],
      },
      gender: {
        type: 'string',
        title: 'Gênero',
        oneOf: [
          { title: 'Feminino', const: 'FEMALE' },
          { title: 'Masculino', const: 'MALE' },
          { title: 'Não binário', const: 'NON_BINARY' },
        ],
      },
      birthDate: {
        type: 'string',
        title: 'Data Nascimento',
        format: 'date',
      },
      rg: {
        type: 'string',
        title: 'RG',
      },
      cpf: {
        type: 'string',
        title: 'CPF',
      },
      motherName: {
        type: 'string',
        title: 'Nome da Mãe',
      },
      holderKinship: {
        type: 'string',
        title: 'Parentesco',
        oneOf: [
          { title: 'Cônjuge', const: 'SPOUSE' },
          { title: 'Filho/Filha', const: 'CHILDREN' },
          { title: 'Pai', const: 'FATHER' },
          { title: 'Mãe', const: 'MOTHER' },
          { title: 'Padrasto', const: 'STEPFATHER' },
          { title: 'Madastra', const: 'STEPMOTHER' },
          { title: 'Tio', const: 'UNCLE' },
          { title: 'Tia', const: 'AUNT' },
          { title: 'Sogro', const: 'FATHER-IN-LAW' },
          { title: 'Sogra', const: 'MOTHER-IN-LAW' },
          { title: 'Irmãos', const: 'SIBLINGS' },
          { title: 'Neto', const: 'GRANDSON' },
          { title: 'Neta', const: 'GRANDDAUGHTER' },
          { title: 'Cunhado', const: 'BROTHER-IN-LAW' },
          { title: 'Cunhada', const: 'SISTER-IN-LAW' },
          { title: 'Genro', const: 'SON-IN-LAW' },
          { title: 'Nora', const: 'DAUGHTER-IN-LAW' },
          { title: 'Sobrinho/Sobrinha', const: 'NEPHEWS' },
          { title: 'Enteados', const: 'STEPCHILDREN' },
          { title: 'Outros', const: 'OTHERS' },
        ],
      },
      isAdministrativeLife: {
        type: ['boolean', 'null'],
        title: 'É uma vida administrativa?',
        oneOf: [
          { title: 'Sim', const: true },
          { title: 'Não', const: false },
        ],
        default: null,
      },
      hasActiveHiredPlan: {
        type: 'boolean',
        title: 'Possui plano?',
        oneOf: [
          { title: 'Sim', const: true },
          { title: 'Não', const: false },
        ],
        default: false,
      },
    },
    dependencies: {
      hasActiveHiredPlan: {
        oneOf: [
          {
            properties: {
              hasActiveHiredPlan: {
                enum: [false],
              },
            },
          },
          {
            properties: {
              hasActiveHiredPlan: {
                enum: [true],
              },
              activeHiredPlanInsurer: {
                type: ['string', 'null'],
                title: 'Qual a operadora atual?',
                oneOf: insurers.map((insurer) => ({
                  title: insurer.label,
                  const: insurer.value,
                })),
              },
            },
          },
        ],
      },
    },
  },
  uiSchema: {
    name: {
      'ui:placeholder': 'Ex.: Maria Silva',
    },
    phone: {
      'ui:placeholder': '(00) 00000 0000',
      'ui:options': {
        mask: 'phone',
      },
    },
    mail: {
      'ui:placeholder': 'exemplo@email.com.br',
      'ui:widget': 'TextWidget',
    },
    maritalStatus: {
      'ui:placeholder': 'Selecione o estado civil',
    },
    gender: {
      'ui:placeholder': 'Selecione o gênero',
    },
    birthDate: {
      'ui:placeholder': '00/00/0000',
    },
    rg: {
      'ui:placeholder': '000000000',
    },
    cpf: {
      'ui:placeholder': '000.000.000-00',
    },
    motherName: {
      'ui:placeholder': 'Ex.: Ana Silva',
    },
    holderKinship: {
      'ui:placeholder': 'Selecione o grau de parentesco',
    },
    isAdministrativeLife: {
      'ui:widget': 'radio',
    },
    hasActiveHiredPlan: {
      'ui:widget': 'radio',
    },
    activeHiredPlanInsurer: {
      'ui:placeholder': 'Selecione a operadora atual',
    },
  },
}
