import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.locale('pt-br')
export const calculateProcessingTime = (
  createdAt?: string | null,
  status?: string | null,
  updatedAt?: string | null,
) => {
  const createdDate = dayjs(createdAt)
  const updatedDate = dayjs(updatedAt)
  const currentDate = dayjs()
  const timeDifference =
    status === 'PENDING' ? createdDate.diff(currentDate) : createdDate.diff(updatedDate)
  const result = dayjs.duration(timeDifference).humanize()
  return status === 'PENDING' ? `-> há ${result}` : `-> Tempo de Processamento: ${result}`
}

export const calculateAge = (birthDate?: string | null) => {
  const currentYear = dayjs()
  const formatedBirthDate = dayjs(birthDate)
  const age = currentYear.diff(formatedBirthDate, 'year')
  return age
}
