import { ChangeEvent, useCallback } from 'react'

import { WidgetProps } from '@rjsf/utils'

import { TextInputMask } from '../../TextInput/TextInput'
import {
  cepApplyMask,
  cnpjApplyMask,
  cpfApplyMask,
  currencyApplyMask,
  heightApplyMask,
  phoneApplyMask,
  rgApplyMask,
  weightApplyMask,
} from '../../TextInput/masks'
import { TextInput } from '../../components'
import { FormItem } from '../styles'

type MaskFunction = (value?: string) => string | undefined

const masks: Record<TextInputMask | 'rg', MaskFunction> = {
  cep: cepApplyMask,
  cnpj: cnpjApplyMask,
  cpf: cpfApplyMask,
  money: currencyApplyMask,
  phone: phoneApplyMask,
  rg: rgApplyMask,
  height: heightApplyMask,
  weight: weightApplyMask,
}

export function TextWidget({
  name,
  value,
  label,
  required,
  onChange,
  options,
  rawErrors,
  ...rest
}: WidgetProps) {
  const applyMask: MaskFunction = masks[options?.mask as string]
  const onTextChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(
        e.target.value === ''
          ? options?.emptyValue || ''
          : applyMask
          ? applyMask(e.target.value)
          : e.target.value,
      )
    },
    [onChange],
  )

  const error = rawErrors
    ? {
        type: 'unknown',
        message: rawErrors.at(0),
      }
    : null

  const maskedValue = applyMask ? applyMask(value) : value

  return (
    <FormItem colSpan={options.colSpan as string | number}>
      <TextInput
        label={required ? `${label}*` : label}
        name={name}
        onChange={onTextChange}
        value={maskedValue || ''}
        vertical
        error={error}
        {...rest}
      />
    </FormItem>
  )
}
