import styled from 'styled-components'
import { colors } from '../../styles/theme'
import { Input } from 'antd'
interface StyledTextProps {
  isCharacterMaxQuantityExceeded: boolean
}
export const StyledCaracteresLabel = styled.div<StyledTextProps>`
  align-self: flex-end;
  color: ${(props) => (props.isCharacterMaxQuantityExceeded ? `${colors.red}` : '#8589A2')};
  font-size: 11px;
  font-weight: 700;
  margin-top: 5px;
`

export const StyledDivTextArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`

export const StyledInputTextArea = styled(Input.TextArea)`
  color: #4b4b4b;
  ::placeholder {
    color: ${colors.secundaryText};
    font-size: 13px;
    font-style: normal;
  }
`
