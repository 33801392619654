import { QueryFunctionContext, queryOptions } from '@tanstack/react-query'

import { api } from '../../http'
import { GetCatalogSubmodalityDto } from '../dtos'

type GetCatalogSubModalityBaseRequest = {
  modalityId?: string | null
}
type GetCatalogSubModalityRequest = QueryFunctionContext & GetCatalogSubModalityBaseRequest

async function getCatalogSubModality({ modalityId, signal }: GetCatalogSubModalityRequest) {
  const response = await api.get<GetCatalogSubmodalityDto>(
    `${import.meta.env.VITE_CATALOGO_API_URL}/modalidades/${modalityId}/tabelas`,
    {
      signal,
      headers: { Authorization: localStorage.getItem('authToken') },
      params: {
        modalityId: modalityId,
      },
    },
  )
  return response.data
}

export function getCatalogSubModalityOptions({
  modalityId,
  enabled = false,
}: GetCatalogSubModalityBaseRequest & { enabled?: boolean }) {
  return queryOptions({
    queryKey: ['catalog', 'subModalities', { modalityId }] as const,
    queryFn: async (params) => getCatalogSubModality({ ...params, modalityId }),
    enabled: !!modalityId && enabled,
    refetchOnWindowFocus: false,
    select(data) {
      return data?.data?.map((subModality) => ({
        label: subModality.sigla,
        value: subModality.id,
      }))
    },
  })
}
