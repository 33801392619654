import React from 'react'

import { CheckboxChangeEvent } from 'antd/es/checkbox'

import { colors } from '../../styles/theme'
import { StyledCheckbox } from './style'

export interface Props {
  label?: string
  value?: string | null
  disabled?: boolean
  name?: string
  onChange?: (e: CheckboxChangeEvent) => void
  checked?: boolean
  color?: string
}

const CheckBox: React.FC<Props> = ({
  label,
  onChange,
  value,
  disabled,
  name,
  checked,
  color = colors.purple,
}) => {
  return (
    <StyledCheckbox
      onChange={onChange}
      name={name}
      value={value}
      disabled={disabled}
      checked={checked}
      color={color}
    >
      {label}
    </StyledCheckbox>
  )
}

export default CheckBox
