import { WidgetProps } from '@rjsf/utils'

import { Error, Select } from '../../components'
import { FormItem, FormItemLabel } from '../styles'

export function SelectWidget({
  value,
  options,
  defaultValue,
  label,
  required,
  rawErrors,
  ...rest
}: WidgetProps) {
  const error = rawErrors
    ? {
        type: 'unknown',
        message: rawErrors.at(0),
      }
    : null

  return (
    <FormItem colSpan={options.colSpan as string | number}>
      <FormItemLabel>
        {label}
        {required ? '*' : ''}
      </FormItemLabel>
      <Select
        options={options.enumOptions ?? []}
        value={value}
        defaultValue={defaultValue as string | string[]}
        showSearch
        {...rest}
      />
      {error && <Error error={error} />}
    </FormItem>
  )
}
