import { colors } from '@design-system/styles/theme'
import { Table } from 'antd'
import styled from 'styled-components'

export const CompactTable = styled(Table)`
  margin-top: 20px;

  .ant-table-thead .ant-table-cell {
    background-color: transparent;
    border-bottom: none;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    display: none !important;
  }

  .ant-table-thead > tr > th {
    color: #6c7895;
    padding-bottom: 4px;
    font-weight: normal;
  }

  .ant-table-tbody > tr > td {
    background-color: #ffffff;
    margin-top: 1px;
    color: #485964;
  }

  .anticon-down .anticon-up svg {
    color: ${colors.gray} !important;
  }

  .ant-table-tbody > tr:first-child > td:last-child {
    border-top-right-radius: 7px;
  }

  .ant-table-tbody > tr:first-child > td:first-child {
    border-top-left-radius: 7px;
  }

  .ant-table-tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 7px;
  }

  .ant-table-tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 7px;
  }

  .ant-table-tbody > tr:first-child > td {
    border-top: 1px solid #dfdfdf;
  }

  .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid #dfdfdf;
  }

  .ant-table-tbody > tr > td {
    border-right: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;

    padding: 4px;
  }

  & .ant-table-tbody:hover {
    cursor: pointer !important;
  }
`

export const TitleContainer = styled.div`
  display: flex;
`
export const Title = styled.span`
  font-weight: 600;
  color: rgb(75, 75, 75) !important;
  text-wrap: nowrap;
  border-bottom: 2px solid #514984;
  padding-bottom: 10px;
`
export const Divider = styled.div`
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
`

export const InvibleTitleInput = styled.input`
  width: 320px;
  padding: 5px;
  border: none;
  background: transparent;
  color: rgb(72, 89, 100);
`

export const TextInputContainer = styled.div`
  width: 384px;
  margin-right: 12px;
`

export const FormContainer = styled.div`
  display: flex;
  margin-top: 36px;
`
