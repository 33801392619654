import React, { ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'

import { DownOutlined, UpOutlined } from '@ant-design/icons'

import { Spinner } from '../components'
import MobileTable from './MobileTable'
import { DesktopTable, ExpandableRowDiv, ExpandableRowDivider } from './style'

export interface Props {
  columns: Array<{
    title: string
    dataIndex: string
    key: string
  }>
  data: Array<object>
  expandableRowComponent?: React.FC
  locale?: object
  loading?: boolean
  onRowClick?: (record: unknown, index?: number) => void
  mobileColumns?: Array<{
    title: string
    dataIndex: string
    customHeader: (params: { record: unknown }) => ReactNode
  }>
}

const Table = ({
  columns,
  data,
  expandableRowComponent,
  locale = { emptyText: 'Nenhum resultado encontrado' },
  loading,
  onRowClick,
  mobileColumns,
}: Props) => {
  const tableLoading = {
    spinning: loading,
    indicator: <Spinner />,
  }

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const expandedRowRender = (record: object, expandableComponent?: React.FC) => {
    return (
      <>
        <ExpandableRowDiv>
          <ExpandableRowDivider />
          {expandableComponent && expandableComponent(record)}
        </ExpandableRowDiv>
      </>
    )
  }

  return (
    <>
      {isMobile ? (
        <MobileTable
          dataSource={data}
          mobileColumns={mobileColumns}
          expandableRowComponent={(record) => expandedRowRender(record, expandableRowComponent)}
        />
      ) : (
        <DesktopTable
          locale={locale}
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={tableLoading}
          rowClassName={() => 'editable-row'}
          onRow={(record, index) => {
            return {
              onClick: () => onRowClick && onRowClick(record, index),
            }
          }}
          expandable={{
            expandedRowRender: (record) => expandedRowRender(record, expandableRowComponent),
            defaultExpandedRowKeys: [],
            expandIcon: ({ expanded, onExpand, record }) =>
              expandableRowComponent &&
              (expanded ? (
                <UpOutlined onClick={(e) => onExpand(record, e)} />
              ) : (
                <DownOutlined onClick={(e) => onExpand(record, e)} />
              )),
            expandIconColumnIndex: 8,
            expandRowByClick: !!expandableRowComponent,
            expandedRowClassName: () => 'editable-expanded-row',
          }}
        />
      )}
    </>
  )
}

export default Table
