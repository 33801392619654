import { StatusTag } from './style'

export type OrderFillingStatus = 'CREATED' | 'PENDING' | 'SUCCESS' | 'ERROR'

export interface OrderFillingStatusTagProps {
  status: OrderFillingStatus
}

enum StatusLocale {
  CREATED = 'Criado',
  PENDING = 'Pendente',
  SUCCESS = 'Sucesso',
  ERROR = 'Erro',
}

export const OrderFillingStatusTag: React.FC<OrderFillingStatusTagProps> = ({ status }) => {
  return (
    <>
      <StatusTag status={status}>{StatusLocale[status]}</StatusTag>
    </>
  )
}
