import { useMutation } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '@/App/clients/http'

import { DocumentDto } from '../../healthPlansOrders/dtos'

type FillOcrData = {
  document?: DocumentDto
  documentType: string
}

export type OcrDocumentInformationResponse = {
  name: string
  number: string
  motherName: string
  issuerIdentityDocument: string
  cpfNumber: string
  dateBirth: string
  rgNumber: string
}

async function fillOcrInformation({ document, documentType }: FillOcrData) {
  const response = await api.post<OcrDocumentInformationResponse>(
    `${import.meta.env.VITE_OCR_URL}/info`,
    {
      name: document?.fileName,
      url: document?.previewURL,
      type: documentType === 'RG' ? 'IDENTIFICATION_CARD' : 'DRIVERS_LICENSE',
    },
    {
      headers: {
        'x-api-key': import.meta.env.VITE_OCR_APIKEY,
      },
    },
  )

  return response.data
}

export function useFillOcrInformation() {
  return useMutation({
    mutationKey: ['OCR', 'Document'],
    mutationFn: fillOcrInformation,
    onSuccess: () => {
      triggerToast(true, 'informações preenchidas com sucesso!')
    },
    onError: () => {
      triggerToast(false, 'Ops, ocorreu um erro ao preencher as informações.')
    },
  })
}
