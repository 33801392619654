import React, { useEffect, useState } from 'react'

import { CloseCircleOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons'
import { colors } from '@design-system/styles/theme'
import { triggerToast } from '@design-system/utils/triggers'
import type { UseQueryResult } from '@tanstack/react-query'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { Button, CheckBox, Counter, Modal, Skeleton } from 'design-system/components'

import { HealthPlansOrdersRequests } from '@/App/clients/healthPlansOrders'
import type { GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import useFetch from '@/App/clients/http'

import { EntityAccordion } from './entityAccordion'
import { EntitiesProvider } from './store'
import {
  CheckBoxesHeaderDiv,
  CompanyOrderHeaderDiv,
  DownloadDocumentsButtonDiv,
  DownloadDocumentsModalCancelButtonDiv,
  DownloadDocumentsModalDiv,
  DownloadDocumentsModalSaveButtonDiv,
  EntitiesCheckBoxesDiv,
  HorizontalLine,
  ModalActionButtonsDiv,
  OrderEntitiesDiv,
  OrderEntitiesHeader,
} from './style'

export interface EntitiesProps {
  validateOrderDispatch: boolean
  validateSiswebDispatch: boolean
  orderInformationQuery: UseQueryResult<GetOrderInformationBody, Error>
}

export const Entities: React.FC<EntitiesProps> = ({
  validateOrderDispatch,
  validateSiswebDispatch,
  orderInformationQuery,
}) => {
  const [holderGroupsQuantity, setHolderGroupsQuantity] = useState(0)
  const [isDocumentsDownloadModalVisible, setIsDocumentsDownloadModalVisible] = useState(false)

  const [selectedEntitiesIdsFromDownloadFileModal, setSelectedEntitiesIdsFromDownloadFilesModal] =
    useState<Array<string | undefined | null>>([])
  const [isLoadingCreateHolderDiv, setIsLoadingCreateHolderDiv] = useState(false)

  const {
    setRequestConfig: setCreateOrderHolderRequestConfig,
    statusCode: createOrderHolderStatusCode,
    isLoading: isLoadingCreateOrderHolder,
  } = useFetch()

  const orderData = orderInformationQuery?.data

  const {
    setRequestConfig: setDownloadOrderDocumentsRequestConfig,
    statusCode: downloadEntitiesStatusCode,
    isLoading: isLoadingDownloadOrderDocuments,
    data: downloadOrderDocumentsData,
    fileName: downloadOrderDocumentsFileName,
  } = useFetch<ArrayBuffer>()

  useEffect(() => {
    if (orderData) {
      setHolderGroupsQuantity(orderData.holders?.length ?? 0)

      const targetVariableEntities =
        orderData.type !== 'COMPANY' ? orderData.holders?.at(0)?.dependents : orderData.holders
      setSelectedEntitiesIdsFromDownloadFilesModal([
        undefined,
        ...(targetVariableEntities?.map(() => undefined) ?? []),
        undefined,
        undefined,
      ])
    }
  }, [orderData])

  useEffect(() => {
    if (isLoadingCreateOrderHolder) setIsLoadingCreateHolderDiv(true)
  }, [isLoadingCreateOrderHolder])

  useEffect(() => {
    if (!orderInformationQuery.isPending) setIsLoadingCreateHolderDiv(false)
  }, [orderInformationQuery.isPending])

  useEffect(() => {
    if (createOrderHolderStatusCode === 201) {
      triggerToast(true, 'Grupo titular adicionado com sucesso!')
      setHolderGroupsQuantity(holderGroupsQuantity + 1)
      setIsLoadingCreateHolderDiv(false)
      orderInformationQuery.refetch()
    } else if (createOrderHolderStatusCode && createOrderHolderStatusCode > 400)
      triggerToast(false, 'Ops, ocorreu um erro ao adicionar um grupo titular')
  }, [createOrderHolderStatusCode])

  useEffect(() => {
    if (downloadOrderDocumentsData && downloadOrderDocumentsFileName) {
      const blob = new Blob([downloadOrderDocumentsData])
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', downloadOrderDocumentsFileName)

      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }
  }, [downloadOrderDocumentsData])

  useEffect(() => {
    if (downloadEntitiesStatusCode && downloadEntitiesStatusCode > 400)
      triggerToast(false, 'Ops, ocorreu um erro ao fazer o download dos arquivos')
  }, [downloadEntitiesStatusCode])

  const createHolder = () => {
    if (orderData?.id) {
      const createOrderHolderRequestConfig = HealthPlansOrdersRequests.createOrderHolder(
        orderData.id,
      )
      setCreateOrderHolderRequestConfig(createOrderHolderRequestConfig)
    }
  }

  const handleEntityCheckBoxChange = (value: boolean, entityId: string, index: number) => {
    if (value) {
      setSelectedEntitiesIdsFromDownloadFilesModal([
        ...selectedEntitiesIdsFromDownloadFileModal.slice(0, index),
        entityId,
        ...selectedEntitiesIdsFromDownloadFileModal.slice(index + 1),
      ])
    } else {
      setSelectedEntitiesIdsFromDownloadFilesModal([
        ...selectedEntitiesIdsFromDownloadFileModal.slice(0, index),
        undefined,
        ...selectedEntitiesIdsFromDownloadFileModal.slice(index + 1),
      ])
    }
  }

  const handleAllEntitysCheckboxChange = (value: boolean) => {
    if (value) {
      if (orderData?.type !== 'COMPANY') {
        setSelectedEntitiesIdsFromDownloadFilesModal([
          orderData?.holders?.at(0)?.id,
          ...(orderData?.holders?.at(0)?.dependents?.map((item) => item.id) ?? []),
          orderData?.financialOfficer?.id,
          orderData?.id,
        ])
      } else {
        setSelectedEntitiesIdsFromDownloadFilesModal([
          orderData?.company?.id,
          ...(orderData?.holders?.map((item) => item.id) ?? []),
          orderData?.financialOfficer?.id,
          orderData.id,
        ])
      }
    } else {
      const targetVariableEntities =
        orderData?.type !== 'COMPANY' ? orderData?.holders?.at(0)?.dependents : orderData.holders
      setSelectedEntitiesIdsFromDownloadFilesModal([
        undefined,
        ...(targetVariableEntities?.map(() => undefined) ?? []),
        undefined,
        undefined,
      ])
    }
  }

  const handleDownloadOrderDocumentsModalCancel = () => {
    setIsDocumentsDownloadModalVisible(false)

    const targetVariableEntities =
      orderData?.type !== 'COMPANY' ? orderData?.holders?.at(0)?.dependents : orderData.holders
    setSelectedEntitiesIdsFromDownloadFilesModal([
      undefined,
      ...(targetVariableEntities?.map(() => undefined) ?? []),
      undefined,
      undefined,
    ])
  }

  const downloadOrderDocuments = (orderId?: string | null) => {
    if (orderId) {
      const downloadOrderDocumentsRequestConfig = HealthPlansOrdersRequests.downloadOrderDocuments(
        orderId,
        selectedEntitiesIdsFromDownloadFileModal,
      )
      setDownloadOrderDocumentsRequestConfig(downloadOrderDocumentsRequestConfig)
    }
  }

  return (
    <EntitiesProvider initialData={orderData}>
      <OrderEntitiesDiv>
        <OrderEntitiesHeader>
          {orderData?.type === 'COMPANY' ? (
            <CompanyOrderHeaderDiv>
              <span>Beneficiários Titulares</span>
              {isLoadingCreateHolderDiv ? (
                <Skeleton type="avatar" shape="round" />
              ) : (
                <Counter
                  count={holderGroupsQuantity}
                  onCountChange={createHolder}
                  hideButton="decrement"
                />
              )}
            </CompanyOrderHeaderDiv>
          ) : (
            <span>Beneficiários</span>
          )}
          <DownloadDocumentsButtonDiv>
            <Button
              onClick={() => setIsDocumentsDownloadModalVisible(true)}
              height="30px"
              backgroundColor={colors.darkPurple}
            >
              <VerticalAlignBottomOutlined /> <p>Baixar documentos</p>
            </Button>
          </DownloadDocumentsButtonDiv>
        </OrderEntitiesHeader>

        <HorizontalLine />

        {orderData == null ? (
          <>
            <Skeleton type="button" block />
            <Skeleton type="button" block />
            <Skeleton type="button" block />
          </>
        ) : (
          <>
            {orderData.type === 'COMPANY' ? (
              <>
                <EntityAccordion
                  type="COMPANY"
                  entityData={orderData?.company}
                  orderInformationQuery={orderInformationQuery}
                  validateOrderDispatch={validateOrderDispatch}
                  validateSiswebDispatch={validateSiswebDispatch}
                />

                {orderData.holders?.map((holder, index) => {
                  return (
                    <EntityAccordion
                      key={holder.id}
                      type="HOLDER_GROUP"
                      entityData={holder}
                      orderInformationQuery={orderInformationQuery}
                      index={index}
                      validateOrderDispatch={validateOrderDispatch}
                      validateSiswebDispatch={validateSiswebDispatch}
                    />
                  )
                })}
              </>
            ) : (
              <>
                <EntityAccordion
                  type="HOLDER"
                  entityData={orderData.holders?.at(0)}
                  orderInformationQuery={orderInformationQuery}
                  validateOrderDispatch={validateOrderDispatch}
                  validateSiswebDispatch={validateSiswebDispatch}
                />
                {orderData.holders?.at(0)?.dependents?.map((dependent, index) => {
                  return (
                    <EntityAccordion
                      key={dependent.id}
                      type="DEPENDENT"
                      entityData={dependent}
                      orderInformationQuery={orderInformationQuery}
                      index={index}
                      validateOrderDispatch={validateOrderDispatch}
                      validateSiswebDispatch={validateSiswebDispatch}
                    />
                  )
                })}
              </>
            )}
          </>
        )}

        {orderData == null ? (
          <></>
        ) : (
          <Modal
            isOpen={isDocumentsDownloadModalVisible}
            setIsOpen={setIsDocumentsDownloadModalVisible}
            footer={
              <ModalActionButtonsDiv>
                <DownloadDocumentsModalCancelButtonDiv>
                  <Button
                    palette="cancel"
                    onClick={() => handleDownloadOrderDocumentsModalCancel()}
                  >
                    <CloseCircleOutlined />
                    Cancelar
                  </Button>
                </DownloadDocumentsModalCancelButtonDiv>

                <DownloadDocumentsModalSaveButtonDiv>
                  <Button
                    onClick={() => downloadOrderDocuments(orderData.id)}
                    loading={isLoadingDownloadOrderDocuments}
                    disabled={selectedEntitiesIdsFromDownloadFileModal.every(
                      (entityId) => entityId == null,
                    )}
                  >
                    Salvar
                  </Button>
                </DownloadDocumentsModalSaveButtonDiv>
              </ModalActionButtonsDiv>
            }
            bodyStyle={{ display: 'flex', flexDirection: 'column', height: '280px' }}
            width={1045}
          >
            <DownloadDocumentsModalDiv>
              <p>Selecione quais destas pastas de documentos você deseja baixar:</p>

              <CheckBoxesHeaderDiv>
                <CheckBox
                  value="all"
                  label="Todas"
                  onChange={(e: CheckboxChangeEvent) =>
                    handleAllEntitysCheckboxChange(e.target.checked)
                  }
                  checked={selectedEntitiesIdsFromDownloadFileModal.every((item) => item != null)}
                />
                <HorizontalLine />
              </CheckBoxesHeaderDiv>

              <EntitiesCheckBoxesDiv>
                {orderData.type == 'COMPANY' ? (
                  <>
                    <CheckBox
                      value={orderData?.company?.id}
                      label="Empresa"
                      onChange={(e: CheckboxChangeEvent) =>
                        handleEntityCheckBoxChange(e.target.checked, e.target.value, 0)
                      }
                      checked={!!selectedEntitiesIdsFromDownloadFileModal[0]}
                    />
                    {orderData?.holders?.map((holder, index) => {
                      return (
                        <CheckBox
                          key={holder.id}
                          value={holder.id}
                          label={`Grupo titular ${index + 1}`}
                          onChange={(e: CheckboxChangeEvent) =>
                            handleEntityCheckBoxChange(e.target.checked, e.target.value, index + 1)
                          }
                          checked={!!selectedEntitiesIdsFromDownloadFileModal[index + 1]}
                        />
                      )
                    })}
                  </>
                ) : (
                  <>
                    <CheckBox
                      value={orderData?.holders?.at(0)?.id}
                      label="Titular"
                      onChange={(e: CheckboxChangeEvent) =>
                        handleEntityCheckBoxChange(e.target.checked, e.target.value, 0)
                      }
                      checked={!!selectedEntitiesIdsFromDownloadFileModal[0]}
                    />
                    {orderData?.holders?.at(0)?.dependents?.map((dependent, index) => {
                      return (
                        <CheckBox
                          key={dependent.id}
                          value={orderData?.holders?.at(0)?.dependents?.at(index)?.id}
                          label={`Dependente ${index + 1}`}
                          onChange={(e: CheckboxChangeEvent) =>
                            handleEntityCheckBoxChange(e.target.checked, e.target.value, index + 1)
                          }
                          checked={!!selectedEntitiesIdsFromDownloadFileModal[index + 1]}
                        />
                      )
                    })}
                  </>
                )}
                <CheckBox
                  value={orderData?.financialOfficer?.id}
                  label="Responsável Financeiro"
                  onChange={(e: CheckboxChangeEvent) =>
                    handleEntityCheckBoxChange(
                      e.target.checked,
                      e.target.value,
                      selectedEntitiesIdsFromDownloadFileModal.length - 2,
                    )
                  }
                  checked={
                    !!selectedEntitiesIdsFromDownloadFileModal[
                      selectedEntitiesIdsFromDownloadFileModal.length - 2
                    ]
                  }
                />
                <CheckBox
                  value={orderData?.id}
                  label="Anexos da proposta"
                  onChange={(e: CheckboxChangeEvent) =>
                    handleEntityCheckBoxChange(
                      e.target.checked,
                      e.target.value,
                      selectedEntitiesIdsFromDownloadFileModal.length - 1,
                    )
                  }
                  checked={
                    !!selectedEntitiesIdsFromDownloadFileModal[
                      selectedEntitiesIdsFromDownloadFileModal.length - 1
                    ]
                  }
                />
              </EntitiesCheckBoxesDiv>
            </DownloadDocumentsModalDiv>
          </Modal>
        )}
      </OrderEntitiesDiv>
    </EntitiesProvider>
  )
}
