import { toast } from 'react-toastify'

import { CheckOutlined, WarningOutlined } from '@ant-design/icons'

export const triggerToast = (toastType: boolean, message: string, autoCloseTime?: number) =>
  toastType
    ? toast.success(<span>{message}</span>, {
        icon: <CheckOutlined />,
        autoClose: autoCloseTime ?? 8000,
      })
    : toast.error(<span>{message}</span>, {
        icon: <WarningOutlined />,
        autoClose: autoCloseTime ?? 8000,
      })
