import { useMutation } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '../../http'

type UploadOrderEntityDocumentRequest = {
  data: FormData
  orderId?: string | null
  entityId?: string | null
}

async function uploadOrderEntityDocument({
  data,
  orderId,
  entityId,
}: UploadOrderEntityDocumentRequest) {
  const response = await api.put<{ documentId: string; uploadURL?: string | null }>(
    `${
      import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
    }/api/v1/orders/${orderId}/entities/${entityId}/document-files`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    },
  )
  return response.data
}

export const useUploadOrderEntityDocument = () => {
  return useMutation({
    mutationKey: ['uploadOrderEntityDocument'],
    mutationFn: uploadOrderEntityDocument,
    onSuccess: () => {
      triggerToast(true, 'Upload feito com sucesso!')
    },
    onError: () => {
      triggerToast(false, 'Ops, ocorreu um erro durante o upload.')
    },
  })
}
