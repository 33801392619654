import { WidgetProps } from '@rjsf/utils'
import styled from 'styled-components'

const SubtitleWidgetWrapper = styled.div`
  grid-column: span 2;
`
const Subtitle = styled.h3`
  color: #4b4b4b;
  font-size: 15px;
  font-weight: 600;
`

const HorizontalLine = styled.hr`
  height: 1px;
  border-width: 0;
  margin-top: 0;
  background-color: #cbcedc;
  width: 100%;
`

export function SubtitleWidget(props: WidgetProps) {
  return (
    <SubtitleWidgetWrapper id={props.id}>
      <Subtitle>{props.label || props.title}</Subtitle>
      <HorizontalLine />
    </SubtitleWidgetWrapper>
  )
}
