import { WidgetProps } from '@rjsf/utils'

import { Radio } from '../../components'
import { FormItem, FormItemLabel } from '../styles'

export function RadioWidget({
  label,
  value,
  options,
  rawErrors,
  onChange,
  required,
  ...rest
}: WidgetProps) {
  const error = rawErrors
    ? {
        type: 'unknown',
        message: rawErrors.at(0),
      }
    : null

  return (
    <FormItem>
      <FormItemLabel>
        {label}
        {required ? '*' : ''}
      </FormItemLabel>
      <div>
        <Radio
          defaultValue={value === undefined ? false : value}
          options={options.enumOptions ?? []}
          error={error}
          onChange={(value) => onChange(value === 'true')}
          {...rest}
        />
      </div>
    </FormItem>
  )
}
