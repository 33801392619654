import React from 'react'
import { ContainerError } from './style'
import { WarningFilled } from '@ant-design/icons'
import { FieldError } from 'react-hook-form'
import { colors } from '../../styles/theme'

interface Props {
  error?: FieldError
}

const Error: React.FC<Props> = ({ error }) => {
  return (
    <ContainerError vertical={true}>
      <WarningFilled style={{ color: colors.red }} />
      {error?.message}
    </ContainerError>
  )
}
export default Error
