import React, { ReactNode, useEffect, useState } from 'react'

import { CopyOutlined, DownloadOutlined } from '@ant-design/icons'

import { formatPhone } from '../../utils/mask'
import { formatCnpj, formatCpf } from '../../utils/mask'
import { Spinner } from '../components'
import { StyledCopiedMessage, StyledCopy, StyledLabel, StyledTextLabel } from './style'

export interface TextLabelProps {
  backgroundColor?: string
  defaultText?: string
  rightIconActionIsLoading?: boolean
  hasLeftIcon?: boolean
  leftIcon?: ReactNode | null
  onDownload?: (reference?: string) => void
  reference?: string
  rightIconType?: 'copy' | 'download' | null
  text?: string | null
  type?: 'phone' | 'cpf' | 'cnpj'
}
const TextLabel: React.FC<TextLabelProps> = ({
  backgroundColor,
  defaultText = 'Não informado',
  rightIconActionIsLoading,
  leftIcon,
  onDownload,
  reference,
  rightIconType,
  text,
  type,
}) => {
  const [copied, setCopied] = useState(false)

  const copyToClipboard = () => {
    if (text) {
      navigator.clipboard.writeText(text)
      setCopied(true)
    } else {
      setCopied(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setCopied(false)
    }, 4000)
  }, [copied])

  const applyMask = (value: string): string => {
    if (!value) return value

    switch (type) {
      case 'phone':
        return formatPhone(value)
      case 'cpf':
        return formatCpf(value)
      case 'cnpj':
        return formatCnpj(value)
      default:
        return value
    }
  }

  return (
    <>
      <StyledTextLabel backgroundColor={backgroundColor} hasLeftIcon={!!leftIcon}>
        {leftIcon}
        <StyledLabel>{text == null ? defaultText : applyMask(text)}</StyledLabel>
        {rightIconType && (
          <StyledCopy
            onClick={() => {
              if (rightIconType === 'copy') {
                return copyToClipboard()
              }

              onDownload && onDownload(reference)
            }}
          >
            {rightIconActionIsLoading ? (
              <Spinner height="15px" width="15px" />
            ) : (
              <>
                {copied && <StyledCopiedMessage>Copiado com sucesso</StyledCopiedMessage>}
                {rightIconType === 'download' && <DownloadOutlined />}
                {rightIconType === 'copy' && <CopyOutlined />}
              </>
            )}
          </StyledCopy>
        )}
      </StyledTextLabel>
    </>
  )
}
export default TextLabel
