import { Collapse as Accordion } from 'antd'
import styled from 'styled-components'

const { Panel } = Accordion

export interface StyledAccordionProps {
  backgroundColor?: string | null
}

export interface CollapsePanelProps {
  innerPadding?: boolean
  backgroundColor?: string | null
  isMobile?: boolean
  borderless?: boolean
}

export interface FooterIconDivProps {
  fixedFooterSize?: boolean
}

export const StyledCollapse = styled(Accordion)<StyledAccordionProps>`
  &&& {
    background-color: ${(props) => props.backgroundColor ?? '#ffffff'};
    border: none;
  }
`

export const CollapsePanel = styled(Panel)<CollapsePanelProps>`
  &&& {
    font-size: 18px;
    box-shadow: ${(props) =>
      !props.isMobile && !props.borderless && props.innerPadding
        ? '0px 3px 10px rgba(27, 26, 69, 0.25)'
        : '0'};
    border: none;
    border-bottom: ${(props) => (props.isMobile || props.borderless) && '2px solid #DCDFEB'};
    border-radius: ${(props) => (props.isMobile || props.borderless ? '0' : '10px')};
  }

  .ant-collapse-content-box {
    background-color: ${(props) => props.backgroundColor ?? '#ffffff'};
    padding: ${(props) => (props.innerPadding ? '16px 16px' : '0')};
  }

  & .ant-collapse-expand-icon {
    text-align: center;
  }

  &&& .ant-collapse-header {
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    border-radius: 0px;
    background: #ffffff;
  }
`
export const CollapseContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
`

export const FooterIconDiv = styled.div<FooterIconDivProps>`
  width: ${(props) => (props.fixedFooterSize ? '25px' : 'auto')};
`
