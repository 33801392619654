import { Progress } from 'antd'
import styled from 'styled-components'
import { colors } from '../../styles/theme'

export const StyledProgressBar = styled(Progress)`
  margin-bottom: 0px;

  &&& .ant-progress-bg {
    background-color: ${colors.primary}!important;
  }
`
