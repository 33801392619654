import { QueryFunctionContext, queryOptions, useQuery } from '@tanstack/react-query'

import { api } from '../../http'

type CityOption = {
  label: string
  value: string
}

async function getCities({ signal }: QueryFunctionContext) {
  const response = await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios`, {
    signal,
  })
  return response.data
}

export function getCitiesQueryOptions() {
  const ONE_MONTH_IN_MS = 30 * 24 * 60 * 60 * 1000
  return queryOptions({
    queryKey: ['cities'] as const,
    queryFn: async (params) => getCities({ ...params }),
    refetchOnWindowFocus: false,
    staleTime: ONE_MONTH_IN_MS,
    select: (data) => {
      const citiesOptions: Array<CityOption> = []

      for (const city of data) {
        citiesOptions.push({
          label: city.nome,
          value: city.nome,
        })
      }
      return citiesOptions
    },
  })
}

export const useGetCitiesQuery = () => {
  return useQuery(getCitiesQueryOptions())
}
