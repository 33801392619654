import { useMutation, useQueryClient } from '@tanstack/react-query'

import { api } from '../../http'
import { ChangeNameCommissionGridPayPayload } from '../dtos'

async function changeNameCommissionGridPay({ id, ...data }: ChangeNameCommissionGridPayPayload) {
  const url = `${import.meta.env.VITE_FINOPS_SERVICES_URL}/grade-comissao-pagar/${id}`

  return api.patch(url, data, {
    headers: {
      Authorization: localStorage.getItem('authToken'),
    },
  })
}

export function useChangeNameCommissionGridPayMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['commissionGridPay', 'change'] as const,
    mutationFn: async (data: ChangeNameCommissionGridPayPayload) =>
      changeNameCommissionGridPay(data),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['commissionGridsPay'] })
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['commissionGridsPay'],
      })
    },
  })
}
