import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { useQuery } from '@tanstack/react-query'
import RadioGroup from 'design-system/Radio/Radio'
import { OnFileUploadInput } from 'design-system/Upload/Upload'
import {
  Counter,
  DatePicker,
  Select,
  Skeleton,
  Spinner,
  TextInput,
  Toast,
  Upload,
} from 'design-system/components'

import { getCatalogAdministratorOptions } from '@/App/clients/catalogApi/queries/catalogAdministrator'
import { getCatalogInsurerOptions } from '@/App/clients/catalogApi/queries/catalogInsurers'
import { getCatalogModalityOptions } from '@/App/clients/catalogApi/queries/catalogModality'
import { getCatalogPlanOptions } from '@/App/clients/catalogApi/queries/catalogPlan'
import { getCatalogProductsOptions } from '@/App/clients/catalogApi/queries/catalogProducts'
import { getCatalogSubModalityOptions } from '@/App/clients/catalogApi/queries/catalogSubModality'
import { CreateQuotationInputDto } from '@/App/clients/healthPlansOrders/dtos'
import { useGetCitiesQuery } from '@/App/clients/healthPlansOrders/queries/getCities'
import { states } from '@/App/helpers/states'
import {
  OrderProductLocale,
  OrderSubtypeLocale,
  OrderTypeLocale,
} from '@/App/pages/orderDetails/quotation'
import { getObjectKeyByValue } from '@/App/utils/getObjectKeyByValue'
import { normalizeString } from '@/App/utils/string'

import { triggerToast } from '../../../../../../../design-system/src/utils/triggers'
import { useCreateOrderContext } from '../../store'
import { QuotationTag, QuotationTags } from '../style'
import {
  CreateQuotationDiv,
  CreateQuotationTitle,
  FieldsWrapper,
  FormDiv,
  FormItem,
  FormItemLabel,
  FormItemRadioRow,
  HorizontalLine,
  LoadingDiv,
  LoadingDivSubtitle,
  LoadingDivTitle,
  TagTitle,
} from './style'

export interface CreateQuotationProps {
  createQuotation: (data: CreateQuotationInputDto) => void
}

interface FormState {
  type?: string | null
  typeId?: string | null
  plan?: string | null
  planId?: string | null
  product?: string | null
  productId?: string | null
  insurer?: string | null
  insurerId?: string | null
  subtype?: string | null
  subtypeId?: string | null
  administrator?: string | null
  administratorId?: string | null
}

const defaultData: FormState = {
  typeId: null,
  plan: null,
  planId: null,
  productId: null,
  insurerId: null,
  subtypeId: null,
  administratorId: null,
}

const CreateQuotation: React.FC<CreateQuotationProps> = ({ createQuotation }) => {
  const [formState, setFormState] = useState<FormState>(defaultData)
  const createOrderContext = useCreateOrderContext()

  const {
    register,
    handleSubmit,
    watch,
    setValue: formSetValue,
    formState: { errors },
    control,
  } = useForm<CreateQuotationInputDto>()

  const formValues = watch()

  const getProductQuery = useQuery(
    getCatalogProductsOptions({ enabled: !createOrderContext.order.isLoading }),
  )
  const getModalityQuery = useQuery(
    getCatalogModalityOptions({ productId: formState.productId, enabled: !!formState.productId }),
  )

  const isAdesaoModality =
    getModalityQuery.data?.find((modality) => normalizeString(modality.label).includes('adesao'))
      ?.value === formState.typeId
  const isPME =
    getModalityQuery.data?.find((modality) => normalizeString(modality.label).includes('pme'))
      ?.value === formState.typeId

  const getAdministratorQuery = useQuery(
    getCatalogAdministratorOptions({
      modalityId: formState.typeId,
      enabled: !!formState.productId && !!formState.typeId && isAdesaoModality,
    }),
  )

  const getInsurerQuery = useQuery(
    getCatalogInsurerOptions({
      modalityId: formState.typeId,
      enabled: !!formState.productId && !!formState.typeId && !isAdesaoModality,
    }),
  )

  const getInsurerWithAdministratorQuery = useQuery(
    getCatalogInsurerOptions({
      administratorId: formState.administratorId,
      modalityId: formState.typeId,
      enabled: !!formState.administratorId && isAdesaoModality,
    }),
  )

  const getPlanQuery = useQuery(
    getCatalogPlanOptions({
      insurerId: formValues.insurerId,
      modalityId: isAdesaoModality ? null : formValues.typeId,
      enabled: !!formState.productId && !!formState.typeId && !!formState.insurerId,
    }),
  )

  const getSubModalityQuery = useQuery(
    getCatalogSubModalityOptions({
      modalityId: formState.typeId,
      enabled: isPME,
    }),
  )

  const getCities = useGetCitiesQuery()

  const isPorto = formValues?.insurer === 'Porto Seguro'
  const isNotreDame = formValues?.insurer === 'Notre Dame Intermédica'
  const isGndi = formValues?.insurer === 'GNDI'
  const isSulamerica = formValues?.insurer === 'Sulamérica'
  const isBradesco = formValues?.insurer === 'Bradesco Seguros'

  const handleChangeProductId = (productId?: string) => {
    setFormState({
      productId,
      typeId: defaultData.typeId,
      administratorId: defaultData.administratorId,
      insurerId: defaultData.insurerId,
      plan: defaultData.plan,
      planId: defaultData.planId,
      subtypeId: defaultData.subtypeId,
    })
    formSetValue(
      'product',
      getObjectKeyByValue({
        objectToExtract: OrderProductLocale,
        valueToCompare: getProductQuery.data?.find((product) => product.value === productId)?.label,
        exact: true,
      }),
    )
    formSetValue('typeId', undefined)
    formSetValue('administratorId', undefined)
    formSetValue('insurerId', undefined)
    formSetValue('plan', undefined)
    formSetValue('planId', undefined)
  }

  const handleChangeAdministratorId = (administratorId?: string) => {
    setFormState((prev) => ({
      productId: prev.productId,
      typeId: prev.typeId,
      administratorId,
      insurerId: defaultData.insurerId,
      plan: defaultData.plan,
      planId: defaultData.planId,
    }))
    formSetValue(
      'administrator',
      getAdministratorQuery.data?.find((administrator) => administrator.value === administratorId)
        ?.label,
    )
    formSetValue('insurerId', undefined)
    formSetValue('plan', undefined)
    formSetValue('planId', undefined)
    formSetValue('subtypeId', undefined)
  }

  const handleChangeInsurerId = (insurerId?: string) => {
    setFormState((prev) => ({
      productId: prev.productId,
      administratorId: prev.administratorId,
      typeId: formValues.typeId,
      insurerId,
      plan: defaultData.plan,
      planId: defaultData.planId,
    }))

    formSetValue(
      'insurer',
      isAdesaoModality
        ? getInsurerWithAdministratorQuery?.data?.find((insurer) => insurer.value === insurerId)
            ?.label
        : getInsurerQuery?.data?.find((insurer) => insurer.value === insurerId)?.label,
    )
    formSetValue('plan', undefined)
    formSetValue('planId', undefined)
  }

  const handleChangeTypeId = (typeId?: string) => {
    setFormState((prev) => ({
      productId: prev.productId,
      typeId,
      insurerId: defaultData.insurerId,
      plan: defaultData.plan,
      planId: defaultData.planId,
      subtypeId: defaultData.subtypeId,
      administratorId: defaultData.administratorId,
    }))
    formSetValue(
      'type',
      getObjectKeyByValue({
        objectToExtract: OrderTypeLocale,
        valueToCompare: getModalityQuery.data?.find((modality) => modality.value === typeId)?.label,
      }),
    )
    formSetValue('administratorId', undefined)
    formSetValue('insurerId', undefined)
    formSetValue('plan', undefined)
    formSetValue('planId', undefined)
    formSetValue('subtypeId', undefined)
  }

  const handleChangeSubTypeId = (subtypeId?: string) => {
    setFormState((prev) => ({
      productId: prev.productId,
      typeId: prev.typeId,
      subtypeId,
      insurerId: defaultData.insurerId,
      plan: defaultData.plan,
      planId: defaultData.planId,
      administratorId: defaultData.administratorId,
    }))
    formSetValue(
      'subtype',
      getObjectKeyByValue({
        objectToExtract: OrderSubtypeLocale,
        valueToCompare: getSubModalityQuery.data?.find((subtype) => subtype.value === subtypeId)
          ?.label,
      }),
    )
    formSetValue('administratorId', undefined)
    formSetValue('insurerId', undefined)
    formSetValue('plan', undefined)
    formSetValue('planId', undefined)
  }

  const handleChangePlan = (plan?: string) => {
    setFormState((prev) => ({
      administratorId: prev.administratorId,
      productId: prev.productId,
      typeId: prev.typeId,
      insurerId: prev.insurerId,
      plan,
    }))
  }

  const handleChangePlanId = (planId?: string) => {
    setFormState((prev) => ({
      productId: prev.productId,
      typeId: prev.typeId,
      insurerId: prev.insurerId,
      planId,
    }))

    formSetValue('plan', getPlanQuery.data?.find((plan) => plan.value === planId)?.label)
  }

  useEffect(() => {
    handleChangeProductId(formValues.productId)
  }, [formValues.productId])

  useEffect(() => {
    handleChangeAdministratorId(formValues.administratorId)
  }, [formValues.administratorId])

  useEffect(() => {
    handleChangeInsurerId(formValues.insurerId)
  }, [formValues.insurerId])

  useEffect(() => {
    handleChangeTypeId(formValues.typeId)
  }, [formValues.typeId])

  useEffect(() => {
    handleChangeSubTypeId(formValues.subtypeId)
  }, [formValues.subtypeId])

  useEffect(() => {
    handleChangePlan(formValues.plan)
  }, [formValues.plan])

  useEffect(() => {
    handleChangePlanId(formValues.planId)
  }, [formValues.planId])

  useEffect(() => {
    if (createOrderContext.quotation.error)
      triggerToast(false, 'Não foi possível criar uma nova cotação')

    if (createOrderContext.order.error) triggerToast(false, 'Não foi possível criar um novo pedido')
  }, [createOrderContext.order.error, createOrderContext.quotation.error])

  const handleFileUpload = async (files: OnFileUploadInput[]) => {
    formSetValue('quotationAttachmentFile', files[0].fileContent)
    formSetValue('quotationAttachmentFileName', files[0].fileName)
    formSetValue('quotationAttachmentFileContentType', files[0].fileContentType)
  }

  const handleLifesQuantityChange = (value: number) => {
    formSetValue('lifesQuantity', value)
  }

  if (createOrderContext.quotation.isLoading && createOrderContext.order.isLoading) {
    return (
      <LoadingDiv>
        <Spinner height="100px" width="100px" />
        <LoadingDivTitle>
          Quase lá! <br /> Estamos criando a proposta...
        </LoadingDivTitle>
        <LoadingDivSubtitle>
          Assim que ela for criada, você poderá subir documentos.
        </LoadingDivSubtitle>
      </LoadingDiv>
    )
  }

  return (
    <CreateQuotationDiv>
      <CreateQuotationTitle>Preencha a cotação referente à esta proposta</CreateQuotationTitle>

      <QuotationTags>
        <QuotationTag>
          <TagTitle>Corretor(a): </TagTitle>
          <p>{createOrderContext.broker?.name}</p>
        </QuotationTag>

        <QuotationTag>
          <TagTitle>Lead: </TagTitle>
          <p>{createOrderContext.lead?.name}</p>
        </QuotationTag>
      </QuotationTags>

      <FormDiv id="createOrder" onSubmit={handleSubmit(createQuotation)}>
        <FormItem colSpan={2}>
          <FormItemLabel>Produto*</FormItemLabel>
          {getProductQuery.isLoading ? (
            <Skeleton type="input" />
          ) : (
            <Select
              name="productId"
              options={getProductQuery.data ?? []}
              control={control}
              placeholder="Selecione o produto"
              rules={{
                required: 'Este campo é obrigatório.',
              }}
            />
          )}
        </FormItem>

        {getProductQuery.isSuccess && !!formState.productId ? (
          <>
            <FormItem colSpan={2}>
              <FormItemLabel>Modalidade*</FormItemLabel>
              {getModalityQuery.isLoading ? (
                <Skeleton type="input" />
              ) : (
                <FormItemRadioRow>
                  <RadioGroup
                    name="typeId"
                    options={getModalityQuery.data ?? []}
                    register={{
                      ...register('typeId', {
                        required: 'Você precisa selecionar uma das opções.',
                      }),
                    }}
                    error={errors.typeId}
                  />
                </FormItemRadioRow>
              )}
            </FormItem>

            {isPME && (
              <FormItem colSpan={2}>
                {getSubModalityQuery.isLoading && !!getSubModalityQuery?.data ? (
                  <Skeleton type="input" />
                ) : (
                  <FormItemRadioRow>
                    <HorizontalLine />
                    <RadioGroup
                      name="subtypeId"
                      options={getSubModalityQuery?.data || []}
                      register={{
                        ...register('subtypeId', {
                          required: isPME ? 'Você precisa selecionar uma das opções.' : undefined,
                        }),
                      }}
                      error={errors.subtypeId}
                    />
                  </FormItemRadioRow>
                )}
              </FormItem>
            )}

            {isAdesaoModality && (
              <FormItem>
                <FormItemLabel>Administradora*</FormItemLabel>
                {getAdministratorQuery.isLoading ? (
                  <Skeleton type="input" />
                ) : (
                  <Select
                    showSearch
                    name="administratorId"
                    control={control}
                    options={getAdministratorQuery.data ?? []}
                    placeholder="Selecione uma administradora"
                    rules={{
                      required: 'Este campo é obrigatório',
                    }}
                    disabled={getModalityQuery.isSuccess && !formState.typeId}
                  />
                )}
              </FormItem>
            )}

            <FormItem>
              <FormItemLabel>Operadora*</FormItemLabel>
              {getInsurerQuery.isLoading || getInsurerWithAdministratorQuery.isLoading ? (
                <Skeleton type="input" />
              ) : (
                <Select
                  showSearch
                  name="insurerId"
                  control={control}
                  options={
                    (isAdesaoModality
                      ? getInsurerWithAdministratorQuery.data
                      : getInsurerQuery.data) ?? []
                  }
                  placeholder="Selecione uma operadora"
                  rules={{
                    required: 'Este campo é obrigatório',
                  }}
                  disabled={
                    (getModalityQuery.isSuccess && !formState.typeId) ||
                    (isAdesaoModality && !formState.administratorId)
                  }
                />
              )}
            </FormItem>

            <FormItem>
              <FormItemLabel>
                Data de vigência {(isPorto || isGndi || isNotreDame) && '*'}
              </FormItemLabel>
              <DatePicker
                name="effectiveDate"
                control={control}
                placeholder="00/00/0000"
                rules={{
                  required: (isPorto || isGndi || isNotreDame) && 'Este campo é obrigatório.',
                }}
              />
            </FormItem>

            {createOrderContext.isRegisteredInInsurer ? (
              <FormItem>
                <FormItemLabel>Espelho da Proposta</FormItemLabel>
                <Upload
                  text="Insira o PDF da proposta"
                  onFileUpload={(files) =>
                    createOrderContext.setOrder({
                      data: {
                        proposalMirrorFile: files.at(0),
                      },
                    })
                  }
                />
              </FormItem>
            ) : null}

            <FormItem>
              <FormItemLabel>Anexo da cotação</FormItemLabel>
              <Upload text="Insira o PDF da cotação" onFileUpload={handleFileUpload} />
            </FormItem>

            <FormItem>
              <TextInput
                name="externalURL"
                vertical
                placeholder="http://"
                label="Link da cotação"
                register={{
                  ...register('externalURL'),
                }}
              />
            </FormItem>

            {(isPorto || isSulamerica || isBradesco) && (
              <FormItem>
                <FormItemLabel>Número Cotação Operadora</FormItemLabel>
                <TextInput
                  name="externalId"
                  vertical
                  placeholder="00000000"
                  register={{
                    ...register('externalId'),
                  }}
                  error={errors.externalId}
                />
              </FormItem>
            )}

            {(isGndi || isNotreDame || isSulamerica) && (
              <FormItem>
                <FormItemLabel>Coparticipação?*</FormItemLabel>

                <RadioGroup
                  name="isCoparticipation"
                  options={[
                    { label: 'Completa', value: 'true' },
                    { label: 'Parcial ', value: 'false' },
                  ]}
                  register={{
                    ...register('isCoparticipation', {
                      required: 'Você precisa selecionar uma das opções.',
                    }),
                  }}
                  defaultValue="false"
                  error={errors.isCoparticipation}
                />
              </FormItem>
            )}

            {createOrderContext.isRegisteredInInsurer ? (
              <>
                <FormItem>
                  <FormItemLabel>Proposta cadastrada na Sisweb?</FormItemLabel>
                  <RadioGroup
                    name="isRegisteredInInternalSystem"
                    options={[
                      { value: 'true', label: 'Sim' },
                      { value: 'false', label: 'Não' },
                    ]}
                    onChange={(value) => {
                      createOrderContext.setOrder({
                        data: {
                          isRegisteredInInternalSystem: value === 'true',
                        },
                      })
                    }}
                  />
                </FormItem>
                <FormItem>
                  <FormItemLabel>Nº da proposta na operadora</FormItemLabel>
                  <TextInput
                    name="registeredInsurerId"
                    vertical
                    placeholder="Ex:. 123456"
                    onChange={(event) => {
                      createOrderContext.setOrder({
                        data: {
                          registeredInsurerId: event.target.value,
                        },
                      })
                    }}
                  />
                </FormItem>
              </>
            ) : null}

            <FieldsWrapper>
              <FormItem>
                <TextInput
                  name="totalAmount"
                  vertical
                  placeholder="R$ 0,00"
                  label="Valor total*"
                  mask="money"
                  register={{
                    ...register('totalAmount', {
                      pattern: {
                        value: /^R\$ \d{1,3}(?:\.\d{3})*(?:,\d{2})$/,
                        message: 'Valor inválido',
                      },
                      required: 'Este campo é obrigatório.',
                    }),
                  }}
                  error={errors.totalAmount}
                />
              </FormItem>

              <FormItem>
                <FormItemLabel>Quantidade de vidas*</FormItemLabel>
                <Counter
                  name="lifesQuantity"
                  count={formValues.lifesQuantity ?? 0}
                  min={1}
                  register={{
                    ...register('lifesQuantity', {
                      required: 'Este campo é obrigatório.',
                      valueAsNumber: true,
                    }),
                  }}
                  onCountChange={(count) => handleLifesQuantityChange(count)}
                  error={errors.lifesQuantity}
                />
              </FormItem>
            </FieldsWrapper>

            {formValues.product === 'HEALTH' && (
              <FormItem>
                <FormItemLabel>Proposta conjugada?*</FormItemLabel>
                <RadioGroup
                  name="isCombinedProposal"
                  options={[
                    { value: 'true', label: 'Sim' },
                    { value: 'false', label: 'Não' },
                  ]}
                  register={{
                    ...register('isCombinedProposal', {
                      required: 'Você precisa selecionar uma das opções.',
                    }),
                  }}
                  error={errors.isCombinedProposal}
                />
              </FormItem>
            )}

            {isSulamerica && formValues.product === 'HEALTH' && (
              <>
                <FormItem>
                  <FormItemLabel>Tipo de contratação*</FormItemLabel>

                  <Select
                    showSearch
                    name="hiringType"
                    control={control}
                    options={[
                      {
                        label: 'CBO',
                        value: 'CBO',
                      },
                      {
                        label: 'Compulsório',
                        value: 'Compulsório',
                      },
                      {
                        label: 'Flex',
                        value: 'Flex',
                        disabled: formValues.isCombinedProposal?.toString() === 'false',
                      },
                    ]}
                    placeholder="Selecione o tipo de contratação"
                    rules={{
                      required: 'Este campo é obrigatório',
                    }}
                  />
                </FormItem>
                <FormItem>
                  <FormItemLabel>Tipo de plano*</FormItemLabel>

                  <Select
                    showSearch
                    name="coverageType"
                    control={control}
                    options={[
                      {
                        label: 'Ambulatorial e Hospitalar com Obstetrícia',
                        value: 'Ambulatorial e Hospitalar com Obstetrícia',
                      },
                      {
                        label: 'Hospitalar com Obstetrícia',
                        value: 'Hospitalar com Obstetrícia',
                      },
                    ]}
                    placeholder="Selecione o tipo de plano"
                    rules={{
                      required: 'Este campo é obrigatório',
                    }}
                  />
                </FormItem>

                <FormItem>
                  <FormItemLabel>Vigência contratual?*</FormItemLabel>
                  <RadioGroup
                    name="contractualTerm"
                    options={[
                      { value: '12 meses', label: '12 meses' },
                      { value: '24 meses', label: '24 meses' },
                    ]}
                    register={{
                      ...register('contractualTerm', {
                        required: 'Você precisa selecionar uma das opções.',
                      }),
                    }}
                    error={errors.contractualTerm}
                  />
                </FormItem>
              </>
            )}

            {(isGndi || isNotreDame) && (
              <>
                <FormItem>
                  <FormItemLabel>Estado beneficiários (maioria)</FormItemLabel>
                  <Select
                    showSearch
                    name="state"
                    control={control}
                    options={states}
                    placeholder="Selecione um estado"
                  />
                </FormItem>

                <FormItem>
                  <FormItemLabel>Cidade beneficiários (maioria)</FormItemLabel>
                  <Select
                    showSearch
                    name="city"
                    control={control}
                    options={getCities.data ?? []}
                    placeholder="Selecione uma cidade"
                  />
                </FormItem>
              </>
            )}
          </>
        ) : null}
      </FormDiv>

      <Toast position="top-right" />
    </CreateQuotationDiv>
  )
}
export default CreateQuotation
