import { useEffect, useState } from 'react'

import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, Image, Modal, Select } from 'design-system/components'

import { useFillOcrPermanenceInformation } from '@/App/clients/OCR/mutation/fillDeclarationPermanence'
import { useFillOcrInformation } from '@/App/clients/OCR/mutation/fillOcrInformation'
import { useFillOcrProofResidency } from '@/App/clients/OCR/mutation/fillProofResidency'
import { DocumentDto, Entity } from '@/App/clients/healthPlansOrders/dtos'

import { EntityType } from '../..'
import { OCRDocuments } from '../OCR'
import { OCRDeclarationPermanence } from '../OCR/declarationPermanence'
import { OCRDeclarationProofResidency } from '../OCR/proofResidency'
import {
  DownloadDocumentsModalCancelButtonDiv,
  DownloadDocumentsModalSaveButtonDiv,
  FilePreviewDiv,
  ModalActionButtonsDiv,
  NameHeader,
  PreviewImageDiv,
  TableBody,
  TableHeader,
  TdActions,
  TdFileName,
  TdFileType,
  TypeHeader,
} from './style'

export interface DocumentUploadModalProps {
  visibility: boolean
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>
  orderId?: string | null
  entityData?: Entity | null
  documentUploaded?: DocumentDto
  setDocumentUploaded: React.Dispatch<React.SetStateAction<DocumentDto | undefined>>
  reloadOrderData?: () => void
  isViewOcrActivated: boolean
  insurer?: string | null
  isOcrProcessing: boolean
  setIsOcrProcessing: React.Dispatch<React.SetStateAction<boolean>>
  entityType: EntityType
}
export const DocumentOcrUploadModal: React.FC<DocumentUploadModalProps> = ({
  visibility,
  setVisibility,
  orderId,
  entityData,
  documentUploaded,
  reloadOrderData,
  isViewOcrActivated,
  insurer,
  setDocumentUploaded,
  isOcrProcessing,
  setIsOcrProcessing,
  entityType,
}) => {
  const [isInspectorOpen, setInspectorOpen] = useState<boolean>()
  const [documentType, setDocumentType] = useState<string>('')

  const fillOcrInformation = useFillOcrInformation()
  const fillOcrPermanenceInformation = useFillOcrPermanenceInformation()
  const fillOcrProofResidency = useFillOcrProofResidency()

  const selectDefaultValue = 'Selecione o tipo do documento'
  const documentTypeOptions = [
    {
      label: 'RG',
      value: 'RG',
    },
    {
      label: 'CNH',
      value: 'CNH',
    },
    {
      label: 'Carta do Plano Saúde Anterior',
      value: 'healthDeclarationPermanence',
    },
    {
      label: 'Carta do Plano Dental Anterior',
      value: 'dentalDeclarationPermanence',
    },
    {
      label: 'Comprovante de residência',
      value: 'proofResidency',
    },
  ]

  const handleTypeChange = (type: string) => {
    setDocumentType(type)
    if (
      [
        'RG',
        'CNH',
        'Documento de Identificação',
        'Documento de Identificação*',
        'Documento de identificação com Foto*',
      ].includes(documentUploaded?.type ?? '')
    ) {
      const fillOcrData = { document: documentUploaded, documentType: type }
      fillOcrInformation.mutate(fillOcrData)
    } else if (documentUploaded?.type === 'Comprovante de residência') {
      const fillOcrData = { document: documentUploaded, documentType: type }
      fillOcrProofResidency.mutate(fillOcrData)
    } else {
      const fillOcrData = { document: documentUploaded, documentType: type }
      fillOcrPermanenceInformation.mutate(fillOcrData)
    }
  }

  useEffect(() => {
    if (fillOcrInformation.isSuccess) {
      setInspectorOpen(true)
    }

    if (fillOcrPermanenceInformation.isSuccess) {
      setInspectorOpen(true)
    }

    if (fillOcrProofResidency.isSuccess) {
      setInspectorOpen(true)
    }
  }, [
    fillOcrInformation.isSuccess,
    fillOcrPermanenceInformation.isSuccess,
    fillOcrProofResidency.isSuccess,
  ])

  const handleClose = () => {
    setVisibility(false)
    setDocumentUploaded(undefined)
    setInspectorOpen(false)
  }

  const ocrFormTypes = {
    dentalDeclarationPermanence: (
      <OCRDeclarationPermanence
        documentType={documentType}
        isInspectorOpen={isInspectorOpen ?? false}
        documentUploaded={documentUploaded}
        entityData={entityData}
        OCRData={fillOcrPermanenceInformation?.data}
        OCRLoading={fillOcrPermanenceInformation?.isPending}
        orderId={orderId}
        isViewOcrActivated={isViewOcrActivated}
        insurer={insurer}
        setVisibility={setVisibility}
        setDocumentUploaded={setDocumentUploaded}
        reloadOrderData={reloadOrderData}
        setInspectorOpen={setInspectorOpen}
        isOcrProcessing={isOcrProcessing}
        setIsOcrProcessing={setIsOcrProcessing}
      />
    ),
    healthDeclarationPermanence: (
      <OCRDeclarationPermanence
        documentType={documentType}
        isInspectorOpen={isInspectorOpen ?? false}
        documentUploaded={documentUploaded}
        entityData={entityData}
        OCRData={fillOcrPermanenceInformation?.data}
        OCRLoading={fillOcrPermanenceInformation?.isPending}
        orderId={orderId}
        isViewOcrActivated={isViewOcrActivated}
        insurer={insurer}
        setVisibility={setVisibility}
        setDocumentUploaded={setDocumentUploaded}
        reloadOrderData={reloadOrderData}
        setInspectorOpen={setInspectorOpen}
        isOcrProcessing={isOcrProcessing}
        setIsOcrProcessing={setIsOcrProcessing}
      />
    ),
    proofResidency: (
      <OCRDeclarationProofResidency
        isInspectorOpen={isInspectorOpen ?? false}
        documentUploaded={documentUploaded}
        entityData={entityData}
        OCRData={fillOcrProofResidency?.data}
        OCRLoading={fillOcrProofResidency?.isPending}
        orderId={orderId}
        isViewOcrActivated={isViewOcrActivated}
        insurer={insurer}
        setVisibility={setVisibility}
        setDocumentUploaded={setDocumentUploaded}
        reloadOrderData={reloadOrderData}
        setInspectorOpen={setInspectorOpen}
        isOcrProcessing={isOcrProcessing}
        setIsOcrProcessing={setIsOcrProcessing}
      />
    ),
  }

  return (
    <Modal
      shouldHideCloseIcon
      isOpen={visibility}
      setIsOpen={setVisibility}
      footer={
        <ModalActionButtonsDiv isInspectorOpen={isInspectorOpen || isViewOcrActivated}>
          <DownloadDocumentsModalCancelButtonDiv>
            <Button palette="cancel" onClick={handleClose}>
              <CloseCircleOutlined />
              Cancelar
            </Button>
          </DownloadDocumentsModalCancelButtonDiv>

          <DownloadDocumentsModalSaveButtonDiv>
            <Button htmlType="submit" formId="OCR" loading={isOcrProcessing}>
              Salvar
            </Button>
          </DownloadDocumentsModalSaveButtonDiv>
        </ModalActionButtonsDiv>
      }
      bodyStyle={{ display: 'flex', flexDirection: 'column', height: '380px' }}
      width={1045}
      customHeight={isInspectorOpen ? '700px' : undefined}
    >
      <TableHeader>
        <NameHeader>
          <p>Arquivo</p>
        </NameHeader>
        <TypeHeader>
          <p>Tipo de documento</p>
        </TypeHeader>
      </TableHeader>
      <TableBody>
        <div>
          <tr>
            <TdFileName>
              <FilePreviewDiv>
                <PreviewImageDiv>
                  <Image
                    src={documentUploaded?.previewURL ?? ''}
                    visible={!!documentUploaded?.previewURL}
                  />
                </PreviewImageDiv>
                {documentUploaded?.fileName}
              </FilePreviewDiv>
            </TdFileName>
            <TdFileType>
              <Select
                defaultValue={selectDefaultValue}
                onChange={(value) => handleTypeChange(value as string)}
                options={documentTypeOptions}
              />
            </TdFileType>
            <TdActions />
          </tr>
        </div>
      </TableBody>

      {documentType !== undefined && (
        <>
          {ocrFormTypes[documentType] ?? (
            <OCRDocuments
              isInspectorOpen={isInspectorOpen ?? false}
              documentUploaded={documentUploaded}
              entityData={entityData}
              OCRData={fillOcrInformation?.data}
              OCRLoading={fillOcrInformation?.isPending}
              orderId={orderId}
              isViewOcrActivated={isViewOcrActivated}
              insurer={insurer}
              setVisibility={setVisibility}
              setDocumentUploaded={setDocumentUploaded}
              reloadOrderData={reloadOrderData}
              setInspectorOpen={setInspectorOpen}
              isOcrProcessing={isOcrProcessing}
              setIsOcrProcessing={setIsOcrProcessing}
              entityType={entityType}
              documentType={documentType}
            />
          )}
        </>
      )}
    </Modal>
  )
}
