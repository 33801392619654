import { QueryFunctionContext, queryOptions } from '@tanstack/react-query'

import { api } from '../../http'
import { DistributionChannel } from '../dtos'

async function getDistributionChannels({ signal }: QueryFunctionContext) {
  return api
    .get<{ data: DistributionChannel[] }>(
      `${import.meta.env.VITE_CATALOGO_API_URL}/canais-distribuicao`,
      {
        signal,
        headers: { Authorization: localStorage.getItem('authToken') },
      },
    )
    .then((res) => res.data.data)
}

export function getDistributionChannelsQueryOptions() {
  return queryOptions({
    queryKey: ['distributionChannel'] as const,
    queryFn: async (params) => getDistributionChannels(params),
  })
}
