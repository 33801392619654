import { useEffect, useState } from 'react'

import { CheckBox } from 'design-system/components'

import { Action } from '../..'
import { ColumnBody, ColumnHeader } from './style'

interface Props {
  data: Array<{ value: string; label: string }>
  handleRequest: (action: Action, filterValues: [{ key: string; value: string }]) => void
  selectedResponsibleAdmins?: string[]
}

export const ResponsibleAdminFilter = ({
  data,
  handleRequest,
  selectedResponsibleAdmins,
}: Props) => {
  const [filteredResponsible, setFilteredResponsible] = useState(data)
  const [allChecked, setAllChecked] = useState(false)

  const handleChange = (event) => {
    const action = event.target.checked ? Action.AddFilter : Action.RemoveFilter

    handleRequest(action, [{ key: 'responsibleAdminId', value: event.target.value }])
  }

  const handleAllChange = (event) => {
    const action = event.target.checked ? Action.AddFilter : Action.RemoveFilter
    const allAdmin: string[] = data.map((admin) => {
      return admin.value
    })
    const allQuery: string = allAdmin.join(',')
    handleRequest(action, [{ key: 'responsibleAdminId', value: allQuery }])
    setAllChecked(event.target.checked)
  }

  useEffect(() => {
    setFilteredResponsible(data)
  }, [])

  return (
    <>
      <ColumnHeader>
        <CheckBox
          onChange={(e) => handleAllChange(e)}
          value="all"
          label="Todos"
          checked={allChecked}
        />
      </ColumnHeader>

      {filteredResponsible?.map((option, index) => {
        return (
          <ColumnBody key={index}>
            <CheckBox
              onChange={(e) => handleChange(e)}
              label={option.label}
              value={option.value}
              name="responsibleAdminId"
              checked={selectedResponsibleAdmins?.includes(option.value)}
            />
          </ColumnBody>
        )
      })}
    </>
  )
}
