import { QueryFunctionContext, infiniteQueryOptions } from '@tanstack/react-query'

import { api } from '../../http'
import { PaginationParams } from '../../http/interface'
import { GetOrderFillingExecutionsDto } from '../dtos'

const PER_PAGE_ORDER_FILLING_EXECUTION = 50

type GetOrderFillingExecutionsRequest = QueryFunctionContext & PaginationParams

async function getOrderFillingExecutions({
  perPage,
  signal,
  page = 0,
}: GetOrderFillingExecutionsRequest) {
  const url = `${
    import.meta.env.VITE_ORDER_INFORMATION_PROCESSING_URL
  }/api/v1/order-filling-executions?perPage=${perPage}&page=${page}`

  return api
    .get<GetOrderFillingExecutionsDto>(url, {
      signal,
      headers: {
        Authorization: localStorage.getItem('authToken'),
      },
    })
    .then((res) => {
      return {
        ...res.data,
        orderFillingExecutions: res.data.orderFillingExecutions.map((orderFilling) => {
          return {
            ...orderFilling,
            key: orderFilling.id,
          }
        }),
      }
    })
}

export function orderFillingExecutionInfiniteQueryOptions(paginationParams?: PaginationParams) {
  const perPage = paginationParams?.perPage || PER_PAGE_ORDER_FILLING_EXECUTION

  return infiniteQueryOptions({
    queryKey: ['orderFillingExecutions', { perPage }] as const,
    initialPageParam: 0,
    queryFn: async (queryParams) => {
      return getOrderFillingExecutions({
        ...queryParams,
        page: queryParams.pageParam,
        perPage,
      })
    },
    getNextPageParam: (lastPage) => (lastPage.hasNext ? lastPage.page + 1 : undefined),
  })
}
