import styled from 'styled-components'

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ModalTextArea = styled.textarea`
  width: 100%;
  border-radius: 10px;
  border-color: #b7c8d5;
`
export const ButtonDiv = styled.div`
  margin-top: 20px;
  width: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const CancelButton = styled.button`
  margin-top: 20px;
  border: none;
  background: white;
  border-bottom: 1px solid;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`

export const ModalTitle = styled.p`
  color: #4b4b4b;
  margin-bottom: 0;
  font-weight: 600;
`
export const ModalSubTitle = styled.p`
  margin-top: 0;
  color: #878da1;
`
export const UploadAreaDiv = styled.div`
  display: inline;
  width: 100%;
`
