import { useMutation } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '@/App/clients/http'

export type DeleteExternalBrokerData = {
  orderId?: string | null
  externalBrokerId?: string | null
}

const deleteExternalBroker = async ({ orderId, externalBrokerId }: DeleteExternalBrokerData) => {
  await api.delete(
    `${
      import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
    }/api/v1/orders/${orderId}/external-brokers/${externalBrokerId}`,
    {
      headers: { Authorization: localStorage.getItem('authToken') },
    },
  )
}

export const useDeleteExternalBroker = (deleteExternalBrokerData?: DeleteExternalBrokerData) => {
  const orderId = deleteExternalBrokerData?.orderId
  const externalBrokerId = deleteExternalBrokerData?.externalBrokerId

  return useMutation({
    mutationKey: ['deleteExternalBroker', orderId, externalBrokerId],
    mutationFn: deleteExternalBroker,
    onSuccess: () => {
      triggerToast(true, 'Corretor externo desativado com sucesso!')
    },
    onError: () => {
      triggerToast(false, 'Ops, ocorreu um erro ao desativar corretor.')
    },
  })
}
