import React, { ReactNode, useState } from 'react'

import PurpleCheckIcon from '../../icons/purpleCheck'
import { Card, CardContent, CardsDiv, CheckedIcon, StyledAddableCard } from './style'

export interface CheckableCard {
  reference: string
  children?: ReactNode
  border?: 'solid' | 'dashed'
}

export interface CheckableCardProps {
  cards: CheckableCard[]
  defaultCheckedReference?: string
  onSelect?: (reference: string) => void
  cardHeight?: string
  cardWidth?: string
  addCardHeight?: string
  addCardWidth?: string
}

const CheckableCards: React.FC<CheckableCardProps> = ({
  cards,
  defaultCheckedReference,
  onSelect,
  cardHeight,
  cardWidth,
  addCardWidth,
  addCardHeight,
}) => {
  const [checkedReference, setCheckedReference] = useState(defaultCheckedReference)

  const handleCheck = (reference: string) => {
    setCheckedReference(reference)
    onSelect && onSelect(reference)
  }

  const DefaultCard = ({ reference, border, children }) => {
    return (
      <CardsDiv>
        <Card
          key={reference}
          checked={reference === checkedReference}
          onClick={() => handleCheck(reference)}
          height={cardHeight}
          width={cardWidth}
          border={border}
        >
          <CheckedIcon checked={reference === checkedReference}>
            <PurpleCheckIcon />
          </CheckedIcon>
          <CardContent>{children}</CardContent>
        </Card>
      </CardsDiv>
    )
  }

  const AddableCard = ({ border, children, reference }) => {
    return (
      <CardsDiv>
        <StyledAddableCard
          key={reference}
          checked={reference === checkedReference}
          onClick={() => handleCheck(reference)}
          addCardHeight={addCardHeight}
          addCardWidth={addCardWidth}
          border={border}
        >
          <CheckedIcon checked={reference === checkedReference}>
            <PurpleCheckIcon />
          </CheckedIcon>
          <CardContent>{children}</CardContent>
        </StyledAddableCard>
      </CardsDiv>
    )
  }
  return (
    <CardsDiv>
      {cards.map((card) => {
        return card.border === 'dashed' ? (
          <AddableCard border={card.border} reference={card.reference}>
            {card.children}
          </AddableCard>
        ) : (
          <DefaultCard border={card.border} reference={card.reference}>
            {card.children}
          </DefaultCard>
        )
      })}
    </CardsDiv>
  )
}

export default CheckableCards
