import { useCallback } from 'react'

import { WidgetProps } from '@rjsf/utils'
import dayjs from 'dayjs'

import { DatePicker, Error } from '../../components'
import { FormItem, FormItemLabel } from '../styles'

export function DateWidget({
  label,
  defaultValue,
  value,
  onChange,
  required,
  rawErrors,
  ...rest
}: WidgetProps) {
  const transformedDefaultValue = defaultValue ? dayjs(defaultValue as string).toDate() : null
  const transformedValue = value ? dayjs(value as string).toDate() : null

  const error = rawErrors
    ? {
        type: 'unknown',
        message: rawErrors.at(0),
      }
    : null

  const handleChange = useCallback(
    (date: dayjs.Dayjs) => {
      return onChange(date?.format('YYYY-MM-DD'))
    },
    [onChange],
  )

  return (
    <FormItem>
      <FormItemLabel>
        {label}
        {required ? '*' : ''}
      </FormItemLabel>
      <DatePicker
        value={transformedValue || transformedDefaultValue}
        onChange={handleChange}
        {...rest}
      />
      {error && <Error error={error} />}
    </FormItem>
  )
}
