import styled from 'styled-components'
import { colors } from '../../styles/theme'

export const StyledLabel = styled.p`
  font-size: 20px;
  margin: 0 0 2px 0;
  color: ${colors.primaryText};
`
export const StyledDatePickerWrapper = styled.div`
  .ant-picker {
    border-color: #b7c8d5;
    width: 100%;
    height: 40px;
    border: 1px solid rgb(187 192 213);
  }

  .ant-picker-status-error {
    border-color: #bf3232 !important;
  }
`
