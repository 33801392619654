import styled from 'styled-components'

import { colors } from '../../../../design-system/src/styles/theme'

export interface StyledPropsOrderDetails {
  hasWidth?: boolean
}

export const OrderDiv = styled.main`
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  font-family: manrope;
  margin-top: 20px;
`

export const OrderHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
`

export const OrderHeaderContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  max-height: 80px;
`

export const OrderHeaderInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const OrderTagsDiv = styled.div`
  display: flex;
  column-gap: 10px;
  width: 100%;
  justify-content: space-between;
`

export const OrderStatusSelectLoadingDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
`
export const OrderStatusSelect = styled.div<StyledPropsOrderDetails>`
  display: flex;
  align-items: center;
  height: 70px;
  column-gap: 10px;
  max-width: 500px;

  .ant-picker {
    width: 100% !important;
    height: 41px !important;
  }
`

export const HorizontalOrderTags = styled.div`
  flex-direction: row;
  display: flex;
  margin-bottom: 5px;
  margin-right: 15px;
  column-gap: 5px;
`
export const OrderTag = styled.div`
  background-color: #f5f6f9;
  border-radius: 10px;
  color: #878da1;
  align-items: center;
  display: flex;
  padding: 0 5px;
  height: 30px;
  width: fit-content;
  span {
    color: ${colors.primaryText};
    display: inline;
    font-weight: 700;
    margin-right: 5px;
  }
`

export const Divider = styled.hr`
  height: 1px;
  border-width: 0;
  background-color: #dcdfeb;
  width: 100%;
`

export const Options = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-family: 'manrope';
  justify-content: space-between;
`

export const FormStyled = styled.form`
  display: grid;
  grid-template-columns: 45rem 35rem;
  column-gap: 40px;
  justify-content: center;
  margin: 0 auto;
  padding: 10px;
  row-gap: 10px;

  @media (max-width: 1278px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #b7c8d5;
    border-radius: 0.4rem;
    height: 41px;
  }

  .ant-select-selector .ant-select-selection-placeholder {
    padding: 2px;
  }
`

export const Label = styled.p`
  font-weight: 700;
  margin: 0;
  color: #4b4b4b;
  font-size: 13px;
  margin-bottom: 5px;
`

export const FormQuoteStyled = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #b7c8d5;
    border-radius: 0.4rem;
  }
`
export const FormInsurerId = styled.form`
  width: 20%;
  display: flex;

  .eOTySg {
    height: 30px;

    .sc-eDDNvR {
      width: 90%;
    }
  }
`

export const StyledOptionLabel = styled.span`
  margin-left: 8px;
`

export const ResponsibleContent = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
`

export const ResponsibleAdminTag = styled.span`
  align-items: center;
  background: #f5f6f9;
  border-radius: 30px;
  color: #4b4b4b;
  column-gap: 10px;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 15px;
  margin: 5px;
`
export const StyledDivIcon = styled.div`
  cursor: pointer;
`

export const MainContent = styled.div`
  flex: 1;
  margin-left: 15px;
`

export const LabelTabsItem = styled.div`
  color: #4b4b4b;
  font-weight: 500;
  font-size: 14px;
`
export const ContentTabSkeleton = styled.div`
  display: flex;
  margin-top: 70px;
  flex-direction: column;
  row-gap: 30px;
`
export const NewProposalButtonContainer = styled.div`
  position: fixed;
  width: 300px;
  left: 50%;
  transform: translate(-50%, -85%);
  bottom: 0;
`

export const RequestTag = styled.div`
  display: flex;
  width: 400px;
  height: 26px;
  border-radius: 50px;
  border: 1px solid #b7c8d5;
  padding: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    color: ${colors.primaryText};
    display: inline;
    font-weight: 600;
    margin-right: 5px;
    font-size: 13px;
  }
`

export const StyledSkeletonDiv = styled.div`
  width: 218px;
`
