import React from 'react'

import { StoreApi, createStore, useStore } from 'zustand'

import type { GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import { calculateAge } from '@/App/utils/calculateProcessingTime'

type EntitiesState = {
  orderData: GetOrderInformationBody
  isLegalAge: boolean
}

type EntitiesAction = {
  setIsLegalAge: (birthDate?: string | null) => void
}

type EntitiesContextData = EntitiesState & EntitiesAction

const EntitiesContext = React.createContext<StoreApi<EntitiesContextData> | null>(null)

function validateLegalAge(orderData = {} as GetOrderInformationBody): boolean {
  if (orderData.type === 'COMPANY') {
    return (
      orderData?.holders?.every((holder) => {
        if (!holder?.birthDate) {
          return true
        }
        const holdersCurrentAge = calculateAge(holder.birthDate) >= 18
        return holdersCurrentAge
      }) ?? true
    )
  }

  if (!orderData?.holders?.at(0)?.birthDate) {
    return true
  }

  const holderAge = calculateAge(orderData?.holders?.at(0)?.birthDate)

  return holderAge >= 18
}

export const EntitiesProvider = ({ children, initialData = {} as GetOrderInformationBody }) => {
  const [store] = React.useState(() =>
    createStore<EntitiesContextData>((set) => ({
      orderData: initialData,
      isLegalAge: validateLegalAge(initialData),
      setIsLegalAge(birthDate) {
        set({
          isLegalAge: birthDate ? calculateAge(birthDate) >= 18 : true,
        })
      },
    })),
  )

  return <EntitiesContext.Provider value={store}>{children}</EntitiesContext.Provider>
}

export function useEntitiesStore<T>(selector: (state: EntitiesContextData) => T): T {
  const store = React.useContext(EntitiesContext)

  if (!store) {
    throw new Error('EntitiesContext not provided in the provider tree.')
  }

  return useStore(store, selector)
}

export const useEntities = () => useEntitiesStore((state: EntitiesContextData) => state)
