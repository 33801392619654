import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button } from 'design-system/components'

import { CommissionGridDetails } from './commissionGridDetails'
import { ButtonContainer, Container } from './styles'

interface UpdateCommissionGridStatus {
  id: string
}

const UpdateCommissionGrid = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [gridId, setGridId] = useState('')

  useEffect(() => {
    if (!location.state) return

    const { id } = location.state as UpdateCommissionGridStatus

    if (id) setGridId(id)
  }, [])

  return (
    <Container>
      {gridId && <CommissionGridDetails gridId={gridId} />}

      <ButtonContainer>
        <Button
          onClick={() => {
            setTimeout(() => {
              navigate('/grades-de-comissao')
            }, 100)
          }}
        >
          <ArrowLeftOutlined /> Voltar
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default UpdateCommissionGrid
