import { Radio } from 'design-system/components'

import { Action } from '..'
import { ManagerFilterContent } from './style'

interface Props {
  data: Array<{ id: string; name: string }>
  handleRequest: (action: Action, filterValues: [{ key: string; value: string }]) => void
  statusSelected?: string[]
}

export const ManagerFilter = ({ data, handleRequest, statusSelected }: Props) => {
  const handleOnClickFilter = (value: string) => {
    handleRequest(Action.ReplaceFilter, [{ key: 'managerId', value: value }])
  }

  return (
    <ManagerFilterContent>
      <Radio
        onChange={handleOnClickFilter}
        options={data.map((manager) => ({ value: manager.id, label: manager.name }))}
        name="managerId"
        defaultValue={statusSelected?.toString()}
      />
    </ManagerFilterContent>
  )
}
