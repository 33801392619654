import styled from 'styled-components'
import { Button as AntdButton } from 'antd'
import { colors } from '../../styles/theme'

export interface StyledCarouselProps {
  background?: boolean
}

export const StyledDownloadButton = styled(AntdButton)<StyledCarouselProps>`
  &&& {
    background-color: ${(props) => (props.background ? colors.darkPurple : 'none')};
    color: ${(props) => (props.background ? 'white' : colors.primaryText)};
    border-radius: 50px;
    cursor: pointer;
    height: 30%;
    box-shadow: none;
    font-weight: 600;
    font-size: 18px;
    width: 100%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: ${(props) => (props.background ? 'none' : 'underline')};
    &:hover {
      transition: background-color 0.2s ease-in-out;
      border: 1px solid ${colors.icon};
      background: ${(props) => (props.background ? colors.icon : colors.mouseHover)};
    }
    &:disabled {
      cursor: not-allowed;
      background-color: #b8b8b8 !important;
    }
  }
`
