import React, { ReactNode, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { MenuOutlined } from '@ant-design/icons'

import Header from '../Header/Header'
import Menu from '../Menu/Menu'
import { Profile } from '../components'
import {
  FrameContent,
  StyledArrow,
  StyledButton,
  StyledDivLogo,
  StyledFrame,
  StyledHeader,
  StyledHeaderMobile,
  StyledImg,
  StyledLogo,
  StyledTitle,
} from './style'

interface MenuItems {
  id: number
  url: string
  label: string
  icon?: ReactNode
}
interface HeaderProfile {
  id: number
  label: string
  onClick?: () => void
}
export interface FrameProps {
  children: ReactNode
  menuItems?: MenuItems[]
  headerProfile: HeaderProfile[]
  profileType: string
  profileName?: string
  profileLogo?: string
  menuFooterText?: boolean
  color?: string
  logoImageSrc?: string
  menuActiveIndex: number
  signOut?: () => void
  isExternal?: boolean
  brokerName?: string
  externalHeaderOnClick?: () => void
  fallbackLogo?: string
  fallbackAvatar?: string
}
const Frame: React.FC<FrameProps> = ({
  color,
  menuFooterText,
  menuItems,
  children,
  headerProfile,
  profileType,
  profileName,
  profileLogo,
  logoImageSrc,
  menuActiveIndex,
  signOut,
  isExternal = false,
  brokerName,
  externalHeaderOnClick,
  fallbackLogo,
  fallbackAvatar,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const [mobileVisibility, setMobileVisibility] = useState(false)
  const MobileMenu = () => {
    return (
      <div>
        <Menu
          items={menuItems ?? []}
          activeIndex={menuActiveIndex}
          signOut={signOut}
          setMobileVisibility={setMobileVisibility}
          mobileVisibility={mobileVisibility}
        ></Menu>
      </div>
    )
  }
  const DesktopMenu = () => {
    return (
      <Menu
        logoImageSrc={logoImageSrc}
        color={color}
        footerImage={menuFooterText}
        items={menuItems ?? []}
        activeIndex={menuActiveIndex}
        fallbackLogo={fallbackLogo}
      ></Menu>
    )
  }
  const HeaderDesktop = () => {
    return (
      <>
        {isExternal ? (
          <StyledHeader isExternal={isExternal}>
            <StyledButton onClick={() => externalHeaderOnClick && externalHeaderOnClick()}>
              <StyledArrow />
              Voltar
            </StyledButton>
            <StyledTitle>{brokerName}</StyledTitle>
          </StyledHeader>
        ) : (
          <StyledHeader>
            <Profile
              items={headerProfile}
              type={profileType}
              name={profileName}
              logoImage={profileLogo}
              color={color}
              fallbackAvatar={fallbackAvatar}
            />
          </StyledHeader>
        )}
      </>
    )
  }
  const HeaderMobile = () => {
    return (
      <>
        {isExternal ? (
          <StyledHeaderMobile>
            <StyledButton
              onClick={() => externalHeaderOnClick && externalHeaderOnClick()}
              isMobile={isMobile}
            >
              <StyledArrow isExternal={isExternal} />
            </StyledButton>
            {logoImageSrc ? (
              <StyledImg src={logoImageSrc} alt={'logo do corretor'} height={60} width={60} />
            ) : (
              <StyledDivLogo color={color}>
                <StyledLogo color={color}>{fallbackLogo}</StyledLogo>
                <span>Seguros Saúde</span>
              </StyledDivLogo>
            )}
          </StyledHeaderMobile>
        ) : (
          <StyledHeaderMobile>
            <MenuOutlined onClick={() => setMobileVisibility(!mobileVisibility)} />
            <Profile
              type={profileType}
              name={profileName}
              logoImage={profileLogo}
              fallbackAvatar={fallbackAvatar}
              color={color}
            />
          </StyledHeaderMobile>
        )}
      </>
    )
  }
  return (
    <StyledFrame>
      {isMobile ? <MobileMenu /> : <DesktopMenu />}
      <FrameContent id="frameContent">
        <Header headerHeight="80px">{isMobile ? <HeaderMobile /> : <HeaderDesktop />}</Header>
        {children}
      </FrameContent>
    </StyledFrame>
  )
}
export default Frame
