import styled from 'styled-components'

export const AccordionBodyDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Label = styled.label`
  font-size: 16px;
`
export const InputDiv = styled.div``

export const AccordionHeader = styled.div`
  justify-content: flex-start;
  & svg {
    margin-right: 10px;
  }
`
export const StyledButton = styled.button`
  color: white;
  margin-top: 20px;
  padding: 10px;
  width: 20vw;
  border: none;
  background: #53154e;
  border-radius: 30px;
`
