import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import Button from 'design-system/Button/Button'
import Tag from 'design-system/Tag/Tag'
import CustomInput from 'design-system/TextInput/TextInput'
import { Toast } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { AuthenticationServerRequests } from '@/App/clients/authenticationServer'
import { LoginDto } from '@/App/clients/authenticationServer/dtos'
import { HealthPlansOrdersRequests } from '@/App/clients/healthPlansOrders'
import { UserData } from '@/App/clients/healthPlansOrders/dtos'
import useFetch from '@/App/clients/http'
import { AuthContext } from '@/App/contexts/authentication/AuthContext'
import { UserContext } from '@/App/contexts/userData/userContext'

import {
  Column,
  Content,
  FormStyled,
  LogoSubtitle,
  Main,
  StyledIlustration,
  StyledLogo,
  StyledTag,
} from './style'

interface UserLogin {
  email: string
  password: string
}

export const LoginPage = () => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()

  const userContext = useContext(UserContext)

  const {
    setRequestConfig: setLoginRequestConfig,
    data: loginData,
    isLoading: isLoadingLogin,
    statusCode: loginStatusCode,
  } = useFetch<LoginDto>()

  const {
    setRequestConfig: setGetAdminByTokenRequestConfig,
    data: getAdminByTokenData,
    isLoading: isLoadingGetAdminByToken,
    statusCode: getAdminByTokenStatusCode,
  } = useFetch<UserData>()

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<UserLogin>({
    mode: 'onBlur',
  })

  useEffect(() => {
    if (localStorage.getItem('authToken')) navigate('/propostas')
  }, [])

  useEffect(() => {
    if (loginStatusCode == 422) {
      triggerToast(false, 'Falha no login, senha ou email inválidos.')
    }

    if (loginData != null) {
      auth?.signIn(loginData?.token)
      const getAdminByTokenRequestConfig = HealthPlansOrdersRequests.getAdminByToken()
      setGetAdminByTokenRequestConfig(getAdminByTokenRequestConfig)
    }
  }, [loginData, loginStatusCode])

  useEffect(() => {
    if (getAdminByTokenStatusCode === 403) {
      localStorage.removeItem('authToken')
      triggerToast(false, 'Você não possui permissão para acessar a plataforma.')
    }

    if (getAdminByTokenData != null) {
      userContext?.setUserData(getAdminByTokenData)
      navigate(
        localStorage.getItem('desiredUrlPath')
          ? (localStorage.getItem('desiredUrlPath') as string)
          : '/propostas',
      )
    }
  }, [getAdminByTokenStatusCode, getAdminByTokenData])

  const handleLogin = async (data: UserLogin) => {
    try {
      const signInBody = AuthenticationServerRequests.signIn(data.email, data.password)
      setLoginRequestConfig(signInBody)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return (
    <Main>
      <Column>
        <Content>
          <StyledIlustration src="assets/loginIllustration.png" alt="ilustração de login" />
        </Content>
      </Column>
      <Column>
        <Content>
          <StyledTag>
            <Tag textColor="#33002e" borderColor="#f1c7c1">
              Equipe Administrativa
            </Tag>
          </StyledTag>

          <StyledLogo src="assets/logoIllustration.png" alt="logo plataforma bliss" />

          <LogoSubtitle>Seu centralizador de informações de venda</LogoSubtitle>

          <FormStyled onSubmit={handleSubmit(handleLogin)}>
            <CustomInput
              name="email"
              label="Email:"
              vertical={true}
              register={{
                ...register('email', {
                  required: 'Campo Obrigatório',
                  pattern: {
                    value: /^[a-za-z0-9._%+-]+@[a-za-z0-9.-]+\.[a-za-z]{2,}$/,
                    message: 'E-mail inválido',
                  },
                }),
              }}
              error={errors.email}
            />

            <CustomInput
              name="password"
              label="Senha:"
              vertical={true}
              type="password"
              register={{
                ...register('password', {
                  required: 'Campo Obrigatório',
                  minLength: {
                    value: 6,
                    message: 'Mínimo 6 caracteres',
                  },
                }),
              }}
              error={errors.password}
            />
            <Button
              loading={isLoadingLogin || isLoadingGetAdminByToken}
              htmlType="submit"
              style={{ marginTop: '1rem' }}
            >
              Acessar a plataforma
            </Button>

            <Toast hideProgressBar={true} position="top-right" />
            {/* <StyledReset to={'adicione a rota aqui'}>Esqueci minha senha</StyledReset>
            <StyledP>
              É sua primeira vez por aqui?{' '}
              <StyledLink to={'adicione a rota aqui'}>Crie sua conta</StyledLink>
            </StyledP> */}
          </FormStyled>
        </Content>
      </Column>
    </Main>
  )
}

export default LoginPage
