import { Input } from 'antd'
import styled from 'styled-components'

export const StyledSearch = styled(Input.Search)`
  &&& {
    .ant-input-affix-wrapper {
      border-radius: 60px 0 0 60px;
    }

    .ant-input-search-button {
      border-radius: 0 60px 60px 0 !important;
    }

    .ant-input-group .ant-input-group-addon {
      background-color: #a4a4a405 !important;
    }
  }
`
