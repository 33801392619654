import { Radio } from 'design-system/components'

import { Action } from '..'
import { ColumnBody } from './style'

interface Props {
  handleRequest?: (action: Action, filterValues: [{ key: string; value: string }]) => void
  statusSelected?: string[]
}

export const RegisteredInternalSystemFilter = ({ handleRequest, statusSelected }: Props) => {
  const handleOnClickFilter = (value: string) => {
    if (handleRequest) {
      handleRequest(Action.AddFilter, [{ key: 'isRegisteredInInternalSystem', value: value }])
    }
  }

  return (
    <>
      <ColumnBody>
        <Radio
          onChange={handleOnClickFilter}
          options={[{ value: 'true', label: 'Sim' }]}
          name="isRegisteredInInternalSystem"
          defaultValue={statusSelected?.toString()}
        />
        <Radio
          onChange={handleOnClickFilter}
          options={[{ value: 'false', label: 'Não' }]}
          name="isRegisteredInInternalSystem"
          defaultValue={statusSelected?.toString()}
        />
      </ColumnBody>
    </>
  )
}
