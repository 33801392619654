import { useMutation } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '@/App/clients/http'

import { DocumentDto } from '../../healthPlansOrders/dtos'

type FillOcrData = {
  document?: DocumentDto
  documentType: string
}

export type OcrDocumentInformationResponse = {
  neighborhood: string
  city: string
  state: string
  number: string
  street: string
  zipcode: string
  complement: string
}

async function fillOcrProofResidency({ document }: FillOcrData) {
  const response = await api.post<OcrDocumentInformationResponse>(
    `${import.meta.env.VITE_OCR_RESIDENCY_URL}/info`,
    {
      name: document?.fileName,
      url: document?.previewURL,
    },
    {
      headers: {
        'x-api-key': import.meta.env.VITE_OCR_PERMANENCE_APIKEY,
      },
    },
  )

  return response.data
}

export function useFillOcrProofResidency() {
  return useMutation({
    mutationKey: ['OCR', 'proofResidency'],
    mutationFn: fillOcrProofResidency,
    onSuccess: () => {
      triggerToast(true, 'informações preenchidas com sucesso!')
    },
    onError: () => {
      triggerToast(false, 'Ops, ocorreu um erro ao preencher as informações.')
    },
  })
}
