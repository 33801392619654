import { WarningFilled } from '@ant-design/icons'
import { colors } from '@design-system/styles/theme'

import { GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'

import { ErrorStyled } from '../style'

interface SulamericaFormQuotationProps {
  orderData: GetOrderInformationBody
  validateQuotationDispatch: boolean | undefined
  validateOrderDispatch: boolean | undefined
}

export const SulamericaViewForm = ({
  orderData,
  validateQuotationDispatch,
  validateOrderDispatch,
}: SulamericaFormQuotationProps) => {
  return (
    <>
      <li>Coparticipação*</li>
      <p>
        {orderData?.quotation?.isCoparticipation === true ? (
          'Completa'
        ) : orderData?.quotation?.isCoparticipation == null && validateQuotationDispatch ? (
          <ErrorStyled>
            <WarningFilled style={{ color: colors.red }} />
            Dado obrigatório
          </ErrorStyled>
        ) : (
          'Parcial'
        )}
      </p>
      <li>Número Cotação Operadora*</li>
      <p>
        {orderData?.quotation?.externalId ? (
          orderData?.quotation?.externalId
        ) : validateOrderDispatch && orderData?.status === 'FILLED' ? (
          <ErrorStyled>
            <WarningFilled style={{ color: colors.red }} />
            Dado obrigatório
          </ErrorStyled>
        ) : (
          '-'
        )}
      </p>
      <li>Tipo de contratação*</li>

      <p>
        {orderData?.quotation?.hiringType ? (
          orderData?.quotation?.hiringType
        ) : validateQuotationDispatch ? (
          <ErrorStyled>
            <WarningFilled style={{ color: colors.red }} />
            Dado obrigatório
          </ErrorStyled>
        ) : (
          '-'
        )}
      </p>
      <li>Tipo de plano*</li>
      <p>
        {orderData?.quotation?.coverageType ? (
          orderData?.quotation?.coverageType
        ) : validateQuotationDispatch ? (
          <ErrorStyled>
            <WarningFilled style={{ color: colors.red }} />
            Dado obrigatório
          </ErrorStyled>
        ) : (
          '-'
        )}
      </p>
      <li>Vigência contratual*</li>
      <p>
        {orderData?.quotation?.contractualTerm ? (
          orderData?.quotation?.contractualTerm
        ) : validateQuotationDispatch ? (
          <ErrorStyled>
            <WarningFilled style={{ color: colors.red }} />
            Dado obrigatório
          </ErrorStyled>
        ) : (
          '-'
        )}
      </p>
    </>
  )
}
