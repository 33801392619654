import styled from 'styled-components'
import { colors } from '../../styles/theme'

export const ContainerError = styled.div<{ vertical: boolean | undefined }>`
  align-items: center;
  color: ${colors.red};
  column-gap: 5px;
  display: flex;
  font-family: 'Manrope', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
`
