import styled from 'styled-components'

import { colors } from '../../../../../design-system/src/styles/theme'

export interface LeadDivProps {
  isVisible?: boolean
}

export interface SelectDivProps {
  isVisible?: boolean
}

export interface CreateLeadButtonProps {
  selected?: boolean
}

export interface CreateLeadInputProps {
  isVisible?: boolean
}

export const LeadAssignmentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LeadDiv = styled.div<LeadDivProps>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
`

export const LeadAssignmentTitleDiv = styled.div``

export const LeadAssignmentTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #4b4b4b;
`

export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const SelectDiv = styled.div<SelectDivProps>`
  display: ${(props) => (props.isVisible == null || props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  width: 400px;
  margin-bottom: 20px;
`

export const SkeletonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const SelectLabel = styled.p`
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: ${colors.primaryText};
  margin: 2px;
  text-align: start;
`

export const CreateLeadButton = styled.div<CreateLeadButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: ${(props) => (props.selected ? '#514984' : '#fff')};
  border: ${(props) => (props.selected ? '2px solid' : '2px dashed #B7C8D5')};
  border-radius: 10px;
  cursor: pointer;
  color: white;

  p {
    color: ${(props) => (props.selected ? '#FFFFFF' : '#4B4B4B')};
  }
`

export const LeadInputDiv = styled.div<CreateLeadInputProps>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
`
