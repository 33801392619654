import { colors } from '@design-system/styles/theme'
import styled from 'styled-components'

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 0 35px;
  font-family: 'Manrope';
`

export const Icon = styled.div`
  & svg {
    font-size: 40px;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
`

export const ModalTitle = styled.h2`
  all: unset;
  color: #4b4b4b;
  font-size: 17px;
  line-height: 35px;
  font-weight: 800;
  text-align: center;
`

export const ModalSubTitle = styled.p`
  all: unset;
  color: #878da1;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  line-height: 24px;
`

export const ModalForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalFormContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  column-gap: 48px;
  row-gap: 24px;
`

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const FormItemLabel = styled.p`
  font-size: 13px;
  font-weight: 600;
  color: ${colors.primaryText};
  margin: 0;
  margin-left: 5px;

  span {
    color: #878da1;
    font-weight: 500;
  }
`

export const ActionContainer = styled.div`
  width: 100%;
  max-width: 276px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  grid-column: span 2;
`

export const CancelButton = styled.button`
  margin-top: 20px;
  border: none;
  background: white;
  border-bottom: 1px solid;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`
