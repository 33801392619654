import styled from 'styled-components'

export const OrderDiv = styled.div`
  display: flex;
  flex-direction: column;
`

export const OrderTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #4b4b4b;
`

export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
