import { useMutation, useQueryClient } from '@tanstack/react-query'

import { api } from '../../http'
import { CreateCommissionGridPayItemInBatchPayload } from '../dtos'

async function createCommissionGridPayItemInBatch(data: CreateCommissionGridPayItemInBatchPayload) {
  const url = `${import.meta.env.VITE_FINOPS_SERVICES_URL}/itens-grade-comissao-pagar`

  return api.post(url, data, {
    headers: {
      Authorization: localStorage.getItem('authToken'),
    },
  })
}

export function useCreateCommissionGridPayItemInBatchMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['commissionGridPayItem', 'create'] as const,
    mutationFn: async (data: CreateCommissionGridPayItemInBatchPayload) =>
      createCommissionGridPayItemInBatch(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['commissionGridPayItems'],
      })
    },
  })
}
