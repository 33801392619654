import { useEffect, useState } from 'react'

import { Loader } from '@googlemaps/js-api-loader'

export interface GooglePlacesPredictions {
  description: string
}
interface PlacesService {
  getPlacePredictions: (
    request: { input: string },
    cb: (results: Array<GooglePlacesPredictions>, status: string) => void,
  ) => void
}

export const useGooglePlaces = (apiKey: string) => {
  const [placesService, setPlacesService] = useState<PlacesService | null>(null)
  useEffect(() => {
    const loader = new Loader({
      apiKey,
      version: 'weekly',
      libraries: ['places'],
      language: 'pt-BR',
    })

    loader.importLibrary('places').then((google) => {
      const service = new google.AutocompleteService()
      setPlacesService(service)
    })
  }, [])
  return placesService
}
