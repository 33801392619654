import styled from 'styled-components'

export const NumericInput = styled.input`
  -webkit-appearance: none;
  appearance: none;

  width: 48px;
  padding: 5px;
  border: none;
  border-radius: 5px;
  background: transparent;
  color: rgb(72, 89, 100);
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
`
