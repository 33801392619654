import { useEffect, useState } from 'react'

import { triggerToast } from '@design-system/utils/triggers'
import { UseQueryResult } from '@tanstack/react-query'
import { OnFileUploadInput } from 'design-system/Upload/Upload'
import { DocumentTable, Skeleton, UploadArea } from 'design-system/components'
import { v4 as uuidV4 } from 'uuid'

import { HealthPlansOrdersRequests } from '@/App/clients/healthPlansOrders'
import { DocumentDto, GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import useFetch, { useFetchParallel } from '@/App/clients/http'
import type { UseFetchConfig } from '@/App/clients/http/interface'

import { FilesDiv, FilesHeader, HorizontalLine, UploadFilesLoadingDiv } from './style'

export interface FilesProps {
  orderInformationQuery: UseQueryResult<GetOrderInformationBody, Error>
}

export const Files: React.FC<FilesProps> = ({ orderInformationQuery }) => {
  const [isDocumentInspectorVisible, setIsDocumentInspectorVisible] = useState(false)
  const [isLoadingUploadFileDiv, setIsLoadingUploadFileDiv] = useState(false)
  const [isLoadingDeleteFileDiv, setIsLoadingDeleteFileDiv] = useState(false)
  const [uploadedDocumentFiles, setUploadedDocumentFiles] = useState<OnFileUploadInput[]>([])
  const [downloadedFileName, setDownloadedFileName] = useState<string>()
  const [selectedDocument, setSelectedDocument] = useState('')

  const orderData = orderInformationQuery?.data

  const {
    setRequestConfig: setUploadFilesRequestConfigs,
    statusCode: uploadFilesStatusCodes,
    data: uploadFilesData,
    isLoading: isLoadingUploadFiles,
  } = useFetchParallel<{ id: string; uploadURL?: string }>()

  const {
    setRequestConfig: setUploadFilesByUploadURLRequestConfig,
    statusCode: uploadFilesByUploadURLStatusCodes,
  } = useFetchParallel()

  const {
    setRequestConfig: setDeleteDocumentRequestConfig,
    statusCode: deleteDocumentStatusCode,
    isLoading: isLoadingDeleteDocument,
  } = useFetch()

  const {
    setRequestConfig: setDownloadFileByPreviewURLRequestConfig,
    data: downloadFileByPreviewURLData,
    isLoading: isLoadindDownloadFileByPreviewURL,
  } = useFetch<ArrayBuffer>()

  useEffect(() => {
    if (isLoadingUploadFiles) setIsLoadingUploadFileDiv(true)
  }, [isLoadingUploadFiles])

  useEffect(() => {
    if (!orderInformationQuery?.isRefetching) {
      setIsLoadingUploadFileDiv(false)
      setIsLoadingDeleteFileDiv(false)
    }
  }, [orderInformationQuery?.isRefetching])

  useEffect(() => {
    if (isLoadingDeleteDocument) setIsLoadingDeleteFileDiv(true)
  }, [isLoadingDeleteDocument])

  useEffect(() => {
    if (deleteDocumentStatusCode === 200) {
      triggerToast(true, 'Documento apagado com sucesso!')
      orderInformationQuery.refetch()
    } else if (deleteDocumentStatusCode && deleteDocumentStatusCode > 400)
      triggerToast(false, 'Ops, ocorreu um erro ao apagar o documento')
  }, [deleteDocumentStatusCode])

  useEffect(() => {
    if (!uploadFilesStatusCodes) return
    const [uploadFilesStatusCode] = uploadFilesStatusCodes

    if (uploadFilesStatusCode === 200) {
      const requestConfigs = uploadFilesData
        .map((data, index) => {
          const contentType = uploadedDocumentFiles[index].fileContentType
          if (data.uploadURL && contentType) {
            return HealthPlansOrdersRequests.uploadOrderDocumentByUploadURL(
              data.uploadURL,
              uploadedDocumentFiles[index].fileContent,
              contentType,
            )
          }
        })
        .filter((data) => data != null) as Array<UseFetchConfig>

      setUploadFilesByUploadURLRequestConfig(requestConfigs)
    }
  }, [uploadFilesStatusCodes])

  useEffect(() => {
    if (!uploadFilesByUploadURLStatusCodes) return
    const [uploadFilesStatusCode] = uploadFilesByUploadURLStatusCodes

    if (uploadFilesStatusCode === 200) {
      triggerToast(true, 'Arquivos enviados  com sucesso!')
      orderInformationQuery.refetch()
    } else if (uploadFilesStatusCode > 400)
      triggerToast(false, 'Ops, ocorreu um erro ao enviar os arquivos')
  }, [uploadFilesByUploadURLStatusCodes])

  useEffect(() => {
    if (downloadFileByPreviewURLData && downloadedFileName) {
      const blobs = new Blob([downloadFileByPreviewURLData])
      const url = window.URL.createObjectURL(blobs)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', downloadedFileName)

      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }
  }, [downloadFileByPreviewURLData])

  const onFileUpload = async (files: OnFileUploadInput[]) => {
    const uploadedFiles: Array<OnFileUploadInput> = []

    const uploadFilesRequestConfigs = files
      .map((file) => {
        const formData = new FormData()
        formData.append('fileName', file.fileName)

        uploadedFiles.push(file)
        if (!orderData?.id) {
          return null
        }
        return HealthPlansOrdersRequests.uploadOrderDocument(formData, orderData.id)
      })
      .filter((data) => data != null) as Array<UseFetchConfig>

    setUploadedDocumentFiles(uploadedFiles)
    setUploadFilesRequestConfigs(uploadFilesRequestConfigs)
  }

  const deleteDocument = (documentId: string) => {
    setSelectedDocument(documentId)
    if (orderData?.id) {
      const deleteDocumentRequestConfig = HealthPlansOrdersRequests.deleteOrderDocument(
        orderData.id,
        documentId,
      )
      setDeleteDocumentRequestConfig(deleteDocumentRequestConfig)
    }
  }

  const downloadDocument = (document: DocumentDto) => {
    if (document.previewURL) {
      setDownloadedFileName(document.fileName)
      setSelectedDocument(document.id)

      const downloadDocumentRequestConfig =
        HealthPlansOrdersRequests.downloadOrderDocumentByPreviewURL(document.previewURL)

      setDownloadFileByPreviewURLRequestConfig(downloadDocumentRequestConfig)
    }
  }

  return (
    <FilesDiv>
      {orderData == null ? (
        <Skeleton
          type="default"
          shape="square"
          active={true}
          loading={true}
          title={{ width: '400px' }}
        />
      ) : (
        <>
          <FilesHeader>
            <span> Anexos proposta </span>
          </FilesHeader>

          <HorizontalLine />

          {isLoadingUploadFileDiv ? (
            <UploadFilesLoadingDiv>
              <Skeleton
                type="default"
                shape="square"
                active={true}
                loading={true}
                title={{ width: '400px' }}
              />
            </UploadFilesLoadingDiv>
          ) : (
            <>
              <UploadArea
                onFileUpload={(files) =>
                  onFileUpload(files.map((file) => ({ ...file, uid: uuidV4() })))
                }
              />
              {orderData.documents && orderData.documents.length > 0 && (
                <DocumentTable
                  loading={isLoadingUploadFileDiv}
                  data={
                    orderData.documents?.map((document) => {
                      return {
                        id: document.id,
                        fileName: document.fileName,
                        previewURL: document.previewURL,
                        type: document.type,
                        status: document.status,
                        isLoadingDownloadDocument:
                          document.id == selectedDocument && isLoadindDownloadFileByPreviewURL,
                        isLoadingDeleteDocument:
                          document.id == selectedDocument && isLoadingDeleteFileDiv,
                      }
                    }) ?? []
                  }
                  isInspectorOpen={isDocumentInspectorVisible}
                  setInspectorOpen={setIsDocumentInspectorVisible}
                  onDownloadImageFromInspectorClick={(doc) => downloadDocument(doc as DocumentDto)}
                  isLoadingDownloadImageFromInspector={isLoadindDownloadFileByPreviewURL}
                  onDeleteClick={deleteDocument}
                  onDownloadClick={(doc) => downloadDocument(doc as DocumentDto)}
                />
              )}
            </>
          )}
        </>
      )}
    </FilesDiv>
  )
}
