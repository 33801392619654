import { QueryFunctionContext, queryOptions, useQuery } from '@tanstack/react-query'

import { GetBrokersByIdDto } from '../../healthPlansOrder/dto'
import { api } from '../../http'

type GetBrokerByIdBaseRequest = {
  brokerId?: string
}

type GetBrokerByIdRequest = QueryFunctionContext & GetBrokerByIdBaseRequest

async function getBrokerById({ signal, brokerId }: GetBrokerByIdRequest) {
  const response = await api.get<GetBrokersByIdDto>(
    `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/brokers/${brokerId}`,
    {
      signal,
      headers: { Authorization: localStorage.getItem('authToken') },
    },
  )
  return response.data
}

export function getBrokerByIdQueryOptions(brokerId?: string) {
  return queryOptions({
    queryKey: ['broker', brokerId] as const,
    queryFn: async (params) => getBrokerById({ ...params, brokerId }),
    refetchOnWindowFocus: false,
    enabled: !!brokerId,
  })
}

export const useGetBrokerByIdQuery = (brokerId?: string) => {
  return useQuery(getBrokerByIdQueryOptions(brokerId))
}
