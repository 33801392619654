const InProgressIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <circle cx="10" cy="10" r="10" fill="#D967B9"></circle>
      <path
        fill="#fff"
        d="M6 11.334V13h1.666l4.915-4.915-1.666-1.666L6 11.334zm7.87-4.538a.443.443 0 000-.626l-1.04-1.04a.443.443 0 00-.626 0l-.814.813 1.667 1.667.813-.814z"
      ></path>
      <path stroke="#fff" d="M6.777 12.5L14.555 12.5"></path>
    </svg>
  )
}

export default InProgressIcon
