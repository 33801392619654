import { useEffect, useState } from 'react'

import { CloseOutlined, FilterOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import StatusTag from 'design-system/StatusTag/StatusTag'
import { Filter, Modal, SearchInput, Toast } from 'design-system/components'
import { formatPhone } from 'design-system/mask'
import { triggerToast } from 'design-system/triggers'

import { healthPlansOrderRequest } from '@/App/clients/healthPlansOrder'
import { GetBrokersByFiltersDto, GetManagersByFiltersDto } from '@/App/clients/healthPlansOrder/dto'
import { useFiltersBrokersQuery } from '@/App/clients/healthPlansOrders/queries/filtersBrokers'
import useFetch from '@/App/clients/http'

import { SiswebFilter } from '../../components/SiswebCode/SiswebFilter'
import BrokerModalContent from './ModalContent'
import { TableComponent } from './Table'
import { ManagerFilter } from './filters/ManagerFilter'
import { RegisteredInternalSystemFilter } from './filters/registeredInternalFilter'
import { StatusFilter } from './filters/statusFilter'
import { Container, FilterButton, FilterDiv, SelectedFilters } from './style'

export enum Action {
  AddPage = 'ADD_PAGE',
  AddFilter = 'ADD_FILTER',
  RemoveFilter = 'REMOVE_FILTER',
  ReplaceFilter = 'REPLACE_FILTER',
}

interface IFilters {
  registrationStatus?: []
  isRegisteredInInternalSystem?: []
  managerId?: []
  internalSystemId?: string[]
}

interface BrokerRows {
  key: string
  brokerName: string
  brokerPhone: string
  brokerMail: string
  registration: string
  sisWebCode: string
  status: string
}

const BrokerManager = () => {
  const columns = [
    { title: 'Corretores', dataIndex: 'brokerName', key: 'brokerName' },
    { title: 'Telefone', dataIndex: 'brokerPhone', key: 'brokerPhone' },
    { title: 'E-mail', dataIndex: 'brokerMail', key: 'brokerMail' },
    { title: 'Cadastro', dataIndex: 'registration', key: 'registration' },
    { title: 'Cód. Sisweb', dataIndex: 'sisWebCode', key: 'sisWebCode' },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status: string) => <StatusTag status={status} type="broker" />,
    },
  ]

  const [isOpen, setIsOpen] = useState(false)
  const [brokers, setBrokers] = useState<Array<BrokerRows>>([])
  const [managers, setManagers] = useState<GetManagersByFiltersDto['managers']>([])
  const [brokerId, setBrokerId] = useState('')
  const [openFilter, setOpenFilter] = useState(false)

  const filtersBrokersQuery = useFiltersBrokersQuery()

  const [filters, setFilters] = useState<IFilters>({
    registrationStatus: [],
    isRegisteredInInternalSystem: [],
    managerId: [],
    internalSystemId: [],
  })
  const [page, setPage] = useState(0)
  const [hasNext, setHasNext] = useState(false)

  const { setRequestConfig, data, error, isLoading } = useFetch<GetBrokersByFiltersDto>()
  const {
    setRequestConfig: managersRequestConfig,
    data: managersData,
    error: managersError,
  } = useFetch<GetManagersByFiltersDto>()

  useEffect(() => {
    const getBrokersByFiltersRequestConfig = healthPlansOrderRequest.getBrokersByFilters({}, page)
    const getManagersRequestConfig = healthPlansOrderRequest.getManagers()

    setRequestConfig(getBrokersByFiltersRequestConfig)
    managersRequestConfig(getManagersRequestConfig)
  }, [])

  useEffect(() => {
    if (filtersBrokersQuery.isError) {
      triggerToast(false, 'Ops, ocorreu um erro ao carregar os corretores')
    }
  }, [filtersBrokersQuery.isError])

  useEffect(() => {
    if (error) triggerToast(false, 'Ops, ocorreu um erro ao carregar os corretores')
    if (managersError) triggerToast(false, 'Ops, ocorreu um erro ao carregar os supervisores')
  }, [error, managersError])

  useEffect(() => {
    if (data) {
      const tableRows = data.brokers.map((broker) => ({
        key: broker.id,
        brokerName: broker.name,
        brokerPhone:
          broker.personalInformation.phone != null
            ? formatPhone(broker.personalInformation.phone)
            : '-',
        brokerMail: broker.personalInformation.mail,
        registration: dayjs(broker.createdAt).locale('pt-br').format('DD/MM/YYYY'),
        sisWebCode: broker.internalSystemId ?? ' - ',
        status: broker.registrationStatus,
      }))

      setHasNext(data.hasNext)
      setBrokers((prevData) => (page === 0 ? tableRows : [...prevData, ...tableRows]))
    }
    if (managersData) setManagers(managersData.managers ?? [])
  }, [data, managersData])

  useEffect(() => {
    const requestConfig = healthPlansOrderRequest.getBrokersByFilters(filters, page)
    setRequestConfig(requestConfig)
    if (data?.hasNext) {
      setRequestConfig(requestConfig)
    } else {
      setHasNext(data?.hasNext ?? false)
    }
  }, [filters, page])

  const handleRequest = (action: Action, filtersValues: { key: string; value: string }[]) => {
    switch (action) {
      case Action.AddPage: {
        const requestConfig = healthPlansOrderRequest.getBrokersByFilters(filters, page)
        setRequestConfig(requestConfig)
        break
      }

      case Action.AddFilter: {
        const keys = filtersValues.map((filter) => filter.key)
        const targetFilters = { ...filters }

        keys.forEach((key) => {
          targetFilters[key] = [
            ...targetFilters[key],
            filtersValues.find((filter) => filter.key === key)?.value,
          ]
        })

        setFilters(targetFilters)
        setPage(0)

        break
      }

      case Action.RemoveFilter: {
        const keys = filtersValues.map((filter) => filter.key)
        const targetFilters = { ...filters }

        keys.forEach((key) => {
          targetFilters[key] = targetFilters[key].filter(
            (filterValue: string) =>
              filterValue !== filtersValues.find((filter) => filter.key === key)?.value,
          )
        })

        setFilters(targetFilters)
        setPage(0)

        break
      }
      case Action.ReplaceFilter: {
        const keys = filtersValues.map((filter) => filter.key)
        const targetFilters = {}

        keys.forEach((key) => {
          targetFilters[key] = filtersValues.find((filter) => filter.key === key)?.value
        })

        setFilters(targetFilters)
        setPage(0)

        break
      }
    }
  }

  const getTranslatedFilterName = (filterName: string) => {
    const filterTranslations = {
      registrationStatus: 'Status do Cadastro',
      isRegisteredInInternalSystem: 'Cadastro no sisweb',
      name: 'Nome',
      internalSystemId: 'Código Sisweb',
    }

    return filterTranslations[filterName] || filterName
  }

  const handleResetFilter = (filterName: string) => {
    setFilters((prevFilters) => {
      const updatedFilters: IFilters = {
        ...prevFilters,
        [filterName]: '',
      }
      return updatedFilters
    })
  }

  return (
    <>
      <Container>
        <FilterButton onClick={() => setOpenFilter(!openFilter)} isActive={openFilter}>
          <FilterOutlined style={{ color: `${openFilter ? '#FFF' : '#4B4B4B'}` }} />
          Filtrar
        </FilterButton>
        {openFilter && (
          <FilterDiv>
            <Filter
              itemsColor="#514984"
              items={[
                {
                  key: '1',
                  label: 'Status',
                  children: (
                    <StatusFilter
                      handleRequest={handleRequest}
                      statusSelected={filters.registrationStatus}
                    />
                  ),
                },
                {
                  key: '2',
                  label: 'Cadastro no sisweb',
                  children: (
                    <RegisteredInternalSystemFilter
                      handleRequest={handleRequest}
                      statusSelected={filters.isRegisteredInInternalSystem}
                    />
                  ),
                },
                {
                  key: '3',
                  label: 'Supervisor',
                  children: (
                    <ManagerFilter
                      data={managers}
                      handleRequest={handleRequest}
                      statusSelected={filters.managerId}
                    />
                  ),
                },
                {
                  key: '4',
                  label: 'Código do Sisweb',
                  children: (
                    <SiswebFilter
                      placeHolder="Buscar corretor pelo código sisweb"
                      handleRequest={handleRequest}
                      selectedCodes={filters.internalSystemId ?? []}
                    />
                  ),
                },
              ]}
            />
          </FilterDiv>
        )}

        {Object.keys(filters).map((key) => {
          const value = filters[key]
          if (Array.isArray(value) && value.length > 0) {
            return (
              <SelectedFilters key={key} onClick={() => handleResetFilter(key)}>
                {getTranslatedFilterName(key)}
                <CloseOutlined style={{ marginLeft: '5px', color: '#878EA6' }} />
              </SelectedFilters>
            )
          }
          return null
        })}

        <SearchInput
          placeholder="Pesquisar"
          onSearch={(value) => handleRequest(Action.ReplaceFilter, [{ key: 'name', value }])}
        />

        <TableComponent
          columns={columns}
          rows={brokers}
          hasNext={hasNext}
          setPage={setPage}
          setIsOpen={setIsOpen}
          setBrokerId={setBrokerId}
          isLoading={isLoading}
        />

        <Modal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          footer={false}
          width={1200}
          bodyStyle={{ height: '80vh', overflow: 'auto' }}
          padding="0px"
          disableMarginTop={true}
        >
          <BrokerModalContent brokerId={brokerId} />
        </Modal>
        <Toast />
      </Container>
    </>
  )
}

export default BrokerManager
