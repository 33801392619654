import React from 'react'

import { Tooltip as TooltipAntd } from 'antd'

export interface Props {
  key: string
  children: React.ReactNode
  title: React.ReactNode
  color?: string
  placement:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
}

const Tooltip: React.FC<Props> = ({
  key,
  title,
  color = 'rgba(34, 34, 34, 0.80)',
  placement,
  children,
}) => (
  <TooltipAntd key={key} title={title} color={color} placement={placement}>
    {children}
  </TooltipAntd>
)

export default Tooltip
