import { useEffect, useMemo, useState } from 'react'

import { CheckOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { Button, Modal, Skeleton, Toast } from 'design-system/components'

import { CommissionGridPay, GridItem } from '@/App/clients/finOps/dtos'
import { getCommissionGridsPayQueryOptions } from '@/App/clients/finOps/queries/commissionGridPay'
import { getCommissionGridPayItemsQueryOptions } from '@/App/clients/finOps/queries/commissionGridPayItems'
import { DistributionChannel } from '@/App/clients/productInformationManagment/dtos'
import { getDistributionChannelsQueryOptions } from '@/App/clients/productInformationManagment/queries/distributionChannels'

import { CustomFilter } from './customFilter'
import { GridsItemsEditComponent } from './gridsItemsEdit'
import { ButtonContainer, CompactTable } from './styles'

export interface QuotasObject {
  quota1: number
  quota2: number
  quota3: number
  quota4: number
  quota5: number
  quota6: number
  quota7: number
  quota8: number
}

export interface DistributionChannelQuotas {
  [key: string]: QuotasObject
}

export interface ITableData extends QuotasObject {
  id: string
  grid: string
  gridId: string
  broker: string
  brokerId: string
  modality: string
  modalityId: string
  administrator: string
  administratorId: string
  dristibutionChannelId: string
  platform: string
  platformId: string
  startAt: Date
  endAt?: Date
  createdAt: Date
}

export const CommissionGridsItems = () => {
  const getCommissionGridPayItemsQuery = useQuery(
    getCommissionGridPayItemsQueryOptions({ gridId: '' }),
  )

  const getCommissionGridsPayQuery = useQuery(getCommissionGridsPayQueryOptions({ filter: '' }))

  const getDistributionChannelsQuery = useQuery(getDistributionChannelsQueryOptions())

  const [modalOpen, setModalOpen] = useState(false)
  const [allGridsItems, setAllGridsItems] = useState<GridItem[]>([])
  const [selectedItems, setSelectedItems] = useState<ITableData[]>([])
  const [filteredGridsItems, setFilteredGridsItems] = useState<GridItem[]>([])

  useEffect(() => {
    if (
      !getCommissionGridPayItemsQuery.data ||
      !getDistributionChannelsQuery.data ||
      !getCommissionGridsPayQuery.data
    )
      return

    const updatedAllGridsItems = gridsWithAllDistributionChannels(
      getCommissionGridPayItemsQuery.data,
      getDistributionChannelsQuery.data,
      getCommissionGridsPayQuery.data,
    )
    setAllGridsItems(updatedAllGridsItems)
  }, [
    getCommissionGridPayItemsQuery.data,
    getDistributionChannelsQuery.data,
    getCommissionGridsPayQuery.data,
  ])

  useEffect(() => {
    setFilteredGridsItems(allGridsItems)
  }, [allGridsItems])

  const [selected, setSelected] = useState<Array<string>>([])

  useEffect(() => {
    setSelected([])
  }, [filteredGridsItems])

  dayjs.extend(utc)
  dayjs.extend(timezone)

  const gridsWithAllDistributionChannels = (
    gridItems: GridItem[],
    distributionChannels: DistributionChannel[],
    commissionGridsPay: CommissionGridPay[],
  ) => {
    const list = commissionGridsPay.map((grid) => {
      const itemsThatAlreadyExists = gridItems.filter((item) => item.idGrade === grid.id)

      const idsThatAlreadyExists = itemsThatAlreadyExists.map(
        (item) =>
          item.idOperadora +
          item.idModalidade +
          (item.idAdministradora ?? '') +
          (item.idPlataforma ?? ''),
      )

      const itemsThatNotExist = distributionChannels
        .filter(
          (distributionChannels) =>
            !idsThatAlreadyExists.includes(
              distributionChannels.operadora.id +
                distributionChannels.modalidade.id +
                (distributionChannels.administradora?.id ?? '') +
                (distributionChannels.plataforma?.id ?? ''),
            ),
        )
        .map(
          (distributionChannel) =>
            ({
              idGrade: grid.id,
              id: 'novo_item_' + distributionChannel.id + '_na_grade_' + grid.id,
              idModalidade: distributionChannel.modalidade.id,
              nomeModalidade: distributionChannel.modalidade.nome,
              idOperadora: distributionChannel.operadora.id,
              nomeOperadora: distributionChannel.operadora.nome,
              idAdministradora: distributionChannel.administradora?.id ?? '',
              nomeAdministradora: distributionChannel.administradora?.nome ?? '',
              idPlataforma: distributionChannel.plataforma?.id ?? '',
              nomePlataforma: distributionChannel.plataforma?.nome ?? '',
              nomeGrade: commissionGridsPay.find((commissionGrid) => commissionGrid.id === grid.id)
                ?.nome,
              fimVigencia: null,
              inicioVigencia: null,
              parcelas: [],
              createdAt: new Date(0),
            } as unknown as GridItem),
        )

      return [...itemsThatAlreadyExists, ...itemsThatNotExist]
    })

    return list.flat()
  }

  const handleChangeSelected = (event, id: string) => {
    if (event.target.checked) {
      setSelected((e) => [...e, id])
    } else {
      setSelected((e) => e.filter((value) => value !== id))
    }
  }

  const handleChangeSelectAll = (event) => {
    if (event.target.checked) {
      setSelected(dataTable.filter((item) => !item.endAt).map((item) => item.id))
    } else {
      setSelected([])
    }
  }

  const columns: ColumnsType<ITableData> = [
    {
      key: '0',
      dataIndex: 'id',
      title: <input type="checkbox" onChange={(e) => handleChangeSelectAll(e)} />,
      render: (id, lineData) =>
        lineData.endAt ? (
          ''
        ) : (
          <input
            type="checkbox"
            checked={selected.includes(id)}
            onChange={(e) => handleChangeSelected(e, id)}
          />
        ),
    },
    {
      key: '1',
      dataIndex: 'grid',
      title: 'Grade',
      sorter: (a, b) => a.grid.localeCompare(b.grid),
    },
    {
      key: '2',
      dataIndex: 'broker',
      title: 'Operadora/Administradora',
      sorter: (a, b) => a.broker.localeCompare(b.broker),
    },
    {
      key: '3',
      dataIndex: 'administrator',
      title: 'Administradora',
      sorter: (a, b) => a.administrator.localeCompare(b.administrator),
    },
    {
      key: '4',
      dataIndex: 'platform',
      title: 'Plataforma',
      sorter: (a, b) => a.broker.localeCompare(b.broker),
    },
    {
      key: '5',
      dataIndex: 'modality',
      title: 'Modalidade Agregada',
      sorter: (a, b) => a.modality.localeCompare(b.modality),
    },
    {
      key: '6',
      dataIndex: 'quota1',
      title: '1',
      render: (value) => value + ' %',
    },
    {
      key: '7',
      dataIndex: 'quota2',
      title: '2',
      render: (value) => value + ' %',
    },
    {
      key: '8',
      dataIndex: 'quota3',
      title: '3',
      render: (value) => value + ' %',
    },
    {
      key: '9',
      dataIndex: 'quota4',
      title: '4',
      render: (value) => value + ' %',
    },
    {
      key: '10',
      dataIndex: 'quota5',
      title: '5',
      render: (value) => value + ' %',
    },
    {
      key: '11',
      dataIndex: 'quota6',
      title: '6',
      render: (value) => value + ' %',
    },
    {
      key: '12',
      dataIndex: 'quota7',
      title: '7',
      render: (value) => value + ' %',
    },
    {
      key: '13',
      dataIndex: 'quota8',
      title: '8',
      render: (value) => value + ' %',
    },
    {
      key: '14',
      dataIndex: '',
      title: 'Total',
      render: (_value, lineData) => {
        if (!lineData) return 0

        const quota1 = lineData.quota1 ?? 0
        const quota2 = lineData.quota2 ?? 0
        const quota3 = lineData.quota3 ?? 0
        const quota4 = lineData.quota4 ?? 0
        const quota5 = lineData.quota5 ?? 0
        const quota6 = lineData.quota6 ?? 0
        const quota7 = lineData.quota7 ?? 0
        const quota8 = lineData.quota8 ?? 0

        const total = quota1 + quota2 + quota3 + quota4 + quota5 + quota6 + quota7 + quota8

        return `${Intl.NumberFormat('pt-BR', { minimumFractionDigits: 0 }).format(total)}%`
      },
    },
    {
      key: '15',
      dataIndex: 'startAt',
      title: 'Início vigência',

      render: (a) => `${a ? dayjs.tz(dayjs(a), 'America/Sao_Paulo').format('DD/MM/YYYY') : ''}`,
    },
    {
      key: '16',
      dataIndex: 'endAt',
      title: 'Fim vigência',
      render: (a) => `${a ? dayjs.tz(dayjs(a), 'America/Sao_Paulo').format('DD/MM/YYYY') : ''}`,
    },
  ]

  const parseDataToTable = (filteredGridItem: GridItem[]): ITableData[] => {
    const dados = filteredGridItem?.map((gridItem) => {
      const quotas: QuotasObject = {
        quota1: 0,
        quota2: 0,
        quota3: 0,
        quota4: 0,
        quota5: 0,
        quota6: 0,
        quota7: 0,
        quota8: 0,
      }

      if (gridItem)
        gridItem?.parcelas?.forEach((parcela) => {
          const quotaLookup = {
            1: 'quota1',
            2: 'quota2',
            3: 'quota3',
            4: 'quota4',
            5: 'quota5',
            6: 'quota6',
            7: 'quota7',
            8: 'quota8',
          }
          const quotaNumber = quotaLookup[parcela.numero]

          if (quotaNumber) quotas[quotaNumber] = Math.round(parcela.porcentagem * 100)
        })

      return {
        id: gridItem.id,
        dristibutionChannelId: gridItem.idCanalDistribuicao,
        grid: gridItem.nomeGrade,
        gridId: gridItem.idGrade,
        broker: gridItem.nomeOperadora,
        brokerId: gridItem.idOperadora,
        modality: gridItem.nomeModalidade,
        modalityId: gridItem.idModalidade,
        platform: gridItem.nomePlataforma ? gridItem.nomePlataforma : 'Direta',
        platformId: gridItem.idPlataforma,
        administrator: gridItem.nomeAdministradora ? gridItem.nomeAdministradora : 'Direta',
        administratorId: gridItem.idAdministradora,
        startAt: gridItem.inicioVigencia,
        endAt: gridItem.fimVigencia,
        createdAt: gridItem.createdAt,
        ...quotas,
      }
    })

    return dados
  }

  const dataTable = useMemo(() => parseDataToTable(filteredGridsItems), [filteredGridsItems])

  const handleOpenEditor = () => {
    const selectedDataTableItems = dataTable.filter((item) => selected.includes(item.id))

    setSelectedItems(selectedDataTableItems)

    setModalOpen(true)
  }

  return (
    <>
      <Toast />
      <CustomFilter data={allGridsItems} setFilteredData={setFilteredGridsItems} />

      {getCommissionGridPayItemsQuery.isLoading ||
      getDistributionChannelsQuery.isLoading ||
      getCommissionGridsPayQuery.isLoading ? (
        <Skeleton />
      ) : (
        <CompactTable
          columns={columns as ColumnsType<object>}
          dataSource={dataTable}
          loading={false}
          pagination={{ hideOnSinglePage: false, defaultPageSize: 50, showSizeChanger: true }}
          rowClassName="editable-row"
        />
      )}

      <Modal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        footer={''}
        width={'100%' as unknown as number}
      >
        <GridsItemsEditComponent items={selectedItems} setModalOpen={setModalOpen} />
      </Modal>

      <ButtonContainer>
        <Button onClick={() => handleOpenEditor()} disabled={selected.length === 0}>
          <CheckOutlined /> Atualizar itens selecionados
        </Button>
      </ButtonContainer>
    </>
  )
}
