import styled from 'styled-components'
import { colors } from '../../styles/theme'

import { Space, Table } from 'antd'
import { Collapse } from 'antd'

export const DesktopTable = styled(Table)`
  .ant-table .ant-table-container .ant-table-content {
    margin: 0 5px !important;
  }

  .ant-table-thead .ant-table-cell {
    background-color: transparent;
    border-bottom: none;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    display: none !important;
  }

  .ant-table {
    background: transparent;
  }

  .ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    background-color: transparent;
  }

  .ant-table-thead > tr > th {
    border: none;
  }

  .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
    border: none;
  }

  .ant-table-tbody > tr > td {
    background-color: #ffffff;
    margin-top: 5px;
    color: #485964;
  }

  .anticon-down .anticon-up svg {
    color: ${colors.gray} !important;
  }

  .ant-table table {
    border-spacing: 0 10px;
  }

  .editable-row {
    border-width: 3px;
    border-style: solid;
    box-shadow: rgb(0 0 0 / 15%) 0px 3px 10px 0px;
    border-radius: 10px;
  }

  .editable-row td:first-child {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }

  .editable-row td:last-child {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    border: none;
  }

  .editable-row + tr {
    margin-top: 20px;
  }

  && tbody > tr:hover {
    background: #fff;
  }

  && tbody > tr:hover > td {
    background: #fff;
  }

  .ant-table-thead > tr > th {
    color: #485964;
    padding-bottom: 0;
  }

  .ant-table-tbody > tr > td {
    border-right: 1px solid #ebebeb;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ant-table-tbody > tr > *:nth-last-child(2) {
    border-right: none;
  }

  .ant-table-expanded-row .ant-table-cell {
    padding: 0 !important;
  }

  .editable-expanded-row .ant-table-cell {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-right: none;
    border-bottom: none;
  }

  & .ant-table-tbody:hover {
    cursor: pointer !important;
  }
`

export const ExpandableRowDiv = styled.div`
  cursor: auto;
  background: white;
  padding: 0;
  box-shadow: rgb(0 0 0 / 15%) 0px 9px 10px 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: -16px;

  @media screen and (max-width: 768px) {
    box-shadow: none;
    padding-bottom: 10px;
  }
`

export const ExpandableRowDivider = styled.div`
  border-bottom: 1px solid #e6e6e6;
  width: 95%;
  transform: translateX(3%);
  padding-top: 5px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`
export const StyledSpace = styled(Space)`
  width: 100%;
`

export const StyledCollapse = styled(Collapse)`
  border: none;
  width: 100%;

  .ant-collapse-header {
    background: white;
    border: none;
  }

  .ant-collapse-item {
    box-shadow: 0px 2px 10px 0px rgba(55, 70, 126, 0.25);

    @media screen and (max-width: 768px) {
      border-radius: 8px !important;
    }
  }

  .ant-collapse-item .ant-collapse-header {
    border-radius: 10px 10px 8px 8px !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
`
