import styled from 'styled-components'

export const SearchInputWrapper = styled.div`
  .ant-select-show-search {
    width: 100% !important;
  }
`

export const ColumnBody = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 15px;
  max-height: 300px;
  overflow: auto;
  padding-left: 5px;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dcdfeb;
    border-radius: 10px;
  }
`
