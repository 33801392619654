import styled from 'styled-components'

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ModalTextArea = styled.textarea`
  width: 100%;
  border-radius: 10px;
  border-color: #b7c8d5;
`
export const ButtonDiv = styled.div`
  margin-top: 20px;
  width: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const CancelButton = styled.button`
  margin-top: 20px;
  border: none;
  background: white;
  border-bottom: 1px solid;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`

export const ModalTitle = styled.p`
  color: #4b4b4b;
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 5px;
`
export const ModalSubTitle = styled.p`
  color: #878da1;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
`

export const TagContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 31.5%;
  margin-bottom: 20px;
`

export const StyledTag = styled.div`
  background-color: #f5f6f9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  height: 30px;
  color: #4b4b4b;
  font-size: 14px;
  font-weight: 600;
`
