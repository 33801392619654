import { useMutation } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'
import { v4 as uuidv4 } from 'uuid'

import { api } from '@/App/clients/http'
import { calculateAge } from '@/App/utils/calculateProcessingTime'

import { GetOrderInformationBody } from '../../healthPlansOrders/dtos'

type DispatchSiswebEventHookData = {
  product?: 'HEALTH' | 'DENTAL'
  orderId?: string | null
}

type DispatchSiswebEventData = Pick<DispatchSiswebEventHookData, 'product'> & {
  order: GetOrderInformationBody
}

function getHoldersAndDependentsLifesTotalAmount(order: GetOrderInformationBody) {
  return order.holders?.reduce((total, holder) => {
    const holderAmount =
      holder.quotationPlans?.reduce((amount, plan) => {
        return amount + (plan.lifeAmount as number)
      }, 0) ?? 0

    const dependentsAmount =
      holder.dependents?.reduce((amount, dependent) => {
        const dependentTotal =
          dependent.quotationPlans?.reduce((dependentAmount, plan) => {
            return dependentAmount + (plan.lifeAmount as number)
          }, 0) ?? 0

        return amount + dependentTotal
      }, 0) ?? 0

    return total + holderAmount + dependentsAmount
  }, 0)
}

async function dispatchSiswebEvent({ product, order }: DispatchSiswebEventData) {
  const dispatchEvent = {
    isTest: import.meta.env.MODE !== 'production',
    correlationId: uuidv4(),
    orderId: order?.id,
    quotation: {
      insurerId: order?.insurerId ?? null,
      dispatchedAt: order?.dispatchedAt ?? null,
      modality: order?.quotation?.type ?? null,
      totalAmount: getHoldersAndDependentsLifesTotalAmount(order),
      isDentalPlan: product === 'DENTAL',
      insurer: order?.quotation?.insurer ?? null,
      product: order?.quotation?.product ?? null,
      administrator: order?.quotation?.administrator ?? null,
      dispatchOrganization: order?.dispatchOrganization ?? null,
      broker: {
        name: order?.broker?.name ?? null,
        document: order?.broker?.document ?? null,
      },
      externalBroker: order?.externalBroker
        ? {
            name: order?.externalBroker?.name ?? null,
            document: order?.externalBroker?.document ?? null,
          }
        : null,
      contact: {
        name: order?.company?.tradingName ?? order?.holders?.at(0)?.name ?? null,
        document: order?.company?.document ?? order?.holders?.at(0)?.cpf ?? null,
      },
    },
    company: order?.company
      ? {
          address: order?.company?.address
            ? {
                zipCode: order.company.address.zipCode ?? null,
                street: order.company.address.street ?? null,
                number: order.company.address.number ?? null,
                complement: order.company.address.complement ?? null,
                city: order.company.address.city ?? null,
                state: order.company.address.state ?? null,
                neighborhood: order.company.address.neighborhood ?? null,
              }
            : null,
          legalRepresentative: {
            name: order?.company?.externalLegalRepresentative?.name ?? null,
            mail: order?.company?.externalLegalRepresentative?.mail ?? null,
            phone: order?.company?.externalLegalRepresentative?.phone ?? null,
          },
        }
      : null,
    financialOfficer:
      calculateAge(order?.holders?.at(0)?.birthDate) < 18 &&
      ['name', 'cpf', 'mail', 'phone', 'birthDate'].every(
        (field) => order?.financialOfficer?.[field] != null,
      )
        ? {
            name: order?.financialOfficer?.name ?? null,
            cpf: order?.financialOfficer?.cpf ?? null,
            mail: order?.financialOfficer?.mail ?? null,
            phone: order?.financialOfficer?.phone ?? null,
            birthDate: order?.financialOfficer?.birthDate ?? null,
          }
        : null,
    holders: order?.holders?.map((holder) => {
      const holderPlanInformation = holder.quotationPlans?.find((plan) => plan.type === product)

      return {
        name: holder.name ?? null,
        cpf: holder.cpf ?? null,
        email: holder.mail ?? null,
        rg: holder.rg ?? null,
        maritalStatus: holder.maritalStatus ?? null,
        relationship: holder.holderKinship ?? null,
        birthDate: holder.birthDate ?? null,
        motherName: holder.motherName ?? null,
        gender: holder.gender ?? null,
        lifeAmount: holderPlanInformation?.lifeAmount ?? 0,
        phone: holder.phone ?? null,
        planInformation: {
          name: holderPlanInformation?.planName ?? null,
          accommodationType: holderPlanInformation?.accommodationType ?? null,
        },
        address: holder.address
          ? {
              zipCode: holder.address.zipCode ?? null,
              street: holder.address.street ?? null,
              number: holder.address.number ?? null,
              complement: holder.address.complement ?? null,
              city: holder.address.city ?? null,
              state: holder.address.state ?? null,
              neighborhood: holder.address.neighborhood ?? null,
            }
          : null,
        dependents: holder.dependents?.map((dependent) => {
          const planInformation = dependent.quotationPlans?.find((plan) => plan.type === product)

          return {
            name: dependent.name ?? null,
            cpf: dependent.cpf ?? null,
            email: holder.mail ?? null,
            rg: dependent.rg ?? null,
            maritalStatus: dependent.maritalStatus ?? null,
            relationship: dependent.holderKinship ?? null,
            birthDate: dependent.birthDate ?? null,
            motherName: dependent.motherName ?? null,
            gender: dependent.gender ?? null,
            lifeAmount: planInformation?.lifeAmount ?? 0,
          }
        }),
      }
    }),
  }

  const response = await api.post(
    `${import.meta.env.VITE_SISWEB_DISPATCH_URL}/order/create`,
    {
      ...dispatchEvent,
    },
    {
      headers: {
        'x-api-key': import.meta.env.VITE_SISWEB_DISPATCH_APIKEY,
      },
    },
  )

  return response.data
}

export function useDispatchSiswebEvent({ product, orderId }: DispatchSiswebEventHookData) {
  return useMutation({
    mutationKey: ['sisweb', 'dispatch', orderId, product],
    mutationFn: (order: GetOrderInformationBody) => dispatchSiswebEvent({ product, order }),
    onSuccess: () => {
      triggerToast(true, 'Solicitação do cadastro feita com sucesso!')
    },
    onError: () => {
      triggerToast(false, 'Ops, ocorreu um erro durante a solicitação do cadastro.')
    },
  })
}
