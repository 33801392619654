import { useMemo } from 'react'

import { Tabs, Toast } from 'design-system/components'

import BrokerCommissionPayComponent from './brokerComissionPay'
import CommissionGridPay from './commissionGridPay'
import { CommissionGridsItems } from './commissionGridsItems'
import { Container } from './styles'

const CommissionGrids = () => {
  const defaultKey = useMemo(() => {
    if (document.URL.includes('/grades-de-comissao/consulta-pagar')) return '2'

    return '0'
  }, [document.URL])

  const tabsItems = [
    {
      key: '0',
      label: 'Grade de comissão a pagar',
      children: <CommissionGridPay />,
    },
    {
      key: '1',
      label: 'Corretor - Comissão a pagar',
      children: <BrokerCommissionPayComponent />,
    },
    {
      key: '2',
      label: 'Consulta a pagar',
      children: <CommissionGridsItems />,
    },
  ]

  return (
    <Container>
      <Tabs items={tabsItems} itemsColor="#514984" defaultActiveKey={defaultKey} />

      <Toast />
    </Container>
  )
}

export default CommissionGrids
