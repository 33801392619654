/* eslint-disable react/prop-types */
import { useEffect } from 'react'

import { Button, Toast } from 'design-system/components'
import Exclamation from 'design-system/exclamation'

import { useOrderInformationQuery } from '@/App/clients/healthPlansOrders/queries/orderInformation'
import { useQuotationDispatchMutation } from '@/App/clients/orderGateway/mutations/dispatchQuotationEvent'
import { useUpdateStatus } from '@/App/clients/orderState/mutation/updateStatus'

import { ButtonDiv, CancelButton, ModalContent, ModalSubTitle, ModalTitle } from './style'

interface QuotationInsurerProps {
  setIsModalOpen?: (value: boolean) => void
  orderId?: string | null
  insurer?: string | null
  setOrderStatus?: React.Dispatch<React.SetStateAction<string | null>>
  reloadOrderData?: () => void
}

export const QuotationInsurerModal: React.FC<QuotationInsurerProps> = ({
  setIsModalOpen,
  setOrderStatus,
  orderId,
  insurer,
  reloadOrderData,
}) => {
  const sendDispatchQuotation = useQuotationDispatchMutation()

  const orderInformationQuery = useOrderInformationQuery({ orderId })
  const updateStatusMutation = useUpdateStatus(orderId)

  useEffect(() => {
    if (sendDispatchQuotation.isSuccess) {
      const currentStatus = 'QUOTATION_INSURER'

      if (orderInformationQuery?.data?.status !== currentStatus) {
        updateStatusMutation.mutateAsync({
          nextStatus: currentStatus,
          order: orderInformationQuery?.data,
        })
        setOrderStatus && setOrderStatus(currentStatus)
      }

      setIsModalOpen && setIsModalOpen(false)
    }
  }, [sendDispatchQuotation.isSuccess])

  useEffect(() => {
    if (updateStatusMutation.isSuccess) {
      reloadOrderData && reloadOrderData()
    }
  }, [updateStatusMutation.isSuccess])

  return (
    <ModalContent>
      <Exclamation />
      <ModalTitle>Tem certeza que deseja solicitar Cotação Automática?</ModalTitle>
      <ModalSubTitle>
        Ao confirmar, o robô da Bliss automaticamente realizará a cotação da propsota no site da
        operadora. Certifique-se de que todos os campos obrigatórios estão preenchidos corretamente.
      </ModalSubTitle>

      <ButtonDiv>
        <Button
          loading={sendDispatchQuotation.isPending}
          onClick={() => {
            const dispatchData = { orderId, insurer }
            sendDispatchQuotation.mutate(dispatchData)
          }}
        >
          Solicitar cotação automática
        </Button>
        <CancelButton onClick={() => setIsModalOpen && setIsModalOpen(false)}>
          Cancelar
        </CancelButton>
      </ButtonDiv>
      <Toast />
    </ModalContent>
  )
}
