import { colors } from '@design-system/styles/theme'
import styled from 'styled-components'

interface StyledProps {
  activeHover?: boolean
}
export const OtherInfosDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  row-gap: 10px;
  width: 100%;
`

export const OtherInfosHeader = styled.div`
  font-size: 17px;
  color: ${colors.primaryText};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin-top: 15px;

  span {
    font-weight: 600;
    size: 17px;
  }
`
export const HorizontalLine = styled.hr`
  height: 1px;
  border-width: 0;
  background-color: #dcdfeb;
  width: 100%;
`

export const ActionsDiv = styled.div`
  display: flex;
  flex-direction: column;
`

export const ActionsDivPorto = styled.div`
  display: flex;
  flex-direction: column;
`

export const WrapperDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    font-size: 14px;
    font-weight: 500;
  }

  span {
    color: #878da1;
    font-weight: 400;
  }
`

export const EditInfoButton = styled.div<StyledProps>`
  width: 21rem;
  display: flex;
  column-gap: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: start;

  p {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 0;
    font-weight: 500;
    color: ${colors.primaryText};
  }

  :hover {
    cursor: pointer;
    border-radius: 10px;
    background-color: ${(props) => props.activeHover && colors.mouseHover};
  }

  svg {
    color: #878da1;
  }

  span {
    color: #878da1;
  }
`
export const RequestTag = styled.div`
  display: flex;
  width: 200px;
  height: 20px;
  border-radius: 50px;
  border: 1px solid #b7c8d5;
  padding: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    color: ${colors.primaryText};
    display: inline;
    font-weight: 600;
    margin-right: 5px;
    font-size: 13px;
  }
`
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`
export const StyledDispatchInProgress = styled.div`
  display: flex;
  column-gap: 5px;
`
export const IconWrapper = styled.div`
  margin-top: 5px;
`
export const TitleStatus = styled.span`
  color: #485964;
  font-size: 10px;
  font-weight: 600;
`
export const SubttitleStatus = styled(TitleStatus)`
  color: #879dac;
`
