import React from 'react'
import { StyledSpinner } from './style'

export interface SpinnerProps {
    color?: string;
    width?: string;
    height?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ color, width, height }) => (
    <StyledSpinner width={width} height={height} color={color}/>
)

export default Spinner;
