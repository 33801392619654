import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, ConfirmationModal, SearchInput, Table } from 'design-system/components'

import { CommissionGridPay } from '@/App/clients/finOps/dtos'
import { useCreateCommissionGridPayMutation } from '@/App/clients/finOps/mutations/createCommissionGridPay'
import { useDeleteCommissionGridPayMutation } from '@/App/clients/finOps/mutations/deleteCommissionGridPay'
import { useDuplicateCommissionGridPayMutation } from '@/App/clients/finOps/mutations/duplicateCommissionGridPay'
import { getCommissionGridsPayQueryOptions } from '@/App/clients/finOps/queries/commissionGridPay'

import OptionsColumn from './optionsColumn'
import { ButtonContainer, CenterText, SearchContainer } from './styles'

const CommissionGridPayComponent = () => {
  const navigate = useNavigate()
  const [filter, setFilter] = useState('')
  const [gridModalExclusion, setGridModalExclusion] = useState<CommissionGridPay | null>(null)

  const getCommissionGridsPayQuery = useQuery(getCommissionGridsPayQueryOptions({ filter: filter }))
  const createCommissionGridsPayMutation = useCreateCommissionGridPayMutation()
  const deleteCommissionGridsPayMutation = useDeleteCommissionGridPayMutation()
  const duplicateCommissionGridsPayMutation = useDuplicateCommissionGridPayMutation()

  const handleCreate = async () => {
    const userName = localStorage.getItem('userName')
    const { status, data } = await createCommissionGridsPayMutation.mutateAsync({
      nome: `Nova grade ${new Date().getTime()}`,
      criadoPor: userName,
    })

    if (status !== 201) return

    navigate('/alterar-grade', {
      state: {
        id: data.id,
      },
    })
  }

  const handleEditView = (data: CommissionGridPay) => {
    navigate('/alterar-grade', {
      state: { id: data.id },
    })
  }

  const handleDuplicate = async (gridData: CommissionGridPay) => {
    const nomeUsuario = localStorage.getItem('userName')

    const { status, data } = await duplicateCommissionGridsPayMutation.mutateAsync({
      id: gridData.id,
      createdBy: nomeUsuario,
    })

    if (status !== 201) return

    navigate('/alterar-grade', {
      state: {
        id: data.id,
      },
    })
  }

  const handleDelete = (data: CommissionGridPay) => {
    setGridModalExclusion(data)
  }

  const handleConfirmDelete = async () => {
    if (gridModalExclusion) {
      const responseDelete = await deleteCommissionGridsPayMutation.mutateAsync(gridModalExclusion)

      return responseDelete.status === 200
    }

    return false
  }

  const tableColumns = [
    {
      title: 'ID Grade',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nome da Grade',
      dataIndex: 'nome',
      key: 'name',
    },
    {
      title: 'Criado/Editado por',
      dataIndex: 'criadoPor',
      key: 'criadoPor',
    },
    {
      title: 'Data Criação/Última atualização',
      dataIndex: '',
      key: 'createdAt/updatedAt',
      width: 160,
      render: ({ createdAt }) => {
        const date = new Date(createdAt)

        return (
          <CenterText>
            {date.getDate()}/{date.getMonth()}/{date.getFullYear()} {date.getHours()}:
            {date.getMinutes()}:{date.getSeconds()}
          </CenterText>
        )
      },
    },
    {
      title: 'Sisweb atualizado?',
      dataIndex: 'internalSystemUpdated',
      key: 'internalSystemUpdated',
      width: 80,
      render: (isUpdated) => <CenterText>{isUpdated ? 'Sim' : 'Não'}</CenterText>,
    },
    {
      title: (
        <SearchContainer>
          <SearchInput placeholder="Digite o que você procura" onSearch={setFilter} />
        </SearchContainer>
      ) as unknown as string,
      dataIndex: '',
      key: '',
      render: (data) => (
        <OptionsColumn
          onEditView={() => handleEditView(data)}
          onDelete={() => handleDelete(data)}
          onDuplicate={() => handleDuplicate(data)}
        />
      ),
    },
  ]

  return (
    <>
      <ConfirmationModal
        buttonText="Confirmar"
        onClick={() => handleConfirmDelete()}
        successMessage=""
        errorMessage=""
        setIsOpen={() => setGridModalExclusion(null)}
        isOpen={!!gridModalExclusion}
        title="Exclusão"
        width={400}
      >
        Tem certeza que deseja excluir a grade &quot;{gridModalExclusion?.nome}&quot;
      </ConfirmationModal>
      <Table
        loading={getCommissionGridsPayQuery.isFetching}
        columns={tableColumns}
        data={getCommissionGridsPayQuery.data ?? []}
      />

      <ButtonContainer>
        <Button onClick={handleCreate}>
          <PlusOutlined /> Cadastrar nova grade
        </Button>
      </ButtonContainer>
    </>
  )
}

export default CommissionGridPayComponent
