export const accommodationTypesBradesco = [
  { title: 'Apartamento', const: 'Apartamento' },
  { title: 'Enfermaria', const: 'Enfermaria' },
  { title: 'Odonto', const: 'Odonto' },
  { title: 'Quarto Privativo', const: 'Quarto Privativo' },
  { title: 'Multiplo 2', const: 'Multiplo 2' },
  { title: 'Multiplo 3', const: 'Multiplo 3' },
  { title: 'Multiplo 4', const: 'Multiplo 4' },
  { title: 'Multiplo 6', const: 'Multiplo 6' },
  { title: 'Multiplo 8', const: 'Multiplo 8' },
  { title: 'Multiplo 10', const: 'Multiplo 10' },
]

export const accommodationDentalTypesBradesco = [
  { title: 'Multiplo 1X Tabela', const: 'Multiplo 1X Tabela' },
  { title: 'Multiplo 3X Tabela', const: 'Multiplo 3X Tabela' },
  { title: 'Multiplo 4X Tabela', const: 'Multiplo 4X Tabela' },
  { title: 'Multiplo 5X Tabela', const: 'Multiplo 5X Tabela' },
]
