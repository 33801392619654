import { EditInfoButton, ExternalMain, ExternalWrapper, WrapperExternalDiv } from '../style'

export const ManagerB2CPersonType: React.FC = () => {
  return (
    <ExternalMain>
      <ExternalWrapper>
        <WrapperExternalDiv>
          <p>Supervisor B2C</p>
        </WrapperExternalDiv>

        <EditInfoButton>
          <p>
            Supervisor na Operadora:* <span>Artur Padua</span>
          </p>
        </EditInfoButton>

        <EditInfoButton>
          <p>
            CPF do supervisor:* {''}
            <span>33383867889</span>
          </p>
        </EditInfoButton>

        <EditInfoButton>
          <p>
            E-mail do supervisor:* <span>artur.padua@saudebliss.com.br</span>
          </p>
        </EditInfoButton>

        <EditInfoButton>
          <p>
            Telefone do supervisor:* <span>(11) 9 8942-7962</span>
          </p>
        </EditInfoButton>
      </ExternalWrapper>
    </ExternalMain>
  )
}
