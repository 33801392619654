import { useEffect, useState } from 'react'

import { CheckBox, SearchInput, Spinner } from 'design-system/components'

import { useFiltersBrokersQuery } from '@/App/clients/healthPlansOrders/queries/filtersBrokers'

import { Action } from '../..'
import { BrokerFilterContent, BrokersDiv, LoadingDiv, SearchInputWrapper } from './style'

interface Props {
  handleRequest: (action: Action, filterValues: [{ key: string; value: string }]) => void
  selectedBrokers: string[]
}

export const BrokerFilter = ({ handleRequest, selectedBrokers }: Props) => {
  const filtersBrokersQuery = useFiltersBrokersQuery()
  const [filteredBrokers, setFilteredBrokers] = useState(
    filtersBrokersQuery.data?.brokersOptions || [],
  )

  useEffect(() => {
    if (filtersBrokersQuery.isSuccess) {
      setFilteredBrokers(filtersBrokersQuery.data.brokersOptions)
    }
  }, [filtersBrokersQuery.isSuccess, filtersBrokersQuery.data?.brokersOptions])

  const handleChange = (event) => {
    const action = event.target.checked ? Action.AddFilter : Action.RemoveFilter

    handleRequest(action, [{ key: 'brokerId', value: event.target.value }])
  }

  return (
    <>
      <SearchInputWrapper>
        <SearchInput
          options={filtersBrokersQuery.data?.brokersOptions}
          placeholder="Buscar corretor"
          onSelect={(_value, selected) =>
            setFilteredBrokers([{ label: selected.label, value: selected.reference }])
          }
          onChange={(value) => {
            if (!value) {
              setFilteredBrokers(filtersBrokersQuery.data?.brokersOptions ?? [])
            }
          }}
        />
      </SearchInputWrapper>
      <BrokersDiv>
        {filtersBrokersQuery.isLoading ? (
          <LoadingDiv>
            <Spinner />
          </LoadingDiv>
        ) : (
          filteredBrokers?.map((option, index) => {
            return (
              <BrokerFilterContent key={index}>
                <CheckBox
                  onChange={(e) => handleChange(e)}
                  label={option.label}
                  value={option.value ?? ''}
                  name="brokerId"
                  checked={selectedBrokers?.includes(option.value ?? '') ? true : false}
                />
              </BrokerFilterContent>
            )
          })
        )}
      </BrokersDiv>
    </>
  )
}
