import styled from 'styled-components'

export const SiswebFilterContent = styled.div`
  margin-top: 10px;
`
export const SearchInputWrapper = styled.div`
  .ant-select-show-search {
    width: 100% !important;
  }
`

export const LoadingDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const SiswebDiv = styled.div`
  margin-top: 15px;
  max-height: 300px;
  overflow: auto;
  padding-left: 5px;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dcdfeb;
    border-radius: 10px;
  }
`
