import styled from 'styled-components'

export const StyledDispatchInProgress = styled.div`
  display: flex;
  column-gap: 5px;
`
export const IconWrapper = styled.div`
  margin-top: 5px;
`
export const TitleStatus = styled.span`
  color: #485964;
  font-size: 10px;
  font-weight: 600;
`
export const SubtitleStatus = styled(TitleStatus)`
  color: #879dac;
`
export const StyledDescriptionError = styled.div`
  max-width: 350px;
`
export const StyledDescriptionSuccess = styled.div`
  display: flex;
  flex-direction: column;
`
