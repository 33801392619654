export const colors = {
  primary: '#601A5A',
  purple: '#53154E',
  darkPurple: '#514984',
  orange: '#E88741',
  green: '#2C7680',
  blue: '#057BDD',
  red: '#BF3232',
  white: '#FFFFFF',
  gray: '#879DAC',
  lightPink: '#f1c7c1',
  placeholder: '#879DAC',
  primaryText: '#4B4B4B',
  secundaryText: '#858AA3',
  icon: '#8589A2',
  mouseHover: '#F6F6F9',
  borderMouseHover: '#000000',
}
