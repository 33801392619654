import { useState } from 'react'

import { CheckBox } from 'design-system/components'

import { Action } from '../..'
import { ColumnBody, ColumnHeader } from './style'

interface Props {
  handleRequest: (action: Action, filterValues: [{ key: string; value: string }]) => void
  selectedStatuses: string[]
}

export const StatusFilter = ({ handleRequest, selectedStatuses }: Props) => {
  const [isAllStatusSelected, setIsAllStatusSelected] = useState(false)
  const checkboxOptions = [
    {
      label: 'Em preenchimento',
      value: 'FILLING_IN_PROGRESS',
      name: 'status',
    },
    {
      label: 'Oportunidade preenchida',
      value: 'OPPORTUNITY_FILLED',
      name: 'status',
    },
    {
      label: 'Preenchido',
      value: 'FILLED',
      name: 'status',
    },
    {
      label: 'Em análise de risco',
      value: 'RISK_ANALYSIS',
      name: 'status',
    },
    {
      label: 'Aprovado para emissão',
      value: 'DISPATCH_APPROVED',
      name: 'status',
    },
    {
      label: 'Em emissão na operadora',
      value: 'INSURER_PROCESSING',
      name: 'status',
    },
    {
      label: 'Pendente emissão',
      value: 'PENDING_DISPATCH',
      name: 'status',
    },
    {
      label: 'Cotação Operadora',
      value: 'QUOTATION_INSURER',
      name: 'status',
    },
    {
      label: 'Em emissão automática',
      value: 'DISPATCH_IN_PROGRESS',
      name: 'status',
    },
    {
      label: 'Preenchimento DS e Aceite',
      value: 'CUSTOMER_ACCEPTANCE',
      name: 'status',
    },
    {
      label: 'Liberar na operadora',
      value: 'READY_TO_DISPATCH',
      name: 'status',
    },
    {
      label: 'Proposta em análise',
      value: 'ANALYSIS',
      name: 'status',
    },
    {
      label: 'Entrevista qualificada',
      value: 'QUALIFIED_INTERVIEW',
      name: 'status',
    },
    {
      label: 'Pendência na proposta',
      value: 'FILLING_NOT_MEET_REQUIREMENTS',
      name: 'status',
    },
    {
      label: 'Data de vigência pendente',
      value: 'EFFECTIVE_DATE_PENDING',
      name: 'status',
    },
    {
      label: 'Assinatura do contrato',
      value: 'WAITING_SIGNATURE',
      name: 'status',
    },
    {
      label: 'Pendente liberar boleto',
      value: 'WAITING_BILL',
      name: 'status',
    },
    {
      label: 'Boleto pendente pgto',
      value: 'BILL_PAYMENT_PENDING',
      name: 'status',
    },
    {
      label: 'Proposta implantada',
      value: 'HIRED',
      name: 'status',
    },
    {
      label: 'Proposta cancelada',
      value: 'CANCELED',
      name: 'status',
    },
    {
      label: 'Status não mapeado',
      value: 'NOT_MAPPED',
      name: 'status',
    },
  ]

  const handleSelectionChange = (event) => {
    setIsAllStatusSelected(false)
    const action = event.target.checked ? Action.AddFilter : Action.RemoveFilter

    handleRequest(action, [{ key: 'status', value: event.target.value }])
  }

  const handleAllChange = (event) => {
    setIsAllStatusSelected(event.target.checked)

    const action = Action.ReplaceFilter

    handleRequest(action, [{ key: 'status', value: '' }])
  }
  return (
    <>
      <ColumnHeader>
        <CheckBox
          onChange={handleAllChange}
          label="Todos"
          value="ALL_STATUS"
          checked={isAllStatusSelected}
        />
      </ColumnHeader>
      <ColumnBody>
        {checkboxOptions.map((option) => {
          return (
            <CheckBox
              key={option.value}
              onChange={handleSelectionChange}
              label={option.label}
              value={option.value}
              name={option.name}
              checked={selectedStatuses?.includes(option.value)}
            />
          )
        })}
      </ColumnBody>
    </>
  )
}
