import type { GetSchemaProps } from '..'
import { companyAmilSchema } from './amil'
import { companyBradescoSchema } from './bradesco'
import { companyDefaultSchema } from './default'
import { companyGndiSchema } from './gndi'
import { companyPortoSeguroSchema } from './portoSeguro'
import { companySulamericaSchema } from './sulamerica'

const companySchemas = {
  amil: companyAmilSchema,
  gndi: companyGndiSchema,
  portoSeguro: companyPortoSeguroSchema,
  sulamerica: companySulamericaSchema,
  bradesco: companyBradescoSchema,
  default: companyDefaultSchema,
}

export function getCompanySchema({ insurer, isOcrFilled, isRegisterInInsurer }: GetSchemaProps) {
  if (isRegisterInInsurer || !insurer) {
    return companySchemas.default({ isOcrFilled })
  }

  const schema = companySchemas[insurer] || companySchemas.default

  return schema({ isOcrFilled })
}
