export const insurersOdontoSulamerica = [
  { value: '34', label: 'AMIL DENTAL' },
  { value: '35', label: 'BRADESCO DENTAL' },
  { value: '58', label: 'CAIXA SEGUROS' },
  { value: '36', label: 'CARE PLUS' },
  { value: '37', label: 'CROWN ODONTO' },
  { value: '7', label: 'ENTAL CARE' },
  { value: '8', label: 'ENTAL GOLD' },
  { value: '38', label: 'DENTALPAR' },
  { value: '60', label: 'DENTSIM' },
  { value: '16', label: 'GOLDEN CROSS' },
  { value: '40', label: 'GREEN CARD' },
  { value: '57', label: 'HAPVIDA' },
  { value: '41', label: 'INPAL' },
  { value: '42', label: 'INTERODONTO' },
  { value: '43', label: 'MAIS ODONTO' },
  { value: '44', label: 'METLIFE' },
  { value: '6', label: 'DONTOPREV' },
  { value: ':5', label: 'ODONTO S.A' },
  { value: '61', label: 'ODONTO SHARING' },
  { value: '46', label: 'ODONTO SYSTEM' },
  { value: '54', label: 'OMINT' },
  { value: '5', label: 'OUTROS' },
  { value: '48', label: 'PORTO SEGURO' },
  { value: '49', label: 'PREVIDENT' },
  { value: '55', label: 'PRIVIAN' },
  { value: '50', label: 'PRODENT' },
  { value: ':1', label: 'SÃO FRANCISCO ODONTOLOGIA' },
  { value: '10', label: 'SEGURO NOVO' },
  { value: '52', label: 'SEMPRE ODONTO' },
  { value: '56', label: 'SESI' },
  { value: '72', label: 'SULAMÉRICA' },
  { value: '53', label: 'UNIMED ODONTO' },
  { value: '66', label: 'UNIODONTO' },
]
