import { QueryFunctionContext, queryOptions } from '@tanstack/react-query'

import { api } from '../../http'
import { GetCatalogProductsDto } from '../dtos'

type GetCatalogAdministratorBaseRequest = {
  modalityId?: string | null
}

type GetCatalogAdministratorRequest = QueryFunctionContext & GetCatalogAdministratorBaseRequest

async function getCatalogAdministrator({ signal, modalityId }: GetCatalogAdministratorRequest) {
  const response = await api.get<GetCatalogProductsDto>(
    `${import.meta.env.VITE_CATALOGO_API_URL}/administradoras`,
    {
      signal,
      headers: { Authorization: localStorage.getItem('authToken') },
      params: {
        hasModalidadeId: modalityId,
      },
    },
  )
  return response.data
}

export function getCatalogAdministratorOptions({
  modalityId,
  enabled = false,
}: GetCatalogAdministratorBaseRequest & { enabled?: boolean }) {
  return queryOptions({
    queryKey: ['catalog', 'administrators', { modalityId }] as const,
    queryFn: (params) => getCatalogAdministrator({ ...params, modalityId }),
    enabled,
    refetchOnWindowFocus: false,
    select(data) {
      return data.data
        .map((product) => ({
          label: product.nome,
          value: product.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    },
  })
}
