import { StyledTag } from './styles'

export interface TagProps{
    children: string;
    borderColor?: string;
    textColor?: string;
}

const Tag: React.FC<TagProps> = ({children, borderColor, textColor}) => (
    <StyledTag borderColor={borderColor} textColor={textColor}> {children} </StyledTag>
);

export default Tag;