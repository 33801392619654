import { Modal } from 'antd'
import styled from 'styled-components'
import { colors } from '../../styles/theme'

export const StyledModal = styled(Modal)`
  &&& {
    .ant-modal-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`

export const StyledIcon = styled.div`
  svg {
    width: 2.5em;
    height: 2.5em;
    color: #9399ab;
  }
`

export const StyledTitle = styled.p`
  font-size: large;
  font-weight: 700;
  color: ${colors.primaryText};
  font-size: 20px;
`
export const StyledDescription = styled.p`
  color: #878da1;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`

export const StyledClickText = styled.p`
  font-size: 16px;
  color: ${colors.primaryText};
  font-weight: 700;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
`
