import { useMemo } from 'react'

import { Button, Modal } from 'design-system/components'
import Exclamation from 'design-system/exclamation'
import { triggerToast } from 'design-system/triggers'

import type { Entity, GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import { useDispatchPersonEvent } from '@/App/clients/orderGateway/mutations/dispatchPersonEvent'
import { useDynamicForm } from '@/App/contexts/dynamicForm/DynamicFormProvider'

import type { EntityType } from '../..'
import * as S from './style'

interface DispatchEntityIncludeEventConfirmationModalProps {
  entity?: Entity | null
  orderData?: GetOrderInformationBody | null
  entityType: EntityType
}

export const DispatchEntityIncludeEventConfirmationModal = ({
  entity,
  entityType,
  orderData,
}: DispatchEntityIncludeEventConfirmationModalProps) => {
  const { entityInclusionDispatchModal, setEntityInclusionDispatchModal } = useDynamicForm()
  const dispatchEntityIncludeEventMutation = useDispatchPersonEvent({ entityId: entity?.id })

  const holder = useMemo(() => {
    const findHolder = orderData?.holders?.find((holder) =>
      holder.dependents?.some((dependent) => dependent.id === entity?.id),
    )

    if (!findHolder) {
      return null
    }

    return {
      cpf: findHolder.cpf ?? null,
      address: findHolder.address ?? null,
    }
  }, [orderData?.holders])

  async function handleDispatchEntityIncludeEvent() {
    setEntityInclusionDispatchModal({ isOpen: false })
    await dispatchEntityIncludeEventMutation.mutateAsync({
      type: 'INCLUSION',
      isDispatchedManually: entityInclusionDispatchModal.origin === 'INTRANET',
      entities: [
        {
          ...entity,
          ...(entityType === 'DEPENDENT' ? { relatedHolder: holder } : {}),
        },
      ],
      insurer: orderData?.quotation?.insurer,
      entityType,
      company: {
        document: orderData?.company?.document,
      },
      quotation: {
        isCombinedProposal: orderData?.quotation?.isCombinedProposal,
        type: orderData?.quotation?.product,
      },
    })
    triggerToast(true, 'Inclusão/exclusão de vida solicitada com sucesso!')
  }

  return (
    <Modal
      isOpen={entityInclusionDispatchModal.isOpen}
      width={677}
      setIsOpen={() => setEntityInclusionDispatchModal({ isOpen: false })}
      footer={false}
    >
      <S.ModalContent>
        <S.Icon>
          <Exclamation />
        </S.Icon>
        <S.ModalHeader>
          <S.ModalTitle>
            {entityInclusionDispatchModal.origin === 'INTRANET'
              ? 'Tem certeza que deseja incluir manualmente esse beneficiário?'
              : 'Tem certeza que deseja solicitar a inclusão/exclusão de beneficiários?'}
          </S.ModalTitle>
          <S.ModalSubTitle>
            {entityInclusionDispatchModal.origin === 'INTRANET'
              ? 'Ao confirmar, não será mais possível desmarcar essa opção. Certifique-se de que as informações do beneficiário estão corretas e que você deseja realmente prosseguir com esta inclusão manual'
              : 'Ao confirmar, o robô da Bliss automaticamente realizará a modificação de beneficiários no portal da operadora. Certifique-se de que todos os campos obrigatórios estão preenchidos corretamente.'}
          </S.ModalSubTitle>
        </S.ModalHeader>

        <S.ActionContainer>
          <Button
            onClick={handleDispatchEntityIncludeEvent}
            loading={dispatchEntityIncludeEventMutation.isPending}
          >
            Solicitar inclusão/exclusão
          </Button>
          <S.CancelButton
            onClick={() => setEntityInclusionDispatchModal({ isOpen: false })}
            disabled={dispatchEntityIncludeEventMutation.isPending}
          >
            Cancelar
          </S.CancelButton>
        </S.ActionContainer>
      </S.ModalContent>
    </Modal>
  )
}
