import styled from 'styled-components'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { colors } from '../../styles/theme'

export interface StyledProps {
  isExternal?: boolean
  isMobile?: boolean
}

export const StyledFrame = styled.div`
  display: grid;
  grid-template-columns: 0fr auto;
  height: 100vh;
`

export const StyledHeader = styled.div<StyledProps>`
  width: 98%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => !props.isExternal && 'end'};
`
export const StyledHeaderMobile = styled.div`
  width: 98%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const FrameContent = styled.div`
  height: 100%;
  position: relative;
  overflow: auto;
`

export const StyledTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #878da1;
  display: flex;
  justify-content: center;
  width: 85%;
`

export const StyledButton = styled.button<StyledProps>`
  border: none;
  background: none;
  font-size: 13px;
  width: 75px;
  margin-left: ${(props) => !props.isMobile && '20px'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  color: #4b4b4b;
`

export const StyledArrow = styled(ArrowLeftOutlined)<StyledProps>`
  color: #8589a2;
  font-size: ${(props) => (props.isExternal ? '20px' : '15px')};
`

export const StyledImg = styled.img`
  margin-right: 10px;
  border-radius: 10px;
`
export const StyledDivLogo = styled.div<StyledProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  span {
    color: ${colors.darkPurple};
    font-size: 12px;
    font-weight: 600;
  }
`
export const StyledLogo = styled.div`
  align-items: center;
  color: ${colors.darkPurple};
  display: flex;
  font-size: 32px;
  font-weight: 700;
  height: 40px;
  justify-content: center;
  width: 50px;
  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
`
