import { useMutation, useQueryClient } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '../../http'
import { StatusMappingExecutionDto } from '../dtos'
import {
  statusMappingExecutionInfiniteQueryOptions,
  statusMappingExecutionQueryOptions,
} from '../queries/statusMappingExecutions'

export type MapStatusData = {
  statusMappingExecution: StatusMappingExecutionDto
  internalStatus?: string
}

async function mapStatus({ internalStatus, statusMappingExecution }: MapStatusData) {
  const url = `${
    import.meta.env.VITE_ORDER_INFORMATION_PROCESSING_URL
  }/api/v1/status-mapping-executions/${statusMappingExecution.id}`

  return api.patch(
    url,
    { internalStatus },
    {
      headers: {
        Authorization: localStorage.getItem('authToken'),
      },
    },
  )
}

export function useMapStatusMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['statusMappingExecution'] as const,
    mutationFn: async (data: MapStatusData) => mapStatus(data),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['statusMappingExecutions'] })
    },
    onError: () => {
      triggerToast(false, 'Ops, ocorreu um erro ao mapear o status')
    },
    onSuccess: (_, variables) => {
      triggerToast(true, 'Status mapeado com sucesso!')

      queryClient.setQueryData(
        statusMappingExecutionQueryOptions({
          isMapped: false,
        }).queryKey,
        (old) => {
          if (!old) {
            return old
          }

          return {
            ...old,
            statusMappingExecutions: old.statusMappingExecutions.filter(
              (statusMappingExecution) =>
                statusMappingExecution.id !== variables.statusMappingExecution.id,
            ),
          }
        },
      )

      queryClient.setQueryData(
        statusMappingExecutionInfiniteQueryOptions({
          isMapped: true,
        }).queryKey,
        (old) => {
          if (!old) {
            return old
          }

          const newStatusMappingExecutions = Object.assign(old.pages[0], {
            statusMappingExecutions: [
              {
                ...variables.statusMappingExecution,
                key: variables.statusMappingExecution.id,
                internalStatus: variables.internalStatus,
              },
              ...old.pages[0].statusMappingExecutions,
            ],
          })

          return {
            ...old,
            pages: [newStatusMappingExecutions, ...old.pages],
          }
        },
      )
    },
    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: [statusMappingExecutionQueryOptions({}).queryKey],
      })
    },
  })
}
