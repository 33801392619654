const PurpleCheckIcon = ({ fill = '#514984' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="none" viewBox="0 0 20 16">
      <path
        fill={fill}
        d="M6.793 15.164l-6.5-6.5a1 1 0 010-1.414l1.414-1.414a1 1 0 011.414 0L7.5 10.214 16.879.836a1 1 0 011.414 0l1.414 1.414a1 1 0 010 1.414l-11.5 11.5a1 1 0 01-1.414 0z"
      ></path>
    </svg>
  )
}

export default PurpleCheckIcon
