export const insurersBradesco = [
  { label: 'ALLIANZ SAUDE S/A', value: 'ALLIANZ SAUDE S/A' },
  {
    label: 'AMIL ASSISTENCIA MEDICA INTERNACIONAL S A',
    value: 'AMIL ASSISTENCIA MEDICA INTERNACIONAL S A',
  },
  {
    label: 'BRADESCO SAUDE OPERADORA DE PLANOS SA',
    value: 'BRADESCO SAUDE OPERADORA DE PLANOS SA',
  },
  { label: 'BRADESCO SAUDE S/A', value: 'BRADESCO SAUDE S/A' },
  {
    label: 'CAIXA SEGURADORA ESPECIALIZADA EM SAÚDE S/A',
    value: 'CAIXA SEGURADORA ESPECIALIZADA EM SAÚDE S/A',
  },
  { label: 'CARE PLUS MEDICINA ASSISTENCIAL LTDA', value: 'CARE PLUS MEDICINA ASSISTENCIAL LTDA' },
  { label: 'CASSI - BANCO DO BRASIL', value: 'CASSI - BANCO DO BRASIL' },
  {
    label: 'CLINIPAM CLÍNICA PARANAENSE DE ASSISTÊNCIA MÉDICA LTDA',
    value: 'CLINIPAM CLÍNICA PARANAENSE DE ASSISTÊNCIA MÉDICA LTDA',
  },
  {
    label: 'GOLDEN CROSS ASSISTENCIA INTERNACIONAL DE SAUDE',
    value: 'GOLDEN CROSS ASSISTENCIA INTERNACIONAL DE SAUDE',
  },
  { label: 'LINCX', value: 'LINCX' },
  { label: 'MARITIMA SA DE SEGUROS S/A', value: 'MARITIMA SA DE SEGUROS S/A' },
  {
    label: 'MEDISERVICE ADM DE PLANOS DE SAUDE S.A.',
    value: 'MEDISERVICE ADM DE PLANOS DE SAUDE S.A.',
  },
  { label: 'NOTRE DAME INTERMEDICA SAUDE S.A.', value: 'NOTRE DAME INTERMEDICA SAUDE S.A.' },
  { label: 'OMINT SERVICOS DE SAUDE LTDA', value: 'OMINT SERVICOS DE SAUDE LTDA' },
  { label: 'ONE HEALTH', value: 'ONE HEALTH' },
  {
    label: 'PORTO SEGURO COMPANHIA DE SEGUROS GERAIS',
    value: 'PORTO SEGURO COMPANHIA DE SEGUROS GERAIS',
  },
  { label: 'SOMPO SAUDE SEGUROS S.A.', value: 'SOMPO SAUDE SEGUROS S.A.' },
  { label: 'SUL AMERICA SERVICOS DE SAUDE S.A.', value: 'SUL AMERICA SERVICOS DE SAUDE S.A.' },
  { label: 'UNIMED SEGUROS SAUDE S/A', value: 'UNIMED SEGUROS SAUDE S/A' },
]
