import { colors } from '@design-system/styles/theme'
import styled from 'styled-components'

export const ModalActionButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`
export const DownloadDocumentsModalCancelButtonDiv = styled.div`
  width: 200px;
`

export const DownloadDocumentsModalSaveButtonDiv = styled.div`
  width: 200px;
`

export const TableHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  p {
    margin: 3px;
    color: #4b4b4b;
  }
`

export const NameHeader = styled.div`
  display: flex;
  width: 30%;
`

export const TypeHeader = styled.div`
  display: flex;
  width: 60%;
`

export const TableBody = styled.table`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  border: 1px solid #cbcedc;
  overflow-y: auto;

  width: 100%;

  tr:hover {
    background-color: ${colors.mouseHover};
    cursor: pointer;
  }

  p {
    margin: 0;
    border-top: 1px solid #cbcedc;
  }

  button {
    border: none;
    border-radius: 8px;
    height: 25px;
    background-color: #878da1;
    color: white;
  }

  tr {
    display: flex;
    justify-content: space-between;
  }
`

export const TdFileName = styled.td`
  padding: 5px 20px;
`

export const TdFileType = styled.td`
  width: 50%;
  align-self: center;
`

export const TdActions = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
`

export const FilePreviewDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 300px;
  gap: 30px;
  align-items: center;
`

export const PreviewImageDiv = styled.div`
  border: 1px solid #cbcedc;
  border-radius: 10px;
  width: 45px;
  padding: 14px 0;
`
