const LabelCheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <g clipPath="url(#clip0_1_3632)">
        <path
          fill="#8589A2"
          d="M1.667 17.5h12.5c.575 0 1.025-.292 1.325-.733L20 10l-4.508-6.758c-.3-.442-.75-.742-1.325-.742h-12.5C.75 2.5 0 3.25 0 4.167v11.666C0 16.75.75 17.5 1.667 17.5z"
        ></path>
        <g clipPath="url(#clip1_1_3632)">
          <path
            fill="#FEFEFE"
            d="M6.396 13.582l-3.25-3.25a.5.5 0 010-.707l.708-.707a.5.5 0 01.707 0l2.189 2.19 4.69-4.69a.5.5 0 01.707 0l.707.707a.5.5 0 010 .707l-5.75 5.75a.5.5 0 01-.708 0z"
          ></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1_3632">
          <path fill="#fff" d="M0 0H20V20H0z" transform="rotate(-180 10 10)"></path>
        </clipPath>
        <clipPath id="clip1_1_3632">
          <path fill="#fff" d="M0 0H10V10H0z" transform="rotate(-180 6.5 7.5)"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default LabelCheckIcon
