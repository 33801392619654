import styled from 'styled-components'

export const FullWidthContainer = styled.div`
  /* Mozilla */
  width: -moz-available;

  /* Chrome */
  width: -webkit-fill-available;
`

export const SubtitleText = styled.h4`
  font-family: Manrope, sans-serif;
  font-weight: 600;
  color: rgb(72, 89, 100);
  margin: 2rem 1rem 0.5rem;
`

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  display: flex;
  padding: 14px;
`

export const FormDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FormLabel = styled.span`
  font-family: Manrope, sans-serif;
  font-weight: 600;
  color: rgb(72, 89, 100);
  font-size: 1rem;
  text-align: start;
`

export const FormRow = styled.div`
  margin: 5px 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const StatusTagContainer = styled.div`
  margin: 5px -10px 5px 0px;
`

export const AriaLabel = styled.span`
  color: #878da1;
  text-align: right;
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  line-height: 2rem;
`

export const FormSelectorContainer = styled.div`
  width: 50%;
`

export const SelectOptionLabel = styled.span`
  margin-left: 8px;
`

export const SaveButtonContainer = styled.div`
  margin-top: 30px;
`
