import styled, { keyframes } from 'styled-components'
import { slideOutDown, slideInUp } from 'react-animations'
import { CloseOutlined } from '@ant-design/icons'

const slideInAnimation = keyframes`${slideInUp}`
const slideOutAnimation = keyframes`${slideOutDown}`

export interface StyledProps {
  isOpen?: boolean
  blurBackground?: boolean
  closeButtonPosition?: 'left' | 'right'
}

export const MainContainer = styled.div<StyledProps>`
  height: 100vh;
  width: 100vw;
  position: absolute;
  inset: 0;
  z-index: 1000;
  position: fixed;
  background-color: ${(props) => props.blurBackground && props.isOpen && 'rgba(19, 19, 19, 0.70)'};
  position: absolute;
  pointer-events: none;
  overflow-x: hidden;
  overflow-y: hidden;
`

export const StyledSwipeableDrawer = styled.footer<StyledProps>`
  background-color: #fff;
  box-shadow: 0 0 10px rgba(17, 19, 47, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  width: 100%;
  animation: 1s ${(props) => (props.isOpen ? slideInAnimation : slideOutAnimation)};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: all;
  overflow-y: hidden;
  overflow-x: hidden;
  max-height: 90%;
`

export const StyledHeader = styled.div<StyledProps>`
  background-color: #f2f3f7;
  width: 100%;
  height: 2.5em;
  display: flex;
  justify-content: ${(props) => (props.closeButtonPosition === 'right' ? 'right' : 'left')};
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`

export const StyledCloseOutlined = styled(CloseOutlined)`
  color: #9399ab;
  margin: 10px;
`

export const StyledButton = styled.div`
  border: none;
  margin-top: 10px;
  height: 5px;
  border-radius: 200px;
  width: 25px;
  background-color: #878da1;
`

export const StyledContainer = styled.div<StyledProps>`
  overflow-y: ${(props) => (props.isOpen ? 'scroll' : 'hidden')};
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  margin-left: ${(props) => !props.isOpen && '20px'};
`
