import { GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'

import { getRequiredEntityFields } from '../helper'

export const getRequiredFieldsQuotationSulamerica = (orderData?: GetOrderInformationBody) => {
  const dynamicFormValidator: Array<{
    dynamicFormId?: string | null | undefined
    requiredFields: Array<string | null>
  } | null> = [
    getRequiredEntityFields(orderData?.quotation, [
      'hiringType',
      'isCoparticipation',
      'coverageType',
      'contractualTerm',
    ]),
  ]
  return dynamicFormValidator.filter((value) => value)
}
