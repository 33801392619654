import { Checkbox } from 'antd'
import styled from 'styled-components'

interface StyledCheckboxProps {
  color?: string
}
export const StyledCheckbox = styled(Checkbox)<StyledCheckboxProps>`
  &.ant-checkbox-wrapper {
    color: #4b4b4b;
    margin-inline-start: 0px;

    @media screen and (max-width: 768px) {
      display: flex;
      align-items: center;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${(props) => props.color};
      border-color: ${(props) => props.color};
    }

    .ant-checkbox-checked::after {
      border-color: ${(props) => props.color};
    }

    &:hover .ant-checkbox-inner {
      border-color: ${(props) => props.color};
    }

    &:focus-within .ant-checkbox-inner {
      box-shadow: 0 0 0 2px ${(props) => props.color};
    }

    &:not(.ant-checkbox-wrapper-disabled):hover
      .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
      border-color: ${(props) => props.color};
    }
  }

  &&& .ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: ${(props) => props.color} !important;
    border-color: ${(props) => props.color} !important;
  }
`
