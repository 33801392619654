const CheckCircleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <circle cx="10" cy="10" r="10" fill="#42A859"></circle>
      <path
        fill="#fff"
        d="M8.396 13.843l-3.25-3.487a.564.564 0 010-.758l.708-.759a.476.476 0 01.707 0l2.189 2.349 4.69-5.03a.476.476 0 01.707 0l.707.758c.195.21.195.549 0 .758l-5.75 6.169a.476.476 0 01-.708 0z"
      ></path>
    </svg>
  )
}

export default CheckCircleIcon
