import { useState } from 'react'

import { insurers } from '@design-system/enum/insurers'
import { CheckBox, SearchInput } from 'design-system/components'

import { Action } from '../..'
import { ColumnBody, SearchInputWrapper } from './style'

interface Props {
  handleRequest: (action: Action, filterValues: [{ key: string; value: string }]) => void
  selectedInsurers: string[]
}

export const InsurerFilter = ({ handleRequest, selectedInsurers }: Props) => {
  const [insurersOptions, setInsurersOptions] = useState(insurers)

  const handleChange = (event) => {
    const action = event.target.checked ? Action.AddFilter : Action.RemoveFilter

    handleRequest(action, [{ key: 'insurer', value: event.target.value }])
  }

  return (
    <>
      <SearchInputWrapper>
        <SearchInput
          onSelect={(_value, selected) =>
            setInsurersOptions([{ label: selected.label, value: selected.reference }])
          }
          onChange={(value) => {
            if (!value) setInsurersOptions(insurers)
          }}
          options={insurersOptions}
          placeholder="Buscar operadora"
        />
      </SearchInputWrapper>
      <ColumnBody>
        {insurersOptions.map((option) => {
          return (
            <CheckBox
              key={option.value}
              onChange={(e) => handleChange(e)}
              label={option.label}
              value={option.value}
              name={option.label}
              checked={selectedInsurers?.includes(option.value)}
            />
          )
        })}
      </ColumnBody>
    </>
  )
}
