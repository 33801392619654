import styled from 'styled-components'

export const MarketingAssetsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const UploadFilesAreaTitle = styled.p`
  font-size: 40px;
`

export const UploadFilesAreaSubtitle = styled.p`
  font-size: 30px;
`

export const UploadAreaDiv = styled.div`
  display: flex;
  flex-direction: column;
`

export const FileLabel = styled.p`
  font-size: 25px;
  text-align: center;
`

export const UploadAreaButtonDiv = styled.div`
  display: flex;
  width: 30vw;
  margin-top: 50px;
`
