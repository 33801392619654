const LabelXIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <g clipPath="url(#clip0_1_3630)">
        <path
          fill="#8589A2"
          d="M1.667 17.5h12.5c.575 0 1.025-.292 1.325-.733L20 10l-4.508-6.758c-.3-.442-.75-.742-1.325-.742h-12.5C.75 2.5 0 3.25 0 4.167v11.666C0 16.75.75 17.5 1.667 17.5zm2.5-10.492l1.175-1.175 2.991 2.992 2.992-2.992L12.5 7.008 9.508 10l2.992 2.992-1.175 1.175-2.992-2.992-2.991 2.992-1.175-1.175L7.158 10 4.167 7.008z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1_3630">
          <path fill="#fff" d="M0 0H20V20H0z" transform="rotate(-180 10 10)"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default LabelXIcon
