import { HttpVerbs } from '../http/enum'
import { UseFetchConfig } from '../http/interface'
import {
  BrokersFilters,
  CreateLeadInputDto,
  CreateQuotationInputDto,
  FillLeadInformationBody,
  FillOrderInformationBody,
  FillQuotationInformationBody,
} from './dtos'

export const HealthPlansOrdersRequests = {
  getAdminByToken: (): UseFetchConfig => {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/admins/self`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  createQuotation(
    brokerId: string,
    leadId: string,
    createQuotationData: CreateQuotationInputDto,
  ): UseFetchConfig {
    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/brokers/${brokerId}/leads/${leadId}/quotations`,
      data: createQuotationData,
      method: HttpVerbs.Post,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  createOrder(createOrderData: FormData): UseFetchConfig {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v2/orders`,
      data: createOrderData,
      method: HttpVerbs.Post,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  createLead(brokerId: string, createLeadData: CreateLeadInputDto): UseFetchConfig {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/brokers/${brokerId}/leads`,
      data: createLeadData,
      method: HttpVerbs.Post,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  getQuotationsByBrokerAndLeadId: (brokerId: string, leadId: string): UseFetchConfig => {
    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/brokers/${brokerId}/leads/${leadId}/quotations`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },
  getManagers: (): UseFetchConfig => {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/managers`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  getBrokersByFilters: (filters?: BrokersFilters): UseFetchConfig => {
    const queryParams = new URLSearchParams()

    if (filters)
      Object.keys(filters).forEach((param) => {
        if (param && filters[param] !== undefined) {
          queryParams.append(param, filters[param].toString())
        }
      })

    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/brokers?${queryParams}`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  getLeadsByBrokerId: (brokerId: string, perPage: number, page: number): UseFetchConfig => {
    const queryParams = new URLSearchParams({
      perPage: perPage.toString(),
      page: page.toString(),
    })

    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/brokers/${brokerId}/leads?${queryParams}`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  createStaticMarketingAsset: (data: FormData): UseFetchConfig => {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/admin/static-marketing-assets`,
      method: HttpVerbs.Post,
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  fillLeadInformation: (data: FillLeadInformationBody, brokerId: string, leadId: string) => {
    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/brokers/${brokerId}/leads/${leadId}`,
      method: HttpVerbs.Patch,
      data: data,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  fillOrderInformation: (
    data: FillOrderInformationBody,
    orderId?: string | null,
  ): UseFetchConfig => {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/orders/${orderId}`,
      method: HttpVerbs.Patch,
      data: data,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  fillQuotationInformation: (
    data: FillQuotationInformationBody,
    brokerId: string,
    leadId: string,
    quotationId: string,
  ): UseFetchConfig => {
    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/brokers/${brokerId}/leads/${leadId}/quotations/${quotationId}`,
      method: HttpVerbs.Patch,
      data,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  downloadOrderDocumentByPreviewURL: (previewURL: string): UseFetchConfig => {
    return {
      url: previewURL,
      method: HttpVerbs.Get,
      responseType: 'blob',
    }
  },

  uploadOrderDocumentByUploadURL: (
    uploadURL: string,
    data: Blob,
    fileContentType: string,
  ): UseFetchConfig => {
    return {
      url: uploadURL,
      method: HttpVerbs.Put,
      data,
      headers: {
        'Content-Type': fileContentType,
      },
    }
  },

  uploadOrderDocument: (data: FormData, orderId?: string | null): UseFetchConfig => {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/orders/${orderId}/documents`,
      method: HttpVerbs.Put,
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  uploadDocumentOrderEntity: (
    data: FormData,
    orderId?: string | null,
    entityId?: string | null,
  ): UseFetchConfig => {
    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/orders/${orderId}/entities/${entityId}/document-files`,
      method: HttpVerbs.Put,
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  getOrderById: (orderId?: string | null): UseFetchConfig => {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v2/orders/${orderId}`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  deleteDocument: (orderId: string, entityId: string, documentId: string): UseFetchConfig => {
    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/orders/${orderId}/entities/${entityId}/documents/${documentId}`,
      method: HttpVerbs.Delete,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  deleteOrderDocument: (orderId: string, documentId: string): UseFetchConfig => {
    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/orders/${orderId}/documents/${documentId}`,
      method: HttpVerbs.Delete,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  deleteOrderEntity: (orderId: string, entityId: string): UseFetchConfig => {
    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/orders/${orderId}/entities/${entityId}`,
      method: HttpVerbs.Delete,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  createOrderHolder: (orderId: string): UseFetchConfig => {
    return {
      url: `${import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL}/api/v1/orders/${orderId}/holders`,
      method: HttpVerbs.Post,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  createOrderHolderDependent: (orderId: string, holderId: string): UseFetchConfig => {
    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/orders/${orderId}/holders/${holderId}/dependents`,
      method: HttpVerbs.Post,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  deleteOrderHolder: (orderId: string, holderId: string): UseFetchConfig => {
    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/orders/${orderId}/holders/${holderId}`,
      method: HttpVerbs.Delete,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
    }
  },

  downloadOrderDocument: (orderId: string, documentId: string): UseFetchConfig => {
    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/orders/${orderId}/documents/${documentId}/files`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
      responseType: 'blob',
    }
  },

  downloadEntityDocumentsFiles: (orderId: string, entityId: string): UseFetchConfig => {
    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/orders/${orderId}/entities/${entityId}/files`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
      responseType: 'blob',
    }
  },

  downloadBrokerDocumentFile: (brokerId: string, documentId: string): UseFetchConfig => {
    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_URL
      }/api/v1/brokers/${brokerId}/documents/${documentId}/files`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
      responseType: 'blob',
    }
  },

  downloadOrderDocuments: (
    orderId: string,
    entitiesIds: Array<string | undefined | null>,
  ): UseFetchConfig => {
    const queryParams = new URLSearchParams()

    const parsedEntitiesIds = entitiesIds.filter((entityId) => entityId != null)
    if (parsedEntitiesIds.length) queryParams.append('entityId', parsedEntitiesIds.join(','))

    return {
      url: `${
        import.meta.env.VITE_HEALTH_PLANS_ORDERS_GET_ORDER_DOCUMENTS_URL
      }/orders/${orderId}/document-files?${queryParams}`,
      method: HttpVerbs.Get,
      headers: {
        Authorization: localStorage.getItem('authToken') ?? '',
      },
      responseType: 'blob',
    }
  },
}
