const GroupIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" fill="none" viewBox="0 0 20 14">
      <path
        fill="#9B9EB3"
        d="M3 6c1.103 0 2-.897 2-2s-.897-2-2-2-2 .897-2 2 .897 2 2 2zm14 0c1.103 0 2-.897 2-2s-.897-2-2-2-2 .897-2 2 .897 2 2 2zm1 1h-2c-.55 0-1.047.222-1.41.581A4.571 4.571 0 0116.939 11H19c.553 0 1-.447 1-1V9c0-1.103-.897-2-2-2zm-8 0c1.934 0 3.5-1.566 3.5-3.5S11.934 0 10 0a3.498 3.498 0 00-3.5 3.5C6.5 5.434 8.066 7 10 7zm2.4 1h-.26c-.65.313-1.371.5-2.14.5A4.933 4.933 0 017.86 8H7.6A3.6 3.6 0 004 11.6v.9A1.5 1.5 0 005.5 14h9a1.5 1.5 0 001.5-1.5v-.9A3.6 3.6 0 0012.4 8zm-6.99-.419A1.994 1.994 0 004 7H2C.897 7 0 7.897 0 9v1c0 .553.447 1 1 1h2.06a4.583 4.583 0 012.35-3.419z"
      ></path>
    </svg>
  )
}

export default GroupIcon
