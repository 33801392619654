import { colors } from '@design-system/styles/theme'
import styled from 'styled-components'

export const StyledContainerDetailsProposal = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

export const LabelTabsItem = styled.div`
  color: #4b4b4b;
  font-weight: 600;
`

export const PlanCard = styled.div`
  border: 1px solid #dcdfeb;
  border-radius: 10px;
  padding: 14px;
  width: 274px;
  height: fit-content;

  button {
    padding: 15px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(17, 19, 47, 0.25);
    color: #4b4b4b;
    width: 100%;
  }
`

export const PlanCardTitle = styled.h3`
  color: #4b4b4b;
  font-size: 15px;
`

export const StyledUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

export const StyledLi = styled.li`
  color: #878da1;
  font-weight: 600;
`

export const StyledSpanLi = styled.span`
  font-weight: bold;
  color: #4b4b4b;
`

export const ActionButton = styled.button`
  display: block;
  margin: 0 auto;
  padding: 10px;
  border: none;
  border-radius: 30px;
  width: 50%;
  margin-top: 20px;
  background: #514984;
  color: #ffffff;
  font-weight: bold;
  font-family: 'Manrope';

  &:hover {
    cursor: pointer;
    background-color: #d8d8d8;
  }
`
export const IconsDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`

export const StyledSpan = styled.span`
  color: '#4B4B4B';
  font-weight: 700;
  font-size: 13px;
  display: flex;
  align-items: center;

  .ant-switch {
    margin-left: 5px;
  }
`

export const StyledBrokerName = styled.span`
  font-weight: 600;
  color: #878da1;
  margin-left: 5px;
`
export const FillInsurerIdInputDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`
export const FillInsurerIdInputLabel = styled.p`
  font-size: 14px;
`
export const FillInsurerIdInput = styled.input`
  width: 100px;
  height: 20px;
  border: none;
  background-color: #f5f6f9;

  :focus {
    outline: none;
  }
`
export const SaveInsurerIdButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const EditInsurerIdButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  column-gap: 5px;

  p {
    font-size: 14px;
    margin-left: 10px;
    color: ${colors.primaryText};
  }

  :hover {
    cursor: pointer;
    background-color: ${colors.mouseHover};
    border-radius: 10px;
  }

  svg {
    color: #878da1;
  }
`
export const StyledTextInput = styled.div`
  color: #878da1;
`
