import styled from 'styled-components'

import { colors } from '../../../../../../../design-system/src/styles/theme'

export interface FormItemSubtypeProps {
  isVisible?: boolean
}

export const LoadingDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;
  justify-content: center;
`

export const LoadingDivTitle = styled.p`
  font-weight: 700;
  font-size: 25px;
  text-align: center;
  color: #4b4b4b;
  margin-bottom: 0;
`

export const LoadingDivSubtitle = styled.p`
  font-size: 16px;
  text-align: center;
  color: #878da1;
`

export const CreateQuotationDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const CreateQuotationTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #4b4b4b;
`

export const CreateQuotationTags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

export const CreateQuotationTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
  background-color: #f5f6f9;
  border-radius: 10px;
  padding: 0px 14px;
  gap: 4px;

  p {
    color: #878da1;
    font-size: 14px;
  }
`

export const TagTitle = styled.p`
  color: #4b4b4b !important;
  font-weight: 700;
  font-size: 14px;
`

export const FormDiv = styled.form`
  width: 100%;
  max-width: 767px;
  padding-top: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  justify-content: center;
  align-items: center;
`

export const FormRow = styled.div`
  flex-direction: row;
  column-gap: 60px;
  flex-wrap: wrap;

  @media (min-width: 1500px) {
    display: flex;
  }
`

export const FormItem = styled.div<{ colSpan?: number }>`
  grid-column: span ${(props) => props.colSpan || 1};
`

export const FormItemLabel = styled.p`
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: ${colors.primaryText};
  margin: 0;
  text-align: start;
`

export const FormItemRadioRow = styled.div`
  width: 50vw;
`
export const HorizontalLine = styled.div`
  width: 50%;
  height: 1px;
  display: flex;
  margin-bottom: 10px;
  background-color: #dcdfeb;
`
export const FormItemSubtype = styled.div<FormItemSubtypeProps>`
  margin-bottom: 20px;
  flex-direction: column;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
`
export const FieldsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
