export function cepApplyMask(value?: string) {
  if (!value) return
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{5})(\d)/, '$1-$2')
    .slice(0, 9)
}

export function currencyApplyMask(value?: string | number) {
  if (!value) return

  if (typeof value === 'number') {
    value = value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }

  if (value.startsWith('R$')) {
    value = value.substring(3).trim() // remove prefix before applying mask
  }

  value = value
    .replace(/\D+/g, '') // remove all non-numbers
    .replace(/(\d)(\d{2})$/, '$1,$2') // puts the comma before the last 2 digits

  // put dot every 3 digits from right to left
  // but only if there are more than 5 digits to prevent leading dot
  if (value.length > 5) {
    value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }

  return 'R$ ' + value
}

export function cpfApplyMask(value?: string) {
  if (!value) return
  if (value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
    return value
  }

  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{2})$/, '$1-$2')
    .slice(0, 14)
}

export function cnpjApplyMask(value?: string) {
  if (!value) return
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .slice(0, 18)
}

export function rgApplyMask(value?: string) {
  if (!value) return

  value = value.replace(/\D/g, '').slice(0, 9)

  value = value.replace(/(\d{2})(?=\d)/, '$1.')
  value = value.replace(/(\d{2}\.\d{3})(?=\d)/, '$1.')
  value = value.replace(/(\d{2}\.\d{3}\.\d{3})(?=\d)/, '$1-')

  return value
}

export function phoneApplyMask(value?: string) {
  if (!value) return

  value = value.replace(/\D/g, '').slice(0, 11)

  if (value.length > 10) {
    return value.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, '($1) $2 $3-$4')
  }

  return value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3')
}

export function heightApplyMask(value?: string | number): string {
  if (value === undefined || value === null || value === '') return ''

  const coercedValue = String(value)

  const digits = coercedValue.replace(/\D/g, '')

  if (digits.length < 3) return digits

  return digits.slice(0, 3).replace(/^(\d)(\d{2})$/, '$1.$2')
}

export function weightApplyMask(value?: string | number): string {
  if (value === undefined || value === null || value === '') return ''

  const coercedValue = String(value)

  const digits = coercedValue.replace(/\D/g, '')

  if (digits.length < 3) return digits

  return digits
    .replace(/^0+/, '')
    .padStart(3, '0')
    .replace(/^(\d+)(\d{2})$/, '$1.$2')
}
