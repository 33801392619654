import { Entity, GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'

import type { EntityType } from '../..'
import { getRequiredEntityFields } from '../helper'

export const getRequiredFieldsGndi = (orderData?: GetOrderInformationBody) => {
  const dynamicFormValidator: Array<{
    dynamicFormId?: string | null | undefined
    requiredFields: Array<string | null>
  } | null> = [
    getRequiredEntityFields(orderData?.company, [
      // company
      'document',
      'address.zipCode',
      'address.number',
      'registrationStatus',
      'openingDate',
      'type',

      // contact
      'name',
      'phone',
      'mail',
      'contactDocument',

      // externalLegalRepresentative
      'externalLegalRepresentative.name',
      'externalLegalRepresentative.mail',
      'externalLegalRepresentative.cpf',
      'externalLegalRepresentative.phone',
    ]),
    getRequiredEntityFields(orderData?.quotation, [
      'totalAmount',
      'effectiveDate',
      'city',
      'state',
      'isCoparticipation',
    ]),
  ]

  if (orderData?.holders && orderData.holders.length > 0) {
    for (const holder of orderData.holders) {
      const { requiredFields } = getRequiredEntityFieldsGndi('HOLDER', holder, orderData)
      dynamicFormValidator.push(...requiredFields)

      const dependents = holder.dependents ?? []
      for (const dependent of dependents) {
        const { requiredFields } = getRequiredEntityFieldsGndi('DEPENDENT', dependent, orderData)
        dynamicFormValidator.push(...requiredFields)
      }
    }
  }

  return dynamicFormValidator.filter((value) => value)
}

export const getRequiredCompanyFieldsGndi = (orderData?: GetOrderInformationBody) => {
  return [
    orderData?.company?.document,
    orderData?.company?.type,
    orderData?.company?.registrationStatus,
    orderData?.company?.openingDate,
    orderData?.company?.address?.zipCode,
    orderData?.company?.address?.number,

    orderData?.company?.name,
    orderData?.company?.phone,
    orderData?.company?.mail,
    orderData?.company?.contactDocument,

    orderData?.company?.externalLegalRepresentative?.name,
    orderData?.company?.externalLegalRepresentative?.mail,
    orderData?.company?.externalLegalRepresentative?.cpf,
    orderData?.company?.externalLegalRepresentative?.phone,
  ]
}

export const getRequiredHolderGroupFieldsGndi = (orderData?: GetOrderInformationBody) => {
  const holderList = orderData?.holders?.map((_, index) => {
    return [
      orderData?.holders?.at(index)?.maritalStatus,
      orderData?.holders?.at(index)?.gender,
      orderData?.holders?.at(index)?.cpf,
      orderData?.holders?.at(index)?.rg,
      orderData?.holders?.at(index)?.phone,
      orderData?.holders?.at(index)?.mail,
      orderData?.holders?.at(index)?.gracePeriodReduction,
      orderData?.holders?.at(index)?.isAdministrativeLife,
      orderData?.holders?.at(index)?.address?.zipCode,
      orderData?.holders?.at(index)?.healthPlan?.catalogPlanId,
    ]
  })

  const allDependents = getRequiredDependentFieldsGndi(orderData)

  return [...(holderList?.at(0) ?? []), ...allDependents]
}

export const getRequiredDependentFieldsGndi = (orderData?: GetOrderInformationBody) => {
  const dependentList =
    orderData?.holders?.map((holder) =>
      holder?.dependents?.map((dependent: Entity) => [
        dependent.maritalStatus,
        dependent.gender,
        dependent.cpf,
        dependent.rg,
        dependent.holderKinship,
        dependent.phone,
        dependent.gracePeriodReduction,
        dependent.isAdministrativeLife,
      ]),
    ) ?? []

  return dependentList.flat(2)
}

export const getRequiredEntityFieldsGndi = (
  entityType: EntityType,
  entity?: Entity | null,
  orderData?: GetOrderInformationBody,
) => {
  const requiredFields: Array<{
    dynamicFormId?: string | null | undefined
    requiredFields: Array<string | null>
  } | null> = []

  if (entityType === 'HOLDER') {
    requiredFields.push(
      getRequiredEntityFields(entity, [
        'maritalStatus',
        'gender',
        'cpf',
        'rg',
        'phone',
        'mail',
        'isAdministrativeLife',
        'address.zipCode',
        ...(orderData?.quotation?.product === 'HEALTH' || orderData?.quotation?.isCombinedProposal
          ? ['healthPlan.catalogPlanId']
          : []),
        ...(orderData?.quotation?.product === 'DENTAL' || orderData?.quotation?.isCombinedProposal
          ? ['dentalPlan.catalogPlanId']
          : []),
        ...(orderData?.quotation?.type !== 'PERSON' ? ['gracePeriodReduction'] : []),
      ]),
    )
  }

  if (entityType === 'DEPENDENT') {
    requiredFields.push(
      getRequiredEntityFields(entity, [
        'maritalStatus',
        'gender',
        'cpf',
        'rg',
        'holderKinship',
        'phone',
        'gracePeriodReduction',
        'isAdministrativeLife',
      ]),
    )
  }

  return {
    requiredFields,
    hasEmptyValues:
      requiredFields?.some(
        (validator) => validator?.requiredFields && validator.requiredFields.length > 0,
      ) ?? false,
  }
}
