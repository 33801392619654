import styled from 'styled-components'

export interface StatusTagProps {
  status: 'CREATED' | 'PENDING' | 'SUCCESS' | 'ERROR'
}

const StatusColor = {
  CREATED: '#0000000a',
  PENDING: '#ffd591',
  SUCCESS: '#b7eb8f',
  ERROR: '#ffa39e',
}

export const StatusTag = styled.div<StatusTagProps>`
  background-color: ${(props) => StatusColor[props.status]};
  padding: 10px;
  border-radius: 10px;
  text-align: center;
`
