import styled from 'styled-components'

export const AccordionSubtitles = styled.h3`
  margin: 16px 0 0 0;
  color: #4b4b4b;
  font-size: 15px;
  font-weight: 600;
`

export const HorizontalLine = styled.hr`
  height: 1px;
  border-width: 0;
  margin-top: 0;
  background-color: #cbcedc;
  width: 100%;
`
export const StyledWarning = styled.p`
  color: #bf404c;
  margin-bottom: 0;
  font-size: 12px;
`
