import styled from 'styled-components'

export const ColumnHeader = styled.div`
  margin-top: 10px;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #dcdfeb;
    margin-top: 0.7rem;
  }
`

export const ColumnBody = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;
  margin-top: 15px;
  max-height: 300px;
  overflow: auto;
  padding-left: 5px;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dcdfeb;
    border-radius: 10px;
  }
`
