import { TeamOutlined } from '@ant-design/icons'
import { colors } from '@design-system/styles/theme'
import styled from 'styled-components'

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ModalTextArea = styled.textarea`
  width: 100%;
  border-radius: 10px;
  border-color: #b7c8d5;
`
export const ButtonDiv = styled.div`
  margin-top: 40px;
  width: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const CancelButton = styled.button`
  margin-top: 40px;
  border: none;
  background: white;
  border-bottom: 2px solid #4b4b4b;
  padding: 0;
  margin-bottom: 20px;
  color: #4b4b4b;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
`

export const ModalTitle = styled.p`
  color: #4b4b4b;
  font-weight: 600;
`
export const ModalSubTitle = styled.p`
  margin-top: 0;
  color: #878da1;
  text-align: center;
  font-weight: 600;
`
export const UploadAreaDiv = styled.div`
  display: inline;
  width: 100%;
`
export const ResponsibleContent = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`
export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
`
export const SelectContainer = styled.div`
  display: flex;
  width: 251px;
  justify-content: center;
  &&& .ant-select-selector {
    color: #878da1 !important;
    font-weight: 700 !important;
  }
`
export const ResponsibleAdminTag = styled.span`
  align-items: center;
  background: #f5f6f9;
  border-radius: 30px;
  color: #4b4b4b;
  column-gap: 10px;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 15px;
  margin: 5px;
`
export const WithoutResponsibleContent = styled.span`
  font-size: 13px;
  color: ${colors.red};
  font-weight: 700;
  display: flex;
  align-items: center;
  column-gap: 5px;
`
export const StyledTeamOutlined = styled(TeamOutlined)`
  font-size: 30px;
  color: #9194ab;
`
export const StyledIcon = styled.div`
  svg {
    width: 10px;
    height: 10px;
    color: #9399ab;
    cursor: pointer;
  }
`
