import styled from 'styled-components'

export const ModalBody = styled.div`
  padding: 30px;
  & > div {
    margin-top: 1rem;
  }

  .ant-collapse-header-text {
    font-weight: 600;
    font-size: 16px;
    color: #4b4b4b;
  }
`

export const Header = styled.header`
  background-color: #f2f3f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 30px;
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    display: block !important;
  }
`
export const CreatedAt = styled.div`
  font-size: 12px;
  color: gray;
  padding-left: 10px;
`
export const BrokerInfo = styled.div`
  flex-basis: 70%;
  margin: 10px;
`
export const Column = styled.div`
  display: flex;

  & > div {
    flex: 1;
  }

  @media screen and (max-width: 768px) {
    display: block !important;
  }
`
export const BrokerStatus = styled.div`
  flex-basis: 30%;
  display: flex;
  row-gap: 10px;
  flex-direction: column;
  margin-top: 5px;
`
export const SelectDiv = styled.div``

export const IsRegisteredInInternalSystemDiv = styled.div`
  display: flex;
  align-items: center;
`

export const IsRegisteredInInternalSystem = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 1rem;
`
export const InternalSystemIdTextInput = styled.input`
  border: 1px #d8d8d8 solid;
  border-radius: 5px;
  width: 60px;
  text-align: center;
`
export const EditableButton = styled.div`
  &:hover {
    cursor: pointer;
  }
`

export const TextLabelDiv = styled.div`
  height: 38px;
  margin: 5px 0;
`
export const StyledOptionLabel = styled.span`
  margin-left: 8px;
`
export const Container = styled.div`
  padding: 35px;

  & > .ant-select {
    float: right;
  }

  & > table {
    border: 1px solid rgb(212, 216, 229) !important;
    margin-top: 25px !important;
    border-radius: 30px !important;
  }
`
export const FilterDiv = styled.div`
  z-index: 3;
  position: absolute;
  margin: 10px;

  & .ant-tabs-content-holder {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
    color: #4b4b4b !important;
  }

  .ant-tabs-tab-btn {
    font-weight: 600;
    color: #878ea6 !important;
  }
`

export const FilterButton = styled.button<{ isActive: boolean }>`
  padding: 8px 15px 8px 15px;
  border-radius: 30px;
  border: 1px solid #dcdfeb;
  background-color: ${(props) => (props.isActive ? '#514984' : '#FFFFFF')};
  color: ${(props) => (props.isActive ? '#FFF' : '#4B4B4B')};
  & svg {
    margin-right: 5px;
  }
  &:hover {
    cursor: pointer;
    background: ${(props) => (props.isActive ? '#514984' : '#F6F6F9')};
    color: ${(props) => (props.isActive ? '#FFFFFF' : '#4b4b4b')};
  }
`

export const SelectedFilters = styled.span`
  background-color: rgb(245, 246, 249);
  padding: 5px 20px 5px 20px;
  border-radius: 20px;
  font-weight: 700;
  font-size: 13px;
  color: rgb(75, 75, 75);
  margin-left: 10px;
  & svg {
    font-size: 11px;
  }

  &:hover {
    cursor: pointer;
  }
`

export const SkeletonWrapper = styled.div`
  margin-bottom: 10px;
`

export const SkeletonBrokerInfo = styled.div`
  width: 100%;
  margin-top: 10px;
  .ant-skeleton {
    margin-bottom: 10px;
  }
`
export const SkeletonColumn = styled.div`
  display: flex;
  gap: 10px;
`
