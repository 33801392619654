import { WidgetProps } from '@rjsf/utils'

import { CheckBox } from '../../components'

export function CheckboxWidget({ value, label, required, name, onChange, disabled }: WidgetProps) {
  return (
    <CheckBox
      value={value}
      checked={!!value}
      label={required ? `${label}*` : label}
      name={name}
      onChange={({ target }) => onChange(target.checked)}
      disabled={disabled}
    />
  )
}
