import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'

import { insurersBradesco } from '../../../helper/bradesco/insurers'
import { kinshipsBradesco } from '../../../helper/bradesco/kinships'
import { modalitiesBradesco } from '../../../helper/bradesco/modalities'
import { planContractTypesBradesco } from '../../../helper/bradesco/planContractTypes'

export function dependentBradescoHealthSchema(): DynamicFormSchemaProps {
  return {
    formContext: {
      hint: '*Campos de preenchimento obrigatório apenas para a emissão automática e/ou cadastro no sisweb',
    },
    schema: {
      type: 'object',
      properties: {
        name: {
          type: ['string', 'null'],
          title: 'Nome Completo*',
        },
        holderKinship: {
          type: ['string', 'null'],
          title: 'Grau de Parentesco*',
          oneOf: kinshipsBradesco.map((kinship) => ({
            title: kinship.label,
            const: kinship.value,
          })),
        },
        maritalStatus: {
          type: ['string', 'null'],
          title: 'Estado Civil*',
          oneOf: [
            { title: 'Solteiro', const: 'SINGLE' },
            { title: 'Casado', const: 'MARRIED' },
            { title: 'Viúvo', const: 'WIDOWED' },
            { title: 'Separado/Divorciado', const: 'DIVORCED' },
          ],
        },
        gender: {
          type: ['string', 'null'],
          title: 'Gênero*',
          oneOf: [
            { title: 'Feminino', const: 'FEMALE' },
            { title: 'Masculino', const: 'MALE' },
          ],
        },
        cpf: {
          type: ['string', 'null'],
          title: 'CPF*',
        },
        rg: {
          type: ['string', 'null'],
          title: 'RG*',
        },
        birthDate: {
          type: ['string', 'null'],
          title: 'Data de Nascimento*',
          format: 'date',
        },
        motherName: {
          type: ['string', 'null'],
          title: 'Nome da Mãe*',
        },
        isAdministrativeLife: {
          type: ['boolean', 'null'],
          title: 'É uma vida administrativa?',
          oneOf: [
            { title: 'Sim', const: true },
            { title: 'Não', const: false },
          ],
          default: null,
        },
        hiredHealthPlan: {
          type: 'object',
          properties: {
            isActive: {
              type: 'boolean',
              title: 'Possui plano médico?',
              oneOf: [
                { title: 'Sim', const: true },
                { title: 'Não', const: false },
              ],
              default: false,
            },
          },
          dependencies: {
            isActive: {
              oneOf: [
                {
                  properties: {
                    isActive: {
                      enum: [false],
                    },
                  },
                },
                {
                  properties: {
                    isActive: {
                      enum: [true],
                    },
                    hiredHealthSubtitle: {
                      type: ['string', 'null'],
                      title: 'Dados do Plano Médico Atual',
                    },
                    registrationNumber: {
                      type: ['string', 'null'],
                      title: 'Matrícula',
                    },
                    activeHiredPlanInsurer: {
                      type: ['string', 'null'],
                      title: 'Operadora Atual*',
                      oneOf: insurersBradesco.map((insurer) => ({
                        title: insurer.label,
                        const: insurer.value,
                      })),
                    },
                    contractType: {
                      type: ['string', 'null'],
                      title: 'Tipo de Contrato',
                      oneOf: planContractTypesBradesco.map((contractType) => ({
                        const: contractType.value,
                        title: contractType.label,
                      })),
                    },
                    hiredAt: {
                      type: ['string', 'null'],
                      format: 'date',
                      title: 'Data de Início*',
                    },
                    modality: {
                      type: ['string', 'null'],
                      title: 'Modalidade*',
                      oneOf: modalitiesBradesco.map((modality) => ({
                        const: modality.value,
                        title: modality.label,
                      })),
                    },
                  },
                },
              ],
            },
          },
        },
      },
    },
    uiSchema: {
      name: {
        'ui:placeholder': 'Ex.: Maria da Silva',
      },
      maritalStatus: {
        'ui:placeholder': 'Selecione o estado civil',
      },
      holderKinship: {
        'ui:placeholder': 'Selecione o grau de parentesco',
      },
      gender: {
        'ui:placeholder': 'Selecione o gênero',
      },
      cpf: {
        'ui:placeholder': '000.000.000-00',
        'ui:options': {
          mask: 'cpf',
        },
      },
      rg: {
        'ui:placeholder': '000000000',
      },
      birthDate: {
        'ui:placeholder': '00/00/0000',
      },
      motherName: {
        'ui:placeholder': 'Ex.: Ana da Silva',
      },
      isAdministrativeLife: {
        'ui:widget': 'radio',
      },
      hiredHealthPlan: {
        'ui:options': {
          colSpan: 2,
        },
        isActive: {
          'ui:widget': 'radio',
          'ui:options': {
            colSpan: 2,
          },
        },
        hiredHealthSubtitle: {
          'ui:widget': 'SubtitleWidget',
        },
        registrationNumber: {
          'ui:placeholder': 'Nº de matrícula',
        },
        activeHiredPlanInsurer: {
          'ui:placeholder': 'Selecione a operadora atual',
        },
        contractType: {
          'ui:placeholder': 'Selecione o tipo de contrato',
        },
        hiredAt: {
          'ui:placeholder': '00/00/0000',
        },
        modality: {
          'ui:placeholder': 'Selecione a modalidade',
        },
      },
    },
  }
}
