import { dependentAmilDentalSchema } from './amil'
import { dependentDefaultDentalSchema } from './default'
import { dependentGndiDentalSchema } from './gndi'
import { dependentPortoSeguroDentalSchema } from './portoSeguro'

export const dependentDentalSchemas = {
  amil: dependentAmilDentalSchema,
  gndi: dependentGndiDentalSchema,
  portoSeguro: dependentPortoSeguroDentalSchema,
  default: dependentDefaultDentalSchema,
}
