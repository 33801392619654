import { colors } from '@design-system/styles/theme'
import styled from 'styled-components'

interface StyledProps {
  hasSignedOrder?: boolean
}

export const TimelineContent = styled.div`
  margin-left: 10px;
  margin-top: 10px;
  max-height: 650px;
  overflow-y: scroll;
  padding: 0 15px 0 10px;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dcdfeb;
    border-radius: 10px;
  }
`

export const ListItem = styled.div``

export const StyledTitle = styled.h1`
  font-size: 14px;
  color: #4b4b4b;
`

export const StyledSubTitle = styled.h2`
  font-size: 12px;
  color: #878ea6;
`

export const StyledDetails = styled.p`
  color: #878da1;
  white-space: pre-line;

  span {
    color: black;
    font-weight: 500;
  }
`

export const StyledAutomationDetails = styled.div`
  color: #878da1;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    color: black;
    font-weight: 500;
  }
`

export const DownloadDocumentButtonDiv = styled.div<StyledProps>`
  width: 360px;
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
`

export const CustomDot = styled.span<{ bgColor: string }>`
  & svg {
    padding: 7px;
    border-radius: 30px;
    background-color: ${(props) => (props.bgColor ? props.bgColor : 'gray')};
  }
`

export const ResponsibleContent = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 40px;
`
export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const SelectContainer = styled.div`
  display: flex;
  width: 251px;
`
export const ResponsibleAdminTag = styled.span`
  align-items: center;
  background: #f5f6f9;
  border-radius: 30px;
  color: #4b4b4b;
  column-gap: 10px;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 15px;
  margin: 5px;
`
export const WithoutResponsibleContent = styled.span`
  font-size: 13px;
  color: ${colors.red};
  font-weight: 700;
  display: flex;
  align-items: center;
  column-gap: 5px;
`
export const OrderStatusSelectLoadingDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 200px;
  margin-bottom: 30px;
`

export const StyledDivIcon = styled.div`
  svg {
    width: 10px;
    height: 10px;
    color: #9399ab;
    cursor: pointer;
  }
`
export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalTitle = styled.h2`
  color: #4b4b4b;
  margin: 0;
`

export const StyledButton = styled.button`
  background: none;
  border: none;
  font-weight: 500;
  text-decoration: underline;
  color: #4b4b4b;
  :hover {
    cursor: pointer;
  }
`

export const ModalTime = styled.p`
  font-size: 12px;
  color: #878ea6;
  margin: 10px 0;
`

export const ModalTagContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 70px;
`

export const ModalTag = styled.div`
  background-color: #f5f6f9;
  border-radius: 10px;
  color: #878da1;
  align-items: center;
  display: flex;
  padding: 5px 12px 5px 12px;

  span {
    color: ${colors.primaryText};
    display: inline;
    font-weight: 700;
    margin-right: 5px;
  }
`

export const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
`
