import { DollarOutlined } from '@ant-design/icons'
import { Accordion } from 'design-system/components'

import { GetBrokersByIdDto } from '@/App/clients/healthPlansOrder/dto'

import { BankTransferContent } from './BankTransferContent'
import { DefaultContent } from './DefaultContent'
import { PixContent } from './PixContent'
import { AccordionBodyDiv, AccordionHeader } from './style'

interface Props {
  data?: GetBrokersByIdDto | null
}

const PaymentInformation = ({ data }: Props) => {
  const commissionReceivingPreference = data?.commissionReceivingPreference

  const pixCommissionReceivingPreference = commissionReceivingPreference === 'PIX'
  const bankTransferCommissionReceivingPreference =
    commissionReceivingPreference === 'BANK_TRANSFER'

  const RenderPaymentInformationContent = () => {
    if (pixCommissionReceivingPreference) return <PixContent data={data} />
    if (bankTransferCommissionReceivingPreference) return <BankTransferContent data={data} />
    return <DefaultContent data={data} />
  }

  return (
    <Accordion
      header={
        <AccordionHeader>
          <DollarOutlined /> Dados de Pagamento
        </AccordionHeader>
      }
    >
      <AccordionBodyDiv>
        <RenderPaymentInformationContent />
      </AccordionBodyDiv>
    </Accordion>
  )
}

export default PaymentInformation
