import React, { ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'

import { ArrowLeftOutlined, ArrowRightOutlined, CloseCircleOutlined } from '@ant-design/icons'

import CheckIcon from '../../icons/check'
import {
  ActionButton,
  ActionButtonsDiv,
  ActionButtonsDivMobile,
  ButtonNext,
  ButtonPrevious,
  Description,
  DivIconMobile,
  LabelStepMobile,
  Line,
  StepsActionButtons,
  StepsContainer,
  StepsEntries,
  StepsEntriesHeader,
  StyledLi,
  StyledLiMobile,
  StyledNumber,
  StyledStepContent,
  Title,
  UlStiledMobile,
  UlStyledDesktop,
} from './style'

interface Step {
  label: string
  children: ReactNode
  onNextStep?: () => void
  onNextStepIsLoading?: boolean
  formId?: string | null
  mobileLabel?: string | null
}

export interface StepsProps {
  steps: Step[]
  currentStep: number
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  title: string
  description: string
  save?: () => void
  saveButtonIsLoading?: boolean
  cancel?: () => void
  mobileActionButtonsPosition?: 'bottomOfScreen' | 'afterContent'
}

const Steps: React.FC<StepsProps> = ({
  steps,
  currentStep,
  setCurrentStep,
  title,
  description,
  save,
  saveButtonIsLoading,
  cancel,
  mobileActionButtonsPosition = 'bottomOfScreen',
}) => {
  const arrayStepsLength = steps.length
  const currentStepContent = steps[currentStep]

  const isMobile = useMediaQuery({ maxWidth: 768 })

  const handleNextStep = (event, isForm: boolean) => {
    if (!isForm) event.preventDefault()
    const currentStepObj = steps.at(currentStep)
    if (currentStepObj?.onNextStep) currentStepObj.onNextStep()

    if (!isForm) {
      setCurrentStep(currentStep + 1)
    }
  }
  const previousStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const UlMobile = () => {
    return (
      <UlStiledMobile>
        {steps.map((_, index) => (
          <StyledLiMobile isActive={currentStep === index} key={index}>
            <StyledNumber>0{index + 1}.</StyledNumber>
          </StyledLiMobile>
        ))}
      </UlStiledMobile>
    )
  }

  const UlDesktop = () => {
    return (
      <>
        <UlStyledDesktop>
          {steps.map((step, index) => (
            <StyledLi isActive={currentStep === index} key={index}>
              <div>
                <p>0{index + 1}.</p>
                <p>{step.label}</p>
                <p>
                  <ArrowRightOutlined />
                </p>
              </div>
            </StyledLi>
          ))}
        </UlStyledDesktop>
      </>
    )
  }
  const ActionsButtonsMobile = () => {
    return (
      <ActionButtonsDivMobile mobileActionButtonsPosition={mobileActionButtonsPosition}>
        <StepsActionButtons>
          <ButtonPrevious
            style={{ width: '160px' }}
            palette="cancel"
            onClick={currentStep === 0 ? cancel : previousStep}
          >
            {currentStep === 0 ? (
              <ActionButton>
                <div>
                  <CloseCircleOutlined />
                </div>
                Cancelar
              </ActionButton>
            ) : (
              <ActionButton>
                <div>
                  <ArrowLeftOutlined />
                </div>
                Voltar
              </ActionButton>
            )}
          </ButtonPrevious>
          <ButtonNext
            style={{ width: '50px', backgroundColor: '#057BDD' }}
            height="50px"
            htmlType={steps[currentStep].formId ? 'submit' : 'button'}
            loading={
              currentStep === arrayStepsLength - 1
                ? saveButtonIsLoading
                : steps[currentStep].onNextStepIsLoading
            }
            onClick={(event) =>
              currentStep === arrayStepsLength - 1 && save != null
                ? save()
                : handleNextStep(event, !!steps[currentStep].formId)
            }
            formId={steps[currentStep].formId}
          >
            <ActionButton>
              <div>
                {currentStep === arrayStepsLength - 1 ? (
                  <DivIconMobile>
                    <CheckIcon />
                  </DivIconMobile>
                ) : (
                  <ArrowRightOutlined />
                )}{' '}
              </div>
            </ActionButton>
          </ButtonNext>
        </StepsActionButtons>
      </ActionButtonsDivMobile>
    )
  }

  const ActionsButtonsDesktop = () => {
    return (
      <ActionButtonsDiv>
        <StepsActionButtons>
          <ButtonPrevious palette="cancel" onClick={currentStep === 0 ? cancel : previousStep}>
            {currentStep === 0 ? (
              <ActionButton>
                <div>
                  <CloseCircleOutlined />
                </div>
                Cancelar
              </ActionButton>
            ) : (
              <ActionButton>
                <div>
                  <ArrowLeftOutlined />
                </div>
                Voltar
              </ActionButton>
            )}
          </ButtonPrevious>
          <ButtonNext
            htmlType={steps[currentStep].formId ? 'submit' : 'button'}
            loading={
              currentStep === arrayStepsLength - 1
                ? saveButtonIsLoading
                : steps[currentStep].onNextStepIsLoading
            }
            onClick={(event) =>
              currentStep === arrayStepsLength - 1 && save != null
                ? save()
                : handleNextStep(event, !!steps[currentStep].formId)
            }
            formId={steps[currentStep].formId}
          >
            <ActionButton>
              <div>{currentStep === arrayStepsLength - 1 ? 'Salvar' : 'Próximo'} </div>
              <ArrowRightOutlined />
            </ActionButton>
          </ButtonNext>
        </StepsActionButtons>
      </ActionButtonsDiv>
    )
  }
  return (
    <StepsContainer>
      <StepsEntries>
        <StepsEntriesHeader>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <Line />
        </StepsEntriesHeader>
        {isMobile ? <UlMobile /> : <UlDesktop />}
        {isMobile && (
          <LabelStepMobile>
            {steps[currentStep].mobileLabel ?? steps[currentStep].label}
          </LabelStepMobile>
        )}
      </StepsEntries>

      <StyledStepContent mobileActionButtonsPosition={mobileActionButtonsPosition}>
        {currentStepContent.children}
        {isMobile ? <ActionsButtonsMobile /> : <ActionsButtonsDesktop />}
      </StyledStepContent>
    </StepsContainer>
  )
}

export default Steps
