import type { UseQueryResult } from '@tanstack/react-query'

import type { GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import type { UseFetchConfig } from '@/App/clients/http/interface'
import { Entities } from '@/App/pages/orderDetails/entities'
import { Files } from '@/App/pages/orderDetails/files'
import { InsurerExternalResponsibles } from '@/App/pages/orderDetails/insurerExternalResponsibles/index'
import { OtherInfos } from '@/App/pages/orderDetails/otherInfos'
import { Quotation } from '@/App/pages/orderDetails/quotation'
import { SiswebDispatch } from '@/App/pages/orderDetails/siswebDispatch'

import { OrderContentColumnDiv, OrderContentDiv } from './style'

interface IProposalTabProps {
  orderId?: string
  validateOrderDispatch: boolean
  validateSiswebDispatch: boolean
  validateQuotationDispatch: boolean
  setValidateSiswebDispatch: React.Dispatch<React.SetStateAction<boolean | undefined>>
  orderInformationQuery: UseQueryResult<GetOrderInformationBody, Error>
  setFillIsRegisteredInInternalSystemRequestConfig: React.Dispatch<
    React.SetStateAction<UseFetchConfig | undefined>
  >
  isRegisteredInInternalSystem: boolean
  setIsRegisteredInInternalSystem: React.Dispatch<React.SetStateAction<boolean | undefined>>
  isLoadingFillIsRegisteredInInternalSystem: boolean
}

export const ProposalTab: React.FC<IProposalTabProps> = ({
  validateOrderDispatch,
  validateQuotationDispatch,
  orderId,
  validateSiswebDispatch,
  setValidateSiswebDispatch,
  orderInformationQuery,
  setFillIsRegisteredInInternalSystemRequestConfig,
  isRegisteredInInternalSystem,
  setIsRegisteredInInternalSystem,
  isLoadingFillIsRegisteredInInternalSystem,
}) => {
  return (
    <>
      <OrderContentDiv>
        <Quotation
          validateQuotationDispatch={validateQuotationDispatch}
          validateOrderDispatch={validateOrderDispatch}
        />

        <OrderContentColumnDiv>
          <Entities
            orderInformationQuery={orderInformationQuery}
            validateOrderDispatch={validateOrderDispatch}
            validateSiswebDispatch={validateSiswebDispatch}
          />

          <InsurerExternalResponsibles
            orderData={orderInformationQuery?.data}
            reloadOrderData={orderInformationQuery.refetch}
            isLoadingReloadOrderData={orderInformationQuery.isRefetching}
            validateOrderDispatch={validateOrderDispatch}
          />

          <OtherInfos
            orderId={orderId}
            orderData={orderInformationQuery?.data}
            validateSiswebDispatch={validateSiswebDispatch}
          />

          <SiswebDispatch
            setFillIsRegisteredInInternalSystemRequestConfig={
              setFillIsRegisteredInInternalSystemRequestConfig
            }
            isRegisteredInInternalSystem={isRegisteredInInternalSystem}
            setIsRegisteredInInternalSystem={setIsRegisteredInInternalSystem}
            isLoadingFillIsRegisteredInInternalSystem={isLoadingFillIsRegisteredInInternalSystem}
            orderId={orderId}
            setValidateSiswebDispatch={setValidateSiswebDispatch}
          />

          <Files orderInformationQuery={orderInformationQuery} />
        </OrderContentColumnDiv>
      </OrderContentDiv>
    </>
  )
}
