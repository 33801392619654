import { Entity, GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import { calculateAge } from '@/App/utils/calculateProcessingTime'

import type { EntityType } from '../..'
import { getRequiredEntityFields } from '../helper'

export const getRequiredFieldsSulamerica = (orderData?: GetOrderInformationBody) => {
  const dynamicFormValidator: Array<{
    dynamicFormId?: string | null | undefined
    requiredFields: Array<string | null>
  } | null> = [
    getRequiredEntityFields(orderData?.company, [
      // COMPANY
      'document',
      'registrationStatus',
      'openingDate',
      'address.zipCode',
      'address.number',

      // CONTACT
      'name',
      'role',
      'phone',
      'mail',
      'contactDocument',

      // EXTERNAL LEGAL REPRESENTATIVE
      'externalLegalRepresentative.name',
      'externalLegalRepresentative.mail',
      'externalLegalRepresentative.phone',
      'externalLegalRepresentative.cpf',
    ]),
    getRequiredEntityFields(orderData?.quotation, ['totalAmount']),
  ]

  if (orderData?.holders && orderData.holders.length > 0) {
    for (const holder of orderData.holders) {
      const { requiredFields } = getRequiredEntityFieldsSulamerica('HOLDER', holder, orderData)
      dynamicFormValidator.push(...requiredFields)

      const dependents = holder.dependents ?? []
      for (const dependent of dependents) {
        const { requiredFields } = getRequiredEntityFieldsSulamerica(
          'DEPENDENT',
          dependent,
          orderData,
        )
        dynamicFormValidator.push(...requiredFields)
      }
    }
  }

  return dynamicFormValidator.filter((value) => value)
}

export const getRequiredCompanyFieldsSulamerica = (orderData?: GetOrderInformationBody) => {
  return [
    orderData?.company?.document,
    orderData?.company?.registrationStatus,
    orderData?.company?.openingDate,
    orderData?.company?.address?.zipCode,
    orderData?.company?.address?.number,

    orderData?.company?.name,
    orderData?.company?.role,
    orderData?.company?.phone,
    orderData?.company?.mail,
    orderData?.company?.contactDocument,

    orderData?.company?.externalLegalRepresentative?.name,
    orderData?.company?.externalLegalRepresentative?.mail,
    orderData?.company?.externalLegalRepresentative?.cpf,
    orderData?.company?.externalLegalRepresentative?.phone,
  ]
}

export const getRequiredHolderGroupFieldsSulamerica = (orderData?: GetOrderInformationBody) => {
  const holderList = orderData?.holders?.map((_, index) => {
    return [
      orderData?.holders?.at(index)?.mail,
      orderData?.holders?.at(index)?.cpf,
      orderData?.holders?.at(index)?.birthDate,
      orderData?.holders?.at(index)?.name,
      orderData?.holders?.at(index)?.maritalStatus,
      orderData?.holders?.at(index)?.gender,
      orderData?.holders?.at(index)?.phone,
      orderData?.holders?.at(index)?.isAdministrativeLife,
      orderData?.holders?.at(index)?.address?.zipCode,
      orderData?.holders?.at(index)?.address?.number,
      ...(orderData?.quotation?.product === 'HEALTH'
        ? [
            orderData?.holders?.at(index)?.healthPlan?.catalogPlanId,
            orderData?.holders?.at(index)?.nationality,
            orderData?.holders?.at(index)?.contractType,
          ]
        : []),
      ...(orderData?.quotation?.product === 'DENTAL'
        ? [orderData?.holders?.at(index)?.dentalPlan?.catalogPlanId]
        : []),
      ...(orderData?.holders?.at(index)?.hiredHealthPlan?.isActive
        ? [
            orderData?.holders?.at(index)?.hiredHealthPlan?.activeHiredPlanInsurer,
            orderData?.holders?.at(index)?.hiredHealthPlan?.hiredAt,
          ]
        : []),
      ...(orderData?.holders?.at(index)?.hiredDentalPlan?.isActive
        ? [
            orderData?.holders?.at(index)?.hiredDentalPlan?.activeHiredPlanInsurer,
            orderData?.holders?.at(index)?.hiredDentalPlan?.hiredAt,
          ]
        : []),
    ]
  })

  const allDependents = getRequiredDependentFieldsSulamerica(orderData)

  return [...(holderList?.at(0) ?? []), ...allDependents]
}

export const getRequiredDependentFieldsSulamerica = (orderData?: GetOrderInformationBody) => {
  const dependentList =
    orderData?.holders?.map((holder) =>
      holder?.dependents?.map((dependent: Entity) => [
        calculateAge(dependent.birthDate) >= 18 ? dependent.cpf : [],
        dependent.birthDate,
        dependent.name,
        dependent.maritalStatus,
        dependent.gender,
        dependent.holderKinship,
        dependent.isAdministrativeLife,
        ...(orderData?.quotation?.product === 'HEALTH' ? [dependent.nationality] : []),
        ...(dependent.hiredHealthPlan?.isActive
          ? [dependent.hiredHealthPlan?.activeHiredPlanInsurer, dependent.hiredHealthPlan?.hiredAt]
          : []),
      ]),
    ) ?? []

  return dependentList.flat(2)
}

export const getRequiredEntityFieldsSulamerica = (
  entityType: EntityType,
  entity?: Entity | null,
  orderData?: GetOrderInformationBody,
) => {
  const requiredFields: Array<{
    dynamicFormId?: string | null | undefined
    requiredFields: Array<string | null>
  } | null> = []

  if (entityType === 'HOLDER') {
    requiredFields.push(
      getRequiredEntityFields(entity, [
        'mail',
        'cpf',
        'birthDate',
        'name',
        'maritalStatus',
        'gender',
        'phone',
        'isAdministrativeLife',
        'address.zipCode',
        'address.number',
        ...(orderData?.quotation?.product === 'HEALTH' || orderData?.quotation?.isCombinedProposal
          ? ['healthPlan.catalogPlanId', 'nationality', 'contractType']
          : []),
        ...(orderData?.quotation?.product === 'DENTAL' || orderData?.quotation?.isCombinedProposal
          ? ['dentalPlan.catalogPlanId']
          : []),
        ...(entity?.hiredHealthPlan?.isActive
          ? ['hiredHealthPlan.activeHiredPlanInsurer', 'hiredHealthPlan.hiredAt']
          : []),
      ]),
    )
  }

  if (entityType === 'DEPENDENT') {
    const isLegalAge = calculateAge(entity?.birthDate) >= 18

    requiredFields.push(
      getRequiredEntityFields(entity, [
        'birthDate',
        'name',
        'maritalStatus',
        'gender',
        'holderKinship',
        'isAdministrativeLife',
        ...(orderData?.quotation?.product === 'HEALTH' ? ['nationality'] : []),
        ...(entity?.hiredHealthPlan?.isActive
          ? ['hiredHealthPlan.activeHiredPlanInsurer', 'hiredHealthPlan.hiredAt']
          : []),
        ...(isLegalAge ? ['cpf'] : []),
      ]),
    )
  }

  return {
    requiredFields,
    hasEmptyValues:
      requiredFields?.some(
        (validator) => validator?.requiredFields && validator.requiredFields.length > 0,
      ) ?? false,
  }
}
