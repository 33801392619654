import React from 'react'
import { ToastPosition } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { StyledToastContainer } from './style'

export interface Props {
  hideProgressBar?: boolean
  position?: ToastPosition
}

const Toast: React.FC<Props> = ({ hideProgressBar = true, position = 'top-right' }) => {
  return (
    <>
      <StyledToastContainer hideProgressBar={hideProgressBar} position={position} />
    </>
  )
}

export default Toast 
