import { Skeleton as AntdSkeleton } from 'antd'
import styled from 'styled-components'

export type StyledSkeletonProps = {
  type?: string
  avatarWidth?: string
  avatarHeight?: string
}

export const SkeletonAvatar = styled(AntdSkeleton.Avatar)<StyledSkeletonProps>`
  &&& {
    :where(.css-dev-only-do-not-override-1vtf12y).ant-skeleton.ant-skeleton-element
      .ant-skeleton-avatar {
      width: ${(props) => props.avatarWidth ?? ''};
      height: ${(props) => props.avatarHeight ?? ''};
    }
  }
`

export const SkeletonImg = styled(AntdSkeleton.Image)`
  &&& {
    width: 100%;
    height: 100%;
  }
`

export const SkeletonInput = styled(AntdSkeleton.Input)`
  &&& {
    width: 100%;
  }
`
