import React from 'react'
import { ReactNode } from 'react'

import { StyledTabs } from './style'

export interface ItemProps {
  key: string
  label: ReactNode
  children?: ReactNode
}

export interface TabsProps {
  position?: 'top' | 'right' | 'bottom' | 'left'
  items: ItemProps[]
  itemsColor?: string
  onTabClick?: (key: string, event: React.KeyboardEvent | React.MouseEvent) => void
  defaultActiveKey?: string
}

const Tabs: React.FC<TabsProps> = ({
  position = 'top',
  items,
  itemsColor,
  onTabClick,
  defaultActiveKey,
}) => {
  const handleTabClick = (key: string, event: React.KeyboardEvent | React.MouseEvent) => {
    if (onTabClick) {
      onTabClick(key, event)
    }
  }

  return (
    <StyledTabs
      tabPosition={position}
      items={items}
      itemsColor={itemsColor}
      onTabClick={handleTabClick}
      defaultActiveKey={defaultActiveKey}
    />
  )
}

export default Tabs
