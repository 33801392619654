import React from 'react'

import { Image as AntdImage } from 'antd'

import { StyledImageWrapper } from './style'

export interface Props {
  height?: number | string
  width?: number | string
  src: string
  visible?: boolean
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>
  alt?: string
}

const Image: React.FC<Props> = ({ width, src, height, visible = false, setVisible, alt }) => (
  <StyledImageWrapper>
    <AntdImage
      width={width}
      height={height}
      src={src}
      preview={
        setVisible && {
          visible,
          src: `${src}`,
          onVisibleChange: (value) => {
            setVisible(value)
          },
        }
      }
      alt={alt}
    />
  </StyledImageWrapper>
)

export default Image
