/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'

import { WarningOutlined } from '@ant-design/icons'
import { Button, Select, Toast } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { useOrderInformationQuery } from '@/App/clients/healthPlansOrders/queries/orderInformation'
import { useUpdateStatus } from '@/App/clients/orderState/mutation/updateStatus'

import { ButtonDiv, CancelButton, ModalContent, ModalSubTitle, ModalTitle } from './style'

const options = [
  {
    label: 'Inadimplência',
    value: 'Motivo do Cancelamento: Inadimplência - Cliente não pagou o boleto',
  },
  {
    label: 'Cotação',
    value: 'Motivo do Cancelamento: Cotação - Cliente não aceitou novo valor da cotação',
  },
  {
    label: 'Ausência',
    value: 'Motivo do Cancelamento: Ausência - Não compareceu na entrevista qualificada',
  },
  {
    label: 'Carência',
    value: 'Motivo do Cancelamento: Carência - Cliente não aceita condições da CPT',
  },
  {
    label: 'Saída',
    value: 'Motivo do Cancelamento: Saída - Desligamento da empresa',
  },
  {
    label: 'Duplicidade',
    value: 'Motivo do Cancelamento: Duplicidade - Proposta já existe',
  },
  {
    label: 'Operadora',
    value: 'Motivo do Cancelamento: Operadora - Sem interesse comercial',
  },
]

interface BillPendingModalProps {
  setIsModalOpen?: (value: boolean) => void
  orderId?: string
  setShouldReload?: React.Dispatch<React.SetStateAction<boolean>>
  setOrderStatus?: React.Dispatch<React.SetStateAction<string | null>>
}

export const CancelModal: React.FC<BillPendingModalProps> = ({
  setIsModalOpen,
  orderId,
  setShouldReload,
  setOrderStatus,
}) => {
  const [statusUpdateDescription, setStatusUpdateDescription] = useState('')

  const orderInformationQuery = useOrderInformationQuery({ orderId })
  const updateStatusMutation = useUpdateStatus(orderId)

  useEffect(() => {
    if (updateStatusMutation.isSuccess) {
      setOrderStatus && setOrderStatus('CANCELED')
      setIsModalOpen && setIsModalOpen(false)
      if (setShouldReload) setShouldReload(true)
      triggerToast(true, 'Status atualizado com sucesso')
    } else if (updateStatusMutation.isError) {
      triggerToast(false, 'Erro ao atualizar o status')
    }
  }, [updateStatusMutation.isError, updateStatusMutation.isSuccess])

  const updateStatusRequest = async () => {
    if (orderId) {
      await updateStatusMutation.mutateAsync({
        nextStatus: 'CANCELED',
        order: orderInformationQuery?.data,
        statusUpdateMetadata: {
          statusUpdateDescription,
        },
      })
    }
  }

  return (
    <ModalContent>
      <WarningOutlined style={{ fontSize: '40px' }} />
      <ModalTitle>Selecione o motivo do cancelamento da proposta</ModalTitle>
      <ModalSubTitle>Assim que você enviar, notificaremos o corretor.</ModalSubTitle>
      <Select
        options={options}
        defaultValue="Selecione o motivo"
        onChange={(value) => setStatusUpdateDescription(value as string)}
      />
      <ButtonDiv>
        <Button loading={updateStatusMutation.isPending} onClick={updateStatusRequest}>
          Salvar e enviar
        </Button>
        <CancelButton onClick={() => setIsModalOpen && setIsModalOpen(false)}>
          Cancelar
        </CancelButton>
      </ButtonDiv>
      <Toast />
    </ModalContent>
  )
}
