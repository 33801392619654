import { Carousel as AntdCarousel } from 'antd'
import styled from 'styled-components'

export interface StyledCarouselProps {
  arrowColor?: string
}

export const StyledCarousel = styled(AntdCarousel)<StyledCarouselProps>`
  &&& {
    .slick-prev {
      left: 10px;
      z-index: 2;
      color: ${(props) => props.arrowColor ?? 'white'};
      font-size: 20px;
    }

    .slick-next {
      right: 10px;
      z-index: 2;
      color: ${(props) => props.arrowColor ?? 'white'};
      font-size: 20px;
    }
  }
`
