/* eslint-disable react/prop-types */
import InfiniteScroll from 'react-infinite-scroll-component'

import { Skeleton, Table } from 'design-system/components'

import { SkeletonWrapper } from './style'

interface Broker {
  key: string
}

interface TableProps {
  rows: {
    key: string
    brokerName: string
    brokerPhone: string
    brokerMail: string
    registration: string
    sisWebCode: string
    status: string
  }[]
  columns: { title: string; dataIndex: string; key: string }[]
  isLoading: boolean
  hasNext: boolean
  setPage: React.Dispatch<React.SetStateAction<number>>
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  setBrokerId: React.Dispatch<React.SetStateAction<string>>
}

export const TableComponent: React.FC<TableProps> = ({
  isLoading,
  setPage,
  columns,
  rows,
  setBrokerId,
  hasNext,
  setIsOpen,
}) => {
  return (
    <InfiniteScroll
      dataLength={rows?.length}
      next={() => setPage((prev: number) => prev + 1)}
      hasMore={hasNext}
      loader={
        isLoading
          ? [...Array(3)].map((_, index) => (
              <SkeletonWrapper key={index}>
                <Skeleton key={index} type="button" active />
              </SkeletonWrapper>
            ))
          : null
      }
      scrollableTarget="frameContent"
      scrollThreshold={1}
    >
      <Table
        onRowClick={(record) => {
          setIsOpen(true)
          setBrokerId((record as Broker).key)
        }}
        loading={isLoading}
        columns={columns}
        data={rows}
      />
    </InfiniteScroll>
  )
}
