import styled from 'styled-components'

export const ActionButton = styled.div`
  font-weight: bold;
  color: #4b4b4b;
  margin: 0 4px;

  &:hover {
    color: #bbb;
  }
`

export const ActionButtonText = styled.span`
  font-weight: bold;
  text-decoration: underline;
  margin-left: 3px;

  &:hover {
    text-decoration: underline;
  }
`

export const CenterText = styled.div`
  text-align: center;
`

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;

  & > div {
    width: 100%;
  }
`
export const TextContainer = styled.div`
  display: flex;
  align-items: center;
`
