import { createContext } from 'react'

import { UserData } from '../../clients/healthPlansOrders/dtos'

export type UserContextType = {
  user: UserData | null
  setUserData: (userData: UserData) => void
  signOut: () => void
}

export const UserContext = createContext<UserContextType | null>(null)
