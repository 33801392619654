export const typeOfSociety = [
  { label: 'EI - EMPRESARIO INDIVIDUAL', value: 'EI' },
  {
    label: 'EIRELLI - Empresa Individual de Responsabilidade Ltda',
    value: 'EIRELLI',
  },
  { label: 'EPP - Empresa de Pequeno Porte', value: 'EPP' },
  { label: 'LTDA - ltda', value: 'LTDA' },
  { label: 'ME - Microempresa', value: 'ME' },
  { label: 'MEI - Microempreendedor Individual', value: 'MEI' },
  { label: 'SA - Sociedade Anônima', value: 'SA' },
]
