const Exclamation = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <path
        fill="#9399AB"
        d="M33.333 3.333H6.667a3.343 3.343 0 00-3.334 3.334v30L10 30h23.333c1.834 0 3.334-1.5 3.334-3.333v-20c0-1.833-1.5-3.333-3.334-3.333zm0 23.334H8.617l-1.95 1.95V6.667h26.666v20zm-15-18.334h3.334v10h-3.334v-10zm0 13.334h3.334V25h-3.334v-3.333z"
      ></path>
    </svg>
  )
}

export default Exclamation
