import React from 'react'

import * as S from './style'

interface OrderScoreTagProps {
  label?: string | null
  score?: number | null
}

function getBackgroundColor(score: number) {
  if (score > 700) {
    return '#F2C6C1'
  }

  if (score >= 300) {
    return '#FFF5E6'
  }

  return '#B2EBB1'
}

const OrderScoreTagComponent: React.FC<OrderScoreTagProps> = ({ score, label }) => {
  if (score == null) {
    return null
  }

  const backgroundColor = getBackgroundColor(score)

  return (
    <S.OrderScoreTagContainer backgroundColor={backgroundColor}>
      {label ? <span>{label}</span> : null}
      <span>{score}</span>
    </S.OrderScoreTagContainer>
  )
}

export const OrderScoreTag = React.memo(
  OrderScoreTagComponent,
  (prevProps, nextProps) =>
    prevProps.score === nextProps.score && prevProps.label === nextProps.label,
)
