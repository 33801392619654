import { QueryFunctionContext, queryOptions, useQuery } from '@tanstack/react-query'

import { Address } from '../../healthPlansOrders/dtos'
import { api } from '../../http'

type GetCompanyByCnpj = {
  cnpj?: string | null
}

type GetCompanyByCnpjRequest = QueryFunctionContext & GetCompanyByCnpj

export interface CompanyResponse {
  company: {
    name?: string
    tradingName?: string
    document?: string
    openingDate?: string
    address?: Address
    registrationStatus?: string
    mail?: string
    phone?: string
    typeOfSociety?: string
  }
}

async function getCompanyByCnpj({ cnpj, signal }: GetCompanyByCnpjRequest) {
  const response = await api.get<CompanyResponse>(
    `${import.meta.env.VITE_COMPANY_URL}/search/${cnpj}`,
    {
      signal,
      headers: {
        'x-api-key': import.meta.env.VITE_COMPANY_APIKEY,
      },
    },
  )
  return response.data
}

export function getCompanyByCnpjQueryOption({
  cnpj,
  enabled = true,
}: GetCompanyByCnpj & { enabled?: boolean }) {
  return queryOptions({
    queryKey: ['company', cnpj] as const,
    queryFn: async (params) => getCompanyByCnpj({ ...params, cnpj }),
    refetchOnWindowFocus: false,
    enabled: enabled && !!cnpj,
  })
}

export const useGetCompanyByCnpj = ({
  cnpj,
  enabled,
}: GetCompanyByCnpj & { enabled?: boolean }) => {
  return useQuery(getCompanyByCnpjQueryOption({ enabled, cnpj }))
}
