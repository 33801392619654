import React, { ReactNode } from 'react'
import { StyledTabs } from './style'

export interface ItemProps {
  key: string
  label: ReactNode
  children: ReactNode
}

export interface TabsProps {
  position?: 'top' | 'right' | 'bottom' | 'left'
  items: ItemProps[]
  colorBackgorund?: string
}

const TabsButton: React.FC<TabsProps> = ({ position = 'top', items, colorBackgorund }) => (
  <StyledTabs tabPosition={position} items={items} colorBackground={colorBackgorund} />
)

export default TabsButton
