import { ChangeEvent, ReactNode, useState } from 'react'
import {
  FieldError,
  FieldValues,
  Path,
  UseControllerProps,
  UseFormRegisterReturn,
} from 'react-hook-form'

import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons'
import { withHookFormMask } from 'use-mask-input'

import Error from '../Error/Error'
import {
  BoxInputValidation,
  ContainerStyled,
  DivInput,
  IconInput,
  Label,
  StyledInput,
} from './style'

export type TextInputMask = 'phone' | 'cep' | 'cnpj' | 'cpf' | 'money' | 'height' | 'weight'

export interface Props<T extends FieldValues> extends UseControllerProps<T> {
  error?: FieldError | null
  register?: UseFormRegisterReturn
  placeholder?: string
  label?: ReactNode
  vertical?: boolean
  disabled?: boolean
  name: Path<T>
  type?: 'password' | 'text'
  value?: string | null
  iconStart?: ReactNode
  iconColor?: string
  textInputColor?: string
  labelColor?: string
  labelWeight?: string
  labelSize?: string
  iconCursorPointer?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  mask?: TextInputMask
  id?: string
}

const CustomInput = <T extends FieldValues>({
  labelSize,
  labelWeight,
  labelColor,
  textInputColor,
  iconColor,
  iconStart,
  disabled,
  vertical,
  label,
  register,
  placeholder,
  type,
  value,
  error,
  onChange,
  mask,
  id,
}: Props<T>) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [internalInputType, setInternalInputType] = useState(type)

  const togglePassword = () => {
    setIsPasswordVisible(!isPasswordVisible)
    setInternalInputType(internalInputType === 'password' ? 'text' : 'password')
  }

  const masks = {
    phone: ['(99) 9999 9999', '(99) 9 9999 9999'],
    cep: ['99999-999', '99999-999'],
    cnpj: ['99.999.999/9999-99', '99.999.999/9999-99'],
    cpf: ['999.999.999-99', '999.999.999-99'],
    money: [
      'R$ 9{1},9{1,2}',
      'R$ 9{1,2},9{1,2}',
      'R$ 9{1,3},9{1,2}',
      'R$ 9{1}.9{1,3},9{1,2}',
      'R$ 9{1,2}.9{1,3},9{1,2}',
      'R$ 9{1,3}.9{1,3},9{1,2}',
      'R$ 9{1}.9{1,3}.9{1,3},9{1,2}',
      'R$ 9{1,2}.9{1,3}.9{1,3},9{1,2}',
      'R$ 9{1,3}.9{1,3}.9{1,3},9{1,2}',
    ],
  }

  return (
    <ContainerStyled vertical={vertical}>
      {label && (
        <Label
          labelSize={labelSize}
          labelWeight={labelWeight}
          labelColor={labelColor}
          vertical={vertical}
        >
          {label}
        </Label>
      )}
      <BoxInputValidation>
        <DivInput isError={!!error}>
          <IconInput iconColor={iconColor}>{iconStart} </IconInput>
          <StyledInput
            id={id}
            placeholder={placeholder}
            type={internalInputType}
            value={value ?? undefined}
            disabled={disabled}
            {...(register ? register : { onChange })}
            {...(mask && register ? withHookFormMask(register, masks[mask]) : {})}
            textInputColor={textInputColor}
          />
          {type === 'password' && (
            <IconInput
              iconCursorPointer={type === 'password'}
              iconColor={iconColor}
              onClick={togglePassword}
            >
              {internalInputType === 'password' ? <EyeInvisibleFilled /> : <EyeFilled />}
            </IconInput>
          )}
        </DivInput>
        {error && <Error error={error}></Error>}
      </BoxInputValidation>
    </ContainerStyled>
  )
}
export default CustomInput
