import React from 'react'

import type { AutoCompleteProps } from 'antd'
import { AutoComplete as AntdAutoComplete } from 'antd'

import { StyledAutoComplete } from './style'

type CustomAutoCompleteProps = AutoCompleteProps

const AutoCompleteComponent = StyledAutoComplete as typeof AntdAutoComplete

const AutoComplete: React.FC<CustomAutoCompleteProps> = ({ ...rest }) => {
  return <AutoCompleteComponent {...rest} />
}

export default AutoComplete
