const Person = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" viewBox="0 0 15 15">
      <g clipPath="url(#clip0_1_7793)">
        <path
          fill="#9194AB"
          d="M7.5 7.5a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5zm2.625.938h-.49a5.105 5.105 0 01-4.27 0h-.49a3.939 3.939 0 00-3.938 3.937v1.219c0 .776.63 1.406 1.407 1.406h10.312c.777 0 1.406-.63 1.406-1.406v-1.219a3.939 3.939 0 00-3.937-3.938z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1_7793">
          <path fill="#fff" d="M0 0H15V15H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Person
