import { useMutation } from '@tanstack/react-query'
import { triggerToast } from 'design-system/triggers'

import { api } from '@/App/clients/http'

import { DocumentDto } from '../../healthPlansOrders/dtos'

type FillOcrData = {
  document?: DocumentDto
  documentType: string
}

export type OcrDocumentInformationResponse = {
  'effective-date': string
  'card-number': string
  operator: string
  'end-date': string
}

async function fillOcrPermanenceInformation({ document }: FillOcrData) {
  const response = await api.post<OcrDocumentInformationResponse>(
    `${import.meta.env.VITE_OCR_PERMANENCE_URL}/info`,
    {
      name: document?.fileName,
      url: document?.previewURL,
    },
    {
      headers: {
        'x-api-key': import.meta.env.VITE_OCR_PERMANENCE_APIKEY,
      },
    },
  )

  return response.data
}

export function useFillOcrPermanenceInformation() {
  return useMutation({
    mutationKey: ['OCR', 'Document'],
    mutationFn: fillOcrPermanenceInformation,
    onSuccess: () => {
      triggerToast(true, 'informações preenchidas com sucesso!')
    },
    onError: () => {
      triggerToast(false, 'Ops, ocorreu um erro ao preencher as informações.')
    },
  })
}
