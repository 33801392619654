export const contractTypes = [
  { label: 'Empregado CLT', value: 'CLT' },
  { label: 'Cooperativo', value: 'COOPERATIVE' },
  { label: 'Estagiário', value: 'INTERN' },
  { label: 'Sócio', value: 'PARTNER' },
  { label: 'Associativo', value: 'ASSOCIATIVE' },
  { label: 'Temporário', value: 'TEMPORARY' },
  { label: 'Terceirizado', value: 'OUTSOURCED' },
  { label: 'Autônomo', value: 'SELF_EMPLOYMENT' },
  { label: 'Servidor público', value: 'FEDERAL_WORKER' },
  { label: 'Empregado doméstico', value: 'HOUSE_KEEPER' },
  { label: 'Estatutário', value: 'COMPANY_MEMBER' },
  { label: 'Administrador', value: 'ADMINISTRATOR' },
  { label: 'Intermitente', value: 'INTERMITTENT' },
]
