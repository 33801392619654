import { Modal } from 'antd'
import styled from 'styled-components'

export const StyledModal = styled(Modal)`
  &&& {
    .ant-modal-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .icon {
        width: 3em;
        height: 3em;
        display: flex;
        align-self: center;

        svg {
          width: 2.5em;
          height: 2.5em;
        }
      }

      p {
        font-size: large;
        font-weight: 600;
      }
    }

    .ant-modal-footer {
      margin-top: 0;
    }
  }
`
