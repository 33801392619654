import InfiniteScroll from 'react-infinite-scroll-component'

import { Skeleton, Table as TableComponent } from 'design-system/components'

import { OrdersData, RecordDto } from '@/App/clients/healthPlansOrder/dto'

import { ExpandableRowContent } from './ExpandedRowContent/ExpandableRowContent'
import { SkeletonWrapper } from './style'

interface Props {
  tableColumns: Array<{
    title: string
    dataIndex: string
    key: string
  }>
  tableRows: OrdersData[]
  isLoading: boolean
  hasNext?: boolean
  setShouldTriggerRequestByPagination?: React.Dispatch<React.SetStateAction<boolean>>
  setOrders: React.Dispatch<React.SetStateAction<OrdersData[]>>
}

export const Table = ({
  setOrders,
  tableColumns,
  tableRows,
  isLoading,
  hasNext,
  setShouldTriggerRequestByPagination,
}: Props) => {
  return (
    <>
      <InfiniteScroll
        dataLength={tableRows?.length}
        next={() =>
          setShouldTriggerRequestByPagination && setShouldTriggerRequestByPagination(true)
        }
        hasMore={hasNext ?? false}
        loader={
          isLoading
            ? [...Array(6)].map((_, index) => (
                <SkeletonWrapper key={index}>
                  <Skeleton key={index} type="button" active />
                </SkeletonWrapper>
              ))
            : null
        }
        scrollableTarget="frameContent"
        scrollThreshold={1}
      >
        <TableComponent
          loading={isLoading}
          columns={tableColumns}
          data={tableRows}
          expandableRowComponent={(record) => (
            <ExpandableRowContent setOrders={setOrders} record={record as RecordDto} />
          )}
        />
      </InfiniteScroll>
    </>
  )
}
