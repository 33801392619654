import React from 'react'

import { DeleteOutlined, DownloadOutlined, PaperClipOutlined } from '@ant-design/icons'
import { Drawer } from 'antd'
import styled from 'styled-components'

import { colors } from '../../styles/theme'

export interface StyledProps {
  onRowClick?: React.MouseEventHandler<HTMLSpanElement>
  isExternal?: boolean
  firstColumn?: 'name' | 'type'
  documentName?: string | null
  disabled?: boolean
}

export const StyledTable = styled.div`
  width: 100%;
`
export const TableHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  p {
    padding: 0 80px 0 11px;
    margin: 0.5em;
    color: #6c7895;
  }
`

export const TableBody = styled.table<StyledProps>`
  background: white;
  border-radius: 10px;
  border: 1px solid #cbcedc;
  width: 100%;

  tr:hover {
    background-color: ${colors.mouseHover};
    cursor: pointer;
  }

  p {
    margin: 0;
    border-top: 1px solid #cbcedc;
  }

  tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const StyledButton = styled.button`
  border: none;
  border-radius: 8px;
  height: 25px;
  background-color: #878da1;
  color: white;
`

export const TdName = styled.td<StyledProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 18rem;
  padding: ${(props) => (props.firstColumn === 'name' ? '0.5% 2% 0.5% 20px' : '0.5% 3% 0.5% 0')};
  display: ${(props) => !props.documentName && 'flex'};

  span {
    color: #bf404c;
    font-size: 12px;
    align-self: center;
    padding-left: 10px;
  }
`

export const TdType = styled.td<StyledProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${(props) => (props.firstColumn === 'name' ? '14rem' : '22rem')};
  padding: ${(props) => (props.firstColumn === 'name' ? '0.5% 2% 0.5% 20px' : '0.5% 2% 0.5% 18px')};
`
export const TdActions = styled.td`
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  width: 10rem;
  padding: 0.5% 2% 0.5% 20px;
`

export const StyledSpan = styled.span`
  margin: 0 5px 0 5px;
`

export const LoadingDiv = styled.div`
  display: flex;
  justify-content: center;
  padding: 3px;
`
export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
  }

  .ant-drawer-header {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .ant-drawer-header-title {
    display: flex;
    justify-content: flex-start;
  }

  .ant-drawer-title {
    font-size: 14px;
    color: #4b4b4b;
  }
`
export const ImageInspector = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  border: 1px solid #dcdfeb;
  border-radius: 10px;

  font-size: 25px;
  margin-bottom: 25px;

  p {
    font-size: 14px;
  }
`
export const StyledFigure = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 300px;
`

export const SearchIconDiv = styled.div`
  display: flex;
  align-self: flex-end;
  margin-right: 15px;
  margin-bottom: 15px;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 20%;
`

export const ButtonWrapper = styled.div`
  width: 20%;
`

export const StyledName = styled.p`
  font-weight: 600;
  margin: 2px 0 3px 0;
`

export const StyledTitle = styled.p`
  color: ${colors.secundaryText};
  margin: 8px 0 8px 0;
`

export const StyledLineContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const StyledLine = styled.div<StyledProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 6rem;
  border-radius: 10px;
  border: 1px solid #cbcedc;
  margin-top: 1rem;
  background-color: ${(props) => props.isExternal && '#F5F6F9'};
`

export const StyledLineType = styled.p`
  width: 100%;
  height: 100%;
  text-align: left;
  display: flex;
  font-size: 15px;
  justify-content: center;
  color: #4b4b4b;
  font-weight: 600;
  margin: 0 0 0 1.5em;
  align-content: center;
  flex-direction: column;
  white-space: nowrap;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const StyledLinePending = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`

export const StyledLineActions = styled.div`
  width: 20%;
  margin-right: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledLineButton = styled.button`
  border: none;
  border-radius: 20px;
  width: 10em;
  height: 30px;

  font-size: 16px;
  background-color: #878da1;
  color: white;
`

export const StyledIsExternalType = styled.p`
  width: 30%;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #4b4b4b;
  font-weight: 600;
  margin-left: 1.5em;
  white-space: nowrap;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const StyledPaperClipOutlined = styled(PaperClipOutlined)`
  font-size: 25px;
  color: #9194ab;
  margin-left: 80%;
`

export const StyledDeleteOutlined = styled(DeleteOutlined)`
  font-size: 25px;
  color: #9194ab;
`

export const StyledDownloadOutlined = styled(DownloadOutlined)`
  font-size: 25px;
  color: #9194ab;
`
