import { AutoComplete } from 'antd'
import styled from 'styled-components'

export const StyledAutoComplete = styled(AutoComplete)`
  &&& {
    .ant-select-selector {
      border: 1px solid #b7c8d5;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
