export const kinships = [
  { label: 'Pai', value: 'FATHER' },
  { label: 'Mãe', value: 'MOTHER' },
  { label: 'Cônjuge', value: 'SPOUSE' },
  { label: 'Filho', value: 'SON' },
  { label: 'Filha', value: 'DAUGHTER' },
  { label: 'Neto(A)', value: 'GRANDSON' },
  { label: 'Irmão/Irmã', value: 'SIBLINGS' },
  { label: 'Sobrinho/Sobrinha', value: 'NEPHEWS' },
  { label: 'Genro/Nora', value: 'SON-IN-LAW' },
  { label: 'Padrasto/Madrasta', value: 'STEPFATHER' },
  { label: 'Enteado', value: 'STEPSON' },
  { label: 'Enteada', value: 'STEPDAUGHTER' },
  {
    label: 'Titular menor utilizando cpf do reponsável',
    value: 'MINOR-HOLDER',
  },
  { label: 'Sogro', value: 'FATHER-IN-LAW' },
  { label: 'Sogra', value: 'MOTHER-IN-LAW' },
  { label: 'Bisavó/Bisavô', value: 'GREAT-GRANDMOTHER' },
  { label: 'Bisneto(a)', value: 'GREAT-GRANDCHILD' },
  { label: 'Avó/Avô', value: 'GRANDMOTHER' },
  { label: 'Tio/Tia', value: 'UNCLE' },
  { label: 'Cunhada/Cunhado', value: 'SISTER-IN-LAW' },
]
