import { Radio } from 'design-system/components'

import { Action } from '..'
// import { Action } from '../..'
import { ColumnBody } from './style'

interface Props {
  handleRequest?: (action: Action, filterValues: [{ key: string; value: string }]) => void
  statusSelected?: string[]
}

export const StatusFilter = ({ handleRequest, statusSelected }: Props) => {
  const handleOnClickFilter = (value) => {
    if (handleRequest) {
      handleRequest(Action.AddFilter, [{ key: 'registrationStatus', value: value }])
    }
  }

  return (
    <>
      <ColumnBody>
        <Radio
          onChange={handleOnClickFilter}
          options={[{ value: 'COMPLETE', label: 'Cadastro Completo' }]}
          name="registrationStatus"
          defaultValue={statusSelected?.toString()}
        />
        <Radio
          onChange={handleOnClickFilter}
          options={[{ value: 'PENDING', label: 'Cadastro Pendente' }]}
          name="registrationStatus"
          defaultValue={statusSelected?.toString()}
        />
      </ColumnBody>
    </>
  )
}
