import styled from "styled-components";
import { colors } from "../../styles/theme"

type StyledTagProps = { 
   borderColor?: string;
   textColor?: string;
}

export const StyledTag = styled.div<StyledTagProps>`
   width: 100%;
   padding: 0.3rem;
   color: ${props => props.textColor ?? colors.primary};
   border: 2px solid ${props => props.borderColor ?? colors.lightPink};
   border-radius: 20px 20px 20px 5px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-weight: 500;
   font-size: 1rem;
   letter-spacing: 2px;
`