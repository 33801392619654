export const kinships = [
  { label: 'Cônjuge', value: 'SPOUSE' },
  { label: 'Filho/Filha', value: 'CHILDREN' },
  { label: 'Pai', value: 'FATHER' },
  { label: 'Mãe', value: 'MOTHER' },
  { label: 'Padrasto', value: 'STEPFATHER' },
  { label: 'Madastra', value: 'STEPMOTHER' },
  { label: 'Tio', value: 'UNCLE' },
  { label: 'Tia', value: 'AUNT' },
  { label: 'Sogro', value: 'FATHER-IN-LAW' },
  { label: 'Sogra', value: 'MOTHER-IN-LAW' },
  { label: 'Irmãos', value: 'SIBLINGS' },
  { label: 'Neto', value: 'GRANDSON' },
  { label: 'Neta', value: 'GRANDDAUGHTER' },
  { label: 'Cunhado', value: 'BROTHER-IN-LAW' },
  { label: 'Cunhada', value: 'SISTER-IN-LAW' },
  { label: 'Genro', value: 'SON-IN-LAW' },
  { label: 'Nora', value: 'DAUGHTER-IN-LAW' },
  { label: 'Sobrinho/Sobrinha', value: 'NEPHEWS' },
  { label: 'Enteados', value: 'STEPCHILDREN' },
  { label: 'Outros', value: 'OTHERS' },
]
