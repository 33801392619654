import { QueryFunctionContext, queryOptions } from '@tanstack/react-query'

import { api } from '../../http'
import { GetCatalogInsurersDto } from '../dtos'

type GetCatalogInsurerBaseRequest = {
  modalityId?: string | null
  administratorId?: string | null
}

type GetCatalogInsurerRequest = QueryFunctionContext & GetCatalogInsurerBaseRequest

async function getCatalogInsurer({
  administratorId,
  modalityId,
  signal,
}: GetCatalogInsurerRequest) {
  const response = await api.get<GetCatalogInsurersDto>(
    `${import.meta.env.VITE_CATALOGO_API_URL}/operadoras`,
    {
      signal,
      headers: { Authorization: localStorage.getItem('authToken') },
      params: {
        hasModalidadeId: modalityId,
        ...(administratorId ? { hasAdministradoraId: administratorId } : {}),
      },
    },
  )
  return response.data
}

export function getCatalogInsurerOptions({
  modalityId,
  administratorId,
  enabled = false,
}: GetCatalogInsurerBaseRequest & { enabled?: boolean }) {
  return queryOptions({
    queryKey: ['catalog', 'insurers', { modalityId, administratorId }] as const,
    queryFn: async (params) => getCatalogInsurer({ ...params, modalityId, administratorId }),
    enabled: !!modalityId && enabled,
    refetchOnWindowFocus: false,
    select(data) {
      return data.data
        .map((insurer) => ({
          label: insurer.nome,
          value: insurer.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    },
  })
}
