import { dependentAmilHealthAndDentalSchema } from './amil'
import { dependentBradescoHealthAndDentalSchema } from './bradesco'
import { dependentDefaultHealthAndDentalSchema } from './default'
import { dependentGndiHealthAndDentalSchema } from './gndi'
import { dependentPortoSeguroHealthAndDentalSchema } from './portoSeguro'
import { dependentSulamericalHealthAndDentalSchema } from './sulamerica'

export const dependentHealthAndDentalSchemas = {
  amil: dependentAmilHealthAndDentalSchema,
  gndi: dependentGndiHealthAndDentalSchema,
  portoSeguro: dependentPortoSeguroHealthAndDentalSchema,
  sulamerica: dependentSulamericalHealthAndDentalSchema,
  bradesco: dependentBradescoHealthAndDentalSchema,
  default: dependentDefaultHealthAndDentalSchema,
}
