export const cancelReasonsAmil = [
  { label: '25 - A PEDIDO DO CLIENTE - PJ', value: '25 - A PEDIDO DO CLIENTE - PJ' },
  {
    label: '32 - AÇÃO CANCELAMENTO ADESÃO - ALTA HOSPITAL',
    value: '32 - AÇÃO CANCELAMENTO ADESÃO - ALTA HOSPITAL',
  },
  {
    label: '33 - AÇÃO CANCELAMENTO ADESÃO - JURÍDICO',
    value: '33 - AÇÃO CANCELAMENTO ADESÃO - JURÍDICO',
  },
  { label: '31 - APOSENTADORIA', value: '31 - APOSENTADORIA' },
  { label: '3 - ATENDIMENTO', value: '3 - ATENDIMENTO' },
  { label: '52 - CADASTRO INCORRETO PJ', value: '52 - CADASTRO INCORRETO PJ' },
  { label: '57 - CANCELAMENTO DO CONTRATO', value: '57 - CANCELAMENTO DO CONTRATO' },
  { label: '95 - CANCELAMENTO RN 557', value: '95 - CANCELAMENTO RN 557' },
  { label: '19 - CARENCIA CONTRATUAL', value: '19 - CARENCIA CONTRATUAL' },
  { label: '21 - CLIENTE NÃO LOCALIZADO', value: '21 - CLIENTE NÃO LOCALIZADO' },
  { label: '98 - DECISÃO OPERADORA', value: '98 - DECISÃO OPERADORA' },
  { label: '1 - DEMISSAO', value: '1 - DEMISSAO' },
  {
    label: '91 - DEPENDÊNCIA NÃO PREVISTA PARA REMISSÃO',
    value: '91 - DEPENDÊNCIA NÃO PREVISTA PARA REMISSÃO',
  },
  { label: '27 - DESISTÊNCIA DA CONTRATAÇÃO', value: '27 - DESISTÊNCIA DA CONTRATAÇÃO' },
  { label: '16 - DESMEMBRAMENTO', value: '16 - DESMEMBRAMENTO' },
  { label: '6 - DOLO', value: '6 - DOLO' },
  { label: '22 - ENCERRAMENTO DE OPERAÇOES', value: '22 - ENCERRAMENTO DE OPERAÇOES' },
  { label: '23 - ENTRADA SEM CONFIRMACAO', value: '23 - ENTRADA SEM CONFIRMACAO' },
  {
    label: '60 - EXCLUSÃO AUTOMÁTICA POR SUSPENSÃO ADM',
    value: '60 - EXCLUSÃO AUTOMÁTICA POR SUSPENSÃO ADM',
  },
  { label: '26 - EXCLUSÃO PERDA DE ELEGIBILIDADE', value: '26 - EXCLUSÃO PERDA DE ELEGIBILIDADE' },
  { label: '43 - EXCLUSÃO POR MAIORIDADE', value: '43 - EXCLUSÃO POR MAIORIDADE' },
  { label: '44 - EXCLUSÂO POR VINCULO CAGED', value: '44 - EXCLUSÂO POR VINCULO CAGED' },
  { label: '2 - EXCLUSAO VOLUNTARIA', value: '2 - EXCLUSAO VOLUNTARIA' },
  { label: '7 - FRAUDE', value: '7 - FRAUDE' },
  { label: '9 - INADIMPLENCIA', value: '9 - INADIMPLENCIA' },
  {
    label: '42 - INADIMPLÊNCIA DIAS NÃO CONSECUTIVOS',
    value: '42 - INADIMPLÊNCIA DIAS NÃO CONSECUTIVOS',
  },
  {
    label: '75 - INSATISFAÇÃO COM ATENDIMENTO  HOSPITAIS',
    value: '75 - INSATISFAÇÃO COM ATENDIMENTO  HOSPITAIS',
  },
  {
    label: '76 - INSATISFAÇÃO COM ATENDIMENTO ERRO DO C',
    value: '76 - INSATISFAÇÃO COM ATENDIMENTO ERRO DO C',
  },
  {
    label: '74 - INSATISFAÇÃO COM ATENDIMENTO OPERADORA',
    value: '74 - INSATISFAÇÃO COM ATENDIMENTO OPERADORA',
  },
  {
    label: '73 - INSATISFAÇÃO COM ATENDIMENTO PRESTADOR',
    value: '73 - INSATISFAÇÃO COM ATENDIMENTO PRESTADOR',
  },
  { label: '51 - INSATISFACAO DO USUARIO', value: '51 - INSATISFACAO DO USUARIO' },
  { label: '15 - JUNÇÃO DE CONTRATO', value: '15 - JUNÇÃO DE CONTRATO' },
  { label: '50 - MOTIVO FINANCEIRO', value: '50 - MOTIVO FINANCEIRO' },
  { label: '5 - MUDANCA DE ESTADO', value: '5 - MUDANCA DE ESTADO' },
  { label: '82 - OBITO - PJ', value: '82 - OBITO - PJ' },
  { label: '99 - OUTROS', value: '99 - OUTROS' },
  { label: '71 - PACOTE DE BENEFÍCIOS', value: '71 - PACOTE DE BENEFÍCIOS' },
  {
    label: '45 - PERDA DE ELEGIBILIDADE DEPENDENTE',
    value: '45 - PERDA DE ELEGIBILIDADE DEPENDENTE',
  },
  { label: '24 - PORTABILIDADE', value: '24 - PORTABILIDADE' },
  { label: '4 - REDE CREDENCIADA', value: '4 - REDE CREDENCIADA' },
  { label: '92 - REMISSÃO', value: '92 - REMISSÃO' },
  { label: '13 - REPIQUE DE VENDA', value: '13 - REPIQUE DE VENDA' },
  {
    label: '72 - RESCISAO DENTAL POR MOTIVOS DO MEDICINA',
    value: '72 - RESCISAO DENTAL POR MOTIVOS DO MEDICINA',
  },
  { label: '8 - RESCISÃO PELA OPERADORA', value: '8 - RESCISÃO PELA OPERADORA' },
  {
    label: '70 - RESCISÃO PELA OPERADORA - AÇÃO JUDICIAL',
    value: '70 - RESCISÃO PELA OPERADORA - AÇÃO JUDICIAL',
  },
  { label: '18 - SEPARAÇÃO', value: '18 - SEPARAÇÃO' },
  { label: '41 - SUSPENSÃO POR INADIMPLÊNCIA', value: '41 - SUSPENSÃO POR INADIMPLÊNCIA' },
  { label: '17 - TRANSF. DE TITULARIDADE', value: '17 - TRANSF. DE TITULARIDADE' },
  { label: '11 - TRANSF. P. FISICA', value: '11 - TRANSF. P. FISICA' },
  { label: '10 - TRANSF. P. JURIDICA', value: '10 - TRANSF. P. JURIDICA' },
  { label: '90 - TROCA CONTRATO/PRODUTO', value: '90 - TROCA CONTRATO/PRODUTO' },
  { label: '12 - TROCA PRODUTO', value: '12 - TROCA PRODUTO' },
  { label: '81 - TROCA TITULARIDADE', value: '81 - TROCA TITULARIDADE' },
  { label: '14 - VENDA INCORRETA', value: '14 - VENDA INCORRETA' },
  { label: '29 - RN 561 EXCLUSÃO A PEDIDO PJ', value: '29 - RN 561 EXCLUSÃO A PEDIDO PJ' },
]
