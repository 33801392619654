import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'

import type { GetSchemaProps } from '../..'
import { insurersSulamerica } from '../../../helper/sulamerica/insurers'
import { kinships } from '../../../helper/sulamerica/kinships'

export function dependentSulamericalHealthAndDentalSchema({
  countries,
}: GetSchemaProps): DynamicFormSchemaProps {
  return {
    formContext: {
      hint: '*Campos de preenchimento obrigatório apenas para a emissão automática e/ou cadastro no sisweb',
    },
    schema: {
      type: 'object',
      properties: {
        name: {
          type: ['string', 'null'],
          title: 'Nome Completo*',
        },
        holderKinship: {
          type: ['string', 'null'],
          title: 'Grau de Parentesco*',
          oneOf: kinships.map((kinship) => ({
            title: kinship.label,
            const: kinship.value,
          })),
        },
        maritalStatus: {
          type: ['string', 'null'],
          title: 'Estado Civil*',
          oneOf: [
            { title: 'Solteiro(a)', const: 'SINGLE' },
            { title: 'Casado(a)', const: 'MARRIED' },
            { title: 'Viúvo(a)', const: 'WIDOWED' },
            { title: 'Divorciado(a)', const: 'DIVORCED' },
            { title: 'Outros', const: 'OTHERS' },
          ],
        },
        gender: {
          type: ['string', 'null'],
          title: 'Gênero*',
          oneOf: [
            { title: 'Feminino', const: 'FEMALE' },
            { title: 'Masculino', const: 'MALE' },
          ],
        },
        cpf: {
          type: ['string', 'null'],
          title: 'CPF*',
        },
        rg: {
          type: ['string', 'null'],
          title: 'RG*',
        },
        birthDate: {
          type: ['string', 'null'],
          title: 'Data de Nascimento*',
          format: 'date',
        },
        nationality: {
          type: ['string', 'null'],
          title: 'Nacionalidade*',
          oneOf: countries?.map((country) => ({
            title: country.label,
            const: country.value,
          })),
        },
        motherName: {
          type: ['string', 'null'],
          title: 'Nome da Mãe*',
        },
        isAdministrativeLife: {
          type: ['boolean', 'null'],
          title: 'É uma vida administrativa?',
          oneOf: [
            { title: 'Sim', const: true },
            { title: 'Não', const: false },
          ],
          default: null,
        },
        healthPlan: {
          type: 'object',
          properties: {
            planName: {
              type: ['string', 'null'],
              title: 'Plano Médico*',
              oneOf: [{ title: '', const: '' }],
            },
            accommodationType: {
              type: ['string', 'null'],
              title: 'Acomodação*',
              oneOf: [
                { title: 'Apartamento', const: 'Apartamento' },
                { title: 'Enfermaria', const: 'Enfermaria' },
                { title: 'Odonto', const: 'Odonto' },
              ],
            },
            lifeAmount: {
              type: ['string', 'null'],
              title: 'Valor Vida Saúde*',
            },
          },
        },
        dentalPlan: {
          type: 'object',
          properties: {
            planName: {
              type: ['string', 'null'],
              title: 'Plano Dental*',
              oneOf: [{ title: '', const: '' }],
            },
            accommodationType: {
              type: ['string', 'null'],
              title: 'Acomodação Odonto*',
              oneOf: [
                { title: 'Apartamento', const: 'Apartamento' },
                { title: 'Enfermaria', const: 'Enfermaria' },
                { title: 'Odonto', const: 'Odonto' },
              ],
            },
            lifeAmount: {
              type: ['string', 'null'],
              title: 'Valor Vida Odonto*',
            },
          },
        },
        hiredHealthPlan: {
          type: 'object',
          properties: {
            isActive: {
              type: 'boolean',
              title: 'Possui plano médico?',
              oneOf: [
                { title: 'Sim', const: true },
                { title: 'Não', const: false },
              ],
              default: false,
            },
          },
          dependencies: {
            isActive: {
              oneOf: [
                {
                  properties: {
                    isActive: {
                      enum: [false],
                    },
                  },
                },
                {
                  properties: {
                    isActive: {
                      enum: [true],
                    },
                    hiredHealthSubtitle: {
                      type: ['string', 'null'],
                      title: 'Dados do Plano Médico Atual',
                    },
                    registrationNumber: {
                      type: ['string', 'null'],
                      title: 'Matrícula',
                    },
                    activeHiredPlanInsurer: {
                      type: ['string', 'null'],
                      title: 'Operadora Atual',
                      oneOf: insurersSulamerica.map((insurer) => ({
                        title: insurer.label,
                        const: insurer.value,
                      })),
                    },
                    hiredAt: {
                      type: ['string', 'null'],
                      format: 'date',
                      title: 'Data de Início*',
                    },
                    lastPaymentAt: {
                      type: ['string', 'null'],
                      format: 'date',
                      title: 'Data do Último Pagamento',
                    },
                  },
                },
              ],
            },
          },
        },
        hiredDentalPlan: {
          type: 'object',
          properties: {
            isActive: {
              type: 'boolean',
              title: 'Possui plano odontológico?',
              oneOf: [
                { title: 'Sim', const: true },
                { title: 'Não', const: false },
              ],
              default: false,
            },
          },
          dependencies: {
            isActive: {
              oneOf: [
                {
                  properties: {
                    isActive: {
                      enum: [false],
                    },
                  },
                },
                {
                  properties: {
                    isActive: {
                      enum: [true],
                    },
                    hiredDentalSubtitle: {
                      type: ['string', 'null'],
                      title: 'Dados do Plano Odontológico Atual',
                    },
                    registrationNumber: {
                      type: ['string', 'null'],
                      title: 'Matrícula',
                    },
                    activeHiredPlanInsurer: {
                      type: ['string', 'null'],
                      title: 'Operadora Atual',
                      oneOf: insurersSulamerica.map((insurer) => ({
                        title: insurer.label,
                        const: insurer.value,
                      })),
                    },
                    hiredAt: {
                      type: ['string', 'null'],
                      format: 'date',
                      title: 'Data de Início*',
                    },
                    lastPaymentAt: {
                      type: ['string', 'null'],
                      format: 'date',
                      title: 'Data do Último Pagamento',
                    },
                  },
                },
              ],
            },
          },
        },
      },
      if: {
        properties: {
          maritalStatus: { const: 'MARRIED' },
        },
      },
      then: {
        properties: {
          marriageDate: {
            type: ['string', 'null'],
            title: 'Data do casamento*',
            format: 'date',
          },
        },
      },
    },
    uiSchema: {
      'ui:order': ['name', 'holderKinship', 'maritalStatus', 'marriageDate', '*'],
      name: {
        'ui:placeholder': 'Ex.: Maria da Silva',
      },
      maritalStatus: {
        'ui:placeholder': 'Selecione o estado civil',
      },
      marriageDate: {
        'ui:placeholder': '00/00/0000',
      },
      holderKinship: {
        'ui:placeholder': 'Selecione o grau de parentesco',
      },
      gender: {
        'ui:placeholder': 'Selecione o gênero',
      },
      cpf: {
        'ui:placeholder': '000.000.000-00',
        'ui:options': {
          mask: 'cpf',
        },
      },
      rg: {
        'ui:placeholder': '000000000',
      },
      birthDate: {
        'ui:placeholder': '00/00/0000',
      },
      nationality: {
        'ui:placeholder': 'Selecione a Nacionalidade',
      },
      motherName: {
        'ui:placeholder': 'Ex.: Ana da Silva',
      },
      isAdministrativeLife: {
        'ui:widget': 'radio',
      },
      healthPlan: {
        'ui:options': {
          colSpan: 2,
        },
        planName: {
          'ui:disabled': true,
          'ui:placeholder': 'Selecione o plano',
        },
        accommodationType: {
          'ui:placeholder': 'Selecione a acomodação',
        },
        lifeAmount: {
          'ui:placeholder': 'R$ 00,00',
          'ui:options': {
            mask: 'money',
          },
        },
      },
      dentalPlan: {
        'ui:options': {
          colSpan: 2,
        },
        planName: {
          'ui:placeholder': 'Selecione o plano',
          'ui:disabled': true,
        },
        accommodationType: {
          'ui:placeholder': 'Selecione a acomodação odonto',
        },
        lifeAmount: {
          'ui:placeholder': 'R$ 00,00',
          'ui:options': {
            mask: 'money',
          },
        },
      },
      hiredHealthPlan: {
        'ui:options': {
          colSpan: 2,
        },
        isActive: {
          'ui:widget': 'radio',
          'ui:options': {
            colSpan: 2,
          },
        },
        hiredHealthSubtitle: {
          'ui:widget': 'SubtitleWidget',
        },
        registrationNumber: {
          'ui:placeholder': 'Nº de matrícula',
        },
        activeHiredPlanInsurer: {
          'ui:placeholder': 'Selecione a operadora atual',
        },
        hiredAt: {
          'ui:placeholder': '00/00/0000',
        },
        lastPaymentAt: {
          'ui:placeholder': '00/00/0000',
        },
      },
      hiredDentalPlan: {
        'ui:options': {
          colSpan: 2,
        },
        isActive: {
          'ui:widget': 'radio',
          'ui:options': {
            colSpan: 2,
          },
        },
        hiredDentalSubtitle: {
          'ui:widget': 'SubtitleWidget',
        },
        registrationNumber: {
          'ui:placeholder': 'Nº de matrícula',
        },
        activeHiredPlanInsurer: {
          'ui:placeholder': 'Selecione a operadora atual',
        },
        hiredAt: {
          'ui:placeholder': '00/00/0000',
        },
        lastPaymentAt: {
          'ui:placeholder': '00/00/0000',
        },
      },
    },
  }
}
