/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'

import { Select } from 'antd'
import CloseIcon from 'design-system/close'
import { Button, Skeleton, Toast } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'
import WarningIcon from 'design-system/warning'

import { healthPlansOrderRequest } from '@/App/clients/healthPlansOrder'
import { HealthPlansOrdersRequests } from '@/App/clients/healthPlansOrders'
import {
  FillOrderInformationBody,
  GetOrderInformationBody,
  GetOrderResponsibleAdmins,
} from '@/App/clients/healthPlansOrders/dtos'
import { useOrderInformationQuery } from '@/App/clients/healthPlansOrders/queries/orderInformation'
import useFetch from '@/App/clients/http'
import { useUpdateStatus } from '@/App/clients/orderState/mutation/updateStatus'

import {
  ButtonDiv,
  CancelButton,
  ModalContent,
  ModalSubTitle,
  ModalTitle,
  ResponsibleAdminTag,
  ResponsibleContent,
  SelectContainer,
  StyledIcon,
  StyledTeamOutlined,
  TagsContainer,
  WithoutResponsibleContent,
} from './style'

interface WaitingSignatureProps {
  setIsModalOpen?: (value: boolean) => void
  orderId?: string
  setShouldReload?: React.Dispatch<React.SetStateAction<boolean>>
  setShouldFillResponsibleAdminsIds?: React.Dispatch<React.SetStateAction<boolean>>
  setOrderStatus?: React.Dispatch<React.SetStateAction<string | null>>
  shouldFillResponsibleAdminsIds?: boolean
}

export const WaitingSignatureModal: React.FC<WaitingSignatureProps> = ({
  setIsModalOpen,
  orderId,
  setShouldReload,
  setShouldFillResponsibleAdminsIds,
  setOrderStatus,
  shouldFillResponsibleAdminsIds,
}) => {
  const [selectResponsibleAdmins, setSelectResponsibleAdmins] = useState<
    Array<{ label?: string; value: string }>
  >([])
  const [currentResponsibleAdmins, setCurrentResponsibleAdmins] = useState<
    Array<{ label?: string; id: string }>
  >([])

  const orderInformationQuery = useOrderInformationQuery({ orderId })
  const updateStatusMutation = useUpdateStatus(orderId)

  const {
    setRequestConfig: setFillOrderResponsibleAdminsRequestConfig,
    isLoading: isLoadingResponsibleAdmins,
    statusCode: fillOrderResponsibleAdminsStatusCode,
  } = useFetch<FillOrderInformationBody>()

  const {
    setRequestConfig: setGetOrderByIdRequestConfig,
    data: orderData,
    statusCode: getOrderByIdStatusCode,
    isLoading: isLoadingGetOrderById,
  } = useFetch<GetOrderInformationBody>()

  const {
    setRequestConfig: setGetResponsibleAdminsRequestConfig,
    isLoading: isLoadingResponsibleAdminData,
    data: responsibleAdminData,
  } = useFetch<GetOrderResponsibleAdmins>()

  useEffect(() => {
    const getOrderByIdRequestConfig = HealthPlansOrdersRequests.getOrderById(orderId)
    setGetOrderByIdRequestConfig(getOrderByIdRequestConfig)
  }, [])
  useEffect(() => {
    const responsibleAdminsRequestConfig = healthPlansOrderRequest.getAdminsByFilters()
    setGetResponsibleAdminsRequestConfig(responsibleAdminsRequestConfig)
  }, [])

  useEffect(() => {
    if (shouldFillResponsibleAdminsIds) {
      const getOrderByIdRequestConfig = HealthPlansOrdersRequests.getOrderById(orderId)
      setGetOrderByIdRequestConfig(getOrderByIdRequestConfig)
    }
  }, [shouldFillResponsibleAdminsIds])

  useEffect(() => {
    if (orderData != null) {
      assignCurrentResponsibleAdmins()
    }
    if (responsibleAdminData != null) {
      const rawAdminsIds = responsibleAdminData.admins.map((admin) => admin.id)
      const responsibleAdminsIds = orderData?.responsibleAdmins?.map((admin) => admin.id)

      const targetIds = rawAdminsIds.filter((id) => !responsibleAdminsIds?.includes(id))
      const availableAdmins = targetIds.map((option) => {
        const targetLabel = responsibleAdminData.admins.find(
          (rawAdmin) => rawAdmin.id === option,
        )?.name

        return { label: targetLabel, value: option }
      })
      setSelectResponsibleAdmins(availableAdmins)
    }
  }, [orderData, getOrderByIdStatusCode])

  useEffect(() => {
    if (fillOrderResponsibleAdminsStatusCode === 200) {
      const rawAdminsIds = responsibleAdminData?.admins.map((admin) => admin.id)
      const responsibleAdminsIds = currentResponsibleAdmins.map((admin) => admin.id)

      const targetIds = rawAdminsIds?.filter((id) => !responsibleAdminsIds.includes(id))
      const availableAdmins = targetIds?.map((option) => {
        const targetLabel = responsibleAdminData?.admins.find(
          (rawAdmin) => rawAdmin.id === option,
        )?.name

        return { label: targetLabel, value: option }
      })
      setSelectResponsibleAdmins(availableAdmins ?? [])
    }
  }, [fillOrderResponsibleAdminsStatusCode])

  const assignCurrentResponsibleAdmins = (): void => {
    const admins = orderData?.responsibleAdmins?.map((option) => {
      return { label: option.name, id: option.id }
    })
    setCurrentResponsibleAdmins(admins ?? [])
  }

  const addResponsibleAdmin = (selectedOption: string) => {
    const responsibleAdminsIds = currentResponsibleAdmins.map((admin) => admin.id)
    responsibleAdminsIds.push(selectedOption)

    const targetAdmins = responsibleAdminsIds.map((admin) => {
      const targetLabel = responsibleAdminData?.admins.find(
        (rawAdmin) => rawAdmin.id === admin,
      )?.name

      return { label: targetLabel, id: admin }
    })
    setCurrentResponsibleAdmins(targetAdmins ?? [])

    const fillOrderInformationRequestConfig = HealthPlansOrdersRequests.fillOrderInformation(
      { responsibleAdminsIds: responsibleAdminsIds },
      orderId,
    )

    setFillOrderResponsibleAdminsRequestConfig(fillOrderInformationRequestConfig)
  }

  const deleteResponsibleAdmin = (value: string) => {
    const responsibleAdminsIds = currentResponsibleAdmins.map((item) => item.id)
    const targetResponsibleIds = responsibleAdminsIds.filter((admin) => admin !== value)

    const targetAdmins = targetResponsibleIds.map((option) => {
      const targetLabel = responsibleAdminData?.admins.find(
        (rawAdmin) => rawAdmin.id === option,
      )?.name

      return { label: targetLabel, id: option }
    })

    setCurrentResponsibleAdmins(targetAdmins ?? [])

    const fillOrderInformationRequestConfig = HealthPlansOrdersRequests.fillOrderInformation(
      { responsibleAdminsIds: targetResponsibleIds },
      orderId,
    )
    setFillOrderResponsibleAdminsRequestConfig(fillOrderInformationRequestConfig)
  }

  useEffect(() => {
    if (updateStatusMutation.isSuccess) {
      const getOrderByIdRequestConfig = HealthPlansOrdersRequests.getOrderById(orderId)
      setGetOrderByIdRequestConfig(getOrderByIdRequestConfig)
      setShouldFillResponsibleAdminsIds && setShouldFillResponsibleAdminsIds(true)
      setOrderStatus && setOrderStatus('WAITING_SIGNATURE')
      setIsModalOpen && setIsModalOpen(false)
      triggerToast(true, 'Status notificado ao corretor!')

      if (setShouldReload) setShouldReload(true)
    }
  }, [updateStatusMutation.isSuccess])

  useEffect(() => {
    if (updateStatusMutation.isError) triggerToast(false, 'Erro ao atualizar o status')
  }, [updateStatusMutation.isError])

  const fillOrderStatus = async () => {
    if (orderId) {
      await updateStatusMutation.mutateAsync({
        nextStatus: 'WAITING_SIGNATURE',
        order: orderInformationQuery?.data,
      })
    }
  }

  return (
    <ModalContent>
      <StyledTeamOutlined />
      <ModalTitle>Confirmação de responsáveis pela proposta</ModalTitle>
      <ModalSubTitle>
        Verifique se todos os responsáveis pelo cadastro e implantação desta proposta estão
        alocados. Basta clicar no botão abaixo e incluir novos usuários que estão ausentes.
      </ModalSubTitle>
      {isLoadingGetOrderById || isLoadingResponsibleAdmins || isLoadingResponsibleAdminData ? (
        <SelectContainer>
          <Skeleton type="input" />
        </SelectContainer>
      ) : (
        <ResponsibleContent>
          <SelectContainer>
            <Select
              defaultValue=" + Alocar novo responsável"
              options={selectResponsibleAdmins}
              onChange={(value: string) => addResponsibleAdmin(value)}
            />
          </SelectContainer>
          {currentResponsibleAdmins.length != 0 ? (
            <TagsContainer>
              {currentResponsibleAdmins.map((value, index) => (
                <ResponsibleAdminTag key={index}>
                  {value.label}
                  <StyledIcon onClick={() => deleteResponsibleAdmin(value.id)}>
                    <CloseIcon />
                  </StyledIcon>
                </ResponsibleAdminTag>
              ))}
            </TagsContainer>
          ) : (
            <WithoutResponsibleContent>
              <WarningIcon />
              Proposta sem responsável
            </WithoutResponsibleContent>
          )}
        </ResponsibleContent>
      )}
      <ButtonDiv>
        <Button
          loading={updateStatusMutation.isPending}
          onClick={fillOrderStatus}
          disabled={isLoadingResponsibleAdmins || isLoadingGetOrderById}
        >
          Confirmar
        </Button>
        <CancelButton onClick={() => setIsModalOpen && setIsModalOpen(false)}>
          Cancelar
        </CancelButton>
      </ButtonDiv>
      <Toast />
    </ModalContent>
  )
}
