import { TextLabel } from 'design-system/components'

import { GetBrokersByIdDto } from '@/App/clients/healthPlansOrder/dto'

import { InputDiv, Label } from './style'

interface BankTransferContentProps {
  data?: GetBrokersByIdDto | null
}
export const BankTransferContent = ({ data }: BankTransferContentProps) => {
  return (
    <>
      {data?.paymentInformation?.accountPersonType === 'PERSONAL_ACCOUNT' ? (
        <>
          <InputDiv>
            <Label>Titular da Conta:</Label>
            <TextLabel
              text={data?.paymentInformation?.accountHolderName}
              backgroundColor="#f2f3f7"
            />
          </InputDiv>
          <InputDiv>
            <Label>CPF do titular:</Label>
            <TextLabel
              text={data?.paymentInformation?.accountHolderDocument}
              backgroundColor="#f2f3f7"
              rightIconType="copy"
              type="cpf"
            />
          </InputDiv>
        </>
      ) : (
        <>
          <InputDiv>
            <Label>Nome Fantasia:</Label>
            <TextLabel
              text={data?.paymentInformation?.accountCompanyName}
              backgroundColor="#f2f3f7"
              rightIconType="copy"
            />
          </InputDiv>
          <InputDiv>
            <Label>Número do CNPJ:</Label>
            <TextLabel
              text={data?.paymentInformation?.accountHolderCompanyDocument}
              backgroundColor="#f2f3f7"
              rightIconType="copy"
              type="cnpj"
            />
          </InputDiv>
          <InputDiv>
            <Label>Emissão de nota fiscal:</Label>
            <TextLabel text={data?.issueInvoices ? 'Sim' : 'Não'} backgroundColor="#f2f3f7" />
          </InputDiv>
        </>
      )}
      <InputDiv>
        <Label>Forma de pagamento:</Label>
        <TextLabel text={'Transferência Bancária'} backgroundColor="#f2f3f7" />
      </InputDiv>
      <InputDiv>
        <Label>Banco:</Label>
        <TextLabel
          text={data?.paymentInformation?.bankName}
          backgroundColor="#f2f3f7"
          rightIconType="copy"
        />
      </InputDiv>
      <InputDiv>
        <Label>Tipo de Conta:</Label>
        <TextLabel
          text={
            data?.paymentInformation?.accountType === 'CHECKING_ACCOUNT'
              ? 'Conta Corrente'
              : data?.paymentInformation?.accountType === 'SAVINGS_ACCOUNT'
              ? 'Conta Poupança'
              : 'Não informado'
          }
          backgroundColor="#f2f3f7"
        />
      </InputDiv>
      <InputDiv>
        <Label>Agência:</Label>
        <TextLabel
          text={data?.paymentInformation?.branch}
          backgroundColor="#f2f3f7"
          rightIconType="copy"
        />
      </InputDiv>
      <InputDiv>
        <Label>Conta:</Label>
        <TextLabel
          text={data?.paymentInformation?.accountNumber}
          backgroundColor="#f2f3f7"
          rightIconType="copy"
        />
      </InputDiv>
      <InputDiv>
        <Label>Modalidade:</Label>
        <TextLabel
          text={
            data?.paymentInformation?.accountPersonType === 'PERSONAL_ACCOUNT'
              ? 'Pessoa Física'
              : data?.paymentInformation?.accountPersonType === 'BUSINESS_ACCOUNT'
              ? 'Pessoa Jurídica'
              : 'Não informado'
          }
          rightIconType="copy"
          backgroundColor="#f2f3f7"
        />
      </InputDiv>
    </>
  )
}
