import { useEffect, useState } from 'react'

import type { UseQueryResult } from '@tanstack/react-query'
import { useVariant } from '@unleash/proxy-client-react'
import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'
import { Skeleton } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { useCatalogPlansQuery } from '@/App/clients/catalogApi/queries/catalogPlan'
import { Entity, type GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import { useFillOrderInformation } from '@/App/clients/healthPlansOrders/mutations/fillOrderInformation'
import { useGetCountrysQuery } from '@/App/clients/healthPlansOrders/queries/getCountrys'
import { useDynamicForm } from '@/App/contexts/dynamicForm/DynamicFormProvider'
import { genderPredictionFunction } from '@/App/helpers/genderPrediction'
import { assembleInsurer } from '@/App/utils/assembleInsurer'
import { calculateAge } from '@/App/utils/calculateProcessingTime'
import { toCamelCase } from '@/App/utils/string'

import { AccordionFrame } from './accordion'
import { DispatchEntityExcludeEventConfirmationModal } from './components/DispatchEntityExcludeEventConfirmationModal'
import { DynamicForm } from './components/DynamicForm'
import { HolderGroupAccordionForm } from './components/HolderGroup'
import { getDynamicSchema } from './schemas'

export type EntityType = 'HOLDER' | 'HOLDER_GROUP' | 'DEPENDENT' | 'COMPANY'
export interface EntityAccordionProps {
  type: EntityType
  index?: number
  entityData?: Entity | null
  validateOrderDispatch?: boolean
  validateSiswebDispatch?: boolean
  orderInformationQuery?: UseQueryResult<GetOrderInformationBody, Error>
}

export const EntityAccordion: React.FC<EntityAccordionProps> = ({
  type,
  index,
  entityData,
  validateOrderDispatch,
  orderInformationQuery,
  validateSiswebDispatch,
}) => {
  const [schema, setSchema] = useState<DynamicFormSchemaProps>()

  const odontoPfModalityId = useVariant('bliss-intranet-odonto-query-id')?.payload?.value
  const odontoPmeModalityId = useVariant('bliss-intranet-odonto-pme-query-id')?.payload?.value

  const [isLegalAge, setIsLegalAge] = useState<boolean>()
  const [genderPrediction, setGenderPrediction] = useState<'female' | 'male'>()

  const [isOcrFilled, setIsOcrFilled] = useState<boolean>(false)
  const [isOcrProcessing, setIsOcrProcessing] = useState(false)

  const orderData = orderInformationQuery?.data

  const getPlansQuery = useCatalogPlansQuery({
    insurerId: orderInformationQuery?.data?.quotation?.insurerId,
    modalityId: orderInformationQuery?.data?.quotation?.typeId,
    enabled: !!orderInformationQuery?.data?.quotation,
  })
  const getOdontoPlansQuery = useCatalogPlansQuery({
    insurerId: orderInformationQuery?.data?.quotation?.insurerId,
    modalityId:
      orderInformationQuery?.data?.type === 'PERSON' ? odontoPfModalityId : odontoPmeModalityId,
    enabled: !!orderInformationQuery?.data?.quotation,
  })
  const getCountriesQuery = useGetCountrysQuery()
  const fillOrderInformationMutation = useFillOrderInformation()
  const dynamicFormContext = useDynamicForm()

  const hasEntityCreatedBeforeOrderWasHired =
    String(entityData?.createdAt) < String(orderInformationQuery?.data?.hiredAt)

  useEffect(() => {
    if (getPlansQuery?.isSuccess) {
      dynamicFormContext.setPlanOptions(getPlansQuery?.data)
    }
  }, [getPlansQuery?.isSuccess, getPlansQuery?.data])

  useEffect(() => {
    if (getOdontoPlansQuery?.isSuccess) {
      dynamicFormContext.setOdontoPlanOptions(getOdontoPlansQuery?.data)
    }
  }, [getOdontoPlansQuery?.isSuccess, getOdontoPlansQuery?.data])

  useEffect(() => {
    if (entityData?.name != undefined) {
      setGenderPrediction(
        genderPredictionFunction(entityData.name)?.toLowerCase() as 'female' | 'male',
      )
    }
  }, [entityData?.name])

  useEffect(() => {
    entityData?.documents?.map((doc) => {
      if (
        [
          'RG',
          'CNH',
          'Documento de Identificação',
          'Documento de Identificação*',
          'Documento de identificação com Foto*',
          'Carta de permanência',
          'Carta de Permanência',
          'Comprovante de residência',
        ].includes(doc.type ?? '')
      ) {
        doc.status === 'UPLOADED' && setIsOcrFilled(true)
      }
    })

    if (
      (orderInformationQuery?.data?.lifesQuantity &&
        orderInformationQuery?.data?.lifesQuantity >= 10) ||
      orderData?.isRegisteredInInsurer
    ) {
      setIsOcrFilled(false)
    } else {
      setIsOcrFilled(true)
    }

    entityData?.documents?.map((doc) => {
      if (doc.type === 'Comprovante de residência' && doc.status === 'PENDING') {
        setIsOcrFilled(false)
      }
    })
  }, [
    entityData?.documents,
    orderInformationQuery?.data?.quotation,
    orderData?.isRegisteredInInternalSystem,
  ])

  useEffect(() => {
    if (orderData?.type === 'COMPANY') {
      const allHoldersAreOlderAge = orderData?.holders?.every((holder) => {
        if (!holder?.birthDate) {
          return true
        }
        const holdersCurrentAge = calculateAge(holder.birthDate) >= 18
        return holdersCurrentAge
      })
      setIsLegalAge(allHoldersAreOlderAge)
    } else {
      if (!orderData?.holders?.at(0)?.birthDate) {
        setIsLegalAge(true)
      } else {
        const holderAge = calculateAge(orderData.holders.at(0)?.birthDate)
        holderAge >= 18 ? setIsLegalAge(true) : setIsLegalAge(false)
      }
    }

    if (!isLegalAge && entityData?.birthDate != null) {
      setIsOcrFilled(false)
    }
  }, [orderInformationQuery?.isSuccess, validateSiswebDispatch, entityData, isLegalAge])

  useEffect(() => {
    if (fillOrderInformationMutation.isSuccess) {
      triggerToast(true, 'Dados atualizados com sucesso!')
    } else if (fillOrderInformationMutation.isError) {
      triggerToast(false, 'Ops, ocorreu um erro ao atualizar os dados')
    }
  }, [fillOrderInformationMutation.isSuccess, fillOrderInformationMutation.isError])

  useEffect(
    function loadSchema() {
      let insurer: string | null | undefined
      let product: string | null | undefined

      if (orderInformationQuery?.data) {
        if (orderInformationQuery?.data.quotation) {
          product = toCamelCase(orderInformationQuery?.data.quotation.product)
          insurer = toCamelCase(
            assembleInsurer(
              orderInformationQuery?.data?.quotation?.insurer ??
                orderInformationQuery?.data?.insurer,
            ),
          )
        }
      }

      setSchema(
        getDynamicSchema({
          insurer,
          product,
          isOcrFilled,
          isRegisterInInsurer: orderInformationQuery?.data?.isRegisteredInInsurer,
          genderPrediction,
          planOptions: dynamicFormContext.planOptions,
          odontoPlanOptions: dynamicFormContext.odontoPlanOptions,
          countries: getCountriesQuery?.data,
          orderType: orderInformationQuery?.data?.type,
          isCombinedProposal: orderInformationQuery?.data?.quotation?.isCombinedProposal,
          enableHiredFields:
            orderInformationQuery?.data?.status === 'HIRED' && !hasEntityCreatedBeforeOrderWasHired,
        })[toCamelCase(type) ?? ''],
      )
    },
    [
      orderInformationQuery?.data,
      getCountriesQuery?.data,
      type,
      dynamicFormContext.planOptions,
      dynamicFormContext.odontoPlanOptions,
      isOcrFilled,
      hasEntityCreatedBeforeOrderWasHired,
    ],
  )

  useEffect(() => {
    if (isOcrProcessing) {
      triggerToast(true, 'Dados salvos com sucesso!')
    }
  }, [isOcrProcessing])

  if (orderInformationQuery?.isRefetching && isOcrProcessing) {
    return <Skeleton type="button" size="large" />
  }

  return (
    <>
      <AccordionFrame
        entityType={type}
        entityData={entityData}
        index={index}
        backgroundColor={type !== 'HOLDER_GROUP' ? '#F0F1F4' : null}
        content={() => {
          if (type === 'HOLDER_GROUP') {
            return (
              <HolderGroupAccordionForm
                isOcrFilled={isOcrFilled ?? false}
                entityData={entityData}
                orderInformationQuery={orderInformationQuery}
                validateOrderDispatch={validateOrderDispatch ?? false}
                validateSiswebDispatch={validateSiswebDispatch ?? false}
                isOcrProcessing={isOcrProcessing}
                setIsOcrProcessing={setIsOcrProcessing}
                genderPrediction={genderPrediction}
              />
            )
          }

          return (
            <DynamicForm
              entityData={entityData}
              entityType={type}
              schema={schema}
              financialOfficerId={orderData?.financialOfficer?.id}
              holders={orderInformationQuery?.data?.holders}
            />
          )
        }}
        orderInformationQuery={orderInformationQuery}
        validateOrderDispatch={validateOrderDispatch}
        validateSiswebDispatch={validateSiswebDispatch}
        setIsOcrProcessing={setIsOcrProcessing}
        isOcrProcessing={isOcrProcessing}
      />
      <DispatchEntityExcludeEventConfirmationModal orderData={orderData} />
    </>
  )
}
