import { Link } from 'react-router-dom'

import styled from 'styled-components'

export const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    text-align: center;
    line-height: 2rem;
    padding: 1rem;
    & > :nth-child(1) {
      display: none;
    }
  }
`

export const Column = styled.div`
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row-reverse;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 500px;
  row-gap: 10px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const StyledIlustration = styled.img`
  width: 100%;
  @media (max-width: 768px) {
    display: none;
  }
`

export const StyledLogo = styled.img`
  width: 100%;
`

export const DivLogin = styled.div`
  @media (max-width: 500px) {
  }
`
export const LogoSubtitle = styled.p``

export const StyledTag = styled.div`
  @media (max-width: 500px) {
  }
`

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

export const StyledReset = styled(Link)`
  text-decoration: underline;
  text-decoration: none;
  color: #4b4b4b;
  text-align: center;
`

export const StyledP = styled.p`
  text-align: center;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
`
