import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { CaretDownOutlined } from '@ant-design/icons'

import {
  BoxGroup,
  HeaderProfile,
  HeaderProfileMobile,
  IconeMenu,
  LabelName,
  LabelNameMobile,
  Logo,
  Menu,
  StyledProfile,
  StyledProfileName,
} from './style'

interface MenuItem {
  id: number
  label: string
  onClick?: () => void
}

export interface ProfileProps {
  logoImage?: string
  items?: MenuItem[]
  name?: string
  type: string
  color?: string
  fallbackAvatar?: string
}

const Profile: React.FC<ProfileProps> = ({
  logoImage,
  items,
  type,
  name,
  color,
  fallbackAvatar,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const [visible, setVisible] = useState(false)
  const toggleVisibility = () => {
    setVisible((current) => !current)
  }

  const DesktopHeader = () => {
    return (
      <HeaderProfile>
        <Logo>
          {logoImage ? (
            <img src={logoImage} alt="profileImage" />
          ) : (
            <StyledProfileName color={color}>{fallbackAvatar}</StyledProfileName>
          )}
        </Logo>

        <BoxGroup>
          <LabelName>{name}</LabelName>
          <span>{type}</span>
        </BoxGroup>

        <IconeMenu onClick={toggleVisibility}>
          <CaretDownOutlined />
        </IconeMenu>
      </HeaderProfile>
    )
  }

  const MobileHeader = () => {
    return (
      <HeaderProfileMobile>
        <LabelNameMobile>{name}</LabelNameMobile>
        <Logo>
          {logoImage ? (
            <img alt="profileImage" src={logoImage} />
          ) : (
            <StyledProfileName color={color}>{fallbackAvatar}</StyledProfileName>
          )}
        </Logo>
      </HeaderProfileMobile>
    )
  }

  return (
    <StyledProfile>
      {isMobile ? <MobileHeader /> : <DesktopHeader />}
      {visible && (
        <Menu role="menu">
          <ul>
            {items?.map((item) => (
              <li key={item.id}>
                <a href="#" onClick={item.onClick}>
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        </Menu>
      )}
    </StyledProfile>
  )
}

export default Profile
