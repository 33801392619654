import { memo, useEffect, useState } from 'react'

import { Skeleton, Tooltip } from 'design-system/components'

import { DispatchEvent } from '@/App/clients/healthPlansOrders/dtos'
import { useOrderDispatchEvents } from '@/App/clients/healthPlansOrders/queries/orderDispatchEvents'
import { calculateProcessingTime } from '@/App/utils/calculateProcessingTime'

import { StatusStyledWithSubstatus } from './style'

export interface StatusCellProps {
  sisweb?: string
  status?: string | null
  orderId?: string
  isDispatchedToInternalSystem?: boolean
}

function OrderSiswebStatusComponente({
  sisweb,
  orderId,
  status,
  isDispatchedToInternalSystem,
}: StatusCellProps) {
  const [healthEvent, setHealthEvent] = useState<DispatchEvent>()
  const [dentalEvent, setDentalEvent] = useState<DispatchEvent>()

  const orderDispatchEventQuery = useOrderDispatchEvents({
    orderId,
    isDispatchedToInternalSystem,
    status,
  })

  const healthOrDentalSuccess =
    healthEvent?.processingStatus === 'SUCCESS' ? healthEvent : dentalEvent
  const healthOrDentalFailed = healthEvent?.processingStatus === 'ERROR' ? healthEvent : dentalEvent

  const healthOrDentalPending =
    healthEvent?.processingStatus === 'PENDING' ? healthEvent : dentalEvent

  useEffect(() => {
    if (orderDispatchEventQuery?.data && orderDispatchEventQuery.data.length > 0) {
      orderDispatchEventQuery.data?.map((event) => {
        if (event.type === 'INCLUSION' && event.product === 'HEALTH') {
          setHealthEvent(event)
        }
        if (event.type === 'INCLUSION' && event.product === 'DENTAL') {
          setDentalEvent(event)
        }
      })
    }
  }, [orderDispatchEventQuery?.data])

  const ProcessingStatusTooltip = ({ event }) => (
    <Tooltip
      key="id"
      placement="bottom"
      title={`Status do processamento: ${event?.label} ${calculateProcessingTime(
        event?.createdAt,
        event?.processingStatus,
        event?.updatedAt,
      )}`}
    >
      {event?.Icon()}
    </Tooltip>
  )

  return (
    <StatusStyledWithSubstatus>
      {orderDispatchEventQuery.isLoading ? (
        <Skeleton type="avatar" />
      ) : (
        <>
          <p>{sisweb}</p>
          {healthEvent?.processingStatus === 'ERROR' ||
          dentalEvent?.processingStatus === 'ERROR' ? (
            <ProcessingStatusTooltip event={healthOrDentalFailed} />
          ) : healthEvent?.processingStatus === 'PENDING' ||
            dentalEvent?.processingStatus === 'PENDING' ? (
            <ProcessingStatusTooltip event={healthOrDentalPending} />
          ) : (
            <ProcessingStatusTooltip event={healthOrDentalSuccess} />
          )}
        </>
      )}
    </StatusStyledWithSubstatus>
  )
}

export const OrderSisweb = memo(OrderSiswebStatusComponente, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps)
})
