import { Address, Entity } from '../clients/healthPlansOrders/dtos'

export const transformEntityAddressToAddress = (addressInput?: string): Address => {
  if (!addressInput) {
    return {
      street: null,
      number: null,
      neighborhood: null,
      city: null,
      state: null,
    }
  }

  const addressWithNumberPatternRegex = /(.*),\s*(\d+)\s*-\s*([^,]+),\s*([^,]+)\s*-\s*(\w{2})/
  const addressWithoutNumberPatternRegex = /(.*)-\s*([^,]+),\s*([^,]+)\s*-\s*(\w{2})/
  const addressWithoutNumberFromDatabasePatternRegex = /(.*),\s*([^,]+),\s*([^,]+)\s*,\s*(\w{2})/
  const adressOfhighwayPatternRegex = /(.*),\s*([^,]+)(?:,\s*([^,]+))?\s*-\s*(\w{2})/
  const match =
    addressInput.match(addressWithNumberPatternRegex) ||
    addressInput.match(addressWithoutNumberPatternRegex) ||
    addressInput.match(addressWithoutNumberFromDatabasePatternRegex) ||
    addressInput.match(adressOfhighwayPatternRegex)

  if (!match) {
    console.warn('Could not parse countryside address. Sending as street -->', addressInput)
    return {
      street: addressInput,
    }
  }

  const neighborhoodNull = match[3]

  if (neighborhoodNull === undefined) {
    const [_ignored, street, city, neighborhood, state] = match

    return {
      street: street,
      number: 'S/N',
      neighborhood: neighborhood,
      city: city,
      state: state,
    }
  }

  const ADDRESS_WITHOUT_NUMBER_MATCH_LENGTH = 5
  if (match.length === ADDRESS_WITHOUT_NUMBER_MATCH_LENGTH) {
    const [_ignored, street, neighborhood, city, state] = match

    return {
      street: street,
      number: 'S/N',
      neighborhood: neighborhood,
      city: city,
      state: state,
    }
  }

  const [_ignored, street, number, neighborhood, city, state] = match

  return {
    street: street,
    number: number || null,
    neighborhood: neighborhood,
    city: city,
    state: state,
  }
}

export function transformAddressToEntityAddress(address?: Entity['address']) {
  if (address == null) {
    return {
      address: {
        zipCode: null,
        number: null,
        entityAddress: null,
      },
    }
  }

  const { city, neighborhood, state, street } = address

  if (city === null) {
    return {
      address: {
        ...address,
        entityAddress: street ? street : null,
      },
    }
  }

  if (neighborhood === null) {
    const hasAllInfo = city && state && street

    return {
      address: {
        ...address,
        entityAddress: hasAllInfo ? `${street}, ${city}, ${state}` : null,
      },
    }
  }

  const hasAllInfo = city && neighborhood && state && street

  return {
    address: {
      ...address,
      entityAddress: hasAllInfo ? `${street}, ${neighborhood}, ${city}, ${state}` : null,
    },
  }
}
