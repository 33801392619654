import styled from 'styled-components'
interface StyledTextLabelProps {
  backgroundColor?: string
  hasLeftIcon?: boolean
}
export const StyledTextLabel = styled.div<StyledTextLabelProps>`
  align-items: center;
  background: ${(props) => props.backgroundColor ?? '#FFFF'};
  border-radius: 10px;
  border: 1px solid #dcdfeb;
  display: flex;
  height: 100%;
  margin: 5px;
  padding: ${(props) => (props.hasLeftIcon ? '0 5px 0 5px' : '0px 5px 0 0')};
  .anticon {
    color: #514984;
    font-size: 100%;
  }
`
export const StyledLabel = styled.div`
  color: #4b4b4b;
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
`
export const StyledCopy = styled.div`
  cursor: pointer;
  margin-left: auto;
`
export const StyledCopiedMessage = styled.span`
  color: #514984;
  font-size: 10px;
  margin-right: 3px;
`
