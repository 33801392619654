import styled from 'styled-components'

export interface ActionCardProps {
  checked?: boolean
}

export interface CheckedIcon {
  checked?: boolean
}

export const InsurerRegistrationDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const InsurerRegistrationTitleDiv = styled.div``

export const InsurerRegistrationTitle = styled.p`
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #4b4b4b;
`
export const InsurerRegistrationSubtitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #878da1;
`

export const CardsDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
`

export const CardContent = styled.div`
  margin-top: 40px;
  margin-left: 28px;
`

export const CardTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: #4b4b4b;
`
export const CardSubtitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #878da1;
  width: 90%;
`
