import styled from 'styled-components'

export const StyledCard = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 9px 0px rgba(19, 23, 53, 0.25);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 4px;
  padding: 15px;
  @media screen and (max-width: 768) {
    width: 90%;
  }
`
