import { useContext } from 'react'
import { RouteObject, RouterProvider, createBrowserRouter, useNavigate } from 'react-router-dom'

import {
  ControlOutlined,
  DollarOutlined,
  MenuUnfoldOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons'
import { useVariant } from '@unleash/proxy-client-react'
import { Frame as FrameComponent } from 'design-system/components'

import { AuthContext } from '@/App/contexts/authentication/AuthContext'

import { ProtectedRoute } from './contexts/ProtectedRoute'
import { AuthProvider } from './contexts/authentication/AuthProvider'
import { DynamicFormProvider } from './contexts/dynamicForm/DynamicFormProvider'
import { UserContext } from './contexts/userData/userContext'
import { UserProvider } from './contexts/userData/userProvider'
import AdminMarketingAssetsPage from './pages/admin/marketingAssets'
import BrokerManager from './pages/brokers/index'
import CommissionGrids from './pages/commissionGrids'
import CreateOrderPage from './pages/createOrder'
import { CreateOrderProvider } from './pages/createOrder/order/store'
import InsurerAutomationsPage from './pages/insurerAutomations'
import LoginPage from './pages/login/Index'
import { OrderDetailsPage } from './pages/orderDetails'
import ListProposals from './pages/orders/index'
import UpdateCommissionGrid from './pages/updateCommissionGrid'
import WaitingPeriod from './pages/waitingPeriod'
import suitcaseIllustration from '/assets/suitcaseIllustration.png'

const Frame = ({
  component,
  menuActiveIndex,
  logoImageSrc = 'assets/suitcaseIllustration.png',
}: {
  component: JSX.Element
  menuActiveIndex: number
  logoImageSrc?: string
}) => {
  const userContext = useContext(UserContext)
  const auth = useContext(AuthContext)
  const navigate = useNavigate()

  const logout = () => {
    userContext?.signOut()
    auth?.signOut()
    navigate('/')
  }

  const menuItems = [
    {
      id: 0,
      label: 'Propostas',
      url: '/propostas',
      icon: <MenuUnfoldOutlined />,
    },
    {
      id: 1,
      label: 'Gerenciar corretores',
      url: '/gerenciar-corretores',
      icon: <ControlOutlined />,
    },
    {
      id: 2,
      label: 'Automações de operadoras',
      url: '/automacoes-de-operadoras',
      icon: <ThunderboltOutlined />,
    },
  ]
  const featurePreviewAllowedMails = useVariant('bliss-intranet-allowed-users-commision-grid')
    ?.payload?.value

  const userMail = localStorage.getItem('userMail')

  if (userMail && featurePreviewAllowedMails?.includes(userMail)) {
    menuItems.push({
      id: 3,
      label: 'Grades de comissão',
      url: '/grades-de-comissao',
      icon: <DollarOutlined />,
    })
  }

  const headerItems = [
    {
      id: 0,
      label: 'Sair',
      onClick: logout,
    },
  ]

  return (
    <FrameComponent
      logoImageSrc={logoImageSrc}
      headerProfile={headerItems}
      menuItems={menuItems}
      profileType="Administrador"
      profileName={userContext?.user?.name}
      profileLogo="https://parcerias-bliss.data2.media/file/yYTxBe4QBlU1OLpmuEGc0"
      menuActiveIndex={menuActiveIndex}
    >
      <ProtectedRoute component={component} />
    </FrameComponent>
  )
}

const routes: Array<RouteObject & { param?: string }> = [
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/',
    element: <LoginPage />,
  },
  {
    path: '/propostas',
    element: <Frame component={<ListProposals />} menuActiveIndex={0} />,
  },
  {
    path: '/cadastrar-proposta',
    element: (
      <Frame
        component={
          <CreateOrderProvider>
            <CreateOrderPage />
          </CreateOrderProvider>
        }
        menuActiveIndex={0}
      />
    ),
  },
  {
    path: '/propostas/:orderId',
    param: 'tab',
    element: (
      <Frame
        logoImageSrc={suitcaseIllustration}
        component={<OrderDetailsPage />}
        menuActiveIndex={0}
      />
    ),
  },
  {
    path: '/gerenciar-corretores',
    element: (
      <Frame
        logoImageSrc={suitcaseIllustration}
        component={<BrokerManager />}
        menuActiveIndex={1}
      />
    ),
  },
  {
    path: '/admin/materiais-de-divulgacao',
    element: <Frame component={<AdminMarketingAssetsPage />} menuActiveIndex={0} />,
  },
  {
    path: '/automacoes-de-operadoras',
    element: <Frame component={<InsurerAutomationsPage />} menuActiveIndex={2} />,
  },
  {
    path: '/grades-de-comissao',
    element: <Frame component={<CommissionGrids />} menuActiveIndex={3} />,
  },
  {
    path: '/grades-de-comissao/:tabId',
    param: 'tabId',
    element: <Frame component={<CommissionGrids />} menuActiveIndex={3} />,
  },
  {
    path: '/alterar-grade',
    element: <Frame component={<UpdateCommissionGrid />} menuActiveIndex={3} />,
  },
  {
    path: '/carencia',
    element: <Frame component={<WaitingPeriod />} menuActiveIndex={4} />,
  },
]
export const Router: React.FC = () => {
  return (
    <AuthProvider>
      <UserProvider>
        <DynamicFormProvider>
          <RouterProvider router={createBrowserRouter(routes)} />
        </DynamicFormProvider>
      </UserProvider>
    </AuthProvider>
  )
}
