export const issuingAgencies = [
  {
    label: 'SSP - Secretaria de Segurança Pública',
    value: 'SSP - SECRETARIA DE SEGURANÇA PÚBLICA',
  },
  { label: 'Ministério da Aeronáutica', value: 'MINISTÉRIO DA AERONAUTICA' },
  { label: 'Ministério do Exército', value: 'MINISTÉRIO DO EXÉRCITO' },
  { label: 'Ministério da Marinha', value: 'MINISTÉRIO DA MARINHA' },
  { label: 'Polícia Federal', value: 'POLICIA FEDERAL' },
  { label: 'Carteira de Identidade Classista', value: 'CARTEIRA DE IDENTIDADE CLASSISTA' },
  { label: 'Conselho Regional de Administração', value: 'CONSELHO REGIONAL DE ADMINISTRAÇÃO' },
  {
    label: 'Conselho Regional de Assistentes Sociais',
    value: 'CONSELHO REGIONAL DE ASSISTENTES SOCIAIS',
  },
  { label: 'Conselho Regional de Biblioteconomia', value: 'CONSELHO REGIONAL DE BIBLIOTECONOMIA' },
  { label: 'Conselho Regional de Contabilidade', value: 'CONSELHO REGIONAL DE CONTABILIDADE' },
  {
    label: 'Conselho Regional de Corretores de Imóveis',
    value: 'CONSELHO REGIONAL CORRETORES IMÓVEIS',
  },
  { label: 'Conselho Regional de Enfermagem', value: 'CONSELHO REGIONAL ENFERMAGEM' },
  {
    label: 'Conselho Regional de Engenharia, Arquitetura e Agronomia',
    value: 'CONSELHO REGIONAL ENG.ARQ. E AGRONOMIA',
  },
  { label: 'Conselho Regional de Estatística', value: 'CONSELHO REGIONAL DE ESTATISTICA' },
  { label: 'Conselho Regional de Farmácia', value: 'CONSELHO REGIONAL DE FARMÁCIA' },
  {
    label: 'Conselho Regional de Fisioterapia e Terapia Ocupacional',
    value: 'CONSELHO REGIONAL FISIOT.TERAPIA OCUPACIONAL',
  },
  { label: 'Conselho Regional de Medicina', value: 'CONSELHO REGIONAL DE MEDICINA' },
  {
    label: 'Conselho Regional de Medicina Veterinária',
    value: 'CONSELHO REGIONAL MEDICINA VETERINÁRIA',
  },
  { label: 'Conselho Regional de Nutrição', value: 'CONSELHO REGIONAL DE NUTRIÇÃO' },
  { label: 'Conselho Regional de Odontologia', value: 'CONSELHO REGIONAL DE ODONTOLOGIA' },
  {
    label: 'Conselho Regional de Profissionais de Relações Públicas',
    value: 'CONSELHO REGIONAL PROF.RELAÇÕES PÚBLICAS',
  },
  { label: 'Conselho Regional de Psicologia', value: 'CONSELHO REGIONAL DE PSICOLOGIA' },
  { label: 'Conselho Regional de Química', value: 'CONSELHO REGIONAL DE QUÍMICA' },
  {
    label: 'Conselho Regional de Representantes Comerciais',
    value: 'CONSELHO REGIONAL REPRES.COMERCIAIS',
  },
  { label: 'Ordem dos Músicos do Brasil', value: 'ORDEM DOS MÚSICOS DO BRASIL' },
  { label: 'Ordem dos Advogados do Brasil', value: 'ORDEM DOS ADVOGADOS DO BRASIL' },
  { label: 'Documentos Estrangeiros', value: 'DOCUMENTOS ESTRANGEIROS' },
  { label: 'Outros Emissores', value: 'OUTROS EMISSORES' },
]
