/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'

import { CheckCircleOutlined } from '@ant-design/icons'
import { Accordion, Button, CheckBox } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { healthPlansOrderRequest } from '@/App/clients/healthPlansOrder'
import { GetBrokersByIdDto } from '@/App/clients/healthPlansOrder/dto'
import useFetch from '@/App/clients/http'

import { AccordionBodyDiv, AccordionHeader, InputDiv, Label } from './style'

export interface Props {
  data?: GetBrokersByIdDto | null
}

const RegisteredOperators: React.FC<Props> = ({ data }) => {
  const operators = [
    {
      value: 'Qualicorp',
      label: 'Qualicorp',
    },
    {
      value: 'Bradesco',
      label: 'Bradesco',
    },
    {
      value: 'Sulamérica',
      label: 'Sulamérica',
    },
    {
      value: 'Amil',
      label: 'Amil',
    },
    {
      value: 'Gndi',
      label: 'Gndi',
    },
    {
      value: '',
      label: 'Sem operadora',
    },
  ]

  const [checkedItems, setCheckedItems] = useState<Array<string>>([])

  const {
    setRequestConfig: fillBrokerSetRequestConfig,
    isLoading,
    error: fillBrokersError,
  } = useFetch()

  useEffect(() => {
    let errorReference = ''

    if (fillBrokersError) {
      errorReference = 'Erro ao atualizar dado do corretor'
    } else {
      errorReference = 'Erro ao carregar informações do corretor'
    }

    if (fillBrokersError) {
      triggerToast(false, `Ops, ocorreu um erro ${errorReference}`)
    }
  }, [fillBrokersError])

  const sendData = (brokerOperator: Array<string>) => {
    if (data) {
      const fillBrokerInformation = healthPlansOrderRequest.fillBrokerInformation(
        data?.id,
        brokerOperator,
      )
      fillBrokerSetRequestConfig(fillBrokerInformation)
    }
  }

  const handleChange = (value: string) => {
    const updatedCheckedItems = [...checkedItems]

    if (updatedCheckedItems?.includes(value)) {
      const index = updatedCheckedItems.indexOf(value)
      updatedCheckedItems.splice(index, 1)
    } else {
      updatedCheckedItems.push(value)
    }

    setCheckedItems(updatedCheckedItems)
  }

  useEffect(() => {
    if (data?.registeredOperators) setCheckedItems(data.registeredOperators)
  }, [data])

  return (
    <Accordion
      header={
        <AccordionHeader style={{ justifyContent: 'flex-start' }}>
          <CheckCircleOutlined /> Cadastro nas operadoras
        </AccordionHeader>
      }
    >
      <AccordionBodyDiv>
        <InputDiv>
          <Label>Selecione em quais operadoras o corretor foi cadastrado:</Label>
          <div>
            {operators.map((operator, index) => (
              <CheckBox
                key={index}
                value={operator.value}
                label={operator.label}
                onChange={(e) => handleChange(e.target.value)}
                checked={checkedItems?.includes(operator.value)}
              />
            ))}
          </div>
        </InputDiv>

        <Button
          onClick={() => sendData(checkedItems)}
          loading={isLoading}
          style={{ width: '20vw', marginTop: '20px', height: '40px' }}
        >
          Salvar
        </Button>
      </AccordionBodyDiv>
    </Accordion>
  )
}

export default RegisteredOperators
