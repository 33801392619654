import { QueryFunctionContext, queryOptions } from '@tanstack/react-query'

import { api } from '../../http'
import { GetModalityDto } from '../dtos'

type GetCatalogTypeBaseRequest = {
  productId?: string | null
}
type GetCatalogTypeRequest = QueryFunctionContext & GetCatalogTypeBaseRequest

async function getCatalogModality({ productId, signal }: GetCatalogTypeRequest) {
  const response = await api.get<GetModalityDto>(
    `${import.meta.env.VITE_CATALOGO_API_URL}/modalidades`,
    {
      signal,
      headers: { Authorization: localStorage.getItem('authToken') },
      params: {
        produtoId: productId,
        situacao: 'A',
      },
    },
  )
  return response.data
}

export function getCatalogModalityOptions({
  productId,
  enabled = false,
}: GetCatalogTypeBaseRequest & { enabled?: boolean }) {
  return queryOptions({
    queryKey: ['catalog', 'modalities', { productId }] as const,
    queryFn: async (params) => getCatalogModality({ ...params, productId }),
    enabled: !!productId && enabled,
    refetchOnWindowFocus: false,
    select(data) {
      return data.data.map((modality) => ({
        label: modality.nome,
        value: modality.id,
      }))
    },
  })
}
