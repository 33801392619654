import { useEffect, useState } from 'react'

import { CheckCircleOutlined } from '@ant-design/icons'
import { Accordion, CheckBox, Radio, Select, Skeleton, TextLabel } from 'design-system/components'

import { healthPlansOrderRequest } from '@/App/clients/healthPlansOrder'
import { GetBrokersByIdDto, OptionsCustomerAssigment } from '@/App/clients/healthPlansOrder/dto'
import { HealthPlansOrdersRequests } from '@/App/clients/healthPlansOrders'
import { GetManagersDto } from '@/App/clients/healthPlansOrders/dtos'
import { useFillBrokerInformation } from '@/App/clients/healthPlansOrders/mutations/fillBrokerInformation'
import useFetch from '@/App/clients/http'

import { AccordionBodyDiv, AccordionHeader, InputDiv, Label, RegisteredOperatorsDiv } from './style'

export interface Props {
  data?: GetBrokersByIdDto | null
}

const PersonalInformation: React.FC<Props> = ({ data }) => {
  const [optionsCustomerAssigment, setOptionsCustomerAssigment] =
    useState<OptionsCustomerAssigment>()
  const [selectedBroker, setSelectedBroker] = useState<string>()

  const fillBrokerInformationRequest = useFillBrokerInformation()

  const {
    setRequestConfig: setDownloadBrokerDocumentFileRequestConfig,
    isLoading: isLoadingDownloadBrokerDocumentFile,
    data: downloadBrokerDocumentFileData,
    fileName: downloadedBrokerDocumentFileName,
  } = useFetch<ArrayBuffer>()

  const { setRequestConfig: setManagersRequestConfig, data: managersOptionsData } =
    useFetch<GetManagersDto>()

  const { setRequestConfig: setFillBrokerInformationRequestConfig } = useFetch()
  const [selectedDocumentToDownloadId, setSelectedDocumentToDownloadId] = useState('')

  useEffect(() => {
    const managersRequestConfig = HealthPlansOrdersRequests.getManagers()
    setManagersRequestConfig(managersRequestConfig)
  }, [])

  useEffect(() => {
    if (managersOptionsData != null && managersOptionsData?.managers != null) {
      const options = managersOptionsData?.managers
        .map((manager) => ({
          label: manager.name,
          value: manager.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
      setOptionsCustomerAssigment((oldState) => ({ ...oldState, managerOptions: options }))
    }
  }, [managersOptionsData])

  useEffect(() => {
    if (downloadBrokerDocumentFileData && downloadedBrokerDocumentFileName) {
      const blob = new Blob([downloadBrokerDocumentFileData])
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', downloadedBrokerDocumentFileName)

      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }
  }, [downloadBrokerDocumentFileData, downloadedBrokerDocumentFileName])

  const downloadDocumentBroker = (documentId?: string) => {
    if (data?.id && documentId) {
      const downloadDocumentBrokerRequestConfig =
        HealthPlansOrdersRequests.downloadBrokerDocumentFile(data.id, documentId)
      setSelectedDocumentToDownloadId(documentId)
      setDownloadBrokerDocumentFileRequestConfig(downloadDocumentBrokerRequestConfig)
    }
  }

  const sendData = (brokerOperator: unknown) => {
    if (data?.id) {
      const fillBrokerInformation = healthPlansOrderRequest.fillBrokerInformation(
        data?.id,
        brokerOperator,
      )
      setFillBrokerInformationRequestConfig(fillBrokerInformation)
    }
  }

  const ChangeManager = (managerId: string) => {
    const findSelectedManager = managersOptionsData?.managers.find(
      (manager) => managerId === manager.id,
    )
    setSelectedBroker(findSelectedManager?.name)

    const fillBrokerInformationData = { data: { managerId }, brokerId: data?.id }
    fillBrokerInformationRequest.mutate(fillBrokerInformationData)
  }

  const operators = [
    { value: 'GNDI', label: 'GNDI' },
    { value: 'Qualicorp', label: 'Qualicorp' },
    { value: 'Bradesco', label: 'Bradesco' },
    { value: 'Amil', label: 'Amil' },
  ]

  return (
    <Accordion
      header={
        <AccordionHeader>
          <CheckCircleOutlined /> Dados Pessoais
        </AccordionHeader>
      }
    >
      <AccordionBodyDiv>
        <InputDiv>
          <Label>Gênero:</Label>
          <TextLabel
            text={data?.personalInformation.gender === 'MALE' ? 'Masculino' : 'Feminino'}
            backgroundColor="#f2f3f7"
          />
        </InputDiv>
        <InputDiv>
          <Label>Possui CNPJ:</Label>
          <TextLabel
            text={data?.personalInformation.hasCompanyRegistration ? 'Sim' : 'Não'}
            backgroundColor="#f2f3f7"
          />
        </InputDiv>
        {data?.personalInformation.hasCompanyRegistration && (
          <InputDiv>
            <Label>CNPJ:</Label>
            <TextLabel
              text={data?.personalInformation.companyDocument}
              backgroundColor="#f2f3f7"
              rightIconType="copy"
              type="cnpj"
            />
          </InputDiv>
        )}
        <InputDiv>
          <Label>CPF:</Label>
          <TextLabel
            text={data?.personalInformation.document ?? 'Não informado'}
            backgroundColor="#f2f3f7"
            rightIconType="copy"
            type="cpf"
          />
        </InputDiv>
        <InputDiv>
          <Label>Data de nascimento:</Label>
          <TextLabel
            text={data?.personalInformation.birthDate ?? 'Não informado'}
            backgroundColor="#f2f3f7"
          />
        </InputDiv>
        <InputDiv>
          <Label>Documento de identidade:</Label>
          <TextLabel
            onDownload={() => downloadDocumentBroker(data?.personalInformation.identityDocument.id)}
            rightIconType={
              data?.personalInformation.identityDocument?.status === 'UPLOADED' ? 'download' : null
            }
            rightIconActionIsLoading={
              isLoadingDownloadBrokerDocumentFile &&
              selectedDocumentToDownloadId === data?.personalInformation.identityDocument.id
            }
            text={
              data?.personalInformation.identityDocument?.status === 'PENDING'
                ? 'Não informado'
                : `Documento_de_identificação_${data?.name}`
            }
            backgroundColor="#f2f3f7"
          />
        </InputDiv>
        <InputDiv>
          <Label>Comprovante de endereço:</Label>
          <TextLabel
            rightIconActionIsLoading={
              isLoadingDownloadBrokerDocumentFile &&
              selectedDocumentToDownloadId === data?.personalInformation.proofOfAddressDocument.id
            }
            onDownload={() =>
              downloadDocumentBroker(data?.personalInformation.proofOfAddressDocument.id)
            }
            rightIconType={
              data?.personalInformation.proofOfAddressDocument?.status === 'UPLOADED'
                ? 'download'
                : null
            }
            text={
              data?.personalInformation.proofOfAddressDocument?.status === 'PENDING'
                ? 'Não informado'
                : `Comprovante_de_endereço_${data?.name}`
            }
            backgroundColor="#f2f3f7"
          />
        </InputDiv>
        <InputDiv>
          <Label>Possui cadastro nas operadoras:</Label>
          <RegisteredOperatorsDiv>
            {operators.map((operator, index) => (
              <CheckBox
                value={operator.value}
                label={operator.label}
                checked={
                  ((data?.personalInformation.declaredRegisteredOperators &&
                    data?.personalInformation.declaredRegisteredOperators.length > 0) ||
                    index === operators?.length - 1) &&
                  data?.personalInformation.declaredRegisteredOperators?.includes(operator.value)
                }
                key={index}
              />
            ))}
          </RegisteredOperatorsDiv>
        </InputDiv>
        <InputDiv>
          <Label />
          <CheckBox
            value={''}
            label="Não possui cadastro em nenhuma destas operadoras"
            checked={data?.personalInformation.declaredRegisteredOperators?.length === 0}
          />
        </InputDiv>
        <InputDiv>
          <Label>Gestor:</Label>
          {fillBrokerInformationRequest.isPending ? (
            <Skeleton type="input" />
          ) : (
            <Select
              defaultValue={selectedBroker ?? data?.manager.name ?? 'Selecionar o gestor'}
              options={optionsCustomerAssigment?.managerOptions ?? []}
              onChange={(value) => {
                if (typeof value === 'string') {
                  ChangeManager(value)
                }
              }}
            />
          )}
        </InputDiv>
        <InputDiv>
          <Label>Receberá pagamentos via NF:</Label>
          <div>
            <Radio
              options={[{ value: 'true', label: 'Sim' }]}
              name="issueInvoices"
              defaultValue={data?.issueInvoices.toString()}
              onChange={(value) => sendData({ issueInvoices: value === 'true' })}
            />
            <Radio
              options={[{ value: 'false', label: 'Não' }]}
              name="issueInvoices"
              defaultValue={data?.issueInvoices.toString()}
              onChange={(value) => sendData({ issueInvoices: value === 'true' })}
            />
          </div>
        </InputDiv>
        <InputDiv>
          <Label>Prefere receber suas comissões por Pix ou Transferência?:</Label>
          <TextLabel
            text={
              data?.commissionReceivingPreference === 'PIX'
                ? 'PIX'
                : data?.commissionReceivingPreference === 'BANK_TRANSFER'
                ? 'Transferência Bancária'
                : 'Não informado'
            }
            backgroundColor="#f2f3f7"
          />
        </InputDiv>
      </AccordionBodyDiv>
    </Accordion>
  )
}

export default PersonalInformation
