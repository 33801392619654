import { useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import { useInfiniteQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { Table } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { OrderFillingExecutionDto } from '@/App/clients/orderInformationProcessing/dtos'
import { orderFillingExecutionInfiniteQueryOptions } from '@/App/clients/orderInformationProcessing/queries/orderFillingExecutions'

import { InsurerLocale } from '..'
import { LastExecutionsExpandableRowContent } from './expandableRow'
import { OrderFillingStatus, OrderFillingStatusTag } from './orderFillingStatusTag'
import { LastExecutionsDiv } from './style'

const InsurerAutomationsLastExecutions = () => {
  const getOrderFillingExecutionsQuery = useInfiniteQuery(
    orderFillingExecutionInfiniteQueryOptions(),
  )

  useEffect(() => {
    if (getOrderFillingExecutionsQuery.error)
      triggerToast(false, 'Ops, ocorreu um erro ao carregar últimas execuções')
  }, [getOrderFillingExecutionsQuery.error])

  const orderFillingExecutions =
    getOrderFillingExecutionsQuery.data?.pages.map((obj) => obj.orderFillingExecutions).flat() || []

  const lastExecutionsColumns = [
    {
      title: 'Operadora/Administradora',
      dataIndex: 'insurer',
      key: 'insurer',
      render: (insurer: string) => InsurerLocale[insurer],
    },
    { title: 'Total de propostas', dataIndex: 'ordersQuantity', key: 'ordersQuantity' },
    {
      title: 'Propostas encontradas no portal',
      dataIndex: 'foundOrdersQuantity',
      key: 'foundOrdersQuantity',
    },
    {
      title: 'Propostas atualizadas no portal',
      dataIndex: 'updatedOrdersQuantity',
      key: 'updatedOrdersQuantity',
    },
    {
      title: 'Data da execução',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => dayjs(createdAt).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Duração',
      dataIndex: 'durationInSeconds',
      key: 'durationInSeconds',
      render: (durationInSeconds: number) =>
        durationInSeconds ? `${durationInSeconds} segundos` : '-',
    },
    {
      title: 'Status',
      key: 'state',
      dataIndex: 'status',
      render: (status: string) => <OrderFillingStatusTag status={status as OrderFillingStatus} />,
    },
  ]

  return (
    <LastExecutionsDiv>
      <InfiniteScroll
        dataLength={orderFillingExecutions?.length}
        next={() => getOrderFillingExecutionsQuery.fetchNextPage()}
        hasMore={getOrderFillingExecutionsQuery.hasNextPage}
        scrollableTarget="frameContent"
        scrollThreshold={1}
        loader={null}
      >
        <Table
          loading={getOrderFillingExecutionsQuery.isLoading}
          columns={lastExecutionsColumns}
          data={orderFillingExecutions}
          expandableRowComponent={(record) => (
            <LastExecutionsExpandableRowContent record={record as OrderFillingExecutionDto} />
          )}
        />
      </InfiniteScroll>
    </LastExecutionsDiv>
  )
}

export default InsurerAutomationsLastExecutions
